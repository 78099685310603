export default function OddityIcon(props) {
  return (
    <svg viewBox="0 0 110.8 110.8" {...props}>
    <style>
      {
        ".__oddity-icon-prefix__cls-0{fill:#1F3037;}.__oddity-icon-prefix__cls-1{fill:#C95671;}.__oddity-icon-prefix__cls-2{fill:#528452;}.__oddity-icon-prefix__cls-3{fill:#CED025;}.__oddity-icon-prefix__cls-4{fill:#FDCB75;}.__oddity-icon-prefix__cls-5{fill:#FFFFFF;}"
      }
    </style>
    <g>
      <path className="__oddity-icon-prefix__cls-0" d="M16.7,0h77.5c9.2,0,16.7,7.5,16.7,16.7v77.5c0,9.2-7.5,16.7-16.7,16.7H16.7C7.5,110.8,0,103.3,0,94.2V16.6
        C0,7.4,7.5,0,16.7,0z"/>
      <g>
        <path className="__oddity-icon-prefix__cls-1" d="M23.5,45.8 55.7,62.3 88,45.8 56.6,31.9z"/>
        <path className="__oddity-icon-prefix__cls-2" d="M55.7,99.4 55.7,62.3 23.5,45.8 24.2,81.7z"/>
        <path className="__oddity-icon-prefix__cls-2" d="M55.8,99.4 55.8,62.3 88,45.8 87.2,81.7z"/>
        <path className="__oddity-icon-prefix__cls-3" d="M55.7,62.3L43,72L11.3,54.9l12.2-9.1L55.7,62.3z M55.8,62.3L68.6,72l31.6-17.1L88,45.8L55.8,62.3z"/>
        <path className="__oddity-icon-prefix__cls-4" d="M52.5,11.3l6.6,10c0.3,0.4,0.8,0.6,1.3,0.5l11.5-3.2c1.1-0.3,2,1,1.3,1.9l-7.5,9.3c-0.3,0.4-0.4,1-0.1,1.4
          l6.6,10c0.7,1-0.3,2.2-1.5,1.8l-11.2-4.2c-0.5-0.2-1.1,0-1.4,0.4l-7.4,9.3c-0.7,0.9-2.2,0.4-2.2-0.8l0.6-11.9c0-0.5-0.3-1-0.8-1.2
          l-11.2-4.2c-1.1-0.4-1-2,0.1-2.3l11.5-3.2c0.5-0.1,0.9-0.6,0.9-1.1l0.5-11.9C50.2,10.7,51.8,10.3,52.5,11.3z"/>
        <circle className="__oddity-icon-prefix__cls-5" cx="52.2" cy="29.6" r="2.4"/>
      </g>
    </g>
    <path className="__oddity-icon-prefix__cls-5" d="M28.1,21.6c2.6,0,4.7,2.1,4.7,4.7S30.7,31,28.1,31s-4.7-2.1-4.7-4.7S25.5,21.6,28.1,21.6z M46.7,16.4
      c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6S45.8,16.4,46.7,16.4z M65.7,43.6c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6
      s-1.6-0.7-1.6-1.6S64.8,43.6,65.7,43.6z M40.3,39.6c2.2,0,4,1.8,4,4s-1.8,4-4,4s-4-1.8-4-4S38.1,39.6,40.3,39.6z M78.4,27.7
      c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9S77.9,27.7,78.4,27.7z"/>
    </svg>
  )
}

import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Trans as Translate } from 'react-i18next';

import { STYLES } from '../CreateSong/CreateSong';
import SongButton from '../CreateSong/SongButton';

import './SelectStyleModal.scss';

export default function SelectStyleModal({ show, styles, onHide, onSubmit }) {

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (show) {
      setSelected(styles);
    }
  }, [show, styles]);

  const toggleStyle = styleId => {
    const newSelected = [...selected];

    if (newSelected.includes(styleId)) {
      newSelected.splice(newSelected.findIndex(s => s === styleId), 1);
    }
    else {
      newSelected.push(styleId);
    }

    setSelected(newSelected);
  }

  return (
    <Modal className='__modal __select-style-modal' show={show} onHide={onHide} backdrop='static'>
      <Modal.Body>
        <div className='style-picker'>
          { STYLES.map(style => (
            <SongButton id={style.id} key={style.id} icon={style.icon} outline={style.outline} active={selected?.includes(style.id)} onClick={() => toggleStyle(style.id)}>
              <Translate>{ style.phrase }</Translate>
            </SongButton>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}><Translate>Cancel</Translate></Button>
        <Button onClick={() => onSubmit(selected)} disabled={selected.length === 0}><Translate>Confirm</Translate></Button>
      </Modal.Footer>
    </Modal>
  );
}

import { useMemo } from 'react';
import './Equalizer.scss';
import classnames from 'classnames';
import PlayIcon from '../../icons/PlayIcon';
import PauseIcon from '../../icons/PauseIcon';

export default function Equalizer({ status, noAnimation, ...props }) {

  const bars = useMemo(() => 
    new Array(20).fill(),
  []);

  return (
    <div className='__equalizer' {...props}>
      { status === 'playing'
      ? <div className='control pause-song'><PauseIcon /></div>
      : <div className='control play-song'><PlayIcon /></div>
      }
      { bars.map((height, index) => <div key={index} className={classnames('bar', {'playing': status === 'playing' && !noAnimation })} style={{ height, animationDelay: -index / 2 + 's' }} />) }
    </div>
  );
}

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useLocation } from "react-router-dom";
import Axios from "axios";
import Slider from 'rc-slider';
import Cookies from "js-cookie";
import { useDebouncedCallback } from "use-debounce/lib";
import { Trans as Translate } from 'react-i18next';
import classNames from "classnames";

import { strofeApi } from "../../api/strofeApi";
import { LoopingAudio } from "../../utils/LoopingAudio";
import { commentsActions } from "../../store/commentsSlice";
import { usersSelectors } from "../../store/usersSlice";
import useInterval from "../../hooks/useInterval";

import NavigationHeader from "../NavigationHeader";
import EnrollmentFlow from "../../registration/EnrollmentFlow";
import Comments from "../Comments/Comments";
import Equalizer from "./Equalizer";
import seekSliderHandle from "./SeekTooltip";
import useEventCallback from "../../hooks/useEventCallback";

import StrofeLogoColors from "../../icons/StrofeLogoColors";
import HeartIconOutline from "../../icons/HeartIconOutline";

import './PublicPlayer.scss';
import LoadingBlocks from "../../layout/LoadingBlocks";

const STATUS = {
  fetching   : "fetching",
  generating : "generating",
  // downloading <- not used yet
  ready      : "ready",
  status     : "error",
};

export default function PublicPlayer() {

  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [songData, setSongData] = useState(null);
  const [loadStatus, setLoadStatus] = useState(STATUS.fetching);
  const [songDuration, setSongDuration] = useState(null);
  const [songStatus, setSongStatus] = useState(null);
  const [playbackPosition, setPlaybackPosition] = useState(null);
  const [seekOffset, setSeekOffset] = useState(null);
  const [disableAnimation, setDisableAnimation] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [songLiked, setSongLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [showEnrollment, setShowEnrollment] = useState(false);

  const seekRef = useRef();

  const currentUser = useSelector(usersSelectors.getCurrentUser);

  const onSongEnded = () => {
    setSongStatus(null);
    setPlaybackPosition(LoopingAudio.playbackPosition);
  }

  useEffect(() => {
    LoopingAudio.initialize({ simplePlayer: true, onSongEnded });
    dispatch(commentsActions.initialize({ songId: parseInt(id, 10) }));

    if (Cookies.get('api_access_token')?.length > 0) {
      setLoggedIn(true);
    }

    return () => {
      LoopingAudio.close();
    }
  }, [id, dispatch]);

  useEffect(() => {

    const getSong = async () => {
      try {
        if (location?.state?.songData) {
          loadSong(location.state.songData);
        }
        else {
          const response = await strofeApi.get(`/songs/${id}`);
          // const response = await Axios.get(process.env.REACT_APP_NOMODO_API + `/songs/${id}`);
          loadSong(response.data);
        }
      }
      catch(error) {
        console.log(error);
        setLoadStatus(STATUS.error);
      }
    }

    const loadSong = async data => {
      setSongData(data);
      LoopingAudio.setSongData(data);
      
      setSongLiked(data.liked);
      setLikeCount(data.like_count);

      let mp3Url = data.mp3_download_url;

      if (!data.generated) {
        setLoadStatus(STATUS.generating);

        const regenerateResponse = await Axios.post(process.env.REACT_APP_NOMODO_API + `/songs/generate/${id}`);
        mp3Url = regenerateResponse.data.mp3_download_url;
      }

      const duration = await LoopingAudio.loadSong(mp3Url);
      setSongDuration(duration);

      setLoadStatus(STATUS.ready);
    }

    getSong();

  }, [id, location]);

  const handleKeyDown = useEventCallback(e => {

    const activeInteractiveElement = () => {
      // buttons allow space-bar for "click" and inputs for adding a blank space:
      if (['INPUT', 'BUTTON', 'TEXTAREA'].includes(document.activeElement.tagName)) {
        return true;
      }
      
      // if a modal is visible, do not prevent the default space action:
      if (document.getElementsByClassName('modal').length) {
        return true;
      }
    }

    if (e.code === 'ArrowRight') {
      if (activeInteractiveElement() || LoopingAudio.playbackPosition + 0.5 >= LoopingAudio.songDuration) {
        return;
      }

      afterChangeOffset(Math.min(LoopingAudio.playbackPosition + 5, LoopingAudio.songDuration - 0.5) * 100);
    }

    if (e.code === 'ArrowLeft') {
      if (activeInteractiveElement()) {
        return;
      }

      afterChangeOffset(Math.max(LoopingAudio.playbackPosition - 5, 0) * 100);
    }

    if (e.code === 'Space') {
      if (activeInteractiveElement()) {
        return;
      }

      e.preventDefault();
      togglePlay();
    }
  });

  useEffect(() => {
    if (loadStatus === STATUS.ready) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [loadStatus, handleKeyDown]);


  useInterval(() => {
    if (LoopingAudio.status === 'playing') {
      setPlaybackPosition(LoopingAudio.playbackPosition);
    }
  }, 200);

  const loading = [STATUS.fetching, STATUS.generating].includes(loadStatus);

  const onShowEnrollment = () => {
    setShowEnrollment(true);
  }

  const onSuccessEnroll = () => {
    setShowEnrollment(false);
    toggleLikeSong();
  }

  const toggleLikeSong = () => {
    setSongLiked(!songLiked);
    setLikeCount(likeCount + (songLiked ? -1 : 1));
    debouncedSongLikeRequest.isPending() ? debouncedSongLikeRequest.cancel() : debouncedSongLikeRequest(songLiked);
  }

  const debouncedSongLikeRequest = useDebouncedCallback(liked => {
    if (liked) {
      strofeApi.delete(`song_likes/${id}`);
    }
    else {
      strofeApi.post('song_likes', { song_like: { song_id: id } });
    }
  }, 300);
  
  const togglePlay = () => {
    if (songStatus === null) {
      LoopingAudio.playTrack();
      setSongStatus('playing');
    }
    else if (songStatus === 'playing') {
      LoopingAudio.stopSong();
      setSongStatus('paused');
    }
    else if (songStatus === 'paused') {
      LoopingAudio.resumeSong();
      setSongStatus('playing');
    }
  }

  const changeOffset = v => {
    LoopingAudio.stopSong();
    setDisableAnimation(true);
    setSeekOffset(v);
  }
  
  const afterChangeOffset = v => {
    const offset = v / 100;
    LoopingAudio.playTrack({ id: 'main', trackOffset: offset, stopTrack: true });
    
    // since the interval for setting playbackPosition is 200, debounce the seek reset
    // to avoid having the seek flicker between playbackPosition and seekOffset
    debouncedResetSeekOffset();
    setSongStatus('playing');
    setDisableAnimation(false);

    // If the slider element is contained, blur it as the <- / -> arrow keys would cause it to jump in small steps and cannot be overwriten:
    if (document.activeElement && seekRef.current?.contains(document.activeElement)) {
      document.activeElement.blur();
    }
  }

  const debouncedResetSeekOffset = useDebouncedCallback(() => {
    setSeekOffset(null);
  }, 300);

  const seekPosition = seekOffset !== null ? seekOffset : playbackPosition * 100;

  const marks = {
    0                    : { label: ['playing', 'paused'].includes(LoopingAudio.status) ? LoopingAudio.secsToClockFormat(LoopingAudio.playbackPosition) : "0:00" },
    [songDuration * 100] : { label: LoopingAudio.secsToClockFormat(songDuration) },
  };

  return (
    <div className='__public-player'>
      { currentUser && <NavigationHeader /> }
      <Link to='/'><StrofeLogoColors width={200} viewBox="0 450 2000 1300" /></Link>
      { loading
      ? <LoadingBlocks><Translate>Loading Track...</Translate></LoadingBlocks>
      :
        <div>
          { loadStatus === STATUS.error
          ? <>
              <h4 className='load-error'>This track was not found or could not be loaded.</h4>
            </>
          : <>
              <h3 className='track-title'>{ songData.title }</h3>
              <Equalizer status={songStatus === 'playing' ? 'playing' : 'paused'} onClick={togglePlay} noAnimation={disableAnimation} />
              <div ref={seekRef}>
                <Slider className='playback' min={0} max={songDuration * 100} value={seekPosition} marks={marks} onChange={changeOffset} onAfterChange={afterChangeOffset} handle={seekSliderHandle} />
              </div>

              <div className='like-count'><HeartIconOutline role='button' onClick={!currentUser?.registered ? onShowEnrollment : toggleLikeSong} className={classNames({ active: songLiked })} />{ likeCount }</div>

              { songData.comments_enabled
              ? <Comments song={songData} />
              : <div className='comments-disabled'>
                  <Translate>Comments are disabled on this track</Translate>
                </div>
              }
            </>
          }

          { loggedIn
          ? <Link to='/library'>Return to your account</Link>
          : loadStatus === STATUS.error
            ? <Link to='/'>Back to Strofe</Link>
            : <>
              <p className='cta-text'>Enjoy this track? Create your own music using Strofe for free!</p>
              <Link className='action-button' to='/'>CREATE MUSIC</Link>
            </>
          }
        </div>
      }
      <EnrollmentFlow showWarmup show={showEnrollment} onSuccess={onSuccessEnroll} from='publicPlayer' onClose={() => setShowEnrollment(false)} />
    </div>
  );
}

import { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

// AUTH CALLBACK URL EXAMPLES
// --------------------------
// http://localhost:3210/auth/discord/callback?code=abcdxyz
// http://localhost:3210/auth/discord/callback?error=access_denied

export default function AuthCallback() {
  const location = useLocation();
  let { provider } = useParams();

  const error = useMemo(() => new URLSearchParams(location.search).get('error'), [location]);
  const code = useMemo(() => new URLSearchParams(location.search).get('code'), [location]);

  useEffect(() => {
    if (code) {
      localStorage.setItem(`${provider}_code`, code);
      window.close();
    }
    else if (error) {
      localStorage.setItem(`${provider}_error`, error);
      window.close();
    }
  }, [provider, code, error]);

  return (
    <div className='text-center'>
      Signing in...
    </div>
  );
}

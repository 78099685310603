import { useState } from 'react';
import { Trans as Translate } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce/lib';
import Slider from 'rc-slider';
import useInterval from '../../hooks/useInterval';
import { LoopingAudio } from '../../utils/LoopingAudio';
import usePrevious from '../../hooks/usePrevious';

import MasterVolume from '../SongPlayer/MasterVolume';
import seekSliderHandle from "../SongPlayer/SeekTooltip";
import MoodStyleIcon from '../SongPlayer/MoodStyleIcon';
import { MOODS, STYLES } from '../CreateSong/CreateSong';

import PlayIconV2 from "../../icons/PlayIconV2";
import PauseIconV2 from '../../icons/PauseIconV2';
import PreviousTrackIcon from '../../icons/PreviousTrackIcon';
import NextTrackIcon from '../../icons/NextTrackIcon';

import './LibraryPlayer.scss';

export default function LibraryPlayer({ song, playingStatus, togglePlayback, songDuration, setPlayingStatus }) {

  const [playbackPosition, setPlaybackPosition] = useState(null);
  const [seekOffset, setSeekOffset] = useState(null);
  const prevSongId = usePrevious(song?.id);

  useInterval(() => {
    if (LoopingAudio.status === 'playing') {
      setPlaybackPosition(LoopingAudio.playbackPosition);
    }
  }, 200);

  const debouncedResetSeekOffset = useDebouncedCallback(() => {
    setSeekOffset(null);
  }, 300);

  const marks = {
    0                    : { label: ['playing', 'paused'].includes(LoopingAudio.status) ? LoopingAudio.secsToClockFormat(LoopingAudio.playbackPosition) : "0:00" },
    [songDuration * 100] : { label: LoopingAudio.secsToClockFormat(songDuration) },
  };

  const changeOffset = v => {
    LoopingAudio.stopSong();
    setSeekOffset(v);
  }
  
  const afterChangeOffset = v => {
    const offset = v / 100;
    LoopingAudio.playTrack({ id: 'main', trackOffset: offset, stopTrack: true });
    
    // since the interval for setting playbackPosition is 200, debounce the seek reset
    // to avoid having the seek flicker between playbackPosition and seekOffset
    debouncedResetSeekOffset();
    setPlayingStatus('playing');
  }

  const seekPosition = seekOffset !== null ? seekOffset : playbackPosition * 100;

  if (!song) {
    return null;
  }

  return (
    <div className='__library-player'>
      <div className='controls'>
        <div className='info'>
          <MoodStyleIcon mood={song.mood} />
          <div className='title'>
            <div className='song-title'>{ song.title }</div>
            <div className='subtitle'>
              <Translate>{ MOODS.find(m => m.id === song.mood).phrase }</Translate>
              {' / '}
              <Translate>{ STYLES.find(e => e.id === song.style).phrase }</Translate>
            </div>
          </div>

          <MasterVolume />

          <div className='playback'>
            { false && <PreviousTrackIcon className='change-track' role='button' onClick={() => console.log('change track')} /> }
            <div className='toggle-playback' role='button' onClick={togglePlayback}>
              { playingStatus === 'playing'
              ? <PauseIconV2 />
              : <PlayIconV2  />
              }
            </div>
            { false && <NextTrackIcon className='change-track' role='button' onClick={() => console.log('change track')} /> }
          </div>
        </div>

        <div className='seek' key={song.id}>
          <div className='marker'>{ marks[0].label }</div>
          { prevSongId === song.id
          ? <Slider min={0} max={songDuration * 100} value={seekPosition} onChange={changeOffset} onAfterChange={afterChangeOffset} handle={seekSliderHandle} />
          : <div className='slider-filler' />
          }
          <div className='marker'>{ marks[songDuration * 100].label }</div>
        </div>
      </div>
    </div>
  );
}
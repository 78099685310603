export default function BossaOutline(props) {
  return (
    <svg
      id="__bossa-outline-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 114.53 114.36"
      {...props}
    >
      <defs>
        <style>
          {
            ".__bossa-outline-prefix__cls-1{fill:none;stroke:#cfd4d8;stroke-miterlimit:10;stroke-width:2px}.__bossa-outline-prefix__cls-2{fill:#cfd4d8}"
          }
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 24 copy"}</title>
      <rect
        className="__bossa-outline-prefix__cls-1"
        x={1.53}
        y={1.75}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
      />
      <path
        className="__bossa-outline-prefix__cls-1"
        d="M87.63 72c-2.71-4.26-7-6.25-11.59-7.74-3-1-3.71-1.92-3.91-5 0-.78-.08-1.56-.21-2.32-1.1-6.75-6.58-9.84-13-7.32-1.35.53-2.67 1.11-4.12 1.71-5.29-9-10.9-18.83-16.13-27.84a2.59 2.59 0 00.8-3.4l-3.31-5.9a2.59 2.59 0 00-3.52-1L29 15.26a2.59 2.59 0 00-1 3.52l3.31 5.9a2.59 2.59 0 003.41 1c2.31 4.18 7.08 13 8.72 16 2.27 4.1 4.56 8.14 6.86 12.22-1.5 1.08-2.65 1.86-3.76 2.7-5.62 4.23-6.06 10.82-.37 14.88C50 74.2 51.25 77 49.59 81.24a8.64 8.64 0 00-.31 2.07c-1.57 13 9.24 19.64 18.38 18.79a26.11 26.11 0 0018.72-10.23A16.93 16.93 0 0087.63 72z"
      />
      <circle
        className="__bossa-outline-prefix__cls-1"
        cx={26.09}
        cy={23.76}
        r={2.05}
        transform="rotate(-29.3 26.095 23.761)"
      />
      <circle
        className="__bossa-outline-prefix__cls-1"
        cx={41.33}
        cy={15.2}
        r={2.05}
        transform="rotate(-29.3 41.33 15.197)"
      />
      <circle className="__bossa-outline-prefix__cls-2" cx={61.71} cy={68.67} r={7.16} />
      <circle className="__bossa-outline-prefix__cls-2" cx={68.51} cy={52.17} r={6.43} />
      <circle className="__bossa-outline-prefix__cls-2" cx={82.21} cy={53.39} r={2.36} />
      <circle className="__bossa-outline-prefix__cls-2" cx={79.85} cy={40.22} r={1.64} />
      <circle className="__bossa-outline-prefix__cls-2" cx={91.05} cy={59.16} r={1.64} />
      <circle className="__bossa-outline-prefix__cls-2" cx={74.94} cy={64.09} r={3.97} />
      <path
        className="__bossa-outline-prefix__cls-1"
        d="M56.87 70.03l15.35 26.29M61.01 67.39l15.45 26.1M65.48 64.54l15.35 26.29"
      />
    </svg>
  );
}

export default function GearIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 50.8 51.8">
      <path d="M49.2,23.3l-4.4-0.8c-0.8-0.1-1.4-0.7-1.6-1.4c-0.4-1.7-1.1-3.2-2-4.7c-0.5-0.7-0.4-1.6,0.1-2.2
        c0.5-0.6,0.9-1.3,1.4-1.9l1.4-2l-3.5-3.6l-3.9,2.8c-0.6,0.5-1.5,0.5-2.2,0.1c-1.5-0.9-3.2-1.6-4.9-2c-0.7-0.2-1.3-0.7-1.4-1.5
        c-0.1-0.6-0.2-1.3-0.3-1.9l-0.5-3h-5c0,0.2-0.1,0.3-0.1,0.5l-0.8,4.4c-0.1,0.8-0.7,1.4-1.4,1.5c-1.6,0.5-3.2,1.1-4.7,2
        c-0.7,0.4-1.6,0.4-2.3-0.1C12.1,8.7,10.9,7.9,9.7,7L9.4,6.8l-3.5,3.5L6,10.5l2.7,3.8c0.5,0.6,0.6,1.5,0.1,2.1
        c-0.9,1.5-1.6,3.2-2,4.9c-0.2,0.7-0.8,1.3-1.5,1.4c-0.4,0.1-0.8,0.1-1.1,0.2l-3.8,0.6c0,1.6,0,3.2,0,4.8c0,0.1,0.2,0.2,0.3,0.2
        l4.6,0.8C6.1,29.4,6.8,30,7,30.8c0.4,1.6,1,3.1,1.8,4.5c0.6,0.7,0.6,1.8,0,2.5c-0.8,1-1.5,2.1-2.2,3.1l-0.5,0.7l3.5,3.5l3.9-2.8
        c0.6-0.5,1.5-0.6,2.2-0.1c1.4,0.8,2.9,1.4,4.5,1.9c0.8,0.2,1.4,0.9,1.5,1.7c0.1,0.9,0.3,1.9,0.5,2.8c0.1,0.7,0.2,1.3,0.3,2h4.9
        c0-0.1,0.1-0.2,0.1-0.3c0.2-1.4,0.5-2.8,0.7-4.2c0-1,0.8-1.8,1.7-2c1.5-0.4,2.9-1,4.3-1.8c0.8-0.5,1.9-0.5,2.6,0.2
        c1.2,0.9,2.5,1.8,3.7,2.6l3.5-3.5l-2.8-4c-0.5-0.6-0.5-1.5-0.1-2.2c0.9-1.5,1.6-3.1,2-4.8c0.2-0.7,0.8-1.3,1.5-1.4
        c0.6-0.1,1.2-0.2,1.8-0.3l3.1-0.5v-5L49.2,23.3z M25.1,34.5c-4.8,0-8.7-3.9-8.7-8.6s3.9-8.7,8.6-8.7c4.7,0,8.6,3.8,8.7,8.5
        c0.1,4.8-3.7,8.7-8.5,8.8C25.2,34.5,25.2,34.5,25.1,34.5z"/>
    </svg>
  )
}

import { strofeApi } from "../api/strofeApi";

const UserEvents = {
  logEvent(title, test) {
    if (test) {
      const { test_identifier, variant: test_variant, revision: test_revision } = test;

      strofeApi.post('events', {
        event: {
          title,
          test_identifier,
          test_variant,
          test_revision,
        }
      });
    }
  },
};

export default UserEvents;

export default function HipHopIcon(props) {
  return (
    <svg
      id="__hip_hop-icon-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110.8 112.6"
      {...props}
    >
      <style>
        {".__hip_hop-icon-prefix__cls-0{fill:#4D3F91;}.__hip_hop-icon-prefix__cls-1{fill:#C95571;}.__hip_hop-icon-prefix__cls-2{fill:#FFFFFF;}.__hip_hop-icon-prefix__cls-3{fill:#FCCA75;}.__hip_hop-icon-prefix__cls-4{fill:#CECF23;}"}
      </style>
      <title>style_SVG_StrofeArtboard 39</title>
      <path className="__hip_hop-icon-prefix__cls-0" d="M94.2,111.7H16.7C7.5,111.7,0,104.3,0,95.1V17.6C0,8.3,7.5,0.9,16.7,0.9h77.5c9.2,0,16.7,7.5,16.7,16.7v77.5
        C110.8,104.3,103.4,111.7,94.2,111.7z"/>
      <g>
        <path className="__hip_hop-icon-prefix__cls-1" d="M95.5,33.8h-2.3l0.1-9.9c0-1.9-1.8-3.5-4.1-3.5L21.9,20c-2.2,0-4.1,1.5-4.1,3.4l-0.1,9.7h-2.2
          c-3.8,0-6.9,3-7,6.9L8.4,71.8c0,3.8,3,6.9,6.9,7l79.9,0.7c3.8,0,6.9-3,7-6.9l0.3-31.8C102.3,37,99.2,33.9,95.5,33.8z M88.8,33.8
          l-23.3-0.2v-2c0-0.8-0.7-1.5-1.5-1.5h-3.6v0.1v3.4h-2.8v-3.4v-0.1H53v0.1v3.4h-2.8v-3.4v-0.1h-3.6c-0.8,0-1.5,0.7-1.5,1.5v2
          l-22.9-0.4v-7.3c0-1.7,1.6-3,3.6-3l59.4,0.4c2,0,3.6,1.4,3.6,3.1V33.8z"/>
        <g>
          <g>
            <path className="__hip_hop-icon-prefix__cls-2" d="M59.2,68.9l-8.1-0.1c-0.8,0-1.5-0.7-1.5-1.5v-4.2c0-0.8,0.7-1.5,1.5-1.5l8.1,0.1c0.8,0,1.5,0.7,1.5,1.5v4.2
              C60.8,68.3,60.2,68.9,59.2,68.9z"/>
          </g>
          <g>
            <path className="__hip_hop-icon-prefix__cls-2" d="M59.4,59.9l-8.1-0.1c-0.8,0-1.5-0.7-1.5-1.5v-4.2c0-0.8,0.7-1.5,1.5-1.5l8.1,0.1c0.8,0,1.5,0.7,1.5,1.5v4.2
              C60.9,59.2,60.2,59.9,59.4,59.9z"/>
          </g>
          <g>
            <path className="__hip_hop-icon-prefix__cls-2" d="M59.4,51l-8.1-0.1c-0.8,0-1.5-0.7-1.5-1.5v-4.2c0-0.8,0.7-1.5,1.5-1.5l8.1,0.1c0.8,0,1.5,0.7,1.5,1.5v4.2
              C61,50.3,60.2,51,59.4,51z"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path className="__hip_hop-icon-prefix__cls-3" d="M28.8,70.2c-3.8,0-7.3-1.5-9.9-4.2c-2.6-2.7-4.1-6.2-4-10c0.1-7.7,6.4-13.9,14-13.9H29
                c3.8,0,7.3,1.5,9.9,4.2s4.1,6.2,4,10c-0.1,7.7-6.4,13.9-14,13.9H28.8z"/>
              <path className="__hip_hop-icon-prefix__cls-2" d="M28.9,43.1L28.9,43.1H29c3.5,0,6.7,1.4,9.2,3.9c2.4,2.5,3.8,5.8,3.7,9.2c-0.1,7.1-5.9,12.9-13,12.9h-0.1
                c-7.2-0.1-13-6-12.9-13.1C15.9,48.9,21.8,43.1,28.9,43.1 M28.9,41.1c-8.3,0-15,6.7-15.1,14.9c-0.1,8.3,6.6,15.1,14.9,15.2h0.1
                c8.3,0,15-6.7,15.1-14.9C44,48,37.3,41.2,29,41.1C28.9,41.1,28.9,41.1,28.9,41.1L28.9,41.1z"/>
            </g>
            <circle className="__hip_hop-icon-prefix__cls-2" cx="28.9" cy="56.1" r="3.9"/>
          </g>
          <g>
            <g>
              <path className="__hip_hop-icon-prefix__cls-3" d="M81.7,70.5c-3.8,0-7.3-1.5-9.9-4.2c-2.6-2.7-4.1-6.2-4-10c0.1-7.7,6.4-13.9,14-13.9h0.1
                c3.8,0,7.3,1.5,9.9,4.2s4.1,6.2,4,10C95.7,64.3,89.3,70.5,81.7,70.5L81.7,70.5z"/>
              <path className="__hip_hop-icon-prefix__cls-2" d="M81.8,43.5L81.8,43.5L81.8,43.5c3.6,0,6.8,1.4,9.3,3.9c2.4,2.5,3.8,5.8,3.7,9.2c-0.1,7.1-5.9,12.9-13,12.9
                h-0.1c-7.2-0.1-13-6-12.9-13.1C68.8,49.2,74.7,43.5,81.8,43.5 M81.8,41.4c-8.3,0-15,6.7-15.1,14.9c-0.1,8.3,6.6,15.1,14.9,15.2
                h0.1c8.3,0,15-6.7,15.1-14.9C96.8,48.3,90.2,41.5,81.8,41.4C81.8,41.4,81.8,41.4,81.8,41.4L81.8,41.4z"/>
            </g>
            <circle className="__hip_hop-icon-prefix__cls-2" cx="81.8" cy="56.5" r="3.9"/>
          </g>
        </g>
        <path className="__hip_hop-icon-prefix__cls-4" d="M18.6,41h-2.1c-1.3,0-2.3-1.1-2.3-2.4l0,0c0-1.3,1.1-2.3,2.4-2.3h2.1c1.3,0,2.3,1.1,2.3,2.4l0,0
          C21,40,19.9,41,18.6,41z"/>
      </g>
    </svg>
  )
}

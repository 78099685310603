import * as React from "react";

const InstrumentFunkGuitar = (props) => (
  <svg
    id="InstrumentFunkGuitar_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 111.04 110.84"
    style={{
      enableBackground: "new 0 0 111.04 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentFunkGuitar_svg__st2{fill:#fdcc75}.InstrumentFunkGuitar_svg__st3{fill:#4e4092}.InstrumentFunkGuitar_svg__st4{fill:#f18860}.InstrumentFunkGuitar_svg__st5{fill:#cfd125}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      className="InstrumentFunkGuitar_svg__st3"
      d="M54.08 57.75s-2.27 3.15-.43 6.2c1.13 1.87 2.89 2.44 4.57 3.44-1.53.39-2.94 1.1-4.33 1.84-2.5 1.33-4.4 3.97-5.68 6.43-4.93 9.5-7.53 21-20.6 21.43-7.02.23-11.44-4.24-15.57-9.31-4.03-4.95-5.88-11.91-4.01-18.16 3.52-11.76 15.44-14.08 25.05-18.85 2.05-1.02 3.81-2.33 5.57-3.76 1.93-1.57 3.38-5.02 5.12-7.02 2.08-2.4 4.5-4.5 7.39-5.88 2.46-1.18 5.16-1.93 7.69-2.95 1.84-.75 3.73-1.04 5.17-2.54-1.1-.53-1.65-1.96-1.25-3.12.4-1.16 1.63-1.95 2.85-1.9 1.22.05 2.36.88 2.86 2 .74 1.67.01 3.48-1.01 4.85-4.69 6.3-14.02 6.59-17.94 13.94-.48.9-.88 1.88-.82 2.9.11 1.99 2.01 2.93 3.8 2.6l1.57 7.86z"
    />
    <path
      className="InstrumentFunkGuitar_svg__st4"
      d="m44.99 66.09-5.24-5.96c-.44-.5-.39-1.25.11-1.69.5-.44 1.25-.39 1.69.11l5.24 5.96c.44.5.39 1.25-.11 1.69-.5.43-1.26.38-1.69-.11zM32.83 77.21l-6.04-6.88c-.31-.36-.28-.9.08-1.22l3.24-2.84c.36-.31.9-.28 1.22.08l6.04 6.88c.31.36.28.9-.08 1.22l-3.24 2.84c-.36.31-.91.27-1.22-.08z"
    />
    <circle
      className="InstrumentFunkGuitar_svg__st5"
      cx={38.41}
      cy={80.01}
      r={0.99}
    />
    <circle
      className="InstrumentFunkGuitar_svg__st5"
      cx={36.6}
      cy={84.22}
      r={0.95}
    />
    <circle
      className="InstrumentFunkGuitar_svg__st5"
      cx={34.18}
      cy={87.92}
      r={1.03}
    />
    <path
      d="M29.27 92.37c-.51.45-1.29.4-1.74-.11-.11-.13-.19-.27-.24-.42-.15-.46-.03-.98.36-1.31.51-.45 1.29-.4 1.74.11.45.51.4 1.28-.12 1.73z"
      style={{
        fill: "#cfd125",
        stroke: "#cfd125",
        strokeWidth: 0.5036,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      className="InstrumentFunkGuitar_svg__st5"
      cx={31.83}
      cy={80.23}
      r={0.99}
    />
    <circle
      className="InstrumentFunkGuitar_svg__st5"
      cx={23.87}
      cy={71.16}
      r={0.99}
    />
    <path
      className="InstrumentFunkGuitar_svg__st5"
      d="M83.67 23.57c-.01-.01-.01-.05-.01-.08L43.98 57.44l4.26 4.86 38.78-35.51-.36.18-2.99-3.4z"
    />
    <path
      className="InstrumentFunkGuitar_svg__st3"
      d="M99.99 13.97c-.6-.43-1.36-.57-2.09-.62.07-.86.16-1.72.04-2.57-.17-1.14-.76-.5-1.2.12-.46.66-1.04 1.24-1.65 1.76-1.3 1.11-2.77 1.99-4.15 2.99-2.15 1.56-5.03 3.04-6.31 5.43-.15.28-1.05 1.99-.97 2.41.01.03 0 .06.01.08l2.98 3.4.36-.18c1.17-.59 2.34-1.16 3.38-1.96 1.32-1.02 2.43-2.27 3.52-3.52.74-.85 1.48-1.71 2.23-2.54.41-.45.68-.92 1.26-1.18.74-.33 1.58-.29 2.32-.63.46-.21.87-.58 1.03-1.06.23-.71-.16-1.5-.76-1.93z"
    />
    <circle
      className="InstrumentFunkGuitar_svg__st2"
      cx={97.32}
      cy={21.9}
      r={0.96}
    />
    <circle
      className="InstrumentFunkGuitar_svg__st2"
      cx={95.15}
      cy={24.31}
      r={0.96}
    />
    <circle
      className="InstrumentFunkGuitar_svg__st2"
      cx={92.73}
      cy={26.44}
      r={0.96}
    />
  </svg>
);

export default InstrumentFunkGuitar;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { strofeApi } from "../api/strofeApi";

const FETCH_PER_PAGE = 10;

const initialState = {
  // current song_id of the comments retrieved:
  songId: null,

  // count of total comments (includes nested replies):
  count: null,

  // comments (for nested replies, an extra entities: [] is added)
  entities: null,

  // pagination of main comments (reply_to === null)
  _page: null,

  // if main comments have more replies to load:
  hasMoreReplies: null,

  // fetch request status (initial-load, load-more, loaded):
  repliesRequest: null,

  // number of parent comments (not counting threaded replies). This number is checked
  // every time new parent comments are loaded, since we don't know how many
  // replies each parent comment has until they are loaded:
  reply_count: null,
}

const fetch = createAsyncThunk(
  'comments/fetch',
  async (action, thunkAPI) => {

    const replyTo = action?.replyTo;
    const page = action?.page || 1;

    // TODO -> if replyTo, then make sure it's not already loading! 

    const urlParams = new URLSearchParams({ song_id: thunkAPI.getState().comments.songId, per_page: FETCH_PER_PAGE, page });
    replyTo && urlParams.append('reply_to', replyTo);

    const { data } = await strofeApi.get(`comments?${urlParams.toString()}`);
    return { data, replyTo };
  }
);

const create = createAsyncThunk(
  'comments/create',
  async ({ message, replyTo: reply_to }, thunkAPI) => {

    const comment = {
      song_id: thunkAPI.getState().comments.songId,
      reply_to,
      message,
    };

    const { data } = await strofeApi.post('comments', { comment });
    return { data };
  }
);

const like = createAsyncThunk(
  'comments/like',
  async ({ id }) => {
    const { data } = await strofeApi.post('comment_likes', { comment_like: { comment_id: id } });
    return { data };
  }
);

const dislike = createAsyncThunk(
  'comments/dislike',
  async ({ id }) => {
    const { data } = await strofeApi.delete(`comment_likes/${id}`);
    return { data };
  }
);

const remove = createAsyncThunk(
  'comments/remove',
  async ({ id, replyTo, hasMoreReplies, page }, thunkAPI) => {
    await strofeApi.delete(`comments/${id}`);

    // if there are more replies, one extra element needs to be loaded
    // to avoid pagination skipping one entry. IE if per_page = 2

    // [5, 4]    <- page 1
    // [5]       <- comment 4 was deleted
    // [2, 1]    <- page 2 was shifted (as page 1 is now [5, 3])

    // Since entry [3] is missing, it needs to be loaded after a delete occurs:
    
    // [3]       <- get comment 2 (page * per_age => 1 * 2)
    if (hasMoreReplies) {
      const urlParams = new URLSearchParams({
        song_id  : thunkAPI.getState().comments.songId,
        page     : page * FETCH_PER_PAGE,
        per_page : 1,
      });

      replyTo && urlParams.append('reply_to', replyTo);

      const { data } = await strofeApi.get(`comments?${urlParams.toString()}`);

      return { id, replyTo, fetchedComment: data[0] };
    }

    return { id }
  }
)

const commentsSlice = createSlice({
  name: 'comments',
  initialState,

  reducers: {
    initialize: (state, action) => {
      const { songId } = action.payload;
      
      state.entities = [];
      state.songId = songId;
    },

    setCount: (state, action) => {
      const { count } = action.payload;
      state.count = count;
    },

    toggleLike: (state, action) => {
      const { id } = action.payload;
      const comment = findById(state, id);

      if (comment.liked) {
        comment.like_count--;
        comment.liked = false;
      }
      else {
        comment.like_count++;
        comment.liked = true;
      }
    },

    report: (state, action) => {
      const { id } = action.payload;
      const comment = findById(state, id);

      comment.reported = true;
    }
  },

  extraReducers: {
    [fetch.pending]: (state, action) => {

      const { replyTo } = action.meta.arg || {};

      const parentEntity = replyTo ? state.entities.find(entity => entity.id === replyTo) : state;

      parentEntity.repliesRequest = !parentEntity.entities?.length ? 'initial-load' : 'load-more';
    },

    [fetch.fulfilled]: (state, action) => {

      const { replyTo, data } = action.payload;

      const parentEntity = replyTo ? state.entities.find(entity => entity.id === replyTo) : state;

      if (parentEntity.repliesRequest === 'initial-load') {
        parentEntity.entities = [];
        parentEntity._page = 0;
      }

      parentEntity._page++;

      // If new comments were created at some point, some will be fetched twice.
      // IE, if two comments are created:
      
      // entities [new, new, 5, 4, 3]
      // data                  [4, 3, 2, 1]
      //                        ^^^^        <- overlapping comments [4, 3] need to be filtered out

      const repeatedIndex = parentEntity.entities.findIndex(entity => entity.id === data[0]?.id);
      
      if (repeatedIndex !== -1) {
        parentEntity.entities = parentEntity.entities.filter((_, index) => index < repeatedIndex);
      }

      parentEntity.entities.push(...data);

      if (!replyTo) {
        const childReplies = parentEntity.entities.reduce((totalCount, entity) => totalCount + entity.reply_count, 0);
        parentEntity.reply_count = parentEntity.count - childReplies;
      }

      // If the reply count is higher than the number of entities, the nested thread has more replies to load:
      parentEntity.hasMoreReplies = parentEntity.reply_count > parentEntity.entities.length;

      parentEntity.repliesRequest = 'loaded';
    },

    [create.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.count++;

      if (data.reply_to) {
        const parentEntity = state.entities.find(entity => entity.id === data.reply_to);
        parentEntity.reply_count++;

        if (!parentEntity.entities) {
          parentEntity.entities = [];
          // since the parent has no child comments, set the comments as loaded:
          parentEntity.repliesRequest = 'loaded';
        }
        
        parentEntity.entities.unshift(data);
      }
      else {
        state.entities.unshift(data);
      }
    },

    [remove.fulfilled]: (state, action) => {

      const { id, replyTo, fetchedComment } = action.payload;
      
      state.entities.some((entity, index) => {

        // deleting a main comment:
        if (entity.id === id) {
          state.entities.splice(index, 1);
          state.count -= (1 + entity.reply_count);
          state.reply_count--;

          return true;
        }

        // deleting a reply of a comment:
        else if (entity.entities) {
          const childIndex = entity.entities.findIndex(childEntity => childEntity.id === id);
    
          if (childIndex !== -1) {
            entity.entities.splice(childIndex, 1);
            state.count--;
            entity.reply_count--;
            return true;
          }
        }
    
        return false;
      });

      // when delete fetched an extra comments (if the parentEntity.hasMoreReplies = true):
      if (fetchedComment) {
        const parentEntity = replyTo ? state.entities.find(entity => entity.id === replyTo) : state;

        // when a new comment is created, if per_page = 10, we have now 11 comments loaded (10 fetched + 1 new one)
        // if new comments were created and then some subsequently deleted, the extra fetched comment will be repeated:
        if (parentEntity.entities.find(entity => entity.id === fetchedComment.id)) {
          return;
        }
        
        parentEntity.entities.push(fetchedComment);

        if (!replyTo) {
          const childReplies = parentEntity.entities.reduce((totalCount, entity) => totalCount + entity.reply_count, 0);
          parentEntity.reply_count = parentEntity.count - childReplies;
        }

        // If the reply count is higher than the number of entities, the nested thread has more replies to load:
        parentEntity.hasMoreReplies = parentEntity.reply_count > parentEntity.entities.length;
      }
    },
  }
});

const findById = (state, id) => {
  let item = null;

  state.entities.some(entity => {
    if (entity.id === id) {
      item = entity; 
      return true;
    }
    else if (entity.entities) {
      const childItem = entity.entities.find(childEntity => childEntity.id === id);

      if (childItem) {
        item = childItem;
        return true;
      }
    }

    return false;
  });

  return item;
}

export const commentsActions = {
  fetch,
  create,
  remove,
  like,
  dislike,
  ...commentsSlice.actions,
}

export const commentsSelectors = {
  getComments: state => state.comments.entities,
  getCount: state => state.comments.count,
  getParentRepliesCount: state => state.comments.reply_count,
  getParentHasMoreReplies: state => state.comments.hasMoreReplies,
  getParentRepliesRequest: state => state.comments.repliesRequest,
  getParentPage: state => state.comments._page,
}

export default commentsSlice.reducer;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { Route, Redirect, useHistory } from "react-router-dom";
import { usersActions, usersSelectors } from '../store/usersSlice';
import { strofeApi } from '../api/strofeApi';
import { Trans as Translate, useTranslation } from 'react-i18next';

import FeedbackModal, { FeedbackButton } from '../modals/Feedback/FeedbackModal';
import RegistrationModal from '../registration/RegistrationModal';

import MoodSpookyIcon from '../icons/MoodSpookyIcon';

import './PrivateRoute.scss';

export default function PrivateRoute({ component: Component, children, showFeedbackButton = true, disableRedirect, superUserOnly, superUserOrInvestorOnly, superUserOrComposerOnly, subscriberOnly, showLogout, noHeader, ...routeProps }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loadUserError, setLoadUserError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [loading, setLoading] = useState(true);

  const currentUser = useSelector(usersSelectors.getCurrentUser);
  const localSettings = useSelector(usersSelectors.getLocalSettings);
  const abTests = useSelector(usersSelectors.getAbTests);
  
  const animaySaleTest = abTests['animay-sale-2022'];
  const notificationVisible = animaySaleTest?.variant === 'control' && !localSettings.hideNotificationBar;

  const { i18n } = useTranslation();

  const handleLogout = useCallback((apiRequest = true) => {
    Cookies.remove('api_access_token');
    Cookies.remove('strofe_user_id');
    
    if (apiRequest) {
      strofeApi.delete('/sessions').finally(() => {
        history.push('/get-started');
      });
    }
    else {
      history.push('/get-started');
    }

  }, [history]);

  useEffect(() => {
    if (process.env.REACT_APP_DO_NOT_LOGOUT_ON_PRIVATE_ROUTE === 'true') {
      return;
    }
    
    if (loadUserError) {
      handleLogout(false);
    }
  }, [loadUserError, handleLogout]);

  useEffect(() => {
    
    const authenticate = async () => {  
      try {
        const { data: userData } = await dispatch(usersActions.fetchById({ id: Cookies.get('strofe_user_id'), isCurrentUser: true })).unwrap();

        if (userData.language !== i18n.language) {
          i18n.changeLanguage(userData.language);
        }

        if (userData.can_subscribe) {
          try {
            //const { data: subscribeTest } = await strofeApi.get('/user_tests/subscriptions-multi-tiers');
            const subscribeTest = {
              revision:        1,
              test_identifier: "subscriptions-multi-tiers",
              variant:         "sub-discount",
            }
            dispatch(usersActions.setTest({ test: subscribeTest }));
          }
          catch {}
        }

        try {
          const expiryDate = DateTime.fromISO('2022-06-01');
          const today = DateTime.now();
          
          if (today < expiryDate) {
            const { data: saleTest } = await strofeApi.get('/user_tests/animay-sale-2022');
            dispatch(usersActions.setTest({ test: saleTest }));
          }
        }
        catch {}

        if (superUserOnly && !userData.super_user) {
          history.push('/');
        }

        else if (superUserOrInvestorOnly && !userData.super_user && !userData.investor) {
          history.push('/');
        }

        else if (superUserOrComposerOnly && !userData.super_user && !userData.composer) {
          history.push('/');
        }

        else if (subscriberOnly && !userData.super_user && !userData.composer && !userData.subscribed && !userData.ambassador) {
          history.push('/');
        }
      }
      catch (error) {

        console.log('error:', error)
        // 404: user_id doesn't exist (GET api.strofe.com/user/1a2a3a):
        // 401: invalid_token (tampering with the token, or got deleted somewhere else):
        if (error.message.includes("401") || error.message.includes("404")) {
          setLoadUserError(true);
        }
        else {
          setServerError(true);
        }
      }
      finally {
        setLoading(false);
      }
    }

    if (Cookies.get('api_access_token')?.length > 0) {
      authenticate();
    }
    else {
      setLoading(false);
    }

  }, [handleLogout, dispatch, superUserOnly, superUserOrInvestorOnly, superUserOrComposerOnly, subscriberOnly, history, i18n]);

  const clickFeedback = () => {
    currentUser?.registered ? setShowFeedback(true) : setShowRegistration(true);
  }

  const onUserCreated = () => {
    setShowRegistration(false);
    setShowFeedback(true);
  }

  const renderContent = () => (
    <div className={classNames('__route', { '__with-notification-bar': notificationVisible, '__no-header': noHeader })}>
      { children }
      { showLogout && (
        <div className='__private-route-footer'>
          { currentUser?.registered && (
            <div role='button' className='link' onClick={handleLogout} data-test="ROUTE-logout"><Translate>Logout</Translate></div>
          )}
        </div>
      )}

      <RegistrationModal show={showRegistration} from='feedback' onClose={() => setShowRegistration(false)} onUserCreated={onUserCreated} />

      { showFeedbackButton && <FeedbackButton role='button' onClick={clickFeedback} /> }
      <FeedbackModal show={showFeedback} onClose={() => setShowFeedback(false)} />
    </div>
  );
  const loggedIn = () => Cookies.get('api_access_token')?.length > 0;

  const renderServerError = () => (
    <div className='text-center px-2'>
      <MoodSpookyIcon width={120} height={120} />
      <h1 className='my-3'>Ooops!</h1>
      <p className='my-3'>Our servers are currently undergoing maintenance or experiencing a small outage.</p>
      <p className='my-3'>Refresh this page to try again. If this error persists, <a style={{ color: '#fcca75' }} href='https://discord.com/invite/7brwyydeyf'>contact us through Discord.</a></p>
    </div>
  );
  
  if (serverError) {
    return renderServerError();
  }
  
  if (!loggedIn() && !disableRedirect) {
    return <Route {...routeProps} render={props => <Redirect to={{ pathname: '/get-started', state: { from: props.location } }} /> }/>
  }
  
  else if (!currentUser && !disableRedirect) {
    return null;
  }

  else if (loading) {
    return null;
  }

  return (
    <Route {...routeProps} render={props => renderContent(props)} />
  );
}

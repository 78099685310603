import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";

import Aiva from '../assets/logos/aiva.png';
import Mubert from '../assets/logos/mubert.png';
import Soundtrap from '../assets/logos/soundtrap.png';
import Amper from '../assets/logos/amper.png';
import Boomy from '../assets/logos/boomy.png';
import EpidemicSound from '../assets/logos/epidemic_sound.png';
//import Uppbeat from '../assets/logos/uppbeat.jpg';
import UppbeatLogo from '../assets/logos/UppbeatLogo.js';

const images = [
  { id: 'aiva', src: Aiva },
  { id: 'mubert', src: Mubert },
  { id: 'soundtrap', src: Soundtrap },
  { id: 'amper', src: Amper },
  { id: 'boomy', src: Boomy },
  { id: 'epidemic-sound', src: EpidemicSound },
  //{ id: 'uppbeat', src: UppbeatLogo },
];

export default function SlideCompetitorsGraph() {

  return (
    <div className='slide slide-competitors-graph white-bg'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <div className='bullet'>
          <div className='circle-big-yellow' />
          <div className='circle-small-lime' />
          <div className='circle-small-red' />
          <div className='circle-small-purple' />
        </div>
        <h1>Landscape</h1>
      </div>

      <div className='content'>
        <div className="chart">
          <div className='axis-label scalability'>Scalability</div>
          <div className='axis-label ease-of-use'>Ease of use</div>
          
          <div className='graph'>
            { images.map(image => <img key={image.id} className={image.id} src={image.src} alt={image.id} />) }
            <StrofeLogoWhite className='strofe-logo-black' viewBox="0 200 2000 1600" />
            <UppbeatLogo className='uppbeat-logo' />
          </div>
        </div>
      </div>

    </div>
  )
}

import TagManager from 'react-gtm-module';

//! To debug tracking, the following env variable needs to be set -> `REACT_APP_DEBUG_TRACKING=true`
const GTM_TRACK_WARNING =
`--------
WARNING!
--------
Google Tag Manager events are getting logged, which may affect real production data for Analytics or Facebook.
`

const Tracking = {
  initializeGTM() {
    if (process.env.REACT_APP_GTM_ID) {

      if (window.location.hostname !== 'www.strofe.com') {
        if (process.env.REACT_APP_DEBUG_TRACKING !== 'true') {
          return;
        }
    
        console.log(GTM_TRACK_WARNING);
      }
    
      TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
    }
  }
}

export const TRACKING_EVENTS = {
  completeRegistration : 'complete_registration',
  createSong           : 'create_song',
  getStarted           : 'get_started',
  purchase             : 'purchase',
  openDownloadModal    : 'open_download_modal',
  
  // When a user clicks on 'purchase coins' item:
  // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#select_item
  selectItem           : 'select_item',
};

// Returns an object with each event from Tracking Events. This is done to avoid
// using magic strings, to ensure that events exist in the GTM, FB and GA:
export const EventTracker = (function() {
  const events = {};

  Object.entries(TRACKING_EVENTS).forEach(([key, event]) => {
    events[key] = (args = {}) => window.dataLayer?.push({ event, ...args });
  });

  return events;
})();

export default Tracking;

import classNames from "classnames";
import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";
import SlideProblemRoadmap from "../assets/SlideProblemRoadmap";

const MILESTONES = [
  { title: 'Website Launched',              date: 'Jun 23 2021', position: '0%' },
  { title: 'First 10k Users',               date: 'Sep 14 2021', position: '12%'},
  { title: 'Paywall / First Sale',          date: 'Oct 14 2021', position: '24%'},
  { title: '100k Songs',                    date: 'Nov 5 2021',  position: '35%'},
  { title: 'Strofe Audio Engine 2.0',       date: 'Feb 28 2022', position: '45%'},
  { title: 'Subscription AB Test',          date: '2022',        position: '60%'},
  { title: 'Adaptable Music Output',        date: '2022',        position: '69%'},
  { title: 'Strofe Music NFT minting',      date: '2022',        position: '83%'},
];

export default function SlideRoadMap() {

  return (
    <div className='slide slide-roadmap'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <SlideProblemRoadmap className='problem' />
        <h1>Milestones / Roadmap</h1>
      </div>

      <div className='content'>
        <div className='roadmap'>
          <div className='line' />
          
          <div className='milestones'>
            { MILESTONES.map((milestone, i) => (
              <div className={classNames('milestone', { yellow: i > 3})} key={milestone.title} style={{ left: milestone.position }}>
                <div>{ milestone.title }</div>
                <div>{ milestone.date }</div>
                <div className='vertical-connector'>
                  <div className='dot' />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  )
}

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";

const Bullet = () => (
  <svg viewBox="0 0 42 31">
    <path d="M18.8 30.8c8.34 0 15.1-6.76 15.1-15.1S27.14.6 18.8.6 3.7 7.36 3.7 15.7s6.76 15.1 15.1 15.1Z" fill="#CECF25" />
    <path d="M6.5 30.7a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Z" fill="#F18860" />
    <path d="M11 9.2A4.6 4.6 0 1 0 11 0a4.6 4.6 0 0 0 0 9.2Z" fill="#F8CCC2" />
    <path d="M16.6 15.5c0-6.7 5.6-12.2 12.3-12.1C35.6 3.4 41.1 9 41 15.7l-24.4-.2Z" fill="#4D3F92" />
  </svg>
);

export default function SlideAfterStrofe() {
  return (
    <div className='slide slide-after-strofe white-bg'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <div className='bullet'>
          <Bullet />
        </div>
        <h1>And now anybody can be a musician</h1>
      </div>

      <div className='content'>
        <div className='persona'>
          <div className='testimonial'>
            <div className='quote'>
              "This is absolutely amazing, it generated an amazing song that legit brought a tear to my eye, lol. I will definitely be using this in future projects! 20/10!"
            </div>
            <div className='arrow' />
          </div>

          <div className='description'>
            <div>Heard about Strofe through word of mouth</div>
            <div>Used his musical tastes to create and pick 6 songs</div>
            <div>Adds his own musical flair despite no formal training</div>
            <div><strong>Purchases them all for $30</strong></div>
          </div>

          <div className='avatar'>
            <div className='head' />
            <div className='torso' />
          </div>
        </div>
        <div className='user-name'>Nick, 22</div>
      </div>
    </div>
  )
}
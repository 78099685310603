import { Trans as Translate } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function SaveSequenceModal({ show, onHide, onSubmit }) {
  return (
    <Modal className='__modal' show={show} onHide={onHide} size='sm' backdrop='static'>
      <Modal.Header>
        <Translate>Loop Status Change</Translate>
      </Modal.Header>
      <Modal.Body>
        <Translate i18nKey='save-loop' />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}><Translate>Cancel</Translate></Button>
        <Button onClick={onSubmit}><Translate>Confirm</Translate></Button>
      </Modal.Footer>
    </Modal>
  );
}

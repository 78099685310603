export default function PointyArrow(props) {
  return (
    <svg width={20} height={13} viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.81 6.06h8.93"
        stroke="#fff"
        strokeWidth={2.14}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="m8.99 12.04 10.36-5.98L8.99.07v11.97Z" fill="#fff" />
    </svg>
  );
}

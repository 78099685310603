const ORCHESTRATIONS = {
  jazz: [
    { id: 1, title: "Swingin Jazz Sextet" },
    { id: 48, title: "Jazz Message" },
    { id: 49, title: "Sax Summit" },
    { id: 50, title: "Vanguard Quartet" },
    { id: 51, title: "Jazz Trio" },
    { id: 52, title: "Guitar Quintet" },
  ],
  bossa: [
    { id: 2, title: "Orchestral Bossa" },
    { id: 3, title: "Brazilian Piano" },
    { id: 10, title: "Quirky Bossa" },
    { id: 11, title: "Windy Bossa" },
    { id: 12, title: "New Age Bossa" },
    { id: 20, title: "Traditional Brazilian Ensemble" },
  ],
  reggae: [
    { id: 4, title: "Relaxin Reggae Band" },
    { id: 33, title: "Steel Holiday" },
    { id: 34, title: "Mekatelyu" },
    { id: 35, title: "Carnival Day" },
    { id: 36, title: "Rock Steady" },
    { id: 37, title: "Calypsonian" },
  ],
  lofi: [
    { id: 5, title: "Linear Lofi" },
    { id: 21, title: "Bass Boom" },
    { id: 53, title: "Chillax" },
    { id: 54, title: "Pura Vida" },
    { id: 55, title: "Plucked Soundscape" },
    { id: 56, title: "Warmth Within" },
    { id: 57, title: "AM Vibes" },
  ],
  orc_pop: [
    { id: 6, title: "Small Pop Orchestra" },
    { id: 38, title: "Popera" },
    { id: 39, title: "Peter and the Wolf" },
    { id: 40, title: "Woodworks" },
    { id: 41, title: "Brassworks" },
    { id: 42, title: "Electric Eclectic " },
  ],
  retro_futuristic: [
    { id: 7, title: "8-bit Tunes" },
    { id: 27, title: "Futuristic Waves" },
    { id: 28, title: "Synth Forest" },
    { id: 68, title: "Lethal Synth" },
    { id: 69, title: "Fatal Synth" },
    { id: 70, title: "Brutal Synth " },
    { id: 71, title: "Dance Synth" },
    { id: 72, title: "Fatal Synth" },
    { id: 75, title: "Garage Wave" },
  ],
  pop: [
    { id: 8, title: "Full Pop Band" },
    { id: 43, title: "American Graffiti" },
    { id: 44, title: "Shimmering Lights" },
    { id: 45, title: "I Wish for Superstition" },
    { id: 46, title: "Soak up the Crow" },
    { id: 47, title: "Swells and Bells" },
    { id: 79, title: "New York Nights" },
    { id: 80, title: "Nashville" },
    { id: 81, title: "LA Edge" },
    { id: 82, title: "Miami Heat" },
  ],
  epic: [
    { id: 9, title: "Epic Orchestra" },
    { id: 29, title: "Wolf Hunt" },
    { id: 30, title: "Elk Hunt" },
    { id: 31, title: "Fox Hunt" },
    { id: 32, title: "Bear Hunt" },
    { id: 76, title: "Pastoral" },
  ],
  oddity: [
    { id: 13, title: "Odd Orchestra" },
    { id: 58, title: "Oddish" },
    { id: 59, title: "So Odd" },
    { id: 60, title: "Weird Fishy" },
    { id: 61, title: "The Odyssey" },
    { id: 62, title: "Zappa Zaps" },
  ],
  hip_hop: [
    { id: 14, title: "Linear Hip Hop" },
    { id: 15, title: "Amerykahn Hip Hop" },
    { id: 16, title: "Street Strings" },
    { id: 17, title: "Aggressive Electric" },
    { id: 18, title: "Neon Chill" },
    { id: 19, title: "Analog Funky Bounce" },
    { id: 63, title: "Harlem Nights" },
  ],
  latin: [
    { id: 22, title: "Baile y Fiesta" },
    { id: 23, title: "Hot Salsa" },
    { id: 24, title: "Willie Colon Trombones" },
    { id: 25, title: "Caliente Brass" },
    { id: 26, title: "Spicy Woodwinds" },
    { id: 74, title: "Carreta Sonera" },
    { id: 77, title: "Bolerazo" },
    { id: 78, title: "Sonero" },
  ],
  anime: [
    { id: 64, title: "Kawaii Metal" },
    { id: 65, title: "City Pop" },
    { id: 66, title: "J-Pop Band" },
    { id: 67, title: "Gran Turismo" },
    { id: 73, title: "Maiden of Iron" },
  ],
};

export default ORCHESTRATIONS;

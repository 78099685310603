import Slider, { SliderTooltip } from 'rc-slider';
import { LoopingAudio } from "../../utils/LoopingAudio";

export default function seekSliderHandle(props) {
  const { value, dragging, index, ...restProps } = props;

  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${LoopingAudio.secsToClockFormat(value / 100)}`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Slider.Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

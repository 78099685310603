import * as React from "react";

const InstrumentTrombone = (props) => (
  <svg
    id="InstrumentTrombone_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentTrombone_svg__st17{fill:#f18860;stroke:#f18860;stroke-width:1.1492;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      d="M50.91 23.85c3.1-.05 7.28 2.67 9.51 4.57 2.78 2.37 4.9 5.4 5.96 8.9-5.34-3.02-10.09.88-13.67 4.79-6.73 7.35-13.37 14.77-19.97 22.24-3.99 4.51-8.06 8.96-12.03 13.48-1.4 1.59-2.77 3.22-4.11 4.86-1.96 2.39-6.47 7.28-2.87 10.2 2.83 2.3 6.06.05 8.25-2.01 2.92-2.74 5.55-5.82 8.28-8.74 6.43-6.9 12.85-13.82 19.26-20.74 7.27-7.84 14.54-15.69 21.8-23.54 5.98-6.47 11.96-12.93 17.94-19.4l4.71-5.1c1.28-1.39 2.36-3.17 4.27-3.73 1.87-.55 3.85.09 5.17 1.48 2.47 2.61.92 5.74-1.04 8.1-2.27 2.73-44.84 47.49-50.55 53.96-.48-.48-.74-.56-1.22-1.04 0 0 36.74-39.07 40.27-42.82 3.09-3.28 6.16-6.57 9.21-9.89 1.73-1.88 5.08-4.94 2.28-7.6-1.62-1.54-3.31-1.54-4.53-.4-2.71 2.53-5.12 5.47-7.64 8.18-5.85 6.3-11.69 12.61-17.53 18.91-7.13 7.7-14.04 15.49-21.17 23.2-6.4 6.92-12.62 14.04-19.02 20.95-2.88 3.11-5.53 6.53-8.61 9.44-2.64 2.49-6.13 4.62-9.81 3.03-3.59-1.55-4.12-5.63-2.44-8.74 1.8-3.34 4.25-6.35 6.63-9.29 2.22-2.73 4.55-5.36 6.82-8.05 2.85-3.38 5.72-6.74 8.57-10.11 1.95-2.3 3.9-4.61 5.79-6.96 2.75-3.42 5.48-7.09 8.04-10.75 2.41-3.44 5.04-7.24 5.66-11.5.23-1.58-.02-3.23-.93-4.57-.18-.24-.91-1.32-1.28-1.31z"
      style={{
        fill: "#cfd125",
        stroke: "#cfd125",
        strokeWidth: 2.2983,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentTrombone_svg__st17"
      d="m45.21 66.01-7.73-6.96-.95 1.07 7.74 6.91zM54.66 67.82l-4.68-4.47-.64.7 4.71 4.47zM58.82 63.31l-4.73-4.4-.64.67 4.77 4.4zM27.36 85.07l-7.32-6.5-.9 1 7.33 6.46zM49.47 73.37l1.23 1.04-1.6 2.78L46.73 75z"
    />
  </svg>
);

export default InstrumentTrombone;

import { useMemo } from 'react';
import './EditorEqualizer.scss';
import classnames from 'classnames';

export default function EditorEqualizer({ status, ...props }) {

  const bars = useMemo(() => 
    new Array(20).fill(),
  []);

  return (
    <div className='__editor-equalizer' {...props}>
      { bars.map((height, index) => <div key={index} className={classnames('bar', {'playing': status === 'playing' })} style={{ height, animationDelay: -index / 2 + 's' }} />) }
    </div>
  );
}

export default function HipHopOutline(props) {
  return (
    <svg
      id="__hip_hop-outline-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 116.8 113.9"
      {...props}
    >
      <style>
        {".__hip_hop-outline-prefix__cls-0{fill:none;stroke:#CFD4D7;stroke-width:1.957;stroke-miterlimit:10;}.__hip_hop-outline-prefix__cls-1{fill:none;stroke:#CFD4D7;stroke-width:2.0114;stroke-miterlimit:10;}"}
      </style>
      <title>style_SVG_StrofeArtboard 23 copy</title>
      <g>
        <path className="__hip_hop-outline-prefix__cls-0" d="M97.1,112.3H19.7c-9.2,0-16.7-7.5-16.7-16.7V18.3C3,9.1,10.5,1.6,19.7,1.6h77.4c9.2,0,16.7,7.5,16.7,16.7v77.4
          C113.8,104.9,106.3,112.3,97.1,112.3z"/>
        <g>
          <path className="__hip_hop-outline-prefix__cls-1" d="M98.5,34.5h-2.3l0.1-9.9c0-1.9-1.8-3.5-4-3.5l-67.1-0.4c-2.2,0-4.1,1.5-4.1,3.4l-0.1,9.7h-2.2
            c-3.8,0-6.9,3-7,6.8l-0.3,31.7c0,3.8,3,6.9,6.8,7L98.1,80c3.8,0,6.9-3,7-6.8l0.3-31.7C105.3,37.7,102.3,34.6,98.5,34.5z
             M91.8,34.5l-23.2-0.2v-2c0-0.8-0.7-1.5-1.5-1.5h-3.6v0.1v3.4h-2.8v-3.4v-0.1h-4.6v0.1v3.4h-2.8v-3.4v-0.1h-3.6
            c-0.8,0-1.5,0.7-1.5,1.5v2l-23-0.2v-7.2c0-1.7,1.6-3,3.6-3L88.3,24c2,0,3.6,1.4,3.6,3.1L91.8,34.5z"/>
          <g>
            <path className="__hip_hop-outline-prefix__cls-1" d="M62.3,69.6l-8.1-0.1c-0.8,0-1.5-0.7-1.5-1.5v-4.2c0-0.8,0.7-1.5,1.5-1.5l8.1,0.1c0.8,0,1.5,0.7,1.5,1.5v4.2
              C63.8,68.9,63.2,69.6,62.3,69.6z"/>
            <path className="__hip_hop-outline-prefix__cls-1" d="M62.4,60.5l-8.1-0.1c-0.8,0-1.5-0.7-1.5-1.5v-4.2c0-0.8,0.7-1.5,1.5-1.5l8.1,0.1c0.8,0,1.5,0.7,1.5,1.5V59
              C63.9,59.8,63.2,60.5,62.4,60.5z"/>
            <path className="__hip_hop-outline-prefix__cls-1" d="M62.4,51.7l-8.1-0.1c-0.8,0-1.5-0.7-1.5-1.5v-4.2c0-0.8,0.7-1.5,1.5-1.5l8.1,0.1c0.8,0,1.5,0.7,1.5,1.5v4.2
              C64,51,63.3,51.7,62.4,51.7z"/>
          </g>
          <g>
            <g>
              <circle className="__hip_hop-outline-prefix__cls-1" cx="31.9" cy="56.8" r="15"/>
              <circle className="__hip_hop-outline-prefix__cls-1" cx="31.9" cy="56.8" r="3.9"/>
            </g>
            <g>
              <circle className="__hip_hop-outline-prefix__cls-1" cx="84.8" cy="57.1" r="15"/>
              <circle className="__hip_hop-outline-prefix__cls-1" cx="84.8" cy="57.1" r="3.9"/>
            </g>
          </g>
          <path className="__hip_hop-outline-prefix__cls-1" d="M21.8,41.7h-2.1c-1.3,0-2.3-1.1-2.3-2.4l0,0c0-1.3,1.1-2.3,2.4-2.3h2.1c1.3,0,2.3,1.1,2.3,2.4l0,0
            C24.1,40.6,23.1,41.7,21.8,41.7z"/>
        </g>
      </g>
    </svg>
  )
}

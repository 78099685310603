import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";

export default function SlideSolution() {
  return (
    <div className='slide slide-solution white-bg'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <div className='solution-bullet'>
          <div className='purple-circle' />
          <div className='lime-circle' />
        </div>
        <h1>We created an AI music platform that lets users make their own music</h1>
      </div>

      <div className='content'>
        <div className='circle orange'>
          <h3>Fast and Affordable</h3>
          <p>A song in seconds for just a few dollars.</p>
        </div>

        <div className='circle pink'>
          <h3>Unique &amp; Customizable</h3>
          <p>Easy to use tools to add your own touch.</p>
        </div>

        <div className='circle orange'>
          <h3>Buy to own</h3>
          <p>No complicated terms and conditions, simply pay to own songs.</p>
        </div>
      </div>
    </div>
  )
}

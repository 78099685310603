import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Trans as Translate } from 'react-i18next';

export default function DeleteInstrumentModal({ show, onHide, onSubmit }) {

  return (
    <Modal className='__modal' show={show} onHide={onHide} backdrop='static' size='sm'>
      <Modal.Header>
        <Translate>Delete Track</Translate>
      </Modal.Header>
      <Modal.Body>
        <Translate>Are you sure you will like to delete this track?</Translate>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}><Translate>Cancel</Translate></Button>
        <Button onClick={onSubmit}><Translate>Confirm</Translate></Button>
      </Modal.Footer>
    </Modal>
  );
}

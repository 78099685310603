const TEMPO_RANGES = {
  spooky: {
    jazz: [60, 90],
    lofi: [60, 90],
  },
  sad: {
    jazz: [60, 90],
    bossa: [60, 90],
    lofi: [60, 90],
    pop: [60, 90],
  },
  chill: {
    jazz: [70, 100],
    bossa: [60, 100],
    reggae: [90, 130],
    lofi: [60, 85],
    pop: [90, 120],
    hip_hop: [80, 90],
    latin: [80, 90],
  },
  happy: {
    bossa: [100, 130],
    reggae: [100, 130],
    pop: [100, 130],
    orc_pop: [100, 130],
    epic: [100, 130],
    retro_futuristic: [100, 130],
    oddity: [350, 450],
    latin: [100, 130],
  },
  spirited: {
    orc_pop: [130, 160],
    epic: [130, 160],
    retro_futuristic: [130, 160],
    anime: [160, 190],
  },
};

export default TEMPO_RANGES;

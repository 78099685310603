import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Axios from "axios";

import { Trans as Translate, useTranslation } from 'react-i18next';

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { FEEDBACK_TYPES } from '../../routes/Feedback/Feedback';

import './FeedbackModal.scss';

export default function FeedbackModal({ show, onClose, onSuccess, initialType, metadata }) {

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('feedback');
  const [description, setDescription] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      setType(initialType || 'feedback');
      setDescription('');
      setShowSuccess(false);
      setLoading(false);
    }
  }, [show, initialType]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let message = description;
    
    if (metadata) {
      message += `\n-----------\n${metadata}`;
    }

    console.log('message:', message)

    await Axios.post(process.env.REACT_APP_NOMODO_API + `/feedback_messages`,
      { feedback_message: { feedback_type: type, message } },
      { headers: { Authorization: `Bearer ${Cookies.get('api_access_token')}` }
    });

    onClose();
    onSuccess?.();
    setLoading(false);
    setShowSuccess(true);
  }

  const submitDisabled = !description.trim();

  return (
    <>
      <Modal className='__modal' show={show} backdrop='static'>
        <Form onSubmit={handleSubmit}>
          <Modal.Header>
            <Translate>Submit a bug or feedback</Translate>
          </Modal.Header>

          <Modal.Body>
            <Form.Group>
              <Form.Label><Translate>Feedback Type</Translate></Form.Label>
              <Form.Control as="select" value={type} onChange={e => setType(e.target.value)}>
                { Object.entries(FEEDBACK_TYPES).map(([value, phrase]) => <option key={value} value={value}>{ phrase }</option> )}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label><Translate>How can we help?</Translate></Form.Label>
              <Form.Control as="textarea" placeholder={t('Provide us with a description')} required value={description} onChange={e => setDescription(e.target.value)} />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={onClose} disabled={loading}><Translate>Cancel</Translate></Button>
            <Button variant="primary" type="submit" disabled={submitDisabled || loading}><Translate>Submit</Translate></Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal className='__modal' show={showSuccess} backdrop='static' size='sm'>
        <Modal.Header>
          <Translate>Submit a bug or feedback</Translate>
        </Modal.Header>

        <Modal.Body>
          <p><strong><Translate i18nKey='feedback-success-1' /></strong></p>
          <p><Translate i18nKey='feedback-success-2' /></p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSuccess(false)}><Translate>Close</Translate></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function FeedbackButton({ onClick }) {

  const renderTooltip = props => (
    <Tooltip className='__feedback-tooltip' {...props}>
      <Translate>Questions or feedback?</Translate>
      <br />
      <Translate>Let us know!</Translate>
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top-end" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
      <div className='__feedback-container'>
        <div className='__feedback-button' onClick={onClick}> ? </div>
      </div>
    </OverlayTrigger>
  );
}
export default function RetroFuturisticIcon(props) {
  return (
    <svg
      id="__retro-future-icon-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110.84 113.06"
      {...props}
    >
      <defs>
        <style>
          {
            ".__retro-future-icon-prefix__cls-2{fill:#fff}.__retro-future-icon-prefix__cls-3{fill:#f18860}.__retro-future-icon-prefix__cls-4{fill:#4d3f92}"
          }
        </style>
      </defs>
      
      <rect
        y={2.22}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
        fill="#528452"
      />
      <path
        className="__retro-future-icon-prefix__cls-2"
        d="M71.61 73.86L64 71.93l-1 7.83s-1.06 5.91 3 10.25c0 0 .11-3.46 2.44-3.66 0 0 .44 7.85 4.05 9.55 0 0-.1-2.38 1.48-3.46 0 0 5.27 7.66 11.28 10.65-.58-6.69-5.76-14.41-5.76-14.41 1.58-1.07 3.76-.1 3.76-.1-.25-4-7.39-7.28-7.39-7.28 1-2.09 4.3-.92 4.3-.92-2.66-5.38-8.55-6.52-8.55-6.52z"
      />
      <path
        className="__retro-future-icon-prefix__cls-3"
        d="M67 69.4L61.25 68l-.76 5.85a9.68 9.68 0 002.22 7.67s.08-2.59 1.82-2.74c0 0 .33 5.87 3 7.14a3.24 3.24 0 011.1-2.58s3.94 5.72 8.44 8c-.43-5-4.31-10.77-4.31-10.77a3.24 3.24 0 012.81-.08c-.19-3-5.53-5.44-5.53-5.44.78-1.57 3.22-.69 3.22-.69A9.68 9.68 0 0067 69.4z"
      />
      <path
        className="__retro-future-icon-prefix__cls-4"
        transform="rotate(-34.22 67.274 76.81)"
        d="M58.22 75.19h18.09v3.23H58.22z"
      />
      <path
        className="__retro-future-icon-prefix__cls-4"
        d="M33.27 60.48l2.54 18.07 22.27 16.78-4.92-20.07-19.89-14.78zM63.13 40.17l15.87 9 7.42 26.88L69.57 64.1l-6.44-23.93z"
      />
      <path
        d="M25.4 15.43S1.92 51.33 58.15 82.61l8.85-6 8.85-6C67.41 6.78 25.4 15.43 25.4 15.43z"
        fill="#ced025"
      />
      <circle
        className="__retro-future-icon-prefix__cls-4"
        cx={42.88}
        cy={41.13}
        r={11.86}
        transform="rotate(-33.9 43.265 41.187)"
      />
      <circle
        className="__retro-future-icon-prefix__cls-2"
        cx={42.88}
        cy={41.13}
        r={8.95}
        transform="rotate(-34.22 42.87 41.132)"
      />
      <path
        className="__retro-future-icon-prefix__cls-3"
        d="M57.45 62.56C50.69 67.16 44 69.89 39.76 70a110.07 110.07 0 0018.39 12.61l8.85-6 8.85-6a110.08 110.08 0 00-4.94-21.72C69.23 52.7 64.22 58 57.45 62.56zM47.05 18.87a37.69 37.69 0 00-5.2-2.07 35.9 35.9 0 00-16.45-1.37 35.9 35.9 0 00-4.77 15.81 37.69 37.69 0 000 5.6 47.78 47.78 0 0014.54-7 47.78 47.78 0 0011.88-10.97z"
      />
    </svg>
  )
}

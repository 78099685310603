import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Slider from 'rc-slider';
import './TestModal.scss';
import Checkbox from "../../layout/Checkbox";
import { strofeApi } from "../../api/strofeApi";
import { useEffect } from "react";

export default function TestModal({ show, onHide, activeTest, onEdit, onCreate }) {

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [variants, setVariants] = useState(['']);
  const [variantActivations, setVariantActivations] = useState([]);
  const [enabled, setEnabled] = useState(true);
  const [errors, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      setErrors(null);
      setSubmitting(false);

      if (activeTest) {
        setTitle(activeTest.title);
        setIdentifier(activeTest.identifier);
        setDescription(activeTest.description);
        setVariants(activeTest.variants);
        setVariantActivations(activeTest.variant_activations);
        setEnabled(activeTest.enabled);
      }

      else {
        setTitle('');
        setIdentifier('');
        setDescription('');
        setVariants(['']);
        setVariantActivations([]);
        setEnabled(false);
      }
    }
  }, [show, activeTest]);

  const onChangeIdentifier = e => {
    setIdentifier(e.target.value.replace(/[^A-Za-z0-9_-]/g, ''));
  }

  const onEditVariant = (index, value) => {
    let newVariants = [...variants];
    newVariants[index] = value.replace(/[^A-Za-z0-9_-]/g, '');
    setVariants(newVariants);
  }

  const onDeleteVariant = (index) => {
    if (variants.length === 1) return;

    let newVariants = [...variants];
    newVariants.splice(index, 1);
    setVariants(newVariants);
  }

  const onAddVariant = () => {
    let newVariants = [...variants];
    newVariants.push('');
    setVariants(newVariants);
  }

  const onChangeActivation = (index, value) => {
    let newVariantActivations = [...variantActivations];
    newVariantActivations[index] = value;
    setVariantActivations(newVariantActivations);
  }

  const onSubmit = async e => {
    const submitErrors = [];

    e.preventDefault();

    if (!description.trim().length) {
      submitErrors.push('Description cannot be empty');
    }

    if (submitErrors.length) {
      setErrors(submitErrors);
      return;
    }
    
    setErrors(null);
    setSubmitting(true);

    try {

      const testData = {
        title,
        identifier,
        description,
        variants,
        enabled,
        variant_activations : variantActivations
      };

      if (activeTest) {
        const { data } = await strofeApi.put(`/tests/${activeTest.id}`, testData);
        onEdit(data);
      }
      else {
        const { data } = await strofeApi.post('/tests', testData);
        onCreate(data);
      }
    }

    catch (error) {
      const { errors: requestErrors } = error.response.data;

      // example: { identifier: ['has been taken'] }
      Object.entries(requestErrors).forEach(([key, keyErrors]) => {
        keyErrors.forEach(err => submitErrors.push(`${key}: ${err}`))
      });

      setErrors(submitErrors);
    }

    finally {
      setSubmitting(false);
    }
  }

  const maxActivation = Math.floor( 100 / (variants.length + 1));

  return (
    <Modal className='__modal __test-modal' show={show} backdrop='static'>
      <Modal.Header>
        {activeTest ? 'Edit Test' : 'Create Test' }
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          {activeTest  && <Checkbox checked={enabled} onChange={e => setEnabled(e.target.checked)}>Enabled</Checkbox>}

          <Form.Group className='two-column'>
            <Form.Label>Title</Form.Label>
            <Form.Control required value={title} onChange={e => setTitle(e.target.value)} />
          </Form.Group>
          
          <Form.Group className='two-column'>
            <Form.Label>Identifier</Form.Label>
            <Form.Control disabled={activeTest} required value={identifier} onChange={onChangeIdentifier} spellCheck={false} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control required value={description} onChange={e => setDescription(e.target.value)} />
          </Form.Group>

          <hr />

          <h3>Variants</h3>
          {!activeTest &&
            <>
            { variants?.map((variant, index) => (
              <Form.Group key={index}>
                <div className="variant">
                  <Form.Control required value={variant} onChange={e => onEditVariant(index, e.target.value)} />
                  <span onClick={e => onDeleteVariant(index)}>X</span>
                </div>
              </Form.Group>
            ))}
            <span onClick={onAddVariant} className='add-variant'>Add Variant</span>
            </>
          }
          {activeTest &&
            <>
            { variants?.map((variant, index) => (
              <Form.Group key={index}>
                <div className="variant-activation">
                  <span>{variant}</span>
                  <span>{variantActivations[index]}%</span>
                </div>
                <Slider className='variant-slider' min={0} max={maxActivation} value={variantActivations[index]} onChange={value => onChangeActivation(index, value)} />
              </Form.Group>
            ))}
            </>
          }

          { errors && (
            <ul className='errors'>
              { errors.map(error => <li>{ error }</li> )}
            </ul>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onHide} disabled={submitting}>Cancel</Button>
          <Button type='submit' disabled={submitting}>{ activeTest ? 'Edit' : 'Create' }</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

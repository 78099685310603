export default function LPSemiCircle(props) {
  return (
    <svg viewBox="0 0 360 360" {...props}>
      <path
        d="M126 32c-13.9 0-27.3 1.9-40 5.5v283c12.7 3.6 26.1 5.5 40 5.5 81.2 0 147-65.8 147-147S207.2 32 126 32z"
        fill="#cecf25"
      />
    </svg>
  );
}

import { useState } from 'react';
import { strofeApi } from '../../api/strofeApi';

import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import StrofeLogoColorsLettersOnly from '../../icons/StrofeLogoColorsLettersOnly';
import MoodChillIcon from '../../icons/MoodChillIcon';
import InvestorsBG from '../../icons/art/InvestorsBG';

import './PotentialInvestors.scss';

export default function PotentialInvestors() {

  const [email, setEmail] = useState('');
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const onSubmitForm = async e => {
    e.preventDefault();

    setRequestError(false);

    try {
      await strofeApi.post('/potential_investors', { client_id: process.env.REACT_APP_NOMODO_API_CLIENT_ID, potential_investor: { email }});
      setRequestSuccess(true);
    }

    catch {
      setRequestError(true);
    }
  }

  return (
    <div className='__potential-investors'>
      <div className='background'>
        <InvestorsBG />
      </div>
      <div className='content'>
        <StrofeLogoColorsLettersOnly className='logo' />
        <div className='copy'>
          { !requestSuccess
            ? <>
                <h1>Be the first to hear about Strofe's Crowdfunding Round!</h1>
                <p>Share your email below to receive info on how to become a Strofe investor</p>
                <Form onSubmit={onSubmitForm}>
                  <div className='form-inner'>
                    <label>Email Address: *</label>
                    <input type='email' value={email} required onChange={e => setEmail(e.target.value)} />
                  </div>
                  <button type='submit'>Submit</button>
                </Form>
              </>
            : <>
                <MoodChillIcon className='chill-icon' />
                <h1>Thank you!</h1>
                <p className='text-center'>We will get in touch shortly!</p>
              </>
          }
          { requestError && (
            <Alert className='my-3' variant='danger'>There was an error processing your request. Please email us to contact@strofe.com instead.</Alert>
          )}
        </div>
      </div>
    </div>
  )
}

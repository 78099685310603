export default function StrofeCoinMono(props) {
  return (
    <svg width={28} height={28} fill="none" {...props}>
      <path
        d="M25.12 0H2.88A2.887 2.887 0 000 2.88v22.256a2.887 2.887 0 002.88 2.88h22.256a2.887 2.887 0 002.88-2.88V2.879C28 1.295 26.705 0 25.12 0zM1.54 22.318V3.915a2.376 2.376 0 012.36-2.376h18.297a2.376 2.376 0 012.361 2.376v.625a2.21 2.21 0 00-2.01-1.295H5.453a2.21 2.21 0 00-2.21 2.209v17.092c0 1.036.717 1.905 1.692 2.133h-1.02a2.357 2.357 0 01-2.377-2.361zm9.414-14.046c.655 1.066 1.691 1.691 2.681 2.392.29.198.61.35.869.579.38.35.64.228.914-.107 1.63-1.904 3.245-3.808 4.89-5.697.167-.199.518-.412.746-.366.503.091.58.579.244.975a738.499 738.499 0 00-4.448 5.286c-.853 1.02-.853 1.02.167 1.92 1.021.898 1.95 1.858 2.59 3.092 1.66 3.2-.716 7.084-4.418 6.627a5.333 5.333 0 01-2.133-.747c-.09-.06-.167-.106-.243-.182-.533-.442-.838-1.097-.777-1.798.06-.777.731-1.478 1.554-1.34.487.076.914.441.99.944.076.548-.229 1.127.015 1.645.442.945 1.722.686 2.407.213.884-.624 1.005-1.858.366-2.726-.793-1.082-1.89-1.813-2.94-2.605-.214-.168-.442-.32-.67-.488-.366-.29-.64-.183-.93.168-1.722 2.071-3.458 4.113-5.18 6.17-.304.365-.609.395-.944.136-.38-.289-.152-.578.076-.837 1.676-1.98 3.336-3.961 5.012-5.957.518-.625.518-.625-.091-1.127-1.112-.914-2.194-1.859-2.94-3.138-1.22-2.087-.823-4.25 1.081-5.759 1.691-1.34 4.098-1.264 5.698-.046.761.564 1.127 1.752.472 2.545-.442.533-1.34.746-1.874.243-.655-.61-.457-1.675-1.097-2.285-.67-.64-1.904-.274-2.3.503-.274.579-.137 1.188.213 1.767zm12.568 3.153a2.178 2.178 0 11-4.356.001 2.178 2.178 0 014.356 0zM9.536 15.493a2.178 2.178 0 11-4.356 0 2.178 2.178 0 014.356 0z"
        fill="#FDCB75"
      />
    </svg>
  );
}

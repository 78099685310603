import { DateTime } from "luxon";
import { useEffect } from "react";
import { useState } from "react";
import { strofeApi } from "../../api/strofeApi";
import Checkbox from "../../layout/Checkbox";
import { useDebouncedCallback } from "use-debounce/lib";

import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import Modal from "react-bootstrap/Modal";

import NavigationHeader from "../NavigationHeader";
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";
import CouponModal from "./CouponModal";
import StrofeCoin from "../../icons/coins/StrofeCoin";

import './CouponManager.scss';

export default function CouponManager() {

  const [coupons, setCoupons] = useState(null);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [filterValid, setFilterValid] = useState(false);
  const [editCouponId, setEditCouponId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toastCouponCreated, setToastCouponCreated] = useState(null);
  const [toastCouponEdited, setToastCouponEdited] = useState(null);
  const [toastCouponDeleted, setToastCouponDeleted] = useState(null);

  const debouncedLoading = useDebouncedCallback(value => {
    setLoading(value);
  }, 200);

  useEffect(() => {
    const loadCoupons = async () => {
      debouncedLoading(true);
      const urlParams = new URLSearchParams();

      filterValid && urlParams.append('valid', filterValid);
      filterEnabled && urlParams.append('enabled', filterEnabled);

      const { data } = await strofeApi.get(`/coupons?${urlParams.toString()}`);
      setCoupons(data);
      debouncedLoading.cancel();
      setLoading(false);
    }

    loadCoupons();
  }, [filterValid, filterEnabled, debouncedLoading]);

  const onCreateCoupon = () => {
    setEditCouponId(null);
    setShowCouponModal(true);
  }

  const onEditCoupon = id => {
    setEditCouponId(id);
    setShowCouponModal(true);
  }

  const onChangeEnabled = async (id, enabled) => {
    await strofeApi.put(`/coupons/${id}`, { enabled });
    const newCoupons = [...coupons];

    newCoupons[coupons.findIndex(coupon => coupon.id === id)].enabled = enabled;
    setCoupons(newCoupons);
  }

  const onDeleteCoupon = id => {
    setDeleteId(id);
    setShowDeleteModal(true);
  }

  const onConfirmDeleteCoupon = async () => {
    await strofeApi.delete(`/coupons/${deleteId}`);

    setCoupons(coupons.filter(coupon => coupon.id !== deleteId));
    setShowDeleteModal(false);
    setTimeout(() => setToastCouponDeleted(true), 500);
  }

  const onEditSuccess = data => {
    const newCoupons = [...coupons];
    newCoupons[coupons.findIndex(coupon => coupon.id === data.id)] = data;

    setCoupons(newCoupons);
    setShowCouponModal(false);
    setTimeout(() => setToastCouponEdited(true), 500);
  }

  const onCreateSuccess = data => {
    const newCoupons = [data, ...coupons];

    setCoupons(newCoupons);
    setShowCouponModal(false);
    setTimeout(() => setToastCouponCreated(true), 500);
  }

  const renderCouponManager = () => {

    if (coupons === null) {
      return (
        <div className='initial-load'>
          <InfiniteLoader width={48} />
          <p>Loading Feedback...</p>
        </div>
      );
    }

    return (
      <>
        <div className='controls'>
          <div className='filters'>
            <strong className='title'>FILTER</strong>
            <Checkbox checked={filterValid} onChange={e => setFilterValid(e.target.checked)}>Valid coupons</Checkbox>
            <Checkbox checked={filterEnabled} onChange={e => setFilterEnabled(e.target.checked)}>Enabled coupons</Checkbox>
          </div>
          <Button className='create' variant='warning' onClick={onCreateCoupon}>Create Coupon</Button>
        </div>

        { coupons.length === 0 && (
          <div className='empty-coupons'>
            There are no coupons to display
          </div>
        )}

        { coupons?.map(coupon => (
          <div className='coupon' key={coupon.id}>
            <div className='title'>
              <h3>{ coupon.title }</h3>
              <div className='coin-controls'>
                <div className='amount'><StrofeCoin />{ coupon.coin_amount.toLocaleString() } </div>
                <Checkbox checked={coupon.enabled} onChange={e => onChangeEnabled(coupon.id, e.target.checked)}>Enabled</Checkbox>
              </div>
            </div>

            <Form.Control className='permalink-preview' readOnly value={`${window.location.origin}/get/${coupon.identifier}`} />
            <p>{ coupon.description }</p>

            <div className='options'>
              <div className='valid-until'>Valid Until: { new DateTime.fromISO(coupon.valid_until).toLocaleString() }{' '}{ !coupon.valid && '(expired)' }</div>
              <div>Redeemed: {coupon.redemptions.toLocaleString()} times</div>
              <Button variant='danger' onClick={() => onDeleteCoupon(coupon.id)}>Delete</Button>
              <Button onClick={() => onEditCoupon(coupon.id)}>Edit</Button>
            </div>
          </div>
        ))}
      </>
    );
  }

  const renderDeleteModal = () => (
    <Modal className='__modal' size='sm' show={showDeleteModal} backdrop='static'>
      <Modal.Header>
        Delete Coupon
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this coupon?
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setShowDeleteModal(false)}>Cancel</Button>
        <Button onClick={onConfirmDeleteCoupon}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div className='__coupon-manager'>
      <NavigationHeader />
      <h1 className='header'>Coupon Manager</h1>
      { renderCouponManager() }
      { renderDeleteModal() }
      <CouponModal show={showCouponModal} onHide={() => setShowCouponModal(false)} editCoupon={coupons?.find(coupon => coupon.id === editCouponId)} onEdit={onEditSuccess} onCreate={onCreateSuccess} />

      <Toast show={coupons !== null && loading} className='strofe-toast'>
        <Toast.Body>Loading...</Toast.Body>
      </Toast>

      <Toast show={toastCouponCreated} autohide delay={2000} className='strofe-toast' onClose={() => setToastCouponCreated(false)}>
        <Toast.Body>Coupon created successfully</Toast.Body>
      </Toast>

      <Toast show={toastCouponEdited} autohide delay={2000} className='strofe-toast' onClose={() => setToastCouponEdited(false)}>
        <Toast.Body>Coupon edited successfully</Toast.Body>
      </Toast>

      <Toast show={toastCouponDeleted} autohide delay={2000} className='strofe-toast' onClose={() => setToastCouponDeleted(false)}>
        <Toast.Body>Coupon deleted successfully</Toast.Body>
      </Toast>
    </div>
  );
}
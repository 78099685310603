import * as React from "react";

const InstrumentSteelDrums = (props) => (
  <svg
    id="InstrumentSteelDrums_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 111.14"
    style={{
      enableBackground: "new 0 0 110.84 111.14",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentSteelDrums_svg__st5{fill:#cfd125}.InstrumentSteelDrums_svg__st53,.InstrumentSteelDrums_svg__st54,.InstrumentSteelDrums_svg__st64{fill:none;stroke-linecap:round;stroke-linejoin:round}.InstrumentSteelDrums_svg__st53{stroke:#cfd125;stroke-width:2.2066;stroke-miterlimit:10}.InstrumentSteelDrums_svg__st54,.InstrumentSteelDrums_svg__st64{stroke:#ca5672;stroke-width:.5516}.InstrumentSteelDrums_svg__st64{stroke:#cfd125;stroke-width:2.3904}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      className="InstrumentSteelDrums_svg__st53"
      d="m20.6 29.02 1.76 40 69.31 15.11"
    />
    <path
      d="m92.48 46.05-2.64-13.39c2.32 12.67-10.23 27.12-29.2 33.03-18.74 5.84-37.06 1.26-42.6-10.18l5.29 12c4.74 12.36 23.04 17.68 41.62 11.89 18.56-5.78 30.58-20.5 27.53-33.35z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      d="M47.17 19.9c15.36-4.79 31.63-1.49 39.61 5.77-6.97-9.39-23.77-12.78-40.93-7.43-19.94 6.21-32.8 21.88-28.72 34.98.25.79.55 1.56.91 2.3.7 1.44 1.6 2.78 2.68 3.99-.99-1.41-1.76-2.96-2.28-4.63-4.07-13.1 8.79-28.77 28.73-34.98z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      d="M89.84 32.66a16.764 16.764 0 0 0-3.06-7C78.8 18.4 62.53 15.11 47.17 19.9c-19.94 6.21-32.8 21.88-28.72 34.98.52 1.67 1.29 3.21 2.28 4.63 7.49 8.44 23.54 11.29 39.91 6.18 18.97-5.91 31.52-20.35 29.2-33.03z"
      style={{
        fill: "#fdcc75",
      }}
    />
    <path
      className="InstrumentSteelDrums_svg__st54"
      d="M36.04 26.19c-.31-.62-.47-.99-.47-.99s.25.32.65.89"
    />
    <path
      d="M37.46 27.86c3.01 4.45 8.19 13.16 4.53 15.62-5.88 3.95-15.43-.18-19.48-2.27"
      style={{
        fill: "none",
        stroke: "#ca5672",
        strokeWidth: 0.5516,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "2.1647,2.1647",
      }}
    />
    <path
      className="InstrumentSteelDrums_svg__st54"
      d="M21.55 40.7c-.61-.34-.95-.55-.95-.55s.38.12 1.04.36"
    />
    <path
      d="M23.68 41.28c4.97 1.98 14.35 6.41 15.71 12.17 1.45 6.14-8.76 8.1-13.38 8.67"
      style={{
        fill: "none",
        stroke: "#ca5672",
        strokeWidth: 0.5516,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "2.1879,2.1879",
      }}
    />
    <path
      className="InstrumentSteelDrums_svg__st54"
      d="M24.92 62.24c-.69.07-1.1.09-1.1.09s.39-.07 1.08-.19"
    />
    <path
      d="M27.22 61.76c5.33-.84 15.61-2.27 21-1.67 5.69.63 8.37 1.56 9.6 3.48"
      style={{
        fill: "none",
        stroke: "#ca5672",
        strokeWidth: 0.5516,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "2.3421,2.3421",
      }}
    />
    <path
      className="InstrumentSteelDrums_svg__st54"
      d="M58.33 64.64c.11.33.2.68.27 1.06 0 0-.02-.42.07-1.1"
    />
    <path
      d="M59.23 62.5c.73-1.88 2.31-4.19 5.7-5.84 4.87-2.36 12.38-3.87 16.05-4.52"
      style={{
        fill: "none",
        stroke: "#ca5672",
        strokeWidth: 0.5516,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "2.1793,2.1793",
      }}
    />
    <path
      className="InstrumentSteelDrums_svg__st54"
      d="M82.06 51.97c.69-.11 1.09-.17 1.09-.17s-.41.13-1.06.28"
    />
    <path
      d="M79.93 52.49c-2.99.42-7.03.34-7.41-3.09-.5-4.45 8.5-17.03 11.96-21.68"
      style={{
        fill: "none",
        stroke: "#ca5672",
        strokeWidth: 0.5516,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "2.1975,2.1975",
      }}
    />
    <path
      className="InstrumentSteelDrums_svg__st54"
      d="M85.14 26.84c.42-.56.67-.87.67-.87s-.18.36-.53.96"
    />
    <path
      d="M84.09 28.81c-2.78 4.12-8.74 11.06-16.42 9.13-5.41-1.35-4.01-12.49-3.14-17.28"
      style={{
        fill: "none",
        stroke: "#ca5672",
        strokeWidth: 0.5516,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "2.2245,2.2245",
      }}
    />
    <path
      className="InstrumentSteelDrums_svg__st54"
      d="M64.74 19.56c.14-.68.23-1.07.23-1.07s-.01.4-.06 1.1"
    />
    <path
      d="M64.69 21.77c-.65 4.99-2.81 14.13-9.85 16.23-9.78 2.42-16-6.67-18.28-10.84"
      style={{
        fill: "none",
        stroke: "#ca5672",
        strokeWidth: 0.5516,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "2.2045,2.2045",
      }}
    />
    <ellipse
      transform="rotate(-19.814 55.826 51.065)"
      cx={55.83}
      cy={51.06}
      rx={6.7}
      ry={4.44}
      style={{
        fill: "none",
        stroke: "#ca5672",
        strokeWidth: 0.5182,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeDasharray: 2.073,
      }}
    />
    <path
      className="InstrumentSteelDrums_svg__st53"
      d="m91.67 84.13 1.28-44.67"
    />
    <path
      className="InstrumentSteelDrums_svg__st64"
      d="m50.57 94.18-31.82-4.49"
    />
    <path
      className="InstrumentSteelDrums_svg__st5"
      d="M19.23 87.22c-1.38-.19-2.65.76-2.84 2.14s.76 2.65 2.14 2.84c1.38.19 2.65-.76 2.84-2.14.19-1.37-.77-2.65-2.14-2.84z"
    />
    <path
      className="InstrumentSteelDrums_svg__st64"
      d="M50.44 97.32 20.87 84.74"
    />
    <path
      className="InstrumentSteelDrums_svg__st5"
      d="M21.98 82.48a2.516 2.516 0 1 0 1.33 3.3c.54-1.28-.06-2.76-1.33-3.3z"
    />
  </svg>
);

export default InstrumentSteelDrums;

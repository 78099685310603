import { useEffect, useState } from "react";
import { Trans as Translate } from 'react-i18next';
import { useDispatch } from "react-redux";
import { usersActions } from "../../store/usersSlice";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

const MODAL_HEADERS = {
  password      : 'Update Password',
  email         : 'Update Email',
  display_name  : 'Update Display Name',
  emailVerified : 'Verify Your Email',
}

// working fields -> 'password', 'email'
export default function UpdateProfileModal({ show, field, onHide, onUpdateSuccess, body }) {

  // < password, confirm, email and displayName > are set to undefined (instead of '') so that they are not
  // accidentally included in the API request (IE: { user: email: '' }))
  const [currentPassword, setCurrentPassword] = useState('');        // current user password ("old" password if updating password)
  const [password, setPassword] = useState(undefined);               // new password that user wants to set
  const [confirmPassword, setConfirmPassword] = useState(undefined); // confirmation of new password (password === confirmPassword)
  const [email, setEmail] = useState(undefined);
  const [displayName, setDisplayName] = useState(undefined);
  const [error, setError] = useState(false);
  const [updating, setUpdating] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      setPassword(undefined);
      setConfirmPassword(undefined);
      setEmail(undefined);
      setDisplayName(undefined);
      setError(false);
    }
  }, [show]);

  const onChangeCurrentPassword = e => {
    setCurrentPassword(e.target.value);
  }

  const onChangePassword = e => {
    setPassword(e.target.value);
  }

  const onChangeConfirmPassword = e => {
    setConfirmPassword(e.target.value);
  }

  const onChangeEmail = e => {
    setEmail(e.target.value);
  }

  const onChangeDisplayName = e => {
    setError(null);

    const name = e.target.value.replace(' ', '_');
    setDisplayName(name.replace(/[^A-Za-z0-9_-]/g, ''));
  }

  const onUpdate = async e => {
    e.preventDefault();
    
    const display_name = displayName?.trim();

    if (field === 'password' && password !== confirmPassword) {
      setError('confirm-password-error');
      return;
    }

    if (field === 'display_name' && display_name?.length === 0) {
      setError('display-name-blank-error');
      return;
    }

    const user = {
      password,
      email,
      display_name,
    };

    setUpdating(true);
    
    try {
      await dispatch(usersActions.updateFields({ user, current_password: currentPassword })).unwrap();
      onHide();
      onUpdateSuccess();
    }
    catch (error) {
      // 403, 401, 500, etc
      
      if (field === 'display_name') {
        setError('display-name-taken');
      }
      
      else if (field === 'email' && error.message.includes("422")) {
        setError('email-taken');
      }

      else {
        setError('current-password-error');
      }
    }

    setUpdating(false);
  }

  const renderPasswordField = () => (
    <>
      <div className='mt-2'><Translate>{ field === 'password' ? 'Current password' : 'Confirm your password' }</Translate></div>
      <Form.Control type='password' className='mb-2' minLength={6} maxLength={20} disabled={updating} value={currentPassword} onChange={onChangeCurrentPassword} />
    </>
  );

  const renderUpdateField = () => {
    if (field === 'password') {
      return (
        <>
          <div className='mt-2'><Translate>New password</Translate></div>
          <Form.Control type='password' required minLength={6} maxLength={20} className='mb-2' disabled={updating} value={password || ''} onChange={onChangePassword} />

          <div className='mt-2'><Translate>Confirm new password</Translate></div>
          <Form.Control type='password' required minLength={6} maxLength={20} className='mb-2' disabled={updating} value={confirmPassword || ''} onChange={onChangeConfirmPassword} />
        </>
      );
    }

    else if (field === 'email') {
      return (
        <>
          <div className='mt-2'><Translate>New email address</Translate></div>
          <Form.Control type='email' required className='mb-2' disabled={updating} value={email || ''} autoComplete='off' onChange={onChangeEmail} />
        </>
      );  
    }

    else if (field === 'display_name') {
      return (
        <>
          { body
          ? <div className='mb-2'>{ body }</div>
          : <div className='mt-2'><Translate>Display name</Translate></div>
          }
          <Form.Control required className='mb-2' disabled={updating} value={displayName || ''} autoComplete='off' autoCorrect='off' onChange={onChangeDisplayName} maxLength={32} minLength={3} />
        </>
      );  
    }
  }

  return (
    <Modal className='__modal __update-profile-modal' show={show} backdrop='static' size='sm'>

      <Modal.Header>
        <Translate>{MODAL_HEADERS[field]}</Translate>
      </Modal.Header>

      <Form onSubmit={onUpdate}>
        <Modal.Body>
          
          { field === 'password' && renderPasswordField() }
          { renderUpdateField() }
          { field !== 'password' && field !== 'display_name' && renderPasswordField() }

          { error && (
            <Alert variant='warning'>
              <Translate i18nKey={error} />
            </Alert>
          )}

          { updating && <p><Translate>Updating profile...</Translate></p> }
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide} disabled={updating}><Translate>Close</Translate></Button>
          <Button variant="primary" type="submit" disabled={updating}><Translate>Update</Translate></Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
export default function SlideMarketValidationBG(props) {
  return (
    <svg
      width={220}
      height={220}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 220 220"
      {...props}
    >
      <path
        d="m145.1 143-78.2 77H199c11.1 0 20.1-9 20.1-20.1v-90.7l-74 33.8Z"
        fill="#C95671"
      />
      <path
        d="M2.7 17.8C1 20.7 0 24.1 0 27.7v167.2C0 206 9 215 20.1 215h35.2l78.2-77L2.7 17.8Z"
        fill="#F18860"
      />
      <path
        d="M197.5 0H30.3C25 0 20.1 2.1 16.5 5.5c-1.4 1.4-2.6 2.9-3.6 4.6l130.9 120.3 73.9-33.8V20.1c-.1-11-9.2-20.1-20.2-20.1Z"
        fill="#CECF25"
      />
    </svg>
  );
}

import * as React from "react";

const InstrumentSlapBass2 = (props) => (
  <svg
    id="InstrumentSlapBass2_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentSlapBass2_svg__st2{fill:#fdcc75}.InstrumentSlapBass2_svg__st3{fill:#4e4092}.InstrumentSlapBass2_svg__st5{fill:#cfd125}.InstrumentSlapBass2_svg__st6{fill:#ca5672}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      className="InstrumentSlapBass2_svg__st6"
      d="M91.31 93.47c2.16-1.57 4.15-3.4 6.06-5.27 1.69-1.64 3.32-3.55 3.65-5.97.62-4.64-3.23-8.33-7.26-9.85-1.59-.6-3.31-.81-4.94-1.25-1.64-.45-3.32-1.22-4.58-2.43-1.51-1.45-1.94-3.35-2.79-5.2-1.2-2.61-2.94-5-5.09-6.92-1.52-1.36-4.42-3.37-6.5-2.07-1.29.8-.6 2.2.01 3.2.74 1.22 1.69 2.32 1.42 3.87-.23 1.34-1.38 2.67-2.83 2.44-1.31-.21-1.82-.68-1.82-.68l-7.56.93s.37 1.12-.51 1.89c-.88.78-1.68.73-2.85.18-1.98-.93-3.3-2.94-5.32-3.77-1.16-.48-2.46-.03-2.97 1.14-1.23 2.84 1.47 5.96 3.34 7.79 2.56 2.5 5.68 3.83 8.71 5.65 2.91 1.76 3.92 4.96 4.97 8.04 0 0 1.79 8.89 7.67 12.02 2.38 1.26 5.1 1.76 7.77 1.45 2.78-.32 5.26-1.54 7.68-2.86 1.29-.7 2.55-1.47 3.74-2.33z"
    />
    <path
      className="InstrumentSlapBass2_svg__st2"
      d="M71.08 56.12c-.03.21.7 1.32.97 1.72.62.92.94 2.05.92 3.16-.03 2.09-1.35 3.85-3.18 4.72-2.59 1.24-5.56 1.37-8.38 1.5-2.3.11-2.26.83-2.26.83.02.93 1.09 1.33 1.78 1.69 1.24.65 2.36 1.38 3.28 2.45 1.91 2.21 2.58 5.14 3.59 7.81.71 1.87 2.32 3.48 4.4 3.59 1.63.08 3-.9 4.21-1.85 1.24-.98 2.44-2.04 3.84-2.79.85-.46 1.72-.89 2.61-1.28 0 0-.96-2.88-.87-3.62.09-.73 2.13-3.05 2.13-3.05-2.3-1.43-3.5-5.27-3.5-5.27-.87-2.78-2.91-5.74-5.08-7.68-.44-.42-4.32-3.04-4.46-1.93z"
    />
    <path
      className="InstrumentSlapBass2_svg__st3"
      d="m85.49 81.63-6.02 5.51c-.31.28-.78.26-1.06-.05l-.48-.53a.746.746 0 0 1 .05-1.06l6.01-5.5c.31-.28.78-.26 1.06.05l.48.53c.29.3.26.77-.04 1.05zM90.83 89.16l-4.21 3.85c-.47.43-1.19.39-1.61-.07l-1.63-1.78c-.43-.47-.39-1.19.07-1.61l4.21-3.85c.47-.43 1.19-.39 1.61.07l1.63 1.78c.43.46.4 1.18-.07 1.61zM79.12 74.92l-6.02 5.51c-.31.28-.78.26-1.06-.05l-.48-.53a.746.746 0 0 1 .05-1.06l6.02-5.51c.31-.28.78-.26 1.06.05l.48.53c.27.3.25.78-.05 1.06z"
    />
    <circle
      className="InstrumentSlapBass2_svg__st5"
      cx={84.13}
      cy={74.66}
      r={1.2}
    />
    <circle
      className="InstrumentSlapBass2_svg__st5"
      cx={87.73}
      cy={75.3}
      r={1.01}
    />
    <circle
      className="InstrumentSlapBass2_svg__st5"
      cx={91.11}
      cy={76.04}
      r={0.93}
    />
    <path
      d="M65.04 70.6c.2.22.55.24.77.03l3.81-3.49c.23-.21.24-.57.02-.79L26.73 23.69l-2.71 2.65L65.04 70.6z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      className="InstrumentSlapBass2_svg__st6"
      d="M11.62 6.72c-1.09.68-2.3 2.19-1.62 3.77.68 1.58 9.46 15.86 9.46 15.86s.83.68 1.39.41 1.21-.9 1.96-.87c.75.04 1.21.44 1.21.44l2.71-2.65s-3.05-2.69-1.7-5.62c0 0-1.62-2.11-4.86-3.62-3.24-1.51-3.77-2.67-3.77-3.47 0-.79.53-2.22-.49-3.39-1.01-1.16-3.2-1.53-4.29-.86z"
    />
    <circle
      className="InstrumentSlapBass2_svg__st2"
      cx={16.52}
      cy={26.02}
      r={1.36}
    />
    <circle
      className="InstrumentSlapBass2_svg__st2"
      cx={13.76}
      cy={21.56}
      r={1.36}
    />
    <circle
      className="InstrumentSlapBass2_svg__st2"
      cx={11.16}
      cy={17.32}
      r={1.36}
    />
    <circle
      className="InstrumentSlapBass2_svg__st2"
      cx={9.07}
      cy={13.25}
      r={1.36}
    />
  </svg>
);

export default InstrumentSlapBass2;

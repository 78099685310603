import * as React from "react";

const InstrumentUprightBass = (props) => (
  <svg
    id="InstrumentUprightBass_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentUprightBass_svg__st3{fill:#4e4092}.InstrumentUprightBass_svg__st4{fill:#f18860}.InstrumentUprightBass_svg__st30{fill:none;stroke:#fff;stroke-width:1.7807;stroke-linecap:round;stroke-miterlimit:10}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#cfd125",
      }}
    />
    <path
      className="InstrumentUprightBass_svg__st4"
      d="M64.19 76.22s-7.3-3.98-1.99-12.08 9.66-3.71 9.66-3.71l3.21-3.54c5.54-6.1 4.46-15.78-2.34-21.01l-3.72-2.87-3.72-2.87c-6.8-5.23-16.43-3.79-20.91 3.12l-2.6 4.01s5.36 3.09-1.11 10.29-12.19 1.16-12.19 1.16S6.5 64.77 30.4 83.17s33.79-6.95 33.79-6.95z"
    />
    <path
      className="InstrumentUprightBass_svg__st3"
      d="M37.1 67.59 17.71 98.06l1.54 1.19 24.5-26.55-3.33-2.55zM60.13 53.25l27.94-41.56-1.66-1.28-1.66-1.28-33.03 37.64 4.2 3.24z"
    />
    <circle
      className="InstrumentUprightBass_svg__st4"
      cx={88.02}
      cy={8.32}
      r={3.53}
    />
    <circle
      className="InstrumentUprightBass_svg__st4"
      cx={17.62}
      cy={99.77}
      r={2.41}
    />
    <path
      className="InstrumentUprightBass_svg__st30"
      d="m74.45 17.24 8.41 6.48M77.01 19.21 35.08 73.68M80 21.51 38.07 75.98"
    />
    <path
      className="InstrumentUprightBass_svg__st3"
      d="M42.63 53.71c.01-.04.02-.08.01-.14-.01-.04-.02-.08-.04-.13-.01-.03-.01-.05-.02-.08-.02-.03-.05-.06-.07-.08-.01-.01-.01-.02-.02-.03l-.02-.02-.03-.03c-1.22-1.36-2.86-.43-4.07.41-.52.36-1.07.72-1.65.97-.26.11-.53.2-.79.29-.06.02-.35.07-.39.13-.77 1.28-1.57 2.53-2.92 3.26-.9.49-2.24.92-3.02.01.08-.02.16-.06.23-.12.22-.2.23-.55.02-.76a.529.529 0 0 0-.76-.02c-.04.04-.06.08-.09.11-.01.01-.01.02-.02.04s-.02.03-.02.05c-.41.77.28 1.47 1.08 1.7 1.04.3 2.1-.31 3.01-.72 3.23-1.47 3.86-1.6 3.87-1.6-.03-.01.33-.68.37-.74a9.61 9.61 0 0 1 2.11-2.36c.42-.34.92-.78 1.46-.88.35-.06.64.03.93.22-.06-.04-.2.3-.21.34-.02.13-.01.25.06.37.14.25.47.33.72.2.15-.08.24-.23.27-.39zM55.97 63.07c.03-.03.07-.04.13-.05.04-.01.09 0 .13 0 .03 0 .05-.01.08 0 .04.01.07.03.1.04.01 0 .02.01.03.01.01.01.02.01.03.02.01.01.03.01.04.02 1.65.79 1.21 2.62.74 4.02-.2.6-.39 1.23-.47 1.86-.03.28-.04.56-.06.84 0 .07.03.36-.01.41-1.01 1.09-1.99 2.22-2.31 3.71-.21 1-.25 2.41.84 2.9 0-.09.01-.17.05-.25.14-.27.46-.37.73-.23.27.14.37.46.23.73-.02.05-.06.08-.08.12l-.03.03c-.01.01-.03.02-.04.03-.62.61-1.49.14-1.94-.56-.58-.92-.29-2.11-.16-3.09.51-3.51.45-4.16.45-4.16.01.02.56-.51.6-.56.27-.3.52-.62.74-.96.37-.54.68-1.13.93-1.73.2-.5.49-1.1.43-1.65-.04-.35-.21-.6-.47-.83.06.05-.23.28-.27.3-.11.06-.24.08-.37.05a.533.533 0 0 1-.39-.63c.06-.19.17-.32.32-.39z"
    />
  </svg>
);

export default InstrumentUprightBass;

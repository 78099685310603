export default function LPFigures2(props) {
  return (
    <svg x={0} y={0} viewBox="0 0 360 522" xmlSpace="preserve" {...props}>
      <style>{".__figures-2{fill:#4a3f8c}"}</style>
      <path fill="#f08660" d="M175.9 48.4H297v175.1H175.9z" />
      <circle className="__figures-2" cx={111} cy={119} r={84} />
      <circle cx={251} cy={246} r={34} fill="#cecf25" />
      <path
        className="__figures-2"
        d="M231 414c0 29.2 14.9 55 37.6 70 8.5 5.7 18.1 9.8 28.4 12V332c-37.7 8.2-66 41.8-66 82z"
      />
  </svg>
  );
}

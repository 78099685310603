import * as React from "react";

const InstrumentPiccolo = (props) => (
  <svg
    id="InstrumentPiccolo_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.1 110.39"
    style={{
      enableBackground: "new 0 0 110.1 110.39",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".InstrumentPiccolo_svg__st3{fill:#4e4092}"}</style>
    
    <path
      d="M93.54 110.1H16.56C7.41 110.1 0 102.69 0 93.54V16.56C0 7.41 7.41 0 16.56 0h76.98c9.15 0 16.56 7.41 16.56 16.56v76.98c0 9.15-7.41 16.56-16.56 16.56z"
      style={{
        fill: "#fdcc75",
      }}
    />
    <path
      d="m74.21 36.62-2.78-2.83c-.5-.51-.49-1.32.02-1.82L92.37 11.4c.51-.5 1.32-.49 1.82.02l2.78 2.83c.5.51.49 1.32-.02 1.82L76.02 36.64c-.5.5-1.32.49-1.81-.02z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      d="m11.63 98.39-3.02-3.07c-.87-.89-.86-2.31.03-3.19l53.34-52.45c.89-.87 2.31-.86 3.19.03l3.02 3.07c.87.89.86 2.31-.03 3.19L14.81 98.42c-.88.87-2.31.86-3.18-.03z"
      style={{
        fill: "#ca5672",
        stroke: "#ca5672",
        strokeWidth: 0.1794,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m69.94 32.71-1.94.72c-.14.05-.27.13-.37.24l-3.75 3.69a1.06 1.06 0 0 0-.01 1.5l5.16 5.25c.41.42 1.08.42 1.5.01l3.82-3.76c.11-.11.2-.24.25-.39l.69-1.85c.14-.39.05-.82-.24-1.11l-3.97-4.04c-.31-.3-.75-.4-1.14-.26z"
      style={{
        fill: "#f18860",
      }}
    />
    <circle
      className="InstrumentPiccolo_svg__st3"
      cx={55.71}
      cy={52.03}
      r={2.43}
    />
    <circle
      className="InstrumentPiccolo_svg__st3"
      cx={50.31}
      cy={57.34}
      r={2.43}
    />
    <circle
      className="InstrumentPiccolo_svg__st3"
      cx={44.9}
      cy={62.65}
      r={2.43}
    />
    <circle
      className="InstrumentPiccolo_svg__st3"
      cx={24.57}
      cy={93.58}
      r={2.43}
    />
    <circle
      className="InstrumentPiccolo_svg__st3"
      cx={19.55}
      cy={76.63}
      r={2.43}
    />
    <circle
      className="InstrumentPiccolo_svg__st3"
      cx={24.17}
      cy={72.09}
      r={2.43}
    />
    <circle
      className="InstrumentPiccolo_svg__st3"
      cx={28.79}
      cy={67.55}
      r={2.43}
    />
  </svg>
);

export default InstrumentPiccolo;

export default function SlideProblemRoadmap(props) {
  return (
    <svg
    width={51}
    height={51}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.8 50.8c11.598 0 21-9.402 21-21s-9.402-21-21-21-21 9.402-21 21 9.402 21 21 21Z"
      fill="#FCCA75"
    />
    <path
      d="M5.2 29.8H0c0-8 3.1-15.5 8.7-21.1C14.3 3.1 21.8 0 29.8 0v5.2c-13.5-.1-24.6 11-24.6 24.6Z"
      fill="#C85671"
    />
  </svg>
  );
}

import * as React from "react"

export default function MoodChillIcon(props) {
  return (
    <svg viewBox="0 0 360 360" {...props}>
      <style>
        {
          ".__chill-icon-st1,.__chill-icon-st3{stroke:#fff;stroke-linecap:round;stroke-miterlimit:10}.__chill-icon-st1{fill:none;stroke-width:35;stroke-linejoin:round}.__chill-icon-st3{fill:#fff;stroke-width:12}"
        }
      </style>
      <path
        d="M293.2 359H67.5C33 359 4.7 330.8 4.7 296.2V62.9C4.7 28.3 33 0 67.5 0h225.6C327.7 0 356 28.3 356 62.9v233.3c0 34.6-28.3 62.8-62.8 62.8z"
        fill="#fbc975"
      />
      <path
        className="__chill-icon-st1"
        d="M288.1 44.6l33.5 16.5M92.3 44.5L42.4 66.1"
      />
      <path
        d="M221.1 288.6s57.4 11.8 85.5-48.5"
        fill="none"
        stroke="#fff"
        strokeWidth={30}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        className="__chill-icon-st3"
        d="M24.6 113.2H160S170.6 201 92.3 201s-67.7-87.8-67.7-87.8zM200.7 113.2h135.4s10.6 87.8-67.7 87.8-67.7-87.8-67.7-87.8z"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeWidth={12}
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M160 113.2h40.7"
      />
    </svg>
  );
}

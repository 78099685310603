export default function AnimeOutlineNoStars(props) {
  return (
    <svg x={0} y={0} viewBox="0 0 110.8 112.6" style={{ enableBackground: "new 0 0 110.8 112.6" }} xmlSpace="preserve" {...props}>
      <style>
        {
          ".__anime-outline-0{fill:#cfd4d7}.__anime-outline-1{fill:none;stroke:#cfd4d7;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.__anime-outline-3{fill:#fff}.__anime-outline-5{fill:#cfd4d7;stroke:#fff;stroke-width:.75;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="__anime-outline-0"
        d="M94.1 2.9c8.1 0 14.7 6.6 14.7 14.7V95c0 8.1-6.6 14.7-14.7 14.7H16.7C8.6 109.7 2 103.1 2 95V17.6C2 9.5 8.6 2.9 16.7 2.9h77.4m0-2H16.7C7.4.9 0 8.3 0 17.6V95c0 9.2 7.5 16.7 16.7 16.7h77.5c9.2 0 16.7-7.5 16.7-16.7V17.6C110.8 8.3 103.4.9 94.1.9z"
      />
      <circle className="__anime-outline-1" cx={57.6} cy={56.1} r={43.1} />
      <circle className="__anime-outline-1" cx={57.6} cy={56.4} r={21.2} />
      <path
        d="M63.3 62.4s.2 3.6-2.8 4.1c-3 .5-3.8-2.9-3.8-2.9l6.6-1.2z"
        style={{
          fill: "#cfd4d7",
          stroke: "#cfd4d7",
          strokeMiterlimit: 10,
        }}
      />
      <path
        className="__anime-outline-3"
        d="M58.5 66.2s.1-1.6 1.6-1.9c1.5-.3 2.3 1.1 2.3 1.1s-.3.7-1.8 1c-1.6.3-2.1-.2-2.1-.2z"
      />
      <path
        className="__anime-outline-0"
        d="M47.2 67.4s3 .1 5-2.5c0 0 3.1.2 3.6 1.1 0 0-2.3-.2-3.3 0l1 1.6s-1.6-.9-2.8-.7l-.4 1.2-3.1-.7z"
      />
      <path
        className="__anime-outline-0"
        d="M38.5 58.5c.7 5.5 4.9 9.6 9.4 9 2.7-.3 4.9-2.3 6-5 .8-1.8 1.1-3.9.8-6.1-.7-5.5-4.9-9.6-9.4-9s-7.5 5.6-6.8 11.1z"
      />
      <path
        className="__anime-outline-3"
        d="M48.1 48.8c.7.2 1.5.6 2.1 1.2 1.4 1.2 2.7 3.6 2.5 5.6-.2 1.7-2.4.7-3.3.3-1.7-.8-3.8-1.8-5-3.5-1.2-1.7-.1-3.2 1.5-3.6.8-.2 1.5-.2 2.2 0z"
      />
      <path
        className="__anime-outline-3"
        d="M46.8 53.5c-.4 0-.9.1-1.2.3-.9.6-1.6 2.4-.6 3.2 1.1.8 2.4-.1 2.4-.1.4-.3.8-1.3.9-1.8.2-1.1-.6-1.6-1.5-1.6z"
      />
      <path
        className="__anime-outline-0"
        d="M38.8 52.9c.8-1.2 1.3-2.6 2.4-3.7 1.2-1.2 2.7-2.1 4.3-2.7 2.3-.8 4.9 0 7.1-.8 1.2-.4 1.9-1.7 2.6-2.7 0 0 .6 1.5-1.2 3.2 0 0 1.8.2 3-1.9 0 0 .6 2.5-2.5 3.5 0 0 2.5 2.6 1.9 4 .1-.3-.8-1.4-1.9-2-1.1-.6-3.1-1.5-7.5-1.1s-7.3 3-8.2 4.2z"
      />
      <path
        className="__anime-outline-3"
        d="M49.5 58.9c.1-.1.2-.1.4-.1.4 0 1.9.1 1.8.7-.1.8-1.1 1.5-1.7 1.5-1.2.1-1.2-1.6-.5-2.1z"
      />
      <path
        className="__anime-outline-0"
        d="M39.6 51.7s-1.8 1.3-3.6.9c0 0 1.7 1.7 2.4 1.3.7-.4 1.2-2.2 1.2-2.2zM71.1 61.4s3 .1 5-2.5c0 0 3.1.2 3.6 1.1 0 0-2.3-.2-3.3 0l1 1.6s-1.6-.9-2.8-.7l-.4 1.2-3.1-.7z"
      />
      <path
        className="__anime-outline-0"
        d="M62.4 52.5c.7 5.5 4.9 9.6 9.4 9 2.7-.3 4.9-2.3 6-5 .8-1.8 1.1-3.9.8-6.1-.7-5.5-4.9-9.6-9.4-9s-7.5 5.5-6.8 11.1z"
      />
      <path
        className="__anime-outline-3"
        d="M72 42.8c.7.2 1.5.6 2.1 1.2 1.4 1.2 2.7 3.6 2.5 5.6-.2 1.7-2.4.7-3.3.3-1.7-.8-3.8-1.8-5-3.5-1.2-1.7-.1-3.2 1.5-3.6.7-.3 1.5-.2 2.2 0z"
      />
      <path
        className="__anime-outline-3"
        d="M70.7 47.4c-.4 0-.9.1-1.2.3-.8.6-1.6 2.3-.6 3.1 1.1.8 2.4-.1 2.4-.1.4-.3.8-1.3.9-1.8.2-1-.7-1.5-1.5-1.5z"
      />
      <path
        className="__anime-outline-0"
        d="M62.7 46.9c.8-1.2 1.3-2.6 2.4-3.7 1.2-1.2 2.7-2.1 4.3-2.7 2.3-.8 4.9 0 7.1-.8 1.2-.4 1.9-1.7 2.6-2.7 0 0 .6 1.5-1.2 3.2 0 0 1.8.2 3-1.9 0 0 .6 2.5-2.5 3.5 0 0 2.5 2.6 1.9 4 .1-.3-.8-1.4-1.9-2-1.1-.6-3.1-1.5-7.5-1.1-4.4.5-7.3 2.9-8.2 4.2z"
      />
      <path
        className="__anime-outline-3"
        d="M73.4 52.8c.1-.1.2-.1.4-.1.4 0 1.9.1 1.8.7-.1.8-1.1 1.5-1.7 1.5-1.2.2-1.2-1.5-.5-2.1z"
      />
      <path
        className="__anime-outline-0"
        d="M63.4 45.6s-1.8 1.3-3.6.9c0 0 1.7 1.7 2.4 1.3.8-.3 1.2-2.2 1.2-2.2z"
      />
      <path className="__anime-outline-1" d="M72.6 21.8s10.3 2.6 15 12.4" />
      <path
        d="M91.3 74.9s-2.7 9.7-12.9 14.5"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 2,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <path
        className="__anime-outline-1"
        d="M74 26.5s5.1 1 8.1 6.7M35.4 86.8s-8.9-4-13-14.5M34.7 82s-4.8-2.2-7.1-7.9"
      />
    </svg>
  )
}

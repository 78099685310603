import React from 'react';
// import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Checkbox.scss';

export default function Checkbox({ children, innerRef, checked, onChange, disabled, className, togglePosition = 'start' }) {
  
  const classes = classnames('__checkbox', { '__checkbox-disabled': disabled }, className);

  const toggle = (
    <div className='__checkbox-toggle'>
      <input type="checkbox" className='__checkbox-check' checked={checked} onChange={onChange} disabled={disabled} />
      <span className='__checkbox-slider' />
    </div>
  );

  return (
    <label className={classes} ref={innerRef}>
      { togglePosition === 'start' && toggle }
      { children && <span className='__checkbox-text'>{ children }</span> }
      { togglePosition === 'end' && toggle }
    </label>
  );
}

// Checkbox.propTypes = {
//   checked        : PropTypes.bool,
//   className      : PropTypes.string,
//   children       : PropTypes.node,
//   onChange       : PropTypes.func,

//   // Determine where to show the toggle, on the start (left-side on LTR) or end (right-side on LTR):
//   togglePosition : PropTypes.oneOf(['start', 'end']),
// }

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";
import AbTests from '../assets/ab_tests.png';

export default function SlideProductTests() {
  return (
    <div className='slide slide-product-abtests white-bg'>
      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      <img className='screenshots' src={AbTests} alt='Product - AB Tests' />
    </div>
  )
}
import * as React from "react";

const InstrumentGlockenspiel = (props) => (
  <svg
    id="InstrumentGlockenspiel_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 111.14"
    style={{
      enableBackground: "new 0 0 110.84 111.14",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentGlockenspiel_svg__st4{fill:#f18860}.InstrumentGlockenspiel_svg__st5{fill:#cfd125}.InstrumentGlockenspiel_svg__st6{fill:#ca5672}.InstrumentGlockenspiel_svg__st13{fill:none}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentGlockenspiel_svg__st13"
      d="M95.35 51.64v23.73l3.29-.3V51.96zM87.26 50.86v25.26l3.29-.31V51.18zM30.66 45.37v35.94l3.29-.3V45.69zM22.58 44.58v37.47l3.28-.3V44.9zM58.21 48.04l-1.2-.12 1.2.12zM79.18 50.07l-6.84-.66 2.04.2zM65.63 48.76 63 48.51v29.83l2.63-.24V49.02zM79.18 50.07v26.79l3.28-.3V50.39zM57.01 47.92l-2.09-.2zM58.21 48.04l-1.2-.12-2.09-.2v31.37l3.29-.31V48.22zM46.83 46.94v32.89l3.29-.3V47.26zM38.75 46.15v34.42l3.28-.3v-33.8zM72.34 49.41l-1.92-.18z"
    />
    <path
      className="InstrumentGlockenspiel_svg__st13"
      d="m72.34 49.41-1.92-.18v28.43l3.96-.36V49.61z"
    />
    <path d="m54.92 47.72-4.8-.46 4.8.46zM66.06 48.8l-.43-.04z" />
    <path
      className="InstrumentGlockenspiel_svg__st5"
      d="M10.45 43.24h4.98v39.93h-4.98z"
    />
    <path
      className="InstrumentGlockenspiel_svg__st5"
      d="M15.33 50.07v4.15l83.31 4.59v-3.68zM15.33 72.44v3.57l83.31-4.59v-4.03zM102.72 52.65h-4.08v2.48l4.08.25zM98.64 73.77h4.08V71.2l-4.08.22zM98.64 67.39l4.08-.25v-8.11l-4.08-.22z"
    />
    <path
      className="InstrumentGlockenspiel_svg__st5"
      d="M98.64 55.13v3.68l4.08.22v-3.65zM98.64 71.42l4.08-.22v-4.06l-4.08.25z"
    />
    <path
      className="InstrumentGlockenspiel_svg__st6"
      d="M58.21 48.22v30.56l4.79-.44V48.51l-4.79-.47zM65.63 48.76V78.1l4.79-.46V49.23l-4.36-.43zM17.78 82.49l4.8-.44V44.58l-4.8-.46zM25.86 81.75l4.8-.44V45.37l-4.8-.47zM33.95 81.01l4.8-.44V46.15l-4.8-.46zM90.55 51.18v24.63l4.8-.44V51.64zM42.03 80.27l4.8-.44V46.94l-4.8-.47zM50.12 79.53l4.8-.44V47.72l-4.8-.46zM74.38 77.3l4.8-.44V50.07l-4.8-.46zM82.46 76.56l4.8-.44V50.86l-4.8-.47z"
    />
    <path
      className="InstrumentGlockenspiel_svg__st5"
      d="M84.32 31.88H46.09c-.49 0-.88-.39-.88-.88v-.55c0-.49.39-.88.88-.88h38.22c.49 0 .88.39.88.88V31c.01.49-.39.88-.87.88z"
    />
    <circle
      className="InstrumentGlockenspiel_svg__st4"
      cx={87.11}
      cy={30.55}
      r={3.13}
    />
    <path
      className="InstrumentGlockenspiel_svg__st5"
      d="M51.14 39.61h38.22c.49 0 .88-.39.88-.88v-.55c0-.49-.39-.88-.88-.88H51.14c-.49 0-.88.39-.88.88v.55c-.01.49.39.88.88.88z"
    />
    <circle
      className="InstrumentGlockenspiel_svg__st4"
      cx={48.34}
      cy={38.28}
      r={3.13}
    />
  </svg>
);

export default InstrumentGlockenspiel;

import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useHistory, Link } from 'react-router-dom';
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { Trans as Translate } from 'react-i18next';
import { usersSelectors } from "../../store/usersSlice";
import { useDebouncedCallback } from 'use-debounce/lib';
import classNames from "classnames";
import useInterval from "../../hooks/useInterval";
import { strofeApi } from "../../api/strofeApi";
import UserEvents from "../../utils/UserEvents";
import { EventTracker } from "../../utils/Tracking";

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Toast from "react-bootstrap/Toast";
import Modal from "react-bootstrap/Modal";
import Countdown from "../../layout/Countdown";

import NavigationHeader from "../NavigationHeader";
import VerifyEmailModal from '../Settings/VerifyEmailModal';
import UpdateProfileModal from '../Settings/UpdateProfileModal';
import PaymentModal from "./PaymentModal";
import RegistrationModal from "../../registration/RegistrationModal";
import DownloadTrackFlow from "../../modals/Download/DownloadTrackFlow";

import StrofeCoin from "../../icons/coins/StrofeCoin";

import AnimaySaleAvatar from "../../icons/art/AnimaySaleAvatar";
import AnimaySaleStar from "../../icons/art/AnimaySaleStar";
// import SummerSaleIceCream from "../../icons/art/SummerSaleIceCream";
// import SummerSaleSun from "../../icons/art/SummerSaleSun";
// import WinterSaleSnow from "../../icons/art/WinterSaleSnow";

import './GetCoins.scss';
import { Redirect } from "react-router-dom";

const COIN_TIERS = [
  { coins: 400, price: '$4.99', salePrice: '$2.49' },
  { coins: 800, price: '$9.99', salePrice: '$4.99' },
  { coins: 1700, price: '$19.99', salePrice: '$9.99' },
];

const COIN_TIERS_2X = [
  { coins: 800, price: '$4.99', salePrice: '$2.49' },
  { coins: 1600, price: '$9.99', salePrice: '$4.99' },
  { coins: 3400, price: '$19.99', salePrice: '$9.99' },
];

const QUESTIONS = [
  {
    question : 'What are Strofe coins?',
    answer   : 'faq-what-are-coins',
  },

  {
    question : 'How much does a track cost?',
    answer   : 'faq-track-cost',
  },

  {
    question : 'Can I edit a purchased track?',
    answer   : 'faq-edit-track',
  },

  {
    question : 'Can I use a track for commercial purposes?',
    answer   : 'faq-commercial-purpose',
  },

  {
    question : 'How long do I own a track for?',
    answer   : 'faq-ownership'
  },

  {
    question : 'Do Strofe Coins expire?',
    answer   : 'faq-coins-expire'
  },

  {
    question : 'How does Strofe use AI to create compositions?',
    answer   : 'faq-strofe-ai'
  },

  {
    question : 'Are all tracks unique?',
    answer   : 'faq-tracks-unique'
  },

  {
    question : 'Will tracks change over time?',
    answer   : 'faq-updates'
  },
]


/* V2 Events 'get_coins'
  ----------------------
  click_purchase_[400,800,1700]
  get_coins.click_purchase_$[4.99, 9.99, 19.99] <- for 2x coins test
  click_create_acount
  click_verify_email

  copy_referrer_link
  purchase_successful
  verified_email
  create_song
*/

export default function GetCoins() {
  const currentUser = useSelector(usersSelectors.getCurrentUser);
  const abTests = useSelector(usersSelectors.getAbTests);

  const referrerLink = `${process.env.NODE_ENV === 'development' ? 'http://localhost:3210' : 'https://strofe.com'}/referrer/${currentUser.display_name}`;

  const [showPayment, setShowPayment] = useState(false);
  const [showPaymentAfterRegister, setShowPaymentAfterRegister] = useState(false);
  const [showPaymentSuccess, setShowPaymentSucces] = useState(false);
  const [pricingTier, setPricingTier] = useState(null);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [showVerifyToast, setShowVerifyToast] = useState(false);
  const [showSetDisplayName, setShowSetDisplayName] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [song, setSong] = useState(null);
  const [showLinkCopied, setShowLinkCopied] = useState(false);
  const [animatedAmount, setAnimatedAmount] = useState(0);

  const history = useHistory();
  const location = useLocation();
  
  const purchaseSongId = useMemo(() => new URLSearchParams(location.search).get('purchase_song'), [location]);
  const songFormat = useMemo(() => new URLSearchParams(location.search).get('format'), [location]);

  const faqRef = useRef();
  const pricingRef = useRef();

  const expiryDate = DateTime.fromISO('2022-06-01');
  const today = DateTime.now();

  const doubleCoinsTest = abTests['purchase-double-coins'];
  // const animaySaleTest = abTests['animay-sale-2022'];
  const subscribeTest = abTests['subscriptions-multi-tiers'];

  const showSale = today < expiryDate;
  const showDoubleCoins = doubleCoinsTest?.variant === 'enabled';

  // track Pageviews to Get Coins:
  // useEffect(() => {
  //   animaySaleTest && UserEvents.logEvent('get_coins.pageview', animaySaleTest);
  //   // doubleCoinsTest && UserEvents.logEvent('get_coins.pageview', doubleCoinsTest);
  // }, [animaySaleTest, doubleCoinsTest]);

  // track Pageviews to Get Coins:
  useEffect(() => {
    UserEvents.logEvent('purchase_flow.pageview', subscribeTest);
  }, [subscribeTest]);
  

  useEffect(() => {
    const getSong = async () => {
      if (purchaseSongId) {
        const res = await strofeApi.get(`/songs/${purchaseSongId}`);
        setSong(res.data);
      }
    }

    getSong();
  }, [purchaseSongId]);

  const showCoinPayment = (coins, price) => {
    const tier = 'coins_' + coins + (showSale ? '_half_price' : '') + (showDoubleCoins ? '_2x' : '');
    setPricingTier(tier);

    if (currentUser.registered) {
      setShowPayment(true);
      UserEvents.logEvent(`purchase_flow.click_CTA_button`, subscribeTest);
      // animaySaleTest && UserEvents.logEvent(`get_coins.click_purchase_${coins}`, animaySaleTest);
      // doubleCoinsTest && UserEvents.logEvent(`get_coins.click_purchase_${price}`, doubleCoinsTest);
      EventTracker.selectItem();
    }
    else {
      setShowPaymentAfterRegister(true);
      setShowRegistration(true);
    }
  }

  const onPaymentSuccessful = () => {
    setShowPayment(false);
    purchaseSongId && (!song.purchased || (!song.midi_purchased && songFormat === 'midi_only')) ? setShowDownload(true) : setShowPaymentSucces(true);

    UserEvents.logEvent(`purchase_flow.processed_payment`, subscribeTest);
    // animaySaleTest && UserEvents.logEvent('get_coins.purchase_successful', animaySaleTest);
    // doubleCoinsTest && UserEvents.logEvent('get_coins.purchase_successful', doubleCoinsTest);
  }

  const handleCreateAccount = () => {
    if (!currentUser.registered) {
      setShowRegistration(true);
    }
  }

  const handleVerify = () => {
    if (currentUser.registered && !currentUser.email_verified) {
      setShowVerify(true);
    }
  }

  const onCloseRegistration = () => {
    setShowRegistration(false);
    setShowPaymentAfterRegister(false);
  }

  const onUserCreated = () => {
    if (showPaymentAfterRegister) {
      setShowPayment(true);
      EventTracker.selectItem();
    }

    onCloseRegistration();
  }

  const onPurchase = async (_, format) => {
    song.purchased = true;
    song.generated = false;

    if (format) {
      song[`${format}_purchased`] = true;
    }

    setSong(JSON.parse(JSON.stringify(song)));
  }

  const copyLink = () => {
    navigator.clipboard.writeText(referrerLink);
    setShowLinkCopied(true);
    debouncedHideLink();
  }

  const selectAll = e => {
    e.target.select();
  }

  const debouncedHideLink = useDebouncedCallback(() => {
    setShowLinkCopied(false);
  }, 3000);

  const onEmailVerified = () => {
    setShowVerifyToast(true);
  }

  const handleCreateSong = () => {
    history.push('create');
  }

  const renderInvite = () => (
    <>
      <p><Translate i18nKey='invite-instructions' /></p>
        { currentUser.display_name === null && <Button className="display-name-button" onClick={() => setShowSetDisplayName(true)}>Set your display name</Button> }
        { currentUser.display_name !== null &&
          <InputGroup className='copy-link'>
            <Form.Control value={referrerLink} readOnly onClick={selectAll} />
            <InputGroup.Append>
              <Button onClick={copyLink}><Translate>Copy Link</Translate></Button>
            </InputGroup.Append>
          </InputGroup>
        }
      <div className={classNames('link-copied', { hidden: !showLinkCopied })}><Translate>Link copied to clipboard</Translate></div>
    </>
  );

  const COIN_REWARDS = [
    {
      title       : 'Create an account',
      rewarded    : currentUser.registered,
      description : 'create-account-steps',
      onClick     : handleCreateAccount,
      amount      : 119,
    },

    {
      title       : 'Verify your email',
      rewarded    : currentUser.email_verified,
      description : 'invite-instructions',
      onClick     : handleVerify,
      amount      : 120,
    },

    {
      title       : 'Make a song every day!',
      description : 'daily-song-steps',
      onClick     : handleCreateSong,
      amount      : [1, 2, 4, 8, 16],
    },

    {
      title       : 'Help grow the community by inviting users to Strofe',
      amount      : 240,
      render      : renderInvite,
    },
  ];

  useInterval(() => {
    setAnimatedAmount(animatedAmount + 1);
  }, [1200]);

  const renderAnimatedAmount = amounts => (
    <div className='animated-container'>
      {/* Invisible amount keeps the height of the container correctly without the need of a magic number */}
      <span className='invisible-amount'>16</span>
      <span className='animated-amount' style={{ transform: `translate(-50%, -${(animatedAmount % 5) * 36}px)` }}>
        { amounts.map(amount => <Fragment key={amount}>{amount} <br /></Fragment>)}
      </span>
    </div>
  );

  const renderAnimaySale = () => (
    <div className="__animay-sale-overflow-container">
      <section className='__animay-sale'>

        <div className='content'>

          <Link className='art-star-container' to='/create/spirited/anime?scrollAnime=true'>
            <div className='star-text'><Translate>Try out the new Anime Style!</Translate></div>
            <AnimaySaleStar className='star-top' />
            <AnimaySaleStar className='star-shadow' />
          </Link>

          <Countdown />
          <h3><Translate>50% Off All MAY</Translate></h3>

          <div className='sale-line'>
            <div className='sale-banner'>
              <div className='sale-title'><Translate>Animay Sale</Translate></div>
              <div className='sale-title title-shadow'><Translate>Animay Sale</Translate></div>
            </div>
            <AnimaySaleAvatar className='art-avatar' />
          </div>

          <div className='sale-footer'>
            <Translate>Purchase Strofe Coins for Half our Regular Price.</Translate><br />
            <Translate>Offer ends May 31st.</Translate>
          </div>
        </div>

      </section>
    </div>
  )

  // const renderSummerSale = () => (
  //   <section className='__summer-sale-countdown'>
  //     <div className='content'>
  //       <SummerSaleIceCream className='art-ice-cream' />
  //       <SummerSaleSun className='art-sun' />
  //       <Countdown />
  //       <h3><Translate>50% Off All January</Translate></h3>
  //       <h2><Translate>Summer Sale</Translate></h2>
  //     </div>
  //   </section>
  // );
  
  // const renderWinterSale = () => (
  //   <div className='__winter-sale-countdown-container'>
  //     <section className='__winter-sale-countdown'>
  //       <div className='content'>
  //         <WinterSaleSnow className='art-winter-snow' />
  //         <Countdown />
  //         <h3><Translate>50% Off All January</Translate></h3>
  //         <h2><Translate>Winter Sale</Translate></h2>

  //         <p><Translate>Purchase Strofe Coins for Half our Regular Price.</Translate></p>
  //         <p><Translate>Offer ends January 31st.</Translate></p>
  //       </div>
  //     </section>
  //   </div>
  // );
  

  const renderDivider = () => (
    <div style={{ overflow: 'hidden', maxWidth: '100%' }}>
      <section className='divider'>
        <div className='left-bg' />
        <div className='lines'>
          <div className='line-left' />
          <div className='line-middle' />
          <div className='line-right' />
        </div>
        <div className='right-bg' />
      </section>
    </div>
  );

  const renderFaq = () => (
    <section className='__get-coins-faq' ref={faqRef}>
      { QUESTIONS.map(question => (
        <div className='question' key={question.question}>
          <h3>{ question.question }</h3>
          <Translate i18nKey={question.answer} />
        </div>
      ))}

      <p className='text-center'><Link className='get-coins' to='#tiers' onClick={() => pricingRef.current.scrollIntoView()}><StrofeCoin /><Translate>Get Strofe Coins</Translate></Link></p>
      
    </section>
  );


  if (doubleCoinsTest === null) {
    return (
      <div className="__get-coins">
        <NavigationHeader />
      </div>
    );
  }

  const coinTiers = showDoubleCoins ? COIN_TIERS_2X : COIN_TIERS;

  // Non-subscribed users who are in control test should go to get-coins
  if (currentUser.subscribed || subscribeTest?.variant === 'enabled') {
    return <Redirect to='/pricing' />
  }
  
  return (
    <div className="__get-coins">
      <NavigationHeader />

      { showSale && renderAnimaySale() }

      <section className='pricing' ref={pricingRef}>
  
        <div className='header'>
          <StrofeCoin width={36} height={36} /><Translate>Get Strofe Coins</Translate>
        </div>
  
        <table className='coin-tiers'>
          <thead>
            <tr>
              <th className='pricing top-corner'><div /></th>
              <th className='coins'><Translate>Buy Strofe Coins</Translate></th>
              <th className='right-column top-corner'><div /></th>
            </tr>
          </thead>
          
          <tbody>
            { coinTiers.map(tier => (
              <tr className='content' key={tier.coins}>
                <td className={classNames('pricing', {'with-sale': showSale })}>
                  { showSale
                  ? <><p><del>{ tier.price }</del> <span className='sale'><Translate>Sale</Translate></span></p> { tier.salePrice }</>
                  : tier.price
                  }
                </td>
                <td className='coins'>
                  <Button className='purchase-button' onClick={() => showCoinPayment(tier.coins, tier.price)} data-test={`GET-COINS-purchase-button`}>
                    <StrofeCoin />
                    { tier.coins.toLocaleString() }
                  </Button>
                  <div className='song-price'>
                    <Translate i18nKey='own-x-tracks' values={{ count: Math.floor(tier.coins / 240) }} />
                  </div>
                </td>
                <td className='right-column' />
              </tr>
            ))}
  
            {/* Empty bottom row with round bottom border */}
            <tr>
              <td className='bottom-corner bottom-corner-left'><div /></td>
              <td className='coins bottom' />
              <td className='bottom-corner bottom-corner-right'><div /></td>
            </tr>
          </tbody>
        </table>

        <div className='summary'>
          <div className='summary-coin'>
            <StrofeCoin />
          </div>          

          <div>
            <h3 className='summary-header'><Translate>Own Your Music</Translate></h3>
            <div>
              <p><Translate i18nKey='purchase-using-coins' /></p>
              <p>
                <Translate>
                  <strong>After you acquire a track, its copyright and distribution rights are yours, forever. <Link to='#faq' onClick={() => faqRef.current.scrollIntoView()}>Learn more.</Link></strong>
                </Translate>
              </p>
            </div>
          </div>
        </div>
      </section>

      { renderDivider() }
  
      <section className='details'>
        <div>
          <h2><Translate i18nKey='ways-earn-coins' /></h2>
    
          <div className='rewards'>

            {/* { COIN_REWARDS.map(reward => ( */}
            { COIN_REWARDS.filter(reward => !reward.rewarded).map(reward => (
              <div className='reward' key={reward.title}>
                <div className='coin-amount'>
                  <div className='amount' role={reward.onClick ? 'button' : undefined } onClick={reward.onClick || null}>
                    <StrofeCoin />
                    { Array.isArray(reward.amount)
                    ? renderAnimatedAmount(reward.amount)
                    : <span>{ reward.amount }</span>
                    }

                  </div>
                </div>
                <div>
                  <h3 role={reward.onClick ? 'button' : undefined } onClick={reward.onClick || null}>
                    <Translate>{ reward.title }</Translate>
                  </h3>
                  { reward.render?.() || <p><Translate i18nKey={reward.description} /></p> }
                </div>
              </div>
            ))}  
          </div>
    
          <h5 className='new-ways'><Translate i18nKey='new-ways-get-coins' /></h5>
        </div>
      </section>

      { renderFaq() }
  
      <Modal className='__modal __download-track-flow' show={showPaymentSuccess} size='sm'>
        <Modal.Header>
          <Translate>Payment Successful</Translate>
        </Modal.Header>
        <Modal.Body>
          <p><Translate>Thank you for your purchase!</Translate></p>
          <p><strong><Translate i18nKey='coin-balance-summary' /></strong></p>

          { pricingTier && (
            <>
              <div className='price-detail'>
                <div><Translate>Previous Balance</Translate></div>
                <div className='amount'>{ (currentUser.coins - pricingTier.split('_')[1])?.toLocaleString() }</div>
              </div>

              <div className='price-detail'>
                <div><Translate>Today's Purchase</Translate></div>
                <div className='amount'>{ pricingTier.split('_')[1].toLocaleString() }</div>
              </div>

              <div className='price-detail final-balance'>
                <div><Translate>Current Balance</Translate></div>
                <div className='amount'><StrofeCoin />{ currentUser.coins.toLocaleString() }</div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => setShowPaymentSucces(false)}><Translate>Close</Translate></Button>
        </Modal.Footer>
      </Modal>

      <PaymentModal show={showPayment} onHide={() => setShowPayment(false)} pricingTier={pricingTier} currentUser={currentUser} onPaymentSuccessful={onPaymentSuccessful} />
      <RegistrationModal onUserCreated={onUserCreated} onClose={onCloseRegistration} showWarmup={false} show={showRegistration} from='generic' />
      <VerifyEmailModal show={showVerify} onHide={() => setShowVerify(false)} onUpdateSuccess={onEmailVerified} />
      <UpdateProfileModal show={showSetDisplayName} field="display_name" onHide={() => setShowSetDisplayName(false)} />

      <DownloadTrackFlow song={song} show={showDownload} purchaseFlow onHide={() => setShowDownload(false)} initialAddMidi={songFormat === 'mp3_midi'} initialGetMidiOnly={songFormat === 'midi_only'} onPurchase={onPurchase} />

      <Toast show={showVerifyToast} className='strofe-toast' autohide delay={2000} onClose={() => setShowVerifyToast(false)}>
        <Toast.Body><Translate>Your email is verified</Translate></Toast.Body>
      </Toast>
    </div>
  );
}

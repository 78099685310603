import axios from 'axios';
import Cookies from 'js-cookie';

export let strofeApi = axios.create({
  baseURL: process.env.REACT_APP_NOMODO_API,
  headers: { Authorization: `Bearer ${Cookies.get('api_access_token')}` }
});

export function resetAccessToken(token) {
  //  instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;
  //! it seems there is a bug that even the default axios instance will show the headers!!
  // strofeApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  
  strofeApi = axios.create({
    baseURL: process.env.REACT_APP_NOMODO_API,
    headers: { Authorization: `Bearer ${token}` }
  });
}

export function authorizationHeader() {
  return {
    headers: {
      Authorization: `Bearer ${Cookies.get('api_access_token')}`
    }
  }
}

export function apiAuthUrl(provider, code, useParam) {
  let url = `/auth/${provider}/callback`;

  if (useParam) {
    url += `?code=${code}`
  };

  return url;
}

import * as React from "react";

const InstrumentTenorSax = (props) => (
  <svg
    id="InstrumentTenorSax_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 111.59"
    style={{
      enableBackground: "new 0 0 110.84 111.59",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentTenorSax_svg__st8{fill:#ca5772}.InstrumentTenorSax_svg__st9{fill:#cfd026}"
      }
    </style>
    
    <path
      d="M93.17 110.84h-77.5C6.46 110.84-1 103.38-1 94.17v-77.5C-1 7.46 6.46 0 15.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentTenorSax_svg__st8"
      d="M44.68 83.14c1.97 7.92 9.63 12.54 17.52 10.43 4.77-1.27 8.03-4.36 9.69-9.04 1.25-3.52 1.03-7-.42-10.43-1.85-4.39-1.8-8.75.36-13.03.4-.79.91-1.53 1.39-2.32l-17.35-8.71c-.05.23-.1.42-.13.6-.81 6.18-.36 12.26 1.48 18.21.66 2.14 1.58 4.21 2.35 6.32.53 1.45.13 2.93-.99 3.88-1.17.99-2.7 1.12-4.06.38-1.03-.55-1.48-1.48-1.82-2.53L32.62 16.42c-.79-2.33-2.32-4.01-4.73-4.77-2.92-.92-5.49-.29-7.6 1.9-1.79 1.85-2.34 3.98-1.94 6.29.18 1.04 1.39 1.59 2.24.96.54-.4.52-1.01.37-2.42-.22-2.08 1.3-3.97 3.39-4.14 2.07-.17 3.58 1.03 4.15 3.35 1.72 6.96 13.69 55.54 16.18 65.55z"
      id="InstrumentTenorSax_svg___x36_Tvu8w.tif_2_"
    />
    <path
      className="InstrumentTenorSax_svg__st8"
      d="M76.61 58.32c-.3 0-.6-.07-.88-.22l-20.3-10.7a1.888 1.888 0 0 1-.79-2.56c.49-.93 1.63-1.28 2.56-.79l20.29 10.7c.93.49 1.28 1.63.79 2.56a1.89 1.89 0 0 1-1.67 1.01z"
    />
    <circle
      className="InstrumentTenorSax_svg__st9"
      cx={37.28}
      cy={40.64}
      r={1.06}
    />
    <circle
      className="InstrumentTenorSax_svg__st9"
      cx={39.09}
      cy={47.28}
      r={1.06}
    />
    <circle
      className="InstrumentTenorSax_svg__st9"
      cx={40.77}
      cy={53.92}
      r={1.06}
    />
  </svg>
);

export default InstrumentTenorSax;

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";
import Ale from '../assets/ale.jpg';
import Yuan from '../assets/yuan.jpg';

export default function SlideTeam() {
  return (
    <div className='slide slide-team white-bg'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <div className='solution-bullet'>
          <div className='lime-circle' />
          <div className='red-circle' />
          <div className='orange-circle' />
          <div className='papaya-circle' />
          <div className='purple-circle' />
        </div>
        <h1>The Team</h1>
      </div>

      <div className='content'>
        <div className='member'>
          <div className='avatar'>
            <img src={Ale} height={170} width={170} alt="ale" />
          </div>
          <div className='bio'>
            <div className="name">Alejandro Di Mare – CEO and Co-Founder</div>
            <div className="description">Has degrees in both music and CS. Worked as Engineering Director on Edmodo from its inception until it grew to over 200 million users.</div>
          </div>
          <div className='circle'>
          </div>
        </div>

        <div className='member'>
          <div className='avatar'>
            <img src={Yuan} height={170} width={170} alt="ale" />
          </div>
          <div className='bio'>
            <div className="name">Yuan-Hao Chiang – Co-Founder</div>
            <div className="description">CS Major turned into marketing entrepreneur, with a decade of experience running an Internet Marketing business with over $500,000 in revenue.</div>
          </div>
          <div className='circle'>
          </div>
        </div>
      </div>
    </div>
  )
}

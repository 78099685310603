export default function StrofeLogoColorsLettersOnly(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 800 2000 950"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".__strofe-logo-colors-letters-only-0{fill:#f7ccc1}.__strofe-logo-colors-letters-only-1{fill:#f08660}.__strofe-logo-colors-letters-only-2{fill:#c85671}"
        }
      </style>
      <path
        d="M387.6 1252.3c-14.9-10.5-29-17.3-52.3-28.4-5.3-2.5-11-5.3-17.3-8.3l-.9-.4c-35.3-17.4-52.3-27-52.3-54.8 0-25.6 15.9-51.4 51.4-51.4 14.3 0 40.5 4.6 56.3 35.4l6.9 13.4 35.3-29.8-4.6-8.6c-18.8-34.9-52.8-54.8-93.3-54.8-65.2 0-99.4 48.7-99.4 96.8 0 16.3 4.1 31.1 12.5 45 13.8 22.8 33.6 33.2 67.3 49.5 27.8 13.7 47.5 23.8 56.9 29.2 24.5 15 27.4 31.9 27.4 43.4 0 38.1-30.2 66.8-70.3 66.8-21.6 0-42.5-9.4-54.5-24.6l-.2-.2c-4.3-5.2-12.2-17.1-16.3-29l-4.4-12.6-41.2 19.5 3.6 10.2c3.4 9.6 9.6 24.3 24.2 41.2 14.8 17.1 40 39.9 88.3 39.9 66.3 0 118.3-49 118.3-111.7-.1-42.3-23.7-63.5-41.4-75.7z"
        fill="#fdcb75"
      />
      <path
        className="__strofe-logo-colors-letters-only-0"
        d="M440.3 1113.5h88.4v320.6h46.5v-320.6h88.4v-44.3H440.3z"
      />
      <path
        className="__strofe-logo-colors-letters-only-2"
        d="M829.3 1265.8c41.6-14 65.4-48.3 65.4-94.2 0-25.1-7-59.8-40.5-83-26.3-18-53.4-19.5-78.8-19.5h-61V1434h46.4v-149.3L863.2 1434h54.7l-112.5-162.6c8.2-1.1 16.3-3 23.9-5.6zm-5.9-143.5c7.2 3.9 23.9 16.4 23.9 48.8 0 37.1-23.3 57.6-65.7 57.6h-20.8v-115.1H777c15.5 0 35.8 2.7 46.2 8.6l.2.1z"
      />
      <path
        d="M1139.9 1064.6c-105.9 0-188.8 82.5-188.8 187.8 0 105 83 187.3 188.8 187.3s188.8-82.3 188.8-187.3c0-105.3-82.9-187.8-188.8-187.8zm0 330.7c-80.6 0-141.4-61.4-141.4-142.9 0-81.8 60.8-143.4 141.4-143.4 80.6 0 141.4 61.7 141.4 143.4 0 81.5-60.8 142.9-141.4 142.9z"
        fill="#cecf25"
      />
      <path
        fill="#528352"
        d="M1399.9 1434.1h46.4v-176.4h112.5v-44.4h-112.5v-99.8h115.5v-44.3h-161.9z"
      />
      <path
        className="__strofe-logo-colors-letters-only-1"
        d="M1816.4 1113.5v-44.3h-187.5v364.9h187.5v-44.4h-141.1v-132h138v-44.4h-138v-99.8z"
      />
    </svg>
  )
}

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";

export default function SlideGoToMarket() {
  return (
    <div className='slide slide-go-to-market white-bg'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <div className='bullet'>
          <div className='circle orange' />
          <div className='circle red' />
          <div className='circle purple' />
        </div>
        <h1>How we're finding our users</h1>
      </div>

      <div className='content'>
        <div className='deal premium'>
          <div className='title'>Forum Posts</div>
          <div className='description'>Brought over 10,000 users from Roblox's developer forum at no cost.</div>
        </div>

        <div className='deal standard'>
          <div className='title'>Reddit Viral Advertising</div>
          <div className='description'>Net over 25k users who each brought 1 or more new users via word of mouth.</div>
        </div>

        <div className='deal free'>
          <div className='title'>Google Ads</div>
          <div className='description'>2 million monthly searches for background music at $0.01 CPC.</div>
        </div>
      </div>
    </div>
  )
}
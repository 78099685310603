export default function InstrumentTightBas(props) {
  return (
    <svg
      id="InstrumentTightBass_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 110.8 110.6"
      style={{
        enableBackground: "new 0 0 110.8 110.6",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".st0{fill:#cecf23}.st1{fill:#f08660}.st2{fill:#4d3f91;stroke:#4d3f91;stroke-width:3.178518e-02;stroke-miterlimit:10}.st3{fill:#c95571}"
        }
      </style>
      <path
        className="st0"
        d="M94.2 110.6H16.7C7.5 110.6 0 103.2 0 94V16.7C0 7.5 7.5 0 16.7 0h77.5c9.2 0 16.7 7.5 16.7 16.7V94c-.1 9.2-7.5 16.6-16.7 16.6z"
      />
      <path
        className="st1"
        d="M38.5 61.7s-1.6 0-2.6-1.6c-1-1.7 0-4.2.7-4.9s4.3-5.1 4.2-6c-.1-.8-1.2-2.2-3.7-1.3-2.5.9-6 3.6-8.6 9.2s-4 6.4-6 7.9c-1.9 1.5-6.8 1.9-11 4-4.9 2.7-8.2 7-8.4 12.9-.1 2.7.5 4.9 1.9 7.2 1.4 2.5 3.2 4.7 5.2 6.7 6.1 5.9 10.7 6.5 14.2 6.4 2.8-.1 5.5-1 7.8-2.6 2.5-1.8 3.9-4.5 4.6-7.5.1-.3.1-.5.2-.8.5-2.1.4-4.3 1.5-6.2.9-1.6 2.4-2.3 4-3 1.9-.9 4.1-2 5.7-3.5.9-1 2-2.4 1.8-3.8-.2-2.1-2.3-1.6-3.6-.9-1.4.8-2.7 1.9-4.4 1.9-1.4 0-2.8-.9-3.2-2.2-.3-1 .1-2.3.8-3.1l-1.1-8.8z"
      />
      <path
        className="st2"
        d="M31.7 68.4s-6.3-2-11.7 2.9-7 9.1-5.8 12.4c.6 1.5 1.7 2.8 2.8 3.9 1.2 1.2 2.6 2.3 4.1 3.1 3.1 1.6 7.9-.1 10.2-2.5 1-1 1.9-2.2 2.7-3.3 1.4-2 2.8-4.4 3-6.9 0 0 .5-2.7-.6-4.8s-4.7-4.8-4.7-4.8zM27.3 95.2c.1 0 .1-.1.2-.1 1.4-.9 2.6-2.2 3.4-3.7.5-.9.9-1.9 1.5-2.8.7-.9 2-1.8 3-.7.8 1 .6 2.5.3 3.6-.3 1.4-.8 2.8-1.6 4.1-1 1.7-2.5 2.9-4.3 3.8-1 .5-2.1.9-3.2 1.2-1.5.3-3 .4-4.5.3-1.2-.2-3.3-.6-4-1.7-.7-1.2.5-2 1.5-2.2 1.1-.2 2.3-.2 3.4-.4 1.5-.2 3-.6 4.3-1.4z"
      />
      <circle className="st0" cx={33.5} cy={90.6} r={1} />
      <circle className="st0" cx={31} cy={95.6} r={1} />
      <circle className="st0" cx={26.1} cy={98.6} r={1} />
      <path
        className="st3"
        d="M20.5 91.5c.2.4-.2 1.4-.4 1.8-.5.9-1.2 1.7-1.9 2.4-.4.4-.8.9-1.3 1.2-.2.2-.5.3-.8.5-.2.1-.5.1-.7.3-.2.3-.2.8-.6.7-.2 0-.4-.3-.6-.4-.2-.2-.5-.4-.7-.6-.9-.7-1.8-1.5-2.7-2.3-1.2-1.1-2.6-2.3-3.4-3.7 0-.1-.1-.2-.1-.2 0-.2.7-.8.9-.9.2-.2.1-.5.5-1 .4-.6.8-1.1 1.2-1.5.9-.9 3.3-2.5 4.1-2.4.7.1 6.3 5.7 6.5 6.1z"
      />
      <path
        className="st1"
        d="M80.9 18.7c-.1-.4-.2-.9-.3-1.3-.1-.5-.3-1.1-.1-1.6.2-.7 15.2-9.7 15.2-9.7s1.6-.9 2.8-.1 2.3 2.5 1.5 5.1c0 0-1.5-.1-3.5 1S91 22.5 88.9 22.2c-1-.2-2.3-.6-3.2-.4-.9.2-1.7 1.4-1.7 1.4l-3.3-3.3s.4-.6.2-1.2z"
      />
      <path
        className="st3"
        d="M31.3 69s-.5.6 2 3 2.6 1.9 2.6 1.9l48-50.7-3.2-3.2-49.4 49z"
      />
      <circle className="st2" cx={93.7} cy={21} r={1.4} />
      <circle className="st2" cx={86.6} cy={8.9} r={1.4} />
      <circle className="st2" cx={82.7} cy={11.4} r={1.4} />
      <circle className="st2" cx={90.5} cy={6.7} r={1.4} />
    </svg>
  );
}

import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Trans as Translate } from 'react-i18next';

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";

export default function CommentForm({ buttonsHidden, onSubmitComment, onCancelComment, autoFocus, nesting = 0, disabled, pending, onFocusTextArea }) {

  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  const [showButtons, setShowButtons] = useState(!buttonsHidden);
  const inputRef = useRef();

  const onMessageFocus = e => {
    if (onFocusTextArea) {
      onFocusTextArea();
      e.currentTarget.blur();
      return;
    }
    
    !showButtons && setShowButtons(true);
  }

  const handleCancel = () => {
    buttonsHidden && setShowButtons(false);
    setMessage('');
    onCancelComment?.();
  }

  const handleSubmit = async () => {
    await onSubmitComment(message);
    handleCancel();
  }

  return (
    <div className='__comments-form' style={{ marginLeft: nesting * 16 }}>
      <Form.Control autoFocus={autoFocus} className='comment-message' as="textarea" value={message} onChange={e => setMessage(e.target.value)} rows={2} placeholder={t('Write a comment...')} onFocus={onMessageFocus} disabled={disabled} ref={inputRef} />
      { showButtons && (
        <div className='form-controls'>
          { pending && <div className='pending'><InfiniteLoader width={16} /></div> }
          <Button variant='link' onClick={handleCancel} disabled={disabled}><Translate>Cancel</Translate></Button>
          <Button variant='primary' onClick={handleSubmit} disabled={!message.trim().length || disabled}><Translate>Comment</Translate></Button>
      </div>
      )}
    </div>
  );
}
import * as React from "react";

const InstrumentCursedStrings = (props) => (
  <svg
    id="InstrumentCursedStrings_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.1 110.1"
    style={{
      enableBackground: "new 0 0 110.1 110.1",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentCursedStrings_svg__st6{fill:#ca5672}.InstrumentCursedStrings_svg__st28{fill:#fff}.InstrumentCursedStrings_svg__st93,.InstrumentCursedStrings_svg__st94,.InstrumentCursedStrings_svg__st95{fill:none;stroke:#fff;stroke-width:1.8634;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.InstrumentCursedStrings_svg__st94,.InstrumentCursedStrings_svg__st95{stroke-width:1.1535}.InstrumentCursedStrings_svg__st95{stroke-width:.3484}"
      }
    </style>
    <path
      d="M93.54 110.1H16.56C7.41 110.1 0 102.69 0 93.54V16.56C0 7.41 7.41 0 16.56 0h76.98c9.15 0 16.56 7.41 16.56 16.56v76.98c0 9.15-7.41 16.56-16.56 16.56z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentCursedStrings_svg__st6"
      d="M43.83 65.9c.33-.52 1.09-.65 1.32-.23.65 1.2 2.55 2.3 3.33 2.73a4.2 4.2 0 0 0 2.78.46c1.35-.25 6.32-5.01 5.36-5.95l4.74-3.27c.24-.16.51-.22.73-.16.45.13 1.2.34 1.31.34 1.87 0 4.65-1.8 5.45-3.55.67-1.47-.33-2.59.29-4.02.81-1.88 5.96-.02 8.72 3.47 1.98 2.51 8.13-1.96 8.93.93 1.17 4.26-1.78 16.7 1.8 18.13 1.67.66 4.4.93 5.01 2.81.41 1.26-.42 2.66-1.03 3.86-2.4 4.7-6.58 8.55-10.98 11.68-4.14 2.95-8.66 6.11-13.58 7.38-3.66.94-8.35.44-10.27-2.52-.78-1.21-1.06-2.73-.81-4.24.28-1.69 1.78-3.12 2.65-4.65 1.16-2.03 2.76-5.03 1.22-6.85-1.01-1.21-2.85-1.59-4.45-1.83-3.58-.54-8.3.08-11.33-1.78-.35-.22-.68-.48-.93-.8-2.2-2.82-2.83-7.83-.26-11.94z"
    />
    <circle
      className="InstrumentCursedStrings_svg__st28"
      cx={66.6}
      cy={78.23}
      r={3}
    />
    <circle
      className="InstrumentCursedStrings_svg__st28"
      cx={75.54}
      cy={70.32}
      r={6.34}
    />
    <path
      className="InstrumentCursedStrings_svg__st93"
      d="M61.61 79.85s-1.48-2.53.68-4.8M78.59 62.34s-2.28-1.85-4.84-.07M70.58 89.06 84.8 75.78"
    />
    <path
      className="InstrumentCursedStrings_svg__st94"
      d="m70.07 85.69 2.1 3.35M72.3 84.66l3.09 3.24M73.98 84.04l2.29 1.91M75.62 81.94l2.6 3.18M78.22 80.84l2.05 1.88M79.34 78.92l2.3 1.92M81.84 76.83l2.33 3.39M83.1 75.66l1.86 2.47"
    />
    <path
      style={{
        fill: "#f18860",
      }}
      d="M60.33 66.74 27.04 22.1l4.6-4.02 34.7 44.31z"
    />
    <path
      className="InstrumentCursedStrings_svg__st95"
      d="m26.75 20.66 35.68 47.07M27.99 19.83l35.77 46.9M29.25 18.93l35.83 46.8M30.51 18.11l35.9 46.63"
    />
    <path
      className="InstrumentCursedStrings_svg__st6"
      d="M31.64 18.08 19.97 5.79c-1.3.13-2.12 2.86-2.48 3.81-.37 1.01-.69 2.05-.92 3.1-.19.87-.94 2.97.15 3.52.62.31 1.49-.31 2.09-.5.51-.16 1.07-.24 1.56-.03.61.27.59.7.66 1.25.07.54.3 1.04.63 1.47.68.91.6-1.3 1.54-.69.88.58 2.91 3.89 3.85 4.37l4.59-4.01z"
    />
  </svg>
);

export default InstrumentCursedStrings;

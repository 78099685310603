import * as React from "react";

const InstrumentTangoAccordion = (props) => (
  <svg
    id="InstrumentTangAccordion_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 111.25 110.84"
    style={{
      enableBackground: "new 0 0 111.25 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentTangAccordion_svg__st3{fill:#4e4092}.InstrumentTangAccordion_svg__st4{fill:#f18860}.InstrumentTangAccordion_svg__st5{fill:#cfd125}.InstrumentTangAccordion_svg__st6{fill:#ca5672}.InstrumentTangAccordion_svg__st10{fill:#538553}"
      }
    </style>
    
    <path
      className="InstrumentTangAccordion_svg__st3"
      d="M94.57 110.84h-77.5C7.86 110.84.4 103.38.4 94.17v-77.5C.41 7.46 7.87 0 17.08 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.47 16.67-16.68 16.67z"
    />
    <path
      className="InstrumentTangAccordion_svg__st5"
      d="m20.1 46.91 28.13 17.57c.57.35.72 1.11.34 1.66l-3.66 5.31c-.35.51-1.04.66-1.57.34L15.3 54.89a1.17 1.17 0 0 1-.4-1.6l3.57-5.98a1.18 1.18 0 0 1 1.63-.4z"
    />
    <path
      className="InstrumentTangAccordion_svg__st10"
      d="m14.3 54.29-1.66 3.55c-.39.85-.1 1.86.68 2.36l3.51 2.26 22.22 14.29c.85.55 1.98.32 2.55-.51l2.68-3.88L14.3 54.29z"
    />
    <path
      className="InstrumentTangAccordion_svg__st5"
      d="m16.84 62.46-.43 2.14c-.17.85.2 1.71.94 2.17l12.84 7.99c.71.44 1.62.41 2.3-.07l1.6-1.13-17.25-11.1z"
    />
    <path
      className="InstrumentTangAccordion_svg__st4"
      d="m32.86 37.59-4.74-2.41 2 4.75-.18-.07 21.68 21.99c.27-.25.55-.47.83-.68L32.83 37.5l.03.09zM22.25 41.23l2.41 4.62h-.05l25.95 17.13c.04-.05.08-.09.12-.14L27.21 42.51l-4.96-1.28z"
    />
    <path
      className="InstrumentTangAccordion_svg__st6"
      d="m25.11 37.98 2.22 4.56-.12-.03 23.46 20.34c.3-.37.62-.7.95-1L29.95 39.86l-4.84-1.88zM19.09 46.28l30.18 18.85c.35-.82.79-1.53 1.28-2.14L24.6 45.86l-5.51.42zM57.22 28.41l-2.41-4.56-1.77 4.43 2.39 28.08.63 3.01c.71-.22 1.16-.29 1.16-.29V28.41zM55.69 59.49c.13-.04.25-.09.37-.12l-.63-3.01.26 3.13z"
    />
    <path
      className="InstrumentTangAccordion_svg__st4"
      d="m53.04 28.28-.18.45-2.15-4.43-.92 5.08-.07-.09 5.7 27.07z"
    />
    <path
      className="InstrumentTangAccordion_svg__st6"
      d="m31.1 32.7 1.73 4.8 19.62 23.67.11-.08-17.19-25.46-4.27-2.93z"
    />
    <path
      className="InstrumentTangAccordion_svg__st4"
      d="m45.72 30.38-.03.18-2.67-4.1-1.56.65v5.07l13.73 27.51c.03-.01.05-.02.08-.03l-9.55-29.28z"
    />
    <path
      className="InstrumentTangAccordion_svg__st6"
      d="m49.72 29.29-3.24-3.88-.75 4.97 9.55 29.27c.15-.06.29-.11.42-.15l-.27-3.13-5.71-27.08zM41.46 32.18l-3.52-3.13.44 4.59 15.22 26.8c.56-.32 1.11-.57 1.59-.76L41.46 32.17v.01z"
    />
    <path
      className="InstrumentTangAccordion_svg__st4"
      d="m38.4 33.81-4.31-3.19 1.38 5.08-.1-.07L52.56 61.1c.35-.25.69-.47 1.03-.66L38.38 33.65l.02.16z"
    />
    <path
      className="InstrumentTangAccordion_svg__st3"
      d="m32.03 57.16.2.61c.06.2.25.33.45.33h.64c.46 0 .65.59.28.86l-.52.38c-.17.12-.24.33-.17.53l.2.61c.14.44-.36.8-.73.53l-.52-.38a.48.48 0 0 0-.56 0l-.52.37a.474.474 0 0 1-.73-.53l.2-.61c.06-.2-.01-.41-.17-.53l-.52-.38c-.37-.27-.18-.86.28-.86h.64c.21 0 .39-.13.45-.33l.2-.61c.14-.43.76-.43.9.01z"
    />
    <path
      className="InstrumentTangAccordion_svg__st5"
      d="M94.32 46.88 66.19 64.45c-.57.35-.72 1.11-.34 1.66l3.66 5.31c.35.51 1.04.66 1.57.34l28.04-16.9c.55-.33.73-1.05.4-1.6l-3.57-5.98a1.17 1.17 0 0 0-1.63-.4z"
    />
    <path
      className="InstrumentTangAccordion_svg__st10"
      d="m100.12 54.26 1.66 3.55c.39.85.1 1.86-.68 2.36l-3.51 2.26-22.23 14.29c-.85.55-1.98.32-2.55-.51l-2.68-3.88 29.99-18.07z"
    />
    <path
      className="InstrumentTangAccordion_svg__st5"
      d="m97.58 62.43.43 2.14c.17.85-.2 1.71-.94 2.17l-12.84 7.99c-.71.44-1.62.41-2.3-.07l-1.6-1.13 17.25-11.1z"
    />
    <path
      className="InstrumentTangAccordion_svg__st6"
      d="m81.56 37.55 4.74-2.41-2 4.75.18-.07-21.68 22a10.3 10.3 0 0 0-.83-.68l19.62-23.67-.03.08zM92.18 41.2l-2.41 4.62h.05L63.87 62.95c-.04-.05-.08-.09-.12-.14l23.46-20.34 4.97-1.27z"
    />
    <path
      className="InstrumentTangAccordion_svg__st4"
      d="M89.32 37.94 87.1 42.5l.12-.03-23.47 20.34c-.3-.37-.62-.7-.95-1l21.68-21.99 4.84-1.88zM95.33 46.25 65.15 65.1c-.35-.82-.79-1.53-1.28-2.14l25.95-17.13 5.51.42zM57.21 28.37l2.41-4.56 1.77 4.43L59 56.33l-.63 3.01c-.71-.22-1.16-.29-1.16-.29V28.37zM58.74 59.46c-.13-.04-.25-.09-.37-.12l.63-3.01-.26 3.13z"
    />
    <path
      className="InstrumentTangAccordion_svg__st6"
      d="m61.39 28.24.18.46 2.15-4.43.91 5.08.08-.1L59 56.33z"
    />
    <path
      className="InstrumentTangAccordion_svg__st4"
      d="m83.32 32.67-1.73 4.8-19.62 23.67-.11-.08L79.05 35.6l4.27-2.93z"
    />
    <path
      className="InstrumentTangAccordion_svg__st6"
      d="m68.7 30.34.03.18 2.67-4.1 1.56.65v5.07l-13.73 27.5c-.03-.01-.05-.02-.08-.03l9.55-29.27z"
    />
    <path
      className="InstrumentTangAccordion_svg__st4"
      d="m64.71 29.25 3.24-3.88.75 4.97-9.55 29.27c-.15-.06-.29-.11-.42-.15l.27-3.13 5.71-27.08zM72.96 32.15l3.52-3.13-.44 4.59L60.83 60.4c-.56-.32-1.11-.57-1.59-.76l13.73-27.51v.02z"
    />
    <path
      className="InstrumentTangAccordion_svg__st6"
      d="m76.03 33.77 4.31-3.19-1.38 5.08.1-.07-17.2 25.47c-.35-.25-.69-.47-1.03-.66l15.21-26.79-.01.16z"
    />
    <path
      className="InstrumentTangAccordion_svg__st3"
      d="m82.39 57.12-.2.61c-.06.2-.25.33-.45.33h-.64c-.46 0-.65.59-.28.86l.52.38c.17.12.24.33.17.53l-.2.61c-.14.44.36.8.73.53l.52-.38a.48.48 0 0 1 .56 0l.52.38c.37.27.87-.09.73-.53l-.2-.61c-.06-.2.01-.41.17-.53l.52-.38c.37-.27.18-.86-.28-.86h-.64c-.21 0-.39-.13-.45-.33l-.2-.61c-.14-.43-.76-.43-.9 0z"
    />
  </svg>
);

export default InstrumentTangoAccordion;

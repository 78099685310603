// Conditionally imported if needed using 'require' when env.development is playing MIDI format:
// import MIDIFile from './MIDIFile';
// import WebAudioFontPlayer from 'webaudiofont';

const MidiPlayer = {
  loadMidi,
  start,
  pause,
  resume,
  close,
  songEnded,
}

export default MidiPlayer;

let audioContext;
let player;
let reverberator;
let input;
let song;
let loop;

function loadMidi(path, cbOnLoaded, setLoop = false) {
  console.log("Loading path: ", path);

  loop = setLoop;

  const MIDIFile = require('./MIDIFile').default;

  var xmlHttpRequest = new XMLHttpRequest();
  xmlHttpRequest.open("GET", path, true);
  xmlHttpRequest.responseType = "arraybuffer";
  xmlHttpRequest.onload = function (e) {
    var arrayBuffer = xmlHttpRequest.response;
    var midiFile = new MIDIFile(arrayBuffer);
    song = midiFile.parseSong();
    startLoad(song, cbOnLoaded);
  };
  xmlHttpRequest.send(null);
}

function startLoad(song, cbOnLoaded) {
  var AudioContextFunc = window.AudioContext || window.webkitAudioContext;
  audioContext = new AudioContextFunc();

  const WebAudioFontPlayer = require('webaudiofont');
  player = new WebAudioFontPlayer();

  reverberator = player.createReverberator(audioContext);
  reverberator.output.connect(audioContext.destination);

  input = reverberator.input;

  for (let i = 0; i < song.tracks.length; i++) {
    const nn = player.loader.findInstrument(song.tracks[i].program);
    const info = player.loader.instrumentInfo(nn);
    song.tracks[i].info = info;
    song.tracks[i].id = nn;
    player.loader.startLoad(audioContext, info.url, info.variable);
  }

  for (let i = 0; i < song.beats.length; i++) {
    const nn = player.loader.findDrum(song.beats[i].n);
    const info = player.loader.drumInfo(nn);
    song.beats[i].info = info;
    song.beats[i].id = nn;
    player.loader.startLoad(audioContext, info.url, info.variable);
  }

  player.loader.waitLoad(function () {
    console.log('Loading ... done!');
    cbOnLoaded?.();
  });
}

let currentSongTime;
let songStart;
let nextStepTime;
let nextPositionTime;
let songFinished;

function start() {
  currentSongTime = 0;
  songStart = audioContext.currentTime;
  nextStepTime = audioContext.currentTime;
  songFinished = false;
  
  // USE INTERVAL INSTEAD OF RAF
  var stepDuration = 44 / 1000;
  // -> USE THIS ->
  // var stepDuration = 0.5;
  
  tick(song, stepDuration);
}

function pause() {
  audioContext.suspend();
}

function resume() {
  audioContext.resume();
}

function close() {
  audioContext?.close();
}

function songEnded() {
  return songFinished;
}

function tick(song, stepDuration) {
  if (audioContext.currentTime > nextStepTime - stepDuration) {
    sendNotes(song, songStart, currentSongTime, currentSongTime + stepDuration, audioContext, input, player);
    currentSongTime = currentSongTime + stepDuration;
    nextStepTime = nextStepTime + stepDuration;

    // song finished, reset position and decide to loop or not:
    if (currentSongTime > song.duration) {
      if (loop) {
        currentSongTime = currentSongTime - song.duration;
        sendNotes(song, songStart, 0, currentSongTime, audioContext, input, player);
        songStart = songStart + song.duration;
        console.log('looping');
      }
      else {
        setTimeout(() => {
          songFinished = true;
        }, 500);

        console.log('do not loop...');
        return;
      }
    }
  }
  if (nextPositionTime < audioContext.currentTime) {
    var o = document.getElementById('position');
    o.value = 100 * currentSongTime / song.duration;
    document.getElementById('tmr').innerHTML = '' + Math.round(100 * currentSongTime / song.duration) + '%';
    nextPositionTime = audioContext.currentTime + 3;
  }
  
  // USE INTERVAL INSTEAD OF RAF:

  window.requestAnimationFrame(function (t) {
    tick(song, stepDuration);
  });

  // -> USE THIS ->
  // setInterval(() => {
  //   tick(song, stepDuration);
  // }, 300);

}

function sendNotes(song, songStart, start, end, audioContext, input, player) {
  for (let t = 0; t < song.tracks.length; t++) {
    const track = song.tracks[t];
    for (let i = 0; i < track.notes.length; i++) {
      if (track.notes[i].when >= start && track.notes[i].when < end) {
        const when = songStart + track.notes[i].when;
        let duration = track.notes[i].duration;
        if (duration > 3) {
          duration = 3;
        }
        const instr = track.info.variable;
        const v = track.volume / 7;
        player.queueWaveTable(audioContext, input, window[instr], when, track.notes[i].pitch, duration, v, track.notes[i].slides);
      }
    }
  }
  for (let b = 0; b < song.beats.length; b++) {
    const beat = song.beats[b];
    for (let i = 0; i < beat.notes.length; i++) {
      if (beat.notes[i].when >= start && beat.notes[i].when < end) {
        const when = songStart + beat.notes[i].when;
        const duration = 1.5;
        const instr = beat.info.variable;
        const v = beat.volume / 2;
        player.queueWaveTable(audioContext, input, window[instr], when, beat.n, duration, v);
      }
    }
  }
}

import { useEffect, useState } from 'react';
import { Trans as Translate, useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Checkbox from '../../layout/Checkbox';
import { useSelector } from "react-redux";
import { usersSelectors } from "../../store/usersSlice";

import { STYLES } from '../CreateSong/CreateSong';

import './FilterModal.scss';

export default function FilterModal({ show, onHide, onSubmit, filters, additionalOptions }) {

  const [mine, setMine] = useState(false);
  const [style, setStyle] = useState('all');
  const [status, setStatus] = useState('all');
  const [category, setCategory] = useState('all');

  const currentUser = useSelector(usersSelectors.getCurrentUser);

  useEffect(() => {
    if (show && filters === null) {
      setMine(false);
      setStyle('all');
      setStatus('all');
      setCategory('all');
    }
  }, [show, filters]);

  const { t } = useTranslation();
  
  const toggleMine = e => {
    setMine(e.target.checked);
  }

  const handleSubmit = () => {

    const newFilters = {
      style: style === 'all' ? undefined : style,
      status: status === 'all' ? undefined : status,
      category: category === 'all' ? undefined : category,
      creator_id: !mine ? undefined : currentUser.id,
    }

    onSubmit(newFilters, true);
  }

  return (
    <Modal show={show} className='__modal __filter-modal' size='sm' backdrop='static'>
      <Modal.Header>
        <Translate>Filter Loops</Translate>
      </Modal.Header>
      <Modal.Body>

        <Form.Group className='filter'>
          <Form.Label><Translate>Category</Translate></Form.Label>
          <Form.Control as="select" value={category} onChange={e => setCategory(e.target.value)}>
            <option value='all'>{ t('All') }</option>
            <option value='chords'>{ t('Accompaniment') }</option>
            <option value='drums'>{ t('Drums') }</option>
            <option value='bass'>{ t('Bass Line') }</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className='filter'>
          <Form.Label><Translate>Genre</Translate></Form.Label>
          <Form.Control as="select" value={style} onChange={e => setStyle(e.target.value)}>
            <option value='all'>{ t('All') }</option>
            { STYLES.map(style => (
              <option key={style.id} value={style.id}>{ t(style.phrase) }</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className='filter'>
          <Form.Label><Translate>Status</Translate></Form.Label>
          <Form.Control as="select" value={status} onChange={e => setStatus(e.target.value)}>
            <option value='all'>{ t('All') }</option>
            <option value='draft'>{ t('Draft') }</option>
            <option value='ready'>{ t('Ready') }</option>

            {additionalOptions && <>
                <option value='review'>{ t('Review') }</option>
                <option value='published'>{ t('Published') }</option>
              </>
            }
          </Form.Control>
        </Form.Group>

        {additionalOptions &&
          <div className='filter'> 
            <span>Created By Me</span>
            <span>
              <span><Checkbox checked={mine} onChange={toggleMine} /></span>
            </span>
          </div>
        }

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}><Translate>Cancel</Translate></Button>
        <Button variant="primary" onClick={handleSubmit}><Translate>Filter</Translate></Button>
      </Modal.Footer>
    </Modal>
  );
}
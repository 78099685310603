export default function ReggaeIcon(props) {
  return (
    <svg
      id="__reggae-icon-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110.84 111.11"
      {...props}
    >
      <defs>
        <style>
          {
            ".__reggae-icon-prefix__cls-3{fill:#fdcb75}.__reggae-icon-prefix__cls-4,.__reggae-icon-prefix__cls-6{fill:none;stroke-linecap:round;stroke-miterlimit:10}.__reggae-icon-prefix__cls-4{stroke:#fff;stroke-width:1.42px}.__reggae-icon-prefix__cls-5{fill:#fff}.__reggae-icon-prefix__cls-6{stroke:#fdcb75;stroke-width:2.69px}"
          }
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 44"}</title>
      <rect
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
        fill="#f18860"
      />
      <path
        d="M80.35 23.29c0 35.68-11.11 64.6-24.82 64.6S30.71 59 30.71 23.29"
        fill="#4d3f92"
      />
      <ellipse
        className="__reggae-icon-prefix__cls-3"
        cx={55.53}
        cy={23.29}
        rx={24.82}
        ry={6.85}
      />
      <path
        className="__reggae-icon-prefix__cls-3"
        d="M37.64 68.07c-.95.58-1.23 1.05-1.23 1.27 0 .38.91 1.63 5.05 2.78a55.28 55.28 0 0014.06 1.6 55.28 55.28 0 0014.06-1.6c4.14-1.14 5.05-2.39 5.05-2.78 0-.21-.28-.69-1.24-1.27l.83-2.39c1.92 1 2.89 2.26 2.89 3.66 0 2.17-2.31 3.91-6.87 5.17a57.78 57.78 0 01-14.72 1.69 57.78 57.78 0 01-14.71-1.7c-4.56-1.26-6.87-3-6.87-5.17 0-1.4 1-2.62 2.87-3.65z"
      />
      <path className="__reggae-icon-prefix__cls-4" d="M55.53 28.07v10.92" />
      <circle className="__reggae-icon-prefix__cls-5" cx={55.53} cy={39.42} r={2.66} />
      <path className="__reggae-icon-prefix__cls-4" d="M37.07 24.12v10.93" />
      <circle className="__reggae-icon-prefix__cls-5" cx={37.07} cy={35.47} r={2.66} />
      <path className="__reggae-icon-prefix__cls-4" d="M74.46 24.12v10.93" />
      <circle className="__reggae-icon-prefix__cls-5" cx={74.46} cy={35.47} r={2.66} />
      <path
        className="__reggae-icon-prefix__cls-6"
        d="M38.46 72.71L32.28 92.7M73.46 72.71l6.18 19.99M55.53 74.87v24.47"
      />
    </svg>
  )
}

import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import Cookies from "js-cookie";
import { Trans as Translate, useTranslation } from 'react-i18next';

import { strofeApi } from "../../api/strofeApi";
import { usersActions, usersSelectors } from "../../store/usersSlice";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import NavigationHeader from "../NavigationHeader";
import RegistrationModal from '../../registration/RegistrationModal';
import LoginModal from '../../registration/LoginModal';
import ForgotPasswordModal from '../../registration/ForgotPasswordModal';

import StrofeCoin from "../../icons/coins/StrofeCoin";
import ScissorsIcon from "../../icons/ScissorsIcon";
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";

import CurveBorder from './coupon_curve_border.png';

import './RedeemCoupon.scss';
import { unwrapResult } from "@reduxjs/toolkit";

export default function RedeemCoupon() {

  const [coupon, setCoupon] = useState();
  const [loading, setLoading] = useState(true);
  const [redeeming, setRedeeming] = useState(false);
  const [refetching, setRefetching] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [redeemError, setRedeemError] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showPreviouslyRedeemed, setShowPreviouslyRedeemed] = useState(false);

  const { permalink } = useParams();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const timerRedeem = useRef();

  const currentUser = useSelector(usersSelectors.getCurrentUser);

  useEffect(() => {
    const loadCoupon = async () => {
      try {
        const { data } = await strofeApi.get(`/coupons/${permalink}`);
        setCoupon(data);
      }
      catch {
        setLoadError(true);
      }
      finally {
        setLoading(false);
      }
    }

    loadCoupon();
  }, [permalink]);

  const onRedeemCoupon = async (checkRegistered) => {
    if (redeeming) {
      return;
    }

    if (checkRegistered && !currentUser?.registered) {
      setShowRegistration(true);
      return;
    }

    setRedeeming(true);

    const redeem = async () => {
      try {
        await strofeApi.post(`/user_coupons`, { user_coupon: { identifier: coupon.identifier }});
        
        // Fetch user to see if coins value has been incremented:
        const action = await dispatch(usersActions.fetchById({ id: Cookies.get('strofe_user_id') }));
        unwrapResult(action);

        setShowBalance(true);
      }
      catch {
        setRedeemError(true);
      }
    }

    // Timeout to allow the redeem animation to finish happen
    timerRedeem.current = setTimeout(redeem, 800);
  }

  useEffect(() => {
    return () => clearTimeout(timerRedeem.current);
  }, []);

  const onLoginFromRegistration = () => {
    setShowRegistration(false);
    setShowLogin(true);
  }

  const onForgotPassword = () => {
    setShowLogin(false);
    setShowForgotPassword(true);
  }

  const onUserCreated = () => {
    setShowRegistration(false);
    redeemAfterSignup();
  }

  const onLoggedIn = () => {
    setShowLogin(false);
    redeemAfterSignup();
  }

  const redeemAfterSignup = async () => {
    setRefetching(true);

    try {
      const { data } = await strofeApi.get(`/coupons/${permalink}`);
      setCoupon(data);

      if (data.redeemed) {
        setShowPreviouslyRedeemed(true);
      }
      else {
        onRedeemCoupon(false);
      }

      setRefetching(false);
    }
    catch {
      setRedeemError(true);
    }
  }

  const renderCoupon = () => {

    if (loading) {
      return (
        <div className='initial-load'>
          <InfiniteLoader height={48} />
          <p><Translate>Loading...</Translate></p>
        </div>
      );
    }

    else if (loadError) {
      return (
        <div className='load-error'>
          <p><Translate i18nKey='coupon-load-error' /></p>
          <Link to='/'><Translate>Back to Strofe</Translate></Link>
        </div>
      );
    }

    else if (!coupon) {
      return null;
    }

    const valid_until = new DateTime.fromISO(coupon.valid_until).setLocale(i18n.language).toLocaleString(DateTime.DATE_FULL);
    const redeemDisabled = !coupon.valid || redeeming || refetching;

    return (
      <>
        <div className='coupon'>
          <div className={classNames('tag-container', { redeemed: coupon.redeemed || redeeming })}>
            <div className='tag-curve-border' style={{ backgroundImage: `url(${CurveBorder})`}} />
            <div className='tag'>
              <div className='corner corner-top-right' />
              <div className='corner corner-bottom-right' />
              <div className='amount'><Translate i18nKey='x-br-strofe-coins' values={{ amount: coupon.coin_amount.toLocaleString() }} /></div>
            </div>
          </div>
          <div className='content-curve-border-mobile' style={{ backgroundImage: `url(${CurveBorder})`}} />
          <div className='content'>
            <div className='content-inner'>
              <div className={classNames('title', { smaller: coupon.title.length > 32 })}>
                <StrofeCoin width={48} height={48} />
                <div>{ coupon.title }</div>
              </div>
              <div className='description'>{ coupon.description }</div>
              <div className='redeem-btn-container'>
                { coupon.redeemed
                ? <div className='redeemed'><Translate>You already redeemed this coupon</Translate></div>
                : <button className='redeem-btn' disabled={redeemDisabled} onClick={onRedeemCoupon}><ScissorsIcon /> <Translate>Redeem Coupon</Translate></button>
                }
              </div>
              <div className='valid-until'><Translate i18nKey='coupon-valid-until' values={{ valid_until }} /></div>
            </div>

            <div className='corner corner-top-left' />
            <div className='corner corner-bottom-left' />
          </div>
          <div className='content-curve-border' style={{ backgroundImage: `url(${CurveBorder})`}} />
          { (!coupon.redeemed && !coupon.valid) && <div className='expired'><Translate>Expired</Translate></div> }
        </div>
      </>
    );
  }

  const redeemedModal = () => (
    <Modal className='__modal __download-track-flow' show={showBalance} size='sm' backdrop='static'>
      <Modal.Header>
        <Translate>Coupon Redeemed</Translate>
      </Modal.Header>
      <Modal.Body>
        <p><Translate>Your coupon has been redeemed!</Translate></p>

        <div className='price-detail'>
          <div><Translate>Previous Balance</Translate></div>
          <div className='amount'>{ (currentUser?.coins - coupon?.coin_amount)?.toLocaleString() }</div>
        </div>

        <div className='price-detail'>
          <div><Translate>Coupon Redemption</Translate></div>
          <div className='amount'>{ coupon?.coin_amount.toLocaleString() }</div>
        </div>

        <div className='price-detail final-balance'>
          <div><Translate>Current Balance</Translate></div>
          <div className='amount'><StrofeCoin />{ currentUser?.coins.toLocaleString() }</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={() => history.push('/')}><Translate>Back to Strofe</Translate></Button>
      </Modal.Footer>
    </Modal>
  );

  const errorModal = () => (
    <Modal className='__modal' show={redeemError} size='sm' backdrop='static'>
      <Modal.Header>
        <Translate>Oops!</Translate>
      </Modal.Header>
      <Modal.Body>
        <p><Translate i18nKey='redeem-error' /></p>
        <p><Translate i18nKey='redeem-error-refresh' /></p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={() => window.location.reload()}><Translate>Refresh</Translate></Button>
      </Modal.Footer>
    </Modal>
  );

  const previouslyRedeemedModal = () => (
    <Modal className='__modal' show={showPreviouslyRedeemed} size='sm' backdrop='static'>
      <Modal.Header>
        <Translate>Coupon Redeemed</Translate>
      </Modal.Header>
      <Modal.Body>
        <Translate i18nKey='previously-redeemed' />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={() => history.push('/')}><Translate>Back to Strofe</Translate></Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      <div className='__redeem-coupon'>
        { currentUser && <NavigationHeader /> }
        { renderCoupon() }
      </div>

      { redeemedModal() }
      { previouslyRedeemedModal() }
      { errorModal() }

      <RegistrationModal onUserCreated={onUserCreated} onClose={() => setShowRegistration(false)} showWarmup={false} show={showRegistration} from='coupon' showLoginLink onLogin={onLoginFromRegistration} fetchOnSuccess />
      <LoginModal show={showLogin} onClose={() => setShowLogin(false)} onForgotPassword={onForgotPassword} onLoggedIn={onLoggedIn} fetchUserAfterLogin />
      <ForgotPasswordModal show={showForgotPassword} onClose={() => setShowForgotPassword(false)} />
    </>
  );
}
import * as React from "react";
const SvgInstrumentDrumsElectronic = (props) => (
  <svg
    id="InstrumentDrumsElectronic_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 108.66 109.75"
    style={{
      enableBackground: "new 0 0 108.66 109.75",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentDrumsElectronic_svg__st99{fill:#f48864}.InstrumentDrumsElectronic_svg__st101{fill:#4b4a9e}.InstrumentDrumsElectronic_svg__st104{fill:#f8c4b9}"
      }
    </style>

    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#528459",
      }}
    />
    <path
      className="InstrumentDrumsElectronic_svg__st99"
      d="m100.11 22.41-9-5.19-8.99 5.19V32.8l8.99 5.19 9-5.19z"
    />
    <path
      style={{
        fill: "#fdc676",
      }}
      d="m74.03 23.87-6.23-8.31-10.31 1.24-4.09 9.55 6.24 8.31 10.31-1.24z"
    />
    <path
      className="InstrumentDrumsElectronic_svg__st99"
      d="m51.65 32.93-1.94-9.04-10.47-2.48-8.53 6.55 1.93 9.03 10.47 2.49z"
    />
    <path
      className="InstrumentDrumsElectronic_svg__st104"
      d="m65.15 44.84-1.75-8.18-9.95-2.15-8.2 6.03L47 48.73l9.95 2.15zM28.02 35.94l-6.29-8.38-7.98-.56-1.7 7.82 6.28 8.38 7.99.56z"
    />
    <path
      className="InstrumentDrumsElectronic_svg__st101"
      d="M79.41 77.04c.27 0 .48-.22.48-.48V29.61a.48.48 0 0 0-.96 0v46.95c0 .27.22.48.48.48zM97.5 81.27 80.28 65.95c-.29-.26-.43-.65-.31-.87l.02-.03c.12-.22.46-.18.76.08l17.22 15.32c.29.26.43.65.31.87l-.02.03c-.13.22-.47.19-.76-.08z"
    />
    <path
      className="InstrumentDrumsElectronic_svg__st101"
      d="m61.35 81.27 17.22-15.32c.29-.26.43-.65.31-.87l-.02-.03c-.12-.22-.46-.18-.76.08L60.89 80.45c-.29.26-.43.65-.31.87l.02.03c.12.22.46.19.75-.08zM29.61 80.58c.27 0 .48-.22.48-.48V33.16a.48.48 0 0 0-.96 0V80.1c0 .27.22.48.48.48zM47.7 84.82 30.48 69.49c-.29-.26-.43-.65-.31-.87l.02-.03c.12-.22.46-.18.76.08l17.22 15.32c.29.26.43.65.31.87l-.02.03c-.13.22-.47.19-.76-.07z"
    />
    <path
      className="InstrumentDrumsElectronic_svg__st101"
      d="M11.55 84.82 28.77 69.5c.29-.26.43-.65.31-.87l-.02-.03c-.12-.22-.46-.18-.76.08L11.09 83.99c-.29.26-.43.65-.31.87l.02.03c.12.22.46.19.75-.07zM72.31 64.9h-3.63a.66.66 0 1 1 0-1.32h3.63c.37 0 .66.3.66.66.01.37-.29.66-.66.66z"
    />
    <path
      className="InstrumentDrumsElectronic_svg__st101"
      d="M84.53 86.89c-.3.16-.68.05-.84-.25L72.11 65.15c-.16-.3-.05-.68.25-.84.3-.16.68-.05.84.25l11.58 21.49c.16.3.05.68-.25.84zM35.02 65.24h3.63a.66.66 0 1 0 0-1.32h-3.63c-.37 0-.66.3-.66.66 0 .36.3.66.66.66z"
    />
    <path
      className="InstrumentDrumsElectronic_svg__st101"
      d="M22.81 87.22c.3.16.68.05.84-.25l11.58-21.49c.16-.3.05-.68-.25-.84a.623.623 0 0 0-.84.25L22.56 86.38c-.17.31-.05.68.25.84zM78.8 42.26H65.08c-.34 0-.62-.28-.62-.62v-.03c0-.34.28-.62.62-.62H78.8c.34 0 .62.28.62.62v.03c-.01.35-.28.62-.62.62z"
    />
    <path
      className="InstrumentDrumsElectronic_svg__st99"
      d="m68.91 61.87-15.78-9.11-15.77 9.11v18.21l15.77 9.11 15.78-9.11z"
    />
  </svg>
);
export default SvgInstrumentDrumsElectronic;


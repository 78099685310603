export default function SlideProductCreate(props) {
  return (
    <svg
      viewBox="0 0 141 132.9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <style>
        {
          ".__strofe_slide_create_0{fill:#538453;}.__strofe_slide_create_1{fill:#CFCF25;}.__strofe_slide_create_2{fill:none;stroke:#FFFFFF;stroke-width:6.4656;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.__strofe_slide_create_3{fill:#FFFFFF;}.__strofe_slide_create_4{fill:none;stroke:#FFFFFF;stroke-width:5.5419;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.__strofe_slide_create_5{fill:#4E4092;}.__strofe_slide_create_6{fill:#F18660;}.__strofe_slide_create_7{fill:#CA5572;}.__strofe_slide_create_8{fill:#FCCB75;}.__strofe_slide_create_9{fill:none;stroke:#F18660;stroke-width:1.3429;stroke-linecap:round;stroke-miterlimit:10;}.__strofe_slide_create_10{fill:#F7F4E9;}.__strofe_slide_create_11{fill:#CFCE26;}.__strofe_slide_create_12{fill:#F7F4E9;stroke:#4E4092;stroke-width:0.7088;stroke-miterlimit:10;}.__strofe_slide_create_13{fill:#F7F4E9;stroke:#4E4092;stroke-width:0.7294;stroke-miterlimit:10;}"
        }
      </style>
      <g>
        <path className="__strofe_slide_create_1" d="M67.4,79.2H25.7c-6.4,0-11.6-5.2-11.6-11.6V24.5c0-6.4,5.2-11.6,11.6-11.6h41.7c6.4,0,11.6,5.2,11.6,11.6v43.1
          C79,74,73.8,79.2,67.4,79.2z"/>
        <line className="__strofe_slide_create_2" x1="67.5" y1="21.1" x2="73.7" y2="24"/>
        <line className="__strofe_slide_create_2" x1="30.4" y1="20.1" x2="18.8" y2="25.5"/>
        <path className="__strofe_slide_create_2" d="M23.5,36.4c-0.6,0.1,3.9-4.8,6.4-3.4c2.6,1.3,5.5,4.9,10.1,4.1"/>
        <circle className="__strofe_slide_create_3" cx="36.8" cy="39.5" r="4.5"/>
        <path className="__strofe_slide_create_2" d="M53.9,36.3c0,0,8.7,0.6,14.9-4.1"/>
        <circle className="__strofe_slide_create_3" cx="65.5" cy="38.4" r="4.5"/>
        <path className="__strofe_slide_create_4" d="M52.3,62.3c0,0,8.8,0.7,12.8-7"/>
      </g>
      <g>
        <g>
          <g>
            <path className="__strofe_slide_create_5" d="M96,55.4c1.7,0.1,3.4,0.4,5,0.8c1.7,0.4,3.3,0.9,4.9,1.5c-1.7-0.2-3.5-0.2-5.2-0.1
              C99.2,56.7,97.6,56,96,55.4z"/>
            <path className="__strofe_slide_create_6" d="M116.2,90.5c1.2-5.1,0.8-10.2-0.7-14.8c3.2-1.4,4.2-3.4,4.2-3.4c2,3.7,3,7.9,3,12.3
              C122.5,84.9,121.7,88.6,116.2,90.5z"/>
            <path className="__strofe_slide_create_7" d="M108.8,104.8c3.4-3.4,5.9-7.8,7-12.8c0-0.1,0-0.1,0-0.2c5.5-2,6.4-5.7,6.4-6c0,1.8-0.2,3.7-0.7,5.5
              c-0.7,2.8-1.8,5.4-3.2,7.7C117.6,100.2,114.9,103.4,108.8,104.8z"/>
            <path className="__strofe_slide_create_6" d="M115.7,74.5c-1.2-3.6-3.1-6.8-5.6-9.6c2.9-0.3,3.6-1.4,3.6-1.4c2.6,2.1,4.7,4.7,6.2,7.6
              C119.9,71.1,118.9,73.1,115.7,74.5z"/>
            <path className="__strofe_slide_create_7" d="M108.5,106c6.1-1.4,8.8-4.6,9.6-5.7c-4,6.4-10.7,10.7-18.2,11.6C103.1,110.5,106,108.5,108.5,106z"/>
            <path className="__strofe_slide_create_7" d="M110.3,63.8c-2-2.2-4.3-4.1-7-5.6c1.2-0.1,2.5,0,3.7,0.1c2.5,1,4.8,2.4,6.9,4
              C113.9,62.4,113.2,63.5,110.3,63.8z"/>
            <polygon className="__strofe_slide_create_6" points="100.6,57.7 100.6,57.7 100.6,57.7       "/>
            <path className="__strofe_slide_create_5" d="M69.5,73.4c-0.1,0.3-1,4.1,3.1,8.3c0,0,0,0,0,0c-1.2,5.1-0.9,10.3,0.7,14.9c-4.5-3.4-5.7-6.8-6-8.4
              c-0.5-3.1-0.4-6.4,0.4-9.7C68.1,76.8,68.7,75,69.5,73.4z"/>
            <path className="__strofe_slide_create_5" d="M89.6,60.2c-3.6,1.4-6.8,3.6-9.5,6.3c-2.3-2.6-2.3-4.8-2.3-5c2.8-2.1,6-3.6,9.4-4.3
              C87.2,57.2,87.1,58.6,89.6,60.2z"/>
            <path className="__strofe_slide_create_5" d="M79.8,67.8c-3.3,3.4-5.8,7.7-7,12.7c-4.1-4.2-3.2-8-3.1-8.3c1.8-3.8,4.5-6.9,7.7-9.3
              C77.5,62.9,77.5,65.1,79.8,67.8z"/>
            <path className="__strofe_slide_create_5" d="M98.4,57.2C98.4,57.2,98.4,57.2,98.4,57.2C98.4,57.2,98.4,57.2,98.4,57.2c-3,0.2-5.8,0.8-8.5,1.9
              c-2.5-1.6-2.4-3-2.4-3c2.6-0.6,5.2-0.8,7.9-0.6c1.2,0.5,2.3,1,3.4,1.6C98.6,57.2,98.5,57.2,98.4,57.2z"/>
            <path className="__strofe_slide_create_7" d="M73,98c1.1,3.2,2.7,6.2,4.9,8.8C72,102.8,68.1,96.6,67,89.6C67.3,91.2,68.5,94.6,73,98z"/>
            <path className="__strofe_slide_create_6" d="M99.5,62.4c-1-0.2-1.9-0.5-2.7-0.7c2.1-2.6,3.8-3.9,3.8-3.9c0,0,0,0,0,0c0,0,0.9,1.9,1.7,5.2
              C101.5,62.8,100.5,62.6,99.5,62.4z"/>
            <path className="__strofe_slide_create_6" d="M102.9,76.4c-0.1,4.2-0.8,9.1-2.5,14.3c-2.1-0.3-4.4-0.7-7-1.3c-2.8-0.7-5.2-1.4-7.3-2.1
              c0.8-5.4,2.3-10.1,4.1-13.9c1.8,0.7,3.8,1.3,6.2,1.9C98.9,75.9,101,76.3,102.9,76.4z"/>
            <path className="__strofe_slide_create_6" d="M93.1,90.7c2.6,0.6,4.9,1,7,1.3c-1.3,3.9-3.2,8-5.9,12.3c-1.3-0.2-2.6-0.5-4-0.8c-1.7-0.4-3.3-0.8-4.7-1.3
              c-0.5-5-0.3-9.5,0.2-13.6C87.9,89.4,90.4,90.1,93.1,90.7z"/>
            <path className="__strofe_slide_create_6" d="M99.3,63.5c1,0.2,1.9,0.4,2.8,0.6c0.7,2.8,1.2,6.6,1.1,11.2c-1.9-0.2-4-0.5-6.4-1.1
              c-2.4-0.6-4.5-1.2-6.2-1.9c1.9-4.1,4.2-7.3,6-9.5C97.4,63,98.3,63.3,99.3,63.5z"/>
            <path className="__strofe_slide_create_6" d="M90,104.8c1.4,0.3,2.7,0.6,4,0.8c-1.2,1.9-2.5,3.7-4,5.6c-0.5-0.1-0.9-0.2-1.4-0.3c-0.7-0.2-1.5-0.4-2.2-0.6
              c-0.5-2.4-0.8-4.6-1.1-6.8C86.7,104,88.3,104.4,90,104.8z"/>
            <path className="__strofe_slide_create_6" d="M101.6,58c0.1,0,0.3,0,0.4,0c2.7,1.5,5,3.4,7,5.6c-1.4,0.2-3.2,0.1-5.8-0.3C102.5,59.9,101.6,58,101.6,58z"
              />
            <path className="__strofe_slide_create_8" d="M103,64.3c2.5,0.4,4.4,0.4,5.8,0.3c2.5,2.8,4.4,6,5.6,9.6c-2.2,1-5.5,1.7-10.2,1.3
              C104.3,70.9,103.7,67.1,103,64.3z"/>
            <path className="__strofe_slide_create_6" d="M107.2,105.7c-2.4,2.5-5.4,4.5-8.5,5.9c-2.5,0.3-5.1,0.3-7.7-0.2c1.5-1.9,2.8-3.8,4-5.6
              C100,106.6,104.1,106.4,107.2,105.7z"/>
            <path className="__strofe_slide_create_8" d="M103.9,76.7c4.8,0.4,8-0.3,10.2-1.3c1.6,4.6,1.9,9.7,0.7,14.8c-3,1.1-7.3,1.6-13.5,0.8
              C103.1,85.8,103.8,80.9,103.9,76.7z"/>
            <path className="__strofe_slide_create_8" d="M114.6,91.5c0,0.1,0,0.1,0,0.2c-1.2,5-3.7,9.4-7,12.8c-3.1,0.7-7.2,0.9-12.3,0.1c2.7-4.2,4.6-8.4,5.9-12.3
              C107.3,93.1,111.6,92.5,114.6,91.5z"/>
            <path className="__strofe_slide_create_7" d="M89.4,73.4c-1.9,3.8-3.6,8.4-4.5,13.8c-5.4-2.1-8.8-4.4-10.8-6.6c1.3-4.9,3.9-9.2,7.3-12.5
              C82.8,69.8,85.3,71.7,89.4,73.4z"/>
            <path className="__strofe_slide_create_7" d="M74.3,98.3c2.3,1.8,5.6,3.5,10,5c0.2,2.2,0.6,4.5,1.1,6.8c-2.2-0.7-4.3-1.8-6.2-3
              C77,104.5,75.4,101.5,74.3,98.3z"/>
            <path className="__strofe_slide_create_5" d="M89.6,72c-4.1-1.6-6.6-3.5-8.1-5.2c2.7-2.8,5.9-4.9,9.5-6.3c1,0.7,2.5,1.4,4.7,2
              C93.7,64.7,91.5,67.9,89.6,72z"/>
            <path className="__strofe_slide_create_7" d="M91.1,59.4c2.7-1.1,5.6-1.7,8.5-1.9c-0.1,0-1.7,1.4-3.8,3.9C93.7,60.8,92.2,60.1,91.1,59.4z"/>
            <path className="__strofe_slide_create_7" d="M84.6,102c-4.5-1.5-7.7-3.2-10-5c-1.6-4.7-1.9-9.8-0.7-14.9c0,0,0,0,0,0c2.1,2.1,5.5,4.4,11,6.4
              C84.3,92.5,84.1,97,84.6,102z"/>
          </g>
          <g>
            <line className="__strofe_slide_create_9" x1="104.4" y1="43.1" x2="103" y2="48.9"/>
            <path className="__strofe_slide_create_6" d="M99.7,57.8l2.6,0.6c1.3,0.3,2.6-0.5,2.9-1.8l0.8-3.2c0.3-1.3-0.5-2.6-1.8-2.9l-2.6-0.6
              c-1.3-0.3-2.6,0.5-2.9,1.8l-0.8,3.2C97.6,56.2,98.4,57.5,99.7,57.8z"/>
          </g>
        </g>
        <path className="__strofe_slide_create_8" d="M126.6,55.2l-2.3,1.3c-0.3,0.2-0.5,0.6-0.4,0.9l0.5,2.5c0.2,0.8-0.8,1.4-1.4,0.8l-1.9-1.7
          c-0.3-0.3-0.7-0.3-1-0.1l-2.3,1.3c-0.7,0.4-1.6-0.3-1.2-1.1l1.1-2.4c0.2-0.3,0.1-0.7-0.2-1l-1.9-1.7c-0.6-0.6-0.2-1.6,0.7-1.5
          l2.6,0.3c0.4,0,0.7-0.2,0.9-0.5l1.1-2.4c0.3-0.8,1.5-0.6,1.6,0.2l0.5,2.5c0.1,0.4,0.4,0.6,0.8,0.7l2.6,0.3
          C127.1,53.7,127.3,54.8,126.6,55.2z"/>
        <path className="__strofe_slide_create_8" d="M137.7,78.8l-0.1,1.4c0,0.2,0.1,0.4,0.3,0.5l1.3,0.5c0.4,0.2,0.4,0.8-0.1,0.9l-1.4,0.4
          c-0.2,0.1-0.3,0.2-0.4,0.4l-0.1,1.4c0,0.5-0.6,0.6-0.9,0.2l-0.8-1.2c-0.1-0.2-0.3-0.3-0.5-0.2l-1.4,0.4c-0.5,0.1-0.8-0.4-0.5-0.8
          l0.9-1.1c0.1-0.2,0.1-0.4,0-0.6l-0.8-1.2c-0.2-0.4,0.2-0.9,0.6-0.7l1.3,0.5c0.2,0.1,0.4,0,0.5-0.1l0.9-1.1
          C137.2,78.1,137.8,78.3,137.7,78.8z"/>
        <path className="__strofe_slide_create_8" d="M58.4,94.9l1.1,0.9c0.2,0.1,0.4,0.1,0.6,0l1.2-0.8c0.4-0.3,0.9,0.1,0.7,0.6L61.5,97c-0.1,0.2,0,0.4,0.1,0.5
          l1.1,0.9c0.4,0.3,0.1,0.9-0.3,0.9l-1.4-0.1c-0.2,0-0.4,0.1-0.5,0.3l-0.5,1.3c-0.2,0.4-0.8,0.4-0.9-0.1l-0.4-1.4
          c-0.1-0.2-0.2-0.3-0.4-0.4L56.8,99c-0.5,0-0.6-0.6-0.2-0.9l1.2-0.8c0.2-0.1,0.3-0.3,0.2-0.5l-0.4-1.4C57.5,94.9,58,94.6,58.4,94.9z
          "/>
        <path className="__strofe_slide_create_10" d="M102.6,50.8c0,0,1.3-0.1,2.1,0.8c0.4,0.5,0.6,1.3,0.5,2.4c0,0-0.4-1-1.1-1.9
          C103.2,50.9,102.6,50.8,102.6,50.8z"/>
      </g>
    </svg>
  );
}

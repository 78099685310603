import * as React from "react";

const InstrumentElectricGrandPiano = (props) => (
  <svg
    id="InstrumentElectricGrandPiano_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.75"
    style={{
      enableBackground: "new 0 0 110.84 110.75",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentElectricGrandPiano_svg__st3{fill:#4e4092}.InstrumentElectricGrandPiano_svg__st4{fill:#f18860}.InstrumentElectricGrandPiano_svg__st28{fill:#fff}.InstrumentElectricGrandPiano_svg__st35{fill:#1f3138}"
      }
    </style>
    
    <path
      d="M94.17 110.75h-77.5C7.46 110.75 0 103.29 0 94.08v-77.5C0 7.38 7.46-.09 16.67-.09h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#cfd125",
      }}
    />
    <path
      className="InstrumentElectricGrandPiano_svg__st3"
      d="M99.77 57.13H13.41c-1.75 0-3.17-1.42-3.17-3.17v-9.65c0-1.75 1.42-3.17 3.17-3.17h86.36c1.75 0 3.17 1.42 3.17 3.17v9.65c0 1.76-1.42 3.17-3.17 3.17z"
    />
    <path
      className="InstrumentElectricGrandPiano_svg__st28"
      d="M19.92 41.16h6.61v7.05h-6.61zM27.07 41.16h5.97v7.05h-5.97zM33.58 41.16h6.08v7.05h-6.08zM40.21 41.16h6.02v7.05h-6.02zM46.76 41.16h6.08v7.05h-6.08zM53.39 41.16h5.97v7.05h-5.97zM59.9 41.16h6.05v7.05H59.9zM66.49 41.16h6.14v7.05h-6.14zM73.17 41.16h6.15v7.05h-6.15zM79.86 41.16h6.07v7.05h-6.07zM86.47 41.16h6.14v7.05h-6.14z"
    />
    <path
      className="InstrumentElectricGrandPiano_svg__st4"
      d="M21.91 79.82h-4.95c-2.25 0-4.08-1.83-4.08-4.08v-18.6h9.03v22.68zM94.55 79.82h-4.21V57.13h9.03v17.86c0 2.67-2.16 4.83-4.82 4.83zM64.36 72.92H47.89a2.78 2.78 0 0 1-2.78-2.78V67.9a2.78 2.78 0 0 1 2.78-2.78h16.47a2.78 2.78 0 0 1 2.78 2.78v2.25c0 1.53-1.24 2.77-2.78 2.77z"
    />
    <path
      className="InstrumentElectricGrandPiano_svg__st4"
      d="M48.78 79.82h-.85a.67.67 0 0 1-.67-.67v-6.81c0-.37.3-.67.67-.67h.85c.37 0 .67.3.67.67v6.81c0 .37-.3.67-.67.67zM64.29 79.82h-.85a.67.67 0 0 1-.67-.67v-6.81c0-.37.3-.67.67-.67h.85c.37 0 .67.3.67.67v6.81c0 .37-.3.67-.67.67z"
    />
    <path
      className="InstrumentElectricGrandPiano_svg__st3"
      d="M99.28 15.36 11.6 40.01a.775.775 0 0 1-.96-.54l-.25-.88c-.12-.41.12-.84.54-.96l87.68-24.64c.41-.12.84.12.96.54l.25.88c.11.41-.13.84-.54.95z"
    />
    <path
      className="InstrumentElectricGrandPiano_svg__st35"
      d="M25.11 37.67h4.82v3.49h-4.82zM31.33 37.67h4.82v3.49h-4.82zM70.72 37.67h4.82v3.49h-4.82zM77.16 37.67h4.82v3.49h-4.82zM83.6 37.67h4.82v3.49H83.6zM45.44 37.67h4.82v3.49h-4.82zM51.82 37.67h4.82v3.49h-4.82zM58.2 37.67h4.82v3.49H58.2z"
    />
  </svg>
);

export default InstrumentElectricGrandPiano;

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import TrackEditor from '../assets/track_editor.png';

export default function SlideProductEditor() {

  return (
    <div className='slide slide-product-editor'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" /> 
      
      <img className='screenshots' src={TrackEditor} alt='Product - Track Editor' />

    </div>
  )
}

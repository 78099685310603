export default function SlideCube(props) {
  return (
    <svg
      width={100}
      height={100}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 63 51.9"
      {...props}
    >
      <style>
        {
          ".__strofe_slide_cube_18{fill:#020203;}"
        }
      </style>
      <g>
        <polygon class="__strofe_slide_cube_18" points="8.3,39.9 31.6,53.5 32.2,26.3 32.2,26.3 9.2,13.2   "/>
        <polygon class="__strofe_slide_cube_18" points="34,2.7 9.9,12.1 9.9,12.1 33,25.2 56.7,13.1  "/>
        <polygon class="__strofe_slide_cube_18" points="33.6,26.5 33.1,52.9 55.8,40.8 56.7,14.6   "/>
      </g>
    </svg>
  );
}

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";

export default function SlideRoblox() {
  return (
    <div className='slide slide-roblox'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <div className='traction-bullet'>
          <div className='orange-circle' />
          <div className='lime-circle' />
          <div className='yellow-circle' />
        </div>
        <h1>And that doesn't include Roblox</h1>
      </div>

      <div className='content'>
        <div className='circle orange'>
          <h3>20 Million</h3>
          <p>Published Games</p>
        </div>
      </div>

      <div className='footer'>
        <a href="https://www.polygon.com/22326123/what-is-roblox-explainer-public-offering" target="_blank" rel="noreferrer">
          https://www.polygon.com/22326123/what-is-roblox-explainer-public-offering
        </a>
      </div>
    </div>
  )
}

export default function TriangleUpIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={531.74}
      height={460.5}
      viewBox="0 0 531.74 460.5"
      overflow="visible"
      {...props}
    >
      <path stroke="#000" d="M.866 460L265.87 1l265.004 459z" />
    </svg>
  );
}

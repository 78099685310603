import * as React from "react";

const InstrumentCrystal = (props) => (
  <svg
    id="InstrumentCrystal_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentCrystal_svg__st3{fill:#4e4092}.InstrumentCrystal_svg__st4{fill:#f18860}.InstrumentCrystal_svg__st6{fill:#ca5672}.InstrumentCrystal_svg__st28{fill:#fff}"
      }
    </style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      className="InstrumentCrystal_svg__st6"
      d="m48.62 14.66 3.7 8.7 19.89 5.62 10.01-13.6z"
    />
    <path
      className="InstrumentCrystal_svg__st3"
      d="m83.97 46.93-1.75-31.55-10.01 13.6-2.16 16.32-1.22 9.24 16.18 11.1z"
    />
    <path
      className="InstrumentCrystal_svg__st4"
      d="m68.83 54.54 4.56 19.05 11.62-7.95z"
    />
    <path
      className="InstrumentCrystal_svg__st3"
      d="M68.83 54.54 47.9 77.36l-1.97 14.88 27.46-18.65z"
    />
    <path
      className="InstrumentCrystal_svg__st6"
      d="m47.9 77.36-12.98-9.93-9.09 2.9 20.1 21.91z"
    />
    <path
      style={{
        fill: "#f9ccc3",
      }}
      d="m36.35 43.53-10.37-5.32-.15 32.12 9.09-2.9z"
    />
    <path
      style={{
        fill: "#fdcc75",
      }}
      d="m36.35 43.53 15.97-20.17-3.7-8.7-22.64 23.55 10.37 5.32 15.97-20.17-15.97 20.17"
    />
    <path
      className="InstrumentCrystal_svg__st4"
      d="m52.32 23.36 19.89 5.62-3.38 25.56L47.9 77.36l-12.98-9.93 1.43-23.9z"
    />
    <path
      className="InstrumentCrystal_svg__st28"
      d="m94.81 15.63 1.3 3.51 3.5 1.29-3.5 1.3-1.3 3.5-1.3-3.5-3.5-1.3 3.5-1.29zM25.98 91.29l.62 1.66 1.66.62-1.66.61-.62 1.67-.61-1.67-1.67-.61 1.67-.62zM84.88 32.47l.9 2.21 2.42.82-2.42.81-.9 2.21-.9-2.21-2.43-.81 2.43-.82zM39.15 79.83l1.3 3.51 3.5 1.29-3.5 1.3-1.3 3.5-1.29-3.5-3.51-1.3 3.51-1.29zM20.96 15.63l1.29 3.51 3.51 1.29-3.51 1.3-1.29 3.5-1.3-3.5-3.5-1.3 3.5-1.29z"
    />
  </svg>
);

export default InstrumentCrystal;

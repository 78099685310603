import * as React from "react";

const InstrumentSweepPad = (props) => (
  <svg
    id="InstrumentSweepPad_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 111.03"
    style={{
      enableBackground: "new 0 0 110.84 111.03",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentSweepPad_svg__st3{fill:#4e4092}.InstrumentSweepPad_svg__st6{fill:#ca5672}.InstrumentSweepPad_svg__st10{fill:#538553}"
      }
    </style>
    
    <path
      className="InstrumentSweepPad_svg__st10"
      d="M94.17 111.03h-77.5C7.46 111.03 0 103.57 0 94.36v-77.5C0 7.66 7.46.19 16.67.19h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
    />
    <path
      d="M98.48 27.83H11.81a4.59 4.59 0 0 0-4.59 4.59V71.7c0 3.31 2.69 6 6 6h82.83c3.87 0 7-3.13 7-7V32.42a4.567 4.567 0 0 0-4.57-4.59z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      className="InstrumentSweepPad_svg__st10"
      d="M60.86 37.78c-1.92 0-3.48-1.56-3.48-3.48s1.56-3.48 3.48-3.48 3.48 1.56 3.48 3.48c-.01 1.92-1.56 3.48-3.48 3.48z"
    />
    <path
      className="InstrumentSweepPad_svg__st3"
      d="M79.57 30.82h17.67v16.76H79.57z"
    />
    <path
      className="InstrumentSweepPad_svg__st6"
      d="M71.95 37.78c-1.92 0-3.48-1.56-3.48-3.48s1.56-3.48 3.48-3.48 3.48 1.56 3.48 3.48-1.56 3.48-3.48 3.48zM60.86 48.13c-1.92 0-3.48-1.56-3.48-3.48s1.56-3.48 3.48-3.48 3.48 1.56 3.48 3.48c-.01 1.92-1.56 3.48-3.48 3.48z"
    />
    <path
      className="InstrumentSweepPad_svg__st10"
      d="M71.95 48.13c-1.92 0-3.48-1.56-3.48-3.48s1.56-3.48 3.48-3.48 3.48 1.56 3.48 3.48-1.56 3.48-3.48 3.48zM13.56 30.82h6.95v6.95h-6.95z"
    />
    <path
      className="InstrumentSweepPad_svg__st6"
      d="M46.84 30.82h6.95v6.95h-6.95zM13.56 41.18h6.95v6.95h-6.95z"
    />
    <path
      className="InstrumentSweepPad_svg__st10"
      d="M46.84 41.18h6.95v6.95h-6.95z"
    />
    <path
      style={{
        fill: "#fff",
      }}
      d="M13.56 55.61h83.68v15.94H13.56z"
    />
    <path
      className="InstrumentSweepPad_svg__st3"
      d="M17.07 55.61h3.12v9.7h-3.12zM22.56 55.61h3.12v9.7h-3.12zM28.06 55.61h3.12v9.7h-3.12zM35.45 55.61h3.12v9.7h-3.12zM40.53 55.61h3.12v9.7h-3.12zM48.31 55.61h3.12v9.7h-3.12zM53.81 55.61h3.12v9.7h-3.12zM59.3 55.61h3.12v9.7H59.3zM67.34 55.61h3.12v9.7h-3.12zM72.42 55.61h3.12v9.7h-3.12zM80.04 55.61h3.12v9.7h-3.12zM85.53 55.61h3.12v9.7h-3.12zM91.03 55.61h3.12v9.7h-3.12z"
    />
    <path
      className="InstrumentSweepPad_svg__st10"
      d="M26.84 39.68v8.86c0 .69.73 1.24 1.64 1.24.91 0 1.64-.56 1.64-1.24v-8.88l-3.28.02z"
    />
    <path
      className="InstrumentSweepPad_svg__st6"
      d="M30.12 31.76c0-.69-.73-1.24-1.64-1.24-.91 0-1.64.56-1.64 1.24v7.92l3.28-.02v-7.9zM41.43 31.76c0-.69-.73-1.24-1.64-1.24s-1.64.56-1.64 1.24v4.18h3.28v-4.18z"
    />
    <path
      className="InstrumentSweepPad_svg__st10"
      d="M38.15 35.94v12.6c0 .69.73 1.24 1.64 1.24.91 0 1.64-.56 1.64-1.24v-12.6h-3.28z"
    />
    <path
      className="InstrumentSweepPad_svg__st3"
      d="M36.99 34.66h5.61v2.57h-5.61zM25.68 38.01h5.61v2.57h-5.61z"
    />
  </svg>
);

export default InstrumentSweepPad;

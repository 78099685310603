import { useMemo } from "react";
import classNames from 'classnames';

import NavigationHeader from "../NavigationHeader";
import TRANSLATIONS_EN from "../../locale/translations_en";
import TRANSLATIONS_ES from "../../locale/translations_es";

import './MissingTranslations.scss';

// TRANSLATIONS_EN already includes the exact translations from getExactTranslations()
const translations = {
  en: TRANSLATIONS_EN,
  es: TRANSLATIONS_ES,
}

function checkMissing(lang, base = 'en') {
  const languageKeys = Object.keys(translations[lang]);
  const baseLanguage = Object.entries(translations[base]);

  const missing = {};

  let missingCount = 0;

  baseLanguage.forEach(([key, phrase]) => {
    if (!languageKeys.includes(key)) {
      missing[key] = phrase;
      missingCount++;
    }
  });

  return { missing, coverage: Math.round((baseLanguage.length - missingCount) * 100 / baseLanguage.length) };
}

export default function MissingTranslations() {

  const spanish = useMemo(() => checkMissing('es'), []);

  const toJSON = useMemo(() => {
    let json = '{\n';

    Object.entries(spanish.missing).forEach(([key, phrase]) => {
      json += `  '${key}': "${phrase}",\n`
    });

    json += "};"

    return json;
  }, [spanish]);

  const missingStringLength = Object.entries(spanish.missing).length;

  return (
    <div className='__missing-translations'>

      <NavigationHeader />

      <h1>Missing Translations</h1>

      <div className='coverage'>
        <div>SPANISH</div>
        <div className={classNames('percent', {'full-coverage': missingStringLength === 0})}>Coverage { spanish.coverage }%</div>
      </div>

      <p className='my-4 text-center'><strong>NOTE:</strong> This list only includes missing translations, not hardwired strings in English on the codebase.</p>
      
      { missingStringLength > 0 && (
        <>
          <table className="phrases">
            <thead>
              <th className='key'>Key</th>
              <th className='phrase'>Phrase</th>
            </thead>
            <tbody>
              { Object.entries(spanish.missing).map(([key, phrase]) => (
                <tr key={key}>
                  <td>{ key !== phrase ? key : '' }</td> <td>{ phrase }</td>
                </tr>
              ))}
            </tbody>
          </table>
    
          <h3>To JSON:</h3>
          <textarea value={toJSON} spellCheck={false} />
        </>
      )}

      </div>
  );
}

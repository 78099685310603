import { useMemo } from 'react';
import './LibraryEqualizer.scss';
import classnames from 'classnames';

export default function LibraryEqualizer({ ...props }) {

  const bars = useMemo(() => 
    new Array(4).fill(),
  []);

  return (
    <div className='__library-equalizer' {...props}>
      { bars.map((height, index) => <div key={index} className={classnames('bar')} style={{ height, animationDelay: -index / 2 + 's' }} />) }
    </div>
  );
}

import * as React from "react";

const InstrumentTremoloStrings = (props) => (
  <svg
    id="InstrumentTremoloStrings_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 111.15 110.84"
    style={{
      enableBackground: "new 0 0 111.15 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentTremoloStrings_svg__st92{fill:none;stroke:#fff;stroke-width:1.4918;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      className="InstrumentTremoloStrings_svg__st92"
      d="M2.53 34.81c1.96-.51 4.22-.84 6.62-.92 7.79-.26 14.2 2.21 14.31 5.51l.01.27c.11 3.3 6.51 5.77 14.31 5.51 4.81-.16 13.5-.96 14.02-7.11.18-2.15 2.37-3.3 4.2-4.03 3.02-1.21 6.37-1.64 9.6-1.74 7.79-.26 14.17 1.91 14.28 5.21l.03.3c.11 3.3 6.51 5.77 14.31 5.51 7.79-.26 14.02-3.14 13.91-6.44"
    />
    <path
      className="InstrumentTremoloStrings_svg__st92"
      d="M2.86 44.95c1.9-.47 4.05-.78 6.34-.85 7.79-.26 14.2 2.21 14.31 5.51l.01.27c.11 3.3 6.51 5.77 14.31 5.51 3.27-.11 6.66-.63 9.68-1.97 1.1-.49 2.16-1.09 3.03-1.93 1.37-1.31.89-3.08 1.66-4.57.51-1 1.88-1.75 2.84-2.22 3.24-1.6 7.06-2.07 10.62-2.19 7.79-.26 14.17 1.91 14.28 5.21l.03.3c.11 3.3 6.51 5.77 14.31 5.51 7.79-.26 14.02-3.14 13.91-6.44"
    />
    <path
      className="InstrumentTremoloStrings_svg__st92"
      d="M3.2 55.09c1.83-.44 3.89-.71 6.08-.79 7.79-.26 14.2 2.21 14.31 5.51l.01.27c.11 3.3 6.51 5.77 14.31 5.51 3.46-.11 7.1-.68 10.23-2.23 1.08-.53 2.17-1.21 2.92-2.16 1.01-1.3.52-3.13 1.5-4.42.69-.92 1.81-1.54 2.83-2.01 3.19-1.48 6.87-1.94 10.34-2.06 7.79-.26 14.17 1.91 14.28 5.21l.03.3c.11 3.3 6.51 5.77 14.31 5.51 7.79-.26 14.02-3.14 13.91-6.44"
    />
    <path
      className="InstrumentTremoloStrings_svg__st92"
      d="M3.53 65.23c1.76-.4 3.73-.65 5.8-.72 7.79-.26 14.2 2.21 14.31 5.51l.01.27c.11 3.3 6.51 5.77 14.31 5.51 3.02-.1 6.1-.56 8.93-1.66 2.08-.81 4.61-2.1 4.94-4.54.18-1.32.36-2.32 1.38-3.23.86-.77 1.94-1.3 3-1.72 3.02-1.2 6.35-1.63 9.58-1.73 7.79-.26 14.17 1.91 14.28 5.21l.03.3c.11 3.3 6.51 5.77 14.31 5.51s14.02-3.14 13.91-6.44"
    />
  </svg>
);

export default InstrumentTremoloStrings;

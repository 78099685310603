export default function ReggaeOutline(props) {
  return (
    <svg
      id="__reggae-outline-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 115.05 112.92"
      {...props}
    >
      <defs>
        <style>
          {
            ".__reggae-outline-prefix__cls-1{fill:none;stroke:#cfd4d8;stroke-miterlimit:10;stroke-width:2px}"
          }
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 28 copy"}</title>
      <rect
        className="__reggae-outline-prefix__cls-1"
        x={2}
        y={1.07}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
      />
      <path
        className="__reggae-outline-prefix__cls-1"
        d="M82.35 24.36C82.35 60 71.23 89 57.53 89S32.71 60 32.71 24.36"
      />
      <ellipse
        className="__reggae-outline-prefix__cls-1"
        cx={57.53}
        cy={24.36}
        rx={24.82}
        ry={6.85}
      />
      <path
        d="M39.64 69.14c-.95.58-1.23 1.05-1.23 1.27 0 .38.91 1.63 5.05 2.78a55.28 55.28 0 0014.06 1.6 55.28 55.28 0 0014.06-1.6c4.14-1.14 5.05-2.39 5.05-2.78 0-.21-.28-.69-1.24-1.27l.83-2.39c1.92 1 2.89 2.26 2.89 3.66 0 2.17-2.31 3.91-6.87 5.17a57.78 57.78 0 01-14.72 1.69 57.78 57.78 0 01-14.72-1.7c-4.56-1.26-6.87-3-6.87-5.17 0-1.4 1-2.62 2.87-3.65z"
        fill="#cfd4d8"
      />
      <path className="__reggae-outline-prefix__cls-1" d="M57.53 29.14v10.92" />
      <circle className="__reggae-outline-prefix__cls-1" cx={57.53} cy={40.49} r={2.66} />
      <path className="__reggae-outline-prefix__cls-1" d="M39.07 25.19v10.92" />
      <circle className="__reggae-outline-prefix__cls-1" cx={39.07} cy={36.54} r={2.66} />
      <path className="__reggae-outline-prefix__cls-1" d="M76.46 25.19v10.92" />
      <circle className="__reggae-outline-prefix__cls-1" cx={76.46} cy={36.54} r={2.66} />
      <path
        className="__reggae-outline-prefix__cls-1"
        d="M40.46 73.78l-6.18 19.99M75.46 73.78l6.18 19.99M57.53 75.94v24.47"
      />
    </svg>
  )
}

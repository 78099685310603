import * as React from "react";

const InstrumentElectricPiano1 = (props) => (
  <svg
    id="InstrumentElectric_Piano1_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 112.06 110.84"
    style={{
      enableBackground: "new 0 0 112.06 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".InstrumentElectric_Piano1_svg__st99{fill:#4e4093}"}</style>
    
    <path
      d="M94.82 0h-77.5C8.12 0 .65 7.46.65 16.67v77.5c0 9.21 7.46 16.67 16.67 16.67h77.5c9.21 0 16.67-7.46 16.67-16.67v-77.5C111.49 7.46 104.03 0 94.82 0z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      d="M100.8 54.94 15.9 31.97c-2.1-.57-4.26.67-4.83 2.77L5.94 53.72c-.57 2.1.67 4.26 2.77 4.83l84.9 22.97c2.1.57 4.26-.67 4.83-2.77l5.13-18.98a3.92 3.92 0 0 0-2.77-4.83z"
      style={{
        fill: "#cb5672",
      }}
    />
    <path
      d="M97.57 59.73 16.8 37.87l-4.16 15.39 80.78 21.86 4.15-15.39z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      className="InstrumentElectric_Piano1_svg__st99"
      d="m23.19 39.6-3.01-.81-2.53 9.36 3.01.81 2.53-9.36zM28.5 41.04l-3.01-.81-2.53 9.36 3.01.81 2.53-9.36zM33.81 42.47l-3.01-.81-2.53 9.36 3.01.81 2.53-9.36zM40.93 44.4l-3.01-.81-2.53 9.36 3.01.81 2.53-9.36zM45.84 45.73l-3.01-.81-2.53 9.36 3.01.81 2.53-9.36zM53.6 47.38l-3.03-.81-2.52 9.41 3.03.81 2.52-9.41zM58.66 49.2l-3.01-.81-2.53 9.36 3.01.81 2.53-9.36zM63.96 50.63l-3.01-.81-2.53 9.36 3.01.81 2.53-9.36zM71.72 52.73l-3.01-.81-2.53 9.36 3.01.81 2.53-9.36zM76.63 54.06l-3.01-.81-2.53 9.36 3.01.81 2.53-9.36zM83.98 56.05l-3.01-.81-2.53 9.36 3.01.81 2.53-9.36zM89.28 57.48l-3.01-.81-2.53 9.36 3.01.81 2.53-9.36zM94.59 58.92l-3.01-.81-2.53 9.36 3.01.81 2.53-9.36z"
    />
  </svg>
);

export default InstrumentElectricPiano1;

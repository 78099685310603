import { useState } from 'react';
import { Trans as Translate } from 'react-i18next';

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FeedbackModal from '../Feedback/FeedbackModal';

export default function ErrorModal({ show }) {

  const [showFeedback, setShowFeedback] = useState(false);
  
  const reloadPage = () => {
    window.location.reload(); 
  }

  return (
    <>
      <Modal className='__modal' show={show} backdrop='static' size='sm'>
        <Modal.Header>
          <Translate>Oops!</Translate>
        </Modal.Header>

        <Modal.Body>
          <p>There was an error processing your request.</p>
          <p>Please reload this page. If this error persists, <span role='button' className='strong-link' onClick={() => setShowFeedback(true)}>contact us here</span> or <a className='strong-link' href='https://discord.com/invite/7brwyydeyf'>through Discord.</a></p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={reloadPage}><Translate>Reload</Translate></Button>
        </Modal.Footer>
      </Modal>

      <FeedbackModal show={showFeedback} onClose={() => setShowFeedback(false)} />
    </>
  );
}

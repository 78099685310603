import { useEffect, useState, useRef } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { usersActions } from '../store/usersSlice';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router';
import { resetAccessToken } from '../api/strofeApi';
import { Trans as Translate } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import InfiniteLoader from "../layout/InfiniteLoader/InfiniteLoader";

import './LoginModal.scss';

// import RegistrationModal from './RegistrationModal';
import SSOButtons from './SSOButtons';

export default function LoginModal({ show, onClose, from, onForgotPassword, onLoggedIn, fetchUserAfterLogin }) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showInvalidInfo, setShowInvalidInfo] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  const history = useHistory();
  const emailRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (!show) {
      setPassword('');
      setShowInvalidInfo(false);
    }
    else {
      emailRef.current?.focus();
    }
  }, [show]);

  const dispatch = useDispatch();

  const handleSignUp = async (e) => {
    e.preventDefault();

    setShowInvalidInfo(false);
    setLoggingIn(true);

    let fetchedUser = null;
    
    try {
      const { data } = await dispatch(usersActions.login({ email, password })).unwrap();
      
      Cookies.set('api_access_token', data.access_token, { expires: 60 });
      Cookies.set('strofe_user_id', data.user_id, { expires: 60 });

      resetAccessToken(data.access_token);

      // When a user already exists (unregistered user clicks on sign up -> login), refetch current user:
      if (fetchUserAfterLogin) {
        const { data: userData } = dispatch(usersActions.fetchById({ id: data.user_id, isCurrentUser: true })).unwrap();
        fetchedUser = userData;
      }
      
      afterLogin(fetchedUser);
    }
    catch (error) {
      setShowInvalidInfo(true);
      setLoggingIn(false);

      console.log(error);
    }
  }

  const afterLogin = () => {
    onLoggedIn ? onLoggedIn() : history.push(from || '/library');
  }

  const changePassword = e => {
    setPassword(e.target.value)
  }

  const changeEmail = e => {
    setEmail(e.target.value);
  }

  const omniauthLogin = async(provider, code, useParam) => {
    setShowInvalidInfo(false);
    setLoggingIn(true);

    const referrer = Cookies.get('strofe_referrer');
    const inviter_display_name = Cookies.get('strofe_inviter_display_name');

    try {
      const action = await dispatch(usersActions.omniauthLogin({ provider, code, useParam, referrer, inviter_display_name }));
      const { data } = unwrapResult(action);
      Cookies.set('api_access_token', data.access_token, { expires: 60 });
      Cookies.set('strofe_user_id', data.user_id, { expires: 60 });
      Cookies.remove('strofe_referrer');
      Cookies.remove('strofe_inviter_display_name');

      resetAccessToken(data.access_token);
      afterLogin();
    }
    catch (error) {
      setShowInvalidInfo(true);
      setLoggingIn(false);

      console.log(error);
    }
  }

  const loginWithFacebook = () => {
    window.FB.login(() => omniauthLogin('facebook'), { scope: 'email' });
  }

  // tested errors: ["popup_closed_by_user"]
  //! untested: "access_denied" -> Strofe does not require consent
  const handleGoogleError = () => {
    // no-op
  }

  const loginWithGoogle = response => {
    omniauthLogin('google_oauth2', response.code);
  }

  const loginWithDiscord = code => {
    omniauthLogin('discord', code, true);
  }

  const loginWithRoblox = code => {
    omniauthLogin('roblox', code, true);
  }

  const submitDisabled = !email || !password;

  return (
    <>
      <Modal show={show} size='sm' backdrop='static' className='__modal __login-modal'>
        <Form onSubmit={handleSignUp}>
          <Modal.Header>
            <Modal.Title><Translate>Log In to Strofe</Translate></Modal.Title>
          </Modal.Header>

          <Modal.Body>

            { loggingIn && (
              <div className='logging-in'>
                <InfiniteLoader height={48} />
                <p>Signing in...</p>
              </div>
            )}

            <div className={classNames({ 'form-hidden': loggingIn })}>
              <SSOButtons onClickFacebook={loginWithFacebook} onSuccessGoogle={loginWithGoogle} onFailureGoogle={handleGoogleError} onSuccessDiscord={loginWithDiscord} onSuccessRoblox={loginWithRoblox} />

              <Form.Group>
                <Form.Control type="email" placeholder={t("Email address")} required value={email} onChange={changeEmail} ref={emailRef} data-test="LOGIN-email" />
              </Form.Group>

              <Form.Group>
                <Form.Control type="password" placeholder={t("Password")} required minLength={6} maxLength={20} value={password} onChange={changePassword} data-test="LOGIN-password" />
              </Form.Group>

              <div className='text-center'>
                { showInvalidInfo && (
                  <Alert variant='warning'>
                    <Translate i18nKey="invalid-email-password">Your email/password combination is not valid.</Translate>
                  </Alert>
                )}
                <Button variant='link' size='sm' className='text-center' onClick={onForgotPassword}><Translate>Forgot password?</Translate></Button>

                {/* <p className='small mt-2'><strong>Not a member? </strong><span role='button' className='btn-link' onClick={null}><strong>Create an account.</strong></span></p> */}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={onClose} disabled={loggingIn}><Translate>Cancel</Translate></Button>
            <Button variant="primary" type="submit" disabled={submitDisabled || loggingIn} data-test="LOGIN-submit"><Translate>Login</Translate></Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* <RegistrationModal showWarmup={false} /> */}
    </>
  );
}
import { useEffect, useState } from 'react';
import { Trans as Translate, useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { CATEGORIES } from './NoteSequences';

const PHRASES = {
  loop: {
    title: 'Create Loop',
    label: "Select the loop's category",
  },

  add: {
    title: 'Add instrument',
    label: "Select category",
  },
}

const ADD_CATEGORIES = {
  tenor            : 'Tenor',
  alto             : 'Alto',
  secondary_melody : 'Secondary Melody',
}

export default function InstrumentCategoryModal({ show, from = 'loop', onHide, onSubmit }) {

  const [category, setCategory] = useState('chords');
  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      setCategory('chords');
    }
  }, [show]);

  const shownCategories = Object.entries(CATEGORIES);

  return (
    <Modal className='__modal' show={show} size='sm' backdrop='static'>
      <Modal.Header>
        <Modal.Title><Translate>{ PHRASES[from].title }</Translate></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form className='my-2'>
          <Form.Label><Translate>{ PHRASES[from].label }</Translate></Form.Label>
          <Form.Control className='my-2' as='select' value={category} onChange={e => setCategory(e.target.value)}>
            { shownCategories.map(([key, cat]) => <option key={key} value={key}>{ t(cat) }</option> ) }
            { from === 'add' && Object.entries(ADD_CATEGORIES).map(([key, cat]) => <option key={key} value={key}>{ t(cat) }</option> ) }
          </Form.Control>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}><Translate>Cancel</Translate></Button>
        <Button variant="primary" onClick={() => onSubmit(category)}><Translate>Create</Translate></Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function PencilIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.2 46.1" {...props}>
    <style>
      {".__pencil-icon-0{fill:#CED025;}.__pencil-icon-1{fill:#F18860;}.__pencil-icon-2{fill:#C95671;}"}
    </style>
    <g>
      <rect x="3.4" y="16.8" transform="matrix(0.7396 -0.6731 0.6731 0.7396 -8.205 23.3378)" className="__pencil-icon-0" width="45.2" height="11"/>
      <path className="__pencil-icon-1" d="M3.3,35.5L0.4,44c0,0.1-0.1,0.4,0,0.6c0.2,0.8,0.9,1.3,1.6,1.1l8.8-2.1L3.3,35.5z"/>
      <path className="__pencil-icon-2" d="M48.9,2.6c1.9,2,1.7,5.2-0.4,7.1l0,0l0,0l-7.5-8.3l0,0l0,0c2-1.9,5.2-1.7,7.1,0.4L48.9,2.6L48.9,2.6z"/>
    </g>
    </svg>
  )
}

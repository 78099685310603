export default function DurationIcon(props) {
  return (
    <svg x={0} y={0} viewBox="0 0 29.7 26.1" xmlSpace="preserve" {...props}>
      <style>
        {
          ".__duration-icon__st0{fill:none;stroke:#ced025;stroke-width:3.1749;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
        }
      </style>
      <circle className="__duration-icon__st0" cx={14.8} cy={13.1} r={11.3} />
      <path className="__duration-icon__st0" d="M14.8 6.4v6.7h3.7" />
    </svg>
  );
}

import { strofeApi } from '../../../api/strofeApi';
import { useState, useEffect } from 'react';

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";

export default function SlideTraction() {
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    const getUserStats = async () => {
      const response = await strofeApi.get(process.env.REACT_APP_NOMODO_API + `/users/stats`);
      setUserCount(response.data.total);
    }
    getUserStats();
  }, []);

  return (
    <div className='slide slide-traction'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <div className='traction-bullet'>
          <div className='orange-circle' />
          <div className='lime-circle' />
          <div className='yellow-circle' />
        </div>
        <h1>Our progress after less than a year</h1>
      </div>

      <div className='content'>
        <div className='circle lime'>
          <h3>{userCount.toLocaleString()}</h3>
          <p>Total Users</p>
        </div>

        <div className='circle papaya'>
          <h3>7,510</h3>
          <p>MAU</p>
        </div>

        <div className='circle orange'>
          <h3>141</h3>
          <p>Customers</p>
        </div>
      </div>
    </div>
  )
}

import * as React from "react";

const InstrumentClarinet = (props) => (
  <svg
    id="InstrumentClarinet_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentClarinet_svg__st46,.InstrumentClarinet_svg__st5{fill:#cfd125}.InstrumentClarinet_svg__st46{stroke:#cfd125;stroke-width:.5;stroke-miterlimit:10}"
      }
    </style>
    <path
      d="M94.52 110.84h-77.5c-9.21 0-16.67-7.46-16.67-16.67v-77.5C.35 7.46 7.81 0 17.02 0h77.49c9.21 0 16.67 7.46 16.67 16.67v77.5c.01 9.21-7.45 16.67-16.66 16.67z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      d="M9.78 83.92s.91 13.11 12.37 12.92c-.37 0 .12-2.69.16-2.92.8-4.68 5.53-8.77 8.67-12.1 4.25-4.51 8.65-8.87 13.07-13.19 5-4.88 10.05-9.7 15.13-14.5 4.59-4.33 9.2-8.63 13.82-12.92 3.03-2.82 6.08-5.62 9.1-8.46.51-.47 1.01-.95 1.51-1.43L94.2 20.88c2.03-2.01 4.06-4.01 6.09-6.02.05-.05.81-.73.78-.77 0 0-.51-.99-1.72-.7-1.21.29-1.55.48-3.23.21-.32-.05-.94.57-1.17.74-.92.69-1.81 1.44-2.7 2.17-2.3 1.92-40.91 39-64.03 58.33-2.67 2.24-6.24 5.11-9.26 6.85-2.7 1.54-6 2.11-9.18 2.23z"
      style={{
        fill: "#4e4092",
        stroke: "#4e4092",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <circle
      className="InstrumentClarinet_svg__st5"
      cx={44.91}
      cy={69.74}
      r={2.02}
    />
    <circle
      className="InstrumentClarinet_svg__st5"
      cx={52.67}
      cy={62.49}
      r={1.8}
    />
    <circle
      className="InstrumentClarinet_svg__st46"
      cx={48.39}
      cy={51.11}
      r={1.33}
    />
    <circle
      className="InstrumentClarinet_svg__st46"
      cx={52.47}
      cy={47.65}
      r={1.33}
    />
    <circle
      className="InstrumentClarinet_svg__st46"
      cx={56.4}
      cy={44.22}
      r={1.33}
    />
    <path
      d="M29.13 73.22s4.82 1.31 5.37 5.87"
      style={{
        fill: "none",
        stroke: "#f18860",
        strokeWidth: 1.8294,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M83.03 23.34s4.33 1.18 4.81 5.26"
      style={{
        fill: "none",
        stroke: "#f18860",
        strokeWidth: 1.6401,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M91.28 15.65s4.09 1.11 4.55 4.97"
      style={{
        fill: "none",
        stroke: "#f18860",
        strokeWidth: 1.5505,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
  </svg>
);

export default InstrumentClarinet;

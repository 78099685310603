import * as React from "react";
const SvgInstrumentDrumsDance = (props) => (
  <svg
    id="InstrumentDrumsDance_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentDrumsDance_svg__st99{fill:#f48864}.InstrumentDrumsDance_svg__st101{fill:#4b4a9e}.InstrumentDrumsDance_svg__st102{fill:#528459}.InstrumentDrumsDance_svg__st103{fill:#c55a74}"
      }
    </style>

    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#f8c4b9",
      }}
    />
    <ellipse
      transform="rotate(-17.254 92.226 16.547)"
      className="InstrumentDrumsDance_svg__st102"
      cx={92.21}
      cy={16.55}
      rx={11.64}
      ry={1.83}
    />
    <path
      className="InstrumentDrumsDance_svg__st102"
      d="M92.13 84.7a.56.56 0 0 1-.56-.56V17.11a.56.56 0 1 1 1.12 0v67.03c0 .31-.25.56-.56.56z"
    />
    <path
      className="InstrumentDrumsDance_svg__st102"
      d="m80.49 88.39 11.2-17.76c.19-.3.1-.7-.2-.9l-.05-.03a.66.66 0 0 0-.9.2l-11.2 17.76c-.19.3-.1.7.2.9l.05.03c.31.19.71.1.9-.2zM103.72 88.39l-11.2-17.76c-.19-.3-.1-.7.2-.9l.05-.03c.3-.19.7-.1.9.2l11.2 17.76c.19.3.1.7-.2.9l-.05.03c-.31.19-.71.1-.9-.2z"
    />
    <ellipse
      transform="rotate(-70.61 23.037 32.278)"
      className="InstrumentDrumsDance_svg__st102"
      cx={23.04}
      cy={32.28}
      rx={2.89}
      ry={13.39}
    />
    <path
      className="InstrumentDrumsDance_svg__st102"
      d="M22.28 88.63a.57.57 0 0 1-.57-.57V32.84a.57.57 0 1 1 1.14 0v55.22c-.01.32-.26.57-.57.57z"
    />
    <path
      className="InstrumentDrumsDance_svg__st102"
      d="m13.4 92.13 8.51-16.84c.15-.29.08-.67-.15-.85l-.04-.03c-.23-.18-.54-.1-.68.19l-8.51 16.84c-.15.29-.08.67.15.85l.04.03c.23.18.54.1.68-.19zM31.23 92.13l-8.51-16.84c-.15-.29-.08-.67.15-.85l.04-.03c.23-.18.54-.1.68.19l8.51 16.84c.15.29.08.67-.15.85l-.04.03c-.23.18-.54.1-.68-.19z"
    />
    <ellipse
      className="InstrumentDrumsDance_svg__st103"
      cx={86.36}
      cy={36.6}
      rx={9.41}
      ry={1.65}
    />
    <ellipse
      className="InstrumentDrumsDance_svg__st102"
      cx={86.19}
      cy={34.1}
      rx={9.41}
      ry={1.65}
    />
    <path
      className="InstrumentDrumsDance_svg__st99"
      d="M86.48 71.81h-.25a.38.38 0 0 1-.38-.38V37.1c0-.21.17-.38.38-.38h.25c.21 0 .38.17.38.38v34.33c0 .21-.17.38-.38.38z"
    />
    <path
      className="InstrumentDrumsDance_svg__st99"
      d="m80.61 70.5 5.52-11.4c.14-.29.07-.66-.15-.84l-.24-.2c-.22-.18-.51-.09-.65.19l-5.52 11.4c-.14.29-.07.66.15.84l.24.2c.22.18.51.1.65-.19zM92.11 70.5l-5.52-11.4c-.14-.29-.07-.66.15-.84l.24-.2c.22-.18.51-.09.65.19l5.52 11.4c.14.29.07.66-.15.84l-.24.2c-.23.18-.52.1-.65-.19zM76.43 86.77h-.27c-.17 0-.32-.14-.32-.32V60.22c0-.17.14-.32.32-.32h.27c.17 0 .32.14.32.32v26.23c-.01.17-.15.32-.32.32z"
    />
    <path
      className="InstrumentDrumsDance_svg__st99"
      d="m71.18 85.76 4.91-8.73c.12-.22.06-.51-.13-.65l-.21-.15c-.2-.14-.45-.07-.58.15l-4.91 8.73c-.12.22-.06.51.13.65l.21.15c.2.14.46.07.58-.15zM81.4 85.76l-4.91-8.73a.518.518 0 0 1 .13-.65l.21-.15c.2-.14.45-.07.58.15l4.91 8.73c.12.22.06.51-.13.65l-.21.15c-.19.14-.45.07-.58-.15z"
    />
    <path
      className="InstrumentDrumsDance_svg__st103"
      d="M71.05 51.29c.84-.74 2.05-.83 2.75-1.85.43-.63.69-1.96 1.56-2.04.85-.08 1.42 1.33 2.07 1.79.9.64 2.74.87 3.98 1.6 0 0-1.66-1.19-2.99-2.52-1.33-1.32-2.14-6.92-2.14-6.92s-3.29-.31-5.26.23c-1.97.53-3.89 1.69-3.89 1.69l2.79 10c.11-.78.52-1.45 1.13-1.98z"
    />
    <path
      className="InstrumentDrumsDance_svg__st99"
      d="M57.5 52.27c.64-.69 1.13-2.22 2.28-2.22 1.12 0 1.7 1.7 2.5 2.31.97.73 2.92 1.18 4.42 1.94 0 0-2.35-1.38-2.45-3.03-.11-1.65-.16-7.14-.16-7.14s-1.33-1.49-5.91-1.17c-4.58.32-5.65 1.5-5.65 1.5l.69 10.02c.14-.12.29-.23.46-.34 1.2-.78 2.79-.76 3.82-1.87z"
    />
    <path
      className="InstrumentDrumsDance_svg__st101"
      d="M66.71 54.3c-1.5-.77-3.45-1.21-4.42-1.94-.8-.61-1.39-2.31-2.5-2.31-1.15 0-1.65 1.53-2.28 2.22-1.03 1.11-2.62 1.1-3.81 1.87-.16.11-.31.22-.46.34-.88.73-1.41 1.7-1.34 2.92.18 2.94 2.28 5.48 4.93 6.63 2.85 1.24 6.04.6 8.48-1.26 1.78-1.35 3.43-3.58 3.43-5.91-.01-1.22-.89-1.98-2.03-2.56z"
    />
    <path
      className="InstrumentDrumsDance_svg__st99"
      d="M82.59 49.41c-.11-.9.1-1.62.5-2.13.41-.53 1.02-.58 1.35-1.32.2-.46.3-1.45.75-1.49 0 0-.89-.09-1.05-1.29-.16-1.2-1.16-3.08-1.16-3.08s-5.71.77-6.53 2.18c0 0 1 5.6 1.3 6.42.29.82 4.28 5.36 7.71 5.01h.02c-.21-.05-.42-.12-.63-.25-1.08-.64-2.02-2.19-2.26-4.05z"
    />
    <path
      className="InstrumentDrumsDance_svg__st101"
      d="M88.99 48.49c-.16-1.8-1.99-1.89-2.72-2.65-.34-.36-.66-1.42-1.09-1.38-.44.04-.54 1.03-.75 1.49-.33.74-.94.79-1.35 1.32-.4.52-.61 1.23-.5 2.13.24 1.87 1.18 3.41 2.26 4.05.21.12.42.2.63.25.95.22 1.86-.3 2.54-1.33.61-.92 1.11-2.39.98-3.88zM82.67 52.58c-.08-.84-.58-1.39-1.25-1.78-1.24-.74-3.08-.97-3.98-1.6-.66-.46-1.23-1.86-2.07-1.79-.88.08-1.13 1.41-1.56 2.04-.7 1.01-1.91 1.11-2.75 1.85-.6.53-1.01 1.2-1.12 1.99-.04.28-.04.58.01.89.36 2.48 2.14 4.49 4.24 5.29 2.26.86 4.63.1 6.34-1.64 1.23-1.28 2.31-3.27 2.14-5.25zM24.59 54.64c4.77 0 8.63-.89 8.63-1.98 0-1.09-3.86-1.98-8.63-1.98s-8.63.89-8.63 1.98c0 1.09 3.86 1.98 8.63 1.98zM19.88 67.78c-.09-1.46-2.46-.94-3.42-1.18-.7-.17-1.35-.46-1.96-.85-1.2-.76-2.2-1.79-3.41-2.53-.92-.56-2.07-1.36-3.2-1.27-.11.01-.22.03-.33.05l-.95.41c-.39.25-.72.57-.98.92-1.04 1.44-.96 3.45-.76 5.13.21 1.72.67 3.39 1.03 5.08.8 3.84 6.71 8.07 10.63 8.31.83.05 1.99-.02 2.57-.72 1.22-1.2.97-10.11.78-13.35z"
    />
    <path
      className="InstrumentDrumsDance_svg__st99"
      d="M24.59 54.64c-4.77 0-8.63-.89-8.63-1.98v5.61c-.77.44-6.18 2.78-8.4 3.73.11-.02.22-.04.33-.05 1.14-.09 2.29.71 3.2 1.27 1.21.74 2.21 1.77 3.41 2.53.61.38 1.26.67 1.96.85.96.24 3.33-.28 3.42 1.18.19 3.24.44 12.15-.79 13.34 0 0 9.34-9.66 11.09-12.38.94-1.45 1.73-4.58 2.27-7.19.48-2.3.75-4.2.75-4.2v-4.69c.02 1.09-3.84 1.98-8.61 1.98z"
    />
    <path
      className="InstrumentDrumsDance_svg__st103"
      d="M50.73 44.45c-6.39-3.46-13.39-1.28-13.39-1.28l-.57 6.87c1.2 0 1.83 2.27 2.7 3.08 1.84 1.72 6.95 2.24 6.95 6.01 0 1.14-.24 2.26-.64 3.31 3.73-4.47 4.95-17.99 4.95-17.99z"
    />
    <path
      className="InstrumentDrumsDance_svg__st101"
      d="M39.47 53.12c-.87-.81-1.49-3.08-2.7-3.08-1.24 0-1.77 2.05-2.46 2.96-1.11 1.48-2.83 1.46-4.1 2.5-1.24 1-2.03 2.45-1.93 4.34.2 3.92 2.46 7.31 5.31 8.84 3.07 1.65 6.51.79 9.14-1.68 1.21-1.14 2.37-2.75 3.05-4.56.4-1.05.64-2.17.64-3.31-.01-3.76-5.12-4.28-6.95-6.01zM40.71 72.57c-.82-.23-1.59-.33-2.25-.21-.24.04-.47.11-.68.22-3.12 1.52-2.08 7.91-2.08 10.23 0 2.3-.01 5.85 1.6 7.16a12.754 12.754 0 0 0 .96.65c2.76 1.7 9.48 5.01 12.07 4.17 2.96-.96 2.48-2.8 2.48-3.84 0-.12-.01-.33-.02-.62-.05-1.19-.2-3.67-.3-5.99-6.34-.37-11.4-5.43-11.78-11.77zM69.85 72.58c-.11-.05-.22-.1-.33-.13-1.03-.33-2.33-.04-3.74.5-.52 5.78-4.95 10.42-10.63 11.28-.1 2.32-.25 4.84-.31 6.07-.01.31-.02.54-.02.66 0 1.04-.48 2.88 2.48 3.84s11.35-3.52 12.99-4.8 1.63-4.87 1.63-7.19c.01-2.32 1.04-8.71-2.07-10.23z"
    />
    <path
      className="InstrumentDrumsDance_svg__st99"
      d="M52.41 79.69c.16-2-.56-2.8-1.84-2.88-2.56-.36-6.65-3.34-9.85-4.24.38 6.34 5.44 11.4 11.77 11.78-.09-1.98-.15-3.84-.08-4.66zM55.22 79.69c.06.8.01 2.6-.07 4.54 5.68-.86 10.11-5.5 10.63-11.28-2.99 1.17-6.45 3.55-8.72 3.86-1.28.08-1.99.88-1.84 2.88z"
    />
    <path
      className="InstrumentDrumsDance_svg__st103"
      d="M65.48 68.89c-1.32-5.54-6.29-9.66-12.23-9.66-6.26 0-11.44 4.58-12.41 10.58-.09.1-.17.2-.27.29-.51.46-1.32 1.35-2.12 2.26.67-.12 1.43-.02 2.25.21 3.21.9 7.29 3.89 9.85 4.24 1.28.08 2 .88 1.84 2.88-.07.82-.01 2.68.08 4.66.1 2.31.25 4.79.3 5.99.69-.01 1.38-.03 2.06-.04.06-1.23.2-3.75.31-6.07.08-1.94.14-3.74.07-4.54-.16-2 .56-2.8 1.84-2.88 2.27-.31 5.73-2.69 8.72-3.86 1.41-.55 2.71-.83 3.74-.5-1.02-1.25-2.82-2.53-4.03-3.56z"
    />
  </svg>
);

export default SvgInstrumentDrumsDance;


import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";
import SlideController from "../assets/SlideController";
import SlidePic from "../assets/SlidePic";
import SlideCube from "../assets/SlideCube";

import Roblox from '../assets/logos/roblox.png';
import Adobe from '../assets/logos/adobe.png';
import Autodesk from '../assets/logos/autodesk.png';

export default function SlideSuccess() {
  return (
    <div className='slide slide-success white-bg'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <div className='success-bullet'>
          <div className='purple-circle' />
          <div className='orange-circle' />
          <div className='lime-circle' />
        </div>
        <h1>Relevant Comparisons</h1>
      </div>

      <div className='content'>
        <div className='section adobe'>
          <div className='logo'><img src={Adobe} alt="adobe" /></div>
          <div className='details'>
            <div className='valuation'>$243B</div>
            <div className='art'><SlidePic /></div>
          </div>
        </div>

        <div className='section autodesk'>
          <div className='logo'><img src={Autodesk} alt="autodesk" /></div>
          <div className='details'>
            <div className='valuation'>$52B</div>
            <div className='art'><SlideCube /></div>
          </div>
        </div>

        <div className='section roblox'>
          <div className='logo'><img src={Roblox} alt="roblox" /></div>
          <div className='details'>
            <div className='valuation'>$36B</div>
            <div className='art'><SlideController /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

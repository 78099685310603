import * as React from "react"

export default function MoodSpookyIcon(props) {
  return (
    <svg viewBox="0 0 360 360" {...props}>
      <style>
        {
          ".__spooky-icon-st6{fill:#fff}.__spooky-icon-st7,.__spooky-icon-st9{fill:none;stroke:#fff;stroke-width:33;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.__spooky-icon-st9{stroke-width:18}"
        }
      </style>
      <path
        d="M292.8 359.5H67.2c-34.5 0-62.8-28.3-62.8-62.8V63.4C4.4 28.9 32.7.6 67.2.6h225.6c34.5 0 62.8 28.3 62.8 62.8v233.3c0 34.5-28.3 62.8-62.8 62.8z"
        fill="#c85771"
      />
      <circle className="__spooky-icon-st6" cx={103.6} cy={136.8} r={33.8} />
      <circle className="__spooky-icon-st6" cx={245.7} cy={128.2} r={71.4} />
      <path
        className="__spooky-icon-st7"
        d="M43.7 114.5s3.9-32.8 38.6-38.6M325.4 62.4S315 31 279.8 32.4"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeWidth={21}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M66 261.3l218.1-23.2"
      />
      <path
        className="__spooky-icon-st9"
        d="M84.3 227.5l-3.8 44.4M111.4 233.3l5.7 50.1M130.7 239.1l7.7 32.8M159.6 231.3l1.9 46.4M190.5 239.1l5.8 30.8M213.6 229.4l7.7 32.8M250.3 227.5l-1.9 46.3M269.6 225.6v34.7"
      />
    </svg>
  )
}

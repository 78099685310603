import StrofeLogoColors from "../../../icons/StrofeLogoColors";

export default function SlideCover() {
  return (
    <div className='slide slide-cover'>
      <StrofeLogoColors width={480} viewBox="0 400 2000 1200" />
      <h2>Make your own music in seconds</h2>
    </div>
  )
}

import * as React from "react";

const InstrumentHaloPad = (props) => (
  <svg
    id="InstrumentHaloPad_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentHaloPad_svg__st3{fill:#4e4092}.InstrumentHaloPad_svg__st4{fill:#f18860}.InstrumentHaloPad_svg__st6{fill:#ca5672}.InstrumentHaloPad_svg__st10{fill:#538553}.InstrumentHaloPad_svg__st29{fill:#2b4c5c}"
      }
    </style>
    
    <path
      className="InstrumentHaloPad_svg__st3"
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
    />
    <path
      d="M98.48 27.64H11.81a4.59 4.59 0 0 0-4.59 4.59V71.5c0 3.31 2.69 6 6 6h82.83c3.87 0 7-3.13 7-7V32.22a4.564 4.564 0 0 0-4.57-4.58z"
      style={{
        fill: "#cfd125",
      }}
    />
    <path
      className="InstrumentHaloPad_svg__st10"
      d="M57.38 30.63h6.95v6.95h-6.95z"
    />
    <path
      className="InstrumentHaloPad_svg__st3"
      d="M79.57 30.63h6.95v6.95h-6.95z"
    />
    <path
      className="InstrumentHaloPad_svg__st4"
      d="M68.48 30.63h6.95v6.95h-6.95z"
    />
    <path
      className="InstrumentHaloPad_svg__st6"
      d="M90.67 30.63h6.95v6.95h-6.95zM57.38 40.99h6.95v6.95h-6.95z"
    />
    <path
      className="InstrumentHaloPad_svg__st4"
      d="M79.57 40.99h6.95v6.95h-6.95z"
    />
    <path
      className="InstrumentHaloPad_svg__st3"
      d="M68.48 40.99h6.95v6.95h-6.95z"
    />
    <path
      className="InstrumentHaloPad_svg__st10"
      d="M90.67 40.99h6.95v6.95h-6.95z"
    />
    <circle
      className="InstrumentHaloPad_svg__st4"
      cx={18.62}
      cy={35.9}
      r={3.94}
    />
    <circle
      className="InstrumentHaloPad_svg__st3"
      cx={31.55}
      cy={44.46}
      r={3.94}
    />
    <circle
      className="InstrumentHaloPad_svg__st10"
      cx={44.47}
      cy={35.9}
      r={3.94}
    />
    <path
      style={{
        fill: "#fff",
      }}
      d="M13.56 55.42h83.68v15.94H13.56z"
    />
    <path
      className="InstrumentHaloPad_svg__st29"
      d="M17.07 55.42h3.12v9.7h-3.12zM22.56 55.42h3.12v9.7h-3.12zM28.06 55.42h3.12v9.7h-3.12zM35.45 55.42h3.12v9.7h-3.12zM40.53 55.42h3.12v9.7h-3.12zM48.31 55.42h3.12v9.7h-3.12zM53.81 55.42h3.12v9.7h-3.12zM59.3 55.42h3.12v9.7H59.3zM67.34 55.42h3.12v9.7h-3.12zM72.42 55.42h3.12v9.7h-3.12zM80.04 55.42h3.12v9.7h-3.12zM85.53 55.42h3.12v9.7h-3.12zM91.03 55.42h3.12v9.7h-3.12z"
    />
  </svg>
);

export default InstrumentHaloPad;

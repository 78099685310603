export default function JazzIcon(props) {
  return (
    <svg
      id="__jazz-icon-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110.84 112.61"
      {...props}
    >
      <defs>
        <style>
          {".__jazz-icon-prefix__cls-2{fill:#ced025}.__jazz-icon-prefix__cls-5{fill:#c95671}"}
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 39"}</title>
      <rect
        y={1.77}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
        fill="#f8ccc2"
      />
      <circle className="__jazz-icon-prefix__cls-2" cx={75.82} cy={49.05} r={7.7} />
      <circle cx={64.63} cy={38.93} r={4.55} fill="#f18860" />
      <circle cx={77.89} cy={28.57} r={4.55} fill="#4d3f92" />
      <path
        className="__jazz-icon-prefix__cls-5"
        d="M45.68 84.91A14.09 14.09 0 0063.2 95.35a13.55 13.55 0 009.69-9 14 14 0 00-.42-10.43 14.87 14.87 0 01.36-13c.4-.79.91-1.53 1.39-2.32l-17.35-8.77c0 .23-.1.42-.13.6a42.22 42.22 0 001.48 18.21c.66 2.14 1.58 4.21 2.35 6.32a3.43 3.43 0 01-1 3.88 3.52 3.52 0 01-4.06.38 4 4 0 01-1.82-2.53l-20.07-60.5a7.09 7.09 0 00-4.73-4.77 7 7 0 00-7.6 1.9 6.93 6.93 0 00-1.94 6.29 1.44 1.44 0 002.24 1c.54-.4.52-1 .37-2.42A3.75 3.75 0 0125.34 16c2.07-.17 3.58 1 4.15 3.35 1.73 6.97 13.7 55.55 16.19 65.56z"
        id="__jazz-icon-prefix___6Tvu8w_tif"
        data-name="6Tvu8w.tif"
      />
      <path
        className="__jazz-icon-prefix__cls-5"
        d="M77.61 60.09a1.88 1.88 0 01-.88-.22l-20.3-10.7a1.89 1.89 0 111.77-3.35l20.29 10.7a1.89 1.89 0 01-.89 3.57z"
      />
      <circle className="__jazz-icon-prefix__cls-2" cx={38.28} cy={42.41} r={1.06} />
      <circle className="__jazz-icon-prefix__cls-2" cx={40.09} cy={49.05} r={1.06} />
      <circle className="__jazz-icon-prefix__cls-2" cx={41.77} cy={55.69} r={1.06} />
    </svg>
  )
}

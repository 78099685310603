export default function LofiIcon(props) {
  return (
    <svg
      id="__lofi-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110.84 110.84"
      {...props}
    >
      <defs>
        <style>
          {
            ".__lofi-prefix__cls-1{fill:#4d3f92}.__lofi-prefix__cls-2{fill:#f18860}.__lofi-prefix__cls-4{fill:#ced025}"
          }
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 14 copy"}</title>
      <rect
        className="__lofi-prefix__cls-1"
        x={0.37}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
      />
      <rect
        className="__lofi-prefix__cls-2"
        x={11}
        y={30.84}
        width={88.64}
        height={49.89}
        rx={8.94}
        ry={8.94}
        transform="rotate(-20.02 55.347 55.804)"
      />
      <rect
        x={19.65}
        y={42.52}
        width={72.82}
        height={25.88}
        rx={7.35}
        ry={7.35}
        transform="rotate(-20.02 56.079 55.466)"
        fill="#fdcb75"
      />
      <rect
        className="__lofi-prefix__cls-2"
        x={28.75}
        y={46.6}
        width={55.14}
        height={17.63}
        rx={8.82}
        ry={8.82}
        transform="rotate(-20.02 56.32 55.42)"
      />
      <circle
        className="__lofi-prefix__cls-4"
        cx={38.7}
        cy={61.84}
        r={8.82}
        transform="rotate(-20.02 38.708 61.864)"
      />
      <circle
        className="__lofi-prefix__cls-4"
        cx={73.94}
        cy={49}
        r={8.82}
        transform="rotate(-20.02 73.955 49.011)"
      />
      <circle
        className="__lofi-prefix__cls-1"
        cx={38.7}
        cy={61.84}
        r={1.7}
        transform="rotate(-20.02 38.708 61.864)"
      />
      <circle
        className="__lofi-prefix__cls-1"
        cx={74.2}
        cy={48.9}
        r={1.7}
        transform="rotate(-20.02 74.207 48.91)"
      />
      <path
        strokeLinecap="round"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={1.41}
        d="M42.98 85.63l5.37-9.08 26.29-9.58 9.95 3.5"
      />
      <rect
        x={28.75}
        y={46.6}
        width={55.14}
        height={17.63}
        rx={8.82}
        ry={8.82}
        transform="rotate(-20.02 56.32 55.42)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={1.41}
      />
    </svg>
  )
}

import * as React from "react";

const InstrumentFretlessBass = (props) => (
  <svg
    id="InstrumentFretlessBass_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.45"
    style={{
      enableBackground: "new 0 0 110.84 110.45",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentFretlessBass_svg__st5{fill:#cfd125}.InstrumentFretlessBass_svg__st6{fill:#ca5672}"
      }
    </style>
    
    <path
      className="InstrumentFretlessBass_svg__st6"
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
    />
    <path
      className="InstrumentFretlessBass_svg__st5"
      d="M13.51 89.48c1.57 2.16 3.4 4.15 5.27 6.06 1.64 1.69 3.55 3.32 5.97 3.65 4.64.62 8.33-3.23 9.85-7.26.6-1.59.81-3.31 1.25-4.94.45-1.64 1.22-3.32 2.43-4.58 1.45-1.51 3.35-1.94 5.2-2.79 2.61-1.2 5-2.94 6.92-5.09 1.36-1.52 3.37-4.42 2.07-6.5-.8-1.29-2.2-.6-3.2.01-1.22.74-2.32 1.69-3.87 1.42-1.34-.23-2.67-1.38-2.44-2.83.21-1.31.68-1.82.68-1.82l-.93-7.56s-1.12.37-1.89-.51c-.78-.88-.73-1.68-.18-2.85.93-1.98 2.94-3.3 3.77-5.32.48-1.16.03-2.46-1.14-2.97-2.84-1.23-5.96 1.47-7.79 3.34-2.5 2.56-3.83 5.68-5.65 8.71-1.76 2.91-4.96 3.92-8.04 4.97 0 0-8.89 1.79-12.02 7.67-1.26 2.38-1.76 5.1-1.45 7.77.32 2.78 1.54 5.26 2.86 7.68.69 1.28 1.46 2.55 2.33 3.74z"
    />
    <path
      d="M29.31 81.05c.2 1.29.11 2.61-.01 3.91-.22 2.39-.93 4.67-.93 7.09 0 1.11-.08 2.9 1.23 3.35 2.03.71 3.33-4.94 4.29-8.25.96-3.31 2.08-4.84 2.08-4.84s-2.32-2.03-3.05-2.13c-.73-.09-3.61.87-3.61.87z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      d="M50.85 69.25c-.21-.03-1.32.7-1.72.97-.92.62-2.05.94-3.16.92-2.09-.03-3.85-1.35-4.72-3.18-1.24-2.59-1.37-5.56-1.5-8.38-.11-2.3-.83-2.26-.83-2.26-.93.02-1.33 1.09-1.69 1.78-.65 1.24-1.38 2.36-2.45 3.28-2.21 1.91-5.14 2.58-7.81 3.59-1.87.71-3.48 2.32-3.59 4.4-.08 1.63.9 3 1.85 4.21.98 1.24 2.04 2.44 2.79 3.84.46.85.89 1.72 1.28 2.61 0 0 2.88-.96 3.62-.87.73.09 3.05 2.13 3.05 2.13 1.43-2.3 5.27-3.5 5.27-3.5 2.78-.87 5.74-2.91 7.68-5.08.42-.44 3.04-4.32 1.93-4.46z"
      style={{
        fill: "#4e4092",
        stroke: "#4e4092",
        strokeWidth: 0.2001,
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentFretlessBass_svg__st6"
      d="m25.34 83.66-5.51-6.02a.746.746 0 0 1 .05-1.06l.53-.48c.31-.28.78-.26 1.06.05l5.51 6.02c.28.31.26.78-.05 1.06l-.53.47c-.31.28-.78.26-1.06-.04zM17.82 89l-3.85-4.21c-.43-.47-.39-1.19.07-1.61l1.78-1.63c.47-.43 1.19-.39 1.61.07l3.85 4.21c.43.47.39 1.19-.07 1.61l-1.78 1.63c-.46.43-1.19.4-1.61-.07zM32.06 77.29l-5.51-6.02a.746.746 0 0 1 .05-1.06l.53-.48c.31-.28.78-.26 1.06.05l5.51 6.02c.28.31.26.78-.05 1.06l-.53.48c-.31.27-.78.25-1.06-.05z"
    />
    <circle
      className="InstrumentFretlessBass_svg__st6"
      cx={32.31}
      cy={82.3}
      r={1.2}
    />
    <circle
      className="InstrumentFretlessBass_svg__st6"
      cx={31.67}
      cy={85.9}
      r={1.01}
    />
    <circle
      className="InstrumentFretlessBass_svg__st6"
      cx={30.93}
      cy={89.28}
      r={0.93}
    />
    <path
      d="M36.37 63.21a.54.54 0 0 0-.03.77l3.49 3.81c.21.23.57.24.79.02L83.28 24.9l-2.65-2.71-44.26 41.02z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentFretlessBass_svg__st5"
      d="M100.25 9.79c-.68-1.09-2.19-2.3-3.77-1.62-1.58.68-15.86 9.46-15.86 9.46s-.68.83-.41 1.39c.26.57.9 1.21.87 1.96-.04.75-.44 1.21-.44 1.21l2.65 2.71s2.69-3.05 5.62-1.7c0 0 2.11-1.62 3.62-4.86 1.51-3.24 2.67-3.77 3.47-3.77.79 0 2.22.53 3.39-.49 1.16-1.01 1.54-3.2.86-4.29z"
    />
    <circle
      className="InstrumentFretlessBass_svg__st5"
      cx={80.95}
      cy={14.69}
      r={1.36}
    />
    <circle
      className="InstrumentFretlessBass_svg__st5"
      cx={85.41}
      cy={11.92}
      r={1.36}
    />
    <circle
      className="InstrumentFretlessBass_svg__st5"
      cx={89.65}
      cy={9.33}
      r={1.36}
    />
    <circle
      className="InstrumentFretlessBass_svg__st5"
      cx={93.72}
      cy={7.24}
      r={1.36}
    />
  </svg>
);

export default InstrumentFretlessBass;

import * as React from "react";

const InstrumentNylonGuitar = (props) => (
  <svg
    id="InstrumentNylonGuitar_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.54"
    style={{
      enableBackground: "new 0 0 110.84 110.54",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentNylonGuitar_svg__st10{fill:#538553}.InstrumentNylonGuitar_svg__st12{fill:none;stroke:#fff;stroke-width:.9068;stroke-linecap:round;stroke-miterlimit:10}"
      }
    </style>
    
    <path
      d="M94.17 0h-77.5C7.46 0 0 7.46 0 16.67v77.49c0 9.21 7.46 16.67 16.67 16.67h77.49c9.21 0 16.67-7.46 16.67-16.67V16.67C110.84 7.46 103.38 0 94.17 0z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      d="M78.79 83.53c-.24-4.97-2.92-8.77-6.13-12.28-2.08-2.28-2.24-3.46-.89-6.2.34-.69.69-1.37.96-2.09 2.36-6.31-.82-11.63-7.51-12.59-1.41-.2-2.83-.36-4.36-.55-.11-10.31-.12-21.44-.19-31.69 1.3-.1 2.34-1.19 2.35-2.52l.05-6.67a2.548 2.548 0 0 0-2.53-2.57l-4.15-.03a2.548 2.548 0 0 0-2.57 2.53l-.05 6.67a2.543 2.543 0 0 0 2.41 2.56c-.07 4.71-.29 14.56-.35 17.93-.08 4.58-.08 9.16-.11 13.76-1.81.19-3.17.3-4.53.47-6.88.87-10.47 6.29-7.59 12.55.86 1.87 1.55 4.02 1 6.08-.3 1.13-1.01 2.03-1.81 2.86-.84.87-1.59 1.73-2.32 2.71-7.69 10.34-1.7 21.31 6.55 25.06 6.89 3.13 14 3.24 21 .39 6.99-2.85 11.11-9.25 10.77-16.38z"
      style={{
        fill: "#ca5672",
      }}
    />
    <circle
      className="InstrumentNylonGuitar_svg__st10"
      cx={50.27}
      cy={19.42}
      r={1.85}
    />
    <circle
      className="InstrumentNylonGuitar_svg__st10"
      cx={67.28}
      cy={13.95}
      r={2.02}
    />
    <path
      d="M64.86 65.54a7.05 7.05 0 0 1-2.44 8.14c-.75 1.63-1.6 2.57-.79 5.26 1.25 4.13 5.27 5.25 8.4.98 3.77-5.12-2.99-12.29-5.17-14.38z"
      style={{
        fill: "#cfd125",
      }}
    />
    <path
      d="M64.86 65.54a7.038 7.038 0 0 0-3.11-3.67c-3.38-1.93-7.7-.76-9.63 2.63s-.76 7.7 2.63 9.63c2.5 1.43 5.5 1.16 7.68-.44.77-.57 1.44-1.3 1.95-2.18a7.07 7.07 0 0 0 .48-5.97z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentNylonGuitar_svg__st12"
      d="m54.59 65.96-.82 29.98M56.99 66l-.65 29.87M59.56 66.05l-.82 29.98M62.13 66.1l-.82 29.98"
    />
  </svg>
);

export default InstrumentNylonGuitar;

const DEFAULT_SWING_VALUES = {
  spooky: {
    jazz: 66,
    lofi: 50,
  },
  sad: {
    jazz: 66,
    bossa: 50,
    lofi: 50,
    pop: 50,
  },
  chill: {
    jazz: 66,
    bossa: 50,
    reggae: 66,
    lofi: 50,
    pop: 50,
    hip_hop: 52,
    latin: 50,
  },
  happy: {
    bossa: 50,
    reggae: 66,
    pop: 50,
    orc_pop: 50,
    epic: 50,
    retro_futuristic: 50,
    oddity: 50,
    latin: 50,
  },
  spirited: {
    orc_pop: 50,
    epic: 50,
    retro_futuristic: 50,
  },
};

export default DEFAULT_SWING_VALUES;

import * as React from "react";

const InstrumentCleanElectricGuitar = (props) => (
  <svg
    id="InstrumentCleanElectricGuitar_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 112.08"
    style={{
      enableBackground: "new 0 0 110.84 112.08",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentCleanElectricGuitar_svg__st5{fill:#cfd125}.InstrumentCleanElectricGuitar_svg__st6{fill:#ca5672}.InstrumentCleanElectricGuitar_svg__st25{fill:#f18860;stroke:#f18860;stroke-miterlimit:10}"
      }
    </style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#4d3f92",
      }}
    />
    <path
      className="InstrumentCleanElectricGuitar_svg__st25"
      d="M47 55.72c-.08-.09-.14-.16-.17-.2-1.06-1.21-2.32-2.48-3.89-2.96-3.1-.95-6.3.81-8.19 3.22-.96 1.23-1.53 2.58-1.86 4.1-.29 1.34-.59 3.18-2.02 3.78-1.57.66-3.29.61-4.96.71-4.42.28-8.99 1.92-11.96 5.32-2.1 2.4-3.39 5.33-3.27 8.53.14 3.67 1.69 6.79 3.68 9.79 1.95 2.94 4.54 5.45 7.67 7.1 4.76 2.51 10.13 2.48 14.36-1.1.15-.13.3-.26.45-.4 2.28-2.09 3.95-4.73 4.89-7.68.47-1.49.76-3.03.91-4.57.14-1.44-.22-3.27.71-4.49 1.56-2.04 12.26-1.99 9.03-6.73-.85-1.25-2.48-1.87-3.49-2.99-1.44-1.6-1.4-3.49-.54-5.4.39-.87.9-1.68 1.45-2.45.1-.15-2.11-2.77-2.8-3.58z"
    />
    <path
      d="M44.69 62.8 27.84 79.97a89.34 89.34 0 0 0 3.37 4.62c.76.96 1.42 1.93 2.82 1.74.81-.11 1.54-.61 2.05-1.25.59-.75.94-1.71 1.13-2.65.09-.45.09-.98.25-1.41.31-.83.8-1.7 1.51-2.25-.07.06-.34-1.9-.32-2.1.08-.76.35-1.57.91-2.09 1.17-1.1 2.99-1.49 4.39-2.22 1.35-.7 4.24-1.84 3.14-3.78-.33-.58-.85-1.02-1.32-1.5-1.09-1.09-1.61-2.77-1.08-4.28z"
      style={{
        fill: "#ca5672",
        stroke: "#ca5672",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentCleanElectricGuitar_svg__st5"
      d="m35.04 91.81-9.97 2.5a.692.692 0 0 1-.84-.5l-.44-1.73c-.09-.37.13-.75.5-.84l9.97-2.5c.37-.09.75.13.84.5l.44 1.73c.09.37-.13.74-.5.84z"
    />
    <circle
      className="InstrumentCleanElectricGuitar_svg__st6"
      cx={33.27}
      cy={90.49}
      r={1.02}
    />
    <circle
      className="InstrumentCleanElectricGuitar_svg__st6"
      cx={25.87}
      cy={92.55}
      r={0.88}
    />
    <path
      style={{
        fill: "#fdcc68",
        stroke: "#fdcc75",
        strokeMiterlimit: 10,
      }}
      d="m81.26 25.51 2.86 2.96-40.2 39.61-3.61-3.72z"
    />
    <path
      className="InstrumentCleanElectricGuitar_svg__st25"
      d="M81.26 25.51s2.78-2.72 1.56-4.13c-.04-.05 5.46-5.33 5.9-5.76.34-.34.73-.66 1.04-1.02.11-.13.23-.27.39-.32.26-.09.39.12.54.29.33.35.55.78.9 1.11.61.57 1.45.86 2.27.86 1.68.01 3.3.23 4.5 1.52.14.15.27.31.31.5.06.3-.14.45-.35.62-.36.29-.72.57-1.08.86-1.13.9-2.26 1.8-3.39 2.69-1.84 1.46-5.58 4.19-5.6 4.2 0 0-1.18-1.05-4.12 1.54l-2.87-2.96z"
    />
    <path
      className="InstrumentCleanElectricGuitar_svg__st5"
      d="m41.18 70.74-3.6-3.72c-.51-.53-.5-1.38.03-1.89l.82-.79c.53-.51 1.38-.5 1.89.03l3.6 3.72c.51.53.5 1.38-.03 1.89l-.82.79c-.53.51-1.38.5-1.89-.03zM29.9 81.67l-3.6-3.72c-.51-.53-.5-1.38.03-1.89l.82-.79c.53-.51 1.38-.5 1.89.03l3.6 3.72c.51.53.5 1.38-.03 1.89l-.82.79c-.53.51-1.38.5-1.89-.03zM18.49 93.03l-3.68-3.8c-.22-.23-.22-.6.01-.82l.28-.27c.23-.22.6-.22.82.01l3.68 3.8c.22.23.22.6-.01.82l-.28.27c-.22.22-.59.22-.82-.01z"
    />
  </svg>
);

export default InstrumentCleanElectricGuitar;

export default function AmbassadorIcon(props) {
  return (
    <svg x={0} y={0} viewBox="0 0 360 360" xmlSpace="preserve" {...props}>
      <style>
        {
          ".__ambassador-icon-14,.__ambassador-icon-15,.__ambassador-icon-16,.__ambassador-icon-8{fill:none;stroke:#fff;stroke-width:21;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.__ambassador-icon-14,.__ambassador-icon-15,.__ambassador-icon-16{stroke-width:20.8499}.__ambassador-icon-15,.__ambassador-icon-16{fill:#fff;stroke-width:13.2365}.__ambassador-icon-16{stroke-width:11.2167}"
        }
      </style>
      <path
        d="M293.1 359H67.4c-34.6 0-62.8-28.3-62.8-62.8V62.8C4.6 28.3 32.9 0 67.4 0H293c34.6 0 62.8 28.3 62.8 62.8v233.3c.1 34.6-28.2 62.9-62.7 62.9z"
        fill="#528452"
      />
      <ellipse
        className="__ambassador-icon-8"
        cx={252.2}
        cy={110.5}
        rx={48.3}
        ry={49}
      />
      <path className="__ambassador-icon-14" d="M300.5 115.5v174" />
      <path
        className="__ambassador-icon-8"
        d="M73.9 109.2s27.1 30.3 61.6-.6M79.8 34.5s-32.5 7-49.3 35"
      />
      <path className="__ambassador-icon-14" d="M286.7 34.5s32.5 7 49.3 35" />
      <path
        className="__ambassador-icon-15"
        d="M175.7 214.8s-25.9-20.9-61.9 0-29.3-18.4-29.3-18.4-25 76.1 91.2 18.4zM177.6 214.8s25.9-20.9 61.9 0 29.3-18.4 29.3-18.4 25 76.1-91.2 18.4z"
      />
      <g>
        <path
          className="__ambassador-icon-16"
          d="M177.2 316.2L139 294.1v45.8zM185.8 316.2l38.2-22.1v45.8z"
        />
      </g>
    </svg>
  )
}

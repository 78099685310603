import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import CreateSong from '../assets/create_song.png';

export default function SlideProductCreate() {

  return (
    <div className='slide slide-product-create'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" /> 
      
      <img className='screenshots' src={CreateSong} alt='Product - Create Song' />

    </div>
  )
}

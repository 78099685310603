import * as React from "react"

export default function MoodSpiritedIcon(props) {
  return (
    <svg viewBox="0 0 360 360" {...props}>
      <style>
        {
          ".__spirited-icon-st1{fill:none;stroke:#fff;stroke-width:35;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.__spirited-icon-st2{stroke:#fff}.__spirited-icon-st6{fill:#fff}.__spirited-icon-st3{fill:#cecf25}"
        }
      </style>
      <path
        className="__spirited-icon-st3"
        d="M292.8 359.5H67.2c-34.5 0-62.8-28.3-62.8-62.8V63.4C4.4 28.9 32.7.6 67.2.6h225.6c34.5 0 62.8 28.3 62.8 62.8v233.3c0 34.5-28.3 62.8-62.8 62.8z"
      />
      <path
        className="__spirited-icon-st1"
        d="M293.2 45.3L327.1 61M92.6 39.8L29.4 68.7M55.2 127.9c-3.1.5 20.9-25.8 34.8-18.7s29.9 26.6 54.6 22.3"
      />
      <circle className="__spirited-icon-st6" cx={126.9} cy={144.5} r={24.3} />
      <path className="__spirited-icon-st1" d="M219.8 127.3s47.2 3.1 80.9-22" />
      <circle className="__spirited-icon-st6" cx={282.7} cy={138.5} r={24.3} />
      <path
        className="__spirited-icon-st2"
        d="M211 268.2s47.5 4 69.1-38.1"
        fill="none"
        strokeWidth={30}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </svg>
  )
}

export const TRACK_PRICES = {
  mp3      : 240,
  midi     : 200,
  mp3_midi : 400,
};

//! IMPORTANT !
// Do not delete pricing tiers that were used in production but are no longer used,
// as this will cause the prices to not show up in the Database
export const PRICING_TIER = {
  pro     : {
    id        : 1,
    type      : 'membership',
    price     : '10',
    phrase    : "Strofe Premium",
    freeTrial : true,
  },

  premium : {
    id     : 3,
    type   : 'membership',
    price  : '35',
    phrase : "Premium",
  },

  std_monthly_regular: {
    id     : 12,
    type   : 'membership',
    price  : '9.99',
    phrase : "Strofe Standard",
  },

  std_yearly_regular: {
    id      : 13,
    type    : 'membership',
    price   : '79.99',
    price_m : '6.99',
    phrase  : "Strofe Standard",
  },

  pro_monthly_regular: {
    id     : 16,
    type   : 'membership',
    price  : '19.99',
    phrase : "Strofe Pro",
  },

  pro_yearly_regular: {
    id      : 17,
    type    : 'membership',
    price   : '189.99',
    price_m : '15.99',
    phrase  : "Strofe Pro",
  },

  std_monthly_discount: {
    id     : 14,
    type   : 'membership',
    price  : '3.99',
    phrase : "Strofe Standard",
  },

  std_yearly_discount: {
    id      : 15,
    type    : 'membership',
    price   : '34.99',
    price_m : '2.99',
    phrase  : "Strofe Standard",
  },

  pro_monthly_discount: {
    id     : 18,
    type   : 'membership',
    price  : '9.99',
    phrase : "Strofe Pro",
  },

  pro_yearly_discount: {
    id      : 19,
    type    : 'membership',
    price   : '94.99',
    price_m : '7.99',
    phrase  : "Strofe Pro",
  },

  // COINS
  // -----
  coins_400: {
    id    : 4,
    type  : 'single-purchase',
    price : '4.99',
    coins : 400,
  },

  coins_800: {
    id    : 5,
    type  : 'single-purchase',
    price : '9.99',
    coins : 800,
  },

  coins_1700: {
    id    : 6,
    type  : 'single-purchase',
    price : '19.99',
    coins : 1700,
  },

  coins_400_half_price: {
    id    : 7,
    type  : 'single-purchase',
    price : '2.49',
    coins : 400,
  },

  coins_800_half_price: {
    id    : 8,
    type  : 'single-purchase',
    price : '4.99',
    coins : 800,
  },

  coins_1700_half_price: {
    id    : 9,
    type  : 'single-purchase',
    price : '9.99',
    coins : 1700,
  },

  coins_800_2x: {
    id    : 8,
    type  : 'single-purchase',
    price : '4.99',
    coins : 800,
  },

  coins_1600_2x: {
    id    : 10,
    type  : 'single-purchase',
    price : '9.99',
    coins : 1600,
  },

  coins_3400_2x: {
    id    : 11,
    type  : 'single-purchase',
    price : '19.99',
    coins : 3400,
  },
}

// Keep a list of price ids where the user is pro to show extra features:
export const PRO_PRICE_IDS = [16, 17, 18, 19];

export function subscriptionIsPro(priceId) {
  return PRO_PRICE_IDS.includes(id => priceId);
}

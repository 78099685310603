import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Cookies from "js-cookie";

// strofe.com/go?ref=reddit   <- referrer string
// strofe.com/go/join         <- alias

const REFER_ALIAS = {
  join     : 'roblox',
  register : 'facebook',
}

export default function ReferRedirect() {

  let { alias } = useParams();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const referrer = REFER_ALIAS[alias] || new URLSearchParams(location.search).get('ref');
    referrer && Cookies.set('strofe_referrer', referrer, { expires: 60 });
    history.replace('/');
  });

  return null;
}

import * as React from "react";

const InstrumentFrenchHorn = (props) => (
  <svg
    id="InstrumentFrenchHorn_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.04 110.34"
    style={{
      enableBackground: "new 0 0 110.04 110.34",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentFrenchHorn_svg__st38,.InstrumentFrenchHorn_svg__st41{fill:#f18860;stroke:#f18860;stroke-width:1.241;stroke-miterlimit:10}.InstrumentFrenchHorn_svg__st41{fill:#fdcc75;stroke:#fdcc75;stroke-width:.9307}"
      }
    </style>
    
    <path
      d="M93.36 110.84h-77.5c-9.21 0-16.67-7.46-16.67-16.67v-77.5C-.81 7.46 6.66 0 15.87 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.47 16.67-16.68 16.67z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentFrenchHorn_svg__st38"
      d="M38.65 63.68c0-12.05 9.77-21.83 21.83-21.83S82.3 51.62 82.3 63.68c0 4.67-1.47 9-3.97 12.55 1.58-1.26 3.14-2.53 4.69-3.82a24.1 24.1 0 0 0 1.63-8.73c0-13.35-10.82-24.18-24.18-24.18-10.75 0-19.86 7.02-23 16.72.14 2.8.52 5.63 1.2 8.38-.01-.31-.02-.61-.02-.92z"
    />
    <path
      className="InstrumentFrenchHorn_svg__st38"
      d="M99.07 61.2 97 58.91c-.08-.08-.22-.05-.23.07-.11.95-.93 2.24-1.48 2.8-.56.58-1.2 1.09-1.8 1.62-3.44 3.05-6.92 6.06-10.46 9-1.55 1.29-3.12 2.56-4.69 3.82-.59.47-1.18.94-1.77 1.4-3.54 2.78-7.2 6.06-11.72 7.03-4.06.87-8.42.11-12.12-1.56-7.8-3.53-12.11-10.65-14.04-18.5-.68-2.75-1.06-5.58-1.2-8.38 0-.06-.01-.11-.01-.17-.3-6.6.44-13.47 2.91-19.64 1.2-2.98 2.86-5.82 5.13-8.11 2.75-2.78 6.35-4.46 10.14-5.26.38-.08.46-.59.11-.76-2.83-1.4-8.69-4.31-22.78-4.4C21.2 17.8 13.73 20.66 11.68 22c-.31.2-.19.69.18.72 4.82.45 8.19 2.19 11.53 7.29 5.49 8.37 5.29 18.91 6.34 28.6 1.14 10.47 4.64 19.97 13.43 26.41 2.81 2.06 5.91 3.23 9.25 4.09 18.41 4.75 34.37-17.04 43.74-26.41a3.841 3.841 0 0 1 2.81-1.24c.14-.02.2-.17.11-.26z"
    />
    <path
      d="M59.51 40.37a6.51 6.51 0 1 0 6.51 6.51c0-3.6-2.91-6.51-6.51-6.51zm0 12.38c-3.25 0-5.88-2.63-5.88-5.88s2.63-5.88 5.88-5.88 5.88 2.63 5.88 5.88-2.63 5.88-5.88 5.88z"
      style={{
        fill: "#ca5672",
        stroke: "#ca5672",
        strokeWidth: 1.241,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M44.5 49.21c-5.37 0-9.72 4.35-9.72 9.72 0 5.37 4.35 9.72 9.72 9.72 5.37 0 9.72-4.35 9.72-9.72 0-5.37-4.36-9.72-9.72-9.72zm0 18.49c-4.85 0-8.78-3.93-8.78-8.78s3.93-8.78 8.78-8.78 8.78 3.93 8.78 8.78c-.01 4.85-3.94 8.78-8.78 8.78z"
      style={{
        fill: "#cfd125",
        stroke: "#cfd125",
        strokeWidth: 1.8535,
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentFrenchHorn_svg__st41"
      d="M70.59 74.91c-.37.21-.83.09-1.04-.28L53.17 46.25a.755.755 0 0 1 .28-1.04c.37-.21.83-.09 1.04.28l16.38 28.37c.21.37.09.84-.28 1.05zM69.4 78.6l-.2.12c-.31.18-.7.07-.88-.24L57.4 59.58c-.18-.31-.07-.7.24-.88l.2-.12c.31-.18.7-.07.88.24l10.91 18.9c.18.31.07.7-.23.88zM66.79 80.1l-.2.12c-.31.18-.7.07-.88-.24L54.8 61.08c-.18-.31-.07-.7.24-.88l.2-.12c.31-.18.7-.07.88.24l10.91 18.9c.18.31.07.71-.24.88zM60.76 75.9l-.08.04c-.34.2-.78.08-.98-.26L46.28 52.42c-.2-.34-.08-.78.26-.98l.08-.04c.34-.2.78-.08.98.26l13.43 23.26c.2.34.08.78-.27.98zM58.06 77.35l-.08.04c-.34.2-.78.08-.98-.26L43.57 53.87c-.2-.34-.08-.78.26-.98l.08-.04c.34-.2.78-.08.98.26l13.43 23.26c.2.34.08.78-.26.98z"
    />
    <path
      d="M65.83 46.88c-2.46 0-4.69.99-6.32 2.59l.45.79 7.79 13.5.45.79c3.81-1.05 6.61-4.53 6.61-8.67.02-4.98-4.01-9-8.98-9zm1.94 16.63-.4-.69-6.82-11.8-.4-.69a7.844 7.844 0 0 1 5.52-2.27c4.34 0 7.86 3.52 7.86 7.86.02 3.63-2.43 6.68-5.76 7.59z"
      style={{
        fill: "#cfd125",
        stroke: "#cfd125",
        strokeWidth: 1.241,
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentFrenchHorn_svg__st41"
      d="M75.17 76.52c-.37.21-.83.09-1.04-.28L57.74 47.86a.755.755 0 0 1 .28-1.04c.37-.21.83-.09 1.04.28l16.38 28.37c.22.37.09.83-.27 1.05z"
    />
  </svg>
);

export default InstrumentFrenchHorn;

export default function SlideGreenCorner(props) {
  return (
  <svg
    width={50}
    height={50}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M.9 49.3H49.3V.9H35.5v34.6H.9v13.8Z" fill="#CECF25" />
  </svg>
  );
}

import * as React from "react";

const InstrumentAccordion = (props) => (
  <svg
    id="InstrumentAccordion_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.44 110.84"
    style={{
      enableBackground: "new 0 0 110.44 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentAccordion_svg__st3{fill:#4e4092}.InstrumentAccordion_svg__st4{fill:#f18860}.InstrumentAccordion_svg__st28{fill:#fff}"
      }
    </style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      d="M80.31 25.57s12.79-.87 18.68 3.46L85.35 87s-7.66 1.41-18.26-3.89l13.22-57.54z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      transform="rotate(-76.579 84.198 56.448)"
      className="InstrumentAccordion_svg__st28"
      d="M55.71 50.39h56.97v12.12H55.71z"
    />
    <path
      transform="rotate(-76.579 76.079 80.25)"
      className="InstrumentAccordion_svg__st3"
      d="M75.02 76.56h2.12v7.38h-2.12z"
    />
    <path
      transform="rotate(-76.579 76.947 76.612)"
      className="InstrumentAccordion_svg__st3"
      d="M75.89 72.92h2.12v7.38h-2.12z"
    />
    <path
      transform="rotate(-76.579 77.816 72.972)"
      className="InstrumentAccordion_svg__st3"
      d="M76.75 69.29h2.12v7.38h-2.12z"
    />
    <path
      transform="rotate(-76.579 78.983 68.08)"
      className="InstrumentAccordion_svg__st3"
      d="M77.92 64.39h2.12v7.38h-2.12z"
    />
    <path
      transform="rotate(-76.579 79.787 64.711)"
      className="InstrumentAccordion_svg__st3"
      d="M78.73 61.02h2.12v7.38h-2.12z"
    />
    <path
      transform="rotate(-76.579 81.016 59.56)"
      className="InstrumentAccordion_svg__st3"
      d="M79.95 55.87h2.12v7.38h-2.12z"
    />
    <path
      transform="rotate(-76.579 81.884 55.92)"
      className="InstrumentAccordion_svg__st3"
      d="M80.82 52.23h2.12v7.38h-2.12z"
    />
    <path
      transform="rotate(-76.579 82.752 52.282)"
      className="InstrumentAccordion_svg__st3"
      d="M81.69 48.59h2.12v7.38h-2.12z"
    />
    <path
      transform="rotate(-76.579 84.022 46.96)"
      className="InstrumentAccordion_svg__st3"
      d="M82.96 43.27h2.12v7.38h-2.12z"
    />
    <path
      transform="rotate(-76.579 84.826 43.591)"
      className="InstrumentAccordion_svg__st3"
      d="M83.76 39.9h2.12v7.38h-2.12z"
    />
    <path
      transform="rotate(-76.579 86.029 38.55)"
      className="InstrumentAccordion_svg__st3"
      d="M84.97 34.86h2.12v7.38h-2.12z"
    />
    <path
      transform="rotate(-76.579 86.897 34.91)"
      className="InstrumentAccordion_svg__st3"
      d="M85.84 31.22h2.12v7.38h-2.12z"
    />
    <path
      transform="rotate(-76.579 87.765 31.27)"
      className="InstrumentAccordion_svg__st3"
      d="M86.7 27.58h2.12v7.38H86.7z"
    />
    <path
      d="m15.6 35.62 34.61 48.56-6.56 4.48a4.027 4.027 0 0 1-5.48-.9L8.03 47.69a4.029 4.029 0 0 1 .45-5.34l7.12-6.73z"
      style={{
        fill: "#ca5672",
        stroke: "#ca5672",
        strokeWidth: 0.1915,
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentAccordion_svg__st28"
      d="m8.74 48.63 4.21-2.55c1.8-1.09 4.14-.61 5.37 1.11L39.98 77.7c1.2 1.7.92 4.03-.66 5.38l-2.84 2.43L8.74 48.63z"
    />
    <path
      style={{
        fill: "#fdcc75",
      }}
      d="m80.31 25.57-6.95-1.3-13.95 57.86 7.68.98z"
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={16.36}
      cy={48.82}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={13.44}
      cy={51.11}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={19.07}
      cy={52.29}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={16.15}
      cy={54.58}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={21.55}
      cy={55.83}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={18.63}
      cy={58.12}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={24.26}
      cy={59.29}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={21.34}
      cy={61.59}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={26.96}
      cy={62.83}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={24.04}
      cy={65.13}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={29.67}
      cy={66.3}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={26.74}
      cy={68.6}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={32.15}
      cy={69.84}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={29.23}
      cy={72.13}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={34.86}
      cy={73.31}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={31.94}
      cy={75.6}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={37.45}
      cy={76.75}
      r={1.24}
    />
    <circle
      className="InstrumentAccordion_svg__st3"
      cx={34.53}
      cy={79.04}
      r={1.24}
    />
    <path className="InstrumentAccordion_svg__st3" d="m53.54 82.27-.01-.03z" />
    <path
      className="InstrumentAccordion_svg__st4"
      d="m40.08 27.62-4.28-2.74-1.94 4.98 19.66 52.38.01.03c.21-.07.44-.13.67-.19L40.1 27.59l-.02.03z"
    />
    <path
      className="InstrumentAccordion_svg__st4"
      d="m27.55 32.73-.05.19-5.3-1.52-.75 4.95 30.19 46.82c.49-.31 1.12-.64 1.9-.9l-.01-.03-25.98-49.51z"
    />
    <path
      className="InstrumentAccordion_svg__st3"
      d="m33.87 29.86-.06.14-5.09-2.16-1.17 4.89 25.98 49.51zM21.45 36.38l-5.84-.76 34.75 48.61s.42-.51 1.29-1.07l-30.2-46.81v.03z"
    />
    <path
      className="InstrumentAccordion_svg__st4"
      d="m54.76 24.71-.04.03-4.51-3.26-3.13 4.45-.23-.14 8.7 56.05zM69.14 26.32l-2.16-3.89-4.25 2.67-.06-.06-5.56 56.77c.23.01.47.03.71.05l11.54-55.65-.22.11z"
    />
    <path
      className="InstrumentAccordion_svg__st3"
      d="m46.85 25.79-4.39-2.71-2.36 4.51 14.11 54.49c.41-.1.86-.18 1.34-.23v-.01l-8.7-56.05zM73.36 24.27l-4.01 1.95-11.53 55.65c.5.05 1.03.14 1.59.27l13.95-57.87z"
    />
    <path
      className="InstrumentAccordion_svg__st3"
      d="m59.41 21.78-4.65 2.93.79 57.13v.01c.48-.05 1-.06 1.55-.04l5.56-56.77-3.25-3.26zM55.55 81.85v-.01z"
    />
  </svg>
);

export default InstrumentAccordion;

export default function StrofeLogoColors(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 2000 2000"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".__strofe-logo-icon-prefix__st0{fill:#f7ccc1}.__strofe-logo-icon-prefix__st1{fill:#f08660}.__strofe-logo-icon-prefix__st2{fill:#c85671}"
        }
      </style>
      <path
        className="__strofe-logo-icon-prefix__st0"
        d="M545.2 934.4l-3.6-2.1-240.5-144.2c-5.9-3.5-7.8-11.1-4.3-17s11.1-7.8 17-4.3l237 142 1106-120.5c6.8-.7 12.9 4.2 13.6 11 .7 6.8-4.2 12.9-11 13.6L545.2 934.4z"
      />
      <path
        className="__strofe-logo-icon-prefix__st1"
        d="M965 833.4l-2.8-.7-756.4-179.6c-6.6-1.6-10.8-8.2-9.2-14.9 1.6-6.7 8.2-10.8 14.9-9.2l753.7 179 772-170.9c6.7-1.5 13.3 2.7 14.8 9.4s-2.7 13.3-9.4 14.8L965 833.4z"
      />
      <path
        className="__strofe-logo-icon-prefix__st2"
        d="M196 922.2c-5.3 0-10.3-3.5-11.9-8.9-1.9-6.6 1.8-13.4 8.4-15.4L817.4 713l.7-.1 574.1-102.6 409.2 154.5c6.4 2.4 9.6 9.6 7.2 15.9-2.4 6.4-9.6 9.6-15.9 7.2l-402.9-152.1-566.7 101.4-623.6 184.5c-1.2.3-2.4.5-3.5.5z"
      />
      <path
        d="M387.6 1252.3c-14.9-10.5-29-17.3-52.3-28.4-5.3-2.5-11-5.3-17.3-8.3l-.9-.4c-35.3-17.4-52.3-27-52.3-54.8 0-25.6 15.9-51.4 51.4-51.4 14.3 0 40.5 4.6 56.3 35.4l6.9 13.4 35.3-29.8-4.6-8.6c-18.8-34.9-52.8-54.8-93.3-54.8-65.2 0-99.4 48.7-99.4 96.8 0 16.3 4.1 31.1 12.5 45 13.8 22.8 33.6 33.2 67.3 49.5 27.8 13.7 47.5 23.8 56.9 29.2 24.5 15 27.4 31.9 27.4 43.4 0 38.1-30.2 66.8-70.3 66.8-21.6 0-42.5-9.4-54.5-24.6l-.2-.2c-4.3-5.2-12.2-17.1-16.3-29l-4.4-12.6-41.2 19.5 3.6 10.2c3.4 9.6 9.6 24.3 24.2 41.2 14.8 17.1 40 39.9 88.3 39.9 66.3 0 118.3-49 118.3-111.7-.1-42.3-23.7-63.5-41.4-75.7z"
        fill="#fdcb75"
      />
      <path
        className="__strofe-logo-icon-prefix__st0"
        d="M440.3 1113.5h88.4v320.6h46.5v-320.6h88.4v-44.3H440.3z"
      />
      <path
        className="__strofe-logo-icon-prefix__st2"
        d="M829.3 1265.8c41.6-14 65.4-48.3 65.4-94.2 0-25.1-7-59.8-40.5-83-26.3-18-53.4-19.5-78.8-19.5h-61V1434h46.4v-149.3L863.2 1434h54.7l-112.5-162.6c8.2-1.1 16.3-3 23.9-5.6zm-5.9-143.5c7.2 3.9 23.9 16.4 23.9 48.8 0 37.1-23.3 57.6-65.7 57.6h-20.8v-115.1H777c15.5 0 35.8 2.7 46.2 8.6l.2.1z"
      />
      <path
        d="M1139.9 1064.6c-105.9 0-188.8 82.5-188.8 187.8 0 105 83 187.3 188.8 187.3s188.8-82.3 188.8-187.3c0-105.3-82.9-187.8-188.8-187.8zm0 330.7c-80.6 0-141.4-61.4-141.4-142.9 0-81.8 60.8-143.4 141.4-143.4 80.6 0 141.4 61.7 141.4 143.4 0 81.5-60.8 142.9-141.4 142.9z"
        fill="#cecf25"
      />
      <path
        fill="#528352"
        d="M1399.9 1434.1h46.4v-176.4h112.5v-44.4h-112.5v-99.8h115.5v-44.3h-161.9z"
      />
      <path
        className="__strofe-logo-icon-prefix__st1"
        d="M1816.4 1113.5v-44.3h-187.5v364.9h187.5v-44.4h-141.1v-132h138v-44.4h-138v-99.8z"
      />
      <path
        className="__strofe-logo-icon-prefix__st1"
        d="M1044.2 536.4h-6.8H1016.7v223.8c-41.4 2.8-75.9 25.2-87.7 56.8-7.7 20.8.6 36.7 21.7 38.4 42.8 3.4 95.4-42.2 93.4-80.3h.1V536.4z"
      />
    </svg>
  )
}

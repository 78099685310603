import { Trans as Translate } from 'react-i18next';

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function ReRollModal({ show, onHide, onCreate }) {

  return (
    <Modal className='__modal' show={show} size='sm' onHide={onHide}>
      <Modal.Header closeButton>
        <Translate>Reroll Track</Translate>
      </Modal.Header>

      <Modal.Body>
        <p><Translate>Let's try again!</Translate></p>
        <p><Translate i18nKey='reroll-modal-description' /></p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}><Translate>Close</Translate></Button>
        <Button variant="primary" onClick={onCreate}><Translate>Create</Translate></Button>
      </Modal.Footer>
    </Modal>
  );
}

import * as React from "react";

const InstrumentClavinet = (props) => (
  <svg
    id="InstrumentClavinet_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentClavinet_svg__st2{fill:#fdcc75}.InstrumentClavinet_svg__st3{fill:#4e4092}.InstrumentClavinet_svg__st28{fill:#fff}"
      }
    </style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      className="InstrumentClavinet_svg__st2"
      d="M96.01 44.11h-83c-.94 0-1.7-.76-1.7-1.7v-2.67c0-.94.76-1.7 1.7-1.7h83c.94 0 1.7.76 1.7 1.7v2.67c0 .94-.76 1.7-1.7 1.7z"
    />
    <path
      className="InstrumentClavinet_svg__st2"
      d="M97.72 59.93H11.36c-1.75 0-3.17-1.42-3.17-3.17v-9.65c0-1.75 1.42-3.17 3.17-3.17h86.36c1.75 0 3.17 1.42 3.17 3.17v9.65c0 1.75-1.42 3.17-3.17 3.17z"
    />
    <path
      className="InstrumentClavinet_svg__st28"
      d="M29.28 43.95h6.61V51h-6.61zM36.43 43.95h5.97V51h-5.97zM42.95 43.95h6.08V51h-6.08zM49.57 43.95h6.02V51h-6.02zM56.13 43.95h6.08V51h-6.08zM62.75 43.95h5.97V51h-5.97zM69.27 43.95h6.05V51h-6.05zM75.86 43.95H82V51h-6.14zM82.54 43.95h6.15V51h-6.15zM89.23 43.95h6.07V51h-6.07z"
    />
    <path
      className="InstrumentClavinet_svg__st3"
      d="m15.31 59.93-7.12 22.6 12.98-22.6zM97.87 59.93l7.12 22.6-12.97-22.6zM34.08 40.47h4.82v3.49h-4.82zM40.31 40.47h4.82v3.49h-4.82zM79.69 40.47h4.82v3.49h-4.82zM86.13 40.47h4.82v3.49h-4.82zM54.41 40.47h4.82v3.49h-4.82zM60.79 40.47h4.82v3.49h-4.82zM67.17 40.47h4.82v3.49h-4.82z"
    />
    <path
      className="InstrumentClavinet_svg__st28"
      d="M17.11 41.51h6.24v2.6h-6.24zM17.11 46.78h6.24v2.6h-6.24z"
    />
  </svg>
);

export default InstrumentClavinet;

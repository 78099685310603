import { DateTime } from "luxon";
import { useState } from "react";
import useInterval from "../hooks/useInterval";
import { Trans as Translate } from 'react-i18next';

import './Countdown.scss';

const TIME_PHRASE = {
  days    : "Days",
  hours   : "Hours",
  minutes : "Minutes",
  seconds : "Seconds",
};

const TIME_PHRASE_SINGULAR = {
  days    : "Day",
  hours   : "Hour",
  minutes : "Minute",
};

const TIME_LETTER = {
  days    : "d",
  hours   : "h",
  minutes : "m",
  seconds : "s",
};

const calculateTime = () => {
  const countdownTime = DateTime.fromISO('2022-06-01');

  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0; 

  if (countdownTime.isValid && countdownTime > DateTime.local()) {
    const durationTime = countdownTime.diffNow(['days', 'hours', 'minutes', 'seconds']);

    days = durationTime.days;
    hours = durationTime.hours;
    minutes = durationTime.minutes;
    seconds = Math.floor(durationTime.seconds);
  }

  return { days, hours, minutes, seconds };
} //, [time]);

export default function Countdown({ inline, time }) {

  const [displayTime, setDisplayTime] = useState(calculateTime());

  useInterval(() => {
    setDisplayTime(calculateTime());
  }, 1000);

  const pretty = number => {
    return (number < 10 ? `0${number}` : number )
  }

  const inlineTimer = unit => (
    <div>{ unit === 'seconds' ? pretty(displayTime[unit]) : displayTime[unit]}{ TIME_LETTER[unit] }</div>
  );

  const fullTimer = unit => (
    <>
      <div className='time-amount'>{unit === 'seconds' ? pretty(displayTime[unit]) : displayTime[unit]}</div>
      <div className='time-unit'>
        { displayTime[unit] === 1 && unit !== 'seconds' 
        ? <Translate>{ TIME_PHRASE_SINGULAR[unit] }</Translate>
        : <Translate className='time-unit'>{ TIME_PHRASE[unit] }</Translate>
        }
      </div>
    </>
  );

  const renderTimeUnit = unit => (

    <div className='time-unit-container'>
      { inline ? inlineTimer(unit) : fullTimer(unit) }
      {/* <div className='time-amount'>{unit === 'seconds' ? pretty(displayTime[unit]) : displayTime[unit]}</div> */}
      
    </div>
  );

  return (
    <div className='__countdown-timer'>
      { renderTimeUnit('days')}
      { renderTimeUnit('hours')}
      { renderTimeUnit('minutes')}
      { renderTimeUnit('seconds')}
    </div>
  );
}

import { DateTime } from "luxon";
import { forwardRef, useEffect, useState } from "react";
import { Trans as Translate, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useDebouncedCallback } from "use-debounce/lib";

import { commentsActions, commentsSelectors } from "../../store/commentsSlice";
import { usersSelectors } from "../../store/usersSlice";

import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import EnrollmentFlow from "../../registration/EnrollmentFlow";
import UpdateProfileModal from "../Settings/UpdateProfileModal";
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";
import FeedbackModal from "../../modals/Feedback/FeedbackModal";
import DeleteCommentModal from "./DeleteCommentModal";

import CommentForm from "./CommentForm";
import HeartIconOutline from '../../icons/HeartIconOutline';

import './Comments.scss';

export default function Comments({ song }) {

  const [showFeedback, setShowFeedback] = useState(false);
  const [showDisplayName, setShowDisplayName] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [reportMetadata, setReportMetadata] = useState(false);
  const [showReplyTo, setShowReplyTo] = useState(null);
  const [submittingComment, setSubmittingComment] = useState(null);
  const [pendingComment, setPendingComment] = useState(null);
  const [pendingLike, setPendingLike] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteComment, setDeleteComment] = useState(null);
  const [showEnroll, setShowEnroll] = useState(false);

  const dispatch = useDispatch();
  
  const currentUser = useSelector(usersSelectors.getCurrentUser);
  const comments = useSelector(commentsSelectors.getComments);
  const commentsCount = useSelector(commentsSelectors.getCount);
  const parentHasMoreReplies = useSelector(commentsSelectors.getParentHasMoreReplies);
  const parentPage = useSelector(commentsSelectors.getParentPage);
  const parentRepliesRequest = useSelector(commentsSelectors.getParentRepliesRequest);

  const { t } = useTranslation();

  const songId = song.id;
  
  useEffect(() => {
    const load = async () => {
      dispatch(commentsActions.fetch());
      dispatch(commentsActions.setCount({ count: song.comment_count }));
    }

    load();
  }, [songId, dispatch, song.comment_count]);

  const loadReplies = async (replyTo, page) => {
    dispatch(commentsActions.fetch({ replyTo, page }));
  }

  const onSubmitComment = async (message, replyTo) => {
    setSubmittingComment(replyTo || 'parent');
    await dispatch(commentsActions.create({ message, replyTo })).unwrap();
    
    setSubmittingComment(null);
  }

  const onReplyComment = (e, comment, checkCurrentUser = true) => {

    if (checkCurrentUser) {
      if (!currentUser?.registered) {
        setPendingComment({ id: comment.id, reply_count: comment.reply_count, repliesRequest: comment.repliesRequest });
        setPendingLike(null);
        setShowEnroll(true);

        return false;
      }
  
      else if (!currentUser.display_name) {
        setPendingComment({ id: comment.id, reply_count: comment.reply_count, repliesRequest: comment.repliesRequest });
        setPendingLike(null);
        setShowDisplayName(true);

        return false;
      }
    }

    if (!showReplyTo && e) {
      setTimeout(() => {
        const rect = e.target.getBoundingClientRect();

        if (rect.bottom + 80 > window.innerHeight) {
          window.scrollBy({ top: 80, behavior: 'smooth' });
        }
      }, 60);
    }

    setShowReplyTo(comment.id);

    // load the previous replies when hitting "reply":
    if (comment.reply_count > 0 && !comment.repliesRequest) {
      loadReplies(comment.id);
    }

    pendingComment && setPendingComment(null);
  }

  const onHideUpdateDisplayName = () => {
    setShowDisplayName(false);
  }

  const onDeleteComment = (comment, parentComment) => {
    const { id, reply_to: replyTo, reply_count: replyCount } = comment;
    const { hasMoreReplies, _page: page } = parentComment;

    setDeleteComment({ id, hasMoreReplies, page, replyTo, replyCount });
    setShowDeleteConfirmation(true);
  }
  
  const confirmDelete = () => {
    dispatch(commentsActions.remove(deleteComment));
    setShowDeleteConfirmation(false);
  }

  const onToggleLike = comment => {

    if (!currentUser) {
      setPendingLike(comment.id);
      setPendingComment(null);
      setShowEnroll(true);

      return;
    }

    // if a request is pending, cancel it (it means the comment like status didn't change):
    debouncedLikeRequest.isPending() ? debouncedLikeRequest.cancel() : debouncedLikeRequest(comment.id, comment.liked);
    dispatch(commentsActions.toggleLike({ id: comment.id }));
  }
  
  const debouncedLikeRequest = useDebouncedCallback((id, liked) => {
    const action = liked ? commentsActions.dislike : commentsActions.like
    dispatch(action({ id }));
  }, 300);

  const onReportComment = comment => {
    setShowFeedback(true);
    setReportId(comment.id);
    
    const metadata = [
      t('song-url', { url: window.location.href }),
      t('report-comment-id', { id: comment.id }),
      `Message: ${comment.message}`,
    ];

    setReportMetadata(metadata.join('\n'));
  }

  const onReportSuccess = () => {
    dispatch(commentsActions.report({ id: reportId }));
  }

  const denyMainCommentFocus = !currentUser || !currentUser.display_name;

  const onFocusMainComment = () => {
    if (!currentUser) {
      setShowEnroll(true);
    }
    else if (!currentUser.display_name) {
      setShowDisplayName(true)
    }
  }

  const onEnrollSuccess = fetchedUser => {
    setShowEnroll(false);

    if (pendingLike) {
      debouncedLikeRequest(pendingLike, false);
      dispatch(commentsActions.toggleLike({ id: pendingLike }));

      setPendingLike(null);
    }

    else if (pendingComment) {
      if (!fetchedUser?.display_name) {
        setShowDisplayName(true);
      }
      else {
        onReplyComment(null, pendingComment, false)
      }
    }

  }

  const renderComment = (comment, nesting = 0, parentComment) => {

    // song creator can delete any comment, comment creator can delete his comments (and nested replies):
    const canDelete = song.creator_id === currentUser?.id || comment.creator_id === currentUser?.id;

    return (
      <div className='entry' key={comment.id} style={{ marginLeft: nesting * 16 }}>
        <div className='header-controls'>
          <div className='header'>
            <div className='display-name'>{ comment.creator.display_name }</div>
            <div className='date'>{ new DateTime.fromISO(comment.created_at).toLocaleString() }</div>
          </div>
          <Dropdown>
            <Dropdown.Toggle as={controlsToggle} />
            <Dropdown.Menu align='right'>
              { canDelete && <Dropdown.Item onClick={() => onDeleteComment(comment, parentComment)}><Translate>Delete</Translate></Dropdown.Item> }
              <Dropdown.Item onClick={() => onReportComment(comment)}><Translate>Report</Translate></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={classNames('message', { reported: comment.reported })}>
          { comment.message }
        </div>

        { comment.reported && (
          <div className='report-message'><Translate>Your comment report has been received.</Translate></div>
        )}
        
        <div className='controls'>
          <div className='likes'><HeartIconOutline role='button' className={classNames({ active: comment.liked })} onClick={() => onToggleLike(comment)} />{ comment.like_count }</div>
          { !nesting && <Button className='reply-btn' variant='link' onClick={e => onReplyComment(e, comment)}><Translate>Reply</Translate></Button> }
        </div>

        { showReplyTo === comment.id && (
          <CommentForm nesting={nesting+1} autoFocus onSubmitComment={async message => await onSubmitComment(message, comment.id)} onCancelComment={() => setShowReplyTo(null)} disabled={submittingComment} pending={submittingComment === comment.id} />
        )}
        { comment.reply_count > 0 && (!comment.repliesRequest || comment.repliesRequest === 'initial-load') && (
          <Button variant='link' className='view-replies' onClick={() => loadReplies(comment.id)} disabled={comment.repliesRequest === 'initial-load'}>
            <span className='arrow' />
            <Translate i18nKey='view-x-replies' values={{ count: comment.reply_count}} />
          </Button>
        )}
        { comment.entities && (
          <div className='nested-comments'>{ comment.entities.map(childComment => renderComment(childComment, nesting + 1, comment)) }</div>
        )}
        { comment.hasMoreReplies && comment.repliesRequest !== 'load-more' && (
          <div className='view-more-replies'><Button variant='link' onClick={() => loadReplies(comment.id, comment._page + 1)}><Translate>View more replies</Translate></Button></div>
        )}
        { (comment.repliesRequest === 'initial-load' || comment.repliesRequest === 'load-more') && <div className='initial-load'><InfiniteLoader /></div> }
      </div>
    );
  }

  if (!parentRepliesRequest) {
    return null;
  }

  const renderMainComments = () => {

    if (parentRepliesRequest === 'initial-load') {
      return <div className='loading-main-comments'><InfiniteLoader /></div>
    }

    return (
      <>
        <CommentForm buttonsHidden onSubmitComment={onSubmitComment} disabled={submittingComment} pending={submittingComment === 'parent'} onFocusTextArea={denyMainCommentFocus ? onFocusMainComment : null} />
        { comments && comments.map(comment => renderComment(comment, 0, { hasMoreReplies: parentHasMoreReplies, _page: parentPage })) }
        { parentHasMoreReplies && <div className='view-more-replies'><Button variant='link' onClick={() => loadReplies(null, parentPage + 1)}><Translate>View more replies</Translate></Button></div> }
      </>
    )
  }

  const displayModalBody = <Translate i18nKey='set-display-name' />

  return (
    <div className='__comments'>
      <div className='comment-count'><Translate i18nKey='x-comments' values={{ count: commentsCount }} /></div>
      { renderMainComments() }
      <FeedbackModal show={showFeedback} onClose={() => setShowFeedback(false)} onSuccess={onReportSuccess} initialType='report' metadata={reportMetadata} />
      <UpdateProfileModal show={showDisplayName} field='display_name' onHide={onHideUpdateDisplayName} onUpdateSuccess={() => pendingComment && onReplyComment(null, pendingComment, false)} body={displayModalBody} />
      <DeleteCommentModal show={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)} onConfirm={confirmDelete} hasNestedReplies={deleteComment?.replyCount > 0} />
      <EnrollmentFlow showWarmup show={showEnroll} from='publicPlayer' onClose={() => setShowEnroll(false)} requireDisplayName={!pendingLike} onSuccess={onEnrollSuccess} />
    </div>
  );
}

const controlsToggle = forwardRef(({ onClick }, ref) => (
  <div ref={ref} onClick={onClick} className='controls-toggle' role='button'>···</div>
));

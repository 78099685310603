import * as React from "react";

const InstrumentRecorder = (props) => (
  <svg
    id="InstrumentRecorder_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentRecorder_svg__st3{fill:#4e4092}.InstrumentRecorder_svg__st5{fill:#cfd125}.InstrumentRecorder_svg__st6{fill:#ca5672}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      className="InstrumentRecorder_svg__st3"
      d="m25.17 82.08-1.94.83-6.16 6.62-.08 2.03 5.15 4.8 2.02-.22 6.17-6.62.69-1.99zM17.24 102.62c-.49-1.83 4.02-6.09 4.02-6.09l-4.38-4.09s-3.93 4.81-5.79 4.45c-1.61-.31 6.58 7.31 6.15 5.73zM62.47 41.63 26.11 82.07l4.85 4.52 37.78-39.12zM91.49 24.46s6.17-5.68 6.36-6.83c.19-1.14 2.08-5.42 2.08-5.42.35-1.32-3.09-4.42-4.5-4.19 0 0-4.13 2.2-5.26 2.46-1.13.27-6.36 6.83-6.36 6.83s-9.9 12.87-11.78 14.65c-1.87 1.77-8.42 4.79-8 7.28l6.97 6.5c2.51.25 5.06-6.5 6.7-8.49 1.65-2 13.79-12.79 13.79-12.79z"
    />
    <path
      className="InstrumentRecorder_svg__st5"
      d="m30.66 87.51-5.57-5.19a.556.556 0 0 1-.03-.79c.21-.23.56-.24.79-.03l5.57 5.19c.23.21.24.56.03.79-.21.23-.56.24-.79.03zM21.11 96.63l-4.47-4.16a.58.58 0 0 1-.03-.81.58.58 0 0 1 .81-.03l4.47 4.16c.23.21.24.58.03.81a.58.58 0 0 1-.81.03zM68.54 47.5l-5.97-5.56c-.63-.59-.67-1.58-.08-2.22.59-.63 1.58-.67 2.22-.08l5.97 5.56c.63.59.67 1.58.08 2.22-.6.63-1.59.67-2.22.08z"
    />
    <path
      className="InstrumentRecorder_svg__st6"
      d="M87.65 23.97 85 21.5a.384.384 0 0 1-.02-.55l.95-1.02c.15-.16.4-.17.55-.02l2.65 2.47c.16.15.17.4.02.55l-.95 1.02c-.14.15-.39.16-.55.02z"
    />
    <circle
      className="InstrumentRecorder_svg__st6"
      cx={60.2}
      cy={50.29}
      r={1.11}
    />
    <circle
      className="InstrumentRecorder_svg__st6"
      cx={54.43}
      cy={56.47}
      r={1.11}
    />
    <circle
      className="InstrumentRecorder_svg__st6"
      cx={48.79}
      cy={62.53}
      r={0.92}
    />
  </svg>
);

export default InstrumentRecorder;

import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import { Trans as Translate, useTranslation } from 'react-i18next';
import { usersActions, usersSelectors } from "../../store/usersSlice";
import { strofeApi } from "../../api/strofeApi";
import UserEvents from "../../utils/UserEvents";
import { EventTracker } from "../../utils/Tracking";
import { Redirect } from "react-router-dom";
import { PRICING_TIER } from "../../utils/Prices";

import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";

import NavigationHeader from "../NavigationHeader";
import UpdateProfileModal from '../Settings/UpdateProfileModal';
import PaymentModal from "./PaymentModal";
import RegistrationModal from "../../registration/RegistrationModal";
import DownloadTrackFlow from "../../modals/Download/DownloadTrackFlow";
// import MembersBadge from "../../icons/art/MembersBadge";
import MemberAvatar from "../../icons/art/MemberAvatar";
import StrofeCoin from "../../icons/coins/StrofeCoin";

import './Pricing.scss';
import classNames from "classnames";

const QUESTIONS = [
  {
    question : 'What are the benefits of Strofe Premium Membership?',
    answer   : 'faq-members-what',
  },

  {
    question : 'Can I use a track for commercial purposes?',
    answer   : 'faq-commercial-purpose-dl',
  },

  {
    question : 'How long do I own a track for?',
    answer   : 'faq-ownership'
  },

  {
    question : 'How does Strofe use AI to create compositions?',
    answer   : 'faq-strofe-ai'
  },

  {
    question : 'Are all tracks unique?',
    answer   : 'faq-tracks-unique'
  },

  {
    question : 'Will tracks change over time?',
    answer   : 'faq-updates'
  },
]

export default function Pricing() {
  const currentUser = useSelector(usersSelectors.getCurrentUser);
  const abTests = useSelector(usersSelectors.getAbTests);

  const [showPayment, setShowPayment] = useState(false);
  const [showPaymentAfterRegister, setShowPaymentAfterRegister] = useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [pricingTier, setPricingTier] = useState(null);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showSetDisplayName, setShowSetDisplayName] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [song, setSong] = useState(null);
  const [showSubscribed, setShowSubscribed] = useState(() => currentUser.subscribed);
  const [trialEnds, setTrialEnds] = useState(null);
  const [showFreeTrialInModal, setShowFreeTrialInModal] = useState(false);
  const [billCycle, setBillCycle] = useState('yearly');

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const purchaseSongId = useMemo(() => new URLSearchParams(location.search).get('purchase_song'), [location]);
  const songFormat = useMemo(() => new URLSearchParams(location.search).get('format'), [location]);
  const noRedirect = useMemo(() => new URLSearchParams(location.search).get('no_redirect') === 'true', [location]);

  const faqRef = useRef();
  const pricingRef = useRef();

  const subscribeTest = abTests['subscriptions-multi-tiers'];
  const priceRange = subscribeTest?.variant.includes('regular') ? 'regular' : 'discount';

  const showFreeTrial = false; //!currentUser.unsubscribed && !currentUser.subscribed;
  const overrideFreeTrial = false; //subscribeTest?.variant.includes('trial') && showFreeTrial;

  // track Pageviews to Get Coins:
  useEffect(() => {
    UserEvents.logEvent('purchase_flow.pageview', subscribeTest);
  }, [subscribeTest]);

  useEffect(() => {
    const getSong = async () => {
      if (purchaseSongId) {
        const res = await strofeApi.get(`/songs/${purchaseSongId}`);
        setSong(res.data);
      }
    }

    getSong();
  }, [purchaseSongId]);

  // Non-subscribed users who are in control test should go to get-coins
  if (!noRedirect && ((!currentUser.subscribed && subscribeTest?.variant === 'control') || !currentUser.can_subscribe)) {
    return <Redirect to='/get-coins' />
  }

  const showMembershipPayment = (tier, cadence) => {
    setPricingTier(`${tier}_${cadence}_${priceRange}`);

    UserEvents.logEvent(`purchase_flow.click_CTA_button`, subscribeTest);

    if (currentUser.registered) {
      setShowPayment(true);
      EventTracker.selectItem();
    }
    else {
      setShowPaymentAfterRegister(true);
      setShowRegistration(true);
    }
  }

  const onPaymentSuccessful = async trialEnds => {
    setShowPayment(false);
    purchaseSongId && (!song.purchased || (!song.midi_purchased && songFormat === 'midi_only')) ? setShowDownload(true) : setShowPaymentSuccess(true);
    showFreeTrial && trialEnds && setTrialEnds(DateTime.fromISO(trialEnds));
    UserEvents.logEvent('purchase_flow.processed_payment', subscribeTest);

    const { data: subscription } = await strofeApi.get('/subscriptions/' + currentUser.id);
    dispatch(usersActions.setSubscription({ subscription }));

    // if the subscription status is 'trialing' after payment successful, show it in the modal:
    if (subscription.status === 'trialing') {
      setShowFreeTrialInModal(true);
    }
  }

  const onCloseRegistration = () => {
    setShowRegistration(false);
    setShowPaymentAfterRegister(false);
  }

  const onUserCreated = () => {
    if (showPaymentAfterRegister) {
      setShowPayment(true);
      EventTracker.selectItem();
    }

    onCloseRegistration();
  }

  const onPurchase = async (_, format) => {
    song.purchased = true;
    song.generated = false;

    if (format) {
      song[`${format}_purchased`] = true;
    }

    setSong(JSON.parse(JSON.stringify(song)));
  }

  const renderFaq = () => (
    <section className='__get-coins-faq' ref={faqRef}>
      { QUESTIONS.map(question => (
        <div className='question' key={question.question}>
          <h3>{ question.question }</h3>
          <Translate i18nKey={question.answer} />
        </div>
      ))}

      <p className='text-center'>
        <Link className='start-trial' to='#start-trial' onClick={() => pricingRef.current.scrollIntoView()} disabled={currentUser.subscribed}>
          { showFreeTrial ? <Translate>Start Free Trial</Translate> : <Translate>Subscribe Now</Translate> }
        </Link>
      </p>
      
    </section>
  );

  const renderPaymentSection = () => {
    // if (!yearlySubscription) {
    //   return (
    //     <div className='trial-non-overflow' ref={pricingRef} id="start-trial">
    //       <section className='pricing'>
    //         <div className='start-trial-container'>
    //           <MembersBadge className='badge' />

    //           <h2><Translate>Become a Strofe Premium Member</Translate></h2>

    //           <div className='benefits'>
    //             <div><Translate>Create unlimited tracks</Translate></div>
    //             <div><Translate>Pick up to 300 songs every month to own forever</Translate></div>
    //             <div><strong>
    //               { showFreeTrial
    //               ? <Translate>$10 / month with a 7 Day Free Trial</Translate>
    //               : <Translate>$10 / month</Translate>
    //               }
    //             </strong></div>
    //             <div><Translate>Be the first to know about Strofe's updates and improvements</Translate></div>

    //             <Button className='start-trial-button' onClick={() => showMembershipPayment()} data-test='PRICING-star-trial' disabled={currentUser.subscribed}>
    //               { showFreeTrial
    //               ? <Translate>Start Free Trial</Translate>
    //               : <Translate>Subscribe Now</Translate>
    //               }
    //             </Button>
    //           </div>
    //         </div>
    //       </section>
    //     </div>
    //   );
    // }
    if (true) {
    // else {
      
      const billMonthly   = billCycle === 'monthly';
      const titlePhrase   = billMonthly ? 'Monthly' : 'Annually';
      // const cadencePhrase = billMonthly ? 'Month'   : 'Year';
      const standardPrice = PRICING_TIER[`std_${billCycle}_${priceRange}`]?.[billMonthly ? 'price' : 'price_m'];
      const proPrice      = PRICING_TIER[`pro_${billCycle}_${priceRange}`]?.[billMonthly ? 'price' : 'price_m'];

      return (
        <div className='yearly-membership' ref={pricingRef} id="start-trial">
          <h1><Translate>Pricing</Translate></h1>
          <div className='bill-cycle-select'>
            <div role='button' className={classNames({ active: billCycle === 'monthly' })} onClick={() => setBillCycle('monthly')}>
              <h3><Translate>Monthly</Translate></h3>
            </div>

            <div role='button' className={classNames({ active: billCycle === 'yearly' })} onClick={() => setBillCycle('yearly')}>
              <h3><Translate>Annually</Translate></h3>
              <div><Translate>Save up to 25%</Translate></div>
            </div>

          </div>

          <div className='plans-container'>
            <div className='plans'>
              <div className='plan free'>
                <h3><Translate>Free, Forever</Translate></h3>
                <div className="price"><h3>$0</h3></div>
                <p><Translate>Start off by creating an unlimited number of compositions.</Translate></p>
                <ul>
                  <li><Translate>Choose from more than 120 instruments and 8 genres</Translate></li>
                  <li><Translate>Unlimited number of tracks</Translate></li>
                  <li><Translate>Easily share your compositions with others</Translate></li>
                </ul>

                <div className='signup-button-container'>
                  <div className='signup-button'><Translate>Current Plan</Translate></div>
                </div>
              </div>

              <div className='plan standard'>
                <h3><Translate>Standard { titlePhrase }</Translate></h3>
                
                <div className="price">
                  <h3>${standardPrice} <span className="cadence">/ <Translate>month</Translate></span></h3>
                  { !billMonthly && <div className="small-print"><Translate>Billed annually</Translate></div> }
                </div>

                <p><Translate>Own 100% of the songs you make with Strofe and gain access to enhanced customization features.</Translate></p>
                <ul>
                  <li><Translate>All benefits from the Free Tier.</Translate></li>
                  <li><Translate>Download and own 50 tracks per month.</Translate></li>
                  <li><Translate>High-quality MP3 and MIDI files.</Translate></li>
                  <li><Translate>Compose your own Loops</Translate></li>
                </ul>

                <div className='signup-button-container'>
                  <button className='signup-button' disabled={currentUser.subscribed} onClick={() => showMembershipPayment('std', billCycle)}><Translate>{overrideFreeTrial ? 'Start Trial' : 'Select'}</Translate></button>
                </div>
              </div>

              <div className='plan pro'>
                <h3><Translate>Pro { titlePhrase }</Translate></h3>
                <div className="price">
                  <h3>${proPrice} <span className="cadence">/ <Translate>month</Translate></span></h3>
                  { !billMonthly && <div className="small-print"><Translate>Billed annually</Translate></div> }
                </div>
                <p><Translate>For creators or makers who need the best out of Strofe.</Translate></p>
                <ul>
                  <li><Translate>All features from our Standard plan.</Translate></li>
                  <li><Translate>Own and download 300 tracks per month.</Translate></li>
                  <li><Translate>Download lossless high fidelity WAV audio tracks.</Translate></li>
                  <li><Translate>Early access to the newest features in Strofe</Translate></li>
                </ul>

                <div className='signup-button-container'>
                  <button className='signup-button' disabled={currentUser.subscribed} onClick={() => showMembershipPayment('pro', billCycle)}><Translate>{overrideFreeTrial ? 'Start Trial' : 'Select'}</Translate></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="__pricing-page">
      <NavigationHeader />

      { renderPaymentSection() }
      { renderFaq() }

      <Modal className='__modal __welcome-premium-modal' show={showPaymentSuccess}>
        <Modal.Header>
          <Translate>Welcome To Strofe Premium</Translate>
        </Modal.Header>
        <Modal.Body>
          <MemberAvatar className='avatar' />
          <p><strong><Translate i18nKey='download-tracks-premium' components={{ icon: <StrofeCoin /> }} /></strong></p>
          { showFreeTrialInModal && <p><em><Translate i18nKey='trial-ends-date' values={{ date: trialEnds?.toLocaleString(DateTime.DATE_FULL) }} /></em></p> }
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => history.push('/settings')}><Translate>Close</Translate></Button>
        </Modal.Footer>
      </Modal>

      <Modal className='__modal' show={showSubscribed} size='sm'>
        <Modal.Header>
          <Translate>Strofe Premium</Translate>
        </Modal.Header>
        <Modal.Body>
          <p><Translate>You're already subscribed to Strofe.</Translate></p>
          <p><Translate>You can update your subscription on the <Link to='/settings'>Settings Page</Link>.</Translate></p>
          <p><Translate>If you have any questions, feel free to reach us through the Feedback form.</Translate></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => setShowSubscribed(false)}><Translate>Close</Translate></Button>
        </Modal.Footer>
      </Modal>

      <PaymentModal show={showPayment} onHide={() => setShowPayment(false)} pricingTier={pricingTier} currentUser={currentUser} onPaymentSuccessful={onPaymentSuccessful} showFreeTrial={showFreeTrial} overrideFreeTrial={overrideFreeTrial} cadence={t(billCycle)} />
      <RegistrationModal onUserCreated={onUserCreated} onClose={onCloseRegistration} showWarmup={false} show={showRegistration} from='generic' />
      <UpdateProfileModal show={showSetDisplayName} field="display_name" onHide={() => setShowSetDisplayName(false)} />

      <DownloadTrackFlow song={song} show={showDownload} purchaseFlow onHide={() => setShowDownload(false)} initialAddMidi={songFormat === 'mp3_midi'} initialGetMidiOnly={songFormat === 'midi_only'} onPurchase={onPurchase} />
    </div>
  );
}

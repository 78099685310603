import { useMemo, useState } from "react";
import { useLocation } from "react-router";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import StrofeLogoColors from "../../icons/StrofeLogoColors";
import './PasswordReset.scss';
import { useDispatch } from "react-redux";
import { usersActions } from "../../store/usersSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";

export default function PasswordReset() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  
  const token = useMemo(() => new URLSearchParams(location.search).get('token'), [location]);
  const user_id = useMemo(() => new URLSearchParams(location.search).get('user_id'), [location]);

  const [showInvalidInfo, setShowInvalidInfo] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const [password, setPassword] = useState('');

  const changePassword = e => {
    setPassword(e.target.value)
  }

  const submitDisabled = !password;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setShowInvalidInfo(false);
    setLoading(true);
    
    try {
      const action = await dispatch(usersActions.resetPassword({ user_id, token, password }));
      unwrapResult(action);
      setResetSuccessful(true);
    }
    catch (error) {
      setShowInvalidInfo(true);
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  const renderForm = () => (
    <Form onSubmit={handleSubmit} className='reset-form'>
      <Form.Group>
        <Form.Label>Enter your new password:</Form.Label>
        <Form.Control type="password" placeholder="Password" required minLength={6} maxLength={20} value={password} onChange={changePassword} />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={submitDisabled || loading}>Reset Password</Button>

      { showInvalidInfo && (
        <Alert variant='warning' className='my-2'>
          Your password reset email has expired or the application is invalid.
        </Alert>
      )}
    </Form>
  );

  const renderResetSuccessful = () => (
    <div>
      <p>Your password was reset.</p>
      <p><Link to='/'>Click here to go back and login.</Link></p>
    </div>
  )

  return (
    <div className="__password-reset">
      <StrofeLogoColors width={220} viewBox="0 200 2000 1600" />
      { !resetSuccessful ? renderForm() : renderResetSuccessful() }
    </div>
  )
}
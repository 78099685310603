export default function MemberAvatar(props) {
  return (
    <svg
      fill="none"
      viewBox="0 0 290 351"
      {...props}
    >
      <path
        d="M237.4 296.1H51.7C23.3 296.1 0 272.8 0 244.4V52.2C0 23.8 23.3.5 51.7.5h185.7c28.4 0 51.7 23.3 51.7 51.7v192.1c0 28.5-23.3 51.8-51.7 51.8Z"
        fill="#528452"
      />
      <path
        d="M15.9 78.1h111.5s8.7 61.8-55.7 61.8-55.8-61.8-55.8-61.8ZM161 78.1h111.5s8.7 61.8-55.7 61.8S161 78.1 161 78.1Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={14.1}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M127.4 78.1H161"
        stroke="#fff"
        strokeWidth={14.1}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="m58.4 278.7 15.7 71.7 11-27.9 17 14.9-36.3-77.6-7.4 18.9Z"
        fill="#fff"
      />
      <path
        d="m53.1 270.6-28.4 67.6 25-16.6 5.4 22.1 15-84.4-17 11.3Z"
        fill="#fff"
      />
      <path
        d="M81.4 212.8c-.2 4.8 4.9 7.9 9 5.5 5.5-3.1 11.5 3.2 8.1 8.5-2.5 4 .3 9.3 5.1 9.3 6.3 0 8.4 8.5 2.8 11.4-4.2 2.2-4.4 8.2-.3 10.6 5.4 3.2 3 11.5-3.3 11.3-4.8-.2-7.9 4.9-5.5 9 3.1 5.5-3.2 11.5-8.5 8.1-4-2.5-9.3.3-9.3 5.1 0 6.3-8.5 8.4-11.4 2.8-2.2-4.2-8.2-4.4-10.6-.3-3.2 5.4-11.5 3-11.3-3.3.2-4.8-4.9-7.9-9-5.5-5.5 3.1-11.5-3.2-8.1-8.5 2.5-4-.3-9.3-5.1-9.3-6.3 0-8.4-8.5-2.8-11.4 4.2-2.2 4.4-8.2.3-10.6-5.4-3.2-3-11.5 3.3-11.3 4.8.2 7.9-4.9 5.5-9-3.1-5.5 3.2-11.5 8.5-8.1 4 2.5 9.3-.3 9.3-5.1 0-6.3 8.5-8.4 11.4-2.8 2.2 4.2 8.2 4.4 10.6.3 3.2-5.5 11.5-3 11.3 3.3Z"
        fill="#fff"
      />
      <path
        d="M63.3 282.9c17.066 0 30.9-13.834 30.9-30.9s-13.834-30.9-30.9-30.9-30.9 13.834-30.9 30.9 13.834 30.9 30.9 30.9Z"
        fill="#F18860"
      />
      <path
        d="M70.7 251.3c-1.5-1.1-2.9-1.9-5.2-3.1-.5-.3-1.1-.6-1.7-.9h-.1c-3.5-1.9-5.2-3-5.1-5.8.1-2.6 1.9-5.2 5.5-5 1.5.1 4.1.7 5.6 3.9l.6 1.4 3.7-2.9-.4-.9c-1.8-3.6-5.1-5.8-9.3-6-6.7-.3-10.4 4.5-10.6 9.4-.1 1.7.3 3.2 1.1 4.7 1.3 2.4 3.3 3.5 6.6 5.4 2.8 1.5 4.7 2.6 5.7 3.2 2.4 1.6 2.6 3.4 2.6 4.6-.2 3.9-3.4 6.7-7.5 6.5-2.2-.1-4.3-1.2-5.4-2.8-.4-.5-1.2-1.8-1.5-3l-.4-1.3-4.3 1.8.3 1.1c.3 1 .9 2.5 2.3 4.3 1.4 1.8 3.9 4.3 8.8 4.5 6.8.3 12.3-4.4 12.6-10.8.2-4.7-2.1-7-3.9-8.3Z"
        fill="#fff"
      />
      <path
        d="M185.6 52.6s23-7.6 43.7 3.8M97.6 52.6s-23-7.6-43.7 3.8"
        stroke="#fff"
        strokeWidth={15.146}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.2 218s48.5 10.9 76.8-29.6"
        stroke="#fff"
        strokeWidth={17.292}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { resetAccessToken } from '../api/strofeApi';
import { DateTime } from 'luxon';
import { Trans as Translate } from 'react-i18next';

import { usersActions, usersSelectors } from '../store/usersSlice';
import { EventTracker } from '../utils/Tracking';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import SSOButtons from './SSOButtons';
import InfiniteLoader from '../layout/InfiniteLoader/InfiniteLoader';

import './LoginModal.scss';

const MODAL_PHRASES = {
  download: {
    WARM_UP_TITLE : 'Download Piece',
    WARM_UP_COPY  : <><p><strong>Your music track is ready!</strong></p><p>Register for free to download and save all your creations.</p></>,
    SUCCESS_COPY  : <><p>Registration complete!</p><p>All your songs are available for download and for playback later inside your library.</p></>,
  },

  subscribe: {
    WARM_UP_TITLE : 'Download Piece',
    WARM_UP_COPY  : <><p><strong>Your music track is ready!</strong></p><p>Register for free to download and save all your creations.</p></>,
    SUCCESS_COPY  : <><p>Registration complete!</p><p>All your songs are available for download and for playback later inside your library.</p></>,
  },

  createSong: {
    WARM_UP_TITLE : 'Sign up Required',
    WARM_UP_COPY  : <><p><strong>You have created 3 music pieces.</strong></p><p>Register for free to create more music.</p></>,
    SUCCESS_COPY  : <><p>Registration successful!</p><p>Your new music piece is now being created...</p></>,
  },

  feedback: {
    WARM_UP_TITLE : 'Sign up Required',
    WARM_UP_COPY  : <p>We thank you for providing feedback. Register (it's free) so that we can get back to you via email.</p>,
    SUCCESS_COPY  : <p>Registration successful!</p>,
  },

  share: {
    WARM_UP_TITLE : 'Share Your Track',
    WARM_UP_COPY  : <p>Register for free to share your track with others.</p>,
    SUCCESS_COPY  : <p>Registration successful!</p>,
  },

  coupon: {
    WARM_UP_TITLE : 'Redeem Coupon',
    WARM_UP_COPY  : <p>Register for free to redeem your Strofe Coins.</p>,
    SUCCESS_COPY  : <p>Registration successful!</p>,
  },

  publicPlayer: {
    WARM_UP_TITLE : 'Sign up Required',
    WARM_UP_COPY  : <p>Sign up to comment on Strofe and like other people's tracks.</p>,
    SUCCESS_COPY  : <p>Registration successful!</p>,
  },

  generic: {
    SUCCESS_COPY  : <p>Registration successful!</p>,
  }
}

export default function RegistrationModal({ show, onClose, showWarmup = true, onUserCreated, from = 'download', showLoginLink, onLogin, fetchOnSuccess, requireDisplayName }) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showUserCreated, setShowUserCreated] = useState(false);
  const [duplicatedError, setDuplicatedError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [displayName, setDisplayName] = useState(undefined);
  const [fetchedUser, setFetchedUser] = useState(null);

  const phrases = MODAL_PHRASES[from];

  const dispatch = useDispatch();

  const currentUser = useSelector(usersSelectors.getCurrentUser);

  useEffect(() => {
    if (!show) {
      setPassword('');
      setShowSignup(false);
      setDuplicatedError(false);
      setDisplayName(null);
    }
    else {
      const autoFill = process.env.NODE_ENV === 'development' && process.env.REACT_APP_AUTO_FILL_REGISTRATION_USER;

      if (autoFill) {
        const date = new DateTime.now().toFormat('yyyy_MM_dd__hh_mm');
        setEmail(`${date}@strofe.com`);
        setPassword('strofe');
      }
    }
  }, [show]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    
    setDuplicatedError(false);
    setLoading(true);

    try {
      let userId = currentUser?.id;

      if (!currentUser) {
        const referrer = Cookies.get('strofe_referrer');

        const { data } = await Axios.post(process.env.REACT_APP_NOMODO_API + '/registrations', {
          client_id : process.env.REACT_APP_NOMODO_API_CLIENT_ID,
          registration: referrer ? { referrer } : undefined,
        });

        Cookies.set('api_access_token', data.access_token, { expires: 60 });
        Cookies.set('strofe_user_id', data.user_id, { expires: 60 });
        Cookies.remove('strofe_referrer');
        
        resetAccessToken(data.access_token);
        userId = data.user_id;
      }

      await dispatch(usersActions.registerUser({ email, password, id: userId, display_name: displayName })).unwrap();
      
      if (fetchOnSuccess) {
        const { data: userData } = await dispatch(usersActions.fetchById({ id: userId, isCurrentUser: true })).unwrap();
        setFetchedUser(userData);
      }

      setShowUserCreated(true);

      EventTracker.completeRegistration();
    }
    catch (error) {
      // TODO -> supposing it's always a "duplicated" error for now (422)

      console.log('error:', error)
      setDuplicatedError(true);
      setLoading(false);
    }
  }

  const changePassword = e => {
    setPassword(e.target.value)
  }

  const changeEmail = e => {
    setEmail(e.target.value);
  }

  const showSignupModal = () => {
    setShowSignup(true);
  }

  const submitDisabled = !email || !password;

  const handleCloseSignup = () => {
    setShowSignup(false);
    onClose();
  }

  const onCloseSuccessModal = () => {
    handleCloseSignup();
    setShowUserCreated(false);
    onUserCreated?.(fetchedUser);
  }

  const onChangeDisplayName = e => {
    const name = e.target.value.replace(' ', '_');
    setDisplayName(name.replace(/[^A-Za-z0-9_-]/g, ''));
  }

  const omniauthSignUp = async(provider, code, useParam) => {
    setDuplicatedError(false);
    setLoading(true);

    try {
      const { data } = await dispatch(usersActions.omniauthRegisterUser({ provider, code, useParam })).unwrap();
      setShowUserCreated(true);
      setFetchedUser(data);

      EventTracker.completeRegistration();
    }
    catch (error) {
      // TODO -> supposing it's always a "duplicated" error for now (422)
      setDuplicatedError(true);
      setLoading(false);
    }
  }

  const signUpWithFacebook = () => {
    window.FB.login(() => omniauthSignUp('facebook'), { scope: 'email' });
  }

  const signUpWithGoogle = (response) => {
    omniauthSignUp('google_oauth2', response.code);
  }

  const signUpWithDiscord = code => {
    omniauthSignUp('discord', code, true);
  }

  return (
    <>
      <Modal className='__modal' show={showWarmup && show && !showSignup} onHide={onClose} size='sm'>
        <Modal.Header closeButton>
          <Modal.Title>{ phrases?.WARM_UP_TITLE }</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          { phrases?.WARM_UP_COPY }
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>Maybe later</Button>
          <Button variant="primary" onClick={showSignupModal}>Sign up</Button>
        </Modal.Footer>
      </Modal>

      <Modal className='__modal __registration-modal' show={!showUserCreated && (showWarmup ? showSignup : show)} size='sm' backdrop='static'>
        <Modal.Header>
          <Modal.Title>Sign Up</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSignUp} data-test='REGISTRATION-form'>

          { loading && (
            <div className='logging-in'>
              <InfiniteLoader height={48} />
              <p>Creating your account...</p>
            </div>
          )}
          <Modal.Body>

            <div className={classNames({ 'form-hidden': loading })}>
              <SSOButtons onClickFacebook={signUpWithFacebook} onSuccessGoogle={signUpWithGoogle} onSuccessDiscord={signUpWithDiscord} />

              <p>Enter your email and password to sign up for free:</p>

              { requireDisplayName && (
                <Form.Group>
                  <Form.Control required placeholder="Display name" value={displayName || ''} autoComplete='off' autoCorrect='off' onChange={onChangeDisplayName} maxLength={32} minLength={3} />
                </Form.Group>
              )}

              <Form.Group>
                <Form.Control type="email" placeholder="Your email" required value={email} onChange={changeEmail} data-test='REGISTER-email' />
              </Form.Group>

              <Form.Group>
                <Form.Control type="password" placeholder="Password" required minLength={6} maxLength={20} value={password} onChange={changePassword} data-test='REGISTER-password' />
              </Form.Group>
          
              {duplicatedError && (
                <Alert variant='danger' className='p-1 text-center'>
                  { requireDisplayName
                  ? <Translate i18nKey='name-or-email-taken' />
                  : <Translate i18nKey='email-taken' />
                  }
                </Alert>
              )}
              
              { showLoginLink && <Button variant='link' className='w-100' onClick={onLogin}><Translate>Already have an account? Log In</Translate></Button> }
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" disabled={loading} onClick={handleCloseSignup}>Cancel</Button>
            <Button variant="primary" type="submit" disabled={submitDisabled || loading} data-test='REGISTER-sign-up'>Sign up</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal className='__modal' show={showUserCreated} size='sm' backdrop='static'>
        <Modal.Header>
          <Modal.Title>Sign Up</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          { phrases?.SUCCESS_COPY }
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onCloseSuccessModal} data-test='REGISTER-success-close'>Got it!</Button>
        </Modal.Footer>
      </Modal>    
  </>
  );
}
const COMPOSER_SCALES = {
  ionian: {
    phrase     : 'Ionian',
    full       : [0, 2, 4, 5, 7, 9, 11],
    pentatonic : [0, 2, 4, 7, 9],
  },

  dorian: {
    phrase     : 'Dorian',
    full       : [0, 2, 3, 5, 7, 9, 10],
    pentatonic : [0, 3, 5, 7, 10],
  },

  phrygian: {
    phrase     : 'Phrygian',
    full       : [0, 1, 3, 5, 7, 8, 10],
    pentatonic : [0, 3, 5, 7, 10],
  },

  lydian: {
    phrase     : 'Lydian',
    full       : [0, 2, 4, 6, 7, 9, 11],
    pentatonic : [0, 2, 4, 7, 9],
  },

  mixolydian: {
    phrase     : 'Mixolydian',
    full       : [0, 2, 4, 5, 7, 9, 10],
    pentatonic : [0, 2, 4, 7, 9],
  },

  aeolian: {
    phrase     : 'Aeolian',
    full       : [0, 2, 3, 5, 7, 8, 10],
    pentatonic : [0, 3, 5, 7, 10],
  },

  locrian: {
    phrase     : 'Locrian',
    full       : [0, 1, 3, 5, 6, 8, 10],
    pentatonic : [0, 3, 5, 6, 10],
  },

  half_whole_diminished: {
    phrase     : 'Half Whole Diminished',
    full       : [0, 3, 4, 6, 7, 9, 10],
    pentatonic : [0, 4, 6, 7, 10],
  },

  whole_half_diminished: {
    phrase     : 'Whole Half Diminished',
    full       : [0, 2, 3, 5, 6, 8, 9],
    pentatonic : [0, 3, 5, 6, 9],
  },

  whole_tone: {
    phrase     : 'Whole Tone',
    full       : [0, 2, 4, 6, 8, 8, 10],
    pentatonic : [0, 2, 4, 6, 10],
  },

  melodic_minor: {
    phrase     : 'Melodic Minor',
    full       : [0, 2, 3, 5, 7, 9, 11],
    pentatonic : [0, 3, 5, 7, 11],
  },

  sus_flat_nine: {
    phrase     : 'Sus Flat Nine',
    full       : [0, 1, 3, 5, 7, 9, 10],
    pentatonic : [0, 1, 5, 7, 10],
  },

  lydian_augmented: {
    phrase     : 'Lydian Augmented',
    full       : [0, 2, 4, 6, 8, 9, 11],
    pentatonic : [0, 2, 4, 8, 9],
  },

  lydian_dominant: {
    phrase     : 'Lydian Dominant',
    full       : [0, 2, 4, 6, 7, 9, 10],
    pentatonic : [0, 2, 4, 7, 9],
  },

  mixolydian_flat_six: {
    phrase     : 'Mixolydian Flat Six',
    full       : [0, 2, 4, 5, 7, 8, 10],
    pentatonic : [0, 2, 4, 7, 8],
  },

  half_diminished: {
    phrase     : 'Half Diminished',
    full       : [0, 2, 3, 5, 6, 8, 10],
    pentatonic : [0, 3, 5, 6, 10],
  },

  altered: {
    phrase     : 'Altered',
    full       : [0, 3, 4, 6, 8, 8, 10],
    pentatonic : [0, 3, 4, 8, 10],
  },

  harmonic_minor: {
    phrase     : 'Harmonic Minor',
    full       : [0, 2, 3, 5, 7, 8, 11],
    pentatonic : [0, 3, 5, 7, 11],
  },

  locrian_nat_six: {
    phrase     : 'Locrian Nat Six',
    full       : [0, 1, 3, 5, 6, 9, 10],
    pentatonic : [0, 3, 5, 6, 10],
  },

  ionian_sharp_five: {
    phrase     : 'Ionian Sharp Five',
    full       : [0, 2, 4, 5, 8, 9, 11],
    pentatonic : [0, 2, 4, 8, 9],
  },

  dorian_sharp_eleven: {
    phrase     : 'Dorian Sharp Eleven',
    full       : [0, 2, 3, 6, 7, 9, 10],
    pentatonic : [0, 3, 6, 7, 10],
  },

  phrygian_dominant: {
    phrase     : 'Phrygian Dominant',
    full       : [0, 1, 4, 5, 7, 8, 10],
    pentatonic : [0, 1, 4, 7, 8],
  },

  lydian_sharp_two: {
    phrase     : 'Lydian Sharp Two',
    full       : [0, 3, 4, 6, 7, 9, 11],
    pentatonic : [0, 3, 4, 7, 9],
  },

  ultra_locrian: {
    phrase     : 'Ultra Locrian',
    full       : [0, 1, 3, 4, 6, 8, 9],
    pentatonic : [0, 1, 3, 6, 9],
  },

}

export default COMPOSER_SCALES;
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import './InvestorInviteModal.scss';
import Checkbox from "../../layout/Checkbox";
import { strofeApi } from "../../api/strofeApi";
import { useEffect } from "react";

export default function InvestorInviteModal({ show, onHide, editInvestorInvite, onEdit, onCreate }) {
  const [permalink, setPermalink] = useState('');
  const [title, setTitle] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [errors, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      setErrors(null);
      setSubmitting(false);

      if (editInvestorInvite) {
        setTitle(editInvestorInvite.title);
        setPermalink(editInvestorInvite.identifier);
        setEnabled(editInvestorInvite.enabled);
      }

      else {
        setTitle('');
        setPermalink('');
        setEnabled(true);
      }
    }
  }, [show, editInvestorInvite]);

  const onChangePermalink = e => {
    setPermalink(e.target.value.replace(/[^A-Za-z0-9_-]/g, ''));
  }

  const onSubmit = async e => {
    const submitErrors = [];

    e.preventDefault();

    if (submitErrors.length) {
      setErrors(submitErrors);
      return;
    }
    
    setErrors(null);
    setSubmitting(true);

    try {

      const investorInviteData = {
        title,
        identifier : permalink,
        enabled,
      };

      if (editInvestorInvite) {
        const { data } = await strofeApi.put(`/investor_invites/${editInvestorInvite.id}`, investorInviteData);
        onEdit(data);
      }
      else {
        const { data } = await strofeApi.post('/investor_invites', investorInviteData);
        onCreate(data);
      }
    }

    catch (error) {
      const { errors: requestErrors } = error.response.data;

      // example: { identifier: ['has been taken'] }
      Object.entries(requestErrors).forEach(([key, keyErrors]) => {
        keyErrors.forEach(err => submitErrors.push(`${key}: ${err}`))
      });

      setErrors(submitErrors);
    }

    finally {
      setSubmitting(false);
    }
  }

  return (
    <Modal className='__modal __investor-invite-modal' show={show} backdrop='static'>
      <Modal.Header>
        {editInvestorInvite ? 'Edit Invite' : 'Create Invite' }
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Checkbox checked={enabled} onChange={e => setEnabled(e.target.checked)}>Enabled</Checkbox>

          <Form.Group className='two-column'>
            <Form.Label>Title</Form.Label>
            <Form.Control required value={title} onChange={e => setTitle(e.target.value)} />
          </Form.Group>

          <Form.Group className='two-column'>
            <Form.Label>Identifier</Form.Label>
            <Form.Control required value={permalink} onChange={onChangePermalink} spellCheck={false} maxLength={32} minLength={4} />
          </Form.Group>
          { permalink && <Form.Control className='permalink-preview' readOnly value={`${window.location.origin}/presentation/${permalink}`} /> }

          { errors && (
            <ul className='errors'>
              { errors.map(error => <li>{ error }</li> )}
            </ul>
          )}

        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onHide} disabled={submitting}>Cancel</Button>
          <Button type='submit' disabled={submitting}>{ editInvestorInvite ? 'Edit' : 'Create' }</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

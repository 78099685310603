export default function PopIcon(props) {
  return (
    <svg
      id="__pop-icon-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110.84 111.11"
      {...props}
    >
      <defs>
        <style>
          {
            ".__pop-icon-prefix__cls-2{fill:#fcca75}.__pop-icon-prefix__cls-3{fill:#f18860}.__pop-icon-prefix__cls-4{fill:#ced025}"
          }
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 43"}</title>
      <rect
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
        fill="#a1465b"
      />
      <path
        className="__pop-icon-prefix__cls-2"
        d="M79.36 97.14a7.42 7.42 0 01-9.8-3.7l-5.88-13a.48.48 0 11.88-.4l5.88 13a6.45 6.45 0 1011.75-5.31L74.15 70a7.42 7.42 0 013.7-9.8l.75-.34a7.42 7.42 0 019.8 3.7l2.15 4.75a.48.48 0 11-.88.4l-2.15-4.75A6.45 6.45 0 0079 60.71l-.75.34A6.45 6.45 0 0075 69.57l8 17.77a7.42 7.42 0 01-3.64 9.8z"
      />
      <path
        className="__pop-icon-prefix__cls-3"
        d="M34.69 34.65l24 44.28 4.07-1.85 4.08-1.84-17.36-47.28-7.4 3.35-7.39 3.34zM49.05 24.56l-8.32 3.76-8.32 3.76a1.15 1.15 0 101 2.1l8.32-3.76L50 26.66a1.15 1.15 0 10-1-2.1zM49 23.55a12.32 12.32 0 00-.65-1.81 9.68 9.68 0 10-17.64 8 7.88 7.88 0 001 1.65zM59.19 80l8-3.6s.36 2.32-3.05 3.86-4.95-.26-4.95-.26z"
      />
      <circle
        className="__pop-icon-prefix__cls-4"
        cx={46.81}
        cy={41.94}
        r={2.32}
        transform="rotate(-24.31 46.814 41.929)"
      />
      <circle
        className="__pop-icon-prefix__cls-4"
        cx={49.72}
        cy={48.38}
        r={2.32}
        transform="rotate(-24.31 49.723 48.385)"
      />
      <path
        className="__pop-icon-prefix__cls-2"
        d="M32 28a.72.72 0 01-.94-1l4.19-8.36a.72.72 0 011.29.65l-4.19 8.36A.72.72 0 0132 28zM37.08 26.93a.72.72 0 01-.94-1l3.55-7.09a.72.72 0 111.31.66l-3.57 7.1a.72.72 0 01-.35.33zM42.1 25.15a.72.72 0 01-.94-1l2.2-4.39a.72.72 0 011.29.65l-2.2 4.39a.72.72 0 01-.35.35zM74 32l-2.88 2.7a1.32 1.32 0 00-.29 1.52l1.68 3.58a1.32 1.32 0 01-1.83 1.71l-3.46-1.91a1.32 1.32 0 00-1.54.19l-2.88 2.7a1.32 1.32 0 01-2.2-1.21l.75-3.88a1.32 1.32 0 00-.66-1.4l-3.49-1.86a1.32 1.32 0 01.47-2.46l3.92-.49a1.32 1.32 0 001.13-1.06l.75-3.88a1.32 1.32 0 012.53-.31l1.68 3.58a1.32 1.32 0 001.36.75l3.92-.49A1.32 1.32 0 0174 32zM38.81 64.6l.5 3a1 1 0 00.84.83l3 .44a1 1 0 01.32 1.89l-2.67 1.39a1 1 0 00-.53 1.06l.5 3a1 1 0 01-1.69.89l-2.15-2.11a1 1 0 00-1.17-.17l-2.67 1.39a1 1 0 01-1.37-1.34L33 72.11a1 1 0 00-.2-1.17l-2.1-2.1a1 1 0 01.85-1.71l3 .44a1 1 0 001-.55l1.34-2.7a1 1 0 011.92.28z"
      />
    </svg>
  )
}

import * as React from "react";
const SvgInstrumentDrumsPower = (props) => (
  <svg
    id="InstrumentDrumsPower_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentDrumsPower_svg__st99{fill:#f48864}.InstrumentDrumsPower_svg__st100{fill:#fdc676}.InstrumentDrumsPower_svg__st101{fill:#4b4a9e}.InstrumentDrumsPower_svg__st102{fill:#528459}.InstrumentDrumsPower_svg__st103{fill:#c55a74}.InstrumentDrumsPower_svg__st104{fill:#f8c4b9}"
      }
    </style>

    <path
      className="InstrumentDrumsPower_svg__st99"
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
    />
    <path
      className="InstrumentDrumsPower_svg__st100"
      d="M34.72 71.01s-3.87 1.26-9.82 1.26-9.82-1.26-9.82-1.26V51.38s3.41-.72 9.74-.72 9.89.72 9.89.72l.01 19.63z"
    />
    <path
      className="InstrumentDrumsPower_svg__st101"
      d="M36.08 56.06s-2.57.6-7.61 0c-5.04-.6-6.28-2.17-6.28-2.17L24.36 40s2.38-.8 7.22 0 6.66 2.17 6.66 2.17l-2.16 13.89z"
    />
    <ellipse
      transform="rotate(-70.61 14.099 34.514)"
      className="InstrumentDrumsPower_svg__st100"
      cx={14.1}
      cy={34.51}
      rx={2.43}
      ry={11.23}
    />
    <path
      className="InstrumentDrumsPower_svg__st101"
      d="M13.46 81.76a.47.47 0 0 1-.47-.47v-46.3a.47.47 0 1 1 .94 0v46.3c.01.26-.2.47-.47.47z"
    />
    <path
      className="InstrumentDrumsPower_svg__st101"
      d="m6.02 84.7 7.13-14.12c.12-.24.06-.56-.13-.71l-.02-.03c-.19-.15-.45-.08-.57.16L5.29 84.12c-.12.24-.06.56.13.71l.03.02c.19.16.45.09.57-.15zM20.97 84.7l-7.13-14.12c-.12-.24-.06-.56.13-.71l.03-.02c.19-.15.45-.08.57.16l7.13 14.12c.12.24.06.56-.13.71l-.03.02c-.2.15-.45.08-.57-.16z"
    />
    <path
      className="InstrumentDrumsPower_svg__st102"
      d="m18.45 71.75-5.25 8.02c-.19.31-.18.67.03.8l.03.02c.21.13.54-.01.74-.32l5.35-8.38-.9-.14z"
    />
    <ellipse
      transform="rotate(-17.254 59.143 31.368)"
      className="InstrumentDrumsPower_svg__st100"
      cx={59.13}
      cy={31.36}
      rx={8.36}
      ry={1.32}
    />
    <ellipse
      className="InstrumentDrumsPower_svg__st103"
      cx={8.71}
      cy={48.48}
      rx={8.16}
      ry={1.43}
    />
    <ellipse
      className="InstrumentDrumsPower_svg__st100"
      cx={8.71}
      cy={46.28}
      rx={8.16}
      ry={1.43}
    />
    <path
      className="InstrumentDrumsPower_svg__st102"
      d="M8.88 78.99h-.3c-.2 0-.36-.16-.36-.36v-29.7c0-.2.16-.36.36-.36h.31c.2 0 .36.16.36.36v29.7c-.01.2-.17.36-.37.36z"
    />
    <path
      className="InstrumentDrumsPower_svg__st102"
      d="m2.94 77.86 5.55-9.88c.14-.25.07-.58-.15-.73l-.24-.17c-.21-.16-.51-.08-.65.16L1.9 77.12c-.14.25-.07.58.15.73l.24.17c.22.16.51.09.65-.16zM14.52 77.86l-5.55-9.88c-.14-.25-.07-.58.15-.73l.24-.17c.22-.16.51-.08.65.17l5.55 9.88c.14.25.07.58-.15.73l-.24.17c-.22.15-.51.08-.65-.17z"
    />
    <path
      className="InstrumentDrumsPower_svg__st103"
      d="M60.17 81.21c-.22 0-.4-.18-.4-.4V32.69c0-.22.18-.4.4-.4.22 0 .4.18.4.4v48.12c0 .22-.18.4-.4.4z"
    />
    <path
      className="InstrumentDrumsPower_svg__st103"
      d="m51.81 83.86 8.04-12.75c.14-.22.07-.51-.15-.64l-.03-.02a.458.458 0 0 0-.64.15l-8.04 12.75c-.14.22-.07.51.15.64l.03.02c.22.13.51.07.64-.15zM68.49 83.86l-8.04-12.75a.458.458 0 0 1 .15-.64l.03-.02c.22-.14.51-.07.64.15l8.04 12.75c.14.22.07.51-.15.64l-.03.02c-.22.13-.5.07-.64-.15zM60.93 59.86s-2 1.31-8.12 1.48c-6.12.17-8.59-.51-8.59-.51l-.37-6.49s3.48-1.65 8.46-2.08c4.98-.43 8.25 1.11 8.25 1.11l.37 6.49z"
    />
    <path
      className="InstrumentDrumsPower_svg__st103"
      d="M55.11 77.75c-.24 0-.44-.2-.44-.44V64.45c0-.24.2-.44.44-.44s.44.2.44.44v12.86c0 .25-.19.44-.44.44z"
    />
    <path
      className="InstrumentDrumsPower_svg__st103"
      d="M55.11 64.39c-.22.11-.48.01-.59-.21l-2.05-4.24a.441.441 0 0 1 .21-.59c.22-.11.48-.01.59.21l2.05 4.24c.1.22.01.48-.21.59zM50.28 74.33l4.64-7.37c.12-.18.06-.43-.12-.55l-.2-.13a.403.403 0 0 0-.55.12l-4.64 7.37c-.12.18-.06.43.12.55l.2.13a.4.4 0 0 0 .55-.12zM59.95 74.33l-4.64-7.37a.403.403 0 0 1 .12-.55l.2-.13c.18-.12.43-.06.55.12l4.64 7.37c.12.18.06.43-.12.55l-.2.13a.4.4 0 0 1-.55-.12z"
    />
    <path
      className="InstrumentDrumsPower_svg__st101"
      d="M67.43 55.51s-2.57.6-7.61 0c-5.04-.6-6.28-2.17-6.28-2.17l2.17-13.89s2.38-.8 7.22 0 6.66 2.17 6.66 2.17l-2.16 13.89z"
    />
    <ellipse
      transform="rotate(-17.254 94.584 28.194)"
      className="InstrumentDrumsPower_svg__st100"
      cx={94.57}
      cy={28.19}
      rx={8.36}
      ry={1.32}
    />
    <ellipse
      className="InstrumentDrumsPower_svg__st103"
      cx={91.77}
      cy={41.85}
      rx={6.43}
      ry={1.13}
    />
    <ellipse
      className="InstrumentDrumsPower_svg__st100"
      cx={91.66}
      cy={40.14}
      rx={6.43}
      ry={1.13}
    />
    <ellipse
      transform="rotate(-19.387 98.55 33.859)"
      className="InstrumentDrumsPower_svg__st100"
      cx={98.55}
      cy={33.86}
      rx={11.23}
      ry={2.43}
    />
    <path
      className="InstrumentDrumsPower_svg__st101"
      d="M98.84 82c.26 0 .47-.21.47-.47v-46.3a.47.47 0 1 0-.94 0v46.3c-.01.26.21.47.47.47z"
    />
    <path
      className="InstrumentDrumsPower_svg__st101"
      d="m106.28 84.94-7.13-14.12c-.12-.24-.06-.56.13-.71l.03-.02c.19-.15.45-.08.57.16l7.13 14.12c.12.24.06.56-.13.71l-.03.02c-.19.15-.45.08-.57-.16zM91.33 84.94l7.13-14.12c.12-.24.06-.56-.13-.71l-.03-.02c-.19-.15-.45-.08-.57.16L90.6 84.37c-.12.24-.06.56.13.71l.03.02c.2.15.45.08.57-.16z"
    />
    <path
      className="InstrumentDrumsPower_svg__st99"
      d="m79.2 79.75-3.75 5.95c-.14.22-.07.51.15.64l.03.02c.22.14.51.07.64-.15l3.52-5.59-.59-.87z"
    />
    <path
      className="InstrumentDrumsPower_svg__st102"
      d="M91.91 65.9h-.24a.28.28 0 0 1-.28-.28v-23.4c0-.16.13-.28.28-.28h.24c.16 0 .28.13.28.28v23.41c0 .15-.13.27-.28.27z"
    />
    <path
      className="InstrumentDrumsPower_svg__st102"
      d="m87.22 65.01 4.38-7.79c.11-.2.06-.45-.12-.58l-.19-.14c-.17-.12-.4-.06-.51.13l-4.38 7.79c-.11.2-.06.45.12.58l.19.14c.17.12.4.07.51-.13zM96.35 65.01l-4.38-7.79c-.11-.2-.06-.45.12-.58l.19-.14c.17-.12.4-.06.51.13l4.38 7.79c.11.2.06.45-.12.58l-.19.14c-.17.12-.4.07-.51-.13z"
    />
    <path
      className="InstrumentDrumsPower_svg__st101"
      d="M94.59 78.04c-.22 0-.4-.18-.4-.4V29.51c0-.22.18-.4.4-.4.22 0 .4.18.4.4v48.12c0 .23-.18.41-.4.41z"
    />
    <path
      className="InstrumentDrumsPower_svg__st101"
      d="m86.24 80.69 8.04-12.75c.14-.22.07-.51-.15-.64l-.03-.02a.458.458 0 0 0-.64.15l-8.04 12.75c-.14.22-.07.51.15.64l.03.02c.21.13.5.06.64-.15zM102.91 80.69l-8.04-12.75a.458.458 0 0 1 .15-.64l.03-.02c.22-.14.51-.07.64.15l8.04 12.75c.14.22.07.51-.15.64l-.03.02a.48.48 0 0 1-.64-.15z"
    />
    <path
      className="InstrumentDrumsPower_svg__st103"
      d="M95.7 58.33s-1.81 1.57-7.85 2.55c-6.04.98-8.58.64-8.58.64l-1.24-6.38s3.23-2.1 8.11-3.19c4.88-1.09 8.32 0 8.32 0l1.24 6.38zM89.99 78.82c-.24 0-.44-.2-.44-.44V65.52c0-.24.2-.44.44-.44s.44.2.44.44v12.86c.01.24-.19.44-.44.44z"
    />
    <path
      className="InstrumentDrumsPower_svg__st103"
      d="M89.99 65.45c-.22.11-.48.01-.59-.21L87.35 61a.441.441 0 0 1 .21-.59c.22-.11.48-.01.59.21l2.05 4.24c.11.22.01.48-.21.59zM85.16 75.39l4.64-7.37c.12-.18.06-.43-.12-.55l-.2-.13a.403.403 0 0 0-.55.12l-4.64 7.37c-.12.18-.06.43.12.55l.2.13c.19.12.43.07.55-.12zM94.84 75.39l-4.64-7.37a.403.403 0 0 1 .12-.55l.2-.13c.18-.12.43-.06.55.12l4.64 7.37c.12.18.06.43-.12.55l-.2.13a.39.39 0 0 1-.55-.12z"
    />
    <path
      className="InstrumentDrumsPower_svg__st102"
      d="M86.05 53.33s-2.29 1.32-7.29 2.2-6.63-.26-6.63-.26l-1.95-13.92s2.05-1.46 6.91-2.09c4.86-.64 7 .14 7 .14l1.96 13.93zM51.56 80.64l3.75 5.95c.14.22.07.51-.15.64l-.03.02c-.22.14-.51.07-.64-.15l-3.52-5.59.59-.87zM24.49 79.94l-3.75 5.95c-.14.22-.07.51.15.64l.03.02c.22.14.51.07.64-.15l3.52-5.59-.59-.87z"
    />
    <circle
      className="InstrumentDrumsPower_svg__st102"
      cx={38.25}
      cy={72.23}
      r={15.75}
    />
    <circle
      className="InstrumentDrumsPower_svg__st104"
      cx={38.25}
      cy={72.23}
      r={13.9}
    />
    <path
      className="InstrumentDrumsPower_svg__st102"
      d="m56.73 80.45-3.75 5.95c-.14.22-.07.51.15.64l.03.02c.22.14.51.07.64-.15l3.52-5.59-.59-.87zM82.91 80.09l3.75 5.95c.14.22.07.51-.15.64l-.03.02c-.22.14-.51.07-.64-.15l-3.52-5.59.59-.87z"
    />
    <circle
      className="InstrumentDrumsPower_svg__st102"
      cx={69.6}
      cy={71.68}
      r={15.75}
    />
    <circle
      className="InstrumentDrumsPower_svg__st104"
      cx={69.6}
      cy={71.68}
      r={13.9}
    />
    <path
      className="InstrumentDrumsPower_svg__st102"
      d="M54.7 53.88s-2.29 1.32-7.29 2.2c-5 .88-6.63-.26-6.63-.26L38.83 41.9s2.05-1.46 6.91-2.09c4.86-.64 7 .14 7 .14l1.96 13.93z"
    />
  </svg>
);
export default SvgInstrumentDrumsPower;


import * as React from "react";

const InstrumentOverdrivenGuitar = (props) => (
  <svg
    id="InstrumentOverdrivenGuitar_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.58"
    style={{
      enableBackground: "new 0 0 110.84 110.58",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentOverdrivenGuitar_svg__st3{fill:#4e4092}.InstrumentOverdrivenGuitar_svg__st5{fill:#cfd125}"
      }
    </style>
    
    <path
      d="M94.17 0h-77.5C7.46 0 0 7.46 0 16.67v77.49c0 9.21 7.46 16.67 16.67 16.67h77.49c9.21 0 16.67-7.46 16.67-16.67V16.67C110.84 7.46 103.38 0 94.17 0z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      className="InstrumentOverdrivenGuitar_svg__st3"
      d="m52.45 59.9-2.08-.07a.26.26 0 0 0-.15.04L4.95 90.94c-.24.16-.07.53.21.45 5.29-1.63 22.36-6.31 33.06-2.67.05.02.08.04.11.08.62.87 8.32 11.67 9.47 14.37.05.11.19.1.22-.01 1.05-3.8 9.92-36.04 10.34-37.74.01-.05.01-.09-.01-.14l-.57-1.68a.38.38 0 0 0-.09-.13l-5.1-3.53a.326.326 0 0 0-.14-.04z"
    />
    <path
      d="M82.18 19.74 38.04 78.42c-.08.11-.06.27.05.35l6.3 4.72c.12.09.28.06.36-.06l40.92-61.21c.08-.11.05-.27-.06-.34l-3.08-2.19a.243.243 0 0 0-.35.05z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      className="InstrumentOverdrivenGuitar_svg__st3"
      d="M82.5 19.35c.31-.4.9-1.4.53-3.06-.14-.63.27-1.25.9-1.4l13.8-3.34c.05-.01.1-.01.15.01l2.94 1.13c.21.08.22.37.02.46-2.49 1.07-12.49 5.51-14.89 8.66a.24.24 0 0 1-.34.05l-3.04-2.16c-.13-.08-.16-.24-.07-.35z"
    />
    <path
      className="InstrumentOverdrivenGuitar_svg__st5"
      d="m42.5 89.42 11.53-17.01c.25-.37.84-.1.7.34l-7.59 24.63c-.16.52-.86.59-1.13.12l-3.59-6.43c-.28-.51-.25-1.15.08-1.65zM44.13 85.11l-.61.79a.62.62 0 0 1-.87.11l-6.23-4.83a.62.62 0 0 1-.11-.87l.61-.79c.21-.27.6-.32.87-.11l6.23 4.83c.27.21.32.6.11.87z"
    />
    <circle
      className="InstrumentOverdrivenGuitar_svg__st3"
      cx={84.06}
      cy={13.05}
      r={0.85}
    />
    <circle
      className="InstrumentOverdrivenGuitar_svg__st3"
      cx={87.85}
      cy={12.16}
      r={0.85}
    />
    <circle
      className="InstrumentOverdrivenGuitar_svg__st3"
      cx={91.63}
      cy={11.27}
      r={0.85}
    />
    <circle
      className="InstrumentOverdrivenGuitar_svg__st3"
      cx={95.42}
      cy={10.38}
      r={0.85}
    />
  </svg>
);

export default InstrumentOverdrivenGuitar;

// Instrument MIDI programs
// ------------------------
// - Some instruments have been renamed from MIDI. Their original name is in the // -> part
// - note that 1-128 means they are id'd 0 to 127.
// - Drums uses id=0 (same as grand piano):

export const INSTRUMENT_PROGRAMS = [
  //Piano:
  /* 1 */         "Grand Piano",
  /* 2 */         "Acoustic Brite Piano", // -> "Bright Acoustic Piano",
  /* 3 */         "Electric Grand Piano",
  /* 4 */         "Honky-tonk Piano",
  /* 5 */         "Electric Piano 1",
  /* 6 */         "Electric Piano 2",
  /* 7 */         "Harpsichord",
  /* 8 */         "Clavinet",

  //Chromatic Percussion:
  /* 9 */         "Celesta",
  /* 10 */        "Glockenspiel",
  /* 11 */        "Music Box",
  /* 12 */        "Vibraphone",
  /* 13 */        "Marimba",
  /* 14 */        "Xylophone",
  /* 15 */        "Bells",
  /* 16 */        "Dulcimer",

  //Organ:
  /* 17 */        "Hammond Organ",
  /* 18 */        "Percussive Organ",
  /* 19 */        "Rock Organ",
  /* 20 */        "Church Organ",
  /* 21 */        "Reed Organ",
  /* 22 */        "Accordion",
  /* 23 */        "Harmonica",
  /* 24 */        "Tango Accordion",

  //Guitar:
  /* 25 */        "Acoustic Guitar",
  /* 26 */        "Steel Guitar",
  /* 27 */        "Jazz Guitar",
  /* 28 */        "Clean Electric Guitar",
  /* 29 */        "Electric Guitar (muted)",
  /* 30 */        "Overdriven Guitar",
  /* 31 */        "Distortion Guitar",
  /* 32 */        "Guitar harmonics",

  //Bass:
  /* 33 */        "Upright Bass",
  /* 34 */        "Finger Bass",
  /* 35 */        "Pick Bass",
  /* 36 */        "Fretless Bass",
  /* 37 */        "Slap Bass 1",
  /* 38 */        "Slap Bass 2",
  /* 39 */        "Synth Bass 1",
  /* 40 */        "Synth Bass 2",

  //Strings:
  /* 41 */        "Violin",
  /* 42 */        "Viola",
  /* 43 */        "Cello",
  /* 44 */        "Contrabass",
  /* 45 */        "Tremolo Strings",
  /* 46 */        "Pizzicato Strings",
  /* 47 */        "Harp",
  /* 48 */        "Timpani",

  //Strings (continued):
  /* 49 */        "String Ensemble Marcato",
  /* 50 */        "String Ensemble 2",
  /* 51 */        "Synth Strings 1",
  /* 52 */        "Synth Strings 2",
  /* 53 */        "Choir Aahs",
  /* 54 */        "Voices Ooohs",
  /* 55 */        "Synth Voice",
  /* 56 */        "Orchestra Hit",

  //Brass:
  /* 57 */        "Trumpet",
  /* 58 */        "Trombone",
  /* 59 */        "Tuba",
  /* 60 */        "Muted Trumpet",
  /* 61 */        "French Horn",
  /* 62 */        "Brass Section",
  /* 63 */        "Synth Brass 1",
  /* 64 */        "Synth Brass 2",

  //Reed:
  /* 65 */        "Soprano Sax",
  /* 66 */        "Alto Sax",
  /* 67 */        "Tenor Sax",
  /* 68 */        "Baritone Sax",
  /* 69 */        "Oboe",
  /* 70 */        "English Horn",
  /* 71 */        "Bassoon",
  /* 72 */        "Clarinet",

  //Pipe:
  /* 73 */        "Piccolo",
  /* 74 */        "Flute",
  /* 75 */        "Recorder",
  /* 76 */        "Pan Flute",
  /* 77 */        "Bottle Blows",
  /* 78 */        "Shakuhachi",
  /* 79 */        "Whistle",
  /* 80 */        "Ocarina",

  //Synth Lead:
  /* 81 */        "Square Wave", // -> "Lead 1 (square)",
  /* 82 */        "Lead 2 (sawtooth)",
  /* 83 */        "Lead 3 (calliope)",
  /* 84 */        "Lead 4 (chiff)",
  /* 85 */        "Charang",
  /* 86 */        "Lead 6 (voice)",
  /* 87 */        "Lead 7 (fifths)",
  /* 88 */        "Lead 8 (bass + lead)",

  //Synth Pad:
  /* 89 */        "New Age", // -> "Pad 1 (new age)",
  /* 90 */        "Pad 2 (warm)",
  /* 91 */        "Pad 3 (polysynth)",
  /* 92 */        "Pad 4 (choir)",
  /* 93 */        "Pad 5 (bowed)",
  /* 94 */        "Pad 6 (metallic)",
  /* 95 */        "Halo Pad",
  /* 96 */        "Sweep Pad",

  //Synth Effects:
  /* 97 */        "FX 1 (rain)",
  /* 98 */        "FX 2 (soundtrack)",
  /* 99 */        "Crystal", // -> "FX 3 (crystal)",
  /* 100 */       "FX 4 (atmosphere)",
  /* 101 */       "FX 5 (brightness)",
  /* 102 */       "Unicorn", // -> "FX 6 (goblins)",
  /* 103 */       "Echoes", // -> "FX 7 (echoes)",
  /* 104 */       "FX 8 (sci-fi)",

  //Ethnic:
  /* 105 */       "Sitar",
  /* 106 */       "Banjo",
  /* 107 */       "Shamisen",
  /* 108 */       "Koto",
  /* 109 */       "Kalimba",
  /* 110 */       "Bag pipe",
  /* 111 */       "Fiddle",
  /* 112 */       "Shanai",

  //Percussive:
  /* 113 */       "Tinkle Bell",
  /* 114 */       "Agogo",
  /* 115 */       "Steel Drums",
  /* 116 */       "Wood Block",
  /* 117 */       "Taiko Drum",
  /* 118 */       "Melodic Tom",
  /* 119 */       "Synth Drum",

  //Sound effects:
  /* 120 */       "Reverse Cymbal",
  /* 121 */       "Guitar Frets",
  /* 122 */       "Breath Noise",
  /* 123 */       "Seashore",
  /* 124 */       "Bird Tweet",
  /* 125 */       "Telephone Ring",
  /* 126 */       "Helicopter",
  /* 127 */       "Applause",
  /* 128 */       "Gunshot",

  //VSTs:
  /* 129 */       "Cursed Strings",
  /* 130 */       "Tight Bass",
  /* 131 */       "Funk Guitar",
  /* 132 */       "VST",
  /* 133 */       "VST",
  /* 134 */       "VST",
  /* 135 */       "VST",
  /* 136 */       "VST",
  /* 137 */       "VST",
  /* 138 */       "VST",
];

export const FLUID_PROGRAMS = {
  0: "ACOUSTIC_GRAND_PIANO",
  1: "ACOUSTIC_BRITE_PIANO",
  2: "ELECTRIC_GRAND_PIANO",
  4: "ELECTRIC_PIANO_1_RHODES",
  5: "ELECTRIC_PIANO_2_CHORUSED_YAMAHA_DX",
  6: "HARPSICHORD",
  7: "CLAVINET",
  8: "CELESTA",
  9: "GLOCKENSPIEL",
  13: "XYLOPHONE",
  14: "BELLS",
  15: "DULCIMER",
  16: "HAMMOND_ORGAN",
  19: "CHURCH_ORGAN",
  21: "ACCORDION",
  23: "TANGO_ACCORDION",
  24: "NYLON_GUITAR",
  25: "STEEL_GUITAR",
  26: "JAZZ_GUITAR",
  27: "CLEAN_ELECTRIC_GUITAR",
  28: "MUTED_ELECTRIC_GUITAR",
  29: "OVERDRIVEN_GUITAR",
  30: "DISTORTION_GUITAR",
  32: "ACOUSTIC_BASS",
  33: "FINGER_BASS",
  34: "PICK_BASS",
  35: "FRETLESS_BASS",
  36: "SLAP_BASS_1",
  37: "SLAP_BASS_2",
  38: "SYNTH_BASS_1",
  40: "VIOLIN",
  42: "CELLO",
  44: "TREMOLO_STRINGS",
  45: "PIZZICATO_STRINGS",
  46: "HARP",
  47: "TIMPANI",
  48: "STRING_ENSEMBLE_1_MARCATO",
  53: "VOICE_OOHS",
  56: "TRUMPET",
  57: "TROMBONE",
  58: "TUBA",
  59: "MUTED_TRUMPET",
  60: "FRENCH_HORN",
  61: "BRASS_SECTION",
  64: "SOPRANO_SAX",
  65: "ALTO_SAX",
  66: "TENOR_SAX",
  67: "BARITONE_SAX",
  68: "OBOE",
  69: "ENGLISH_HORN",
  70: "BASSOON",
  71: "CLARINET",
  72: "PICCOLO",
  73: "FLUTE",
  74: "RECORDER",
  75: "PAN_FLUTE",
  76: "BOTTLE_BLOW",
  79: "OCARINA",
  80: "SQUARE_WAVE",
  84: "CHARANG",
  88: "NEW_AGE",
  94: "HALO_PAD",
  95: "SWEEP_PAD",
  98: "CRYSTAL",
  101: "UNICORN",
  102: "ECHO_VOICE",
  104: "SITAR",
  114: "STEEL_DRUMS",
  115: "WOOD_BLOCK",
  120: "GUITAR_FRETSE",
  122: "SEASHORE",
  125: "HELICOPTER",
  128: "CURSED_STRINGS",
  129: "TIGHT_BASS",
  130: "FUNK_GUITAR",
}

export const DRUM_PROGRAMS = {
  0:  "Drums 1",
  1:  "Drums 2",
  8:  "Room Drums",
  16: "Power Drums",
  24: "Electronic Drums",
  25: "Drums 808",
  26: "Dance Drums",
  32: "Jazz Drums",
  40: "Brush Drums",
  48: "Orchestral Percussion",
};

export const PROGRAM_MAX_INDEX = 129;

// I M P O R T A N T
// =================
// To add an instrument, first add it to the array. Create an icon using the name "Instrument[NameCamelCase].js", and add it to src/instruments/index.js
// The INSTRUMENT_PROGRAMS name has to be exactly same (spaces are ignored), then check if it exists on FLUID_PROGRAMS since only those are used:
// after that, run checkMissingInstruments() on the bottom of this file to debug if the instrument was inserted correctly.
export const INSTRUMENT_CATEGORIES = {
  keys: {
    phrase: "Keys",
    instruments: ['Grand Piano', 'Acoustic Brite Piano', 'Electric Grand Piano', 'Church Organ', 'Hammond Organ', 'Electric Piano 1', 'Electric Piano 2', 'Harpsichord', 'Clavinet', 'Accordion', 'Tango Accordion']
  },

  winds: {
    phrase: "Winds",
    instruments: ['Ocarina', 'French Horn', 'Bassoon', 'Flute', 'Pan Flute', 'Oboe', 'Recorder', 'Piccolo', 'English Horn', 'Clarinet', 'Bottle Blows'],
  },

  brass: {
    phrase: "Brass",
    instruments: ['Trumpet', 'Trombone', 'Tuba', 'Muted Trumpet', 'Brass Section', 'Soprano Sax', 'Alto Sax', 'Tenor Sax', 'Baritone Sax'],
  },

  strings: {
    phrase: "Strings",
    instruments: ['Violin', 'Cello', 'Tremolo Strings', 'Pizzicato Strings', 'Harp', 'String Ensemble Marcato', 'Cursed Strings', 'Sitar'],
  },

  bass: {
    phrase: "Bass",
    instruments: ['Upright Bass', 'Tight Bass', 'Finger Bass', 'Pick Bass', 'Slap Bass 1', 'Slap Bass 2', 'Fretless Bass', 'Synth Bass 1'],
  },

  // 'Nylon Guitar' is acoustic in fluid
  guitars: {
    phrase: "Guitars",
    instruments: ['Acoustic Guitar', 'Clean Electric Guitar', 'Distortion Guitar', 'Funk Guitar', 'Guitar Frets', 'Jazz Guitar', 'Overdriven Guitar', 'Steel Guitar'],
  },

  sfx: {
    phrase: "SFX",
    instruments: ['Crystal', 'Echoes', 'Unicorn', 'Square Wave', 'New Age', 'Voices Ooohs', 'Sweep Pad', 'Halo Pad', 'Charang'],
  },

  percussion: {
    phrase: "Percussion",
    instruments: ['Steel Drums', 'Glockenspiel', 'Bells', 'Wood Block', 'Timpani', 'Xylophone', 'Dulcimer', 'Celesta']
  }
}

export function availablePrograms(drums) {
  if (drums) {
    return Object.keys(DRUM_PROGRAMS).map(key => ({ number: key, name: DRUM_PROGRAMS[key] }));
  }
  return Object.keys(FLUID_PROGRAMS).map(key => ({ number: key, name: INSTRUMENT_PROGRAMS[key] }));
}

// function checkMissingInstruments() {
//   console.log('Check missing instruments...');

//   const fluid = JSON.parse(JSON.stringify(FLUID_PROGRAMS))

//   Object.values(INSTRUMENT_CATEGORIES).forEach(category => category.instruments.forEach(i => {
//     if (!INSTRUMENT_PROGRAMS.includes(i)) {
//       console.log('name not found:', i)
//     }

//     delete fluid[INSTRUMENT_PROGRAMS.findIndex(instrument => instrument === i)];
//   }));
  
//   console.log('fluid:', fluid)
// }

// run this to check missing instruments (hellicopter and seashore are not implemented)
// checkMissingInstruments()
import * as React from "react";

const InstrumentSlapBass1 = (props) => (
  <svg
    id="InstrumentSlapBass_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentSlapBass_svg__st3{fill:#4e4092}.InstrumentSlapBass_svg__st4{fill:#f18860}.InstrumentSlapBass_svg__st5{fill:#cfd125}"
      }
    </style>
    
    <path
      className="InstrumentSlapBass_svg__st3"
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.49c9.21 0 16.67 7.46 16.67 16.67v77.49c.01 9.22-7.45 16.68-16.66 16.68z"
    />
    <path
      className="InstrumentSlapBass_svg__st5"
      d="M47.21 58.43s-3.73 3.67-5.26 1.39c-2.11-3.14 5.4-6.87 3.84-10.17-.49-1.05-1.66-1.67-2.81-1.71-3.77-.13-6.42 3.7-7.95 6.67-1.82 3.54-3.81 7.04-6.6 9.92-2.83 2.92-6.72 3.93-10.11 6-2.42 1.48-5.45 3.3-6.74 5.95-2.49 5.13-2.84 10.4 2.84 17.4s11.19 11.9 15.4 10.53c4.21-1.37 6.6-5.77 7.04-7.1 1.29-3.89 1.63-8.51 4.57-11.61 1.89-2 4.53-2.92 6.69-4.56 2.64-2 5.77-5.72 6.09-9.18.27-2.95-2.46-3-4.13-1.64-1.16.94-2.76 3.12-4.35 1.68-1.6-1.44-.81-2.39-.81-2.39l2.29-11.18z"
    />
    <path
      className="InstrumentSlapBass_svg__st4"
      d="m35.94 85.1 2.56 2.07s-1.01 2.15-1.89 4.58c-1.29 3.56-1.14 6.83-3.54 8.17-1.4.49-2-1.23-1.39-5.75.61-4.52.63-7.91.63-7.91s3.15-.85 3.63-1.16z"
    />
    <path
      className="InstrumentSlapBass_svg__st3"
      d="m35.53 82.32-6.12-5.53a.556.556 0 0 1-.04-.79l.69-.77c.21-.23.56-.25.79-.04l6.12 5.53c.23.21.25.56.04.79l-.69.77c-.2.23-.56.25-.79.04zM29.16 89.38l-6.12-5.53a.556.556 0 0 1-.04-.79l.69-.77c.21-.23.56-.25.79-.04l6.12 5.53c.23.21.25.56.04.79l-.69.77c-.21.23-.56.24-.79.04z"
    />
    <path
      className="InstrumentSlapBass_svg__st4"
      d="m42.6 72.2-4-3.61a.695.695 0 0 1-.03-1.01L81.9 22.76c.26-.27.7-.29.98-.03l1.6 1.45c.28.26.31.7.06.98L43.61 72.14c-.26.29-.71.32-1.01.06z"
    />
    <path
      className="InstrumentSlapBass_svg__st5"
      d="m82.75 22.61 1.55 1.4c.22.2.55.2.77 0 .6-.55 1.96-1.42 3.94-.58.59.25 2.04-1.72 2.23-2.05.82-1.4 1.41-2.92 2.03-4.41.32-.77 1.11-2.67 2.24-2.5 2.09.31 4.07-.46 4.3-2.79.28-2.8-2.66-4.29-4.93-2.82-1.07.69-13.52 9.73-13.65 10.03-.12.27 1.22 2.61 1.33 3.38.03.13.09.25.19.34z"
    />
    <ellipse
      transform="rotate(-43.937 35.955 87.59)"
      className="InstrumentSlapBass_svg__st3"
      cx={35.96}
      cy={87.59}
      rx={0.79}
      ry={0.81}
    />
    <ellipse
      transform="rotate(-43.937 35.16 91.075)"
      className="InstrumentSlapBass_svg__st3"
      cx={35.16}
      cy={91.08}
      rx={0.79}
      ry={0.81}
    />
    <ellipse
      transform="rotate(-43.937 34.5 94.471)"
      className="InstrumentSlapBass_svg__st3"
      cx={34.5}
      cy={94.48}
      rx={0.79}
      ry={0.81}
    />
    <ellipse
      transform="rotate(-43.937 33.223 97.367)"
      className="InstrumentSlapBass_svg__st3"
      cx={33.22}
      cy={97.37}
      rx={0.79}
      ry={0.81}
    />
    <circle
      className="InstrumentSlapBass_svg__st5"
      cx={92.23}
      cy={7.31}
      r={1.19}
    />
    <circle
      className="InstrumentSlapBass_svg__st5"
      cx={83.88}
      cy={12.91}
      r={1.19}
    />
    <circle
      className="InstrumentSlapBass_svg__st5"
      cx={87.86}
      cy={9.94}
      r={1.71}
    />
  </svg>
);

export default InstrumentSlapBass1;

import Dropdown from 'react-bootstrap/Dropdown';
import HiddenIcon from "../../icons/HiddenIcon";
import VisibleIcon from "../../icons/VisibleIcon";

import './PermissionsDropdown.scss';

const PERMISSIONS = {
  private: {
    icon        : HiddenIcon,
    iconClass   : 'hidden',
    label       : "Private",
    description : <span>Only you have access <br /> to this track.</span>,
  },

  public: {
    icon        : VisibleIcon,
    label       : "Public",
    description : <span>Anyone with the link <br /> can listen to this track.</span>,
  }
}

export default function PermissionsDropdown({ trackPermission, onChange }) {

  const permissionIcon = (item, addClass = false) => {
    const Icon = item.icon;
    return <Icon width={20} height={20} className={addClass ? item.iconClass : undefined} />;
  }

  const renderPermission = permission => {
    const item = PERMISSIONS[permission];

    return (
      <>
        { permissionIcon(item, true) }
        <span className='label'>{ item.label }</span>
      </>
    );
  }

  return (
    <Dropdown className='__permissions-dropdown'>
      <Dropdown.Toggle variant='link'>
        { renderPermission(trackPermission) }
      </Dropdown.Toggle>

      <Dropdown.Menu align='right'>
        { Object.entries(PERMISSIONS).map(([permission, item]) => (
          <Dropdown.Item onClick={() => onChange(permission)} active={permission === trackPermission} key={permission}>
            <div className='item-value'>
              { permissionIcon(item) }
              { item.label }
            </div>
            <div className='item-description'>{ item.description }</div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
import * as React from "react";
const SvgInstrumentDrumsJazz = (props) => (
  <svg
    id="InstrumentDrumsJazz_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentDrumsJazz_svg__st101{fill:#4b4a9e}.InstrumentDrumsJazz_svg__st102{fill:#528459}.InstrumentDrumsJazz_svg__st103{fill:#c55a74}.InstrumentDrumsJazz_svg__st105{fill:#c6cf3e}"
      }
    </style>

    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#fdc676",
      }}
    />
    <path
      className="InstrumentDrumsJazz_svg__st102"
      d="M77.79 72.54h.25c.24 0 .43-.19.43-.43V31.99c0-.24-.19-.43-.43-.43h-.25c-.24 0-.43.19-.43.43v40.12c-.01.24.19.43.43.43z"
    />
    <path
      className="InstrumentDrumsJazz_svg__st102"
      d="M84.2 71.01 78.16 57.7c-.15-.33-.08-.78.16-.99l.26-.23c.24-.21.56-.11.71.22l6.04 13.31c.15.33.08.78-.16.99l-.26.23c-.24.22-.56.12-.71-.22zM71.61 71.01l6.04-13.31c.15-.33.08-.78-.16-.99l-.26-.23c-.24-.21-.56-.11-.71.22l-6.04 13.31c-.15.33-.08.78.16.99l.26.23c.25.22.56.12.71-.22z"
    />
    <ellipse
      className="InstrumentDrumsJazz_svg__st102"
      cx={77.94}
      cy={29.03}
      rx={14.38}
      ry={2.52}
    />
    <ellipse
      className="InstrumentDrumsJazz_svg__st103"
      cx={78.19}
      cy={25.21}
      rx={14.38}
      ry={2.52}
    />
    <path
      className="InstrumentDrumsJazz_svg__st101"
      d="M30.79 53.13c7.93 0 14.36-2.48 14.36-5.54v23.03s-1.95 6.06-15.37 6.06-13.35-6.06-13.35-6.06V47.59c0 3.06 6.43 5.54 14.36 5.54z"
    />
    <ellipse
      className="InstrumentDrumsJazz_svg__st105"
      cx={30.79}
      cy={47.59}
      rx={14.36}
      ry={5.54}
    />
    <path
      className="InstrumentDrumsJazz_svg__st101"
      d="m92.15 79.11 2.29.22c-.78 0-1.55-.08-2.29-.22z"
    />
    <path
      className="InstrumentDrumsJazz_svg__st102"
      d="M69.63 53.61c7.85 0 14.21-1.81 14.21-4.05v11.43s-1.45 4.74-14.73 4.74-13.69-4.74-13.69-4.74V49.56c0 2.24 6.36 4.05 14.21 4.05z"
    />
    <ellipse
      className="InstrumentDrumsJazz_svg__st105"
      cx={69.63}
      cy={49.56}
      rx={14.21}
      ry={4.05}
    />
    <path
      className="InstrumentDrumsJazz_svg__st103"
      d="M69.26 41.11c5.95-1.27 10.37-4.18 9.87-6.49l2.53 11.82s-.05 5.14-10.11 7.29-11.43-2.68-11.43-2.68L57.6 39.23c.49 2.31 5.72 3.15 11.66 1.88z"
    />
    <ellipse
      transform="rotate(-12.082 68.37 36.922)"
      className="InstrumentDrumsJazz_svg__st101"
      cx={68.37}
      cy={36.92}
      rx={11.01}
      ry={4.28}
    />
    <ellipse
      transform="rotate(-17.254 86.483 12.777)"
      className="InstrumentDrumsJazz_svg__st102"
      cx={86.47}
      cy={12.78}
      rx={11.64}
      ry={1.83}
    />
    <path
      className="InstrumentDrumsJazz_svg__st102"
      d="M86.39 80.93a.56.56 0 0 1-.56-.56V13.34a.56.56 0 1 1 1.12 0v67.03c0 .31-.25.56-.56.56z"
    />
    <path
      className="InstrumentDrumsJazz_svg__st102"
      d="m74.75 84.62 11.2-17.76c.19-.3.1-.7-.2-.9l-.05-.03a.66.66 0 0 0-.9.2L73.6 83.9c-.19.3-.1.7.2.9l.05.03c.31.18.71.09.9-.21zM97.98 84.62l-11.2-17.76c-.19-.3-.1-.7.2-.9l.05-.03c.3-.19.7-.1.9.2l11.2 17.76c.19.3.1.7-.2.9l-.05.03c-.31.19-.71.1-.9-.2z"
    />
    <path
      className="InstrumentDrumsJazz_svg__st103"
      d="m42.3 59.36 19.15-2.13c-6.81 0-12.32 7.7-12.32 17.2 0 8.58 4.51 15.7 10.4 16.99L41.94 89.5s-8.06-2.13-8.06-15.03 8.42-15.11 8.42-15.11z"
    />
    <path
      className="InstrumentDrumsJazz_svg__st101"
      d="M61.45 57.23c7.68 0 13.91 7.7 13.91 17.2s-6.23 17.2-13.91 17.2l-2.17-.21c-6.65-1.29-11.73-8.41-11.73-16.99-.01-9.5 6.22-17.2 13.9-17.2z"
    />
    <ellipse
      cx={61.45}
      cy={74.43}
      rx={12.18}
      ry={15.07}
      style={{
        fill: "#f8c4b9",
      }}
    />
    <path
      className="InstrumentDrumsJazz_svg__st101"
      d="m45.77 85.39-4.57 7.24c-.17.27-.09.62.18.78l.04.03c.27.17.62.09.78-.18l4.29-6.81-.72-1.06zM71.29 86.58l3.28 5.2c.17.27.09.62-.18.78l-.04.03c-.27.17-.62.09-.78-.18l-3.09-4.9.81-.93z"
    />
    <ellipse
      transform="rotate(-70.61 30.795 36.975)"
      className="InstrumentDrumsJazz_svg__st103"
      cx={30.79}
      cy={36.98}
      rx={4.69}
      ry={21.72}
    />
    <path
      className="InstrumentDrumsJazz_svg__st103"
      d="M30.05 92.05c-.31 0-.55-.25-.55-.55V37.53c0-.31.25-.55.55-.55.31 0 .55.25.55.55v53.96c.01.31-.24.56-.55.56z"
    />
    <path
      className="InstrumentDrumsJazz_svg__st103"
      d="m21.38 95.47 8.31-16.46c.14-.28.07-.65-.15-.83l-.04-.03c-.23-.18-.52-.09-.66.19L20.53 94.8c-.14.28-.07.65.15.83l.04.03c.22.17.52.09.66-.19zM38.8 95.47l-8.31-16.46c-.14-.28-.07-.65.15-.83l.04-.03c.23-.18.52-.09.66.19l8.31 16.46c.14.28.07.65-.15.83l-.04.03c-.22.17-.52.09-.66-.19z"
    />
  </svg>
);
export default SvgInstrumentDrumsJazz;


import classNames from 'classnames';
import * as Instruments from '../../icons/instruments/';

export default function InstrumentIcon({ id, name, onClick, selected, showName = true, ...props }) {
  
  const renderIcon = name => {
    const iconName = 'Instrument' + name.replaceAll(' ', '');
    const Icon = Instruments[iconName];

    if (!Icon) {
      console.error(name + ': does not exist');
      return null;
    }

    return <Icon />
  }

  return (
    <div id={id} className={classNames('instrument-icon', { selected: selected === name })} role={onClick ? 'button' : null} onClick={e => onClick?.(e, name) } {...props}>
      { renderIcon(name) }
      {/* <Translate>{ name }</Translate> */}
      { showName && name }
    </div>

  )
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { usersSelectors } from '../../store/usersSlice';
import { DRUM_PROGRAMS, INSTRUMENT_CATEGORIES, PROGRAM_MAX_INDEX } from '../../utils/InstrumentPrograms';
import { Trans as Translate, useTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import InstrumentCategory from './InstrumentCategory';

import InstrumentIcon from './InstrumentIcon';
import './InstrumentSelectorModal.scss';

export default function InstrumentSelectorModal({ show, onSubmit, drums = false, onHide, initialSelected }) {

  const [selected, setSelected] = useState(null);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [searchItems, setSearchItems] = useState(null); // null is no search, empty array [] means no items found
  const [vstIndex, setVstIndex] = useState(0);

  const currentUser = useSelector(usersSelectors.getCurrentUser);

  useEffect(() => {
    if (show) {
      setSearchItems(null);
      setSearchText('');
      setSelected(initialSelected);

      if (initialSelected) {
        document.getElementById('__instrument-modal-' + initialSelected.replaceAll(' ', ''))?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [show, initialSelected]);

  const changeText = e => {
    setSearchText(e.target.value);
    debouncedSearch(e.target.value);
  }

  const debouncedSearch = useDebouncedCallback(text => {
    if (!text) {
      setSearchItems(null);
    }
    else {
      const items = [];

      if (!drums) {
        Object.values(INSTRUMENT_CATEGORIES).forEach(category => {
          category.instruments.forEach(instrument => {
            if (instrument.toUpperCase().includes(text.toUpperCase())) {
              items.push(instrument);
            }
          })
        });
      }

      else {
        Object.values(DRUM_PROGRAMS).forEach(drum => {
          if (drum.toUpperCase().includes(text.toUpperCase())) {
            items.push(drum);
          }
        });
      }

      setSearchItems(items);
    }
  }, 300, { maxWait: 600 });

  const renderSearchBar = () => {
    return (
      <Form.Control type='text' className='search' placeholder={t('Search...')} value={searchText} onChange={changeText} />
    );
  }

  const renderSearchResults = () => {
    if (searchItems.length === 0) {
      return (
        <div className='text-center my-2'>
          No results found for "{searchText}".
        </div>
      );
    }

    return (
      <>
        <h3 className='search-result'>Showing search results for: {searchText}</h3>
        <div className='instrument-search'>
          { searchItems.map(name => (
            <InstrumentIcon key={name} name={name} selected={selected} onClick={(e, instrument) => setSelected(instrument)} />
          ))}
        </div>
      </>
    );
  }

  const showSearch = searchText && searchItems !== null;

  return (
    <Modal show={show} className={classNames('__modal __instrument-selector-modal', { '__instrument-selector-modal-drums': drums })} onHide={onHide}>
      <Modal.Header closeButton onHide={onHide}>
        { true && renderSearchBar() }
      </Modal.Header>
      <Modal.Body>
        <div className={classNames({ 'hide-categories': showSearch })}>
          { !drums && Object.values(INSTRUMENT_CATEGORIES).map(category => (
              <InstrumentCategory key={category.phrase} category={category} selected={selected} setSelected={setSelected} />
          )) }

          { drums && (
            <>
              <h3 className='category-title'><Translate>Drums</Translate></h3>

              <div className='drums'>
                { Object.values(DRUM_PROGRAMS).map(name => (
                  <InstrumentIcon key={name} name={name} selected={selected} onClick={(e, instrument) => setSelected(instrument)} />
                ))}
              </div>
            </>
          )}

          { (currentUser?.super_user || currentUser?.composer) && 
            <div style={{ display: "flex", alignItems: "center", gap: 32, padding: 8 }}>
              <Form.Control style={{ width: '33%' }} className='instrument-select' as='select' size='sm' value={vstIndex} onChange={e => setVstIndex(parseInt(e.target.value), 10)}>
                { [1,2,3,4,5,6,7,8,9,10].map(i => <option key={PROGRAM_MAX_INDEX + i} value={PROGRAM_MAX_INDEX + i}>VST #{PROGRAM_MAX_INDEX + i}</option> )}
              </Form.Control>

              <Button onClick={() => onSubmit(vstIndex)}>Use VST</Button>
            </div>
          }
        </div>
        { showSearch && renderSearchResults() }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onSubmit(selected)}><Translate>Select</Translate></Button>
      </Modal.Footer>
    </Modal>
  )
}
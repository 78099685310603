export default function LatinIcon(props) {
  return (
    <svg 
      id="__latin-icon-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110.8 112.6"
      {...props}
    >
      <style>
        {".__latin-icon-prefix__cls-0{fill:#CECF25;}.__latin-icon-prefix__cls-1{fill:#4D3F91;}.__latin-icon-prefix__cls-2{fill:#F08660;}.__latin-icon-prefix__cls-3{fill:#528452;}.__latin-icon-prefix__cls-4{fill:#C95571;}.__latin-icon-prefix__cls-5{fill:#FFFFFF;}.__latin-icon-prefix__cls-6{fill:#F7F4EA;}"}
      </style>
      <g>
        <path className="__latin-icon-prefix__cls-0" d="M93.9,111.3h-77c-9.1,0-16.6-7.4-16.6-16.6v-77c0-9.1,7.4-16.6,16.6-16.6h77c9.1,0,16.6,7.4,16.6,16.6v77
          C110.4,103.9,103,111.3,93.9,111.3z"/>
        <g>
          <path className="__latin-icon-prefix__cls-1" d="M54.4,90.1c-0.1,1.9-0.3,3.8-0.3,5.4c1.2-0.2,3.5-0.3,5.8,0.7c0.4-1.5,0.8-3.4,1.1-5.3
            C58.4,89.6,55.4,89.9,54.4,90.1z"/>
          <path className="__latin-icon-prefix__cls-2" d="M58.9,52.1c0.2,2.7,0,5.4-0.3,8.2c-0.8,7-2.1,14-3.3,21c-0.1,0.4-0.5,3.6-0.8,7.1c0.9-0.2,4-0.6,6.8,0.9
            c0.6-3.5,1.1-6.6,1.1-7c0.7-7.1,1.3-14.1,2.3-21.1c0.4-2.8,0.9-5.5,1.8-8C63.6,51.7,60.7,51.8,58.9,52.1z"/>
          <path className="__latin-icon-prefix__cls-2" d="M64.1,15.7l5.3,5.6l4.9-4.5l3.1,6.6l2.4-0.4c-0.3-2.2-1.1-4.4-2.3-6.3c-1.4-2.2-3.3-4.1-5.8-5.1
            c-1.5-0.6-3.3-0.9-5-1c-1.3-0.1-2.7,0.1-3.9,0.5c-2.5,0.8-4.7,2.4-6.3,4.5c-0.6,0.7-1.1,1.5-1.6,2.3l4.7,3L64.1,15.7z"/>
          <path className="__latin-icon-prefix__cls-3" d="M54.1,95.5c0,1.7,0.1,3.1,0.4,3.6c0.3,0.4,3.1,0.9,4.1,0.5c0.4-0.4,0.9-1.7,1.3-3.4
            C57.7,95.2,55.3,95.3,54.1,95.5z"/>
          <path className="__latin-icon-prefix__cls-4" d="M54.5,88.4c0,0.5-0.1,1.1-0.1,1.7c1-0.2,4-0.5,6.7,0.9c0.1-0.5,0.2-1.1,0.3-1.6
            C58.6,87.8,55.4,88.3,54.5,88.4z"/>
          <path className="__latin-icon-prefix__cls-1" d="M57.9,47.4c0.4,0.9,0.6,1.9,0.8,2.8c0.1,0.6,0.2,1.3,0.3,1.9c1.8-0.3,4.7-0.5,7.7,1c0.6-1.5,1.3-2.9,2.2-4.3
            C63.7,45.4,58.5,47.2,57.9,47.4z"/>
          <path className="__latin-icon-prefix__cls-5" d="M79.8,23l-2.4,0.4l-3.1-6.6l-4.9,4.5l-5.3-5.6l-4.5,5.1l-4.7-3c-0.5,0.9-1,1.9-1.4,2.9
            c-0.2,0.5-0.4,1-0.5,1.6l1.9-2.7l4.8,3.4l4.4-5.3l5.6,6.4l4.3-4.8l2.7,6.1l3.3-0.8c0-0.1,0-0.3,0-0.4C79.9,23.8,79.8,23.4,79.8,23
            z"/>
          <path className="__latin-icon-prefix__cls-4" d="M73.9,19.3l-4.3,4.8l-5.6-6.4L59.7,23l-4.8-3.4L53,22.3c-1.2,4.2-1.4,8.6-0.5,12.8l2-5.5l4,4.8l3.6-5.8
            l3.5,5.2l4.8-4.1l2.5,6.1l3.8-2l0.6,4.5c2.1-4.2,3.1-8.9,2.8-13.7l-3.3,0.8L73.9,19.3z"/>
          <path className="__latin-icon-prefix__cls-6" d="M72.8,35.9l-2.5-6.1l-4.8,4.1L62,28.7l-3.6,5.8l-4-4.8l-2,5.5c0.1,0.4,0.2,0.9,0.3,1.3L54,33l4,4.8l3.6-5.8
            l3.5,5.2l4.8-4.1l2.5,6.1l3.8-2l0.3,2.4c0.2-0.4,0.5-0.9,0.7-1.3l-0.6-4.5L72.8,35.9z"/>
          <path className="__latin-icon-prefix__cls-2" d="M72.3,39.3l-2.5-6.1L65,37.3l-3.5-5.2L58,37.8L54,33l-1.2,3.4c0.2,0.8,0.4,1.6,0.7,2.4c0.7,2,1.7,4,2.9,5.9
            c0.6,0.9,1.1,1.8,1.4,2.8c0.7-0.3,5.9-2,11,1.5c0.6-0.8,1.3-1.6,2.1-2.3c2.2-2,4.1-4.3,5.5-6.8l-0.3-2.4L72.3,39.3z"/>
        </g>
        <g>
          <path className="__latin-icon-prefix__cls-2" d="M81.5,85.6c1.6,1.1,3.2,2.2,4.6,3.1c0.5-1.1,1.6-3.3,3.7-4.7c-1.1-1.2-2.6-2.5-4-3.8
            C83.1,81.8,81.9,84.6,81.5,85.6z"/>
          <path className="__latin-icon-prefix__cls-1" d="M50.3,61.5c2.5,1.2,4.8,2.9,7.1,4.6c5.8,4.5,11.3,9.2,16.8,14c0.3,0.3,2.9,2.3,5.9,4.4
            c0.3-0.8,1.6-3.9,4.4-5.6c-2.8-2.4-5.3-4.4-5.6-4.7C73,70,67.1,65.7,61.4,61.1c-2.2-1.8-4.4-3.7-6.1-5.8
            C52.4,57.1,51,59.8,50.3,61.5z"/>
          <path className="__latin-icon-prefix__cls-4" d="M20.9,37.7l7.7-1.7l-1.4-6.7l7.5,0.8l0.9-2.3c-2.2-0.9-4.5-1.4-6.8-1.4c-2.7,0-5.4,0.8-7.5,2.4
            c-1.4,1-2.6,2.5-3.5,3.9c-0.8,1.1-1.3,2.4-1.7,3.7c-0.6,2.6-0.3,5.4,0.6,7.9c0.3,0.9,0.7,1.8,1.2,2.6l5.2-2.6L20.9,37.7z"/>
          <path className="__latin-icon-prefix__cls-3" d="M86.1,88.6c1.5,0.9,2.8,1.6,3.4,1.5c0.5,0,2.5-2.3,2.6-3.3c-0.1-0.6-1-1.7-2.3-2.9
            C87.8,85.4,86.6,87.5,86.1,88.6z"/>
          <path className="__latin-icon-prefix__cls-4" d="M80.1,84.6c0.5,0.3,0.9,0.7,1.4,1c0.4-0.9,1.6-3.8,4.3-5.4c-0.4-0.4-0.9-0.8-1.3-1.1
            C81.7,80.7,80.4,83.7,80.1,84.6z"/>
          <path className="__latin-icon-prefix__cls-3" d="M45.6,59.9c1,0.2,2,0.4,2.9,0.8c0.6,0.2,1.2,0.5,1.8,0.8c0.6-1.7,2-4.4,5-6.3c-1-1.3-1.9-2.7-2.6-4.2
            C46.9,53.7,45.7,59.2,45.6,59.9z"/>
          <path className="__latin-icon-prefix__cls-6" d="M35.6,27.7L34.7,30l-7.5-0.8l1.4,6.7l-7.7,1.7l2.2,6.7l-5.2,2.6c0.5,1,1.2,1.9,1.9,2.8
            c0.4,0.4,0.7,0.9,1.1,1.3l-1.4-3.1l5.5-2.4l-2.3-6.7l8.6-1.6l-2-6.3l6.8,0.9l1.1-3.3c-0.1-0.1-0.2-0.1-0.4-0.2
            C36.4,28,36,27.8,35.6,27.7z"/>
          <path className="__latin-icon-prefix__cls-2" d="M29.3,30.9l2,6.3l-8.6,1.6l2.3,6.7l-5.5,2.4l1.4,3.1c3.1,3.3,6.9,5.8,11,7.2l-3.8-4.6l6.4-0.9l-3.2-6.2
            l6.4-0.3l-1.1-6.5l6.7,1.1l0.2-4.5l4.3,1.9c-2.6-4.1-6.2-7.5-10.7-9.7l-1.1,3.3L29.3,30.9z"/>
          <path className="__latin-icon-prefix__cls-6" d="M43.4,40.7l-6.7-1.1l1.1,6.5l-6.4,0.3l3.2,6.2l-6.4,0.9l3.8,4.6c0.4,0.1,0.9,0.3,1.3,0.4l-2.4-2.9l6.4-0.9
            L34,48.5l6.4-0.3l-1.1-6.5l6.7,1.1l0.2-4.5l2.3,1c-0.2-0.4-0.5-0.9-0.8-1.3l-4.3-1.9L43.4,40.7z"/>
          <path className="__latin-icon-prefix__cls-4" d="M46.1,42.8l-6.7-1.1l1.1,6.5L34,48.5l3.2,6.2l-6.4,0.9l2.4,2.9c0.8,0.2,1.6,0.4,2.5,0.6
            c2.2,0.4,4.4,0.6,6.7,0.5c1.1,0,2.2,0,3.2,0.2c0.1-0.7,1.3-6.3,7.1-8.9c-0.4-1-0.7-2-0.9-3.1c-0.6-3-1.6-5.9-3.1-8.5l-2.3-1
            L46.1,42.8z"/>
        </g>
        <circle className="__latin-icon-prefix__cls-5" cx="33.7" cy="71.7" r="5.4"/>
        <circle className="__latin-icon-prefix__cls-5" cx="85.7" cy="66.2" r="2"/>
        <circle className="__latin-icon-prefix__cls-5" cx="43.8" cy="75.6" r="2"/>
        <circle className="__latin-icon-prefix__cls-5" cx="88.9" cy="50.9" r="1.4"/>
        <circle className="__latin-icon-prefix__cls-5" cx="41.8" cy="85.6" r="1.4"/>
        <circle className="__latin-icon-prefix__cls-5" cx="81.5" cy="49.5" r="1.4"/>
        <circle className="__latin-icon-prefix__cls-5" cx="80.1" cy="58.5" r="3.4"/>
      </g>
      </svg>
  )
}

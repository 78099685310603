export default function InstrumentDrums1(props) {
  return (
    <svg
      viewBox="0 0 110.84 110.84"
      style={{
        enableBackground: "new 0 0 110.84 110.84",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".InstrumentDrums_1_st99{fill:#f48864}.InstrumentDrums_1_st102{fill:#528459}.st103{fill:#c55a74}.InstrumentDrums_1_st105{fill:#c6cf3e}"
        }
      </style>

      <path
        d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
        style={{
          fill: "#4b4a9e",
        }}
      />
      <path
        className="InstrumentDrums_1_st102"
        d="M48.45 75.98s-5.66 1.84-14.37 1.84-14.37-1.84-14.37-1.84V47.24s4.99-1.05 14.26-1.05 14.48 1.05 14.48 1.05v28.74z"
      />
      <path
        className="st103"
        d="M50.45 54.09s-3.77.88-11.14 0c-7.38-.88-9.19-3.17-9.19-3.17l3.17-20.33s3.49-1.17 10.58 0 9.75 3.17 9.75 3.17l-3.17 20.33zM77.7 50.9s-3.35 1.93-10.67 3.23c-7.32 1.29-9.71-.38-9.71-.38l-2.85-20.38s3-2.13 10.12-3.06 10.26.21 10.26.21L77.7 50.9z"
      />
      <ellipse
        transform="rotate(-70.61 18.27 22.544)"
        className="InstrumentDrums_1_st105"
        cx={18.27}
        cy={22.54}
        rx={3.55}
        ry={16.44}
      />
      <ellipse
        transform="rotate(-17.254 88.712 14.096)"
        className="InstrumentDrums_1_st105"
        cx={88.7}
        cy={14.1}
        rx={12.24}
        ry={1.93}
      />
      <path
        className="st103"
        d="M17.34 91.71c-.38 0-.69-.31-.69-.69V23.24c0-.38.31-.69.69-.69.38 0 .69.31.69.69v67.78c0 .38-.31.69-.69.69z"
      />
      <path
        className="st103"
        d="m6.44 96.01 10.44-20.67c.18-.35.09-.82-.19-1.04l-.04-.03c-.28-.22-.66-.12-.84.24L5.38 95.17c-.18.35-.09.82.19 1.04l.04.03c.28.23.66.13.83-.23zM28.32 96.01 17.88 75.34c-.18-.35-.09-.82.19-1.04l.04-.03c.28-.22.66-.12.84.24l10.44 20.67c.18.35.09.82-.19 1.04l-.04.03c-.28.22-.66.12-.84-.24z"
      />
      <path
        className="InstrumentDrums_1_st99"
        d="m73.11 90.07 5.49 8.71c.2.32.11.74-.21.94l-.05.03a.68.68 0 0 1-.94-.21l-5.16-8.18.87-1.29zM33.49 89.05 28 97.76c-.2.32-.11.74.21.94l.05.03c.32.2.74.11.94-.21l5.16-8.18-.87-1.29z"
      />
      <path
        className="InstrumentDrums_1_st102"
        d="M24.64 77.05 16.96 88.8c-.28.45-.26.97.05 1.17l.04.03c.31.2.79-.01 1.08-.46l7.84-12.27-1.33-.22z"
      />
      <path
        className="InstrumentDrums_1_st105"
        d="M91.84 58.22s-2.64 2.29-11.49 3.73-12.56.93-12.56.93l-1.81-9.34s4.73-3.07 11.87-4.67c7.14-1.6 12.19 0 12.19 0l1.8 9.35z"
      />
      <circle className="InstrumentDrums_1_st99" cx={53.62} cy={77.76} r={23.05} />
      <circle className="InstrumentDrums_1_st105" cx={53.62} cy={77.76} r={20.35} />
      <ellipse className="st103" cx={86.09} cy={34.09} rx={9.41} ry={1.65} />
      <ellipse className="InstrumentDrums_1_st105" cx={85.92} cy={31.59} rx={9.41} ry={1.65} />
      <path
        className="InstrumentDrums_1_st99"
        d="M86.29 69.31h-.35c-.23 0-.41-.18-.41-.41V34.63c0-.23.18-.41.41-.41h.35c.23 0 .41.18.41.41V68.9c0 .22-.19.41-.41.41z"
      />
      <path
        className="InstrumentDrums_1_st99"
        d="m79.43 68 6.41-11.4c.16-.29.08-.66-.17-.84l-.28-.2c-.25-.18-.59-.09-.75.19l-6.41 11.4c-.16.29-.08.66.17.84l.28.2c.25.18.59.09.75-.19zM92.79 68l-6.41-11.4c-.16-.29-.08-.66.17-.84l.28-.2c.25-.18.59-.09.75.19l6.41 11.4c.16.29.08.66-.17.84l-.28.2c-.25.18-.59.09-.75-.19z"
      />
      <path
        className="InstrumentDrums_1_st102"
        d="M90.21 87.07c-.33 0-.59-.26-.59-.59V16.03c0-.33.26-.59.59-.59.33 0 .59.26.59.59v70.45c0 .32-.26.59-.59.59z"
      />
      <path
        className="InstrumentDrums_1_st102"
        d="m77.98 90.95 11.77-18.66a.68.68 0 0 0-.21-.94l-.05-.03a.68.68 0 0 0-.94.21L76.78 90.19c-.2.32-.11.74.21.94l.05.03c.32.2.74.11.94-.21zM102.4 90.95 90.63 72.29a.68.68 0 0 1 .21-.94l.05-.03c.32-.2.74-.11.94.21l11.77 18.66c.2.32.11.74-.21.94l-.05.03a.68.68 0 0 1-.94-.21z"
      />
    </svg>
  );
}

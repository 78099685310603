import * as React from "react";

const InstrumentNewAge = (props) => (
  <svg
    id="InstrumentNewAge_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.99 110.84"
    style={{
      enableBackground: "new 0 0 110.99 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentNewAge_svg__st2{fill:#fdcc75}.InstrumentNewAge_svg__st3{fill:#4e4092}.InstrumentNewAge_svg__st4{fill:#f18860}.InstrumentNewAge_svg__st78{fill:#f9ccc3}"
      }
    </style>
    
    <path
      className="InstrumentNewAge_svg__st4"
      d="M94.31 110.84h-77.5C7.6 110.84.14 103.38.14 94.17v-77.5C.14 7.46 7.61 0 16.82 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.47 16.67-16.68 16.67z"
    />
    <path
      d="M86.87 87.18H24.25a5.54 5.54 0 0 1-5.54-5.54V29.19a5.54 5.54 0 0 1 5.54-5.54h62.62a5.54 5.54 0 0 1 5.54 5.54v52.45a5.54 5.54 0 0 1-5.54 5.54z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      style={{
        fill: "#fff",
      }}
      d="M25.03 56.81h61.08V78.1H25.03z"
    />
    <path
      className="InstrumentNewAge_svg__st3"
      d="M28.96 56.81h4.16v12.95h-4.16zM36.3 56.81h4.16v12.95H36.3zM43.63 56.81h4.16v12.95h-4.16zM53.5 56.81h4.16v12.95H53.5zM60.29 56.81h4.16v12.95h-4.16zM70.67 56.81h4.16v12.95h-4.16zM78.01 56.81h4.16v12.95h-4.16z"
    />
    <circle
      className="InstrumentNewAge_svg__st3"
      cx={65.21}
      cy={29.23}
      r={2.73}
    />
    <circle
      className="InstrumentNewAge_svg__st4"
      cx={65.21}
      cy={37.9}
      r={2.73}
    />
    <circle
      className="InstrumentNewAge_svg__st2"
      cx={65.21}
      cy={46.57}
      r={2.73}
    />
    <circle
      className="InstrumentNewAge_svg__st4"
      cx={73.41}
      cy={29.23}
      r={2.73}
    />
    <circle
      className="InstrumentNewAge_svg__st2"
      cx={73.41}
      cy={37.9}
      r={2.73}
    />
    <circle
      className="InstrumentNewAge_svg__st3"
      cx={73.41}
      cy={46.57}
      r={2.73}
    />
    <circle
      className="InstrumentNewAge_svg__st2"
      cx={82.17}
      cy={29.23}
      r={2.73}
    />
    <circle
      className="InstrumentNewAge_svg__st3"
      cx={82.17}
      cy={37.9}
      r={2.73}
    />
    <circle
      className="InstrumentNewAge_svg__st4"
      cx={82.17}
      cy={46.57}
      r={2.73}
    />
    <path
      className="InstrumentNewAge_svg__st2"
      d="M28.14 28.98c0-.91-.73-1.64-1.64-1.64-.91 0-1.64.73-1.64 1.64v17.59h3.28V28.98z"
    />
    <path
      className="InstrumentNewAge_svg__st4"
      d="M24.86 46.57v4.58c0 .91.73 1.64 1.64 1.64.91 0 1.64-.73 1.64-1.64v-4.58h-3.28z"
    />
    <path
      className="InstrumentNewAge_svg__st3"
      d="M36.48 39.45v11.7c0 .91.73 1.64 1.64 1.64.91 0 1.64-.73 1.64-1.64V39.42l-3.28.03z"
    />
    <path
      className="InstrumentNewAge_svg__st4"
      d="M39.76 28.98c0-.91-.73-1.64-1.64-1.64-.91 0-1.64.73-1.64 1.64v10.47l3.28-.03V28.98z"
    />
    <path
      className="InstrumentNewAge_svg__st3"
      d="M51.07 28.98c0-.91-.73-1.64-1.64-1.64s-1.64.73-1.64 1.64v5.52h3.28v-5.52z"
    />
    <path
      className="InstrumentNewAge_svg__st2"
      d="M47.79 34.5v16.65c0 .91.73 1.64 1.64 1.64.91 0 1.64-.73 1.64-1.64V34.5h-3.28z"
    />
    <path
      className="InstrumentNewAge_svg__st78"
      d="M46.63 32.81h5.61v3.39h-5.61zM35.32 37.24h5.61v3.39h-5.61zM23.69 44.87h5.61v3.39h-5.61z"
    />
  </svg>
);

export default InstrumentNewAge;

import * as React from "react";

const InstrumentElectricPiano2 = (props) => (
  <svg
    id="InstrumentElectricPiano2_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 112.34 110.84"
    style={{
      enableBackground: "new 0 0 112.34 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentElectricPiano2_svg__st4{fill:#f18860}.InstrumentElectricPiano2_svg__st29{fill:#2b4c5c}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentElectricPiano2_svg__st4"
      d="M99.12 77.5H11.17c-2.18 0-3.94-1.77-3.94-3.94V53.9c0-2.18 1.77-3.94 3.94-3.94h87.95c2.18 0 3.94 1.77 3.94 3.94v19.66c0 2.18-1.76 3.94-3.94 3.94z"
    />
    <path
      style={{
        fill: "#fff",
      }}
      d="M13.56 55.42h83.68v15.94H13.56z"
    />
    <path
      className="InstrumentElectricPiano2_svg__st29"
      d="M17.07 55.42h3.12v9.7h-3.12zM22.56 55.42h3.12v9.7h-3.12zM28.06 55.42h3.12v9.7h-3.12zM35.45 55.42h3.12v9.7h-3.12zM40.53 55.42h3.12v9.7h-3.12zM48.31 55.42h3.12v9.7h-3.12zM53.81 55.42h3.12v9.7h-3.12zM59.3 55.42h3.12v9.7H59.3zM67.34 55.42h3.12v9.7h-3.12zM72.42 55.42h3.12v9.7h-3.12zM80.04 55.42h3.12v9.7h-3.12zM85.53 55.42h3.12v9.7h-3.12zM91.03 55.42h3.12v9.7h-3.12z"
    />
    <path
      className="InstrumentElectricPiano2_svg__st4"
      d="M95.44 50.87H14.85V36.83c0-2.03 1.64-3.67 3.67-3.67h73.24c2.03 0 3.67 1.64 3.67 3.67v14.04z"
    />
  </svg>
);

export default InstrumentElectricPiano2;

import * as React from "react";

const InstrumentBells = (props) => (
  <svg
    id="InstrumentBells_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.45 110.6"
    style={{
      enableBackground: "new 0 0 110.45 110.6",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentBells_svg__st3{fill:#4e4092}.InstrumentBells_svg__st4{fill:#f18860}.InstrumentBells_svg__st6{fill:#ca5672}.InstrumentBells_svg__st52{fill:#fff;stroke:#fff;stroke-width:.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#fdcc75",
      }}
    />
    <path
      className="InstrumentBells_svg__st3"
      d="m69.81 15.59-3.45.13-.21.01-3.45.13s-1.59 3.52-1.4 8.59c.19 5.07 2.11 33.97 2.11 33.97l4.46-.17 4.46-.17s-.29-28.95-.48-34.03c-.19-5.08-2.04-8.46-2.04-8.46z"
    />
    <path
      className="InstrumentBells_svg__st6"
      d="M67.88 58.25h-.01l-4.46.17.16 4.11 4.46-.17h.01l4.46-.17-.16-4.11z"
    />
    <path
      className="InstrumentBells_svg__st4"
      d="M82.56 82.23c-1.84-2.49-2.76-5.76-3.63-8.68-.73-2.46-1-5.19-2.41-7.43-1.17-1.86-3.59-3.94-5.92-3.85 0 0-2.14.08-2.49.09h-.07c-.35.01-2.49.09-2.49.09-2.37.09-4.3 2.11-5.44 4.01-1.44 2.41-1.43 5.2-2.01 7.88-.64 2.99-1.31 6.31-2.96 8.94-2.68 4.27-6.47 6.34-6.47 6.34s1.42 4.52 21.21 3.77c19.79-.75 19.62-5.32 19.62-5.32s-3.94-1.79-6.94-5.84z"
    />
    <path
      className="InstrumentBells_svg__st52"
      d="M71.44 63.91s4.67 1.07 4.36 5.26c0 0-1.08-3.51-4.36-5.26z"
    />
    <path
      className="InstrumentBells_svg__st6"
      d="m85.46 26.47-2.23-2.63-.13-.16-2.23-2.63s-3.75.91-7.63 4.19C69.37 28.5 47.78 47.8 47.78 47.8l2.88 3.41 2.88 3.41s22.63-18.06 26.51-21.33c3.89-3.28 5.41-6.82 5.41-6.82z"
    />
    <path
      className="InstrumentBells_svg__st3"
      d="m50.59 51.12-.01-.01-2.86-3.42-3.15 2.63 2.86 3.43 2.86 3.43 3.16-2.64z"
    />
    <path
      className="InstrumentBells_svg__st4"
      d="M40.57 77.4c.84-2.98 2.87-5.71 4.65-8.18 1.5-2.08 3.5-3.96 4.41-6.45.76-2.07.93-5.25-.57-7.04 0 0-1.37-1.65-1.6-1.91l-.05-.05c-.22-.27-1.6-1.91-1.6-1.91-1.52-1.82-4.3-2.12-6.5-1.86-2.79.33-4.99 2.05-7.46 3.23-2.76 1.32-5.8 2.82-8.88 3.12-5.02.49-8.97-1.24-8.97-1.24S11.29 59 23.98 74.2s16.2 12.27 16.2 12.27-.98-4.22.39-9.07z"
    />
    <path
      className="InstrumentBells_svg__st52"
      d="M47.63 56.9s2.53 4.07-.66 6.8c.01 0 1.73-3.25.66-6.8z"
    />
  </svg>
);

export default InstrumentBells;

import * as React from "react";

const InstrumentAcousticBritePiano = (props) => (
  <svg
    id="InstrumentAcousticBritePiano_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 112.19 110.84"
    style={{
      enableBackground: "new 0 0 112.19 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentAcousticBritePiano_svg__st2{fill:#fdcc75}.InstrumentAcousticBritePiano_svg__st5{fill:#cfd125}.InstrumentAcousticBritePiano_svg__st10{fill:#538553}.InstrumentAcousticBritePiano_svg__st28{fill:#fff}.InstrumentAcousticBritePiano_svg__st35{fill:#1f3138}"
      }
    </style>
    <path
      d="M95.52 110.84H18.03c-9.21 0-16.67-7.46-16.67-16.67v-77.5C1.35 7.46 8.82 0 18.03 0h77.49c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      className="InstrumentAcousticBritePiano_svg__st10"
      d="M101.13 57.22H14.76c-1.75 0-3.17-1.42-3.17-3.17V44.4c0-1.75 1.42-3.17 3.17-3.17h86.36c1.75 0 3.17 1.42 3.17 3.17v9.65c0 1.75-1.41 3.17-3.16 3.17z"
    />
    <path
      className="InstrumentAcousticBritePiano_svg__st5"
      d="M99.08 41.24H17.02v-6.48c0-3.47 2.81-6.28 6.28-6.28h69.51c3.47 0 6.28 2.81 6.28 6.28v6.48z"
    />
    <path
      className="InstrumentAcousticBritePiano_svg__st28"
      d="M21.27 41.24h6.61v7.79h-6.61zM28.42 41.24h5.97v7.79h-5.97zM34.93 41.24h6.08v7.79h-6.08zM41.56 41.24h6.02v7.79h-6.02zM48.12 41.24h6.08v7.79h-6.08zM54.74 41.24h5.97v7.79h-5.97zM61.25 41.24h6.05v7.79h-6.05zM67.84 41.24h6.14v7.79h-6.14zM74.53 41.24h6.15v7.79h-6.15zM81.21 41.24h6.07v7.79h-6.07zM87.82 41.24h6.14v7.79h-6.14z"
    />
    <path
      className="InstrumentAcousticBritePiano_svg__st5"
      d="M23.26 79.9h-4.95c-2.25 0-4.08-1.83-4.08-4.08v-18.6h9.03V79.9zM95.9 79.9h-4.21V57.22h9.03v17.86c0 2.66-2.16 4.82-4.82 4.82z"
    />
    <path
      className="InstrumentAcousticBritePiano_svg__st2"
      d="M65.71 73.01H49.24a2.78 2.78 0 0 1-2.78-2.78v-2.25a2.78 2.78 0 0 1 2.78-2.78h16.47a2.78 2.78 0 0 1 2.78 2.78v2.25a2.78 2.78 0 0 1-2.78 2.78z"
    />
    <path
      className="InstrumentAcousticBritePiano_svg__st2"
      d="M50.13 79.9h-.85a.67.67 0 0 1-.67-.67v-6.81c0-.37.3-.67.67-.67h.85c.37 0 .67.3.67.67v6.81c.01.37-.29.67-.67.67zM65.64 79.9h-.85a.67.67 0 0 1-.67-.67v-6.81c0-.37.3-.67.67-.67h.85c.37 0 .67.3.67.67v6.81c0 .37-.3.67-.67.67z"
    />
    <path
      className="InstrumentAcousticBritePiano_svg__st10"
      d="M70.03 33.77H45.24c-.75 0-1.36-.61-1.36-1.36 0-.75.61-1.36 1.36-1.36h24.79c.75 0 1.36.61 1.36 1.36 0 .75-.61 1.36-1.36 1.36z"
    />
    <path
      className="InstrumentAcousticBritePiano_svg__st35"
      d="M28.38 37.76h4.82v3.49h-4.82zM34.61 37.76h4.82v3.49h-4.82zM73.99 37.76h4.82v3.49h-4.82zM80.43 37.76h4.82v3.49h-4.82zM86.87 37.76h4.82v3.49h-4.82zM48.71 37.76h4.82v3.49h-4.82zM55.09 37.76h4.82v3.49h-4.82zM61.47 37.76h4.82v3.49h-4.82z"
    />
  </svg>
);

export default InstrumentAcousticBritePiano;

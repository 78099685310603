import * as React from "react";

const InstrumentAltoSax = (props) => (
  <svg
    id="InstrumentAltoSax_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentAltoSax_svg__st4{fill:#f18860}.InstrumentAltoSax_svg__st5{fill:#cfd125}"
      }
    </style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      className="InstrumentAltoSax_svg__st4"
      d="M77 19.45c.53-2.31 1.83-3.48 3.6-3.27 1.78.21 3.05 2.13 2.83 4.21-.15 1.41-.17 2.01.28 2.42.72.65 1.76.12 1.93-.92.37-2.3-.06-4.44-1.56-6.33-1.76-2.22-3.95-2.9-6.45-2.04-2.07.71-3.4 2.36-4.12 4.68L55.39 78.33c-.31 1.04-.71 1.96-1.59 2.49-1.18.71-2.48.55-3.46-.46-.94-.97-1.26-2.46-.79-3.9.69-2.1 1.51-4.14 2.11-6.28 1.67-5.91 2.15-11.99 1.56-18.18-.02-.19-.06-.37-.1-.61l-14.95 8.38c.4.8.82 1.54 1.15 2.34 1.77 4.32 1.75 8.67.1 13.03-1.29 3.4-1.53 6.88-.52 10.42 1.34 4.71 4.07 7.86 8.12 9.22 6.7 2.25 13.3-2.22 15.11-10.11 2.3-9.95 13.29-58.3 14.87-65.22z"
      id="InstrumentAltoSax_svg___x36_Tvu8w.tif_00000147184279866345330160000008625402207222356639_"
    />
    <path
      className="InstrumentAltoSax_svg__st4"
      d="M35.3 59.27c.25.01.51-.06.76-.2l17.48-10.32c.8-.47 1.12-1.61.72-2.54-.4-.93-1.37-1.31-2.17-.84L34.6 55.68c-.8.47-1.12 1.61-.72 2.54.28.66.84 1.04 1.42 1.05z"
    />
    <ellipse
      className="InstrumentAltoSax_svg__st5"
      cx={69.14}
      cy={42.33}
      rx={0.91}
      ry={1.06}
    />
    <ellipse
      className="InstrumentAltoSax_svg__st5"
      cx={67.48}
      cy={48.94}
      rx={0.91}
      ry={1.06}
    />
    <ellipse
      className="InstrumentAltoSax_svg__st5"
      cx={65.94}
      cy={55.55}
      rx={0.91}
      ry={1.06}
    />
  </svg>
);

export default InstrumentAltoSax;

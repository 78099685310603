import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Trans as Translate } from 'react-i18next';
import Cookies from 'js-cookie';
import classNames from "classnames";
import { strofeApi } from "../../api/strofeApi";

import Form from 'react-bootstrap/Form';
// import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { usersActions, usersSelectors } from "../../store/usersSlice";
import { EventTracker } from "../../utils/Tracking";
import MidiPlayer from "../../utils/MidiPlayer";

import RegistrationModal from "../../registration/RegistrationModal";
import Checkbox from "../../layout/Checkbox";
import NotesIcon from "../../icons/NotesIcon";

import './CreateSongDetails.scss';
import useEventCallback from "../../hooks/useEventCallback";

// TEMPO
// -----
// const MIN_TEMPO = 80;
// const MAX_TEMPO = 240;
// const DEFAULT_TEMPO = 120;

// const TEMPO_MARKS = {
//   [MIN_TEMPO] : MIN_TEMPO,
//   [MAX_TEMPO] : MAX_TEMPO,
// };

const TONALITIES = ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'C#', 'D#', 'F#', 'G#', 'A#', 'Db', 'Eb', 'Gb', 'Ab', 'Bb'];

export default function CreateSongDetails({ creating, genre, mood, tempo, swing, orchestration, onCreate, onCreateError, disabled }) {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // const [tempo, setTempo] = useState(DEFAULT_TEMPO);
  // const [customTempo, setCustomTempo] = useState(false);
  const [customTone, setCustomTone] = useState(false);
  const [tonic, setTonic] = useState('C');
  const [showHarmonies, setShowHarmonies] = useState(() => Cookies.get('strofe_create_harmony_id') ? true : false);
  const [harmonies, setHarmoies] = useState([]);
  const [selectedHarmony, setSelectedHarmony] = useState(() => Cookies.get('strofe_create_harmony_id') || 1);
  const [showRegistration, setShowRegistration] = useState(false);

  const currentUser = useSelector(usersSelectors.getCurrentUser);

  const handleCreateSong = useEventCallback(() => {
    
    // if the user is not registered and has created 3 or more songs, show registration modal:
    if (!currentUser.registered && currentUser.created_song_count >= 3) {
      setShowRegistration(true);
    }
    else {
      createSongRequest();
    }
  });

  const createSongRequest = async () => {
    onCreate();

    (currentUser.created_song_count === 0) && EventTracker.createSong();

    try {
      const { data } = await strofeApi.post('/songs', {
        song: {
          style            : genre || undefined,
          qpm              : tempo || undefined,
          tonic            : customTone ? tonic : undefined,
          swing            : swing || undefined,
          harmony_id       : showHarmonies ? parseInt(selectedHarmony, 10) : undefined,
          orchestration_id : orchestration || undefined,
          mood,
        },
      });
      
      dispatch(usersActions.songCreated());

      // Fetch user to see if coins value has been incremented:
      dispatch(usersActions.fetchById({ id: Cookies.get('strofe_user_id') }));

      if (showHarmonies) {
        Cookies.set('strofe_create_harmony_id', selectedHarmony);
      } else {
        Cookies.remove('strofe_create_harmony_id');
      }
      
      const { download_url: midiUrl, id } = data;
      
      const audioFormat = process.env.REACT_APP_DEFAULT_FORMAT || 'midi';

      if (audioFormat === 'midi') {
        MidiPlayer.loadMidi(midiUrl, () => history.push(`/song/${id}/?created=true`, { midiUrl }));
      }
      else {
        history.push(`/song/${id}/?created=true`);
      }
    }
    
    catch(error) {
      onCreateError?.(error);
    }
  }

  const closeRegistrationModal = () => {
    setShowRegistration(false);

    if (!currentUser.registered && location.state?.reroll) {
      history.goBack();
    }
  }

  const onUserCreated = () => {
    createSongRequest();
  }

  useEffect(() => {
    const getHarmonies = async () => {
      const response = await strofeApi.get('/harmonies');
      setHarmoies(response.data);
    }

    (currentUser.super_user || currentUser.composer) && getHarmonies();
  }, [currentUser]);

  useEffect(() => {
    if (location.state?.reroll) {
      handleCreateSong();
    }
  }, [location.state, handleCreateSong]);

  const renderCreateDetails = () => (
    <div className='__create-song-details'>
      { (currentUser.composer || currentUser.super_user) && (
        <>
          <div className='d-flex justify-content-center mt-2'>
            <Checkbox checked={showHarmonies} onChange={e => setShowHarmonies(e.target.checked)}>Custom Harmony</Checkbox>
          </div>

          { showHarmonies && (
            <Form.Control className='harmony-select' as='select' size='sm' value={selectedHarmony} onChange={e => setSelectedHarmony(e.target.value)}>
              { harmonies.map(harmony => (
                <option key={harmony.id} value={harmony.id}>{harmony.id} - { harmony.title }</option>
              ))}
            </Form.Control>
          )}

          <div className='d-flex justify-content-center mt-2'>
            <Checkbox checked={customTone} onChange={e => setCustomTone(e.target.checked)}>Custom Key</Checkbox>
          </div>

          { customTone && (
            <Form.Control className='harmony-select' as='select' size='sm' value={tonic} onChange={e => setTonic(e.target.value)}>
              { TONALITIES.map(tonic => (
                <option key={tonic} value={tonic}>{ tonic }</option>
              ))}
            </Form.Control>
          )}

          {/* <div className='d-flex justify-content-center my-2'>
            <Checkbox checked={customTempo} onChange={e => setCustomTempo(e.target.checked)}>Custom Tempo</Checkbox>
          </div>
          
          { customTempo && (
            <>
              <Slider className='big-handle' min={MIN_TEMPO} max={MAX_TEMPO} marks={TEMPO_MARKS} onChange={v => setTempo(v)} value={tempo} />
              <div className='tempo-marker'><span className='label'>{ tempo } qpm</span></div>
            </>
          )} */}
        </>
      ) }

      <div className='details'>
        <button className={classNames('create-song-button', { 'button-disabled': disabled })} onClick={handleCreateSong} disabled={disabled} data-test="create-song">
          <div className='icon'><NotesIcon /></div>
          <span><Translate>Create</Translate></span>
        </button>
      </div>
    </div>
  );

  // when creating is set on parent component, do not render anything
  // the component still needs to be called as it handles the logic of requesting the new song
  return (
    <>
      { !creating && renderCreateDetails() }
      <RegistrationModal show={showRegistration} onClose={closeRegistrationModal} onUserCreated={onUserCreated} from='createSong' />
    </>
  )
}

export default function LPCurve2(props) {
  return (
    <svg viewBox="0 0 360 512.5" {...props}>
      <path
        d="M238.5 22.6l2.6 381.6c.2 32.9-26.1 59.8-59 60.4l-63.6 1"
        fill="none"
        stroke="#4a3f8c"
        strokeWidth={2.645}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray={5.291}
      />
    </svg>
  );
}

export default function HeartIconOutline(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.7 26.1" {...props}>
      <path
        d="M26.8 6.8C25 1.7 18.3 2 14.9 5.2 11.4 2 4.7 1.7 2.9 6.8.2 14 9.7 20.1 14.9 23.3c5.2-3.2 14.6-9.3 11.9-16.5z"
        fill="none"
        stroke="#ced025"
        strokeWidth={3.357}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </svg>
  );
}

import * as React from "react";

const InstrumentUnicorn = (props) => (
  <svg
    id="InstrumentUnicorn_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 111.06 110.84"
    style={{
      enableBackground: "new 0 0 111.06 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".InstrumentUnicorn_svg__st28{fill:#fff}"}</style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.49c9.21 0 16.67 7.46 16.67 16.67v77.49c.01 9.22-7.45 16.68-16.66 16.68z"
      style={{
        fill: "#4e4092",
      }}
    />
    <linearGradient
      id="InstrumentUnicorn_svg__SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={27.487}
      y1={20.072}
      x2={67.016}
      y2={76.26}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#f9ccc3",
        }}
      />
      <stop
        offset={0.088}
        style={{
          stopColor: "#f9ccc2",
        }}
      />
      <stop
        offset={0.12}
        style={{
          stopColor: "#f9ccbe",
        }}
      />
      <stop
        offset={0.142}
        style={{
          stopColor: "#faccb6",
        }}
      />
      <stop
        offset={0.161}
        style={{
          stopColor: "#faccaa",
        }}
      />
      <stop
        offset={0.176}
        style={{
          stopColor: "#fbcc9a",
        }}
      />
      <stop
        offset={0.19}
        style={{
          stopColor: "#fccc86",
        }}
      />
      <stop
        offset={0.197}
        style={{
          stopColor: "#fdcc75",
        }}
      />
      <stop
        offset={0.222}
        style={{
          stopColor: "#f6cd6a",
        }}
      />
      <stop
        offset={0.285}
        style={{
          stopColor: "#e6cf50",
        }}
      />
      <stop
        offset={0.347}
        style={{
          stopColor: "#dad03a",
        }}
      />
      <stop
        offset={0.41}
        style={{
          stopColor: "#d2d12b",
        }}
      />
      <stop
        offset={0.47}
        style={{
          stopColor: "#cfd125",
        }}
      />
      <stop
        offset={0.58}
        style={{
          stopColor: "#e4b050",
        }}
      />
      <stop
        offset={0.678}
        style={{
          stopColor: "#f18860",
        }}
      />
      <stop
        offset={0.682}
        style={{
          stopColor: "#ef8762",
        }}
      />
      <stop
        offset={0.77}
        style={{
          stopColor: "#bc6c7c",
        }}
      />
      <stop
        offset={0.849}
        style={{
          stopColor: "#8f5889",
        }}
      />
      <stop
        offset={0.916}
        style={{
          stopColor: "#6c4b8f",
        }}
      />
      <stop
        offset={0.969}
        style={{
          stopColor: "#564392",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#4e4092",
        }}
      />
    </linearGradient>
    <path
      d="M86.81 54.32c-2.24-1.14-4.44-1.99-6.42-3.66-1.84-1.55-2.93-3.8-4.67-5.45-3.39-3.22-9.06-4.47-9.67-4.91.69-.66 3.52-.79 4.89-.5 2.8.59 4.95 2.1 4.95 2.1-1-3.53-6.07-5.16-9.24-5.57-1.29-.17-2.96-.07-4.57-.16-.1-.08-.19-.17-.29-.25 0-.01-.01-.03-.01-.04.01.07 1.45-1.09 1.57-1.23 1.38-1.73 1.44-4.81 1.39-6.91-2.67 1.76-5.35 3.53-8.13 5.11.01.03.01.06.02.09-.04-.01-.07-.03-.11-.04.03-.02.06-.03.09-.05-.22-.91-.13-1.9.29-2.74.81-1.58 2.71-2.8 2.49-4.56-.94 1.54-2.77 2.32-4.54 2.67-1.77.36-3.61.4-5.33.95-1.74.55-3.51 2.01-3.27 3.82.01.05.03.1.04.16-.25.1-.5.22-.74.33L24.04 7.86l15.31 30.66c0 .01-.01.02-.01.02-.92 1.13-4.94 2.54-4.95 4.07 0 .47.24.91.39 1.36.93 2.66-2.25 5.88-3.43 7.99-1.15 2.05-7.2 8.52-7.3 10.97-.12 2.71 2.95 4.54 5.25 4.4 2.3-.14 4.62-3.49 8.95-4.12 4.64-.68 9.28-3.46 12.08-5.43.44-.31.99.18.75.66-4.64 9.33-8.31 38.8.02 44.54.26.18-.55-25.68 24.29-36 3.46-1.56 3.26-4.85 1.64-8.28-.91-1.93-2.02-4.17-3.28-6.52.8.58 1.63 1.1 2.51 1.52 3.3 1.6 7.04 1.29 10.55.62z"
      style={{
        fill: "url(#InstrumentUnicorn_svg__SVGID_1_)",
      }}
    />
    <path
      className="InstrumentUnicorn_svg__st28"
      d="m36.43 13.17 1.3 3.5 3.5 1.3-3.5 1.29-1.3 3.51-1.3-3.51-3.5-1.29 3.5-1.3zM77.86 71.99l.72 1.94 1.95.72-1.95.72-.72 1.95-.72-1.95-1.94-.72 1.94-.72zM92.42 61.32l.72 1.95 1.94.72-1.94.72-.72 1.94-.72-1.94-1.95-.72 1.95-.72zM27.57 25.5l.9 2.2 2.43.82-2.43.82-.9 2.2-.9-2.2-2.43-.82 2.43-.82zM77.86 55.23l1.3 3.51 3.5 1.29-3.5 1.3-1.3 3.5-1.3-3.5-3.5-1.3 3.5-1.29z"
    />
  </svg>
);

export default InstrumentUnicorn;

import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import Cookies from 'js-cookie';
import { Trans as Translate, useTranslation } from 'react-i18next';
import classNames from "classnames";
import { useDebouncedCallback } from "use-debounce/lib";
import useEventCallback from '../../hooks/useEventCallback';
import { strofeApi } from "../../api/strofeApi";
import { usersSelectors } from "../../store/usersSlice";

import Toast from "react-bootstrap/Toast";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from "react-bootstrap/Button";

import NavigationHeader from "../NavigationHeader";
import DeleteSequenceModal from "./DeleteSequenceModal";
import FilterModal from "./FilterModal";
import InstrumentCategoryModal from "./InstrumentCategoryModal";

import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";
import InfinityIcon from "../../icons/InfinityIcon";
import MoodStyleIcon from "../SongPlayer/MoodStyleIcon";
import TriangleUpIcon from "../../icons/TriangleUpIcon";
import DurationIcon from "../../icons/DurationIcon";

import './NoteSequences.scss';

const MOBILE_SORTS = [
  {
    phrase: 'Date modified (newest first)',
    sortDir: 'desc',
    sortBy: 'updated_at',
  },

  {
    phrase: 'Date modified (oldest first)',
    sortDir: 'asc',
    sortBy: 'updated_at',
  },

  {
    divider: true,
  },

  {
    phrase: 'Title (ascending)',
    sortDir: 'asc',
    sortBy: 'title',
  },

  {
    phrase: 'Title (descending)',
    sortDir: 'desc',
    sortBy: 'title',
  }
]
const SORTING_COLUMNS = {
  created_at: {
    phrase  : '#',
    sortDir : 'asc',
  },

  updated_at: {
    phrase  : 'Modified',
    sortDir : 'desc',
  },

  title: {
    phrase  : 'Title',
    sortDir : 'asc',
  },

  category: {
    phrase  : 'Category',
    sortDir : 'asc',
  },

  status: {
    phrase  : 'Status',
    sortDir : 'asc',
  }
}

export const STATUS_TRANSLATION = {
  all       : "All",
  draft     : "Draft",
  ready     : "Ready",
  review    : "Review",
  published : "Published",
}

export const CATEGORIES = {
  chords : 'Accompaniment',
  bass   : 'Bass Line',
  drums  : 'Drums',
}

const CATEGORY_PROGRAMS = {
  chords: 0,
  bass:   33,
  drums:  0,
}

// TODO -> ROW_HEIGHT has a magic number for now, it can be calculated by rendering a dummy table first:
const ROW_HEIGHT = 44;
const FETCH_SONGS_MIN_PER_PAGE = 10;
const FETCH_SONGS_MAX_PER_PAGE = 30;

export default function NoteSequences() {
  const calculatePerPage = () => (
    Math.min(Math.max(Math.floor((window.innerHeight / ROW_HEIGHT) / 5) * 5, FETCH_SONGS_MIN_PER_PAGE), FETCH_SONGS_MAX_PER_PAGE)
  );

  const [sequences, setSequences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(false);
  const [showDeleteSequenceId, setShowDeleteSequenceId] = useState(null);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [sortBy, setSortBy] = useState(() => Cookies.get('strofe__sequences_sort_by') || 'updated_at');
  const [sortDirection, setSortDirection] = useState(() => Cookies.get('strofe__sequences_sort_dir') || 'desc');
  const [loadingSortChange, setLoadingSortChange] = useState(false);
  const [perPage, ] = useState(() => calculatePerPage());
  const [fetchMoreSequences, setFetchMoreSequences] = useState(false);
  const [initialLoad, setInitialLoad] = useState('initialize');
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState(null);
  const [quickFilter, setQuickFilter] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const currentUser = useSelector(usersSelectors.getCurrentUser);
  const additionalOptions = currentUser?.super_user || currentUser?.composer;

  const skeletonRowRef = useRef();
  const observerRef = useRef();
  const mobileLibraryRef = useRef();

  const { t } = useTranslation();
  const history = useHistory();

  const loadSequences = useEventCallback((resetSequences = false, fetchPage = page, fetchSort = sortBy, fetchDirection = sortDirection, fetchFilters = filters, resetInitialLoad) => {
    !resetSequences && setLoading(true);

    if (loading) {
      return;
    }

    const params = {
      page       : fetchPage,
      sort_by    : fetchSort,
      sort_order : fetchDirection,
      per_page   : perPage,
    }

    const urlParams = new URLSearchParams(params);

    // urlParams will add param: 'undefined' or 'null' in the string, so make sure
    // to only append if the filter exists:
    fetchFilters && Object.entries(fetchFilters).forEach(([key, value]) => {
      if (value !== undefined) {
        urlParams.append(key, value);
      }
    });

    // Subscribers and ambassadors can only fetch note sequences they've created
    if (!additionalOptions) {
      urlParams.append('creator_id', currentUser.id);
    }

    strofeApi.get(`/note_sequences/?${urlParams.toString()}`).then(response => {

      let newSequences = response.data;

      if (newSequences.length === perPage) {
        setLoadMore(true);
      }
      else {
        setLoadMore(false);
      }

      setPage(fetchPage + 1);
      setLoadingSortChange(false);
      debouncedSortChange.cancel();

      if (resetSequences) {
        setSequences([...newSequences]);
      }
      else {
        // TODO -> this filter only needs to run if a delete was called, it can be optimized:
        newSequences = newSequences.filter(newSequence => !sequences.find(sequence => sequence.id === newSequence.id));
        setSequences([...sequences, ...newSequences]);
      }

      !resetSequences && setLoading(false);
      setFetchMoreSequences(true);

      if (initialLoad === 'initialize' || resetInitialLoad) {
        setInitialLoad('loaded');
      }
    });
  });

  useEffect(() => {
    loadSequences()
  }, [loadSequences]);

  useEffect(() => {
    if (fetchMoreSequences) {
      setFetchMoreSequences(false);
      const rect = skeletonRowRef.current?.getBoundingClientRect();

      if (rect && rect.top < window.innerHeight) {
        loadSequences();
      }
    }
  }, [loadSequences, fetchMoreSequences]);

  const deleteSequence = async () => {
    const id = showDeleteSequenceId;
    const index = sequences.findIndex(sequence => sequence.id === id);

    setShowDeleteSequenceId(null);

    await strofeApi.delete(`/note_sequences/${id}`);

    const params = {
      page       : (page-1) * perPage,
      sort_by    : sortBy,
      sort_order : sortDirection,
      per_page   : 1,
    }
    const urlParams = new URLSearchParams(params);

    // Subscribers and ambassadors can only fetch note sequences they've created
    if (!additionalOptions) {
      urlParams.append('creator_id', currentUser.id);
    }

    const response = await strofeApi.get(`/note_sequences/?${urlParams.toString()}`);
    const responseSequences = response.data || [];

    const newSequences = [...sequences, ...responseSequences];
    newSequences.splice(index, 1);
    setSequences(newSequences);
    setShowDeleteToast(true);
  }

  useEffect(() => {
    if (initialLoad === 'loaded') {
      setInitialLoad(false);

      const callback = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            loadSequences();
          }
        });
      }
  
      // if intersector already exists, disconnect it first:
      observerRef.current?.disconnect();
      
      // the skeleton <tr> rows will not exist if on first load all user songs are loaded:
      if (skeletonRowRef.current) {
        observerRef.current = new IntersectionObserver(callback);
        observerRef.current.observe(skeletonRowRef.current);
      }
    }
  }, [initialLoad, loadSequences]);

  useEffect(() => {
    return () => {
      observerRef.current?.disconnect();
    }
  }, []);

  const updateSort = id => {
    let dir = SORTING_COLUMNS[id].sortDir;

    if (id === sortBy) {
      dir = sortDirection === 'asc' ? 'desc' : 'asc'
      setSortDirection(dir);
    }
    else {
      setSortBy(id);
      setSortDirection(SORTING_COLUMNS[id].sortDir);
    }

    Cookies.set('strofe__sequences_sort_by', id, { expires: 3650 });
    Cookies.set('strofe__sequences_sort_dir', dir, { expires: 3650 });

    loadSequences(true, 1, id, dir);
    debouncedSortChange(true);
  }

  const updateMobileSort = (sortBy, sortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);

    Cookies.set('strofe__sequences_sort_by', sortBy, { expires: 3650 });
    Cookies.set('strofe__sequences_sort_dir', sortDirection, { expires: 3650 });

    loadSequences(true, 1, sortBy, sortDirection);
    debouncedSortChange(true);
  }

  const debouncedSortChange = useDebouncedCallback(value => {
    setLoadingSortChange(value);
  }, 200);

  const sortableColumn = id => {

    let icon = null;

    if (id === 'duration') {
      icon = <DurationIcon className='title' />;
    }

    const phrase = SORTING_COLUMNS[id].phrase;

    return (
      <th className={classNames({ [`column-${id}`]: true })} role='button' onClick={() => updateSort(id)} tabIndex={0}>
        <div className='sortable'>
          { icon || <span className='title'><Translate>{ phrase }</Translate></span> }
          <TriangleUpIcon className={classNames('sort-icon', {desc: sortDirection === 'desc', visible: sortBy === id})} />
        </div>
      </th>
    )
  }

  const handleFilterSongs = (newFilters, resetQuickFilters) => {
    if (newFilters !== null) {
      const { creator_id, status, style, category } = newFilters;

      if (creator_id === undefined && status === undefined && style === undefined && category === undefined) {
        newFilters = null;
      }
    }
    else {
      setQuickFilter(null);
    }

    if (resetQuickFilters) {
      setQuickFilter(null);
    }

    setFilters(newFilters);
    setPage(1);
    setShowFilter(false);
    debouncedSortChange(true);

    loadSequences(true, 1, sortBy, sortDirection, newFilters, true);
  }

  const createNewSequence = async category => {
    const title = 'new-' + category;
    const { data } = await strofeApi.post('/note_sequences/', { note_sequence: { category, title: t(title), program: CATEGORY_PROGRAMS[category] }});
    history.push(`/composer/${data.id}`);
  }

  const handleToggleQuickFilter = key => {
    let newFilters;
    switch(key){
      case 'mine':
        newFilters = { creator_id: currentUser.id };
        setQuickFilter('mine');
        break;

      case 'draft':
        newFilters = { status: 'draft' };
        setQuickFilter('draft');
        break;

      case 'ready':
        newFilters = { status: 'ready' };
        setQuickFilter('ready');
        break;

      case 'review':
        newFilters = { status: 'review' };
        setQuickFilter('review');
        break;

      case 'published':
        newFilters = { status: 'published' };
        setQuickFilter('published');
        break;

      default:
        break;
    }

    handleFilterSongs(newFilters);
  }

  const renderFilterMessage = () => {
    let message = 'Showing filtered results. ';

    if (quickFilter === 'mine') {
      message = 'Showing loops created by you. ';
    }
    else if (quickFilter === 'draft') {
      message = 'Showing loop drafts only. ';
    }
    else if (quickFilter === 'ready') {
      message = 'Showing ready loops only. ';
    }
    else if (quickFilter === 'review') {
      message = 'Showing loops up for review only. ';
    }
    else if (quickFilter === 'published') {
      message = 'Showing published loops only. ';
    }

    return (
      <Translate>
        { message }
        <span role='button' onClick={() => handleFilterSongs(null)}>Remove filter.</span>
      </Translate>
    );
  }

  const renderSkeletonRows = () => {
    const rows = [1,2,3];

    return rows.map(row => (
      <tr key={row} className={classNames('skeleton-row', `skeleton-row-${row}`)} ref={row === 1 ? skeletonRowRef : null}>
        {/*   #   */}<td className='mobile-hidden' />
        {/* Mood  */}<td className='centered'><div className='skeleton-cell skeleton-cell-1' style={{ width: 32, height: 32 }} /></td>
        {/* Title */}<td><div className='skeleton-cell' style={{ width: 128, height: 24 }} /></td>
        {/* Style */}<td className='mobile-hidden centered'><div className='skeleton-cell skeleton-cell-2' style={{ width: 64, height: 24 }} /></td>
        {/* Date  */}<td className='mobile-hidden centered'><div className='skeleton-cell skeleton-cell-3' style={{ width: 48, height: 24 }} /></td>
        {/* Time  */}<td className='mobile-hidden centered'><div className='skeleton-cell skeleton-cell-4' style={{ width: 32, height: 24 }} /></td>
        {/* Fave  */}<td className='desktop-hidden centered'><div className='skeleton-cell skeleton-cell-4' style={{ width: 32, height: 32 }} /></td>
        {/*  ...  */}
      </tr>
    ));
  }

  const renderUserOptions = () => {
    return <>
      <div className='sort-filter desktop-filter'>
        <Button className='draft' variant='dark' onClick={() => handleToggleQuickFilter('draft')} active={quickFilter === 'draft'}>
          <Translate>Draft</Translate>
        </Button>

        <Button className='ready' variant='dark' onClick={() => handleToggleQuickFilter('ready')} active={quickFilter === 'ready'}>
          <Translate>Ready</Translate>
        </Button>

        <Button className='filter' variant='dark' onClick={() => setShowFilter(true)} active={filters !== null && !quickFilter}>
          <Translate>Custom Filter</Translate>
        </Button>

        <Button className='create-sequence' variant='dark' onClick={() => setShowCreateModal(true)}>
          <Translate>Create New Loop</Translate>
        </Button>
      </div>

      <div className='sort-filter mobile-filter'>
        <Button className='filter' variant='dark' onClick={() => setShowFilter(true)}>
          <Translate>Filter</Translate>
        </Button>

        <Dropdown className='sort'>
          <Dropdown.Toggle variant='dark'>
            Sort
          </Dropdown.Toggle>

          <Dropdown.Menu align='right'>
            { MOBILE_SORTS.map((sort, index) => (
              sort.divider
              ? <Dropdown.Divider key={`divider-${index}`} />
              : <Dropdown.Item key={sort.phrase} active={sort.sortBy === sortBy && sort.sortDir === sortDirection} onClick={() => updateMobileSort(sort.sortBy, sort.sortDir)}><Translate>{ sort.phrase }</Translate></Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>;
  }

  const renderSuperUserOptions = () => {
    return <>
      <div className='sort-filter desktop-filter'>
        <Button className='mine' variant='dark' onClick={() => handleToggleQuickFilter('mine')} active={quickFilter === 'mine'}>
          <Translate>Created By Me</Translate>
        </Button>

        <Button className='review' variant='dark' onClick={() => handleToggleQuickFilter('review')} active={quickFilter === 'review'}>
          <Translate>Review</Translate>
        </Button>

        <Button className='published' variant='dark' onClick={() => handleToggleQuickFilter('published')} active={quickFilter === 'published'}>
          <Translate>Published</Translate>
        </Button>

        <Button className='filter' variant='dark' onClick={() => setShowFilter(true)} active={filters !== null && !quickFilter}>
          <Translate>Custom Filter</Translate>
        </Button>

        <Button className='create-sequence' variant='dark' onClick={() => setShowCreateModal(true)}>
          <Translate>Create New Loop</Translate>
        </Button>
      </div>

      <div className='sort-filter mobile-filter'>
        <Button className='filter' variant='dark' onClick={() => setShowFilter(true)}>
          <Translate>Filter</Translate>
        </Button>

        <Dropdown className='sort'>
          <Dropdown.Toggle variant='dark'>
            Sort
          </Dropdown.Toggle>

          <Dropdown.Menu align='right'>
            { MOBILE_SORTS.map((sort, index) => (
              sort.divider
              ? <Dropdown.Divider key={`divider-${index}`} />
              : <Dropdown.Item key={sort.phrase} active={sort.sortBy === sortBy && sort.sortDir === sortDirection} onClick={() => updateMobileSort(sort.sortBy, sort.sortDir)}><Translate>{ sort.phrase }</Translate></Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>;
  }

  return (
    <div className='__song-library'>
      <NavigationHeader />
      <div className='__song-library-container'>
        <div className='header'>
          <div className='title'>
            <InfinityIcon width={36} height={36} /><Translate>Loops</Translate>
          </div>

          { (sequences.length || !loading) && additionalOptions &&
            renderSuperUserOptions()
          }

          { (sequences.length || !loading) && !additionalOptions &&
            renderUserOptions()
          }
          </div>

        { filters !== null && (
          <div className='showing-filters'>
            { renderFilterMessage() }
          </div>
        )}

        { !sequences.length && loading && (
          <div className='loading'>
            <InfiniteLoader width={48} />
            <pre><Translate>Loading Loops...</Translate></pre>
          </div>
        )}

        {/* Used to detect if the mobile sequence list is visible */}
        <div className='mobile-library' ref={mobileLibraryRef} />

        { sequences.length > 0 && (
          <table className='track-view'>
            <thead>
              <tr>
                { sortableColumn('created_at') }
                { sortableColumn('category') }
                { sortableColumn('title') }
                <th className='column-styles'><Translate>Styles</Translate></th>
                {additionalOptions && <th className='column-styles'><Translate>Creator</Translate></th>}
                { sortableColumn('updated_at') }
                { sortableColumn('status') }
                <th className='column-options' />
              </tr>
            </thead>

            <tbody>
              { sequences?.map((sequence, index) => (
              <tr key={sequence.id}>
                <td className='centered cell-playback'>
                  <div className='playback-container'>
                    <div className='track-number'>{ sequence.id }</div>
                  </div>
                </td>
                <td className='centered cell-category'>
                  <Translate>{ CATEGORIES[sequence.category] }</Translate>
                </td>
                <td>
                  <div className='title-container'>
                    <div className='title'>{ sequence.title }</div>
                  </div>
                </td>
                <td className='centered cell-styles'>
                  { sequence.styles.map((style) => (
                    <MoodStyleIcon key={style} genre={style} width={32} height={32} />
                  ))}
                </td>
                {additionalOptions && <td className='centered cell-creator'>{sequence.creator.display_name}</td>}
                <td className='centered cell-modified'>{ DateTime.fromISO(sequence.updated_at).setLocale(t.language).toLocaleString() }</td>
                <td className='centered cell-status'><Translate>{ STATUS_TRANSLATION[sequence.status] || '-' }</Translate></td>
                <td className='centered cell-options'>
                  <Dropdown className='options-menu'>
                    <Dropdown.Toggle variant='link' onDoubleClick={e => e.stopPropagation()} disabled={loading}>
                    ···
                    </Dropdown.Toggle>
                    <Dropdown.Menu align='right'>
                      <Dropdown.Item as={Link} to={`/composer/${sequence.id}`}><Translate>Edit Loop</Translate></Dropdown.Item>
                      <Dropdown.Item onClick={() => setShowDeleteSequenceId(sequence.id)}><Translate>Delete</Translate></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <div className='column-hover' />
              </tr>
              ))}

              { loadMore && renderSkeletonRows() }

            </tbody>

          </table>
        )}

        { !loading && sequences && !sequences?.length && (
          <div className='empty-library'>
            <Translate>{ filters ? "There are no loops that match the current filter." : "Your loop library is empty." }</Translate>
          </div>
        )}

        <DeleteSequenceModal show={showDeleteSequenceId !== null} onClose={() => setShowDeleteSequenceId(null)} onDelete={deleteSequence} />
        <Toast show={showDeleteToast} className='strofe-toast' autohide delay={2000} onClose={() => setShowDeleteToast(false)}>
          <Toast.Body><Translate>Loop deleted</Translate></Toast.Body>
        </Toast>

        <Toast show={loadingSortChange} className='strofe-toast'>
          <Toast.Body><Translate>Loading...</Translate></Toast.Body>
        </Toast>

        <FilterModal additionalOptions={additionalOptions} show={showFilter} onHide={() => setShowFilter(false)} onSubmit={handleFilterSongs} filters={filters} />
        <InstrumentCategoryModal show={showCreateModal} onHide={() => setShowCreateModal(false)} onSubmit={createNewSequence} />
      </div>
    </div>
  )
}

import { useEffect } from "react";
import { useState } from "react";
import { strofeApi } from "../../api/strofeApi";
import { useDebouncedCallback } from "use-debounce/lib";

import MoodStyleIcon from '../SongPlayer/MoodStyleIcon';

import NavigationHeader from "../NavigationHeader";
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";

import './NoteSequenceStats.scss';

export default function NoteSequenceStats() {
  const [stats, setStats] = useState(null);
  const [displayedDynamics, setDisplayedDynamics] = useState(null);
  const [loading, setLoading] = useState(false);

  const debouncedLoading = useDebouncedCallback(value => {
    setLoading(value);
  }, 200);

  useEffect(() => {
    const loadStats = async () => {
      debouncedLoading(true);
      const { data } = await strofeApi.get(`/stats/note_sequences`);
      setStats(data);
      initDisplayedDynamics(data);
      debouncedLoading.cancel();
      setLoading(false);
    }

    loadStats();
  }, [debouncedLoading]);

  const initDisplayedDynamics = (data) => {
    let categories = {};

    Object.keys(data).forEach(style => {
      categories[style] = {};
      Object.keys(data[style]).forEach(category => {
        categories[style][category] = false;
      });
    });

    setDisplayedDynamics(categories);
  }

  const toggleCategory = (style, category) => {
    let newCategories = {...displayedDynamics};
    newCategories[style][category] = !newCategories[style][category];
    setDisplayedDynamics(newCategories);
  }

  const renderStats = () => {
    if (stats === null || displayedDynamics === null) {
      return null;
    }

    return <>
      {Object.keys(stats).map(style => {
        return <div className='style'>
          <div className='style-header'>
            <div>{style}</div>
            <div><MoodStyleIcon genre={style} width={36} height={36} /></div>
          </div>

          {Object.keys(stats[style]).map(category => {
            return <div className='category'>
              <div className='category-header' onClick={() => toggleCategory(style, category)}>
                <div>{category}</div>
                <div>{stats[style][category]['total']}</div>
              </div>
              {displayedDynamics[style][category] &&
                <div className='dynamics'>
                  { stats[style][category]['dynamics'].map((total, dynamic) => (
                    <div className='dynamic'>
                      <div>{dynamic}</div>
                      <div>{total}</div>
                    </div>
                  ))}
                </div>
              }
            </div>
          })}
        </div>
      })}
    </>;
  }

  return (
    <div className='__note-sequence-stats'>
      <NavigationHeader />
      <h1 className='header'>Loop Stats</h1>

      { loading && (
        <div className='loading'>
          <InfiniteLoader width={100} />
        </div>
      )}

      { !loading && renderStats() }
    </div>
  );
}
import * as React from "react";

const InstrumentCelesta = (props) => (
  <svg
    id="InstrumentCelesta_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentCelesta_svg__st6{fill:#ca5672}.InstrumentCelesta_svg__st28{fill:#fff}.InstrumentCelesta_svg__st35{fill:#1f3138}"
      }
    </style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#cfd125",
      }}
    />
    <path
      d="M101.13 59.81H11c-.71 0-1.29-.58-1.29-1.29v-13.4c0-.71.58-1.29 1.29-1.29h90.12c.71 0 1.29.58 1.29 1.29v13.41c0 .71-.57 1.28-1.28 1.28z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      className="InstrumentCelesta_svg__st6"
      d="M97.2 43.83H15.14V32.87c0-.99.81-1.8 1.8-1.8H95.4c.99 0 1.8.81 1.8 1.8v10.96z"
    />
    <path
      className="InstrumentCelesta_svg__st28"
      d="M19.41 43.83h6.61v5.97h-6.61zM26.56 43.83h5.97v5.97h-5.97zM33.07 43.83h6.08v5.97h-6.08zM39.69 43.83h6.02v5.97h-6.02zM46.25 43.83h6.08v5.97h-6.08zM52.87 43.83h5.97v5.97h-5.97zM59.39 43.83h6.05v5.97h-6.05zM65.98 43.83h6.14v5.97h-6.14zM72.66 43.83h6.15v5.97h-6.15zM79.35 43.83h6.07v5.97h-6.07zM85.96 43.83h6.14v5.97h-6.14z"
    />
    <path
      className="InstrumentCelesta_svg__st6"
      d="M15.38 59.81h82.06v20.61H15.38z"
    />
    <path
      className="InstrumentCelesta_svg__st35"
      d="M23.89 40.35h4.82v3.49h-4.82zM30.12 40.35h4.82v3.49h-4.82zM69.5 40.35h4.82v3.49H69.5zM75.94 40.35h4.82v3.49h-4.82zM82.38 40.35h4.82v3.49h-4.82zM44.22 40.35h4.82v3.49h-4.82zM50.6 40.35h4.82v3.49H50.6zM56.98 40.35h4.82v3.49h-4.82z"
    />
    <path
      className="InstrumentCelesta_svg__st6"
      d="m95.78 20.05-79.74 7.56c-.54.05-.94.53-.89 1.07l.12 1.23c.05.54.53.94 1.07.89l79.74-7.56c.54-.05.94-.53.89-1.07l-.12-1.23a.986.986 0 0 0-1.07-.89z"
    />
  </svg>
);

export default InstrumentCelesta;

import * as React from "react";

const InstrumentFlute = (props) => (
  <svg
    id="InstrumentFlute_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 109.83 110.34"
    style={{
      enableBackground: "new 0 0 109.83 110.34",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentFlute_svg__st3{fill:#4e4092}.InstrumentFlute_svg__st90{fill:#fdcc75;stroke:#fdcc75;stroke-width:.75;stroke-miterlimit:10}"
      }
    </style>
    
    <path
      d="M93.54 110.1H16.56C7.41 110.1 0 102.69 0 93.54V16.56C0 7.41 7.41 0 16.56 0h76.98c9.15 0 16.56 7.41 16.56 16.56v76.98c0 9.15-7.41 16.56-16.56 16.56z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      d="m8.52 95.06-.42-.45c-.49-.54-.46-1.37.08-1.86L95.7 12.6c.54-.49 1.37-.46 1.86.08l.41.45c.49.54.46 1.37-.08 1.86L10.38 95.14c-.54.5-1.37.46-1.86-.08z"
      style={{
        fill: "#cfd125",
        stroke: "#cfd125",
        strokeWidth: 3,
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentFlute_svg__st3"
      d="m87.01 23.46-.73-.8a.88.88 0 0 1 .05-1.23l1.55-1.42c.35-.32.9-.3 1.23.05l.73.8c.32.35.3.9-.05 1.23l-1.55 1.42c-.36.32-.91.3-1.23-.05z"
    />
    <circle
      className="InstrumentFlute_svg__st3"
      cx={57.4}
      cy={49.94}
      r={1.07}
    />
    <circle
      className="InstrumentFlute_svg__st3"
      cx={53.24}
      cy={53.85}
      r={1.07}
    />
    <circle
      className="InstrumentFlute_svg__st3"
      cx={50.37}
      cy={56.52}
      r={1.07}
    />
    <circle
      className="InstrumentFlute_svg__st90"
      cx={49.11}
      cy={65.91}
      r={1.07}
    />
    <circle
      className="InstrumentFlute_svg__st3"
      cx={41.58}
      cy={64.64}
      r={1.07}
    />
    <circle
      className="InstrumentFlute_svg__st3"
      cx={38.27}
      cy={67.36}
      r={1.07}
    />
    <circle
      className="InstrumentFlute_svg__st3"
      cx={31.26}
      cy={74.04}
      r={1.07}
    />
    <circle
      className="InstrumentFlute_svg__st3"
      cx={27.21}
      cy={77.54}
      r={1.07}
    />
    <circle
      className="InstrumentFlute_svg__st90"
      cx={27.21}
      cy={85.2}
      r={1.07}
    />
    <circle
      className="InstrumentFlute_svg__st3"
      cx={34.94}
      cy={70.58}
      r={1.07}
    />
    <path
      d="M21.99 73.63a.629.629 0 0 1 .04-.88l35.94-32.91c.25-.23.64-.21.88.04.23.25.21.64-.04.88L22.86 73.67c-.25.23-.64.21-.87-.04z"
      style={{
        fill: "#4e4092",
        stroke: "#4e4092",
        strokeWidth: 0.75,
        strokeMiterlimit: 10,
      }}
    />
  </svg>
);

export default InstrumentFlute;

import * as React from "react";

const InstrumentGuitarFrets = (props) => (
  <svg
    id="InstrumentGuitarFrets_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentGuitarFrets_svg__st5{fill:#cfd125}.InstrumentGuitarFrets_svg__st86,.InstrumentGuitarFrets_svg__st87{fill:none;stroke:#fff;stroke-width:.75;stroke-miterlimit:10}.InstrumentGuitarFrets_svg__st87{stroke-width:4.3464;stroke-linecap:round;stroke-linejoin:round}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentGuitarFrets_svg__st86"
      d="M0 90.17h110.84M0 75.56h110.84M0 60.95h110.84M0 46.35h110.84M0 31.74h110.84M0 17.13h110.84"
    />
    <path
      className="InstrumentGuitarFrets_svg__st87"
      d="M98.76 10.64v89.79M70.43 10.64v89.79M42.1 10.64v89.79M13.77 10.64v89.79"
    />
    <circle
      className="InstrumentGuitarFrets_svg__st5"
      cx={85.02}
      cy={38.94}
      r={4.52}
    />
    <circle
      className="InstrumentGuitarFrets_svg__st5"
      cx={85.02}
      cy={68.09}
      r={4.52}
    />
    <circle
      className="InstrumentGuitarFrets_svg__st5"
      cx={27.33}
      cy={53.32}
      r={4.52}
    />
  </svg>
);

export default InstrumentGuitarFrets;

import * as React from "react";

const InstrumentSteelGuitar = (props) => (
  <svg
    id="InstrumentSteelGuitar_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.08 110.88"
    style={{
      enableBackground: "new 0 0 110.08 110.88",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentSteelGuitar_svg__st10{fill:#538553}.InstrumentSteelGuitar_svg__st14{fill:none;stroke:#4e4092;stroke-width:1.0143;stroke-linecap:round;stroke-miterlimit:10}"
      }
    </style>
    
    <path
      d="M94.17.04h-77.5C7.46.04 0 7.51 0 16.72v77.49c0 9.21 7.46 16.67 16.67 16.67h77.49c9.21 0 16.67-7.46 16.67-16.67V16.72C110.84 7.51 103.38.04 94.17.04z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      d="M52.7 93.89c3.6-4.23 4.32-9.39 4.39-14.72.05-3.46.81-4.53 4.02-5.74.8-.3 1.61-.59 2.38-.97 6.75-3.35 8.23-10.12 3.5-16.02-1-1.24-2.03-2.46-3.14-3.78 7.8-8.51 16.29-17.59 24.08-26.02 1.14.91 2.82.82 3.84-.26l5.14-5.4a2.847 2.847 0 0 0-.1-4.03l-3.36-3.2a2.847 2.847 0 0 0-4.03.1l-5.14 5.4a2.845 2.845 0 0 0 .01 3.93c-3.65 3.79-11.37 11.67-13.99 14.37-3.57 3.68-7.07 7.42-10.62 11.15-1.62-1.23-2.82-2.18-4.06-3.08-6.28-4.55-13.36-2.87-15.79 4.44-.73 2.19-1.81 4.46-3.83 5.73-1.11.69-2.38.89-3.67.95-1.35.07-2.63.2-3.96.44-14.18 2.56-17.69 16.1-13.82 25.46 3.24 7.83 8.95 13.35 16.85 16.38 7.87 3.02 16.13.95 21.3-5.13z"
      style={{
        fill: "#f18860",
      }}
    />
    <circle
      className="InstrumentSteelGuitar_svg__st10"
      cx={78.45}
      cy={19.74}
      r={2.07}
    />
    <circle
      className="InstrumentSteelGuitar_svg__st10"
      cx={96.52}
      cy={28.28}
      r={2.26}
    />
    <path
      d="M50.14 73.05a7.793 7.793 0 0 0 0 0z"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M55.71 66.92s-.06.04-.16.1c-.1.53-.26 1.05-.46 1.54a7.883 7.883 0 0 1-4.95 4.49c-.58.18-1.08.28-1.59.31-.56.05-1.12.04-1.67-.02h-.01c-.03 0-.06 0-.11-.02l-4.95 5.13s-.73.4 3.6 4.24c3.69 3.27 6.14-.28 6.74-3.93.49-2.96.96-7.21 4.41-8.16.47-.13.96-.19 1.4-.4 2.59-1.27-1.85-3.55-2.25-3.28z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      d="M55.36 63.18c-1.28-4.17-5.7-6.5-9.87-5.22s-6.5 5.7-5.22 9.87c.87 2.83 3.19 4.79 5.89 5.37.23.05.46.11.7.14h.01c.55.07 1.11.07 1.67.02.53-.05 1.06-.15 1.59-.31a7.883 7.883 0 0 0 4.95-4.49c.21-.49.36-1.01.46-1.54a7.62 7.62 0 0 0-.18-3.84z"
      style={{
        fill: "#fdcc75",
      }}
    />
    <path
      className="InstrumentSteelGuitar_svg__st14"
      d="M46.39 61.04 22.78 84.89M48.31 62.9 24.94 86.8M50.37 64.91l-23.6 23.85M52.44 66.92l-23.6 23.85"
    />
  </svg>
);

export default InstrumentSteelGuitar;

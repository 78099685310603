export default function SlideProductEdit(props) {
  return (
    <svg
      viewBox="0 0 103.7 104.4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <style>
        {
          ".__strofe_slide_edit_0{fill:#538453;}.__strofe_slide_edit_1{fill:#CFCF25;}.__strofe_slide_edit_2{fill:none;stroke:#FFFFFF;stroke-width:6.4656;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.__strofe_slide_edit_3{fill:#FFFFFF;}.__strofe_slide_edit_4{fill:none;stroke:#FFFFFF;stroke-width:5.5419;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.__strofe_slide_edit_5{fill:#4E4092;}.__strofe_slide_edit_6{fill:#F18660;}.__strofe_slide_edit_7{fill:#CA5572;}.__strofe_slide_edit_8{fill:#FCCB75;}.__strofe_slide_edit_9{fill:none;stroke:#F18660;stroke-width:1.3429;stroke-linecap:round;stroke-miterlimit:10;}.__strofe_slide_edit_10{fill:#F7F4E9;}.__strofe_slide_edit_11{fill:#CFCE26;}.__strofe_slide_edit_12{fill:#F7F4E9;stroke:#4E4092;stroke-width:0.7088;stroke-miterlimit:10;}.__strofe_slide_edit_13{fill:#F7F4E9;stroke:#4E4092;stroke-width:0.7294;stroke-miterlimit:10;}"
        }
      </style>
      <g>
        <path className="__strofe_slide_edit_11" d="M27.5,69.3H27c-1.7,0-3-1.3-3-3V36.2c0-1.7,1.3-3,3-3h0.5c1.7,0,3,1.3,3,3v30.1C30.5,68,29.2,69.3,27.5,69.3z
          "/>
        <circle className="__strofe_slide_edit_12" cx="27.3" cy="37.7" r="5.1"/>
      </g>
      <g>
        <path className="__strofe_slide_edit_3" d="M51.7,68.8h-0.5c-1.7,0-3-1.3-3-3V35.7c0-1.7,1.3-3,3-3h0.5c1.7,0,3,1.3,3,3v30.1
          C54.7,67.5,53.3,68.8,51.7,68.8z"/>
        <circle className="__strofe_slide_edit_12" cx="51.4" cy="64" r="5.1"/>
      </g>
      <g>
        <g>
          <path className="__strofe_slide_edit_3" d="M79,35.9c0-1.7-1.3-3-3-3h-0.5c-1.7,0-3,1.3-3,3v6.6H79V35.9z"/>
          <path className="__strofe_slide_edit_11" d="M72.5,42.5V66c0,1.7,1.3,3,3,3H76c1.7,0,3-1.3,3-3V42.5H72.5z"/>
        </g>
        <circle className="__strofe_slide_edit_13" cx="75.7" cy="46" r="5.3"/>
      </g>
    </svg>
  );
}

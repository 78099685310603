export const DRUMS = {
  'laser'                : 27,
  'whip'                 : 28,
  'scratch_push'         : 29,
  'scratch_pull'         : 30,
  'sticks'               : 31,
  'square_click'         : 32,
  'metronome_click'      : 33,
  'metronome_bell'       : 34,
  'bass_drum'            : 35,
  'bass_drum_2'          : 36,
  'cross_stick'          : 37,
  'snare'                : 38,
  'clap_hand'            : 39,
  'snare_2'              : 40,
  'tom_low_2'            : 41,
  'hi_hat'               : 42,
  'tom_low_1'            : 43,
  'hi_hat_pedal'         : 44,
  'tom_mid_2'            : 45,
  'hi_hat_open'          : 46,
  'tom_mid_1'            : 47,
  'tom_high_2'           : 48,
  'crash'                : 49,
  'tom_high_1'           : 50,
  'ride'                 : 51,
  'cymbal_chinese'       : 52,
  'cymbal_ride_bell'     : 53,
  'tambourine'           : 54,
  'cymbal_splash'        : 55,
  'cow_bell'             : 56,
  'cymbal_crash_2'       : 57,
  'vibra_slap'           : 58,
  'cymbal_ride_2'        : 59,
  'bongo_high'           : 60,
  'bongo_low'            : 61,
  'conga_high_1_mute'    : 62,
  'conga_high_2_open'    : 63,
  'conga_low'            : 64,
  'timbale_high'         : 65,
  'timbale_low'          : 66,
  'agogo_high'           : 67,
  'agogo_low'            : 68,
  'cabasa'               : 69,
  'maracas'              : 70,
  'whistle_1_high_short' : 71,
  'whistle_2_low_long'   : 72,
  'guiro_1_short'        : 73,
  'guiro_2_long'         : 74,
  'claves'               : 75,
  'wood_block_1_high'    : 76,
  'wood_block_2_low'     : 77,
  'cuica_1_mute'         : 78,
  'cuica_2_open'         : 79,
  'triangle_1_mute'      : 80,
  'triangle_2_open'      : 81,
  'shaker'               : 82,
  'belltree'             : 84,
  'castanets'            : 85,
  'surdu_dead_stroke'    : 86,
  'surdu'                : 87
}

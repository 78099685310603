export default function BossaIcon(props) {
  return (
    <svg
      id="__bosa-icon-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110.84 112.61"
      {...props}
    >
      <defs>
        <style>
          {
            ".__bosa-icon-prefix__cls-3{fill:#c95671}.__bosa-icon-prefix__cls-5{fill:#fff}.__bosa-icon-prefix__cls-6{fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.77px}"
          }
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 40"}</title>
      <rect
        y={1.77}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
        fill="#f18860"
      />
      <path
        d="M86.1 72c-2.71-4.26-7-6.25-11.59-7.74-3-1-3.71-1.92-3.91-5 0-.78-.08-1.56-.21-2.32-1.1-6.75-6.58-9.84-13-7.32-1.35.53-2.67 1.11-4.12 1.71-5.29-9-10.9-18.83-16.13-27.84a2.59 2.59 0 00.8-3.4l-3.31-5.9a2.59 2.59 0 00-3.52-1l-3.67 2.06a2.59 2.59 0 00-1 3.52l3.31 5.9a2.59 2.59 0 003.41 1c2.31 4.18 7.08 13 8.72 16 2.24 4.08 4.54 8.12 6.83 12.19-1.5 1.08-2.65 1.86-3.76 2.7-5.56 4.29-5.95 10.88-.3 14.94 3.83 2.73 5.08 5.5 3.42 9.77a8.64 8.64 0 00-.31 2.07C46.18 96.32 57 103 66.14 102.12a26.11 26.11 0 0018.71-10.23A16.93 16.93 0 0086.1 72z"
        fill="#fdcb75"
      />
      <circle
        className="__bosa-icon-prefix__cls-3"
        cx={24.56}
        cy={23.78}
        r={2.05}
        transform="rotate(-29.3 24.555 23.788)"
      />
      <circle
        className="__bosa-icon-prefix__cls-3"
        cx={39.8}
        cy={15.23}
        r={2.05}
        transform="rotate(-29.3 39.809 15.229)"
      />
      <circle cx={60.18} cy={68.69} r={7.16} fill="#4d3f92" />
      <circle className="__bosa-icon-prefix__cls-5" cx={66.99} cy={52.19} r={6.43} />
      <circle className="__bosa-icon-prefix__cls-5" cx={80.68} cy={53.41} r={2.36} />
      <circle className="__bosa-icon-prefix__cls-5" cx={78.32} cy={40.24} r={1.64} />
      <circle className="__bosa-icon-prefix__cls-5" cx={89.52} cy={59.18} r={1.64} />
      <circle className="__bosa-icon-prefix__cls-5" cx={73.42} cy={64.11} r={3.97} />
      <path
        className="__bosa-icon-prefix__cls-6"
        d="M55.34 70.05l15.36 26.3M59.48 67.41l15.45 26.1M63.95 64.57l15.36 26.29"
      />
    </svg>
  )
}

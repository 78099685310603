export default function RerollIcon(props) {
  return (
    <svg x={0} y={0} viewBox="0 0 52.4 40.8" xmlSpace="preserve" {...props}>
      <style>{"._reroll-icon-st0{fill:#f18860}._reroll-icon-st1{fill:#ced025}"}</style>
      <path
        className="_reroll-icon-st0"
        d="M12.2 21.5l4.1-14.3-15 7.1 1.2 18.1 16.3 8.2-1.1-13.8-5.5-5.3zm-6.9-2.6c1 .4 1.8 1.5 1.9 2.5 0 1-.7 1.4-1.7 1-1-.4-1.8-1.5-1.9-2.5-.1-.9.7-1.3 1.7-1zm.1 4.6c1 .4 1.8 1.5 1.8 2.5s-.7 1.4-1.7 1c-1-.4-1.8-1.5-1.8-2.5 0-.9.8-1.4 1.7-1zm.3 8.1c-1-.4-1.8-1.5-1.8-2.5s.7-1.4 1.7-1c1 .4 1.8 1.5 1.8 2.5s-.7 1.4-1.7 1zm8.6-4.5c1 .4 1.8 1.5 1.8 2.5s-.8 1.5-1.8 1.1c-1-.4-1.8-1.5-1.8-2.5.1-1 .8-1.5 1.8-1.1zm.1 8.2c-1-.4-1.8-1.5-1.8-2.5s.8-1.5 1.8-1c1 .4 1.8 1.5 1.8 2.5s-.8 1.4-1.8 1zM9.5 13c.7-.5 1.9-.6 2.8-.2.9.4 1.1 1.1.4 1.6-.7.5-1.9.6-2.8.2-.9-.3-1.1-1.1-.4-1.6zM19.6 40.5l6.4-4.7-7.4-8 1 12.7z"
      />
      <g>
        <path
          className="_reroll-icon-st1"
          d="M18.7 2.6l-5.5 18.5L27 34.9 32.4 16l18.8-4.1L37.3.4 18.7 2.6zm-.5 18.7c-.3 1-1.1 1.4-1.8.9-.7-.6-1-1.8-.7-2.9.3-1 1.1-1.4 1.8-.9.6.6 1 1.9.7 2.9zm1.5-9.3c-.7-.5-1.1-1.8-.8-2.8.3-1 1.1-1.4 1.9-.9.7.5 1.1 1.8.8 2.8-.3 1-1.1 1.4-1.9.9zm2.3 8.4c-.7-.5-1-1.8-.7-2.9.3-1 1.1-1.4 1.9-.9.7.5 1.1 1.8.7 2.9-.4 1.1-1.2 1.5-1.9.9zm3.5 8.1c-.3 1.1-1.1 1.5-1.9.9-.7-.6-1-1.9-.7-2.9.3-1.1 1.1-1.5 1.9-.9s1.1 1.8.7 2.9zm4.2-10.9c-.3 1.1-1.2 1.5-1.9 1s-1.1-1.8-.7-2.9c.3-1.1 1.2-1.5 1.9-1 .6.6 1 1.9.7 2.9zM28.2 4.9c.9-.1 2.2.3 2.9 1 .7.6.5 1.2-.4 1.4-.9.1-2.2-.3-2.9-1-.7-.7-.5-1.3.4-1.4zm10.6 3.3c.9-.1 2.2.3 2.9.9.7.6.5 1.2-.4 1.4-.9.1-2.2-.3-2.9-.9-.7-.7-.5-1.3.4-1.4z"
        />
        <path
          className="_reroll-icon-st1"
          d="M33.2 17L28 35.4l18.1-4.3 5.4-18.2L33.2 17zm2.6 4.8c-.9.3-1.4-.6-1.1-1.8s1.3-2.5 2.2-2.7c.9-.2 1.4.6 1.1 1.8-.3 1.2-1.3 2.4-2.2 2.7zm3 4.5c-.9.3-1.4-.5-1.1-1.8s1.2-2.5 2.2-2.7c.9-.3 1.4.5 1.1 1.8s-1.3 2.4-2.2 2.7zm5.4 1.9c-.2 1.3-1.2 2.5-2.1 2.8-.9.3-1.4-.5-1.2-1.7.3-1.2 1.2-2.4 2.2-2.7.9-.3 1.4.4 1.1 1.6z"
        />
      </g>
    </svg>
  )
}

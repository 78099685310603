export default function NotesWhiteIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51.72 51.72"
      {...props}
    >
      <path
        d="M23.46 10v29.25c-5.46.36-9.93 3.29-11.46 7.43-1 2.72.08 4.8 2.84 5 5.6.44 12.48-5.52 12.22-10.5V13.26l18.25-6.91v24.78c-5.42.36-9.93 3.29-11.47 7.43-1 2.72.08 4.8 2.84 5 5.6.44 12.48-5.52 12.22-10.5V0z"
      />
      <circle cx={10.91} cy={30.69} r={5.71} />
      <circle cx={17.46} cy={18.6} r={2.86} />
    </svg>
  )
}

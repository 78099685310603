import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './TestResultsModal.scss';
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";
import classNames from "classnames";
import { strofeApi } from "../../api/strofeApi";
import { useEffect } from "react";

export default function TestResultsModal({ show, onHide, activeTest, onEdit, onCreate }) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);
  const [revision, setRevision] = useState(activeTest.revision);

  useEffect(() => {
    const loadTest = async (revision_param) => {
      let url = `/tests/${activeTest.id}`;
      if ( revision_param !== '-1' ){
        url += `?revision=${revision_param}`;
      }
      const { data } = await strofeApi.get(url);
      setResults(data.results);
      setLoading(false);
    }

    loadTest(revision);
  }, [revision, activeTest]);

  const updateRevision = e => {
    setLoading(true);
    setRevision(e.target.value);
  }

  let revisions = [];
  for (let i = 0; i <= activeTest.revision; i++){
    revisions.push(i);
  }
  console.log(results)

  return (
    <Modal className='__modal __test-modal' show={show} backdrop='static' size='lg'>
      <Modal.Header>
        {activeTest.title} Results
      </Modal.Header>
      <Modal.Body>
        <div className='filter'>
          <div>Revision</div>
          <Form.Control className='revision' as='select' value={revision} onChange={updateRevision}>
            <option key={-1} value={-1}>All</option>
            { revisions.map((rev) => (
              <option key={rev} value={rev}>{rev}</option>
            ))}
          </Form.Control>
        </div>
        {loading && 
          <div className='loading'>
            <InfiniteLoader width={48} />
            <p>Computing test results...</p>
          </div>
        }
        {!loading &&
          <>
            <table className='event-results'>
              <thead>
                <tr>
                  <th></th>
                  { results.all_variants.map((variant) => (
                    <th key={variant}>{variant}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Users</td>
                  { results.all_variants.map((variant) => (
                    <td key={variant}>{results.user_results[variant]}</td>
                  ))}
                </tr>
                <tr>
                  <th colspan="100%">Events</th>
                </tr>
                { results.all_events.map((event) => (
                  <tr>
                    <td className='event-name'>{event}</td>
                    { results.all_variants.map((variant) => (
                      <td key={`${variant}-${event}`}>
                        <div>
                          {results.event_results[variant][event]['total']}
                        </div>
                        { variant === 'control' && <div><b>{Math.round(results.event_results[variant][event]['rate'] * 10000) / 100}%</b></div> }
                        { variant !== 'control' &&
                          <div className={classNames({positive: results.event_results[variant][event]['rate'] > results.event_results['control'][event]['rate']}, {negative: results.event_results[variant][event]['rate'] < results.event_results['control'][event]['rate']})}>
                            <b>{Math.round(results.event_results[variant][event]['rate'] * 10000) / 100}%</b>
                          </div>
                        }
                        <div>
                          { variant === 'control' && "N/A" }
                          { variant !== 'control' && <>{Math.round(results.event_results[variant][event]['stat_sig'] * 10000) / 100}%</> }
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

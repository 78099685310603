import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Trans as Translate } from 'react-i18next';
import Cookies from 'js-cookie';

import { usersActions } from '../../store/usersSlice';

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import LoadingOverlay from '../../modals/LoadingOverlay';

export default function VerifyEmailModal({ show, onHide, onUpdateSuccess }) {

  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [sendingVerification, setSendingVerification] = useState(false);

  const dispatch = useDispatch();

  const sendVerification = useCallback(() => {
    setSendingVerification(true);
    setError(false);
    setVerificationCode('');

    const send = async () => { 
      try {
        const action = await dispatch(usersActions.sendEmailVerification());
        unwrapResult(action);
        setSendingVerification(false);
      }
      catch {
        console.log("500 or wrong token error"); // edge case, <ErrorModal is a good choice />
      }
    }

    send();

  }, [dispatch]);

  
  useEffect(() => {
    if (show) {
      sendVerification();
    }
  }, [show, sendVerification]);

  const onVerify = async e => {
    e.preventDefault();
    
    const code = verificationCode.trim().replace(" ", "");
    setVerificationCode(code);

    const verificationRegex = /^\d{6}$/;

    if (!verificationRegex.test(code)) {
      setError('email-verification-regex-error');
      return;
    }

    setError(false);
    setVerifying(true);

    try {
      const action = await dispatch(usersActions.verifyEmail({ code }));
      unwrapResult(action);

      // refetch user to see if coins need to be granted:
      dispatch(usersActions.fetchById({ id: Cookies.get('strofe_user_id') }));

      onUpdateSuccess();
      onHide();
    }
    catch (error) {
      // 401 -> unauthorized (wrong code or expired code)
      // 403 -> is forbidden (IE: access token is invalid)
      // 500 -> server exploded, should show bigger error
      setError('email-verification-request-error');
    }
    finally {
      setVerifying(false);
    }
  }

  const onChangeVerificationCode = e => {
    setVerificationCode(e.target.value);
    setError(false);
  }

  return (
    <Modal className='__modal __verify-email-modal' show={show} backdrop='static' size='sm'>

      <LoadingOverlay show={sendingVerification} phrase='Sending verification code...' />
      
      <Modal.Header>
        <Translate>Verify Your Email</Translate>
      </Modal.Header>

      <Form onSubmit={onVerify}>
        <Modal.Body>
          <p>Enter the verification code you received in your email:</p>

          <Form.Control className='my-2' disabled={verifying} value={verificationCode} onChange={onChangeVerificationCode} />

          { error && (
            <Alert variant='warning'>
              <Translate i18nKey={error} />
            </Alert>
          )}

          { verifying && <p><Translate>Verifying...</Translate></p> }

          <Button onClick={sendVerification} variant='link'><Translate>Re-send Verification Code</Translate></Button>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide} disabled={verifying}><Translate>Close</Translate></Button>
          <Button variant="primary" type="submit" disabled={!verificationCode.trim().length || verifying}><Translate>Verify</Translate></Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
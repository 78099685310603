export default function RetroFuturisticOutline(props) {
  return (
    <svg
      id="__retro-futuristic-outline-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 114.68 113.56"
      {...props}
    >
      <defs>
        <style>
          {
            ".__retro-futuristic-outline-prefix__cls-1{fill:none;stroke:#cfd4d8;stroke-miterlimit:10;stroke-width:2px}"
          }
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 29 copy"}</title>
      <rect
        className="__retro-futuristic-outline-prefix__cls-1"
        x={2.02}
        y={1.48}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
      />
      <path
        className="__retro-futuristic-outline-prefix__cls-1"
        d="M64.95 79s-1.06 5.91 3 10.25c0 0 .11-3.46 2.44-3.66 0 0 .44 7.85 4.05 9.55 0 0-.1-2.38 1.48-3.46 0 0 5.27 7.66 11.28 10.65C86.59 95.67 81.4 88 81.4 88c1.58-1.07 3.76-.1 3.76-.1-.25-4-7.39-7.28-7.39-7.28 1-2.09 4.3-.92 4.3-.92-2.56-5.34-8.44-6.53-8.44-6.53"
      />
      <path
        className="__retro-futuristic-outline-prefix__cls-1"
        transform="rotate(-34.22 69.294 76.081)"
        d="M60.24 74.46h18.09v3.23H60.24z"
      />
      <path
        className="__retro-futuristic-outline-prefix__cls-1"
        d="M27.42 14.69S3.94 50.59 60.16 81.88l8.85-6 8.85-6C69.43 6 27.42 14.69 27.42 14.69z"
      />
      <circle
        cx={44.9}
        cy={40.39}
        r={8.95}
        transform="rotate(-34.22 44.896 40.387)"
        fill="#cfd4d8"
      />
      <path
        className="__retro-futuristic-outline-prefix__cls-1"
        d="M59.47 61.82c-6.76 4.6-13.47 7.33-17.69 7.48a110.07 110.07 0 0018.38 12.58l8.85-6 8.85-6a110.08 110.08 0 00-4.94-21.72c-1.68 3.84-6.69 9.06-13.45 13.66zM49.07 18.14a37.69 37.69 0 00-5.2-2.07 35.9 35.9 0 00-16.45-1.37 35.9 35.9 0 00-4.77 15.8 37.69 37.69 0 000 5.6 47.78 47.78 0 0014.54-7 47.78 47.78 0 0011.88-10.96z"
      />
      <path
        className="__retro-futuristic-outline-prefix__cls-1"
        d="M35.82 63.59l2 14.22 22.27 16.78-3.64-14.86M70.75 42.61l10.27 5.83 7.42 26.87-10.88-7.71"
      />
    </svg>
  )
}

const BOSSA_DRUMS = [
  // Basic Bossa on Ride
  {
    totalTime: 4,
    notes: [
      { pitch: 51, startTime: 0.0, endTime: 0.0, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 0.25, endTime: 0.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 0.5, endTime: 0.5, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 0.75, endTime: 0.75, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 1.0, endTime: 1.0, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.25, endTime: 1.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 1.5, endTime: 1.5, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.75, endTime: 1.75, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 2.0, endTime: 2.0, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.25, endTime: 2.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 2.5, endTime: 2.5, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.75, endTime: 2.75, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 3.0, endTime: 3.0, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 3.25, endTime: 3.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 3.5, endTime: 3.5, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 3.75, endTime: 3.75, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.75, endTime: 0.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.75, endTime: 1.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 2.75, endTime: 2.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 3.75, endTime: 3.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 0.5, endTime: 0.5, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 1.25, endTime: 1.25, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 2.0, endTime: 2.0, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 2.75, endTime: 2.75, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 3.5, endTime: 3.5, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
  // Percussion filled Bossa
  {
    totalTime: 4,
    notes: [
      { pitch: 69, startTime: 0.0, endTime: 0.0, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 0.25, endTime: 0.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 0.5, endTime: 0.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 0.75, endTime: 0.75, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 1.0, endTime: 1.0, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.25, endTime: 1.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 1.5, endTime: 1.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.75, endTime: 1.75, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 2.0, endTime: 2.0, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 2.25, endTime: 2.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 2.5, endTime: 2.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 2.75, endTime: 2.75, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 3.0, endTime: 3.0, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 3.25, endTime: 3.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 3.5, endTime: 3.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 3.75, endTime: 3.75, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 80, startTime: 0.25, endTime: 0.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 81, startTime: 0.5, endTime: 0.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 80, startTime: 0.75, endTime: 0.75, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 80, startTime: 1.25, endTime: 1.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 81, startTime: 1.5, endTime: 1.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 80, startTime: 1.75, endTime: 1.75, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 80, startTime: 2.25, endTime: 2.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 81, startTime: 2.5, endTime: 2.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 80, startTime: 2.75, endTime: 2.75, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 80, startTime: 3.25, endTime: 3.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 81, startTime: 3.5, endTime: 3.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 80, startTime: 3.75, endTime: 3.75, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.75, endTime: 0.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.75, endTime: 1.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 2.75, endTime: 2.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 3.75, endTime: 3.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 0.5, endTime: 0.5, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 1.25, endTime: 1.25, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 2.0, endTime: 2.0, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 2.75, endTime: 2.75, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 3.5, endTime: 3.5, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
  // Mozambique
  {
    totalTime: 4,
    notes: [
      { pitch: 51, startTime: 0.0, endTime: 0.0, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 0.5, endTime: 0.5, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.0, endTime: 1.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.25, endTime: 1.25, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 1.75, endTime: 1.75, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 2.25, endTime: 2.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 2.5, endTime: 2.5, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 3.0, endTime: 3.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 3.25, endTime: 3.25, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 3.75, endTime: 3.75, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 0.75, endTime: 0.75, velocity: 62, isDrum: true, isUpbeat: true },
      { pitch: 50, startTime: 1.5, endTime: 1.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 50, startTime: 1.75, endTime: 1.75, velocity: 62, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 2.75, endTime: 2.75, velocity: 62, isDrum: true, isUpbeat: true },
      { pitch: 50, startTime: 3.5, endTime: 3.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
];

const ORC_POP_DRUMS = [
  // Lively toms
  {
    totalTime: 4,
    notes: [
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 47, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 0.25, endTime: 0.25, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 0.75, endTime: 0.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 47, startTime: 1.25, endTime: 1.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 1.75, endTime: 1.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 2.0, endTime: 2.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.5, endTime: 2.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 3.0, endTime: 3.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 47, startTime: 3.25, endTime: 3.25, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 3.5, endTime: 3.5, velocity: 15, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 3.5833333333333335, endTime: 3.5833333333333335, velocity: 23, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 3.6666666666666665, endTime: 3.6666666666666665, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 3.75, endTime: 3.75, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 3.8333333333333335, endTime: 3.8333333333333335, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 3.9166666666666665, endTime: 3.9166666666666665, velocity: 55, isDrum: true, isUpbeat: false },
    ]
  },
  // Tom groove with snare roll finish
  {
    totalTime: 4,
    notes: [
      { pitch: 42, startTime: 0.0, endTime: 0.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 0.5, endTime: 0.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.0, endTime: 1.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.5, endTime: 1.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 2.0, endTime: 2.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 2.5, endTime: 2.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 3.0, endTime: 3.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 3.5, endTime: 3.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 47, startTime: 0.75, endTime: 0.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 47, startTime: 2.75, endTime: 2.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.75, endTime: 3.75, velocity: 23, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.8125, endTime: 3.8125, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.875, endTime: 3.875, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.9375, endTime: 3.9375, velocity: 70, isDrum: true, isUpbeat: false },
    ]
  },
];

const LOFI_DRUMS = [
  // Cabasa lofi with snare
  {
    totalTime: 4,
    notes: [
      { pitch: 69, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 2.25, endTime: 2.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.75, endTime: 0.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
  // Cabasa lofi with laid back cross sticks
  {
    totalTime: 4,
    notes: [
      { pitch: 69, startTime: 0.0, endTime: 0.0, velocity: 70, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 0.5, endTime: 0.5, velocity: 70, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.25, endTime: 1.25, velocity: 70, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.75, endTime: 1.75, velocity: 70, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 2.0, endTime: 2.0, velocity: 70, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 2.5, endTime: 2.5, velocity: 70, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 3.0, endTime: 3.0, velocity: 70, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 3.5, endTime: 3.5, velocity: 70, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 0.9166666666666666, endTime: 0.9166666666666666, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.75, endTime: 1.75, velocity: 78, isDrum: true, isUpbeat: true },
    ]
  },
  // Linear lofi
  {
    totalTime: 4,
    notes: [
      { pitch: 69, startTime: 0.25, endTime: 0.25, velocity: 70, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.0, endTime: 1.0, velocity: 70, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.25, endTime: 1.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 1.75, endTime: 1.75, velocity: 70, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 2.5, endTime: 2.5, velocity: 70, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 2.75, endTime: 2.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 3.25, endTime: 3.25, velocity: 70, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.25, endTime: 0.25, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 1.75, endTime: 1.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 2.25, endTime: 2.25, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 3.75, endTime: 3.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.75, endTime: 0.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 3.25, endTime: 3.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
  // Cabasa lofi with Sticks
  {
    totalTime: 4,
    notes: [
      { pitch: 69, startTime: 0.0, endTime: 0.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 0.5, endTime: 0.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.0, endTime: 1.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.5, endTime: 1.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 2.0, endTime: 2.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 2.5, endTime: 2.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 3.0, endTime: 3.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 3.5, endTime: 3.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.75, endTime: 0.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 2.5, endTime: 2.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
];

const REGGAE_DRUMS = [
  // Reggae one-drop
  {
    totalTime: 2,
    notes: [
      { pitch: 42, startTime: 0.0, endTime: 0.0, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 0.5, endTime: 0.5, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.0, endTime: 1.0, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.25, endTime: 1.25, velocity: 15, isDrum: true, isUpbeat: true },
      { pitch: 46, startTime: 1.25, endTime: 1.25, velocity: 15, isDrum: true, isUpbeat: true },
      { pitch: 42, startTime: 1.5, endTime: 1.5, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 1.0, endTime: 1.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 47, startTime: 1.75, endTime: 1.75, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
];

const LATIN_DRUMS = [
  // Mozambique
  {
    totalTime: 4,
    notes: [
      { pitch: 51, startTime: 0.0, endTime: 0.0, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 0.5, endTime: 0.5, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.0, endTime: 1.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.25, endTime: 1.25, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 1.75, endTime: 1.75, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 2.25, endTime: 2.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 2.5, endTime: 2.5, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 3.0, endTime: 3.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 3.25, endTime: 3.25, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 3.75, endTime: 3.75, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 0.75, endTime: 0.75, velocity: 62, isDrum: true, isUpbeat: true },
      { pitch: 50, startTime: 1.5, endTime: 1.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 50, startTime: 1.75, endTime: 1.75, velocity: 62, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 2.75, endTime: 2.75, velocity: 62, isDrum: true, isUpbeat: true },
      { pitch: 50, startTime: 3.5, endTime: 3.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
  // Son
  {
    totalTime: 4,
    notes: [
      { pitch: 75, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 75, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 75, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 75, startTime: 2.75, endTime: 2.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 75, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 60, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 60, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 60, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 61, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 60, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 60, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 60, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 61, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 62, startTime: 0.0, endTime: 0.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 62, startTime: 0.25, endTime: 0.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 62, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 62, startTime: 0.75, endTime: 0.75, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 62, startTime: 1.0, endTime: 1.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 62, startTime: 1.25, endTime: 1.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 63, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 63, startTime: 1.75, endTime: 1.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 62, startTime: 2.0, endTime: 2.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 62, startTime: 2.25, endTime: 2.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 62, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 62, startTime: 2.75, endTime: 2.75, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 62, startTime: 3.0, endTime: 3.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 62, startTime: 3.25, endTime: 3.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 63, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 63, startTime: 3.75, endTime: 3.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 0.0, endTime: 0.0, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 0.5, endTime: 0.5, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 1.0, endTime: 1.0, velocity: 23, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 1.25, endTime: 1.25, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 1.75, endTime: 1.75, velocity: 23, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 2.0, endTime: 2.0, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 2.5, endTime: 2.5, velocity: 23, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 2.75, endTime: 2.75, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 3.25, endTime: 3.25, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 3.75, endTime: 3.75, velocity: 23, isDrum: true, isUpbeat: true },
    ]
  },
  // Montuno
  {
    totalTime: 4,
    notes: [
      { pitch: 75, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 75, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 75, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 75, startTime: 2.75, endTime: 2.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 75, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 56, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 56, startTime: 0.5, endTime: 0.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 56, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 56, startTime: 1.5, endTime: 1.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 56, startTime: 1.75, endTime: 1.75, velocity: 62, isDrum: true, isUpbeat: true },
      { pitch: 56, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 56, startTime: 2.5, endTime: 2.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 56, startTime: 2.75, endTime: 2.75, velocity: 62, isDrum: true, isUpbeat: true },
      { pitch: 56, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 56, startTime: 3.5, endTime: 3.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 56, startTime: 3.75, endTime: 3.75, velocity: 62, isDrum: true, isUpbeat: true },
      { pitch: 62, startTime: 0.0, endTime: 0.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 62, startTime: 0.25, endTime: 0.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 62, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 62, startTime: 0.75, endTime: 0.75, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 62, startTime: 1.0, endTime: 1.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 62, startTime: 1.25, endTime: 1.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 63, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 63, startTime: 1.75, endTime: 1.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 62, startTime: 2.0, endTime: 2.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 62, startTime: 2.25, endTime: 2.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 62, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 64, startTime: 2.75, endTime: 2.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 64, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 62, startTime: 3.25, endTime: 3.25, velocity: 39, isDrum: true, isUpbeat: true },
      { pitch: 63, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 63, startTime: 3.75, endTime: 3.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 0.0, endTime: 0.0, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 0.5, endTime: 0.5, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 1.0, endTime: 1.0, velocity: 23, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 1.25, endTime: 1.25, velocity: 23, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 1.5, endTime: 1.5, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 1.75, endTime: 1.75, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 2.25, endTime: 2.25, velocity: 23, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 2.5, endTime: 2.5, velocity: 23, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 2.75, endTime: 2.75, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 3.0, endTime: 3.0, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 3.5, endTime: 3.5, velocity: 23, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 3.75, endTime: 3.75, velocity: 47, isDrum: true, isUpbeat: true },
    ]
  },
];

const JAZZ_DRUMS = [
  // 3-3-2 Jazz
  {
    totalTime: 4,
    notes: [
      { pitch: 51, startTime: 0.0, endTime: 0.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 0.5, endTime: 0.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 0.75, endTime: 0.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 1.0, endTime: 1.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.5, endTime: 1.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.0, endTime: 2.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.25, endTime: 2.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 2.5, endTime: 2.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 3.0, endTime: 3.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 3.25, endTime: 3.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 51, startTime: 3.5, endTime: 3.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 40, startTime: 0.5, endTime: 0.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 40, startTime: 0.75, endTime: 0.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 40, startTime: 1.0, endTime: 1.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 40, startTime: 2.75, endTime: 2.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 40, startTime: 3.5, endTime: 3.5, velocity: 55, isDrum: true, isUpbeat: false },
    ]
  },
];

const RETRO_FUTURISTIC_DRUMS = [
  // Constant bass drum rock
  {
    totalTime: 1,
    notes: [
      { pitch: 52, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.25, endTime: 0.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 0.75, endTime: 0.75, velocity: 78, isDrum: true, isUpbeat: true },
    ]
  },
];

const ANIME_DRUMS = [
  // Linear Late Backbeat
  {
    totalTime: 4,
    notes: [
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 0.25, endTime: 0.25, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 38, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 0.75, endTime: 0.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.25, endTime: 1.25, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 38, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.75, endTime: 1.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 42, startTime: 1.875, endTime: 1.875, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 2.25, endTime: 2.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 42, startTime: 2.5, endTime: 2.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 2.75, endTime: 2.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 42, startTime: 3.0, endTime: 3.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 3.25, endTime: 3.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 38, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 3.75, endTime: 3.75, velocity: 55, isDrum: true, isUpbeat: true },
    ]
  },
];

const POP_DRUMS = [
  // Linear Pop
  {
    totalTime: 4,
    notes: [
      { pitch: 69, startTime: 0.25, endTime: 0.25, velocity: 15, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 0.5, endTime: 0.5, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.0, endTime: 1.0, velocity: 15, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 1.25, endTime: 1.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 1.75, endTime: 1.75, velocity: 15, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 2.0, endTime: 2.0, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 2.5, endTime: 2.5, velocity: 15, isDrum: true, isUpbeat: false },
      { pitch: 69, startTime: 2.75, endTime: 2.75, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 3.25, endTime: 3.25, velocity: 15, isDrum: true, isUpbeat: true },
      { pitch: 69, startTime: 3.5, endTime: 3.5, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 0.0, endTime: 0.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 0.5, endTime: 0.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.0, endTime: 1.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.5, endTime: 1.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 2.0, endTime: 2.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 2.5, endTime: 2.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 3.0, endTime: 3.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 3.5, endTime: 3.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.25, endTime: 0.25, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 1.75, endTime: 1.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 2.25, endTime: 2.25, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 3.75, endTime: 3.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.75, endTime: 0.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 3.25, endTime: 3.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 37, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
];

const EPIC_DRUMS = [
  // Syncopated epic toms
  {
    totalTime: 4,
    notes: [
      { pitch: 43, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.25, endTime: 2.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 2.75, endTime: 2.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 0.0, endTime: 0.0, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 0.25, endTime: 0.25, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 38, startTime: 0.5, endTime: 0.5, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 0.75, endTime: 0.75, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 38, startTime: 1.0, endTime: 1.0, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 1.25, endTime: 1.25, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 38, startTime: 1.5, endTime: 1.5, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 1.75, endTime: 1.75, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 38, startTime: 2.0, endTime: 2.0, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 2.25, endTime: 2.25, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 38, startTime: 2.5, endTime: 2.5, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 2.75, endTime: 2.75, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 38, startTime: 3.0, endTime: 3.0, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.1875, endTime: 3.1875, velocity: 35, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.25, endTime: 3.25, velocity: 35, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.3125, endTime: 3.3125, velocity: 35, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.375, endTime: 3.375, velocity: 35, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.5, endTime: 3.5, velocity: 59, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.75, endTime: 3.75, velocity: 59, isDrum: true, isUpbeat: true },
      { pitch: 49, startTime: 0.0, endTime: 0.0, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 49, startTime: 1.0, endTime: 1.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 49, startTime: 2.0, endTime: 2.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 49, startTime: 3.0, endTime: 3.0, velocity: 39, isDrum: true, isUpbeat: false },
    ]
  },
  // Epic Sticks
  {
    totalTime: 4,
    notes: [
      { pitch: 43, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 0.25, endTime: 0.25, velocity: 23, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 0.5, endTime: 0.5, velocity: 23, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 0.75, endTime: 0.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 1.0, endTime: 1.0, velocity: 23, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 1.25, endTime: 1.25, velocity: 23, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 1.75, endTime: 1.75, velocity: 23, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 2.0, endTime: 2.0, velocity: 23, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.25, endTime: 2.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 2.5, endTime: 2.5, velocity: 23, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.75, endTime: 2.75, velocity: 23, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 3.25, endTime: 3.25, velocity: 23, isDrum: true, isUpbeat: true },
      { pitch: 43, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 3.75, endTime: 3.75, velocity: 23, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 0.0, endTime: 0.0, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.25, endTime: 0.25, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 0.5, endTime: 0.5, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.75, endTime: 0.75, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 1.0, endTime: 1.0, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 1.25, endTime: 1.25, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 1.5, endTime: 1.5, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 1.75, endTime: 1.75, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 2.0, endTime: 2.0, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 2.25, endTime: 2.25, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 2.5, endTime: 2.5, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 2.75, endTime: 2.75, velocity: 47, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 3.0, endTime: 3.0, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 3.25, endTime: 3.25, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 3.375, endTime: 3.375, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 3.5, endTime: 3.5, velocity: 70, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 3.75, endTime: 3.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 49, startTime: 0.0, endTime: 0.0, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 49, startTime: 1.0, endTime: 1.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 49, startTime: 2.0, endTime: 2.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 49, startTime: 3.0, endTime: 3.0, velocity: 39, isDrum: true, isUpbeat: false },
    ]
  },
  // Busy Sticks
  {
    totalTime: 4,
    notes: [
      { pitch: 43, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 47, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 47, startTime: 1.125, endTime: 1.125, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.75, endTime: 2.75, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.8125, endTime: 2.8125, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.875, endTime: 2.875, velocity: 47, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.9375, endTime: 2.9375, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.0, endTime: 0.0, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.125, endTime: 0.125, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.25, endTime: 0.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 0.375, endTime: 0.375, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.5, endTime: 0.5, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.625, endTime: 0.625, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.75, endTime: 0.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 0.875, endTime: 0.875, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 1.0, endTime: 1.0, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 1.125, endTime: 1.125, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 1.25, endTime: 1.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 1.375, endTime: 1.375, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 1.5, endTime: 1.5, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 1.625, endTime: 1.625, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 1.75, endTime: 1.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 1.875, endTime: 1.875, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 2.0, endTime: 2.0, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 2.125, endTime: 2.125, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 2.25, endTime: 2.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 2.375, endTime: 2.375, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 2.5, endTime: 2.5, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 2.625, endTime: 2.625, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 2.75, endTime: 2.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 2.875, endTime: 2.875, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 3.0, endTime: 3.0, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 3.125, endTime: 3.125, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 3.25, endTime: 3.25, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 3.375, endTime: 3.375, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 3.5, endTime: 3.5, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 3.625, endTime: 3.625, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 3.75, endTime: 3.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 76, startTime: 3.875, endTime: 3.875, velocity: 55, isDrum: true, isUpbeat: false },
    ]
  },
];

const ODDITY_DRUMS = [
  // Eleven Drumsets
  {
    totalTime: 11,
    notes: [
      { pitch: 51, startTime: 0.0, endTime: 0.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 0.5, endTime: 0.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.0, endTime: 1.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.0, endTime: 2.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.5, endTime: 2.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 3.5, endTime: 3.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 4.0, endTime: 4.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 4.5, endTime: 4.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 5.0, endTime: 5.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 5.5, endTime: 5.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 6.0, endTime: 6.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 6.5, endTime: 6.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 7.0, endTime: 7.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 7.0, endTime: 7.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 7.5, endTime: 7.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 8.0, endTime: 8.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 8.5, endTime: 8.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 9.0, endTime: 9.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 9.5, endTime: 9.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 10.0, endTime: 10.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 10.5, endTime: 10.5, velocity: 39, isDrum: true, isUpbeat: false },
    ]
  },
  // Eleven Drumsets High
  {
    totalTime: 11,
    notes: [
      { pitch: 51, startTime: 0.0, endTime: 0.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 0.5, endTime: 0.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.0, endTime: 1.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.0, endTime: 2.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.5, endTime: 2.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 3.5, endTime: 3.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 4.0, endTime: 4.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 4.5, endTime: 4.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 5.0, endTime: 5.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 5.5, endTime: 5.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 6.0, endTime: 6.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 6.5, endTime: 6.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 7.0, endTime: 7.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 7.0, endTime: 7.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 7.5, endTime: 7.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 8.0, endTime: 8.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 8.5, endTime: 8.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 9.0, endTime: 9.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 9.5, endTime: 9.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 10.0, endTime: 10.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 10.5, endTime: 10.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 50, startTime: 3.5, endTime: 3.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 4.5, endTime: 4.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 9.0, endTime: 9.0, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 50, startTime: 9.5, endTime: 9.5, velocity: 62, isDrum: true, isUpbeat: false },
    ]
  },
  // Even or Eleven
  {
    totalTime: 22,
    notes: [
      { pitch: 53, startTime: 0.0, endTime: 0.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.0, endTime: 1.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 1.5, endTime: 1.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.5, endTime: 2.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 3.0, endTime: 3.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 4.0, endTime: 4.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 4.5, endTime: 4.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 5.0, endTime: 5.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 5.5, endTime: 5.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 6.5, endTime: 6.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 7.0, endTime: 7.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 8.0, endTime: 8.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 8.5, endTime: 8.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 9.5, endTime: 9.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 10.0, endTime: 10.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 10.5, endTime: 10.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 5.5, endTime: 5.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 11.0, endTime: 11.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 12.0, endTime: 12.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 12.5, endTime: 12.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 13.5, endTime: 13.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 14.0, endTime: 14.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 15.0, endTime: 15.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 15.5, endTime: 15.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 16.0, endTime: 16.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 11.0, endTime: 11.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 14.5, endTime: 14.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 16.5, endTime: 16.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 17.5, endTime: 17.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 18.0, endTime: 18.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 19.0, endTime: 19.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 19.5, endTime: 19.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 20.5, endTime: 20.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 37, startTime: 21.0, endTime: 21.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 21.5, endTime: 21.5, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 16.5, endTime: 16.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 0.0, endTime: 0.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 2.0, endTime: 2.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 4.0, endTime: 4.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 6.0, endTime: 6.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 8.0, endTime: 8.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 10.0, endTime: 10.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 12.0, endTime: 12.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 14.0, endTime: 14.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 16.0, endTime: 16.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 18.0, endTime: 18.0, velocity: 55, isDrum: true, isUpbeat: false },
      { pitch: 44, startTime: 20.0, endTime: 20.0, velocity: 55, isDrum: true, isUpbeat: false },
    ]
  },
  // Linear Eleven
  {
    totalTime: 11,
    notes: [
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 1.0, endTime: 1.0, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 4.0, endTime: 4.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 4.5, endTime: 4.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 5.0, endTime: 5.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 5.5, endTime: 5.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 6.0, endTime: 6.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 6.5, endTime: 6.5, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 7.0, endTime: 7.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 7.5, endTime: 7.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 8.0, endTime: 8.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 8.5, endTime: 8.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 9.0, endTime: 9.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 9.5, endTime: 9.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 10.0, endTime: 10.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 10.5, endTime: 10.5, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
  // Eleven Toms
  {
    totalTime: 11,
    notes: [
      { pitch: 53, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 50, startTime: 0.5, endTime: 0.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 47, startTime: 2.0, endTime: 2.0, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 2.5, endTime: 2.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 4.0, endTime: 4.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 4.5, endTime: 4.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 5.0, endTime: 5.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 5.5, endTime: 5.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 5.5, endTime: 5.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 50, startTime: 6.0, endTime: 6.0, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 6.5, endTime: 6.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 7.0, endTime: 7.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 7.0, endTime: 7.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 47, startTime: 7.5, endTime: 7.5, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 8.0, endTime: 8.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 53, startTime: 8.5, endTime: 8.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 8.5, endTime: 8.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 43, startTime: 9.0, endTime: 9.0, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 9.5, endTime: 9.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 10.0, endTime: 10.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 10.5, endTime: 10.5, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
  // Five Eleven
  {
    totalTime: 11,
    notes: [
      { pitch: 51, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 4.0, endTime: 4.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 4.5, endTime: 4.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 5.0, endTime: 5.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 5.5, endTime: 5.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 6.0, endTime: 6.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 6.5, endTime: 6.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 7.0, endTime: 7.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 7.5, endTime: 7.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 8.0, endTime: 8.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 8.5, endTime: 8.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 8.5, endTime: 8.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 9.0, endTime: 9.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 9.5, endTime: 9.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 10.0, endTime: 10.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 10.0, endTime: 10.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 10.5, endTime: 10.5, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
  // Seven Eleven
  {
    totalTime: 11,
    notes: [
      { pitch: 51, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.0, endTime: 2.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 2.5, endTime: 2.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 3.0, endTime: 3.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 3.5, endTime: 3.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 4.0, endTime: 4.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 4.5, endTime: 4.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 4.5, endTime: 4.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 5.0, endTime: 5.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 5.5, endTime: 5.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 5.5, endTime: 5.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 6.0, endTime: 6.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 6.5, endTime: 6.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 7.0, endTime: 7.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 7.0, endTime: 7.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 76, startTime: 7.5, endTime: 7.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 8.0, endTime: 8.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 8.5, endTime: 8.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 9.0, endTime: 9.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 9.5, endTime: 9.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 10.0, endTime: 10.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 51, startTime: 10.5, endTime: 10.5, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
];

const HIP_HOP_DRUMS = [
  // Basic Hip Hop
  {
    totalTime: 2,
    notes: [
      { pitch: 42, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 0.25, endTime: 0.25, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 42, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 0.75, endTime: 0.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 42, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.25, endTime: 1.25, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 42, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.75, endTime: 1.75, velocity: 55, isDrum: true, isUpbeat: true },
      { pitch: 42, startTime: 0.125, endTime: 0.125, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 0.875, endTime: 0.875, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.625, endTime: 1.625, velocity: 39, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.25, endTime: 1.25, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 38, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
  // Linear Hip Hop
  {
    totalTime: 2,
    notes: [
      { pitch: 42, startTime: 0.125, endTime: 0.125, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 0.25, endTime: 0.25, velocity: 62, isDrum: true, isUpbeat: true },
      { pitch: 42, startTime: 0.625, endTime: 0.625, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 0.875, endTime: 0.875, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.125, endTime: 1.125, velocity: 31, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.25, endTime: 1.25, velocity: 62, isDrum: true, isUpbeat: true },
      { pitch: 42, startTime: 1.625, endTime: 1.625, velocity: 62, isDrum: true, isUpbeat: false },
      { pitch: 42, startTime: 1.75, endTime: 1.75, velocity: 31, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 0.0, endTime: 0.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.375, endTime: 0.375, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 0.75, endTime: 0.75, velocity: 78, isDrum: true, isUpbeat: true },
      { pitch: 35, startTime: 1.0, endTime: 1.0, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 35, startTime: 1.375, endTime: 1.375, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 0.5, endTime: 0.5, velocity: 78, isDrum: true, isUpbeat: false },
      { pitch: 38, startTime: 1.5, endTime: 1.5, velocity: 78, isDrum: true, isUpbeat: false },
    ]
  },
];

const STYLE_TEMPO_SEQUENCES = {
  bossa: BOSSA_DRUMS,
  orc_pop: ORC_POP_DRUMS,
  lofi: LOFI_DRUMS,
  reggae: REGGAE_DRUMS,
  latin: LATIN_DRUMS,
  jazz: JAZZ_DRUMS,
  retro_futuristic: RETRO_FUTURISTIC_DRUMS,
  anime: ANIME_DRUMS,
  pop: POP_DRUMS,
  epic: EPIC_DRUMS,
  oddity: ODDITY_DRUMS,
  hip_hop: HIP_HOP_DRUMS,
};

export default STYLE_TEMPO_SEQUENCES;

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";
import SlideProductCreate from "../assets/SlideProductCreate";
import SlideProductEdit from "../assets/SlideProductEdit";
import StrofeCoins from '../assets/strofe_coins.png';

export default function SlideProductSketch() {
  return (
    <div className='slide slide-product-sketch white-bg'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <div className='bullet'>
          <div className='semi-circle' />
          <div className='circle-top'><div className='circle' /></div>
        </div>
        <h1>The Product</h1>
      </div>

      <div className='content'>
        <div>
          <div className='circle create'>
            <SlideProductCreate height={270} width={270} />
          </div>
          Pick Mood & Style
        </div>

        <div>
          <div className='circle edit'>
            <SlideProductEdit height={200} width={200} />
          </div>
          Customize the Song
        </div>

        <div>
          <div className='circle purchase'>
            <img src={StrofeCoins} height={175} width={225} alt="coins" />
          </div>
          Purchase Ownership
        </div>
      </div>
    </div>
  )
}
import * as React from "react";

const InstrumentTimpani = (props) => (
  <svg
    id="InstrumentTimpani_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentTimpani_svg__st2{fill:#fdcc75}.InstrumentTimpani_svg__st28{fill:#fff}.InstrumentTimpani_svg__st65{fill:#fdcb76}.InstrumentTimpani_svg__st66{stroke:#fff;stroke-width:1.3196;stroke-miterlimit:10}.InstrumentTimpani_svg__st66,.InstrumentTimpani_svg__st67,.InstrumentTimpani_svg__st68{fill:none;stroke-linecap:round}.InstrumentTimpani_svg__st67{stroke:#fdcb76;stroke-width:2.6905;stroke-miterlimit:10}.InstrumentTimpani_svg__st68{stroke:#fdcc75;stroke-width:2.3904;stroke-linejoin:round}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      d="M76.62 39.69c0 20.19-11.11 36.56-24.82 36.56S26.98 59.87 26.98 39.69"
      style={{
        fill: "#ca5672",
      }}
    />
    <ellipse
      className="InstrumentTimpani_svg__st65"
      cx={51.8}
      cy={39.69}
      rx={24.82}
      ry={3.88}
    />
    <path
      className="InstrumentTimpani_svg__st65"
      d="M29.98 56.42c-1.15.58-1.48 1.05-1.48 1.27 0 .38 1.1 1.63 6.1 2.78 4.52 1.03 10.55 1.6 16.99 1.6s12.47-.57 16.99-1.6c5-1.14 6.1-2.39 6.1-2.78 0-.21-.34-.69-1.49-1.27l1-2.39c2.32 1.03 3.49 2.26 3.49 3.66 0 2.17-2.79 3.91-8.3 5.17-4.77 1.09-11.08 1.69-17.78 1.69s-13.01-.6-17.78-1.69c-5.51-1.26-8.3-3-8.3-5.17 0-1.4 1.16-2.62 3.46-3.65l1 2.38z"
    />
    <path className="InstrumentTimpani_svg__st66" d="M51.46 44.22v9.49" />
    <circle
      className="InstrumentTimpani_svg__st28"
      cx={51.46}
      cy={54.14}
      r={2.66}
    />
    <path className="InstrumentTimpani_svg__st66" d="M33 40.8v9.48" />
    <circle
      className="InstrumentTimpani_svg__st28"
      cx={33}
      cy={50.19}
      r={2.66}
    />
    <path className="InstrumentTimpani_svg__st66" d="M70.39 40.8v9.48" />
    <circle
      className="InstrumentTimpani_svg__st28"
      cx={70.39}
      cy={50.19}
      r={2.66}
    />
    <path
      className="InstrumentTimpani_svg__st67"
      d="m34.74 62.14-6.18 19.99M69.74 62.14l6.18 19.99M51.8 64.3v24.47"
    />
    <path className="InstrumentTimpani_svg__st68" d="m97.02 27.59-32.07-2.02" />
    <path
      className="InstrumentTimpani_svg__st2"
      d="M65.24 23.07c-1.39-.09-2.58.97-2.67 2.35-.09 1.39.97 2.58 2.35 2.67 1.39.09 2.58-.97 2.67-2.35.09-1.39-.97-2.59-2.35-2.67z"
    />
    <path className="InstrumentTimpani_svg__st68" d="m95.95 34.1-27.8-16.11" />
    <path
      className="InstrumentTimpani_svg__st2"
      d="M69.52 15.88c-1.2-.7-2.74-.29-3.44.92s-.29 2.74.92 3.44c1.2.7 2.74.29 3.44-.92s.28-2.75-.92-3.44z"
    />
  </svg>
);

export default InstrumentTimpani;

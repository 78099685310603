export default function EpicIcon(props) {
  return (
    <svg
      id="__epic-icon-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110.84 112.61"
      {...props}
    >
      <defs>
        <style>{".__epic-icon-prefix__cls-5{fill:#e2e27c}"}</style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 41"}</title>
      <rect
        y={1.77}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
        fill="#4d3f92"
      />
      <path
        fill="#f18860"
        d="M69.26 25.57l2.96 9.82-20.15 45.18-6.12-2.73-6.12-2.73 20.15-45.18 9.28-4.36z"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.21}
        d="M65.57 33.84l-23.4 52.47"
      />
      <path
        d="M46.63 76.3l11.87 5.29a5.53 5.53 0 01-7.3 2.8l-2.62-1.17L42 97.88l2 .88a2 2 0 01-1.65 3.71l-6.17-2.75L30 97a2 2 0 111.65-3.71l2 .88 6.54-14.67-2.62-1.17a5.53 5.53 0 01-2.8-7.3z"
        fill="#c95671"
      />
      <path
        className="__epic-icon-prefix__cls-5"
        d="M38.82 14.23l9.57 23.09H34.34l.58 16.13-12.33-24.1h5.92L20.3 14.23h18.52zM94.67 55.87l-17.44-2.43 2.49 9.58-11.11 2.47 18.63 4.12-1.05-4.03 11.77 2.92-3.29-12.63z"
      />
    </svg>
  );
}

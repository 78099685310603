import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";
import StrofeProduct from '../assets/strofe_product.png';

export default function SlideProduct() {

  return (
    <div className='slide slide-product white-bg'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <div className='bullet'>
          <div className='circle big-lime' />
          <div className='circle small-orange' />
          <div className='circle small-purple' />
        </div>
        <h1>The Product</h1>
      </div>

      <div className='content'>
        <img className='screenshots' src={StrofeProduct} alt='product screenshots' />
      </div>

    </div>
  )
}

import * as React from "react";

const InstrumentXylophone = (props) => (
  <svg
    id="InstrumentXylophone_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.6"
    style={{
      enableBackground: "new 0 0 110.84 110.6",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentXylophone_svg__st3{fill:#4e4092}.InstrumentXylophone_svg__st4{fill:#f18860}.InstrumentXylophone_svg__st6{fill:#ca5672}.InstrumentXylophone_svg__st10{fill:#538553}.InstrumentXylophone_svg__st13{fill:none}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#cfd125",
      }}
    />
    <path
      className="InstrumentXylophone_svg__st13"
      d="m58.21 48.04-1.2-.12 1.2.12zM79.18 50.07l-6.84-.66 2.04.2zM57.01 47.92l-2.09-.2zM72.34 49.41l-1.92-.18z"
    />
    <path d="m66.06 48.8-.43-.04z" />
    <path
      transform="rotate(-21.235 12.97 56.461)"
      className="InstrumentXylophone_svg__st3"
      d="M10.48 36.5h4.98v39.93h-4.98z"
    />
    <path
      className="InstrumentXylophone_svg__st3"
      d="m10.45 43.35 1.5 3.87 79.31-25.9-1.33-3.43zM18.55 64.2l1.29 3.33 75.99-34.45-1.46-3.76zM92.84 14.11l-3.81 1.48.9 2.3 3.9-1.24zM96.68 35.27l3.81-1.48-.93-2.4-3.73 1.69zM94.37 29.32l3.72-1.71-2.94-7.56-3.89 1.27z"
    />
    <path
      className="InstrumentXylophone_svg__st3"
      d="m89.93 17.89 1.33 3.43 3.89-1.27-1.32-3.4zM95.83 33.08l3.73-1.69-1.47-3.78-3.72 1.71z"
    />
    <path
      className="InstrumentXylophone_svg__st10"
      d="m49.74 26.1 11.07 28.49 4.31-2.16-10.8-27.8-4.64 1.3zM56.85 23.91l.1.25 10.53 27.1 4.31-2.16-10.3-26.49-4.22 1.19zM24.47 72.69l4.31-2.15-13.57-34.93-4.64 1.31zM31.74 69.07l4.31-2.15-13.02-33.5-4.64 1.3zM39.01 65.45l4.31-2.15-12.47-32.08-4.64 1.3zM80.96 17.14l8.92 22.96 4.32-2.14-8.6-22.12zM46.27 61.83l4.32-2.15-11.91-30.66-4.65 1.3zM53.54 58.21l4.32-2.15L46.5 26.82l-4.64 1.31zM75.35 47.35l4.31-2.15-9.7-24.97-4.64 1.31zM82.62 43.72l4.31-2.14-9.15-23.55-4.64 1.31z"
    />
    <path
      className="InstrumentXylophone_svg__st6"
      d="m79.49 62.59-1.3 38.2c-.02.49-.42.87-.91.85l-.55-.02a.875.875 0 0 1-.85-.91l1.3-38.2c.02-.49.42-.87.91-.85l.55.02c.49.02.87.42.85.91z"
    />
    <circle
      className="InstrumentXylophone_svg__st4"
      cx={78.26}
      cy={59.76}
      r={3.13}
    />
    <path
      className="InstrumentXylophone_svg__st6"
      d="m61.9 77.93 33.13 19.06c.42.24.96.1 1.2-.32l.27-.47a.88.88 0 0 0-.32-1.2L63.05 75.93a.88.88 0 0 0-1.2.32l-.27.47c-.25.43-.1.97.32 1.21z"
    />
    <circle
      className="InstrumentXylophone_svg__st4"
      cx={60.14}
      cy={75.38}
      r={3.13}
    />
  </svg>
);

export default InstrumentXylophone;

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";

export default function SlideTestimonials() {
  return (
    <div className='slide slide-testimonials white-bg'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <div className='traction-bullet'>
          <div className='orange-circle' />
          <div className='yellow-circle' />
          <div className='lime-circle' />
        </div>
        <h1>What our users are saying</h1>
      </div>

      <div className='content'>
        <div className='testimonial first'>
          <div className='quote'>
            "This is absolutely amazing, it generated an amazing song that legit brought a tear to my eye, lol. I will definitely be using this in future projects! 20/10!"
          </div>
          <div className='author'>
            <div className='name'>NickIsANuke</div>
            <div className='triangle'></div>
          </div>
        </div>

        <div className='testimonial second'>
          <div className='quote'>
            "SHEEEESH MANN, literally what we all need, the best tool in existence for roblox developers. many thanks!"
          </div>
          <div className='author'>
            <div className='name'>tr_ulyurdev</div>
            <div className='triangle'></div>
          </div>
        </div>

        <div className='NPS'><div className='count'>59</div>NPS</div>

        <div className='testimonial third'>
          <div className='quote'>
            "This is honestly the best music making tool i've ever tried. And it's so simple! I love it!! I'll definitely be using this for my games. Thank you for this!!"
          </div>
          <div className='author'>
            <div className='name'>Tiger8374ALT</div>
            <div className='triangle'></div>
          </div>
        </div>

        <div className='testimonial fourth'>
          <div className='quote'>
            "This is AWESOME! I always want music for games I work on but I can't create any so thanks for helping tons of dev around the world and making it simple and easy to use"
          </div>
          <div className='author'>
            <div className='name'>Abhay216</div>
            <div className='triangle'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

import * as React from "react";

const InstrumentAcousticGuitar = (props) => (
  <svg
    id="InstrumentAcousticGuitar_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 112.24"
    style={{
      enableBackground: "new 0 0 110.84 112.24",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentAcousticGuitar_svg__st4{fill:#f18860}.InstrumentAcousticGuitar_svg__st11{fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10}"
      }
    </style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#fdcc75",
      }}
    />
    <path
      d="M90.5 60.94c-3.94-3.81-8.97-4.83-14.21-5.23-3.4-.26-4.41-1.08-5.4-4.31-.25-.81-.48-1.62-.81-2.4-2.88-6.85-9.45-8.72-15.55-4.44-1.29.9-2.54 1.85-3.92 2.85-7.89-8.2-16.3-17.12-24.11-25.3.97-1.07.98-2.72-.02-3.8l-5-5.39a2.803 2.803 0 0 0-3.97-.15l-3.36 3.11a2.803 2.803 0 0 0-.15 3.97l5 5.39a2.806 2.806 0 0 0 3.87.24c3.5 3.83 10.77 11.91 13.28 14.66 3.4 3.74 6.86 7.42 10.31 11.14-1.31 1.52-2.32 2.64-3.28 3.81-4.86 5.9-3.65 12.97 3.39 15.81 4.74 1.91 6.77 4.52 6.1 9.45-.1.73.11 1.51.2 2.27 1.64 14.11 14.75 18.4 24.2 15.17 7.9-2.7 13.69-7.98 17.16-15.56 3.46-7.57 1.93-15.82-3.73-21.29z"
      style={{
        fill: "#538553",
      }}
    />
    <circle
      className="InstrumentAcousticGuitar_svg__st4"
      cx={19.99}
      cy={28.93}
      r={2.04}
    />
    <circle
      className="InstrumentAcousticGuitar_svg__st4"
      cx={27.83}
      cy={15.13}
      r={2.23}
    />
    <circle
      className="InstrumentAcousticGuitar_svg__st4"
      cx={62.26}
      cy={63.99}
      r={7.78}
    />
    <path
      className="InstrumentAcousticGuitar_svg__st11"
      d="m57.78 65.12 22.01 24.7M59.74 63.35 81.8 87.82M61.84 61.44l22.01 24.7M63.94 59.53l22.01 24.7"
    />
  </svg>
);

export default InstrumentAcousticGuitar;

import React, { Suspense } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import RootRoute from './routes/RootRoute';
import CreateSong from './routes/CreateSong/CreateSong';
import SongPlayer from './routes/SongPlayer/SongPlayer';
import SongLibrary from './routes/SongLibrary/SongLibrary';
import LandingPage from './routes/LandingPage/LandingPage';
import PrivateRoute from './navigation/PrivateRoute';
import PasswordReset from './routes/PasswordReset/PasswordReset';
import TermsOfService from './routes/Policies/TermsOfService';
import PrivacyPolicy from './routes/Policies/PrivacyPolicy';
import Logout from './navigation/Logout';
import Settings from './routes/Settings/Settings';
import Transactions from './routes/Transactions/Transactions';
import GetCoins from './routes/GetCoins/GetCoins';
import UserStats from './routes/UserStats/UserStats';
import Feedback from './routes/Feedback/Feedback';
import PublicPlayer from './routes/SongPlayer/PublicPlayer';
import AuthCallback from './routes/AuthCallback';
import ReferRedirect from './routes/ReferRedirect';
import Referrer from './routes/Referrer';
import ReleaseVersion from './routes/ReleaseVersion';
import CouponManager from './routes/Coupons/CouponManager';
import RedeemCoupon from './routes/Coupons/RedeemCoupon';
import TestManager from './routes/Tests/TestManager';
import NoteSequenceStats from './routes/NoteSequenceStats/NoteSequenceStats';
import PitchDeck from './routes/Pitch/PitchDeck';
import Presentation from './routes/Presentation/Presentation';
import InvestorInviteManager from './routes/InvestorInvites/InvestorInviteManager';
import MissingTranslations from './routes/MissingTranslations/MissingTranslations';
import Pricing from './routes/GetCoins/Pricing';
import Roblox from './routes/Roblox/Roblox';
import Composer from './routes/Sequencer/Composer';
import NoteSequences from './routes/NoteSequences/NoteSequences';
import PotentialInvestors from './routes/PotentialInvestors/PotentialInvestors';
import PotentialInvestorStats from './routes/PotentialInvestors/PotentialInvestorStats';
import Survey from './routes/Survey/Survey';

import './App.scss';
import './modals/Modal.scss';
// import ListOfIcons from './routes/ListOfIcons';
const ListOfIcons = React.lazy(() => import('./routes/ListOfIcons'));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {

  const ROUTES = (
    <Router>
      <Switch>
        <Route path='/get-started'><LandingPage /></Route>
        <Route path='/password-reset'><PasswordReset /></Route>
        <Route path='/logout'><Logout /></Route>
        <Route path='/auth/:provider/callback'><AuthCallback /></Route>
        <Route path='/go/:alias'><ReferRedirect /></Route>
        <Route path='/go'><ReferRedirect /></Route>
        <Route path='/referrer/:inviter_display_name'><Referrer /></Route>
        <Route path='/terms'><TermsOfService /></Route>
        <Route path='/privacy'><PrivacyPolicy /></Route>
        <Route path='/2022RobloxGameJam/signup'><Roblox /></Route>
        <Route path='/invest'><PotentialInvestors /></Route>
        <Route path='/icons' superUserOnly><Suspense fallback={<div>Loading...</div>}><ListOfIcons /></Suspense></Route>

        <PrivateRoute path='/survey'><Survey /></PrivateRoute>
        <PrivateRoute path='/deck/:id' superUserOrInvestorOnly noHeader showFeedbackButton={false}><PitchDeck /></PrivateRoute>
        <PrivateRoute path='/deck/' superUserOrInvestorOnly noHeader showFeedbackButton={false}><PitchDeck /></PrivateRoute>

        <PrivateRoute path='/intro/:id' superUserOrInvestorOnly noHeader showFeedbackButton={false}><PitchDeck simplified /></PrivateRoute>
        <PrivateRoute path='/intro/' superUserOrInvestorOnly noHeader showFeedbackButton={false}><PitchDeck simplified /></PrivateRoute>

        <Route path='/presentation/:identifier' noHeader showFeedbackButton={false}><Presentation /></Route>

        <PrivateRoute path='/listen/:id' disableRedirect><PublicPlayer /></PrivateRoute>
        <PrivateRoute superUserOnly showFeedbackButton={false} path='/release'><ReleaseVersion /></PrivateRoute>
        <PrivateRoute superUserOnly path='/user-stats'><UserStats /></PrivateRoute>
        <PrivateRoute superUserOnly path='/feedback'><Feedback /></PrivateRoute>
        <PrivateRoute superUserOnly path='/coupon-manager'><CouponManager /></PrivateRoute>
        <PrivateRoute superUserOnly path='/test-manager'><TestManager /></PrivateRoute>
        <PrivateRoute superUserOnly path='/introduction-invite-manager'><InvestorInviteManager /></PrivateRoute>
        <PrivateRoute superUserOnly path='/missing-translations'><MissingTranslations /></PrivateRoute>
        <PrivateRoute superUserOnly path='/potential-investors'><PotentialInvestorStats /></PrivateRoute>

        <PrivateRoute superUserOrComposerOnly path='/loop-stats'><NoteSequenceStats /></PrivateRoute>
        <PrivateRoute subscriberOnly path='/composer/:id'><Composer /></PrivateRoute>
        <PrivateRoute subscriberOnly path='/composer'><Composer /></PrivateRoute>
        <PrivateRoute subscriberOnly path='/loops' showFeedbackButton={false}><NoteSequences /></PrivateRoute>

        <PrivateRoute path='/settings' showLogout><Settings /></PrivateRoute>
        <PrivateRoute path='/transactions'><Transactions /></PrivateRoute>
        <PrivateRoute path='/get-coins'><GetCoins /></PrivateRoute>
        <PrivateRoute path='/pricing'><Pricing /></PrivateRoute>
        <PrivateRoute path='/get/:permalink' disableRedirect><RedeemCoupon /></PrivateRoute>

        <PrivateRoute path='/library' showFeedbackButton={false}><SongLibrary /></PrivateRoute>
        <PrivateRoute path="/song/:id" disableRedirect><SongPlayer /></PrivateRoute>

        <PrivateRoute path="/create/:mood/:style"><CreateSong /></PrivateRoute>
        <PrivateRoute path="/create/:mood"><CreateSong /></PrivateRoute>
        <PrivateRoute path="/create"><CreateSong /></PrivateRoute>

        <PrivateRoute path="/"><RootRoute /></PrivateRoute>
      </Switch>
    </Router>
  );

  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_DISABLE_PAYMENTS?.toLowerCase() === 'true') {
    return ROUTES;
  }

  return (
    <Elements stripe={stripePromise}>
      <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID || 'test' }}>
        { ROUTES }
      </PayPalScriptProvider>
    </Elements>
  );
}

export default App;

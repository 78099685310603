import * as React from "react";

const InstrumentEnglishHorn = (props) => (
  <svg
    id="InstrumentEnglishHorn_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 114.49"
    style={{
      enableBackground: "new 0 0 110.84 114.49",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentEnglishHorn_svg__st5{fill:#cfd125}.InstrumentEnglishHorn_svg__st6{fill:#ca5672}"
      }
    </style>
    
    <path
      d="M94.17 114.49h-77.5C7.46 114.49 0 107.03 0 97.82v-77.5c0-9.2 7.46-16.67 16.67-16.67h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#fdcc75",
      }}
    />
    <path
      className="InstrumentEnglishHorn_svg__st6"
      d="M17.76 27.26s-.95-2.28.21-3.6c1.16-1.32 3.49-.32 3.49-.32l-3.7 3.92z"
    />
    <path
      className="InstrumentEnglishHorn_svg__st6"
      d="M17.58 24.35c-.05.06-.64-.31-.68-.33-.69-.41-1.31-.92-1.89-1.47-2.59-2.45-4.52-5.67-6.17-8.9-.15-.3-1.63-3.02-.93-3.3.87-.34 1.44 1.69 1.72 2.24.96 1.86 2.07 3.61 3.27 5.29 1.12 1.56 2.28 3.1 3.79 4.3.53.42 1.23 1.01 2.01.98 0-.01-1.12 1.19-1.12 1.19z"
    />
    <path
      d="M100.66 90.82c-1-1.16-2.59-1.47-4.24-1.69-.16-.02-.32-.05-.47-.07-.58-.08-1.19-.1-1.75-.21-.55-.11-.98-.37-1.39-.69-.76-.59-1.38-1.32-2.09-1.96-1.18-1.05-2.36-2.1-3.53-3.16-1.54-1.38-3.08-2.77-4.62-4.15l-5.48-4.94c-2.04-1.84-4.08-3.69-6.12-5.53l-6.54-5.91c-2.25-2.03-4.49-4.06-6.73-6.1l-6.71-6.08c-2.15-1.95-4.31-3.9-6.46-5.86-2-1.81-3.99-3.62-5.99-5.43-1.77-1.6-3.53-3.2-5.29-4.81-1.46-1.33-2.92-2.65-4.38-3.98l-3.24-2.94c-.63-.57-1.25-1.14-1.88-1.71a12.3 12.3 0 0 0-.39-.35l-3.21 3.39c.27.26.55.52.82.78.77.73 1.54 1.46 2.31 2.2 1.19 1.13 2.38 2.27 3.58 3.4 1.54 1.47 3.08 2.93 4.63 4.4 1.82 1.73 3.64 3.46 5.45 5.19 2.02 1.92 4.04 3.85 6.06 5.77 2.15 2.05 4.3 4.09 6.45 6.14 2.21 2.1 4.41 4.2 6.62 6.3 2.19 2.09 4.38 4.17 6.57 6.26l6.3 6 5.8 5.53c1.7 1.62 3.39 3.24 5.09 4.86 1.39 1.32 2.77 2.65 4.16 3.97 1 .96 2.01 1.92 3.01 2.88.53.51 1.15 1.02 1.58 1.66.46.69.49 1.54.65 2.41.3 1.63.74 3.4 1.95 4.34 1.99 1.54 7.57 3.84 7.57 3.84s3.21-.82 5.98-5.65c-.03.03-2.43-6.19-4.07-8.1z"
      style={{
        fill: "#ca5672",
        stroke: "#ca5672",
        strokeWidth: 1.2093,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st5"
      cx={32.72}
      cy={36.25}
      r={1.62}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st5"
      cx={37.3}
      cy={40.59}
      r={1.62}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st5"
      cx={41.87}
      cy={44.92}
      r={1.62}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st5"
      cx={53.57}
      cy={56}
      r={1.62}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st5"
      cx={59.76}
      cy={61.86}
      r={1.62}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st5"
      cx={64.96}
      cy={66.79}
      r={1.62}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st6"
      cx={74.57}
      cy={81.22}
      r={2.63}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st6"
      cx={50.15}
      cy={48.2}
      r={2.63}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st6"
      cx={30.09}
      cy={29.89}
      r={2.63}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st5"
      cx={70.7}
      cy={72.22}
      r={1.62}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st5"
      cx={74.57}
      cy={81.22}
      r={1.62}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st5"
      cx={50.15}
      cy={48.2}
      r={1.62}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st5"
      cx={30.09}
      cy={29.89}
      r={1.62}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st5"
      cx={85.87}
      cy={84.37}
      r={1.62}
    />
    <circle
      className="InstrumentEnglishHorn_svg__st5"
      cx={89.53}
      cy={91.46}
      r={1.62}
    />
    <path
      d="m24.69 26.26-3.78 3.99c-.49.52-1.31.54-1.83.05l-1.44-1.36c-.52-.49-.54-1.31-.05-1.83l3.78-3.99c.49-.52 1.31-.54 1.83-.05l1.44 1.36c.52.48.54 1.31.05 1.83z"
      style={{
        fill: "#f18860",
      }}
    />
  </svg>
);

export default InstrumentEnglishHorn;

import * as React from "react";

const InstrumentTuba = (props) => (
  <svg
    id="InstrumentTuba_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentTuba_svg__st5{fill:#cfd125}.InstrumentTuba_svg__st6{fill:#ca5672}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      d="M46.7 9.99c-.07-.06-.02-.18.07-.18l39.82-.07c.11 0 .16.15.06.21-5.17 3.17-9.22 8.11-11.59 13.67-2.76 6.48-2.49 13.44-2.71 20.33 0 .15-.01.3-.02.46-.43 12.17.29 24.82-1.52 36.88-.9 6.01-2.52 12.28-7.81 15.91-3.89 2.67-8.8 3.85-13.48 3.9h-.25c-3.46 0-15.18-.8-18.3-10.39s-.33-47.15-.33-47.15c.08-2.59.51-5.27 1.35-7.72 1.87-5.46 7.42-6.99 12.65-6.99 1.8 0 8.38.06 11.48 3.72 3.1 3.66 2.85 8.5 2.85 8.5l.25 19.86s.08 11.29-.95 13.47c-2.01 4.23-6.91 5.22-11.18 4.89-4.2-.32-9.69-1.96-10.43-6.84-.54-3.54-.68-7.13-.75-10.7-.06-2.88-.49-6.05.02-8.89.8-4.46 4.29-6.79 8.71-6.81 10.8-.06 10.34 7.66 10.34 7.66l-.1 15.33c0 .23-.19.41-.42.41l-.97-.02c-.23 0-.41-.19-.41-.42.08-3.03.13-6.06.11-9.09-.01-1.63-.05-3.27-.11-4.9-.05-1.44.02-3.04-.42-4.42-.33-1.03-.86-1.41-1.8-1.86-1.84-.87-4.05-1.28-6.07-1.08-6.26.32-6.79 4.44-6.8 4.47.08 3 .18 6.01.29 9.01.09 2.34.18 4.68.3 7.02.16 3.16-.14 6.37 2.91 8.28 1.77 1.11 3.81.95 5.81.82 3.76-.25 8.59-.82 8.79-5.48.14-3.32.13-6.65.14-9.97.01-4.21.1-8.43.07-12.64-.03-4.03.67-9.38-1.6-12.96-1.95-3.08-5.63-4.04-9.09-4.01-9.22.09-10.05 3.82-10.52 7.26-1.01 7.47-.6 15.03-.4 22.54.19 7.27.17 14.54 1.5 21.72.31 1.68.68 3.21 1.91 4.48 2.77 2.87 6.2 4.58 10.21 4.51 2.62-.04 5.46-.45 7.84-1.67 3.13-1.61 3.8-4.05 3.85-7.36.05-3.45.09-6.9.11-10.35.1-14.03 1.5-28.14-.26-42.13-.39-3.1-1.06-6.19-2.38-9.04-2.32-5.05-6.7-8.5-10.77-12.17z"
      style={{
        fill: "#4e4092",
        stroke: "#4e4092",
        strokeWidth: 0.75,
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentTuba_svg__st6"
      d="M77.66 42.66v.97c0 .28-.2.51-.48.54-3.56.38-16.53 2.28-18.69 4.06-1.69 1.09-1.59 3.25-1.65 5.01-.04 1.26-.11 15.25-.13 18.18 0 .3-.24.53-.54.53h-.74a.53.53 0 0 1-.54-.53c-.04-4.5.25-19.86.36-20.57.21-1.45.87-2.83 2.03-3.78 3.06-2.51 16.66-4.42 19.75-4.96.33-.04.63.22.63.55z"
    />
    <path
      className="InstrumentTuba_svg__st5"
      d="m77.87 41.87 1.4-.82c.28-.16.63.04.63.36v2.6c0 .26-.23.45-.49.41l-1.4-.23a.418.418 0 0 1-.35-.41v-1.55c0-.15.08-.29.21-.36zM49.17 78.56h-.8c-.38 0-.69-.31-.69-.69v-32c0-.38.31-.69.69-.69h.8c.38 0 .69.31.69.69v31.99c0 .39-.31.7-.69.7zM45.32 65.54h-.8c-.38 0-.69-.31-.69-.69V45.87c0-.38.31-.69.69-.69h.8c.38 0 .69.31.69.69v18.98c0 .38-.31.69-.69.69zM41.47 65.54h-.8c-.38 0-.69-.31-.69-.69V45.87c0-.38.31-.69.69-.69h.8c.38 0 .69.31.69.69v18.98c0 .38-.31.69-.69.69z"
    />
    <circle
      className="InstrumentTuba_svg__st6"
      cx={41.07}
      cy={42.47}
      r={1.54}
    />
    <circle
      className="InstrumentTuba_svg__st6"
      cx={44.92}
      cy={42.47}
      r={1.54}
    />
    <circle
      className="InstrumentTuba_svg__st6"
      cx={48.73}
      cy={42.47}
      r={1.54}
    />
  </svg>
);

export default InstrumentTuba;

import { createSlice, /*createAsyncThunk*/ } from "@reduxjs/toolkit";
// import { apiAuthUrl, strofeApi } from '../api/strofeApi';
import Cookies from 'js-cookie';
import { DEFAULT_MASTER_GAIN } from "../utils/LoopingAudio";

// IIFE (initialState is *not* a function, but an object returned from the closure):
const initialState = (() => {
  const initialVolume = parseInt(Cookies.get('strofe_master_volume'), 10);
  const masterVolume = isNaN(initialVolume) ? DEFAULT_MASTER_GAIN * 100  : Math.max(Math.min(initialVolume, 100), 0);
  
  return {
    masterVolume,
    masterMuted: false,
  }
})();

const playerSlice = createSlice({
  name: 'player',
  initialState,

  reducers: {
    changeMasterVolume: (state, action) => {
      const volume = Math.max(Math.min(action.payload.volume, 100), 0);
      state.masterVolume = volume;
      
      Cookies.set("strofe_master_volume", volume);
    },

    toggleMasterMuted: state => {
      state.masterMuted = !state.masterMuted;
    },
  },
});

export const playerActions = {
  ...playerSlice.actions,
};

export const playerSelectors = {
  getMasterVolume: state => state.player.masterVolume,
  getMasterMuted: state => state.player.masterMuted,
};

export default playerSlice.reducer;

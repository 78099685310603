export default function LPCurve1(props) {
  return (
    <svg viewBox="0 0 360 360" {...props}>
      <path
        d="M46.5 107.5l1.1 59.7c.5 26.7 22.4 48 49.2 47.8l236.7-2.5"
        fill="none"
        stroke="#4a3f8c"
        strokeWidth={2.645}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray={5.291}
      />
    </svg>
  );
}

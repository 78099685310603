export default function LPFigures1(props) {
  return (
    <svg viewBox="0 0 410 574" {...props}>
      <path fill="#c85671" d="M253.3 21.7H143.5v109.7h31.3V53h78.5z" />
      <path
        d="M193.3 511C120.2 511 61 451.8 61 378.7c0-73.1 59.2-132.3 132.3-132.3V511z"
        fill="#f08660"
      />
      <path fill="#cecf25" d="M22.8 222.4H120v97.2H22.8z" />
      <circle cx={275} cy={369} r={34} fill="#fcca75" />
      <path fill="#528352" d="M342.6 557H216V439.2z" />
    </svg>
  );
}

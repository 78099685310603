import StrofeLogoColors from "../../../icons/StrofeLogoColors";
import LoadingBlocks from "../../../layout/LoadingBlocks";
import TryMeButton from "../assets/TryMeButton";

export default function SlideTryMe({ creating, onCreate }) {

  const renderTryMe = () => (
    <>
      <StrofeLogoColors className='logo' viewBox="0 1000 2000 600" />
      <TryMeButton role='button' onClick={onCreate} className='create-song' width={480} height={320} />
    </>
  );

  const renderCreating = () => (
    <LoadingBlocks>
      A new, unique track will be ready in seconds...
    </LoadingBlocks>
  );

  return (
    <div className='slide slide-try-me'>
      { creating ? renderCreating () : renderTryMe() }
    </div>
  );
}

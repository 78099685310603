import { useEffect, useRef, useState, forwardRef } from "react";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import useEventCallback from "../../hooks/useEventCallback";
import classNames from "classnames";
import { strofeApi } from "../../api/strofeApi";
import { LoopingAudio } from "../../utils/LoopingAudio";
import { useDebouncedCallback } from "use-debounce/lib";
import useInterval from "../../hooks/useInterval";
import { CSSTransition } from "react-transition-group";
import Slider from "rc-slider";
import { Dropdown } from "react-bootstrap";

import Checkbox from "../../layout/Checkbox";
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";

import SlideCover from "./slides/SlideCover";
import SlideProblem from "./slides/SlideProblem";
import SlideSolution from "./slides/SlideSolution";
import SlideTraction from "./slides/SlideTraction";
import SlideRoblox from "./slides/SlideRoblox";
import SlideTestimonials from "./slides/SlideTestimonials";
import SlideGoToMarket from "./slides/SlideGoToMarket";
import SlideProduct from "./slides/SlideProduct";
import SlideTryMe from "./slides/SlideTryMe";
import SlideBusinessModelTest from "./slides/SlideBusinessModelTest";
import SlideProductSketch from "./slides/SlideProductSketch";
//import SlideBusinessModel from "./slides/SlideBusinessModel";
import SlideMarketValidation from "./slides/SlideMarketValidation";
//import SlideCompetitors from "./slides/SlideCompetitors";
import SlideTeam from "./slides/SlideTeam";
import SlideRoadMap from "./slides/SlideRoadmap";
import SlideProductEditor from "./slides/SlideProductEditor";
import SlideProductCreate from "./slides/SlideProductCreate";
import SlideProductLibrary from "./slides/SlideProductLibrary";
import SlideProductTests from "./slides/SlideProductTests";
import SlideSuccess from "./slides/SlideSuccess";
import SlideCompetitorsGraph from "./slides/SlideCompetitorsGraph";
import SlideBeforeStrofe from "./slides/SlideBeforeStrofe";
import SlideAfterStrofe from "./slides/SlideAfterStrofe";
import SlideVideoGameGrowth from "./slides/SlideVideoGameGrowth";

import PauseIconV2 from "../../icons/PauseIconV2";
import PlayIconV2 from "../../icons/PlayIconV2";
import NotesIcon from "../../icons/NotesIcon";

import './PitchDeck.scss';

const SLIDES = [
  { id: 'cover',             title: "Cover",                component: SlideCover },
  { id: 'team',              title: "Team",                 component: SlideTeam },
  { id: 'problem',           title: "Problem",              component: SlideProblem },
  { id: 'before-strofe',     title: "Before Strofe",        component: SlideBeforeStrofe },
  { id: 'solution',          title: "Solution",             component: SlideSolution },
  { id: 'after-strofe',      title: "After Strofe",         component: SlideAfterStrofe },
  { id: 'vg-growth-chart',   title: "Video-game Growth",    component: SlideVideoGameGrowth },
  { id: 'roblox',            title: "Roblox",               component: SlideRoblox },
  { id: 'market-validation', title: "Market Validation",    component: SlideMarketValidation },
  { id: 'product',           title: "Product (1/2)",        component: SlideProduct },
  { id: 'product-sketch',    title: "Product (2/2)",        component: SlideProductSketch },
  { id: 'product-create',    title: "Product: Create",      component: SlideProductCreate },
  { id: 'product-editor',    title: "Product: Editor",      component: SlideProductEditor },
  { id: 'product-library',   title: "Product: Library",     component: SlideProductLibrary },
  //{ id: 'product-details',   title: "Product (3/3)",      component: SlideProductDetails },
  { id: 'product-details',   title: "Business Model",       component: SlideBusinessModelTest },
  //{ id: 'business-model',    title: "Business Model",       component: SlideBusinessModel },
  { id: 'traction',          title: "Traction",             component: SlideTraction },
  { id: 'go-to-market',      title: "Go To Market",         component: SlideGoToMarket },
  { id: 'testimonials',      title: "Testimonials",         component: SlideTestimonials },
  //{ id: 'landscape',         title: "Landscape",            component: SlideCompetitors },
  { id: 'landscape-graph',   title: "Landscape (Graph)",    component: SlideCompetitorsGraph },
  { id: 'try-me',            title: "Try Me!",              component: SlideTryMe },

  // Appendix
  { id: 'success-stories',   title: "Relevant Comparisons", component: SlideSuccess },
  { id: 'roadmap',           title: "Roadmap",              component: SlideRoadMap },
  { id: 'product-tests',     title: "Product: A/B Tests",   component: SlideProductTests },
];

const SLIDES_SIMPLIFIED = [
  { id: 'cover',             title: "Cover",                component: SlideCover },
  { id: 'team',              title: "Team",                 component: SlideTeam },
  { id: 'problem',           title: "Problem",              component: SlideProblem },
  { id: 'before-strofe',     title: "Before Strofe",        component: SlideBeforeStrofe },
  { id: 'solution',          title: "Solution",             component: SlideSolution },
  { id: 'after-strofe',      title: "After Strofe",         component: SlideAfterStrofe },
  { id: 'vg-growth-chart',   title: "Video-game Growth",    component: SlideVideoGameGrowth },
  { id: 'roblox',            title: "Roblox",               component: SlideRoblox },
  { id: 'market-validation', title: "Market Validation",    component: SlideMarketValidation },
  { id: 'product',           title: "Product (1/2)",        component: SlideProduct },
  { id: 'product-sketch',    title: "Product (2/2)",        component: SlideProductSketch },
  { id: 'product-create',    title: "Product: Create",      component: SlideProductCreate },
  { id: 'product-editor',    title: "Product: Editor",      component: SlideProductEditor },
  { id: 'product-library',   title: "Product: Library",     component: SlideProductLibrary },
  { id: 'product-details',   title: "Business Model",       component: SlideBusinessModelTest },
  //{ id: 'business-model',    title: "Business Model",       component: SlideBusinessModel },
  { id: 'traction',          title: "Traction",             component: SlideTraction },
  { id: 'go-to-market',      title: "Go To Market",         component: SlideGoToMarket },
  { id: 'try-me',            title: "Try Me!",              component: SlideTryMe },
  { id: 'testimonials',      title: "Testimonials",         component: SlideTestimonials },
];


const PITCH_STYLES = ['bossa', 'pop', 'latin', 'anime'];
const PITCH_MOODS  = ['happy', 'chill'];

const PITCH_HARMONIES = {
  bossa : [3, 4, 5, 6, 7, 11],
  pop   : [6, 12, 13, 7, 92],
  latin : [6, 7, 12, 13, 92],
  anime : [106]
};

const PITCH_ORCHESTRATIONS = {
  bossa : [2, 10, 12],
  pop   : [8],
  latin : [23],
  anime : [64]
};

export default function PitchDeck({ simplified = false }) {

  const { id } = useParams();
  const history = useHistory();

  const [resizeScale, setResizeScale] = useState(1);
  const [enableScaling, setEnableScaling] = useState(true);

  const [creating, setCreating] = useState(false);
  const [trackCreated, setTrackCreated] = useState(false);
  const [songData, setSongData] = useState(false);
  const [songStatus, setSongStatus] = useState(null);
  const [songDuration, setSongDuration] = useState(null);
  const [playbackPosition, setPlaybackPosition] = useState(null);
  const [seekOffset, setSeekOffset] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);

  const containerRef = useRef();
  const trackCreatedRef = useRef();

  const pitchSlides = simplified ? SLIDES_SIMPLIFIED : SLIDES;
  const path = simplified ? 'intro' : 'deck';

  let slideIndex = pitchSlides.findIndex(slide => slide.id === id);

  useEffect(() => {
    if (!id || slideIndex === -1) {
      history.replace(`/${path}/cover`);
    }
  }, [history, id, slideIndex, path]);

  const captureKeyDown = useEventCallback(e => {
    if (e.key === 'ArrowRight' && slideIndex !== pitchSlides.length - 1) {
      changeSlide(slideIndex + 1);
    }
    else if (e.key === 'ArrowLeft' && slideIndex !== 0) {
      changeSlide(slideIndex - 1);
    }
  });

  useEffect(() => {
    window.addEventListener('keydown', captureKeyDown);
    return () => window.removeEventListener('keydown', captureKeyDown);
  }, [captureKeyDown]);

  useInterval(() => {
    if (LoopingAudio.status === 'playing') {
      setPlaybackPosition(LoopingAudio.playbackPosition);
    }
  }, 200);

  const debouncedResetSeekOffset = useDebouncedCallback(() => {
    setSeekOffset(null);
  }, 300);

  const debouncedResize = useDebouncedCallback(() => {
    if (containerRef.current) {
      let scaleWidth;
      let scaleHeight;
      let scale;
      
      const { width, height } = getComputedStyle(containerRef.current);
      let containerWidth = parseInt(width, 10);
      let containerHeight = parseInt(height, 10);

      if (window.innerWidth > containerWidth) {
        containerWidth += 80;
        containerHeight += 40;
      }

      scaleWidth = window.innerWidth / containerWidth;
      scaleHeight = window.innerHeight / containerHeight;
      scale = Math.min(scaleWidth, scaleHeight);

      setResizeScale(scale);
    }

  }, 100, { maxWait: 500 });

  useEffect(() => {
    debouncedResize();
    debouncedResize.flush();

    window.addEventListener('resize', debouncedResize);

    return () => window.removeEventListener('resize', debouncedResize);
  }, [debouncedResize]);

  const debouncedSetTrackCreated = useDebouncedCallback(value => {
    setTrackCreated(value);
  }, 5000);

  const Slide = pitchSlides[slideIndex]?.component;

  const changeSlide = index => {
    history.push(`/${path}/${pitchSlides[index].id}`);
  }

  const onSongEnded = () => {
    setSongStatus(null);
  }

  const createSong = async () => {

    const chooseRandom = array => array[Math.floor(Math.random() * array.length)];

    let style = '';
    do {
      style = chooseRandom(PITCH_STYLES);      
    } while (selectedStyle === style);

    const mood = style === 'anime' ? 'spirited' : chooseRandom(PITCH_MOODS);

    setSelectedStyle(style);

    const orchestration_id = chooseRandom(PITCH_ORCHESTRATIONS[style]);
    const harmony_id = chooseRandom(PITCH_HARMONIES[style]);

    const song = { style, mood, harmony_id, orchestration_id, generated: true };

    try {
      setCreating(true);
      
      // ! instead of creating, loading a previous songs/:id will be faster:
      // const { data } = await strofeApi.get('/songs/320');
      const { data } = await strofeApi.post('/songs', { song });

      let mp3Url = data.mp3_download_url;
      
      // song will never be generated on first time, but still leave it conditional:
      if (!data.generated) {
        const regenerateResponse = await strofeApi.post(`/songs/generate/${data.id}`);
        mp3Url = regenerateResponse.data.mp3_download_url;
      }

      if (songData) {
        LoopingAudio.stopSong();
      }

      LoopingAudio.initialize({ simplePlayer: true, onSongEnded });
      LoopingAudio.setSongData(data);

      const duration = await LoopingAudio.loadSong(mp3Url);

      setSongStatus(null);
      setSongData(data);
      setCreating(false);
      setSongDuration(duration);
      setSeekOffset(null);
      setPlaybackPosition(0);
      
      setTrackCreated(true);
      debouncedSetTrackCreated(false);
    }
    catch {
      console.log("I AM ERROR");
    }
  }

  const togglePlay = () => {
    if (songStatus === null) {
      LoopingAudio.playTrack();
      setSongStatus('playing');
    }
    else if (songStatus === 'playing') {
      LoopingAudio.stopSong();
      setSongStatus('paused');
    }
    else if (songStatus === 'paused') {
      LoopingAudio.resumeSong();
      setSongStatus('playing');
    }
  }

  const seekPosition = seekOffset !== null ? seekOffset : playbackPosition * 100;

  const changeOffset = v => {
    LoopingAudio.stopSong();
    setSongStatus('paused');
    setSeekOffset(v);
  }
  
  const afterChangeOffset = v => {
    const offset = v / 100;
    LoopingAudio.playTrack({ id: 'main', trackOffset: offset, stopTrack: true });

    // since the interval for setting playbackPosition is 200, debounce the seek reset
    // to avoid having the seek flicker between playbackPosition and seekOffset
    debouncedResetSeekOffset();
    setSongStatus('playing');
  }

  if (slideIndex === -1) {
    return null;
  }

  const renderSlidePlayer = () => (
    <div className='slide-player'>
      <h2>Your song is ready!</h2>

      <div className='content'>
          <div className='giant-player'>
            <div className='toggle-playback' role='button' onClick={togglePlay}>
              { songStatus === 'playing' ? <PauseIconV2 /> : <PlayIconV2 viewBox="0 0 28.97 45.94" /> }
            </div>
            <Slider min={0} max={songDuration * 100} value={seekPosition} onChange={changeOffset} onAfterChange={afterChangeOffset} />
        </div>
      </div>

      <div className='tag-container'>
          <div className='create-tag'>
            <div className='hole' />
            <div className='string' />
            <button onClick={createSong} className='create-track'>
              <div className='icon'><NotesIcon /></div>
              <span>Create Track</span>
            </button>
          </div>
        </div>
    </div>
  );

  // On try-me slide, if there is a song created and no new song is been created, render slide player
  const showPlayer = id === 'try-me' && songData && !creating;

  const marks = {
    start : { label: ['playing', 'paused'].includes(LoopingAudio.status) ? LoopingAudio.secsToClockFormat(LoopingAudio.playbackPosition) : "0:00" },
    end   : { label: LoopingAudio.secsToClockFormat(songDuration) },
  };

  return (
    <div className='__pitch-deck'>
      <div className='__pitch-deck-container' ref={containerRef} style={{ transform: enableScaling && `scale(${resizeScale})` }}>
        <div className='navigation'>
          <div>{ slideIndex > 0 && <div className='link' role='button' onClick={() => changeSlide(slideIndex - 1)}><span className='arrow'>←</span> { pitchSlides[slideIndex-1].title }</div> }</div>
          <div>{ slideIndex < pitchSlides.length - 1 && <div className='link' role='button' onClick={() => changeSlide(slideIndex + 1)}>{ pitchSlides[slideIndex+1].title } <span className='arrow'>→</span></div> }</div>
        </div>
        <div className='slide-container'>
          { showPlayer ? renderSlidePlayer() : <Slide onCreate={createSong} creating={creating} /> }
          <CSSTransition in={trackCreated} nodeRef={trackCreatedRef} timeout={5000} classNames='show'>
            <div className='track-created' ref={trackCreatedRef}>
              Your track has been created. <span role='button' onClick={() => songStatus === null && togglePlay()}>Play Track</span>
            </div>
          </CSSTransition>
        </div>
        
        <div className='bottom-controls'>
          <div className='dropdown-options'>
            <Dropdown>
              <Dropdown.Toggle as={JumpToggle}>
                <div className='square' /><div className='square' /><div className='square' /><div className='square' />
              </Dropdown.Toggle>
            
              <Dropdown.Menu align='right' style={{ columnCount: 2 }}>
                { pitchSlides.map(slide => <Dropdown.Item key={slide.id} as={NavLink} to={`/${path}/${slide.id}`}>{ slide.title }</Dropdown.Item>)}
                { pitchSlides.length % 2 === 1 && <Dropdown.Item disabled>&nbsp;</Dropdown.Item> }
              </Dropdown.Menu>
            </Dropdown>
          
            <Checkbox checked={enableScaling} onChange={e => setEnableScaling(e.target.checked)}>Fit to screen</Checkbox>
          </div>
          
          <div className='player'>
            { songData && (creating || id !== 'try-me') && (
              <div className='controls'>
                <div className='marker'>{ marks.start.label }</div>
                <div className='toggle-playback' role='button' onClick={togglePlay}>
                  { songStatus === 'playing' ? <PauseIconV2 /> : <PlayIconV2 viewBox="0 0 27.97 44.94" /> }
                </div>
                <Slider min={0} max={songDuration * 100} value={seekPosition} onChange={changeOffset} onAfterChange={afterChangeOffset} />

                { !creating && (
                  <button className={classNames('create-track', { 'button-disabled': creating })} onClick={createSong} disabled={creating}>
                    <div className='icon'><NotesIcon /></div>
                    <span>Create Another Track</span>
                  </button>
                )}
              </div>
            )}

            { creating && (
              <div className='creating'>
                <InfiniteLoader width={16} />
                <span>Creating Track...</span>
              </div> 
            )}

          </div>
        </div>
      </div>
    </div>
  );
}

const JumpToggle = forwardRef(({ children, onClick }, ref) => (
  <div ref={ref} onClick={onClick} className='jump-toggle' role='button'>
    { children }
  </div>
));

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";

const Bullet = () => (
  <svg viewBox="0 0 48 32">
    <path d="M0 23.9C.1 10.9 10.7.4 23.8.5c13 .1 23.5 10.7 23.4 23.8L0 23.9Z" fill="#CECF25" />
    <path d="M2 31.7c.1-8.7 7.1-15.6 15.8-15.6 8.7.1 15.6 7.1 15.6 15.8L2 31.7Z" fill="#4D3F92" />
    <path d="M36.9 16.2a8.1 8.1 0 1 0 0-16.2 8.1 8.1 0 0 0 0 16.2Z" fill="#FCCA75" />
  </svg>
);

export default function SlideBeforeStrofe() {
  return (
    <div className='slide slide-before-strofe white-bg'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='bullet-title'>
        <div className='bullet'>
          <Bullet />
        </div>
        <h1>We began by looking at the Game Dev market</h1>
      </div>

      <div className='content'>
        <div className='persona'>
          <div className='avatar'>
            <div className='head' />
            <div className='torso' />
          </div>
          <div className='description'>
            <div>Indie Game Developer</div>
            <div>Already pays $5-10 for each visual asset</div>
            <div>Has little to no music experience</div>
            <div>Currently has to pay $500 for a single song</div>
            <div>Gets instantly flagged for copyright</div>
          </div>
        </div>
        <div className='user-name'>Nick, 22</div>
      </div>
    </div>
  )
}
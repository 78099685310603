import * as React from "react";

const InstrumentHarpsichord = (props) => (
  <svg
    id="InstrumentHarpsichord_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.48"
    style={{
      enableBackground: "new 0 0 110.84 110.48",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentHarpsichord_svg__st2{fill:#fdcc75}.InstrumentHarpsichord_svg__st4{fill:#f18860}.InstrumentHarpsichord_svg__st28{fill:#fff}.InstrumentHarpsichord_svg__st35{fill:#1f3138}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      className="InstrumentHarpsichord_svg__st4"
      d="M89.74 55.94H19.58c-1.4 0-2.54-1.14-2.54-2.54v-4.22c0-1.4 1.14-2.54 2.54-2.54h70.16c1.4 0 2.54 1.14 2.54 2.54v4.22a2.55 2.55 0 0 1-2.54 2.54z"
    />
    <path
      className="InstrumentHarpsichord_svg__st28"
      d="M33.03 50.8h5.8v5.14h-5.8zM20 50.8h6.3v5.14H20zM26.82 50.8h5.69v5.14h-5.69zM39.35 50.8h5.74v5.14h-5.74zM45.6 50.8h5.8v5.14h-5.8zM51.91 50.8h5.7v5.14h-5.7zM58.13 50.8h5.77v5.14h-5.77zM64.41 50.8h5.86v5.14h-5.86zM70.78 50.8h5.86v5.14h-5.86zM77.16 50.8h5.78v5.14h-5.78zM83.46 50.8h5.85v5.14h-5.85z"
    />
    <path
      className="InstrumentHarpsichord_svg__st35"
      d="M26.78 48.49h4.59v2.3h-4.59zM32.72 48.49h4.59v2.3h-4.59zM70.28 48.49h4.59v2.3h-4.59zM76.42 48.49h4.59v2.3h-4.59zM82.56 48.49h4.59v2.3h-4.59zM46.17 48.49h4.59v2.3h-4.59zM52.25 48.49h4.59v2.3h-4.59zM58.33 48.49h4.59v2.3h-4.59z"
    />
    <path
      className="InstrumentHarpsichord_svg__st2"
      d="M18.24 55.94h72.39v4.43H18.24zM68.36 35.8l-5.24-1.26H49.3l-4.99 1.26h-5.48v10.85h34.11V35.8z"
    />
    <path
      className="InstrumentHarpsichord_svg__st4"
      d="m75.67 8.45-57.5 35.58c-.56.35-1.34.23-1.74-.25l-.05-.06c-.4-.48-.27-1.16.29-1.5l57.5-35.58c.56-.35 1.34-.23 1.74.25l.05.06c.4.48.27 1.16-.29 1.5zM26.91 101.42h-2.15l-2.29-41.05h6.61zM84.6 101.42h-2.16l-2.28-41.05h6.61zM42.35 90.16h-1.56l-1.66-29.79h4.79z"
    />
  </svg>
);

export default InstrumentHarpsichord;

export default function LPFigures3(props) {
  return (
    <svg viewBox="0 0 360 724.5" {...props}>
      <path
        d="M346 630.5c-108.2 0-196-87.8-196-196s87.8-196 196-196c4.4 0 8.7.2 13 .4v-52.4c-10.3-2.3-19.9-6.4-28.4-12H86v520h273v-64.4c-4.3.2-8.6.4-13 .4z"
        fill="#cecf25"
      />
      <path fill="#c85671" d="M-5.4 216.5H144v139z" />
      {/* <path
        d="M300.1 138.3c6.5 14.8 17.2 27.3 30.5 36.2 8.5 5.7 18.1 9.8 28.4 12v-48.2h-58.9z"
        fill="#4a3f8c"
      /> */}
    </svg>
  );
}

export default function InvestorsBG(props) {
  return (
    <svg
      // width={760}
      width={600}
      height={1196}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // viewBox="0 0 760 1196"
      viewBox="0 0 600 1196"
      {...props}      
    >
      <path
        d="M357.54 726.02c197.243 0 357.14-157.869 357.14-352.61S554.783 20.8 357.54 20.8.4 178.669.4 373.41s159.897 352.61 357.14 352.61Z"
        fill="#294B5B"
        fillOpacity={0.6}
      />
      <path
        d="M715.263 32.54A577.372 577.372 0 0 0 679.047 0H485c129.795 51.39 231.354 157.05 275 288V81.531a577.03 577.03 0 0 0-44.737-48.992ZM215.62 1078.41c-.05 40.94 9.8 81.28 28.71 117.59h464.36a253.893 253.893 0 0 0 28.72-117.59H215.62ZM89.45 1141.05c29.912 0 54.16-23.94 54.16-53.47 0-29.53-24.248-53.47-54.16-53.47-29.912 0-54.16 23.94-54.16 53.47 0 29.53 24.248 53.47 54.16 53.47ZM89.45 1173.77c-8.558-.02-17 1.98-24.637 5.84A54.323 54.323 0 0 0 45.51 1196h87.87a54.197 54.197 0 0 0-19.299-16.38 54.191 54.191 0 0 0-24.631-5.85ZM382.61 763.04v289.05h81.41v-206.6H668v-82.45H382.61Z"
        fill="#294B5B"
        fillOpacity={0.6}
      />
    </svg>
  )
}


import { MOODS, STYLES } from '../CreateSong/CreateSong';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Trans as Translate } from 'react-i18next';

import './MoodStyleIcon.scss';

/*
Mood / Style icon

- There is a default padding: 4 so that the tooltip is not completely adjacent
- 'style' is set as `genre` prop to avoid collision with css style keyword 
*/
export default function MoodStyleIcon({ mood, genre, ...props }) {
  const moodStyle = mood ? MOODS.find(m => m.id === mood) : STYLES.find(s => s.id === genre);

  const toolTip = props => (
    <Tooltip className='__feedback-tooltip' {...props}>
      <Translate>{ moodStyle?.phrase }</Translate>
    </Tooltip>
  );
  
  const Icon = moodStyle?.icon;

  return (
    <OverlayTrigger delay={{ show: 500 }} overlay={toolTip}>
      <div className='__mood-style-icon'>
        <Icon {...props} />
      </div>
    </OverlayTrigger>
  );
}

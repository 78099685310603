let tracks = {};
let trackSources = {};
let trackGains = {};
let trackVelocities = {};
let trackMutes = {};
let trackSolos = {};
let updateInterval;

const UPDATE_INTERVAL = 100;

// AUDIO NODE CONNECTIONS
// ----------------------
// Audio Context (global) => Master Gain (volume) => Track Gain => Track Buffer Source <- decodeAudioBuffer
let AudioContext;
let audioCtx;
let masterGain;

export const DEFAULT_MASTER_GAIN = 0.7;

const INITIAL_STATE = {

  // in simpler player mode, only a main track plays: no loops / track mutes
  simplePlayer: false,

  // float of how long the song lasts (NOT including loops):
  songDuration: 0,

  durationWithLoops: 0,

  // position in the playback, considering loops (set manually as the WebAudio API has no support yet):
  playbackPosition: 0,

  currentSection: 0,

  offset: 0,

  playbackOffset: 0,

  totalPlayTime: 0,

  // gain from 0 to 100
  masterVolume: 0,
  
  // Whether the loop should run:
  loop: false,
  
  // one of type: [stopped, playing, paused, ended]
  status: 'stopped',

  // all track statuses (when all playing tracks finish, the song has ended)
  // one of types [playing, ended].
  // Note that when a track is paused, the audio context is stopped, but the
  // tracks are still "playing" internally.
  trackStatus: {},
  
  // if loopStart and End exist, the loop will be in between this section:
  loopStart: null,
  loopEnd: null,

  // number of times the loop has played:
  loopsPlayed: 0,

  // when progress bar is seeked, this will include the time of previous loops played:
  loopsPlayTime: 0,

  // On the next iteration, the loopsPlayed will be reset if this is true:
  resetLoopsPlayed: false,

  // amount of time that loops have played. This accumulates:
  totalLoopDuration: 0,

  loopsEnabled: [],

  songData: null,

  sectionLoopTimes: null,

  currentLoopIndex: 0,

  // True if there is any solo'ed track:
  hasSoloTrack: false,

  // Set logging verbosity:
  logging: false,
};

export let LoopingAudio = {

  ...INITIAL_STATE,
  loadSong,
  playTrack,
  stopSong,
  resumeSong,
  setLoop,
  loadTrack,
  loadTracks,
  playAllTracks,
  setTrackVelocity,
  changeGain,
  loadRevisedTrack,
  setTrackMuted,
  toggleTrackSolo,
  setSongData,
  resetTrackGain,
  setMasterGain,
  setSeekLoop,
  printTrackGains,
  setSectionVelocity,
  initialize,
  close,
  toggleLoop,
  logData,
  calculateTotalDuration,
  setLoopRepetitions,
  changeTotalPlayTime,
  secsToClockFormat,
  deleteTrack,
  addTrack,
}

function verboseLog() {
  LoopingAudio.logging && console.log.apply(console, arguments);
}

// When running initialize(), a race condition when a track is getting loaded might cause two songs to play at once.
// To solve it, the loadingTracks value is polled until it is false, then initialize() can run:
function pollNotLoadingTracks() {

  const pollStatus = resolve => {
    if (LoopingAudio.loadingTracks) {
      setTimeout(pollStatus.bind(this, resolve), 60);
    }
    else {
      resolve();
    }
  }
  
  return new Promise(resolve => {
    pollStatus(resolve);
  });
}

// The Audio Context is only created once in initialize, as well as Master gain.
// If tracks exist (previous song playing), they are disconnected before getting
// emptied in the array (same as close). There is no need to call close() before
// initialize(), but close is needed when changing routes to avoid the song
// continuosly playing:
async function initialize(options = {}) {

  // Initialize will wait until no track is loaded (to prevent race condition where two songs play at once):
  if (LoopingAudio.loadingTracks) {
    await pollNotLoadingTracks();
  }

  if (!audioCtx) {
    AudioContext = window.AudioContext || window.webkitAudioContext;
    audioCtx = new AudioContext();

    masterGain = audioCtx.createGain();
    masterGain.gain.value = DEFAULT_MASTER_GAIN;
    masterGain.connect(audioCtx.destination);
  }
  else {
    Object.values(trackSources).forEach(source => source.disconnect());
    Object.values(trackGains).forEach(gain => gain.disconnect());
  }

  tracks = {};
  trackSources = {};
  trackGains = {};
  trackVelocities = {};
  trackMutes = {};
  trackSolos = {};

  // clone INITIAL_STATE:
  LoopingAudio = {
    ...LoopingAudio,
    ...JSON.parse(JSON.stringify(INITIAL_STATE)),
  };

  if (options.simplePlayer) {
    LoopingAudio.simplePlayer = true;
  }

  if (options.onSongEnded) {
    LoopingAudio.onSongEnded = options.onSongEnded;
  }

  clearInterval(updateInterval);
}

function close() {
  clearInterval(updateInterval);

  Object.values(trackSources).forEach(source => source.disconnect());
  Object.values(trackGains).forEach(gain => gain.disconnect());

  tracks = {};
  trackSources = {};
  trackGains = {};
  trackVelocities = {};
  trackMutes = {};
  trackSolos = {};
}

function printTrackGains() {
  return Object.values(trackGains).map(gain => Math.floor(gain?.gain?.value * 100)).join(', ');
}

function setMasterGain(value) {
  masterGain.gain.value = value / 100;
}

// plays tracks
function playAllTracks(trackOffset, stopTrack = true) {

  let principalTrack = true;

  Object.keys(tracks).forEach(id => {
    if (id !== 'main') {
      playTrack({ id, trackOffset, stopTrack, principalTrack });
      principalTrack = false;
    }
  });
}

// Deep clone songData
function setSongData(data) {
  LoopingAudio.songData = JSON.parse(JSON.stringify(data));

  // memoize the loop start and end times >>
  // This functions assumes that an end section will never be the last section based on the spec
  // (a song will always have an ending loop):
  if (!LoopingAudio.simplePlayer) {
    LoopingAudio.sectionLoopTimes = data.loops.map(([start, end]) => (
      [data.section_start_times[start], data.section_start_times[end + 1]]
    ));

    LoopingAudio.loopsEnabled = data.loops.map(() => true);
  }
}

function changeTotalPlayTime(loopIndex, op) {
  const { sectionLoopTimes, playbackPosition } = LoopingAudio;

  const startTime = sectionLoopTimes[loopIndex][0];
  const endTime = sectionLoopTimes[loopIndex][1];

  const loopDuration = endTime - startTime;
  const loopRepetitions = LoopingAudio.songData.loop_repetitions[LoopingAudio.currentLoopIndex];

  if (playbackPosition >= endTime) {
    LoopingAudio.loopsPlayTime += (op === 'increment' ? 1 : -1) * loopDuration;
  }

  // If it's inside the loop, discount or increment depending on the number of loops played:
  else if (playbackPosition >= startTime) {
    if (op === 'decrement' && LoopingAudio.loopsPlayed + 1 > loopRepetitions) {
      LoopingAudio.loopsPlayTime -= loopDuration;
    }
    else if (op === 'increment' && LoopingAudio.loopsPlayed + 1 >= loopRepetitions) {
      LoopingAudio.loopsPlayTime += loopDuration;
    }
  }
}

function setLoopRepetitions(index, repetitions) {
  LoopingAudio.songData.loop_repetitions[index] = repetitions;
}

function calculateTotalDuration() {

  const { sectionLoopTimes, songData, songDuration } = LoopingAudio;

  let time = 0;

  for (let i = 0; i < sectionLoopTimes.length; ++i) {
    const startTime = sectionLoopTimes[i][0];
    const endTime = sectionLoopTimes[i][1];

    time += (endTime - startTime) * (songData.loop_repetitions[i] - 1);
  }

  LoopingAudio.durationWithLoops = time + songDuration;
}

function setTrackVelocity(id, velocity) {
  trackVelocities[id] = velocity;
}

function setSectionVelocity(velocity, sectionIndex, instrumentId) {
  instrumentId = instrumentId.toString();

  const section = LoopingAudio.songData.instruments.find(instrument => instrument.id.toString() === instrumentId).song_sections[sectionIndex];

  section.velocity = velocity;

  // TODO -> reset track gain only if the current section index is the one being played:
  resetTrackGain(instrumentId);
}

function setTrackMuted(id, muted) {
  trackMutes[id] = muted;

  LoopingAudio.songData.instruments.find(instrument => instrument.id.toString() === id.toString()).muted = muted;

  if (trackGains[id] && trackGains[id].gain) {
    trackGains[id].gain.cancelScheduledValues(audioCtx.currentTime);
    trackGains[id].gain.value = muted ? 0 : absoluteGain(id);
  }
}

function toggleTrackSolo(id) {

  // object keys don't use integers, so transform it to string, else 7 !== '7'
  id = id === null ? null : id.toString();

  trackSolos[id] = trackSolos[id] ? false : true;
  LoopingAudio.hasSoloTrack = Object.values(trackSolos).some(isSolo => isSolo);

  Object.entries(trackGains).forEach(([trackId, gains]) => {
    if (!LoopingAudio.hasSoloTrack) {
      trackGains[trackId].gain.cancelScheduledValues(audioCtx.currentTime);
      gains.gain.value = trackMutes[trackId] ? 0 : absoluteGain(trackId);
    }
    else {
      const trackSolo = trackSolos[trackId];

      trackGains[trackId].gain.cancelScheduledValues(audioCtx.currentTime);
      gains.gain.value = !trackSolo ? 0 : absoluteGain(trackId);
    }
  });
}

function changeGain(id, velocity) {
  trackVelocities[id] = velocity;

  if (trackGains[id] && trackGains[id].gain && !trackMutes[id] && (!LoopingAudio.hasSoloTrack || trackSolos[id])) {
    trackGains[id].gain.cancelScheduledValues(audioCtx.currentTime);
    trackGains[id].gain.value = absoluteGain(id);
  }
}

function absoluteGain(id) {
  
  const instrument = LoopingAudio.songData.instruments.find(instrument => instrument.id.toString() === id.toString());
  
  if (instrument.muted) {
    return 0;
  }
  
  const section = instrument.song_sections[LoopingAudio.currentSection];

  if (section.muted) {
    return 0;
  }
  else {
    return (trackVelocities[id] / 100) * (section.velocity / 100);
  }
}

// TODO -> just get the section, don't change it yet, should be updated in next interval update:
// this is not used on loops, on loops the section is manually reset:
function setCurrentPlayingSection(offset) {
  const startTimes = LoopingAudio.songData.section_start_times;
  let currentSection = null;

  for (let i = 0; i < startTimes.length - 1; ++i) {
    if (offset < startTimes[i + 1]) {
      currentSection = i;
      break;
    }
  }

  verboseLog('Changing playing section to:', currentSection);

  LoopingAudio.currentSection = currentSection !== null ? currentSection : startTimes.length - 1;
  LoopingAudio.nextSectionTime = LoopingAudio.songData.section_start_times[LoopingAudio.currentSection + 1];
}

function toggleLoop(index) {
  LoopingAudio.loopsEnabled[index] = !LoopingAudio.loopsEnabled[index];
}

function logData(fullLog = false) {
  const log = {};

  Object.entries(this).forEach(([key, v]) => {
    if (typeof v !== 'function') {
      log[key] = v;
    }
  });

  if (fullLog) {
    log.tracks = tracks;
    log.trackSources = trackSources;
    log.trackGains = trackGains;
    log.trackVelocities = trackVelocities;
    log.trackMutes = trackMutes;
    log.trackSolos = trackSolos;
  }

  console.log(log);
}

function resetAllTrackLoops() {

  const { trackStatus } = LoopingAudio;
  
  Object.entries(trackStatus).forEach(([id, track]) => {
    if (track === 'playing') {
      
      if (trackSources[id]) {
        // console.log('reset', id);
        // trackSources[id].loop = false;
        // TODO -> if there is no loop any more, remove the loop:
        // console.log('loopStart:', LoopingAudio.loopStart)
        // console.log('loopEnd:', LoopingAudio.loopEnd)

        // v1.5
        //if (index === null || index === -1) {

        if (LoopingAudio.currentLoopIndex === null) {
          trackSources[id].loop = false;
        }
        else {
          trackSources[id].loopStart = LoopingAudio.loopStart;
          trackSources[id].loopEnd = LoopingAudio.loopEnd;

          // v1.5 (will not be used maybe)
          // trackSources[id].loopStart = LoopingAudio.sectionLoopTimes[index][0];
          // trackSources[id].loopEnd = LoopingAudio.sectionLoopTimes[index][1];
        }
      }
    }
  });
}

function resetAllTrackGains(delay = 0) {
  
  Object.entries(LoopingAudio.trackStatus).forEach(([id, track]) => {
    if (track === 'playing') {
      const value = (trackMutes[id] || (LoopingAudio.hasSoloTrack && !trackSolos[id])) ? 0 : absoluteGain(id);

      if (trackGains[id] && trackGains[id].gain) {
        trackGains[id].gain.cancelScheduledValues(audioCtx.currentTime);
        trackGains[id].gain.setValueAtTime(value, audioCtx.currentTime + delay)
      }
    }
  });
}

function resetTrackGain(id, delay = 0) {
  id = id.toString();
  const value = (trackMutes[id] || (LoopingAudio.hasSoloTrack && !trackSolos[id])) ? 0 : absoluteGain(id);

  if (trackGains[id] && trackGains[id].gain) {
    trackGains[id].gain.cancelScheduledValues(audioCtx.currentTime);
    trackGains[id].gain.setValueAtTime(value, audioCtx.currentTime + delay);
  }
}

function playTrack(options) {

  const { id = 'main', trackOffset = 0, stopTrack = false, principalTrack = true } = options || {};

  if (stopTrack) {
    if (trackSources[id] && LoopingAudio.trackStatus[id] === 'playing') {
      trackSources[id].onended = null;
      trackSources[id].stop();
    }

    if (principalTrack && !LoopingAudio.simplePlayer) {
      
      LoopingAudio.totalLoopDuration = 0;
      
      const previousLoop = insideLoopIndex(LoopingAudio.playbackPosition);
      const nextLoop = insideLoopIndex(trackOffset);

      // If inside the same loop, do not reset the loops played count. This will affect the playbackOffset:
      if (previousLoop !== nextLoop) {
        LoopingAudio.loopsPlayed = 0;
      }

      // TODO -> v2
      // else {
      //   if (LoopingAudio.loopsPlayed + 1 >= LoopingAudio.songData.loop_repetitions[LoopingAudio.currentLoopIndex] || !LoopingAudio.loopsEnabled[LoopingAudio.currentLoopIndex]) {
      //     // disable the loop ->
      //   }
      // }

      // if it's outside the loop, calculate the previous loop's playtime
      if (nextLoop !== previousLoop || nextLoop === -1) {

        let time = 0;

        for (let i = 0; i < LoopingAudio.sectionLoopTimes.length; ++i) {
          const startTime = LoopingAudio.sectionLoopTimes[i][0];
          const endTime = LoopingAudio.sectionLoopTimes[i][1];

          if (trackOffset >= endTime) {
            time += (endTime - startTime) * (LoopingAudio.songData.loop_repetitions[i] - 1);
          }
          else {
            break;
          }
        }

        LoopingAudio.loopsPlayTime = time;
      }
    }
  }

  if (principalTrack && !LoopingAudio.simplePlayer) {
    setCurrentPlayingSection(trackOffset);
  }

  const trackSource = audioCtx.createBufferSource();
  trackSource.buffer = tracks[id];
  // trackSource.connect(audioCtx.destination); // <- done by the gain node

  const trackGain = audioCtx.createGain();
  trackSource.connect(trackGain);
  // trackGain.connect(audioCtx.destination);
  trackGain.connect(masterGain);

  if (trackVelocities[id] !== undefined) {
    trackGain.gain.value = (trackMutes[id] || (LoopingAudio.hasSoloTrack && !trackSolos[id])) ? 0 : absoluteGain(id);
  }

  trackSource.onended = () => {

    LoopingAudio.trackStatus[id] = 'ended';

    if (id === 'main' || !Object.values(LoopingAudio.trackStatus).some(status => status === 'playing')) {
      LoopingAudio.status = 'ended';
      LoopingAudio.playbackOffset = 0;
      LoopingAudio.loopsPlayed = 0;
      
      // After a track is finished, reset the loop to true and to the first loop
      // (these loops change when the track is seeked)

      if (!LoopingAudio.simplePlayer) {
        LoopingAudio.loop = true;
        LoopingAudio.currentLoopIndex = 0;
        LoopingAudio.loopStart = LoopingAudio.sectionLoopTimes[0][0];
        LoopingAudio.loopEnd = LoopingAudio.sectionLoopTimes[0][1];
      }

      LoopingAudio.onSongEnded?.();
    }
  }

  if (LoopingAudio.loop) {
    trackSource.loop = true;
    trackSource.loopStart = LoopingAudio.loopStart;
    trackSource.loopEnd = LoopingAudio.loopEnd;
  }

  trackSources[id] = trackSource;
  trackGains[id] = trackGain;

  LoopingAudio.trackStatus[id] = 'playing';

  if (trackOffset === 0 && ['stopped', 'ended'].includes(LoopingAudio.status)) {
    trackSource.start();
  }
  else {
    // if trackOffset is a negative number, set to 0:
    trackSource.start(0, Math.max(trackOffset, 0));
    
    if (principalTrack) {
      // if seeking inside the same loop, the number of loops played will not be reset, so the duration must be added:
      const loopDuration = LoopingAudio.loopEnd - LoopingAudio.loopStart;
      LoopingAudio.playbackOffset = trackOffset + (LoopingAudio.loopsPlayed * loopDuration);
    }
  }

  if (principalTrack) {
    if (LoopingAudio.status === 'paused') {
      audioCtx.resume();
    }
    
    LoopingAudio.offset = audioCtx.currentTime;
    LoopingAudio.status = 'playing';
  }
}

function stopSong() {
  audioCtx.suspend();
  LoopingAudio.status = 'paused';
}

function resumeSong() {
  audioCtx.resume();
  LoopingAudio.status = 'playing';
}

// Check if an offset is inside a loop index. Return the index, or -1 if the playback offset is not inside:
function insideLoopIndex(offset) {
  const { sectionLoopTimes } = LoopingAudio;

  for (let i = 0; i < sectionLoopTimes.length; ++i) {
    const startTime = sectionLoopTimes[i][0];
    const endTime = sectionLoopTimes[i][1];

    if (startTime <= offset && offset < endTime) {
      return i;
    }
  }

  return -1;
}

// Find the next loop that will be played (either the playback is in the current section, or next section):
// Function will return -1 if there is no next loop (IE: end of track) >>
function setSeekLoop(trackOffset) {

  const { sectionLoopTimes } = LoopingAudio;

  for (let i = 0; i < sectionLoopTimes.length; ++i) {
    const startTime = sectionLoopTimes[i][0];
    const endTime = sectionLoopTimes[i][1];

    // Audio context will still count the endtime as the current loop (end time is inclusive),
    // but for the seek of the loop, that part is discounted:
    if (trackOffset < endTime) {
      LoopingAudio.loopStart = startTime;
      LoopingAudio.loopEnd = endTime;
      LoopingAudio.currentLoopIndex = i;
      LoopingAudio.loop = true;

      return i;
    }
  }
  
  LoopingAudio.loop = false;
  LoopingAudio.currentLoopIndex = null;
  return -1;
}

function setLoop(loop, { id, all }) {

  //failsafe to prevent a song to be reset to the loop if it's already past the loopEnd:
  if (loop && LoopingAudio.playbackPosition > LoopingAudio.loopEnd) {
    return false;
  }

  const setLoopTrack = id => {
    const trackSource = trackSources[id];

    if (trackSource) {
      trackSource.loop = loop;

      trackSource.loopStart = LoopingAudio.loopStart;
      trackSource.loopEnd = LoopingAudio.loopEnd;
    }
  }

  if (id) {
    setLoopTrack(id);
  }
  else if (all) {
    Object.keys(tracks).forEach(trackId => {
      trackId !== 'main' && setLoopTrack(trackId);
    });
  }
  
  LoopingAudio.loop = loop;
}

async function loadRevisedTrack(id, file) {
  
  const track = await getFile(file, false);
  tracks[id] = track;

  // if music is stopped (not started playing) or ended, track doesn't need to reload.
  // if it is paused or playing, reload track:
  if (!['stopped', 'ended'].includes(LoopingAudio.status)) {
    updateProgress({ forceUpdate: true });
    playTrack({ id, trackOffset: LoopingAudio.playbackPosition, stopTrack: true, principalTrack: false });
  }

  return true;
}

async function deleteTrack(id) {  
  trackSources[id]?.disconnect();
  trackGains[id]?.disconnect();
  trackSources[id]?.stop();

  delete tracks[id];
  trackSources[id] && delete trackSources[id];
  trackGains[id] && delete trackGains[id];
  delete trackMutes[id];
  delete trackSolos[id];
}

async function addTrack(instrument) {
  const { id, velocity, mp3_download_url } = instrument;

  LoopingAudio.songData.instruments.push(JSON.parse(JSON.stringify(instrument)));
  
  const track = await getFile(mp3_download_url);
  tracks[id] = track;
  trackVelocities[id] = velocity;

  if (!['stopped', 'ended'].includes(LoopingAudio.status)) {
    updateProgress({ forceUpdate: true });
    playTrack({ id, trackOffset: LoopingAudio.playbackPosition, stopTrack: true, principalTrack: false });
  }
}

async function loadTrack(id, file, setDuration = true) {
  const track = await getFile(file, setDuration);
  tracks[id] = track;

  return track.duration;
}

/**
 * Load a song as a single track file using id: "main"
 * @param {String} file - File URL to load
 */
async function loadSong(file, loop) {

  // prevent multiple tracks from getting loaded (on the edge case where a race condition in Library calls load twice before initialize):
  LoopingAudio.loadingTracks = true;

  await loadTracks([{ id: "main", file }]);

  if (loop) {
    LoopingAudio.loop = true;
    LoopingAudio.loopStart = loop.loopStart;
    LoopingAudio.loopEnd = loop.loopEnd;
  }

  LoopingAudio.loadingTracks = false;

  return LoopingAudio.songDuration;
}

async function loadTracks(tracks) {

  let maxDuration = 0;
  
  if (tracks.length === 1) {
    maxDuration = await loadTrack(tracks[0].id, tracks[0].file, false);
  }

  else {
    try {
      await Promise.all(tracks.map(async track => {
        const duration = await loadTrack(track.id, track.file);
        maxDuration = Math.max(duration, maxDuration);
      }));
    }
    
    catch (error) {
      throw error;
    }
  }

  if (audioCtx.state === 'suspended') {
    audioCtx.resume();
  }

  updateInterval = setInterval(() => updateProgress(), UPDATE_INTERVAL);

  LoopingAudio.songDuration = maxDuration;

  return maxDuration;
}

function updateProgress(options) {

  const { forceUpdate = false } = options || {};

  if (LoopingAudio.status !== 'playing' && !forceUpdate) return;

  if (LoopingAudio.resetLoopsPlayed) {
    LoopingAudio.loopsPlayed = 0;
    LoopingAudio.resetLoopsPlayed = false;
  }

  let playTime = audioCtx.currentTime - LoopingAudio.offset + LoopingAudio.playbackOffset - LoopingAudio.totalLoopDuration;
  LoopingAudio.totalPlayTime = audioCtx.currentTime - LoopingAudio.offset + LoopingAudio.playbackOffset + LoopingAudio.loopsPlayTime;

  if (!LoopingAudio.simplePlayer) {

    const { loopStart, loopEnd } = LoopingAudio;
    const loopDuration = loopEnd - loopStart;
  
    playTime -= (loopDuration * LoopingAudio.loopsPlayed);
  
    const { nextSectionTime } = LoopingAudio;
    const nextIntervalPlayTime = playTime + (UPDATE_INTERVAL/1000) * 2;
    // const nextIntervalPlayTime = playTime + (UPDATE_INTERVAL/1000);

    // TODO -> v1.5 (probably will not be used)
    // const nextLoopFuturePlayTime = playTime + ((UPDATE_INTERVAL/1000) * 3);

    // if (nextLoopFuturePlayTime > nextSectionTime) {
    //   if (LoopingAudio.loop && loopEnd === nextSectionTime) {
    //     if (LoopingAudio.loopsPlayed + 1 >= LoopingAudio.songData.loop_repetitions[LoopingAudio.currentLoopIndex] || !LoopingAudio.loopsEnabled[LoopingAudio.currentLoopIndex]) {
    //       const index = calculateFutureLoop(nextLoopFuturePlayTime);
    //       resetAllTrackLoops(index);
    //       console.log("CHANGE LOOP", index)
    //     }
    //   }
    // }

    // TODO -> it should save it in a variable and do it in next
    // TODO -> iteration the loops played will show "0 / 4" in the last 100 ms of the loop
    if (nextIntervalPlayTime > nextSectionTime) {
      const delay = nextSectionTime - playTime;

      // console.log('nextSectionTime:', nextSectionTime)
      // console.log('nextIntervalPlayTime:', nextIntervalPlayTime)
      // console.log('loopEnd:', loopEnd)

      // calculate section from loop
      if (LoopingAudio.loop && loopEnd === nextSectionTime) {
        if (LoopingAudio.loopsPlayed + 1 >= LoopingAudio.songData.loop_repetitions[LoopingAudio.currentLoopIndex] || !LoopingAudio.loopsEnabled[LoopingAudio.currentLoopIndex]) {
          LoopingAudio.totalLoopDuration += LoopingAudio.loopsPlayed * loopDuration;
          
          // reset the loopsPlayed count on next iteration:
          LoopingAudio.resetLoopsPlayed = true;
          
          setCurrentPlayingSection(nextIntervalPlayTime);
          setSeekLoop(nextIntervalPlayTime);
          resetAllTrackLoops();
          verboseLog("-------\nLoop Ended, next section is:", LoopingAudio.currentSection);
        }
        else {
          LoopingAudio.currentSection = LoopingAudio.songData.loops[LoopingAudio.currentLoopIndex][0];
          LoopingAudio.nextSectionTime = LoopingAudio.songData.section_start_times[LoopingAudio.currentSection + 1];

          verboseLog(`-------\nRepeat the loop, repetition #${LoopingAudio.loopsPlayed}, starting at section ${LoopingAudio.currentSection}`);
          LoopingAudio.loopsPlayed++;
        }

        // TODO -> same command as below, could be simplified when coming out of the last loop:
        if (LoopingAudio.currentLoopIndex === null) {
          setCurrentPlayingSection(nextIntervalPlayTime);
          verboseLog("> Loop finished", LoopingAudio.currentSection);
        }
      }
      else {
        verboseLog("LAST ELSE, currentSection:", LoopingAudio.currentSection);
        setCurrentPlayingSection(nextIntervalPlayTime);
      }

      resetAllTrackGains(delay);
    }
  }

  LoopingAudio.playbackPosition = playTime;
}

function decodeAudioDataPromise(arrayBuffer) {
  return new Promise((resolve, reject) => {
    audioCtx.decodeAudioData(arrayBuffer, buffer => resolve(buffer), err => reject(err));
  });
}

async function getFile(filepath, setDuration) {

  const response = await fetch(filepath);
  const arrayBuffer = await response.arrayBuffer();
  let audioBuffer;

  try {
    audioBuffer = await audioCtx.decodeAudioData(arrayBuffer);
  }

  // on safari, use a promise to decode:
  catch (e) {
    console.log("Promisifying decode audio...")
    audioBuffer = decodeAudioDataPromise(arrayBuffer);
  }

  finally {
    if (setDuration) {
      LoopingAudio.songDuration = audioBuffer.duration;
    }
  }

  return audioBuffer;
}

/**
 * Return seconds in clock format: 1 second is 0:01, 61 seconds is 1:01
 * @param {*} secs seconds elapsed
 * @returns m:ss format
 */
function secsToClockFormat(secs) {
  const minutes = Math.floor(secs / 60);
  let seconds = Math.floor(secs) % 60;
  
  return `${minutes}:${seconds < 10? '0' : ''}${seconds}`;
}

import * as React from "react";

const InstrumentBaritoneSax = (props) => (
  <svg
    id="InstrumentBaritoneSax_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentBaritoneSax_svg__st4{fill:#f18860}.InstrumentBaritoneSax_svg__st10{fill:#538553}"
      }
    </style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#fdcc75",
      }}
    />
    <path
      className="InstrumentBaritoneSax_svg__st10"
      d="M66.42 20.84c.15-2.36 1.25-3.73 3.03-3.8 1.79-.07 3.35 1.61 3.46 3.7.08 1.41.15 2.01.66 2.35.81.52 1.76-.16 1.76-1.22 0-2.33-.77-4.38-2.55-6-2.1-1.91-4.36-2.23-6.7-.98-1.93 1.03-2.98 2.88-3.32 5.27L54.5 82.41c-.14 1.07-.39 2.05-1.18 2.71-1.05.89-2.36.94-3.49.1-1.08-.81-1.64-2.23-1.4-3.72.35-2.18.83-4.33 1.08-6.53.7-6.11.21-12.18-1.37-18.2-.05-.18-.12-.36-.19-.58L34.54 66.85c.52.73 1.06 1.39 1.51 2.13 2.44 3.98 3.11 8.28 2.18 12.85-.73 3.56-.41 7.03 1.15 10.37 2.08 4.43 5.28 7.11 9.49 7.8 6.97 1.15 12.78-4.32 13.31-12.39.66-10.21 3.79-59.69 4.24-66.77z"
      id="InstrumentBaritoneSax_svg___x36_Tvu8w.tif_00000045592180216025644100000011225460986074924177_"
    />
    <path
      className="InstrumentBaritoneSax_svg__st10"
      d="M31.62 66.8c.25-.04.5-.14.71-.32L47.94 53.5c.71-.59.85-1.77.3-2.63-.55-.86-1.57-1.07-2.28-.48l-15.6 12.99c-.71.59-.85 1.77-.3 2.63.37.59.98.87 1.56.79z"
    />
    <ellipse
      transform="rotate(-9.189 62.321 44.683)"
      className="InstrumentBaritoneSax_svg__st4"
      cx={62.32}
      cy={44.68}
      rx={0.91}
      ry={1.06}
    />
    <ellipse
      transform="rotate(-9.189 61.743 51.468)"
      className="InstrumentBaritoneSax_svg__st4"
      cx={61.74}
      cy={51.46}
      rx={0.91}
      ry={1.06}
    />
    <ellipse
      transform="rotate(-9.189 61.277 58.237)"
      className="InstrumentBaritoneSax_svg__st4"
      cx={61.27}
      cy={58.23}
      rx={0.91}
      ry={1.06}
    />
  </svg>
);

export default InstrumentBaritoneSax;

import * as React from "react";

const InstrumentChurchOrgan = (props) => (
  <svg
    id="InstrumentChurchOrgan_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentChurchOrgan_svg__st4{fill:#f18860}.InstrumentChurchOrgan_svg__st6{fill:#ca5672}.InstrumentChurchOrgan_svg__st28{fill:#fff}.InstrumentChurchOrgan_svg__st35{fill:#1f3138}"
      }
    </style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#cfd125",
      }}
    />
    <path
      className="InstrumentChurchOrgan_svg__st6"
      d="m13.49 20.68-3.07 13.2c-.01.06.03.13.1.13h5.99c.07 0 .11-.06.1-.13l-2.92-13.2c-.03-.11-.18-.11-.2 0zM97.33 20.35 94.2 34.2h6.25l-2.98-13.84c-.02-.08-.12-.08-.14-.01z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st4"
      d="M19.93 64.17h1.48l1.61-7.06V21.82h-4.77v35.29z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st6"
      d="M22.06 54.92H19.2c-.2 0-.36-.16-.36-.36v-.37c0-.2.16-.36.36-.36h2.86c.2 0 .36.16.36.36v.37c.01.2-.16.36-.36.36z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st4"
      d="m26.95 64.19 1.92-.12 2.19-7.06-.38-40.32h-5.97v40.32z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st6"
      d="M29.32 54.82h-2.86c-.2 0-.36-.16-.36-.36v-.37c0-.2.16-.36.36-.36h2.86c.2 0 .36.16.36.36v.37c0 .2-.16.36-.36.36z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st4"
      d="M35.22 64.17h2.52l1.96-7.06V12.6h-5.97l-.94 44.51z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st6"
      d="M37.67 54.92h-2.86c-.2 0-.36-.16-.36-.36v-.37c0-.2.16-.36.36-.36h2.86c.2 0 .36.16.36.36v.37c.01.2-.16.36-.36.36z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st4"
      d="m43.88 64.04 2.57.01 2.52-6.94-1.33-48.06h-5.97l-.26 48.06z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st6"
      d="M46.62 54.92h-2.86c-.2 0-.36-.16-.36-.36v-.37c0-.2.16-.36.36-.36h2.86c.2 0 .36.16.36.36v.37c0 .2-.16.36-.36.36z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st4"
      d="M56.84 64.08h-3.33l-2.83-6.97V4.64h8.73v52.47z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st6"
      d="M56.47 54.92h-2.86c-.2 0-.36-.16-.36-.36v-.37c0-.2.16-.36.36-.36h2.86c.2 0 .36.16.36.36v.37c.01.2-.16.36-.36.36z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st4"
      d="M67.05 64.17h-4.54l-1.39-7.1 1.33-48.06h5.97l.45 48.09z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st6"
      d="M66.42 54.92h-2.86c-.2 0-.36-.16-.36-.36v-.37c0-.2.16-.36.36-.36h2.86c.2 0 .36.16.36.36v.37c.01.2-.16.36-.36.36z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st4"
      d="m75.47 64.09-3.07-.01-1.83-7.01V12.56h5.98l.94 44.51z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st6"
      d="M75.46 54.92H72.6c-.2 0-.36-.16-.36-.36v-.37c0-.2.16-.36.36-.36h2.86c.2 0 .36.16.36.36v.37c0 .2-.16.36-.36.36z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st4"
      d="M83.4 64.13h-2.41l-2.2-7.06.39-40.32h5.97l.41 40.41z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st6"
      d="M83.4 54.92h-2.86c-.2 0-.36-.16-.36-.36v-.37c0-.2.16-.36.36-.36h2.86c.2 0 .36.16.36.36v.37c0 .2-.16.36-.36.36z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st4"
      d="m90.79 64.1-2.16-.02-1.81-6.87.44-35.43h4.77l.44 35.48z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st6"
      d="M91.07 54.92h-2.86c-.2 0-.36-.16-.36-.36v-.37c0-.2.16-.36.36-.36h2.86c.2 0 .36.16.36.36v.37c.01.2-.16.36-.36.36z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st4"
      d="M16.64 64.08h77.57v32.21H16.64z"
    />
    <path
      style={{
        fill: "#fdcc75",
      }}
      d="M88.69 81.17v-15.5H20.5v15.5h-1.92v15.12h72.16V81.17z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st4"
      d="M25.23 69.1h60.4v5.86h-60.4z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st6"
      d="M10.39 35.06h6.25v61.23h-6.25zM94.2 35.06h6.25v61.23H94.2z"
    />
    <path
      style={{
        fill: "none",
      }}
      d="M16.64 64.17h77.57v32.12H16.64z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st28"
      d="M30.8 88.26h4.56v4.13H30.8zM35.74 88.26h4.12v4.13h-4.12zM40.23 88.26h4.2v4.13h-4.2zM44.81 88.26h4.16v4.13h-4.16zM49.34 88.26h4.2v4.13h-4.2zM53.92 88.26h4.13v4.13h-4.13zM58.42 88.26h4.18v4.13h-4.18zM62.97 88.26h4.24v4.13h-4.24zM67.58 88.26h4.25v4.13h-4.25zM72.2 88.26h4.19v4.13H72.2zM76.77 88.26h4.24v4.13h-4.24z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st35"
      d="M33.9 85.86h3.33v2.41H33.9zM38.2 85.86h3.33v2.41H38.2zM65.4 85.86h3.33v2.41H65.4zM69.85 85.86h3.33v2.41h-3.33zM74.3 85.86h3.33v2.41H74.3zM47.94 85.86h3.33v2.41h-3.33zM52.35 85.86h3.33v2.41h-3.33zM56.75 85.86h3.33v2.41h-3.33z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st28"
      d="M33.44 80.66h4.06v3.67h-4.06zM37.83 80.66h3.67v3.67h-3.67zM41.84 80.66h3.74v3.67h-3.74zM45.92 80.66h3.7v3.67h-3.7zM49.95 80.66h3.74v3.67h-3.74zM54.02 80.66h3.67v3.67h-3.67zM58.03 80.66h3.72v3.67h-3.72zM62.08 80.66h3.78v3.67h-3.78zM66.19 80.66h3.78v3.67h-3.78zM70.31 80.66h3.73v3.67h-3.73zM74.37 80.66h3.77v3.67h-3.77z"
    />
    <path
      className="InstrumentChurchOrgan_svg__st35"
      d="M36.2 78.51h2.96v2.14H36.2zM40.02 78.51h2.96v2.14h-2.96zM64.25 78.51h2.96v2.14h-2.96zM68.21 78.51h2.96v2.14h-2.96zM72.17 78.51h2.96v2.14h-2.96zM48.7 78.51h2.96v2.14H48.7zM52.62 78.51h2.96v2.14h-2.96zM56.55 78.51h2.96v2.14h-2.96z"
    />
    <circle
      className="InstrumentChurchOrgan_svg__st6"
      cx={83.01}
      cy={84.33}
      r={1.04}
    />
    <circle
      className="InstrumentChurchOrgan_svg__st6"
      cx={88.25}
      cy={84.33}
      r={1.04}
    />
    <circle
      className="InstrumentChurchOrgan_svg__st6"
      cx={85.63}
      cy={84.33}
      r={1.04}
    />
    <circle
      className="InstrumentChurchOrgan_svg__st6"
      cx={83.01}
      cy={87.86}
      r={1.04}
    />
    <circle
      className="InstrumentChurchOrgan_svg__st6"
      cx={88.25}
      cy={87.86}
      r={1.04}
    />
    <circle
      className="InstrumentChurchOrgan_svg__st6"
      cx={85.63}
      cy={87.86}
      r={1.04}
    />
    <circle
      className="InstrumentChurchOrgan_svg__st6"
      cx={23.65}
      cy={84.33}
      r={1.04}
    />
    <circle
      className="InstrumentChurchOrgan_svg__st6"
      cx={28.89}
      cy={84.33}
      r={1.04}
    />
    <circle
      className="InstrumentChurchOrgan_svg__st6"
      cx={26.27}
      cy={84.33}
      r={1.04}
    />
    <circle
      className="InstrumentChurchOrgan_svg__st6"
      cx={23.65}
      cy={87.86}
      r={1.04}
    />
    <circle
      className="InstrumentChurchOrgan_svg__st6"
      cx={28.89}
      cy={87.86}
      r={1.04}
    />
    <circle
      className="InstrumentChurchOrgan_svg__st6"
      cx={26.27}
      cy={87.86}
      r={1.04}
    />
  </svg>
);

export default InstrumentChurchOrgan;

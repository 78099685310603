import * as React from "react";

const InstrumentGrandPiano = (props) => (
  <svg
    id="InstrumentGrandPiano_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentGrandPiano_svg__st3{fill:#4e4092}.InstrumentGrandPiano_svg__st28{fill:#fff}"
      }
    </style>
    
    <path
      className="InstrumentGrandPiano_svg__st28"
      d="m79.13 102.56-1.56.77c-.23.11-.5.07-.67-.11l-1.21-1.24c-.39-.4-1.07-.07-.99.49l.25 1.72c.04.25-.09.49-.32.6l-1.56.77c-.5.25-.39 1 .16 1.09l1.71.29c.25.04.44.24.48.49l.25 1.72c.08.56.83.68 1.09.19l.81-1.54c.12-.22.36-.34.61-.3l1.71.29c.55.09.9-.57.51-.98l-1.21-1.24a.562.562 0 0 1-.1-.67l.81-1.54c.26-.51-.27-1.05-.77-.8z"
    />
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      d="M18.7 73.22h80.56V61.76c0-2.55-.26-5.15-.16-7.68.13-3.36-.3-6.13-4.24-8.34-5.19-2.9-12.35-1.21-17.43-4.29-4.6-2.78-5.51-7.74-6.4-11.87-1.09-5.04-3.96-8.79-9.02-12.21-11.2-7.58-26.48-2.83-26.5-2.83-3.4 1.06-6.47 2.29-9.19 4.23-7.49 5.36-7.62 12.25-7.62 19.51v34.94z"
      style={{
        fill: "#cfd125",
      }}
    />
    <path
      style={{
        fill: "#538553",
      }}
      d="M21.43 73.22h75.11v16.12H21.43z"
    />
    <path
      className="InstrumentGrandPiano_svg__st28"
      d="M24.92 73.22h69.45v11.64H24.92z"
    />
    <path
      className="InstrumentGrandPiano_svg__st3"
      d="M28.97 73.22h2.49v8.46h-2.49zM34.29 73.22h2.49v8.46h-2.49zM39.6 73.22h2.49v8.46H39.6zM62.54 73.22h2.49v8.46h-2.49zM67.86 73.22h2.49v8.46h-2.49zM48.42 73.22h2.49v8.46h-2.49zM53.73 73.22h2.49v8.46h-2.49zM82.38 73.22h2.49v8.46h-2.49zM87.69 73.22h2.49v8.46h-2.49zM73.17 73.22h2.49v8.46h-2.49z"
    />
  </svg>
);

export default InstrumentGrandPiano;

import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { DateTime } from "luxon";

import './CouponModal.scss';
import Checkbox from "../../layout/Checkbox";
import { strofeApi } from "../../api/strofeApi";
import { useEffect } from "react";

export default function CouponModal({ show, onHide, editCoupon, onEdit, onCreate }) {

  const [amount, setAmount] = useState(0);
  const [permalink, setPermalink] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [validDate, setValidDate] = useState(DateTime.now().toISODate());
  const [enabled, setEnabled] = useState(true);
  const [errors, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      setErrors(null);
      setSubmitting(false);

      if (editCoupon) {
        setTitle(editCoupon.title);
        setAmount(editCoupon.coin_amount);
        setPermalink(editCoupon.identifier);
        setDescription(editCoupon.description);
        setEnabled(editCoupon.enabled);
        setValidDate(DateTime.fromISO(editCoupon.valid_until).toISODate());
      }

      else {
        setAmount(0);
        setPermalink('');
        setTitle('');
        setDescription('');
        setValidDate(DateTime.now().toISODate());
      }
    }
  }, [show, editCoupon]);

  const onChangeAmount = e => {
    setAmount(e.target.value.replace(/[^0-9]/g, ''));
  }

  const onChangePermalink = e => {
    setPermalink(e.target.value.replace(/[^A-Za-z0-9_-]/g, ''));
  }

  const onChangeValidDate = e => {
    setValidDate(e.target.value);
  }

  const onSubmit = async e => {
    const submitErrors = [];

    e.preventDefault();

    if (!description.trim().length) {
      submitErrors.push('Description cannot be empty');
    }

    if (parseInt(amount, 10) <= 0 || Number.isNaN(amount)) {
      submitErrors.push('Coin amount must be a number higher than 0');
    }

    if (submitErrors.length) {
      setErrors(submitErrors);
      return;
    }
    
    setErrors(null);
    setSubmitting(true);

    try {

      const couponData = {
        title,
        coin_amount : amount,
        identifier  : permalink,
        valid_until : new DateTime.fromISO(validDate).toISO(),
        enabled,
        description,
      };

      if (editCoupon) {
        const { data } = await strofeApi.put(`/coupons/${editCoupon.id}`, couponData);
        onEdit(data);
      }
      else {
        const { data } = await strofeApi.post('/coupons', couponData);
        onCreate(data);
      }
    }

    catch (error) {
      const { errors: requestErrors } = error.response.data;

      // example: { identifier: ['has been taken'] }
      Object.entries(requestErrors).forEach(([key, keyErrors]) => {
        keyErrors.forEach(err => submitErrors.push(`${key}: ${err}`))
      });

      setErrors(submitErrors);
    }

    finally {
      setSubmitting(false);
    }
  }

  return (
    <Modal className='__modal __coupon-modal' show={show} backdrop='static'>
      <Modal.Header>
        {editCoupon ? 'Edit Coupon' : 'Create Coupon' }
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Checkbox checked={enabled} onChange={e => setEnabled(e.target.checked)}>Enabled</Checkbox>

          <Form.Group className='two-column'>
            <Form.Label>Title</Form.Label>
            <Form.Control required value={title} onChange={e => setTitle(e.target.value)} />
          </Form.Group>

          <Form.Group className='two-column'>
            <Form.Label>Identifier</Form.Label>
            <Form.Control required value={permalink} onChange={onChangePermalink} spellCheck={false} maxLength={32} minLength={4} />
          </Form.Group>
          { permalink && <Form.Control className='permalink-preview' readOnly value={`${window.location.origin}/get/${permalink}`} /> }

          <Form.Group className='two-column'>
            <Form.Label>Coin Amount</Form.Label>
            <Form.Control required value={amount} onChange={onChangeAmount} />
          </Form.Group>

          <Form.Group className='two-column'>
            <Form.Label>Valid Until</Form.Label>
            <Form.Control type='date' required value={validDate} onChange={onChangeValidDate} />
          </Form.Group>

          <hr />
          
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control required value={description} onChange={e => setDescription(e.target.value)} />
          </Form.Group>

          { errors && (
            <ul className='errors'>
              { errors.map(error => <li>{ error }</li> )}
            </ul>
          )}

        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onHide} disabled={submitting}>Cancel</Button>
          <Button type='submit' disabled={submitting}>{ editCoupon ? 'Edit' : 'Create' }</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";

export default function SlideBusinessModelTest() {

  const renderPill = (i, text) => (
    <div className='pill' style={{ marginLeft: `${(i-1)*11}rem`}}>
      <div className='number'>{ i }</div>
      <div className='text'>{ text }</div>
    </div>
  );

  const bullet = (
    <div className='bullet'>
      <div className='semi-circle' />
      
      <div className='circle-top'><div className='circle' /></div>
      <div className='circle-bottom'><div className='circle' /></div>
    </div>
  )

  return (
    <div className='slide slide-business-model-test white-bg'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />

      <div className='bullet-title'>
        { bullet }
        <h1>We launched a freemium business model to discover who our early customers are</h1>
      </div>
      
      <div className='content'>
        { renderPill(1, 'Game Devs') }
        { renderPill(2, 'Twitch Streamers') }
        { renderPill(3, 'Youtubers') }
        { renderPill(4, 'Pro & Amateur Musicians') }
      </div>
    </div>
  )
}

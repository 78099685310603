import { DateTime } from "luxon";
import { useEffect } from "react";
import { useState } from "react";
import { strofeApi } from "../../api/strofeApi";
import Checkbox from "../../layout/Checkbox";
import { useDebouncedCallback } from "use-debounce/lib";

import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import Modal from "react-bootstrap/Modal";

import NavigationHeader from "../NavigationHeader";
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";
import InvestorInviteModal from "./InvestorInviteModal";

import './InvestorInviteManager.scss';

export default function InvestorInviteManager() {
  const [investorInvites, setInvestorInvites] = useState(null);
  const [editInvestorInviteId, setEditInvestorInviteId] = useState(null);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showInvestorInviteModal, setShowInvestorInviteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toastInvestorInviteCreated, setToastInvestorInviteCreated] = useState(null);
  const [toastInvestorInviteEdited, setToastInvestorInviteEdited] = useState(null);
  const [toastInvestorInviteDeleted, setToastInvestorInviteDeleted] = useState(null);

  const debouncedLoading = useDebouncedCallback(value => {
    setLoading(value);
  }, 200);

  useEffect(() => {
    const loadInvestorInvites = async () => {
      debouncedLoading(true);
      const urlParams = new URLSearchParams();

      filterEnabled && urlParams.append('enabled', filterEnabled);

      const { data } = await strofeApi.get(`/investor_invites?${urlParams.toString()}`);
      setInvestorInvites(data);
      debouncedLoading.cancel();
      setLoading(false);
    }

    loadInvestorInvites();
  }, [filterEnabled, debouncedLoading]);

  const onCreateInvestorInvite = () => {
    setEditInvestorInviteId(null);
    setShowInvestorInviteModal(true);
  }

  const onEditInvestorInvite = id => {
    setEditInvestorInviteId(id);
    setShowInvestorInviteModal(true);
  }

  const onChangeEnabled = async (id, enabled) => {
    await strofeApi.put(`/investor_invites/${id}`, { enabled });
    const newInvestorInvites = [...investorInvites];

    newInvestorInvites[investorInvites.findIndex(investorInvite => investorInvite.id === id)].enabled = enabled;
    setInvestorInvites(newInvestorInvites);
  }

  const onDeleteInvestorInvite = id => {
    setDeleteId(id);
    setShowDeleteModal(true);
  }

  const onConfirmDeleteInvestorInvite = async () => {
    await strofeApi.delete(`/investor_invites/${deleteId}`);

    setInvestorInvites(investorInvites.filter(investorInvite => investorInvite.id !== deleteId));
    setShowDeleteModal(false);
    setTimeout(() => setToastInvestorInviteDeleted(true), 500);
  }

  const onEditSuccess = data => {
    const newInvestorInvites = [...investorInvites];
    newInvestorInvites[investorInvites.findIndex(investorInvite => investorInvite.id === data.id)] = data;

    setInvestorInvites(newInvestorInvites);
    setShowInvestorInviteModal(false);
    setTimeout(() => setToastInvestorInviteEdited(true), 500);
  }

  const onCreateSuccess = data => {
    const newInvestorInvites = [data, ...investorInvites];

    setInvestorInvites(newInvestorInvites);
    setShowInvestorInviteModal(false);
    setTimeout(() => setToastInvestorInviteCreated(true), 500);
  }

  const renderInvestorInviteManager = () => {

    if (investorInvites === null) {
      return (
        <div className='initial-load'>
          <InfiniteLoader width={48} />
          <p>Loading Feedback...</p>
        </div>
      );
    }

    return (
      <>
        <div className='controls'>
          <div className='filters'>
            <strong className='title'>FILTER</strong>
            <Checkbox checked={filterEnabled} onChange={e => setFilterEnabled(e.target.checked)}>Enabled Invites</Checkbox>
          </div>
          <Button className='create' variant='warning' onClick={onCreateInvestorInvite}>Create Invite</Button>
        </div>

        { investorInvites.length === 0 && (
          <div className='empty-investor-invites'>
            There are no introduction invites to display
          </div>
        )}

        { investorInvites?.map(investorInvite => (
          <div className='investor-invite' key={investorInvite.id}>
            <div className='title'>
              <h3>{ investorInvite.title }</h3>
              <div className='coin-controls'>
                <Checkbox checked={investorInvite.enabled} onChange={e => onChangeEnabled(investorInvite.id, e.target.checked)}>Enabled</Checkbox>
              </div>
            </div>

            <Form.Control className='permalink-preview' readOnly value={`${window.location.origin}/presentation/${investorInvite.identifier}`} />

            <div className='options'>
              {investorInvite.last_login !== null && <div className='valid-until'>Last Login: { new DateTime.fromISO(investorInvite.last_login).toLocaleString() }</div>}
              <Button variant='danger' onClick={() => onDeleteInvestorInvite(investorInvite.id)}>Delete</Button>
              <Button onClick={() => onEditInvestorInvite(investorInvite.id)}>Edit</Button>
            </div>
          </div>
        ))}
      </>
    );
  }

  const renderDeleteModal = () => (
    <Modal className='__modal' size='sm' show={showDeleteModal} backdrop='static'>
      <Modal.Header>
        Delete Invite
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this invite?
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setShowDeleteModal(false)}>Cancel</Button>
        <Button onClick={onConfirmDeleteInvestorInvite}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div className='__investor-invite-manager'>
      <NavigationHeader />
      <h1 className='header'>Introduction Invite Manager</h1>
      { renderInvestorInviteManager() }
      { renderDeleteModal() }
      <InvestorInviteModal show={showInvestorInviteModal} onHide={() => setShowInvestorInviteModal(false)} editInvestorInvite={investorInvites?.find(investorInvite => investorInvite.id === editInvestorInviteId)} onEdit={onEditSuccess} onCreate={onCreateSuccess} />

      <Toast show={investorInvites !== null && loading} className='strofe-toast'>
        <Toast.Body>Loading...</Toast.Body>
      </Toast>

      <Toast show={toastInvestorInviteCreated} autohide delay={2000} className='strofe-toast' onClose={() => setToastInvestorInviteCreated(false)}>
        <Toast.Body>Invite created successfully</Toast.Body>
      </Toast>

      <Toast show={toastInvestorInviteEdited} autohide delay={2000} className='strofe-toast' onClose={() => setToastInvestorInviteEdited(false)}>
        <Toast.Body>Invite edited successfully</Toast.Body>
      </Toast>

      <Toast show={toastInvestorInviteDeleted} autohide delay={2000} className='strofe-toast' onClose={() => setToastInvestorInviteDeleted(false)}>
        <Toast.Body>Invite deleted successfully</Toast.Body>
      </Toast>
    </div>
  );
}
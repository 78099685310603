export default function LPCurve3(props) {
  return (
    <svg viewBox="0 0 447 240" {...props}>
      <path
        d="M21.5 12.5V132c0 41.7 33.8 75.5 75.5 75.5h329.5"
        fill="none"
        stroke="#4a3f8c"
        strokeWidth={2.645}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray={5.291}
      />
    </svg>
  );
}

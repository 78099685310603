import { useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce/lib";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext,verticalListSortingStrategy } from '@dnd-kit/sortable';
import classNames from "classnames";
import useEventCallback from "../../hooks/useEventCallback";
import usePrevious from "../../hooks/usePrevious";
import { generateUniqueId } from "./Composer";
import { DRUMS } from "../../utils/rails_generated/drums";

import DrumSelect from "./DrumSelect";
import DeleteTrackModal from "./DeleteTrackModal";

import './PianoRoll.scss';

// an extra octave is added so that scales can be changed from C to B
const EXTRA_OCTAVE = 1;
const COMPOSER_LOWEST_OCTAVE = 2;
const COMPOSER_HIGHEST_OCTAVE = 6 + EXTRA_OCTAVE;

const NOTES_COUNT = 12;
const NOTES_NAME = ['C','C#','D','D#','E','F','F#','G','G#','A','A#','B'];

const generateNotes = () => {
  const notes = [];

  for (let octave = COMPOSER_LOWEST_OCTAVE; octave <= COMPOSER_HIGHEST_OCTAVE; ++octave) {

    for (let index = 0; index < NOTES_COUNT; ++index) {
      notes.push({
        id: `${NOTES_NAME[index]}${octave - 2}`,
        tone: NOTES_NAME[index].includes('#') ? 'half' : 'full',
        index,
        octave,
        pitch: (octave * NOTES_COUNT) + index,
        extra: octave === COMPOSER_HIGHEST_OCTAVE && NOTES_NAME[index] !== 'C',
      });
    }
  }

  // Add the top C dangling key so that looking at the incomplete piano doesn't cause OCD:
  notes.push({
    id     : `C${COMPOSER_HIGHEST_OCTAVE - 2 + 1}`,
    tone   : 'full',
    index  : 0,
    octave : COMPOSER_HIGHEST_OCTAVE + 1,
    pitch  : ((COMPOSER_HIGHEST_OCTAVE + 1) * NOTES_COUNT),
    extra  : true,
  });

  // reverse notes as the piano renders them from highest pitch to lowest pitch:
  return notes.reverse();
}

const NOTES = generateNotes();

// note: { index: 0, octave: 1, pitch: 20 }

// { index: 2, start: 2, length: 2 }

export default function PianoRoll({ drumsMode, notes, chords, setNotes, setChords, playNote, notesToSequence, scale, root, division, duration, swing, addChord, noteVelocity, noteProbability, onNoteSelect, pentatonicScale, playingNotes, toggleSequence, sequenceId }) {

  const [hoveredNote, setHoveredNote] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [draggingNote, setDraggingNote] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [showDeleteTrack, setShowDeleteTrack] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const previousRoot = usePrevious(root);

  const rollRef = useRef();
  const sensors = useSensors(useSensor(PointerSensor));

  const deleteHighlightedSegment = () => {
    if (selectedSegment?.type === 'note') {
      const newNotes = JSON.parse(JSON.stringify(notes));
      newNotes[selectedSegment.trackIndex].singleNotes.splice(selectedSegment.segmentIndex, 1);

      setNotes(newNotes);
      notesToSequence(newNotes, chords);
      setSelectedSegment(null);
    }
    else if (selectedSegment?.type === 'chord') {
      const newChords = JSON.parse(JSON.stringify(chords));
      newChords[selectedSegment.trackIndex].splice(selectedSegment.segmentIndex, 1);

      setChords(newChords);
      notesToSequence(notes, newChords);
      setSelectedSegment(null);
    }
  }

  const activeInteractiveElement = () => {
    // buttons allow space-bar for "click" and inputs for adding a blank space:
    if (['INPUT', 'BUTTON', 'TEXTAREA'].includes(document.activeElement.tagName)) {
      return true;
    }
    
    // if a modal is visible, do not prevent the default space action:
    if (document.getElementsByClassName('modal').length) {
      return true;
    }
  }

  const captureKeyDown = useEventCallback(e => {

    if (activeInteractiveElement()) {
      return;
    }

    if (e.code === 'Space') {
      e.preventDefault();
      toggleSequence();
    }

    if (selectedSegment) {
      // Delete note or chord
      if (e.code === 'Delete' || e.code === 'Backspace') {
        deleteHighlightedSegment();
      }

      // change the length of a segment using numbers
      else if (['1', '2', '3', '4', '5'].includes(e.key) && !drumsMode) {
        if (selectedSegment.type === 'note') {
          const newNotes = JSON.parse(JSON.stringify(notes));
          const singleNote = newNotes[selectedSegment.trackIndex].singleNotes[selectedSegment.segmentIndex];
          singleNote.end = singleNote.start + parseInt(e.key, 10);

          setNotes(newNotes);
        }
        else {
          const newChords = JSON.parse(JSON.stringify(chords));
          const chord = newChords[selectedSegment.trackIndex][selectedSegment.segmentIndex];
          chord.end = chord.start + parseInt(e.key, 10);

          setChords(newChords);
        }
      }

      else if (e.shiftKey && (e.code === 'ArrowRight' || e.code === 'ArrowLeft')) {
        const differential = e.code === 'ArrowLeft' ? -1 : 1;

        if (selectedSegment.type === 'note') {
          const newNotes = JSON.parse(JSON.stringify(notes));
          const singleNote = newNotes[selectedSegment.trackIndex].singleNotes[selectedSegment.segmentIndex];

          singleNote.start += differential;
          singleNote.end += differential;

          setNotes(newNotes);
        }
        else {
          const newChords = JSON.parse(JSON.stringify(chords));
          const chord = newChords[selectedSegment.trackIndex][selectedSegment.segmentIndex];

          chord.start += differential;
          chord.end += differential;

          setChords(newChords);
        }
      }

      else if (e.shiftKey && (e.code === 'ArrowUp' || e.code === 'ArrowDown')) {
        const differential = e.code === 'ArrowUp' ? -1 : 1;

        if (selectedSegment.type === 'note') {
          let newTrack = selectedSegment.trackIndex + differential;
          
          // TODO -> missing guard on first and last notes (it would look indefinitely)
          while (!noteEnabled(NOTES[newTrack].index)) {
            newTrack += differential;
          }
          
          if (noteEnabled(NOTES[newTrack].index)) {
            const extractedNote = notes[selectedSegment.trackIndex].singleNotes.splice(selectedSegment.segmentIndex, 1);

            if (!notes[newTrack]) {
              notes[newTrack] = {
                scaleIndex  : scale.findIndex(scaleIndex => scaleIndex === NOTES[newTrack].index),
                octave      : NOTES[newTrack].octave,
                singleNotes : [],
              };
            }

            notes[newTrack].singleNotes.push(extractedNote[0]);
            const newSegmentIndex = notes[newTrack].singleNotes.length - 1;

            const newNotes = JSON.parse(JSON.stringify(notes));
            setSelectedSegment({ ...selectedSegment, trackIndex: newTrack, segmentIndex: newSegmentIndex });
            setNotes(newNotes);
          }
        }
        // TODO -> V2 shift up/down work for chords
        // else {
        //   const newChords = JSON.parse(JSON.stringify(chords));
        //   const chord = newChords[selectedSegment.trackIndex][selectedSegment.segmentIndex];

        //   chord.start += differential;
        //   chord.end += differential;

        //   setChords(newChords);
        // }
      }

      else if (e.code === 'Escape') {
        setSelectedSegment(null);
      }
    }
  });

  useEffect(() => {
    window.addEventListener('keydown', captureKeyDown);

    return () => {
      window.removeEventListener('keydown', captureKeyDown);
    }
  }, [captureKeyDown]);

  useEffect(() => {
    window.addEventListener('mousemove', leaveHighlightedNote);

    return () => {
      window.removeEventListener('mousemove', leaveHighlightedNote);
    }
  }, []);

  const leaveHighlightedNote = useDebouncedCallback(e => {
    if (typeof hoveredNote === 'number' && !rollRef.current.contains(e.target)) {
      setHoveredNote(null);
    }
  }, 100, { maxWait: 100 });

  useEffect(() => {
    notesToSequence(notes, chords);
  }, [pentatonicScale]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }

    let newNotes = {};

    if (Object.values(notes).length > 0) {
      let scaleDiff = root - previousRoot;
  
      // TODO -> need to guard that the diff doesn't overflow editor:
      Object.entries(notes).forEach(([key, note]) => {
        const newKey = parseInt(key) - scaleDiff;
        newNotes[newKey] = note;
      });
  
      // deep clone since previous operation creates shallow references:
      newNotes = JSON.parse(JSON.stringify(newNotes));
  
      notesToSequence(newNotes, chords);
      setNotes(newNotes);
    } 
    else {
      notesToSequence(notes, chords);
    }
  }, [root]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }

    notesToSequence(notes, chords);

  }, [swing]);

  // When velocity changes, if a note is selected, change it:
  useEffect(() => {
    if (selectedSegment?.type === 'note') {
      const newNotes = JSON.parse(JSON.stringify(notes));
      newNotes[selectedSegment.trackIndex].singleNotes[selectedSegment.segmentIndex].velocity = noteVelocity;
      notesToSequence(newNotes, chords);
      setNotes(newNotes);
    }
    else if (selectedSegment?.type === 'chord') {
      const newChords = JSON.parse(JSON.stringify(chords));
      newChords[selectedSegment.trackIndex][selectedSegment.segmentIndex].velocity = noteVelocity;
      notesToSequence(notes, newChords);
      setChords(newChords);
    }
  }, [noteVelocity]);

  useEffect(() => {
    if (selectedSegment?.type === 'note') {
      const newNotes = JSON.parse(JSON.stringify(notes));
      newNotes[selectedSegment.trackIndex].singleNotes[selectedSegment.segmentIndex].probability = noteProbability;
      notesToSequence(newNotes, chords);
      setNotes(newNotes);
    }
    else if (selectedSegment?.type === 'chord') {
      const newChords = JSON.parse(JSON.stringify(chords));
      newChords[selectedSegment.trackIndex][selectedSegment.segmentIndex].probability = noteProbability;
      notesToSequence(notes, newChords);
      setChords(newChords);
    }
  }, [noteProbability]);

  const mouseMoveRoll = index => {
    setHoveredNote(index);
  }

  const focusNote = (type, note, trackIndex, segmentIndex) => {
    setSelectedSegment({
      type,
      trackIndex,
      segmentIndex
    });

    onNoteSelect(note);
  }

  const clickSegment = (trackIndex, segmentIndex, note) => {

    if (addChord) {
      const index = Math.floor((trackIndex + root - 1) / 12);
      
      // if the top note (ie: highest pitch C on C scale) is clicked, do not add the chord as it is in the extra octave:
      if (index >= EXTRA_OCTAVE) {
        const newChords = JSON.parse(JSON.stringify(chords));

        if (!newChords[index]) {
          newChords[index] = [];
        }

        newChords[index].push({
          division,
          _id         : generateUniqueId(),
          probability : noteProbability,
          velocity    : noteVelocity,
          start       : segmentIndex,
          end         : segmentIndex + 1,
        });

        setChords(newChords);
        notesToSequence(notes, newChords);
        setSelectedSegment({ type: 'chord', trackIndex: index, segmentIndex: newChords[index].length - 1 });
      }
      
      return;
    }

    const newNotes = JSON.parse(JSON.stringify(notes));

    const id = generateUniqueId();

    const singleNote = {
      division,
      _id         : id,
      velocity    : noteVelocity,
      probability : noteProbability,
      start       : segmentIndex,
      end         : segmentIndex + 1,
    }

    if (!newNotes[trackIndex]) {
      newNotes[trackIndex] = {
        scaleIndex  : scale.findIndex(scaleIndex => scaleIndex === note.index),
        octave      : note.octave,
        singleNotes : [],
      };
    }

    newNotes[trackIndex].singleNotes.push(singleNote);

    setSelectedSegment({ type: 'note', trackIndex, segmentIndex: newNotes[trackIndex].singleNotes.length - 1 });
    setNotes(newNotes);
    notesToSequence(newNotes, chords);
  }

  const handleNoteMouseDown = (e, handle, trackIndex, noteIndex, start, end, type) => {
    setDraggingNote({ initialX: e.clientX, initialY: e.clientY, handle, trackIndex, noteIndex, start, end, type });
  }

  const addDrumTrack = () => {
    const newNotes = JSON.parse(JSON.stringify(notes));
    const notesLength = Object.keys(newNotes).length;

    const usedPitches = Object.values(notes).map(note => note.pitch);
    let pitch = DRUMS.snare;

    while (usedPitches.includes(pitch)) {
      pitch = Object.values(DRUMS)[(pitch + 1) % Object.values(DRUMS).length];
    }

    if (!newNotes[notesLength]) {
      newNotes[notesLength] = {
        pitch,
        singleNotes : [],
      };
    }

    setNotes(newNotes);
  }

  useEffect(() => {
    if (draggingNote && !isDragging) {
      document.addEventListener('mousemove', handleDragMouseMove);
      document.addEventListener('mouseup', handleDragMouseUp);

      setIsDragging(true);
    }
  }, [draggingNote]);

  const handleDragMouseMove = useEventCallback(e => {

    const noteOrChord = draggingNote.type === 'note'
      ? notes[draggingNote.trackIndex].singleNotes[draggingNote.noteIndex]
      : chords[draggingNote.trackIndex][draggingNote.noteIndex];

    const updateNotesOrChords = (n, c) => draggingNote.type === 'note' ? updateNotes(n) : updateChords(c);

    if (!noteOrChord) {
      return;
    }

    if (draggingNote.handle === 'left') {

      const difference = (e.clientX - draggingNote.initialX);

      const blocks = difference / (120 / noteOrChord.division);

      if (blocks <= -0.5) {
        noteOrChord.start = Math.max(0, draggingNote.start + Math.floor(blocks));
        updateNotesOrChords(notes, chords);
      }

      else if (blocks >= 0.5 && noteOrChord.start + 1 < noteOrChord.end) {
        noteOrChord.start = draggingNote.start + Math.ceil(blocks);
        updateNotesOrChords(notes, chords);
      }

      // 
      // else if (blocks > -0.5 && blocks < 0.5 && singleNote.start !== draggingNote.start) {
      //   singleNote.start = draggingNote.start;
      //   updateNotesOrChords(notes, chords);
      // }
    }

    if (draggingNote.handle === 'right') {

      const difference = (e.clientX - draggingNote.initialX);

      const blocks = difference / (120 / noteOrChord.division);

      if (blocks >= 0.5) {
        // TODO -> max should be all duration:
        // singleNote.end = Math.max(0, draggingNote.start + Math.ceil(blocks));
        noteOrChord.end = draggingNote.end + Math.ceil(blocks);
        updateNotesOrChords(notes, chords);
      }

      else if (blocks <= -0.5 && noteOrChord.start + 1 < noteOrChord.end) {
        noteOrChord.end = draggingNote.end + Math.floor(blocks);
        updateNotesOrChords(notes, chords);
      }
    }

    else if (draggingNote.handle === 'center') {
      const differenceX = (e.clientX - draggingNote.initialX);
      //const differenceY = (e.clientY - draggingNote.initialY);

      const blocksX = differenceX / (120 / noteOrChord.division);
      //const blocksY = differenceY / 22.8; // hard-coded from .roll-track

      if (blocksX >= 0.5) {
        // TODO -> max should be all duration:
        // noteOrChord.end = Math.max(0, draggingNote.start + Math.ceil(blocks));
        noteOrChord.start = draggingNote.start + Math.ceil(blocksX);
        noteOrChord.end = draggingNote.end + Math.ceil(blocksX);

        updateNotesOrChords(notes, chords);
      }

      else if (blocksX <= -0.5) {

        noteOrChord.start = draggingNote.start + Math.floor(blocksX);
        noteOrChord.end = draggingNote.end + Math.floor(blocksX);
        updateNotesOrChords(notes, chords);
      }

      // TODO
      // tracks will need to be removed (spliced) from their containing array
      // and added to the 
      // next trackIndex
      // else if (blocksY >= 0.5) {
      //   const addTracks = Math.ceil(blocksY);
      //   const newTrack = draggingNote.trackIndex + addTracks;
        
      //   console.log('notes:', notes)

      //   if (noteEnabled(NOTES[newTrack].index)) {
      //     console.log('note enabled', newTrack);
      //     const extractedNote = notes[draggingNote.trackIndex].singleNotes.splice(draggingNote.noteIndex);

      //     console.log('extractedNote:', extractedNote)
      //     console.log('notes:', notes)

      //     if (!notes[newTrack]) {
      //       notes[newTrack] = {
      //         scaleIndex  : scale.findIndex(scaleIndex => scaleIndex === NOTES[newTrack].index),
      //         octave      : NOTES[newTrack].octave,
      //         singleNotes : [],
      //       };
      //     }

      //     notes[newTrack].singleNotes.push(extractedNote);

      //     // update new track index and note index
      //     setDraggingNote({ trackIndex: newTrack, noteIndex: notes[newTrack].singleNotes.length - 1, ...draggingNote });

      //     updateNotesOrChords(notes, chords);
      //   }
      // }
    }
  });

  const handleDragMouseUp = useEventCallback(() => {
    document.removeEventListener('mousemove', handleDragMouseMove);
    document.removeEventListener('mouseup', handleDragMouseUp);

    setIsDragging(false);
    setDraggingNote(null);
  });

  const updateNotes = n => {
    const newNotes = JSON.parse(JSON.stringify(n));
    setNotes(newNotes);
    notesToSequence(newNotes, chords);
  }

  const updateChords = c => {
    const newChords = JSON.parse(JSON.stringify(c));
    setChords(newChords);
    notesToSequence(notes, newChords);
  }

  const noteEnabled = noteIndex => {
    if (drumsMode) return true;

    if (!pentatonicScale) {
      return scale.findIndex(scaleIndex => (scaleIndex + root) % NOTES_COUNT === noteIndex) !== -1;
    }
    else {
      return pentatonicScale.findIndex(scaleIndex => (scaleIndex + root) % NOTES_COUNT === noteIndex) !== -1;
    }
  }

  const segmentBorder = segmentIndex => {

    if (division === 4) {
      switch (segmentIndex % division) {  
        case 1:
          return 'solid';
  
        case 3:
          return 'double';

        default:
          return 'dashed';
      }
    }

    else if (division === 6) {
      switch (segmentIndex % division) {
  
        case 2:
          return 'solid';
  
        case 5:
          return 'double';

        default:
          return 'dashed';
      }
    }

    else if (division === 5) {
      switch (segmentIndex % division) {
        case 4:
          return 'double';

        default:
          return 'dashed';
      }
    }
  }

  const segmentsArray = () => {
    return Array.from(Array(duration * division).keys());
  }

  const indexedChord = index => {
    return chords[Math.floor((index + root - 1) / NOTES_COUNT)];
  }

  const indexOfChord = index => (Math.floor((index + root - 1) / NOTES_COUNT));

  const fullRoot = root + (NOTES_NAME[root].includes('#') ? 1 : 0);
  
  const filterExtraNotes = () => {
    return NOTES.filter((note, index) => index > 0 && (!note.extra || note.index <= fullRoot));
  }

  // the index = 0 (first C) is never shown, it's only kept for reference to keep index 0 on C:
  const noteVisible = (note, index) => index > 0 && (!note.extra || note.index <= fullRoot);

  const renderPiano = () => {
    let top = 0;
    const pianoNotes = [];

    NOTES.forEach((note, index) => {
      if (!noteVisible(note, index)) {
        return;
      }

      const noteClasses = classNames('note', note.id[0], note.tone, { hover: noteEnabled(note.index) && index < (NOTES.length - root) && hoveredNote === index, highlighted: noteEnabled(note.index) && index < (NOTES.length - root) });
      const noteRender = (
        <div key={note.id} role='button' className={noteClasses} style={{ top }} onClick={() => playNote(note.pitch)}>
          { note.id.includes("C") && note.tone === 'full' && <div className='scale-marker'>{ note.id }</div> }
        </div>
      );

      top += (note.tone === 'full' ? 39 : 0);

      pianoNotes.push(noteRender);
    })

    return (
      <div className='piano'>
        <div className='overflow-margin-left' style={{ height: 47 * filterExtraNotes().length / 2 }}>
          { pianoNotes }
        </div>
      </div>
    );
  }

  const changeDrumPitch = (index, pitch) => {
    const newNotes = JSON.parse(JSON.stringify(notes));
    newNotes[index].pitch = pitch;
    setNotes(newNotes);

    playNote(pitch);
  }
  
  const confirmDeletePitch = deleteIndex => {
    setDeleteIndex(deleteIndex);
    setShowDeleteTrack(true);
  }

  const deleteDrumPitch = () => {
    setShowDeleteTrack(false);
    const newNotes = {};

    Object.values(notes).filter((_, index) => index !== deleteIndex).forEach((note, index) => {
      newNotes[index] = note;
    });

    setNotes(JSON.parse(JSON.stringify(newNotes)));
  }

  const handleDragEnd = async e => {
    const { active, over } = e;

    if (active.id !== over.id) {
      let orderedNotes = Object.values(notes);

      console.log('notePitches:', orderedNotes)
      const oldIndex = orderedNotes.findIndex(note => note.pitch === active.id);
      const newIndex = orderedNotes.findIndex(note => note.pitch === over.id);

      orderedNotes = arrayMove(orderedNotes, oldIndex, newIndex);
      
      const newNotes = {};
      
      for (let i = 0; i < orderedNotes.length; ++i) {
        newNotes[i] = Object.values(notes).find(note => note.pitch === orderedNotes[i].pitch);
      }
      
      setNotes(JSON.parse(JSON.stringify(newNotes)));
    }
  }

  const renderDrumSelector = () => {
    return (
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <div className="drums-selector">
          <div className='overflow-margin-left' style={{ height: 24 * Object.values(notes).length + 40 }}>
            <SortableContext items={Object.values(notes).map(note => note.pitch)} strategy={verticalListSortingStrategy}>
                { Object.values(notes).map((_, index) => (
                  <div key={index} className="drum-row" style={{ top: index * 22.8 + 16 }}>
                    <DrumSelect notes={notes} index={index} onChange={changeDrumPitch} onDelete={confirmDeletePitch} />
                  </div>
                ))}
            </SortableContext>
          </div>
    
          { Object.keys(notes).length < Object.keys(DRUMS).length && (
            <div key='add-drum' className="drum-row" style={{ top: Object.values(notes).length * 22.8 + 16 }}>
              <button size='sm' className="add" onClick={addDrumTrack}>+ Add</button>
            </div>
          )}
        </div>
      </DndContext>
    );
  }

  const renderRollTrack = () => {
    const tracks = [];

    const drumOrPianoNotes = drumsMode ? Object.values(notes) : NOTES;
    
    drumOrPianoNotes.forEach((note, index) => {
      if (!drumsMode && !noteVisible(note, index)) {
        return;
      }

      const track = (
        <div key={note.id} className={classNames('roll-track', { full: note.tone === 'full', half: note.tone === 'half', disabled: !noteEnabled(note.index) })} onMouseMove={() => mouseMoveRoll(index)} onClick={() => noteEnabled(note.index) && playNote(note.pitch)}>
          { noteEnabled(note.index) && index < (NOTES.length - root) && segmentsArray().map(segmentIndex => (
              <div key={segmentIndex} className={classNames('segment', segmentBorder(segmentIndex), `division-${division}`)} onClick={() => clickSegment(index, segmentIndex, note)} />
          ))}
          
          { noteEnabled(note.index) && index < (NOTES.length - root) && notes[index]?.singleNotes.map((singleNote, singleNoteIndex) => (
            <div key={singleNoteIndex} className={classNames("single-note", { selected: selectedSegment?.type === 'note' && index === selectedSegment?.trackIndex && singleNoteIndex === selectedSegment?.segmentIndex, playing: playingNotes[singleNote._id] })} style={{ left: singleNote.start * (120 / singleNote.division) - 1, width: (singleNote.end - singleNote.start) * (120 / singleNote.division) + 1, opacity: 0.25 + (singleNote.probability / 100) * 0.75 }} tabIndex={0} onFocus={() => focusNote('note', singleNote, index, singleNoteIndex)} onDoubleClick={deleteHighlightedSegment}>
              <div className='note-velocity' style={{ width: `calc(${(singleNote.velocity * 100) / 128}% - 8px)` }} />
              { !drumsMode && <div className='handle left-handle' draggable={false} onMouseDown={e => handleNoteMouseDown(e, 'left', index, singleNoteIndex, singleNote.start, singleNote.end, 'note')} /> }
              { !drumsMode && <div className='handle right-handle' draggable={false} onMouseDown={e => handleNoteMouseDown(e, 'right', index, singleNoteIndex, singleNote.start, singleNote.end, 'note')} /> }
              <div className='handle center-handle' draggable={false} onMouseDown={e => handleNoteMouseDown(e, 'center', index, singleNoteIndex, singleNote.start, singleNote.end, 'note')} />
            </div>
          ))}

          {(NOTES_COUNT - 1 + index + root) % NOTES_COUNT === 0 && indexedChord(index)?.map((chord, chordIndex) => (
            <div key={chordIndex} className={classNames('chord-area', { selected: selectedSegment?.type === 'chord' && indexOfChord(index) === selectedSegment?.trackIndex && chordIndex === selectedSegment?.segmentIndex, playing: playingNotes[chord._id] })} style={{ left: chord.start * (120 / chord.division) - 1, width: (chord.end - chord.start) * (120 / chord.division) + 1, opacity: 0.25 + (chord.probability / 100) * 0.75 }} tabIndex={0}  onFocus={() => focusNote('chord', chord, indexOfChord(index), chordIndex)} onDoubleClick={deleteHighlightedSegment}>
              <div className='note-velocity' style={{ width: `calc(${(chord.velocity * 100) / 128}% - 8px)` }} />
              <div className='handle left-handle' draggable={false} onMouseDown={e => handleNoteMouseDown(e, 'left', indexOfChord(index), chordIndex, chord.start, chord.end, 'chord')} />
              <div className='handle right-handle' draggable={false} onMouseDown={e => handleNoteMouseDown(e, 'right', indexOfChord(index), chordIndex, chord.start, chord.end, 'chord')} />
              <div className='handle center-handle' draggable={false} onMouseDown={e => handleNoteMouseDown(e, 'center', indexOfChord(index), chordIndex, chord.start, chord.end, 'chord')} />
            </div>
          ))}
        </div>
      );

      tracks.push(track);
    });

    return tracks;
  }

  const renderRoll = () => (
    <div className={classNames('roll', { 'drum-track': drumsMode })} style={{ width: duration * 4 * 30, height: drumsMode && (Object.values(notes).length + 1) * 24 + 16 }} ref={rollRef}>
      { renderRollTrack() }
    </div>
  );

  return (
    <>
      <div className='__piano-roll' style={{ width: duration * 4 * 30 }}>
        { !drumsMode ? renderPiano() : renderDrumSelector() }
        { renderRoll() }
      </div>
      <DeleteTrackModal show={showDeleteTrack} onClose={() => setShowDeleteTrack(false)} onDelete={deleteDrumPitch} />
    </>
  );
}

import { useEffect } from "react";
import NavigationHeader from "../NavigationHeader";

import './Survey.scss';

export default function Survey() {
  useEffect(() => {
    let el = document.createElement("script");
    el.src = 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd7zJZd1MXy8ky30QHjnz_2BkRXt1nywjo2NWZCFXoj_2F2l4.js';
    document.body.appendChild(el);
  }, []);

  return (
    <div className='__survey'>
      <NavigationHeader />

      <h1 className='header'>Feedback Survey</h1>

      <div className='survey'>
        <div id='smcx-sdk'></div>
      </div>

      <div className='coupon'>
        <a href="/get/survey_reward" target="_blank">
          Claim your Strofe Coins
        </a>
      </div>
    </div>
  );
}
import * as React from "react";
const SvgInstrumentDrumsRoom = (props) => (
  <svg
    id="InstrumentDrumsRoom_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentDrumsRoom_svg__st99{fill:#f48864}.InstrumentDrumsRoom_svg__st100{fill:#fdc676}.InstrumentDrumsRoom_svg__st101{fill:#4b4a9e}.InstrumentDrumsRoom_svg__st103{fill:#c55a74}.InstrumentDrumsRoom_svg__st104{fill:#f8c4b9}.InstrumentDrumsRoom_svg__st105{fill:#c6cf3e}.InstrumentDrumsRoom_svg__st106{fill:none;stroke:#f8c4b9;stroke-width:1.0334;stroke-miterlimit:10}"
      }
    </style>

    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#528459",
      }}
    />
    <path
      className="InstrumentDrumsRoom_svg__st106"
      d="m103.07 90.66-59.01 7.12-1.23-86.81 60.24 4.8zM12.79 90.18l26.94 7.6V10.97l-26.94 4.32z"
    />
    <path
      className="InstrumentDrumsRoom_svg__st104"
      d="M70.26 84c-.25 0-.45-.2-.45-.45V30.29c0-.25.2-.45.45-.45s.45.2.45.45v53.26c-.01.25-.21.45-.45.45z"
    />
    <path
      className="InstrumentDrumsRoom_svg__st104"
      d="m61.01 86.94 8.9-14.11c.15-.24.08-.56-.16-.71l-.04-.02a.516.516 0 0 0-.71.16l-8.9 14.11c-.15.24-.08.56.16.71l.04.02c.24.15.56.08.71-.16zM79.47 86.94l-8.9-14.11a.516.516 0 0 1 .16-.71l.04-.02c.24-.15.56-.08.71.16l8.9 14.11c.15.24.08.56-.16.71l-.04.02c-.24.15-.56.08-.71-.16z"
    />
    <path
      className="InstrumentDrumsRoom_svg__st103"
      d="M36.71 58.03c-6.87 0-12.45-2.8-12.45-6.25v17.63s1.27 7.32 12.9 7.32 11.99-7.32 11.99-7.32V51.78c.01 3.45-5.56 6.25-12.44 6.25z"
    />
    <ellipse
      className="InstrumentDrumsRoom_svg__st105"
      cx={36.71}
      cy={51.78}
      rx={12.45}
      ry={6.25}
    />
    <path
      className="InstrumentDrumsRoom_svg__st99"
      d="m62.97 61.13-16.05-1.79c5.7 0 10.33 6.45 10.33 14.42 0 7.2-3.78 13.16-8.71 14.24l14.74-1.61s5.66-1.82 5.66-12.63-5.97-12.63-5.97-12.63z"
    />
    <path
      className="InstrumentDrumsRoom_svg__st101"
      d="M46.92 59.35c-6.44 0-11.66 6.45-11.66 14.42s5.22 14.42 11.66 14.42l1.82-.19c5.57-1.08 9.83-7.04 9.83-14.24.01-7.96-5.21-14.41-11.65-14.41z"
    />
    <ellipse
      className="InstrumentDrumsRoom_svg__st104"
      cx={46.92}
      cy={73.76}
      rx={10.21}
      ry={12.63}
    />
    <ellipse
      transform="rotate(-70.61 27.942 38.021)"
      className="InstrumentDrumsRoom_svg__st104"
      cx={27.94}
      cy={38.02}
      rx={2.25}
      ry={10.4}
    />
    <path
      className="InstrumentDrumsRoom_svg__st104"
      d="M27.35 81.78c-.24 0-.44-.2-.44-.44V38.46c0-.24.2-.44.44-.44s.44.2.44.44v42.88c0 .24-.19.44-.44.44z"
    />
    <path
      className="InstrumentDrumsRoom_svg__st104"
      d="m20.46 84.5 6.61-13.08c.11-.22.06-.52-.12-.66l-.03-.02c-.18-.14-.42-.07-.53.15l-6.61 13.08c-.11.22-.06.52.12.66l.03.02c.18.14.42.07.53-.15zM34.3 84.5l-6.6-13.08c-.11-.22-.06-.52.12-.66l.03-.02c.18-.14.42-.07.53.15l6.61 13.08c.11.22.06.52-.12.66l-.03.02c-.19.14-.43.07-.54-.15z"
    />
    <path
      className="InstrumentDrumsRoom_svg__st103"
      d="m60.06 82.95 3.83 6.07c.14.22.07.52-.15.66l-.04.02c-.22.14-.52.07-.66-.15l-3.6-5.7.62-.9zM38.67 83.95l-2.75 4.36c-.14.22-.07.52.15.66l.03.02c.22.14.52.07.66-.15l2.59-4.11-.68-.78z"
    />
    <path
      className="InstrumentDrumsRoom_svg__st101"
      d="m24.08 75.47-1.82.18c.62-.01 1.22-.07 1.82-.18z"
    />
    <path
      className="InstrumentDrumsRoom_svg__st104"
      d="M75.19 59.93c-5.41 0-9.8-1.25-9.8-2.79v7.88s1 3.27 10.15 3.27 9.44-3.27 9.44-3.27v-7.88c0 1.54-4.38 2.79-9.79 2.79z"
    />
    <ellipse
      className="InstrumentDrumsRoom_svg__st105"
      cx={75.19}
      cy={57.13}
      rx={9.8}
      ry={2.79}
    />
    <path
      className="InstrumentDrumsRoom_svg__st99"
      d="M45.67 47.4c-4.63-2.49-7.53-6.08-6.5-8l-5.3 9.84s-1.35 4.55 6.48 8.76 10.27.26 10.27.26l5.3-9.84c-1.03 1.93-5.63 1.47-10.25-1.02z"
    />
    <ellipse
      transform="rotate(-61.684 47.548 43.915)"
      className="InstrumentDrumsRoom_svg__st100"
      cx={47.55}
      cy={43.91}
      rx={3.96}
      ry={9.51}
    />
    <path
      className="InstrumentDrumsRoom_svg__st101"
      d="M65.7 50.11c-4.87 2.35-9.62 2.62-10.6.59l5.01 10.37s2.98 3.87 11.22-.12 6.42-8.41 6.42-8.41l-5.01-10.37c.98 2.04-2.17 5.59-7.04 7.94z"
    />
    <ellipse
      transform="rotate(-25.799 63.924 46.443)"
      className="InstrumentDrumsRoom_svg__st100"
      cx={63.92}
      cy={46.44}
      rx={9.8}
      ry={4.08}
    />
    <ellipse
      transform="rotate(-17.254 69.123 28.832)"
      className="InstrumentDrumsRoom_svg__st104"
      cx={69.11}
      cy={28.83}
      rx={9.25}
      ry={1.46}
    />
    <path
      className="InstrumentDrumsRoom_svg__st99"
      d="M82.52 85.45h-.11c-.19 0-.34-.15-.34-.34v-34.9c0-.19.15-.34.34-.34h.11c.19 0 .34.15.34.34v34.9c0 .19-.16.34-.34.34z"
    />
    <path
      className="InstrumentDrumsRoom_svg__st99"
      d="m78.03 84.12 4.25-11.56c.11-.29.06-.67-.11-.86l-.19-.2c-.17-.18-.39-.1-.5.19l-4.25 11.56c-.11.29-.06.67.11.86l.19.2c.17.19.4.1.5-.19zM86.9 84.12l-4.25-11.56c-.11-.29-.06-.67.11-.86l.19-.2c.17-.18.39-.1.5.19l4.25 11.56c.11.29.06.67-.11.86l-.19.2c-.17.19-.4.1-.5-.19z"
    />
    <ellipse
      className="InstrumentDrumsRoom_svg__st99"
      cx={82.63}
      cy={49.75}
      rx={9.54}
      ry={1.67}
    />
    <ellipse
      className="InstrumentDrumsRoom_svg__st100"
      cx={82.47}
      cy={47.21}
      rx={9.54}
      ry={1.67}
    />
  </svg>
);
export default SvgInstrumentDrumsRoom;


import Modal from "react-bootstrap/Modal";
import { Trans as Translate } from 'react-i18next';
import Button from 'react-bootstrap/Button';

export default function DeleteCommentModal({ show, hasNestedReplies, onConfirm, onClose }) {
  return (
    <Modal className='__modal' show={show} size='sm' backdrop='static'>
      <Modal.Header onHide={onClose}>
        <Translate>Delete Comment</Translate>
      </Modal.Header>
      <Modal.Body>
        <p><Translate i18nKey='delete-comment-body' /></p>
        <p>{ hasNestedReplies && <Translate i18nKey='delete-nested-replies' /> }</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}><Translate>Cancel</Translate></Button>
        <Button onClick={onConfirm}><Translate>Delete</Translate></Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function ScissorsIcon(props) {
  return (
    <svg x={0} y={0} viewBox="0 0 219 237.7" xmlSpace="preserve" {...props}>
      <style>{".__scissors-icon-15{fill:#ced025}.__scissors-icon-16{fill:none}"}</style>
      <path
        className="__scissors-icon-15"
        d="M112.2 4.1L95 34.7c-1.7 3-2.6 6.5-2.5 10.1v.8c.1 4.1.1 8.2.2 12.4.1 7.8.3 15.7.4 23.5.2 9.8.3 19.6.5 29.4.2 10.1.3 20.1.5 30.2.2 9.5.5 19.1.4 28.6-.1 9.3-.5 18.6.8 28 1.8 12.5 8.4 24.5 20.7 31.4 20.5 11.5 45.4 5.4 54.4-13.6 7.9-16.7 0-38.1-18-48.8-9-5.3-18.9-7.3-28-6.1L118 5.8c-.1-3-4.4-4.3-5.8-1.7zm-2.1 180.3c6.4-11.5 22.2-14.9 35.3-7.6s18.4 22.5 11.9 34c-6.4 11.5-22.2 14.9-35.3 7.6-13-7.2-18.4-22.5-11.9-34z"
      />
      <path className="__scissors-icon-16" d="M125.9 107.3l1.4 37.2" />
      <ellipse
        transform="rotate(-60.746 42.37 146.476)"
        className="__scissors-icon-16"
        cx={42.4}
        cy={146.5}
        rx={23.9}
        ry={27}
      />
      <path
        className="__scissors-icon-15"
        d="M214.1 59.6l-87.5 48.8 1.2 32.6c3.2-1.9 6.3-3.7 9.5-5.6 8.5-5 16.9-10 25.4-15 6.7-4 13.5-8 20.2-12 3.5-2.1 7.1-4.2 10.6-6.3.2-.1.4-.3.7-.4 3.1-1.8 5.6-4.3 7.3-7.4l17.2-30.7c1.3-2.5-2-5.5-4.6-4zM78.7 135c-3.8-8.3-10.6-15.8-19.8-20.7-18.5-9.7-40.8-5.3-51 10.2-11.6 17.6-3.7 42 16.8 53.5 12.3 6.9 26 6.2 37.6 1.2 8.7-3.7 16.5-9 24.3-13.9l3.9-2.4-.7-33.8-11.1 5.9zm-48 32.3c-13-7.3-18.4-22.5-11.9-34 6.4-11.5 22.2-14.9 35.3-7.6s18.4 22.5 11.9 34c-6.5 11.5-22.3 14.9-35.3 7.6z"
      />
      <circle cx={109} cy={132.7} r={5} fill="#fff" />
    </svg>
  )
}

import * as React from "react";

const InstrumentWoodBlock = (props) => (
  <svg
    id="InstrumentWoodBlock_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentWoodBlock_svg__st3{fill:#4e4092}.InstrumentWoodBlock_svg__st4{fill:#f18860}"
      }
    </style>
    
    <path
      className="InstrumentWoodBlock_svg__st3"
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
    />
    <path
      style={{
        fill: "#538553",
      }}
      d="M81.31 67.68 24.79 38.49 37.1 27.46l56.82 26.05z"
    />
    <path
      className="InstrumentWoodBlock_svg__st4"
      d="M93.92 53.51 92.4 65.35l-11.56 13.4.47-11.07z"
    />
    <path
      style={{
        fill: "#ca5672",
      }}
      d="m81.31 67.68-.47 11.07-56.05-29.61V38.49z"
    />
    <path
      className="InstrumentWoodBlock_svg__st3"
      d="m69.98 68.19-37.92-19.2c-.83-.42-1.19-1.48-.79-2.37.39-.89 1.39-1.27 2.22-.84l37.92 19.2c.83.42 1.19 1.48.79 2.37-.4.88-1.39 1.26-2.22.84z"
    />
    <path
      d="M45.99 46.12 18.64 61.1c-.87.48-1.96.16-2.44-.71l-.18-.33c-.48-.87-.16-1.96.71-2.44l27.35-14.98c.87-.48 1.96-.16 2.44.71l.18.33c.48.87.16 1.96-.71 2.44z"
      style={{
        fill: "#cfd125",
      }}
    />
    <circle
      className="InstrumentWoodBlock_svg__st4"
      cx={49.34}
      cy={41.67}
      r={5.38}
    />
  </svg>
);

export default InstrumentWoodBlock;

export default function JazzOutline(props) {
  return (
    <svg
      id="__jazz-outline-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 116.77 113.93"
      {...props}
    >
      <defs>
        <style>
          {
            ".__jazz-outline-prefix__cls-1{fill:none;stroke:#cfd4d8;stroke-miterlimit:10;stroke-width:2px}"
          }
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 23 copy"}</title>
      <rect
        className="__jazz-outline-prefix__cls-1"
        x={3.42}
        y={1.32}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
      />
      <circle className="__jazz-outline-prefix__cls-1" cx={79.24} cy={48.6} r={7.7} />
      <circle className="__jazz-outline-prefix__cls-1" cx={68.05} cy={38.48} r={4.55} />
      <circle className="__jazz-outline-prefix__cls-1" cx={81.31} cy={28.12} r={4.55} />
      <path
        className="__jazz-outline-prefix__cls-1"
        d="M49.1 84.46A14.09 14.09 0 0066.62 94.9a13.55 13.55 0 009.69-9 14 14 0 00-.42-10.43 14.87 14.87 0 01.36-13c.4-.79.91-1.53 1.39-2.32l-17.35-8.77c0 .23-.1.42-.13.6a42.22 42.22 0 001.49 18.22c.66 2.14 1.58 4.21 2.35 6.32a3.43 3.43 0 01-1 3.88 3.52 3.52 0 01-4.06.38 4 4 0 01-1.82-2.53L37 17.74A7.09 7.09 0 0032.3 13a7 7 0 00-7.6 1.9 6.93 6.93 0 00-1.94 6.29 1.44 1.44 0 002.24 1c.54-.4.52-1 .37-2.42a3.75 3.75 0 013.39-4.14c2.07-.17 3.58 1 4.15 3.35 1.72 6.89 13.69 55.47 16.19 65.48z"
        id="__jazz-outline-prefix___6Tvu8w_tif"
        data-name="6Tvu8w.tif"
      />
      <path
        d="M81 59.64a1.88 1.88 0 01-.88-.22l-20.27-10.7a1.89 1.89 0 111.77-3.35l20.29 10.7a1.89 1.89 0 01-.91 3.57z"
        fill="#cfd4d8"
      />
      <circle className="__jazz-outline-prefix__cls-1" cx={41.69} cy={41.96} r={1.06} />
      <circle className="__jazz-outline-prefix__cls-1" cx={43.51} cy={48.6} r={1.06} />
      <circle className="__jazz-outline-prefix__cls-1" cx={45.19} cy={55.24} r={1.06} />
    </svg>
  );
}

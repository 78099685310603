import * as React from "react";

const InstrumentPickBass = (props) => (
  <svg
    id="InstrumentPickBass_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.71 110.84"
    style={{
      enableBackground: "new 0 0 110.71 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentPickBass_svg__st4{fill:#f18860}.InstrumentPickBass_svg__st5{fill:#cfd125}.InstrumentPickBass_svg__st6{fill:#ca5672}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      className="InstrumentPickBass_svg__st4"
      d="M36.83 91.44c.18-.22 2.79-3.72 2.46-3.98-1.1-.86-1.62-1.93-1.36-3.33.37-1.99 1.37-3.51 2.91-4.8.82-.68 1.99-1.33 3.08-1.1.58.12 1.14.29 1.69.51.06.02.67.36.71.33 2.3-1.7 4.19-3.95 5.2-6.65.93-2.47.92-4.95.8-7.56-.14-2.91 1.34-5.4 2.7-7.88l-2.11-2.18-.08-.08-.08-.08-2.11-2.18c-2.52 1.28-5.06 2.68-7.96 2.45-2.6-.21-5.08-.29-7.58.56-2.73.93-5.03 2.74-6.81 4.99-.03.04.28.66.3.72.21.55.35 1.13.46 1.71.2 1.09-.49 2.25-1.2 3.04-1.34 1.5-2.89 2.45-4.89 2.76-1.41.22-2.47-.34-3.29-1.46-.25-.34-3.83 2.16-4.05 2.33-2.67 2.02-4.94 4.18-5.88 7.52-1.93 6.89 2.93 14.38 8.71 17.8 2.67 1.58 5.87 2.84 9.07 2.63 4.04-.26 6.89-3.08 9.31-6.07z"
    />
    <path
      className="InstrumentPickBass_svg__st6"
      d="m24.31 87.29-4.93-5.09c-.31-.32-.3-.84.02-1.15l.24-.23c.32-.31.84-.3 1.15.02l4.93 5.09c.31.32.3.84-.02 1.15l-.24.23c-.32.31-.84.31-1.15-.02zM13.95 83.56l-1.48-.52a.88.88 0 0 1-.54-1.14l3.17-8.97a.88.88 0 0 1 1.14-.54l1.48.52c.47.16.71.67.54 1.14l-3.17 8.97c-.17.46-.68.71-1.14.54z"
    />
    <circle
      className="InstrumentPickBass_svg__st5"
      cx={16.39}
      cy={74.17}
      r={0.85}
    />
    <circle
      className="InstrumentPickBass_svg__st5"
      cx={13.89}
      cy={81.89}
      r={0.85}
    />
    <path
      d="M47.11 55.15c.23-.06.39.23.22.39l-6.35 6.16-14.39 13.86-1.9-1.96a.58.58 0 0 1-.11-.65c.23-.52.57-1.58.3-2.99-.05-.27.13-.53.39-.59.83-.19 2.51-.76 3.94-2.44 1.98-2.34.98-6.97.98-6.97s2.14-2.64 5.31-3.17c3.17-.53 5.83-.76 7.39-.83 1.06-.05 3.07-.52 4.22-.81z"
      style={{
        fill: "#fdcc75",
      }}
    />
    <path
      className="InstrumentPickBass_svg__st4"
      d="M91.62 11.18a1.03 1.03 0 0 1 1.55-.07c.57.59 1.31 1.28 1.73 1.37.69.15 2.68.09 4.7 2.12.67.67-.3 1.32-.79 1.71-.45.35-.91.71-1.36 1.05-1.28.97-2.57 1.93-3.86 2.88-1.37 1.01-2.74 2.02-4.11 3.02-.91.66-1.81 1.32-2.72 1.99-.02.01-.33.25-.33.24l-3.41-3.52 8.6-10.79z"
    />
    <path
      className="InstrumentPickBass_svg__st6"
      d="m42.28 69.74-4.39-4.53c-.57-.59-.56-1.54.03-2.11l.83-.8c.59-.57 1.54-.56 2.11.03l4.39 4.53c.57.59.56 1.54-.03 2.11l-.83.8c-.59.58-1.54.56-2.11-.03zM31.28 80.4l-4.39-4.53c-.57-.59-.56-1.54.03-2.11l.83-.8c.59-.57 1.54-.56 2.11.03l4.39 4.53c.57.59.56 1.54-.03 2.11l-.83.8c-.59.58-1.54.56-2.11-.03z"
    />
    <path
      className="InstrumentPickBass_svg__st5"
      d="m83.03 21.97 3.41 3.51-41.19 41.38-4.65-4.8zM17.31 52.75l-2.15 3.17c-.52.77-.01 1.82.92 1.88l3.82.27c.93.07 1.58-.9 1.17-1.74L19.4 52.9c-.4-.84-1.57-.92-2.09-.15z"
    />
  </svg>
);

export default InstrumentPickBass;

export default function ReleaseVersion() {
  return (
    <div className='text-center'>
      <pre style={{ color: 'white' }}>
        <strong>------- STROFE REACT -------</strong>
        <br /> 
        <br /> 
        
        <strong>RELEASE SHA:</strong>
        <br /> 
        { process.env.REACT_APP_GIT_SHA }
        
        <br /><br />
        <strong>DATE:</strong>
        <br />
        { process.env.REACT_APP_GIT_DATE }

        <br /><br />
        <strong>----------------------------</strong>
      </pre>
    </div>
  );
}

import { useHistory } from "react-router-dom";
import { Trans as Translate } from 'react-i18next';

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function SubscribeModal({ show, onHide, hasTrial }) {
  
  const history = useHistory();

  return (
    <Modal className='__modal' show={show} size='sm'>
      <Modal.Header>
        <Translate>Download Track</Translate>
      </Modal.Header>
      <Modal.Body>
        <p><Translate>Own this track forever, use it however you want!</Translate></p>
        { hasTrial
        ? <p><Translate>Create unlimited tracks, sign up for a free 7-day trial.</Translate></p>
        : <p><Translate>Create unlimited tracks, become a Strofe Premium Member.</Translate></p>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}><Translate>Close</Translate></Button>
        <Button variant='primary' onClick={() => history.push('/pricing')}><Translate>Tell Me More</Translate></Button>
      </Modal.Footer>
    </Modal>
  );
}

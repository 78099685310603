import * as React from "react";

const InstrumentCello = (props) => (
  <svg
    id="InstrumentCello_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentCello_svg__st6{fill:#ca5672}.InstrumentCello_svg__st18,.InstrumentCello_svg__st19{fill:none;stroke:#fff;stroke-width:1.7753;stroke-linecap:round;stroke-miterlimit:10}.InstrumentCello_svg__st19{stroke-width:.8944}"
      }
    </style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      d="M76.32 76.35s-7.86 1.29-8.57-8.33 5.17-8.81 5.17-8.81l.37-4.75c.64-8.19-5.83-15.18-14.04-15.18h-9c-8.21 0-14.68 7-14.04 15.18l.37 4.75s5.87-.82 5.17 8.81c-.7 9.62-8.57 8.33-8.57 8.33s-7.3 26.05 21.57 26.05 21.57-26.05 21.57-26.05z"
      style={{
        fill: "#fdcc75",
      }}
    />
    <path
      className="InstrumentCello_svg__st6"
      d="m50.56 81.7 2.1 20.7h4.18l2.09-20.7h-4.18zM60.04 59.22 56.84 15h-4.18l-3.2 44.22h5.29z"
    />
    <circle
      cx={54.75}
      cy={15}
      r={5.81}
      style={{
        fill: "#f18860",
      }}
    />
    <path
      className="InstrumentCello_svg__st18"
      d="M49.46 23.52h10.58M52.67 23.52v68.53M56.44 23.52v68.53"
    />
    <path
      className="InstrumentCello_svg__st6"
      d="M48.46 70.6a.369.369 0 0 0-.09-.14c-.04-.03-.08-.06-.13-.09-.03-.02-.05-.04-.08-.05-.04-.02-.08-.02-.13-.03-.01 0-.02-.01-.04-.01h-.04c-.02 0-.04-.01-.06-.01-2.14-.36-2.97 1.71-3.48 3.39-.21.72-.46 1.47-.82 2.13-.16.29-.34.57-.52.85-.04.07-.28.33-.27.41.23 1.75.41 3.51-.3 5.19-.47 1.11-1.41 2.51-2.81 2.24.06-.08.11-.17.12-.28a.634.634 0 0 0-.54-.72.634.634 0 0 0-.72.54c-.01.06 0 .12 0 .17 0 .02.01.03.01.05 0 .02.01.04.01.06.18 1.02 1.35 1.16 2.27.78 1.19-.49 1.72-1.85 2.27-2.9 1.92-3.76 2.41-4.34 2.41-4.35-.03.01-.2-.88-.2-.96-.05-.48-.07-.96-.06-1.44.01-.78.11-1.56.29-2.32.14-.62.28-1.4.71-1.9.27-.32.62-.44 1.03-.48-.09.01.03.43.05.47.07.14.18.24.32.3.32.12.68-.03.82-.34.09-.19.07-.39-.02-.56zM61.49 70.67c.01-.05.04-.09.08-.15.03-.04.08-.07.12-.1.03-.02.04-.04.07-.06.04-.02.08-.02.13-.04.01 0 .02-.01.04-.01.01 0 .03 0 .04-.01.02 0 .04-.01.06-.01 2.11-.51 3.08 1.51 3.7 3.15.26.7.56 1.43.96 2.07.18.28.38.55.58.81.05.06.3.31.29.39-.11 1.77-.17 3.53.66 5.15.55 1.08 1.58 2.41 2.95 2.05a.585.585 0 0 1-.14-.27c-.07-.35.15-.69.49-.76.35-.07.69.15.76.49.01.06.01.12.01.17 0 .02-.01.03-.01.05 0 .02-.01.04-.01.06-.11 1.03-1.27 1.25-2.21.94-1.22-.41-1.85-1.72-2.46-2.73-2.17-3.62-2.7-4.17-2.7-4.17.03.01.13-.89.14-.97.02-.48.01-.96-.03-1.44-.07-.78-.22-1.55-.44-2.3-.19-.61-.37-1.38-.84-1.85-.3-.3-.65-.4-1.06-.41.09 0 0 .43-.02.47-.06.14-.17.25-.3.32-.31.14-.68.01-.84-.29-.11-.17-.1-.37-.02-.55z"
    />
    <path
      className="InstrumentCello_svg__st19"
      d="m77.3 62.42 15.04 34.69M75.57 63.34l15.04 34.69M77.8 63.58l-1.16 1.63"
    />
  </svg>
);

export default InstrumentCello;

import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import classNames from 'classnames';
import { Trans as Translate } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import './ShareTrackModal.scss';

export default function ShareTrackModal({ show, songId, onClose, isPublic, onMakePublic }) {

  const shareUrl = `${process.env.NODE_ENV === 'development' ? 'http://localhost:3210' : 'https://strofe.com'}/listen/${songId}/`;

  const [showLinkCopied, setShowLinkCopied] = useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(shareUrl);
    setShowLinkCopied(true);
    debouncedHideLink();
  }

  const selectAll = e => {
    e.target.select();
  }

  const debouncedHideLink = useDebouncedCallback(() => {
    setShowLinkCopied(false);
  }, 3000);

  return (
    <>
      <Modal className='__modal __share-track-modal' show={show && isPublic} onHide={onClose}>
        <Modal.Header closeButton>
          Share Your Composition
        </Modal.Header>

        <Modal.Body>
          <p>Share this link with others to show them your composition:</p>
          <InputGroup className='copy-link'>

            <Form.Control value={shareUrl} readOnly onClick={selectAll} />

            <InputGroup.Append>
              <Button onClick={copyLink}><Translate>Copy Link</Translate></Button>
            </InputGroup.Append>
          </InputGroup>
          <div className={classNames('link-copied', {hidden: !showLinkCopied })}>Link copied to clipboard.</div>
          <p className='small text-center mb-0'> Your track is <strong>public</strong>, anyone with this link can listen to it</p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onClose} variant='secondary'><Translate>Close</Translate></Button>
        </Modal.Footer>
      </Modal>

      <PublicTrackModal show={show && !isPublic} onClose={onClose} onMakePublic={onMakePublic} />
    </>
  );
}

function PublicTrackModal({ show, onClose, onMakePublic }) {
  return (
    <Modal className='__modal' show={show} onHide={onClose} size='sm'>
      <Modal.Header closeButton>
        Share Your Composition
      </Modal.Header>

      <Modal.Body>
        <p>Your track is currently private (only you can listen to it).</p>
        <p><strong>In order to share it, you must make it public first.</strong></p>
      </Modal.Body>
      
      <Modal.Footer>
        <Button onClick={onClose} variant='secondary'><Translate>Cancel</Translate></Button>
        <Button onClick={onMakePublic}><Translate>Make Public</Translate></Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function OrchestralPopIcon(props) {
  return (
    <svg x={0} y={0} viewBox="0 0 110.8 111.8" style={{ enableBackground: "new 0 0 110.8 111.8" }} xmlSpace="preserve" {...props}>
      <style>
        {
          ".__orc-pop-icon-2{fill:#c95571}.__orc-pop-icon-3{fill:#f18860}.__orc-pop-icon-4{fill:none;stroke:#fff;stroke-width:1.6;stroke-linecap:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        d="M16.7.6h77.5c9.2 0 16.7 7.5 16.7 16.7v77.5c0 9.2-7.5 16.7-16.7 16.7H16.7C7.5 111.4 0 103.9 0 94.7V17.2C0 8 7.5.6 16.7.6z"
        style={{
          fill: "#4d3f91",
        }}
      />
      <path
        d="M74.2 74.9s-7.1 1.2-7.7-7.5 4.7-7.9 4.7-7.9l.3-4.3c.5-7-4.7-13.1-11.7-13.6h-9.1c-7 0-12.7 5.7-12.7 12.7v1l.3 4.3s5.3-.7 4.7 7.9-7.7 7.5-7.7 7.5-6.6 23.5 19.5 23.5 19.4-23.6 19.4-23.6z"
        style={{
          fill: "#fcca75",
        }}
      />
      <path
        className="__orc-pop-icon-2"
        d="m51 79.7 1.9 18.7h3.7l1.9-18.7h-3.7zM59.5 59.4l-2.9-39.8h-3.7L50 59.4h4.8z"
      />
      <circle className="__orc-pop-icon-3" cx={54.8} cy={19.6} r={5.2} />
      <path className="__orc-pop-icon-4" d="M50 27.3h9.5" />
      <circle className="__orc-pop-icon-3" cx={54.8} cy={69.9} r={7.6} />
      <path
        className="__orc-pop-icon-4"
        d="M52.3 27.3V89M56.3 27.3V89M22.7 73.7l66.1-14.3M23.7 77l66.1-14.2M24.9 73.2l2.4 2.6M42.5 56.4s3 1.8 2.9 4.5M66.8 56.4s-3 1.8-2.9 4.5"
      />
    </svg>
  );
}

import { useEffect, useState } from 'react';
import { Trans as Translate, useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Checkbox from '../../layout/Checkbox';

import { AVAILABLE_GENRES, MOODS, STYLES } from '../CreateSong/CreateSong';

import './FilterModal.scss';

export default function FilterModal({ show, onHide, onSubmit, filters }) {

  const [purchased, setPurchased] = useState(false);
  const [favorite, setFavorite] = useState(undefined);
  const [mood, setMood] = useState('all');
  const [style, setStyle] = useState('all');

  useEffect(() => {
    if (show && filters === null) {
      setPurchased(false);
      setFavorite(false);
      setMood('all');
      setStyle('all');
    }
  }, [show, filters]);

  const { t } = useTranslation();

  const togglePurchased = e => {
    setPurchased(e.target.checked);
  }

  const toggleFavorite = e => {
    setFavorite(e.target.checked);
  }

  const handleChangeMood = e => {
    setMood(e.target.value);
    !AVAILABLE_GENRES[e.target.value].includes(style) && setStyle('all');
  }

  const handleSubmit = () => {

    const newFilters = {
      purchased: purchased || undefined,
      favorite,
      mood: mood === 'all' ? undefined : mood,
      style: style === 'all' ? undefined : style,
    }

    onSubmit(newFilters, true);
  }

  return (
    <Modal show={show} className='__modal __filter-modal' size='sm' backdrop='static'>
      <Modal.Header>
        Filter Tracks
      </Modal.Header>
      <Modal.Body>

        <Form.Group className='filter'>
          <Form.Label><Translate>Mood</Translate></Form.Label>
          <Form.Control as="select" value={mood} onChange={handleChangeMood}>
            <option value='all'>{ t('All') }</option>
            { MOODS.map(mood => <option key={mood.id} value={mood.id}>{ t(mood.phrase) }</option> )}
          </Form.Control>
        </Form.Group>

        <Form.Group className='filter'>
          <Form.Label><Translate>Genre</Translate></Form.Label>
          <Form.Control as="select" value={style} onChange={e => setStyle(e.target.value)}>
            <option value='all'>{ t('All') }</option>
            { STYLES.filter(style => AVAILABLE_GENRES[mood].includes(style.id)).map(style => (
              <option key={style.id} value={style.id}>{ t(style.phrase) }</option>
            ))}
          </Form.Control>
        </Form.Group>

        <div className='filter'> 
          <span>Track Purchased</span>
          <span>
            <span><Checkbox checked={purchased} onChange={togglePurchased} /></span>
          </span>
        </div>

        <div className='filter'>
          <span>Favorited</span>
          <span>
            <span><Checkbox checked={favorite} onChange={toggleFavorite} /></span>
          </span>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}><Translate>Cancel</Translate></Button>
        <Button variant="primary" onClick={handleSubmit}><Translate>Filter</Translate></Button>
      </Modal.Footer>
    </Modal>
  );
}
export default function SlidePic(props) {
  return (
  <svg
    width={120}
    height={120}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 71.7 68.2"
    {...props}
  >
    <style>
      {
        ".__strofe_slide_pic_19{fill:#FFFFFF;stroke:#000000;stroke-width:3.0318;stroke-miterlimit:10;}.__strofe_slide_pic_20{stroke:#000000;stroke-width:0.5506;stroke-miterlimit:10;}.__strofe_slide_pic_21{fill:#FFFFFF;stroke:#020203;stroke-width:0.9183;stroke-miterlimit:10;}.__strofe_slide_pic_22{fill:none;stroke:#020203;stroke-width:2.0615;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.__strofe_slide_pic_23{fill:none;stroke:#020203;stroke-width:1.767;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.__strofe_slide_pic_24{stroke:#020203;stroke-width:1.0086;stroke-linecap:round;stroke-miterlimit:10;}.__strofe_slide_pic_25{fill:none;stroke:#020203;stroke-width:1.0086;stroke-linecap:round;stroke-miterlimit:10;}.__strofe_slide_pic_26{fill:#020203;stroke:#FFFFFF;stroke-width:0.6545;stroke-miterlimit:10;}"
      }
    </style>
    <g>
      <rect x="7" y="12.6" className="__strofe_slide_pic_19" width="47.1" height="47.1"/>
      <rect x="10.2" y="15.8" className="__strofe_slide_pic_20" width="40.7" height="40.7"/>
      <g>
        <path className="__strofe_slide_pic_21" d="M37.7,46.3H23.5c-2.2,0-4-1.8-4-4V27.6c0-2.2,1.8-4,4-4h14.2c2.2,0,4,1.8,4,4v14.7
          C41.7,44.5,39.9,46.3,37.7,46.3z"/>
        <line className="__strofe_slide_pic_22" x1="36.9" y1="27" x2="38.9" y2="28"/>
        <line className="__strofe_slide_pic_22" x1="25.4" y1="27" x2="22.5" y2="28.3"/>
        <path className="__strofe_slide_pic_23" d="M33,41.4c0,0,3.4,0.7,5-2.9"/>
        <g>
          <path className="__strofe_slide_pic_24" d="M21.4,31h8c0,0,0.6,4.4-4,4.4S21.4,31,21.4,31z"/>
          <path className="__strofe_slide_pic_24" d="M31.8,31h8c0,0,0.6,4.4-4,4.4S31.8,31,31.8,31z"/>
          <line className="__strofe_slide_pic_25" x1="29.4" y1="31" x2="31.8" y2="31"/>
        </g>
      </g>
      <g>
        <path className="__strofe_slide_pic_26" d="M28.7,25.3c1.9,3.6,6-0.1,7.9-1.6c1.3-1,2.5-2.6,4.2-2.9c2.2-0.4,3.4,1.9,3.1,3.8c-0.3,1.8-1.8,3.5-3.5,4.3
          c-3.1,1.4-7.3,0.7-10-1.4C29.8,26.9,29.2,26.2,28.7,25.3z"/>
        <path className="__strofe_slide_pic_26" d="M43.7,20.6c4.5-3.7,20.1-17.4,22.7-13.9s-12,10.6-17.1,13.6c-0.2,0.1-4.2,2.5-4.2,2.5
          C45.1,22.8,43.7,20.6,43.7,20.6z"/>
      </g>
    </g>
  </svg>
  );
}

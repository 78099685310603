import * as React from "react";

const InstrumentDistortionGuitar = (props) => (
  <svg
    id="InstrumentDistortionGuitar_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 111.24 110.84"
    style={{
      enableBackground: "new 0 0 111.24 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentDistortionGuitar_svg__st3{fill:#4e4092}.InstrumentDistortionGuitar_svg__st5{fill:#cfd125}"
      }
    </style>
    <path
      className="InstrumentDistortionGuitar_svg__st5"
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
    />
    <path
      d="M62.6 50.77c-.5-.6-1.28-.89-2.04-.72-1.29.3-3.3.28-3.86-2.44-.24-1.17.14-2.77.82-4.47.79-2-1.58-3.75-3.27-2.42l-6.05 4.75c-1.24.98-1.03 2.95.42 3.58 2.56 1.11 5.02 3.07 1.52 5.93-.64.87-17.69 8.84-24.97 12.85-2.31 1.27-8.44 4.99-12.84 8.74-1.71 1.46-.28 4.21 1.89 3.66 12.59-3.21 19.12 1.88 19.26 1.57.98-2.13 3.61-3.1 5.94-2.88 3.08.3 6.44 2.09 8.61 4.27 1.95 1.97 4.26 6.38 1.55 8.08-.78.49-1.5 10.97-1.29 13.12.11 1.09 5.98-3.76 7.93-5.36 3.23-2.64 6.38-5.43 9.6-8.07.31-.26-1.36-6.4-1.45-7.13-.35-2.87-.32-5.84.66-8.59.44-1.24 1.1-2.45 2.11-3.3 1.97-1.67 4.82-1.67 7.4-1.57.34.01 1.33-3.63 2.35-7.75.51-2.05-1.99-3.5-3.5-2.03-1.6 1.55-3.34 2.5-4.98 1.94-3.73-1.27-3.11-4.47-2.48-6.13.26-.7.13-1.47-.34-2.04l-2.99-3.59z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      className="InstrumentDistortionGuitar_svg__st3"
      d="m57.86 68.41-5.79-3.66c-1.54-.98-2-3.02-1.03-4.57.98-1.54 3.02-2 4.57-1.03l5.79 3.66c1.54.98 2 3.02 1.03 4.57-.98 1.54-3.02 2-4.57 1.03z"
    />
    <path
      className="InstrumentDistortionGuitar_svg__st5"
      d="m57.54 72.95-9.79-6.2a.94.94 0 0 1-.29-1.3.94.94 0 0 1 1.3-.29l9.79 6.2c.44.28.57.86.29 1.3-.28.43-.86.57-1.3.29z"
    />
    <circle
      cx={57.3}
      cy={77.85}
      r={2.18}
      style={{
        fill: "#f18860",
      }}
    />
    <path
      className="InstrumentDistortionGuitar_svg__st3"
      d="M97.52 12.83c-.78-.45-1.13-1.47-1.08-2.33.08-1.37.76-2.76 1.37-3.96.14-.27.17-.67-.09-.83-.72-.44-5.08 3.1-6.12 4.2-1.18 1.26-2.27 2.75-2.69 4.45-.17.71-.25 1.46-.07 2.16.05.19.12.37.2.55.16.31.36.59.59.86L57.3 56.31l4.36 3.48 30.7-39.39c.34.27.68.53 1.03.78.06.05 5.2-5.84 5.45-6.59.47-1.4-.53-1.3-1.32-1.76z"
    />
  </svg>
);

export default InstrumentDistortionGuitar;

import { useState } from 'react';
import { Trans as Translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { useSelector } from "react-redux";
import UserEvents from "../utils/UserEvents";
import { usersSelectors } from '../store/usersSlice';

import AnimeIconNoStars from '../icons/genres/AnimeIconNoStars';
import StarIconOutline from '../icons/StarIconOutline';
import AnimaySaleAvatar from "../icons/art/AnimaySaleAvatar";

import './HeroSale.scss';

export default function HeroSale({ onClick }) {

  const [bannerVisible, setBannerVisible] = useState(() => !Cookies.get('strofe_hide_hero_try_anime'));

  const abTests = useSelector(usersSelectors.getAbTests);

  const animaySaleTest = abTests['animay-sale-2022'];
  const tryAnime = animaySaleTest?.variant === 'banner-try-anime';
  const animaySale = animaySaleTest?.variant === 'banner-sale-try';

  const handleHideBanner = () => {
    Cookies.set('strofe_hide_hero_try_anime', true, { expires: 31 });
    UserEvents.logEvent('hero_banner.click_hide', animaySaleTest);
    setBannerVisible(false);
  }

  if (!bannerVisible || (!tryAnime && !animaySale)) {
    return null;
  }

  const handleClickTryAnime = (e, stopPropagation = false) => {
    UserEvents.logEvent('hero_banner.click_try_anime', animaySaleTest);
    onClick?.(e);
    stopPropagation && e.stopPropagation();
  }

  const handleClickSale = () => {
    UserEvents.logEvent('hero_banner.click_sale', animaySaleTest);
  }

  const renderTryAnime = () => (
    <div className='try-anime'>
      <Link to='/create/spirited/anime?scrollAnime=true' onClick={handleClickTryAnime}>
        <div className='try-anime-inner'>
          <div className='animated-icon'>
            <AnimeIconNoStars className='anime-icon' />
            <StarIconOutline className='animated-star animated-star-left' />
            <StarIconOutline className='animated-star animated-star-top-right' />
            <StarIconOutline className='animated-star animated-star-center-right' />
          </div>
          <div className='title-container'>
            <h2 className='title-top'><Translate>Try the New Anime Style</Translate></h2>
            <h2 className='title-shadow'><Translate>Try the New Anime Style</Translate></h2>
          </div>
        </div>
      </Link>
    </div>
  );

  const renderAnimaySale = () => (
    <div className='animay-sale'>
      <Link to='/get-coins' onClick={handleClickSale}>
        <div className='animay-sale-inner'>
          <div className='avatar-cloud-trail'>
            <div className='cloud-trail'>
              <Link to='/create/spirited/anime?scrollAnime=true' onClick={e => handleClickTryAnime(e, true)}>
                <div className='text'>
                  <Translate>Try out the new Anime Style</Translate>
                </div>
              </Link>
            </div>
          </div>

          <div className='sale-content'>
            <AnimaySaleAvatar className='sale-avatar' />
            <div className='sale-copy'>
              <div className='title-container'>
                <h2><Translate>Animay Sale</Translate></h2>
                <h2 className='title-shadow'><Translate>Animay Sale</Translate></h2>
              </div>
            
              <div className='subtitle'>
                50
                <span className='percent-off'><div>%</div><div className='off'>off</div></span>
                Strofe Coins
              </div>

              <div className='offer-ends'><Translate>Offer ends May 31st.</Translate></div>
            </div>
          </div>

          <div className='right-column' />
        </div>
      </Link>
    </div>
  )
  
  return (
    <div className="__hero-sale">
      <div className={classNames('close-banner', { 'dark-color': animaySale })} onClick={handleHideBanner}>×</div>
      { tryAnime ? renderTryAnime() : renderAnimaySale() }
    </div>
  );
}
export default function StarIconOutline(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.2 29.9" {...props}>
      <style>
      {
        ".__star-icon-outline {fill: none; stroke: #ced025; stroke-width: 3.4}"
      }
      </style>
      <path
        d="M17.5 3.3l1.8 5.6c.3.8 1 1.4 1.9 1.4h5.9c1.9 0 2.7 2.4 1.2 3.6l-4.8 3.5c-.7.5-1 1.4-.7 2.2l1.8 5.6c.6 1.8-1.5 3.3-3 2.2l-4.8-3.5c-.7-.5-1.6-.5-2.3 0l-4.8 3.5c-1.5 1.1-3.6-.4-3-2.2l1.8-5.6c.3-.8 0-1.7-.7-2.2L3 13.8c-1.5-1.1-.7-3.6 1.2-3.6h5.9c.9 0 1.6-.5 1.9-1.4l1.8-5.6c.5-1.7 3.1-1.7 3.7.1z"
        className="__star-icon-outline"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </svg>
  )
}

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Trans as Translate } from 'react-i18next';
import { Fragment } from 'react';
import classNames from 'classnames';
import { OverlayTrigger } from 'react-bootstrap';
import Slider from 'rc-slider';
import { DRUM_PROGRAMS, INSTRUMENT_PROGRAMS } from '../../utils/InstrumentPrograms';
import SongSection from './SongSection';
import SeparatorRow from './SeparatorRow';

import InfiniteLoader from '../../layout/InfiniteLoader/InfiniteLoader';
import Tooltip from 'react-bootstrap/Tooltip';
import InstrumentIcon from '../../modals/InstrumentSelector/InstrumentIcon';
import TrackMuteIcon from '../../icons/TrackMuteIcon';
import RefreshIcon from '../../icons/RefreshIcon';
import DragHandleIcon from '../../icons/DragHandleIcon';

export default function InstrumentRow({ instrument, trackMutes, trackSolos, changeSectionMute, regenerateSection, showComposer, noteSequences, instrumentIndex, currentSection, startTimes, trackSectionRegenerating, regeneratingPrograms, regeneratingAllSections, songDuration, hasSoloTrack, changeTrackMute, regenerateInstrument, toggleTrackSolos, trackVelocities, changeTrackVelocity, saveTrackVelocity, sectionLengths, playbackPosition, narrowSections, changeInstrumentProgram, currentUser, onClickSelectInstrument, onClickDelete, promptAddInstrument, trackRowRef }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: instrument.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const currentTrackRegenerating = instrumentId => regeneratingPrograms[instrumentId] || trackSectionRegenerating[instrumentId] || regeneratingAllSections[instrumentId];
  const canRegenerate = category => !['melody', 'alto', 'tenor'].includes(category);

  const regenerateTrackTooltip = props => (
    <Tooltip {...props} className='__tooltip'>
      <Translate>Regenerate instrument</Translate>
    </Tooltip>
  );

  const regenerateTrackButton = instrumentId => (
    <div role='button' className={classNames('regenerate-button', { 'regenerating': regeneratingAllSections[instrumentId] })} onClick={() => !regeneratingAllSections[instrumentId] && regenerateInstrument(instrumentId)}><RefreshIcon /></div>
  );

  const renderInstrumentSelect = instrument => {
    if (instrument.category === 'drums') {
      return (
        <div style={{ display: currentTrackRegenerating(instrument.id) ? 'none' : 'inline-block' }}>
          <div role='button' className='instrument-select as-button' onClick={() => onClickSelectInstrument(instrument, true)}>
          <InstrumentIcon showName={false} name={DRUM_PROGRAMS[instrument.program]} />
            <div className='name'>{ DRUM_PROGRAMS[instrument.program] }</div>
            <div className='dropdown-arrow'>v</div>
          </div>
        </div>
      );
    }

    else {
      return (
        <div style={{ display: currentTrackRegenerating(instrument.id) ? 'none' : 'inline-block' }}>
          <div role='button' className='instrument-select as-button' onClick={() => onClickSelectInstrument(instrument)}>
            <InstrumentIcon showName={false} name={INSTRUMENT_PROGRAMS[instrument.program]} />
            <div className='name'>{ INSTRUMENT_PROGRAMS[instrument.program] }</div>
            <div className='dropdown-arrow'>v</div>
          </div>
        </div>
      );
    }
  }

  if (instrument._creating) {
    return (
      <Fragment key={instrument.id}>
        <div className={classNames('instrument', { dragging: isDragging })} ref={setNodeRef} style={style}>
          <div className='drag-handle' {...listeners} {...attributes}>
            <DragHandleIcon />  
          </div>

          <div className='regenerate-progress'>
            <div className='loader'><InfiniteLoader color='lightgray' /></div>
            <span><Translate>Creating Instrument...</Translate></span>
          </div>
        </div>

        <div style={{ gridColumn: '2 / -1' }} />
        
        <SeparatorRow onClickAdd={() => promptAddInstrument(instrumentIndex + 1)} />
      </Fragment>
    );
  }

  return (
    <Fragment key={instrument.id}>
      <div className='instrument-row' ref={trackRowRef}>
        <div className={classNames('instrument', { dragging: isDragging })} ref={setNodeRef} style={style}>
          { !isDragging && instrument.category !== 'melody' && <div className='delete-instrument' role="button" onClick={() => onClickDelete(instrument.id)}>×</div> }
          <div className='drag-handle' {...listeners} {...attributes}>
            <DragHandleIcon />  
          </div>
          <div className='name'>
            <div className='mute-controls'>
              { currentTrackRegenerating(instrument.id) && (
                <div className='regenerate-progress'>
                  <div className='loader'><InfiniteLoader color='lightgray' /></div>
                  <span><Translate>Regenerating...</Translate></span>
                </div>
              )}

              { renderInstrumentSelect(instrument) }

              <TrackMuteIcon height={28} role='button' className={classNames('track-mute-btn', {disabled: hasSoloTrack, active: trackMutes[instrument.id]})} onClick={() => !hasSoloTrack && changeTrackMute(instrument.id)} />
              <div role='button' className={classNames('track-solo-btn',{ active: Object.entries(trackSolos).some(([id, value]) => id === instrument.id.toString() && value) })} onClick={() => toggleTrackSolos(instrument.id)}><Translate>Solo</Translate></div>
              
              <div className='regenerate-button-container'>
                { canRegenerate(instrument.category) && (!currentTrackRegenerating(instrument.id) || regeneratingAllSections[instrument.id]) && (
                  regeneratingAllSections[instrument.id]
                  ? regenerateTrackButton(instrument.id)
                  : <OverlayTrigger delay={{ show: 800 }} overlay={regenerateTrackTooltip}>{ regenerateTrackButton(instrument.id) }</OverlayTrigger>
                )}
              </div>
            </div>
          </div>

          <Slider className='track-volume' vertical min={0} max={100} value={trackVelocities[instrument.id]} onChange={value => changeTrackVelocity(value, instrument.id)} onAfterChange={value => saveTrackVelocity(value, instrument.id)} />
        </div>

        { instrument.song_sections.map((section, i) => (
          <SongSection odd={instrumentIndex % 2} sectionLengths={sectionLengths} playbackPosition={playbackPosition} i={i} narrow={narrowSections[i]} key={section.id} section={section} instrument={instrument} currentSection={currentSection} startTimes={startTimes} currentSectionRegenerating={trackSectionRegenerating[instrument.id]} wholeTrackRegenerating={regeneratingPrograms[instrument.id] || regeneratingAllSections[instrument.id]} trackRegenerating={currentTrackRegenerating(instrument.id)} songDuration={songDuration} hasSoloTrack={hasSoloTrack} trackMutes={trackMutes} trackSolos={trackSolos} changeSectionMute={changeSectionMute} regenerateSection={regenerateSection} showComposer={showComposer} noteSequences={noteSequences.filter(sequence => sequence.category === instrument.category)} />
        ))}

        <SeparatorRow onClickAdd={() => promptAddInstrument(instrumentIndex + 1)} />
      </div>
    </Fragment>
  )  
}

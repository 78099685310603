import * as React from "react";

const InstrumentMutedTrumpet = (props) => (
  <svg
    id="InstrumentMutedTrumpet_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentMutedTrumpet_svg__st3{fill:#4e4092}.InstrumentMutedTrumpet_svg__st6{fill:#ca5672}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      className="InstrumentMutedTrumpet_svg__st3"
      d="m100.25 41.61-.02 1.47-.2 17.69c0 .35-.43.51-.66.24-1.76-2.1-7.29-7.38-18.48-7.59-3.84-.25-7.72-.21-11.56-.31-7.79-.2-15.57-.41-23.36-.61-6.47-.17-12.93-.33-19.4-.5-2.92-.07-5.51.04-6.88 3.12-1 2.24-.09 5.19 1.95 6.52 1.66 1.08 5.75 1.17 5.75 1.17l46.92.59s3.91-.01 5.36-1.19c1.87-1.53 1.83-4.83-.08-6.31-1.36-1.05-3.21-1.13-4.93-1.16-10.07-.18-20.14-.37-30.21-.55-9.95-.18-19.9-.35-29.85-.55l-2.77-.06c-.2 0-.37-.17-.37-.38v-1.18c0-.21.17-.38.38-.37 8.1.19 16.19.39 24.29.59 7.49.19 14.98.37 22.47.56 5.76.14 11.52.29 17.28.43 1.18.03 1.6.09 2.77.12 1.39.03 2.91 1.71 3.45 2.82 1.36 2.8.56 7.66-2.75 8.58-1.94.54-4.26.41-6.29.49-1.79.07-3.58.11-5.37.14-4.35.07-8.7.05-13.04 0-8.53-.1-17.07-.3-25.59-.86-2.57-.17-5.49.06-7.92-1.11-2.35-1.13-3.67-3.5-3.64-6.08.03-2.36.91-4.87 2.8-6.38 1.72-1.37 3.76-1.28 5.84-1.37 4.06-.17 8.13-.26 12.2-.35 9.11-.21 28.76-.66 40.2-1.2 14.3-.54 19.4-4.71 21.02-6.68.25-.26.69-.1.69.26z"
    />
    <path
      className="InstrumentMutedTrumpet_svg__st6"
      d="m11.24 51.55-3.52-1.36a.374.374 0 0 0-.51.32l-.3 4.02c-.01.27.24.47.5.39l3.81-1.26c.15-.05.26-.19.26-.35l.01-1.4c-.01-.16-.1-.3-.25-.36z"
    />
    <circle
      className="InstrumentMutedTrumpet_svg__st3"
      cx={42.64}
      cy={43.23}
      r={1.9}
    />
    <circle
      className="InstrumentMutedTrumpet_svg__st3"
      cx={47.54}
      cy={43.22}
      r={1.9}
    />
    <circle
      className="InstrumentMutedTrumpet_svg__st3"
      cx={52.23}
      cy={43.35}
      r={1.9}
    />
    <path
      className="InstrumentMutedTrumpet_svg__st6"
      d="m42.49 69.51-.39-.01c-1-.02-1.79-.84-1.77-1.83l.31-17.99c.02-1 .84-1.79 1.83-1.77l.39.01c1 .02 1.79.84 1.77 1.83l-.31 17.99c-.02.99-.84 1.79-1.83 1.77zM47.18 69.51l-.39-.01c-1-.02-1.79-.84-1.77-1.83l.31-17.99c.02-1 .84-1.79 1.83-1.77l.39.01c1 .02 1.79.84 1.77 1.83l-.31 17.99c-.02.99-.84 1.79-1.83 1.77zM51.87 69.51l-.39-.01c-1-.02-1.79-.84-1.77-1.83l.31-17.99c.02-1 .84-1.79 1.83-1.77l.39.01c1 .02 1.79.84 1.77 1.83l-.31 17.99c-.02.99-.84 1.79-1.83 1.77z"
    />
    <path
      d="M100.24 42.52s5.89 1.05 5.89 4.08-.05 6.88 0 9.42-6.09 4.14-6.09 4.14l.2-17.64z"
      style={{
        fill: "#cfd125",
      }}
    />
  </svg>
);

export default InstrumentMutedTrumpet;

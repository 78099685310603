import { strofeApi } from '../../api/strofeApi';
import { useState, useEffect } from 'react';
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";
import Checkbox from "../../layout/Checkbox";
import NavigationHeader from "../NavigationHeader";

import './UserStats.scss';

export default function UserStats() {

  const [loading, setLoading] = useState(true);
  const [displayReferrer, setDisplayReferrer] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [rawStats, setRawStats] = useState([]);
  const [displayStats, setDisplayStats] = useState([]);

  useEffect(() => {
    if (rawStats.length === 0) {
      return;
    }

    let display_stats = [];

    if (displayReferrer){
      let currentDay = '';
      rawStats.forEach((stat) => {
        let day = '';
        if (stat.creation_day !== currentDay){
          currentDay = stat.creation_day;
          day = currentDay;
        }

        display_stats.push({
          creation_day: day,
          referrer: stat.referrer || 'Null',
          total: stat.total
        });
      });
    }
    else {
      let currentStat = {creation_day: rawStats[0].creation_day, total: 0};
      const arraySize = rawStats.length;
      rawStats.forEach((stat, index) => {
        if (stat.creation_day !== currentStat.creation_day){
          display_stats.push({...currentStat});
          currentStat.total = 0;
        }

        currentStat.creation_day = stat.creation_day;
        currentStat.total += stat.total;

        if ( index === arraySize-1 ){
          display_stats.push({...currentStat});
        }
      });
    }
    setDisplayStats(display_stats);
    setLoading(false);
  }, [displayReferrer, rawStats]);

  useEffect(() => {
    const getUserStats = async () => {
      const response = await strofeApi.get(process.env.REACT_APP_NOMODO_API + `/users/stats`);
      setUserCount(response.data.total);
      setRawStats(response.data.users);
    }
    getUserStats();
  }, []);

  return (
    <div className='__user-stats'>
      <NavigationHeader />
      <h1>User Stats</h1>

      { loading && (
        <div className='loading'>
          <InfiniteLoader width={100} />
        </div>
      )}

      { !loading && (
        <>
          <h2>Total User Count</h2>
          <div className='user-count'>
            {userCount.toLocaleString()}
          </div>

          <h2>Daily Registrations</h2>
          <Checkbox checked={displayReferrer} onChange={e => setDisplayReferrer(e.target.checked)}>Show Referrer</Checkbox>
          <div>
            <div className='stats-header'>
              <div>Registration Date</div>
              {displayReferrer && <div>Referrer</div>}
              <div>Total</div>
            </div>
            { displayStats.map((stat, index) => (
              <div key={`stats-row-${index}`} className='stats-row'>
                <div>{stat.creation_day}</div>
                {displayReferrer && <div>{stat.referrer}</div>}
                <div>{stat.total}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

import { Trans as Translate } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function DeleteSongModal({ show, onClose, onDelete }) {

  return (
    <Modal className='__modal' show={show} size='sm' backdrop='static'>
      <Modal.Header>
        <Modal.Title><Translate>Delete Track</Translate></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Translate i18nKey='delete-song-warning' />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}><Translate>Cancel</Translate></Button>
        <Button variant="primary" onClick={onDelete}><Translate>Delete</Translate></Button>
      </Modal.Footer>
    </Modal>
  );
}

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Trans as Translate } from 'react-i18next';

export default function MaxInstrumentsModal({ show, onHide }) {

  return (
    <Modal className='__modal' show={show} onHide={onHide} backdrop='static' size='sm'>
      <Modal.Header>
        <Translate>Instrument Limit Reached</Translate>
      </Modal.Header>
      <Modal.Body>
        <Translate i18nKey='instrument-max-limit' />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}><Translate>Close</Translate></Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function LPTopDivider(props) {
  return (
    <svg viewBox="0 4 1362 133.4" {...props}>
    {/* <svg viewBox="0 0 1364.2 133.4" {...props}> */}
      <path
        d="M.3 97.6c6-2 249.6-39.4 249.6-39.4l770.1 73.6 309.5-72.9h33.9l1.2-58.1L-.9-.3.3 97.6z"
        fill="#284e5b"
      />
    </svg>
  );
}

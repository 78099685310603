import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useEventCallback from '../../hooks/useEventCallback';
import { strofeApi } from "../../api/strofeApi";
import { DateTime } from "luxon";
import { useDebouncedCallback } from 'use-debounce';
import Form from "react-bootstrap/Form";
import NavigationHeader from "../NavigationHeader";
import InfiniteLoader from '../../layout/InfiniteLoader/InfiniteLoader';
import Toast from "react-bootstrap/Toast";

import './Feedback.scss';

export const FEEDBACK_TYPES = {
  feedback        : "Feedback",
  bug_report      : "Bug / Issue",
  feature_request : "Feature Request",
  billing         : "Billing",
  report          : "Report Abuse"
};

export default function Feedback() {
  const [loading, setLoading] = useState(false);
  const [feedbackMessages, setFeedbackMessages] = useState(null);
  const [feedbackType, setFeedbackType] = useState('all');
  const [feedbackStatus, setFeedbackStatus] = useState('open');

  const { i18n } = useTranslation();

  const loadFeedbackMessages = useEventCallback((fetchFeedbackType = feedbackType, fetchFeedbackStatus = feedbackStatus) => {
    debouncedLoading(true);

    const fetchFeedbackMessages = async () => {
      const { data } = await strofeApi.get(`/feedback_messages?feedback_type=${fetchFeedbackType}&feedback_status=${fetchFeedbackStatus}`);

      setFeedbackMessages(data);
      debouncedLoading.cancel();
      setLoading(false);
    }

    fetchFeedbackMessages();
  });

  useEffect(() => {
    loadFeedbackMessages()
  }, [loadFeedbackMessages]);

  const updateFeedbackType = e => {
    setFeedbackType(e.target.value);
    loadFeedbackMessages(e.target.value);
  }

  const updateFeedbackStatus = e => {
    setFeedbackStatus(e.target.value);
    loadFeedbackMessages(feedbackType, e.target.value);
  }

  const handleChangeFeedbackStatus = (id, feedback_status ) => {
    const newFeedbackMessages = [...feedbackMessages];
    const feedbackMessage = newFeedbackMessages.find(feedbackMessage => feedbackMessage.id === id);
    feedbackMessage.feedback_status = feedback_status;
    setFeedbackMessages(newFeedbackMessages);

    debouncedUpdateReviewedApi(id, feedback_status);
  }

  const debouncedUpdateReviewedApi = useDebouncedCallback((id, feedback_status) => {
    strofeApi.put(`/feedback_messages/${id}`, { feedback_message: { feedback_status }});
  }, 300);

  const debouncedLoading = useDebouncedCallback(value => {
    setLoading(value);
  }, 200);

  const renderFeedback = () => {
    if (feedbackMessages === null) {
      return (
        <div className='initial-load'>
          <InfiniteLoader width={48} />
          <p>Loading Feedback...</p>
        </div>
      );
    }

    else if (feedbackMessages.length === 0) {
      return (
        <div className='empty-feedback'>
          No Feedback to display
        </div>
      );
    }

    else {
      return renderFeedbackMessages();
    }
  }

  const renderFeedbackMessages = () => (
    <>
      { feedbackMessages.map(feedbackMessage => (
        <div className='feedback-message' key={feedbackMessage.id}>
          <div className='creator'>
            <span>
              {feedbackMessage.creator.email}
              {feedbackMessage.creator.name !== null && <> ({feedbackMessage.creator.name})</>}
            </span>
            <span>{FEEDBACK_TYPES[feedbackMessage.feedback_type]}</span>
          </div>
          <div className="created-at">
            { DateTime.fromISO(feedbackMessage.created_at).setLocale(i18n.language).toLocaleString() }
            <Form.Control className='feedback_status-input' as='select' value={feedbackMessage.feedback_status} onChange={e => handleChangeFeedbackStatus(feedbackMessage.id, e.target.value)}>
              <option key='open' value='open'>Open</option>
              <option key='in_progress' value='in_progress'>In Progress</option>
              <option key='solved' value='solved'>Solved</option>
              <option key='ignored' value='ignored'>Ignored</option>
            </Form.Control>
          </div>
          {feedbackMessage.message}
        </div>
      ))}
    </>
  );

  return (
    <div className='__feedback'>
      <NavigationHeader />

      <div className='header'>
        Feedback
      </div>

      <div className='options'>
        <div className='filters'>
          <div>Type:</div>
          <Form.Control className='feedback_type-input' as='select' value={feedbackType} onChange={updateFeedbackType}>
            <option key='all' value='all'>All</option>
            { Object.entries(FEEDBACK_TYPES).map(([value, phrase]) => <option key={value} value={value}>{ phrase }</option> )}
          </Form.Control>

          <div>Status:</div>
          <Form.Control className='feedback_status-input' as='select' value={feedbackStatus} onChange={updateFeedbackStatus}>
            <option key='all' value='all'>All</option>
            <option key='open' value='open'>Open</option>
            <option key='in_progress' value='in_progress'>In Progress</option>
            <option key='solved' value='solved'>Solved</option>
            <option key='ignored' value='ignored'>Ignored</option>
          </Form.Control>
        </div>
      </div>

      { renderFeedback() }

      <Toast show={feedbackMessages !== null && loading} className='strofe-toast'>
        <Toast.Body>Loading...</Toast.Body>
      </Toast>
    </div>
  );
}

export default function PauseIconV2(props) {
  return (
    <svg viewBox="0 0 25.56 33.52" {...props}>
      <rect
        width={8.48}
        height={33.52}
        rx={4.24}
        ry={4.24}
      />
      <rect
        x={17.09}
        width={8.48}
        height={33.52}
        rx={4.24}
        ry={4.24}
      />
    </svg>
  );
}

import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import classNames from 'classnames';
import { Trans as Translate, useTranslation } from 'react-i18next';

import HeroSale from '../../layout/HeroSale';
import RhythmControls from './RhythmControls';
import ORCHESTRATIONS from '../../utils/rails_generated/orchestrations';

import SongButton from './SongButton';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import CreateSongDetails from './CreateSongDetails';
import ErrorModal from '../../modals/Error/ErrorModal';

import StrofeLogoColors from '../../icons/StrofeLogoColors';
import NavigationHeader from '../NavigationHeader';
import LoadingBlocks from '../../layout/LoadingBlocks';

import MoodSpirited from '../../icons/MoodSpiritedIcon';
import MoodHappyIcon from '../../icons/MoodHappyIcon';
import MoodSadIcon from '../../icons/MoodSadIcon';
import MoodSpookyIcon from '../../icons/MoodSpookyIcon';
import MoodChillIcon from '../../icons/MoodChillIcon';

import LofiIcon from '../../icons/genres/LofiIcon';
import JazzIcon from '../../icons/genres/JazzIcon';
import ReggaeIcon from '../../icons/genres/ReggaeIcon';
import PopIcon from '../../icons/genres/PopIcon';
import HipHopIcon from '../../icons/genres/HipHopIcon';
import LatinIcon from '../../icons/genres/LatinIcon';
import OddityIcon from '../../icons/genres/OddityIcon';
import OrchestralPopIcon from '../../icons/genres/OrchestralPopIcon';
import EpicIcon from '../../icons/genres/EpicIcon';
import RetroFuturisticIcon from '../../icons/genres/RetroFuturisticIcon';
import BossaIcon from '../../icons/genres/BossaIcon';
import AnimeIconNoStars from '../../icons/genres/AnimeIconNoStars';
import AnimeIcon from '../../icons/genres/AnimeIcon';

import LofiOutline from '../../icons/genres/outlines/LofiOutline';
import JazzOutline from '../../icons/genres/outlines/JazzOutline';
import ReggaeOutline from '../../icons/genres/outlines/ReggaeOutline';
import PopOutline from '../../icons/genres/outlines/PopOutline';
import HipHopOutline from '../../icons/genres/outlines/HipHopOutline';
import LatinOutline from '../../icons/genres/outlines/LatinOutline';
import OddityOutline from '../../icons/genres/outlines/OddityOutline';
import OrchestralPopOutline from '../../icons/genres/outlines/OrchestralPopOutline';
import EpicOutline from '../../icons/genres/outlines/EpicOutline';
import RetroFuturisticOutline from '../../icons/genres/outlines/RetroFuturisticOutline';
import BossaOutline from '../../icons/genres/outlines/BossaOutline';
import AnimeOutlineNoStars from '../../icons/genres/outlines/AnimeOutlineNoStars';
import AnimeOutline from '../../icons/genres/outlines/AnimeOutline';
import StarIconOutline from '../../icons/StarIconOutline';
import PointyArrow from '../../icons/PointyArrow';

import './CreateSong.scss';

export const STYLES = [
  {
    id      : 'jazz',
    phrase  : 'Jazz',
    icon    : JazzIcon,
    outline : JazzOutline,
  },
  
  {
    id      : 'bossa',
    phrase  : 'Bossa Nova',
    icon    : BossaIcon,
    outline : BossaOutline,
  },

  {
    id      : 'reggae',
    phrase  : 'Reggae',
    icon    : ReggaeIcon,
    outline : ReggaeOutline,
  },

  {
    id      : 'lofi',
    phrase  : 'Lofi',
    icon    : LofiIcon,
    outline : LofiOutline,
  },

  {
    id      : 'pop',
    phrase  : 'Pop',
    icon    : PopIcon,
    outline : PopOutline,
  },

  {
    id      : 'orc_pop',
    phrase  : 'Orchestral Pop',
    icon    : OrchestralPopIcon,
    outline : OrchestralPopOutline,
  },

  {
    id      : 'epic',
    phrase  : 'Epic',
    icon    : EpicIcon,
    outline : EpicOutline,
  },

  {
    id      : 'retro_futuristic',
    phrase  : 'Retro Futuristic',
    icon    : RetroFuturisticIcon,
    outline : RetroFuturisticOutline,
  },

  {
    id      : 'oddity',
    phrase  : 'Oddity',
    icon    : OddityIcon,
    outline : OddityOutline,
  },

  {
    id      : 'hip_hop',
    phrase  : 'Hip Hop',
    icon    : HipHopIcon,
    outline : HipHopOutline,
  },

  {
    id      : 'latin',
    phrase  : 'Latin',
    icon    : LatinIcon,
    outline : LatinOutline,
  },

  {
    id              : 'anime',
    phrase          : 'Anime',
    icon            : AnimeIcon,
    outline         : AnimeOutline,
    animatedIcon    : AnimeIconNoStars,
    animatedOutline : AnimeOutlineNoStars,
    animations : [
      { icon: StarIconOutline, className: 'anim-star anim-star-left' },
      { icon: StarIconOutline, className: 'anim-star anim-star-top-right' },
      { icon: StarIconOutline, className: 'anim-star anim-star-center-right' },
    ],
  }
];

export const MOODS = [
  {
    id     : 'spooky',
    phrase : "Spooky",
    emoji  : '👻',
    icon   : MoodSpookyIcon,
  },

  {
    id     : 'sad',
    phrase : "Sad",
    emoji  : '🙁',
    icon   : MoodSadIcon,
  },
  {
    id     : 'chill',
    phrase : "Chill",
    emoji  : '😌',
    icon   : MoodChillIcon,
  },
  {
    id     : 'happy',
    phrase : "Happy",
    emoji  : '😃',
    icon   : MoodHappyIcon,
  },
  {
    id     : 'spirited',
    phrase : "Spirited",
    emoji  : '🤩',
    icon   : MoodSpirited,
  },
];

export const AVAILABLE_GENRES = {
  all       : STYLES.map(style => style.id),
  spooky    : ['jazz', 'lofi'],
  sad       : ['jazz', 'bossa', 'lofi', 'pop'],
  chill     : ['jazz', 'bossa', 'reggae', 'lofi', 'pop', 'hip_hop', 'latin'],
  happy     : ['bossa', 'reggae', 'pop', 'orc_pop', 'epic', 'retro_futuristic', 'latin', 'oddity'],
  spirited  : ['orc_pop', 'epic', 'retro_futuristic', 'anime'],
};

export default function CreateSong() {
  const location = useLocation();
  let { mood: initialMood, style: initialStyle } = useParams();

  const { t } = useTranslation();

  const [selectedMood, setSelectedMood] = useState(location.state?.mood || initialMood);
  const [genre, setGenre] = useState(location.state?.style || initialStyle);
  const [tempo, setTempo] = useState(null);
  const [swing, setSwing] = useState(null);
  const [orchestration, setOrchestration] = useState(null);
  const [chooseCustomGenre, setChooseCustomGenre] = useState(initialStyle !== undefined);
  const [creating, setCreating] = useState(location.state?.reroll);
  const [createError, setCreateError] = useState(false);
  const [expandedOptions, setExpandedOptions] = useState(false);
  const [doScroll] = useState(() => location.search === '?scrollAnime=true');

  const styleRef = useRef();
  const genreRef = useRef();

  const selectMood = mood => {
    setSelectedMood(mood);
    setGenre(null);
    setOrchestration(null);
    setTempo(null);
  }

  const selectGenre = genre => {
    setGenre(genre);
    setOrchestration(null);
    setTempo(null);
  }

  const selectAutoGenre = () => {
    setGenre(null);
    setExpandedOptions(false);
    setChooseCustomGenre(false);
  }

  const handleCreate = () => {
    setCreating(true);
  }

  const handleCreateError = () => {
    setCreateError(true);
  }

  const onChangeTempo = value => {
    setTempo(value);
  }

  const onChangeSwing = value => {
    setSwing(value);
  }

  const onChangeOrchestration = e => {
    setOrchestration(e.target.value === 'auto' ? null : e.target.value);
  }

  const handleSetAnime = e => {
    e.preventDefault();

    setSelectedMood('spirited');
    setGenre('anime');
    setChooseCustomGenre(true);
    setOrchestration(null);
    setTempo(null);

    const rect = genreRef.current.getBoundingClientRect();
    setTimeout(() => window.scroll({ top: rect.top, left: 0, behavior: 'smooth' }), 100);
  }

  useEffect(() => {
    if (doScroll) {
      const rect = genreRef.current.getBoundingClientRect();
      setTimeout(() => window.scroll({ top: rect.top, left: 0, behavior: 'smooth' }), 100);
    }
  }, [doScroll]);

  const renderMoodIcon = mood => {
    const Icon = mood.icon;
    return <Icon />
  }

  useEffect(() => {
    if (chooseCustomGenre) {
      const rect = styleRef.current.getBoundingClientRect();
      window.innerWidth <= 720 && window.scroll({ top: rect.top, left: 0, behavior: 'smooth' });
    }
  }, [chooseCustomGenre]);

  const renderPickGenre = () => {
    const genres = AVAILABLE_GENRES[selectedMood];

    return (
      STYLES.filter(style => genres?.includes(style.id)).map(style => (
        <SongButton id={style.id} key={style.id} icon={style.animatedIcon || style.icon} outline={style.animatedOutline || style.outline} onClick={selectGenre} active={genre === style.id} animations={style.animations}>
          <Translate>{ style.phrase }</Translate>
        </SongButton>
      ))
    );
  }

  const renderCreating = () => (
    <LoadingBlocks>
      <Translate>Your new, unique track will be ready in seconds...</Translate>
    </LoadingBlocks>
  );

  const renderCreateOptions = () => {
    return (
      <>
        <p className='mood-prompt'>
          <Translate>Select a mood for your track</Translate>
        </p>

        <div className='moods'>
          { MOODS.map(mood => (
            <Button className='style-button mood' variant='outline-info' active={selectedMood === mood.id} onClick={() => selectMood(mood.id)} disabled={creating} key={mood.id} data-test={`CREATE-mood-${mood.id}`}>
              <div className='icon'>{ renderMoodIcon(mood) }</div>
              <div><Translate>{ mood.phrase }</Translate></div>
            </Button>
          ))}
        </div>
        
        <div className={classNames('custom-genre', {'genre-disabled': !selectedMood })} ref={styleRef}>
          <p><Translate>Music Style</Translate></p>
          <ButtonGroup>
            <Button disabled={!selectedMood} size='lg' active={(!chooseCustomGenre)} onClick={selectAutoGenre} className='auto'><Translate>Auto</Translate></Button>
            <Button disabled={!selectedMood} size='lg' active={chooseCustomGenre} onClick={() => setChooseCustomGenre(true)} className='custom'><Translate>Customize</Translate></Button>
          </ButtonGroup>
        </div>

        <div className={classNames('track-options-container', { expanded: expandedOptions })} ref={genreRef}>
          { chooseCustomGenre && (
            <div className={classNames('pick-genre-container', { 'custom-options': chooseCustomGenre })}>
              { renderPickGenre() }
            </div>
          )}
          
          { chooseCustomGenre && (
            <div className='extra-options'>
              { expandedOptions && (
                <div className={classNames('controls', { disabled: !genre })}>
                  <RhythmControls genre={genre} mood={selectedMood} disabled={!genre} onChangeTempo={onChangeTempo} onChangeSwing={onChangeSwing} />
                  <div className='label'><Translate>Instrument Pack</Translate></div>

                  <Form.Control disabled={!genre} className='harmony-select' as='select' size='sm' value={orchestration || 'auto'} onChange={onChangeOrchestration}>
                    <option value='auto'>{ t('Automatic') }</option>
                    { ORCHESTRATIONS[genre]?.map(orchestration => (
                      <option key={orchestration.id} value={orchestration.id}>{ orchestration.title }</option>
                    ))}
                  </Form.Control>
                </div>
              )}
              <div role='button' className='toggle' onClick={() => setExpandedOptions(!expandedOptions)}>
                <PointyArrow />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className='__create-song'>
      <NavigationHeader />
      <HeroSale onClick={handleSetAnime} />
      <div className='__create-song-container-fade'>
        <div className='pick-style'>
          <StrofeLogoColors width={220} viewBox="0 200 2000 1600" />
          { creating ? renderCreating() : renderCreateOptions() }
          <CreateSongDetails creating={creating} genre={genre} mood={selectedMood} tempo={tempo} swing={(chooseCustomGenre && genre && expandedOptions) ? swing : null} orchestration={orchestration} onCreate={handleCreate} onCreateError={handleCreateError} disabled={!selectedMood || (chooseCustomGenre && !genre)} />
        </div>
        <ErrorModal show={createError} />
      </div>
    </div>
  )
}
import * as React from "react"

export default function MoodHappyIcon(props) {
  return (
    <svg viewBox="0 0 360 360" {...props}>
      <style>
        {
          ".__happy-icon-st1{fill:none;stroke:#fff;stroke-width:35;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.__happy-icon-st6{fill:#fff}"
        }
      </style>
      <path
        d="M292.8 359.5H67.2c-34.5 0-62.8-28.3-62.8-62.8V63.4C4.4 28.9 32.7.6 67.2.6h225.6c34.5 0 62.8 28.3 62.8 62.8v233.3c0 34.5-28.3 62.8-62.8 62.8z"
        fill="#f7ccc1"
      />
      <path
        className="__happy-icon-st1"
        d="M91.3 42.5L41.2 60M271.8 40.8l48.9 20.8M139.7 114.6S71 85.3 44.2 155.2"
      />
      <path
        className="__happy-icon-st6"
        d="M36 104.2c-.1-.1 0 .1 0 .3l.1.4c.1.3.1.6.2.9l.6 1.8c.4 1.2.9 2.5 1.5 3.7 1.1 2.4 2.4 4.7 4 6.9 1.6 2.1 3.4 4 5.7 5.6 1.1.7 2.4 1.4 3.8 1.8 1.4.4 2.9.7 4.5.8-1.3.9-2.7 1.7-4.3 2.3-1.6.6-3.3 1-5.1 1.2-3.6.4-7.3 0-10.8-.9-3.6-.9-7-2.2-10.3-3.7-1.7-.8-3.3-1.6-4.9-2.6l-2.4-1.5c-.4-.3-.8-.5-1.2-.8l-.6-.5c-.2-.2-.4-.3-.7-.6-4.1-5.5-3-13.4 2.5-17.5 5.4-4.3 13.2-3.1 17.4 2.4z"
      />
      <path className="__happy-icon-st1" d="M224 114.6s68.7-29.3 95.5 40.6" />
      <path
        className="__happy-icon-st6"
        d="M347.7 119.2c-.3.3-.5.4-.7.6l-.6.5c-.4.3-.8.6-1.2.8l-2.4 1.5c-1.6.9-3.2 1.8-4.9 2.6-3.3 1.6-6.7 2.9-10.3 3.7-3.5.9-7.3 1.3-10.8.9-1.8-.2-3.5-.6-5.1-1.2-1.6-.6-3-1.4-4.3-2.3 1.6-.1 3.1-.4 4.5-.8 1.4-.5 2.6-1.1 3.8-1.8 2.3-1.5 4.1-3.4 5.7-5.6 1.6-2.1 2.9-4.5 4-6.9.6-1.2 1.1-2.4 1.5-3.7l.6-1.8c.1-.3.2-.6.2-.9l.1-.4c0-.1.1-.3 0-.3 4.1-5.5 12-6.6 17.5-2.5s6.5 12.1 2.4 17.6z"
      />
      <path
        className="__happy-icon-st1"
        d="M112.4 205.9s11.3 65.4 67.7 65.3c56.3-.1 67.9-65.6 67.9-65.6"
      />
    </svg>
  );
}

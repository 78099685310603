import { useEffect, useRef, useState } from 'react';
import { SoundFontPlayer } from '@magenta/music';
import STYLE_TEMPO_SEQUENCES from '../../utils/rails_generated/sequences';
import TEMPO_RANGES from '../../utils/rails_generated/tempo_ranges';
import DEFAULT_SWING_VALUES from '../../utils/rails_generated/default_swing_values';
import useEventCallback from '../../hooks/useEventCallback';
import { useDebouncedCallback } from 'use-debounce/lib';
import Slider from 'rc-slider';

import PauseIconV2 from '../../icons/PauseIconV2';
import PlayIconV2 from '../../icons/PlayIconV2';
import './RhythmControls.scss';

let sfPlayer;

// When a sequence is first loaded, replicate it to a total count to avoid the delay in the loop:
const REPEAT_SEQUENCE_COUNT = 10;

// because some tempo ranges can be very short (ie: hip hop [70, 80], use a multiplier to smooth out the slider UX):
const TEMPO_SLIDER_MULTIPLIER = 5;

const MIN_SWING = 50;
const MAX_SWING = 66;

// genres that have swing disabled:
const DISABLED_SWING_GENRES = ['anime', 'oddity'];

export default function RhythmControls({ genre, mood, disabled, onChangeTempo, onChangeSwing }) {

  const [tempo, setTempo] = useState(null);
  const [swing, setSwing] = useState(null);
  const [status, setStatus] = useState('stopped');
  const [relativeTempo, setRelativeTempo] = useState(() => Math.random());
  // const [playingNote, setPlayingNote] = useState(null);
  const [genreChanged, setGenreChanged] = useState(false);
  const [tempoRange, setTempoRange] = useState([80, 120]);

  const sequenceRef = useRef();
  const tempoRef = useRef();
  const offsetRef = useRef();
  const swingEndTime = useRef();

  const togglePlay = () => {
    if (status === 'playing') {
      sfPlayer.pause();
      setStatus('paused');
      return;
    }

    else if (status === 'paused') {
      sfPlayer.resume();
      sfPlayer.setTempo(tempoRef.current);
      setStatus('playing');
      return;
    }
    else {
      startSong();
    }
  }

  const changeSwingEndTime = note => {
    swingEndTime.current = note.endTime;
    offsetRef.current = performance.now();
  }

  // magenta adds a few seconds of compilation. Removing it can be done or conditional loading:
  // player = {};
  // const mm = require('@magenta/music');
  // player =  new mm.Player();
  const startSong = useEventCallback(useSwing => {
    setStatus('playing');

    sfPlayer = new SoundFontPlayer('https://storage.googleapis.com/magentadata/js/soundfonts/sgm_plus'); //, null, null, null, n => console.log(n));

    sfPlayer.callbackObject = {
      run: changeSwingEndTime,
      stop: () => null,
    };

    const chooseRandom = array => array[Math.floor(Math.random() * array.length)];
    const sequence = chooseRandom(STYLE_TEMPO_SEQUENCES[genre]);

    // -> play all percussion pitches
    /*
    const sequence = {
      totalTime          : 40,
      _originaltotalTime : 40,
      notes              : [],
    }

    for (let i = 0; i < 60; ++i) {
      sequence.notes.push({ pitch: i + 35, startTime: i / 2, endTime: i / 2, velocity: 60, isDrum: true })
    };
    */
    // <- end -> 

    sequenceRef.current = sequence;

    if (!sequence._originaltotalTime) {

      // extend the sequence COUNT-1 times:
      const notesLength = sequence.notes.length;

      let pitchCount = 0;
      const pitches = [];

      for (let i = 0; i < notesLength; ++i) {

        if (!pitches[sequence.notes[i].pitch]) {
          pitches[sequence.notes[i].pitch] = pitchCount;
          pitchCount++;
        }

        const newNotes = { ...sequence.notes[i] };

        for (let j = 1; j < REPEAT_SEQUENCE_COUNT; ++j) {
          newNotes.startTime += sequence.totalTime;
          newNotes.endTime += sequence.totalTime;

          sequence.notes[i + (notesLength * j)] = { ...newNotes };
        }

        sequence._pitches = pitches;
      }

      sequence.totalTime *= REPEAT_SEQUENCE_COUNT;
      sequence._originaltotalTime = sequence.totalTime;
    }
    else {
      sequence.totalTime = sequence._originaltotalTime;
    }
    
    const realSwing = useSwing || (swing / TEMPO_SLIDER_MULTIPLIER);
    const upbeatCorrection = 0.5 * (realSwing - 50) / 100;
    let swingSequence = sequence;

    if (upbeatCorrection) {
      swingSequence = JSON.parse(JSON.stringify(sequenceRef.current));
  
      swingSequence.notes.forEach(note => {
        if (note.isUpbeat) {
          note.startTime += upbeatCorrection;
          note.endTime += upbeatCorrection;
        }
      });
    }

    sfPlayer.loadSamples(swingSequence).then(() => {
      const loop = () => {
        sfPlayer.setTempo(tempoRef.current || tempo);
        return sfPlayer.start(swingSequence);
      }
  
      //! This creates an infinite tail, memory can leak, can try with async:
      // https://stackoverflow.com/questions/39894777/how-to-have-an-async-endless-loop-with-promises
  
      Promise.resolve().then(function resolver() {
        return loop().then(loop).then(resolver);
      }).catch((error) => {
          console.log("Error: " + error);
      });
    });
  });

  const changeTempo = value => {

    if (genreChanged) {
      setGenreChanged(false);
      return;
    }
    if (disabled) {
      return;
    }
    
    const realTempo = Math.round(value / TEMPO_SLIDER_MULTIPLIER);

    tempoRef.current = realTempo;
    setTempo(value);
    setRelativeTempo((realTempo - tempoRange[0]) / (tempoRange[1] - tempoRange[0]));
    
    if (sequenceRef.current) {
      sfPlayer?.setTempo(realTempo);
      sequenceRef.current.totalTime = sequenceRef.current._originalTotalTime * 120 / realTempo;
    }

    onChangeTempo(realTempo);
  }

  const changeSwing = value => {
    setSwing(value);

    onChangeSwing(Math.round(value / TEMPO_SLIDER_MULTIPLIER));

    if (sfPlayer) {
      debouncedChangeSwing(value / TEMPO_SLIDER_MULTIPLIER);
    }
  }

  const debouncedChangeSwing = useDebouncedCallback(swing  => {
    
    sfPlayer.stop();

    let adjustForSwing = true;

    const loop = () => {

      const realSwing = swing;
      const upbeatCorrection = 0.5 * (realSwing - 50) / 100;
      const extraOffset = (performance.now() - offsetRef.current) / 1000;

      let swingSequence = sequenceRef.current;
  
      if (upbeatCorrection) {
        swingSequence = JSON.parse(JSON.stringify(sequenceRef.current));
    
        swingSequence.notes.forEach(note => {
          if (note.isUpbeat) {
            note.startTime += upbeatCorrection;
            note.endTime += upbeatCorrection;
          }

          if (adjustForSwing && note.endTime <= swingEndTime.current + extraOffset) {
            note.startTime = -1;
            note.endTime = -1;
          }    
        });
      }

      let startTime = adjustForSwing ? swingEndTime.current + extraOffset : 0;
      adjustForSwing = false;
      sfPlayer.setTempo(tempoRef.current || tempo);
      return sfPlayer.start(swingSequence, undefined, startTime);
    }

    //! This creates an infinite tail, memory can leak, can try with async:
    // https://stackoverflow.com/questions/39894777/how-to-have-an-async-endless-loop-with-promises

    Promise.resolve().then(function resolver() {
      return loop().then(loop).then(resolver);
    }).catch((error) => {
        console.log("Error: " + error);
    });
  }, 100, { maxWait: 500 });

  const switchGenre = useEventCallback(() => {
    let useSwing = undefined;

    if (mood) {
      const range = TEMPO_RANGES[mood][genre];

      if (range !== tempoRange) {
        setGenreChanged(true);
        setTempoRange(TEMPO_RANGES[mood][genre]);

        const newTempo = relativeTempo * (range[1] - range[0]) + range[0];
        setTempo(newTempo * TEMPO_SLIDER_MULTIPLIER);
        tempoRef.current = newTempo;
        onChangeTempo(Math.round(newTempo));
      }

      useSwing = DEFAULT_SWING_VALUES[mood][genre];
      setSwing(useSwing * TEMPO_SLIDER_MULTIPLIER);

      onChangeSwing(useSwing);
    }

    if (status === 'playing') {
      startSong(useSwing || 50);
    }

    else if (status === 'paused') {
      setStatus('stopped');
    }
  });

  useEffect(() => {
    if (!genre) {
      setRelativeTempo(Math.random());
      return;
    }

    switchGenre();

    return () => {
      sfPlayer?.stop();
      sfPlayer = null;
    };

  }, [genre, switchGenre]);

  return (
    <div className='__rhythm-controls'>
      <div className='title'>
        <div role='button' className='playback-toggle' onClick={() => !disabled && togglePlay()}>
          { status === 'playing' ? <PauseIconV2 /> : <PlayIconV2 /> }
        </div>
        <div>Rhythm</div>
      </div>

      <div className='label-slider'>
        <div className='slider-label'>Tempo</div>
        <div className='__rhythm-slider'>
          <Slider min={tempoRange[0] * TEMPO_SLIDER_MULTIPLIER} max={tempoRange[1] * TEMPO_SLIDER_MULTIPLIER} value={disabled ? null : tempo} onChange={changeTempo} disabled={disabled} />
        </div>
      </div>

      { !DISABLED_SWING_GENRES.includes(genre) &&
        <div className='label-slider'>
          <div className='slider-label'>Swing</div>
          <div className='__rhythm-slider'>
            <Slider min={MIN_SWING * TEMPO_SLIDER_MULTIPLIER} max={MAX_SWING * TEMPO_SLIDER_MULTIPLIER} value={disabled ? null : swing} onChange={changeSwing} disabled={disabled} />
          </div>
        </div>
      }
    </div>
  );
}
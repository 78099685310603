export default function EpicOutline(props) {
  return (
    <svg
      id="__epic-outline-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 115.99 113.56"
      {...props}
    >
      <defs>
        <style>
          {
            ".__epic-outline-prefix__cls-1{fill:none;stroke:#cfd4d8;stroke-miterlimit:10;stroke-width:2px}.__epic-outline-prefix__cls-2{fill:#cfd4d8}"
          }
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 25 copy"}</title>
      <rect
        className="__epic-outline-prefix__cls-1"
        x={3.33}
        y={1.21}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
      />
      <path
        className="__epic-outline-prefix__cls-1"
        d="M72.59 25.01l2.96 9.82L55.4 80.01l-6.12-2.73-6.12-2.73 20.15-45.18 9.28-4.36zM68.9 33.28L45.5 85.75"
      />
      <path
        className="__epic-outline-prefix__cls-1"
        d="M50 75.74L61.83 81a5.53 5.53 0 01-7.3 2.8l-2.62-1.17-6.54 14.7 2 .88a2 2 0 01-1.65 3.71l-6.17-2.75-6.17-2.75A2 2 0 1135 92.7l2 .88 6.54-14.67-2.64-1.16a5.53 5.53 0 01-2.8-7.3z"
      />
      <path
        className="__epic-outline-prefix__cls-2"
        d="M42.15 13.67l9.57 23.09H37.68l.57 16.14-12.33-24.11h5.92l-8.21-15.12h18.52zM98 55.31l-17.44-2.43 2.49 9.58-11.11 2.47 18.63 4.13-1.05-4.04 11.77 2.92L98 55.31z"
      />
    </svg>
  )
}

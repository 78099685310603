export default function DragHandleIcon(props) {
  return (
    <svg
      width={16}
      height={26}
      viewBox="0 0 16 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.02 4.73a2.02 2.02 0 1 0 0-4.04 2.02 2.02 0 0 0 0 4.04ZM2.02 15.1a2.02 2.02 0 1 0 0-4.04 2.02 2.02 0 0 0 0 4.04ZM2.02 25.47a2.02 2.02 0 1 0 0-4.04 2.02 2.02 0 0 0 0 4.04ZM13.39 4.73a2.02 2.02 0 1 0 0-4.04 2.02 2.02 0 0 0 0 4.04ZM13.39 15.1a2.02 2.02 0 1 0 0-4.04 2.02 2.02 0 0 0 0 4.04ZM13.39 25.47a2.02 2.02 0 1 0 0-4.04 2.02 2.02 0 0 0 0 4.04Z"
        fill="#fff"
      />
    </svg>
  )
}

import * as React from "react";
const SvgInstrumentDrums808 = (props) => (
  <svg
    id="InstrumentDrums808_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentDrums808_svg__st99{fill:#f48864}.InstrumentDrums808_svg__st100{fill:#fdc676}.InstrumentDrums808_svg__st102{fill:#528459}.InstrumentDrums808_svg__st103{fill:#c55a74}.InstrumentDrums808_svg__st104{fill:#f8c4b9}"
      }
    </style>

    <path
      className="InstrumentDrums808_svg__st104"
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
    />
    <path
      d="M91.69 77.59H19.15c-5.2 0-9.41-4.21-9.41-9.41v-32c0-5.2 4.21-9.41 9.41-9.41h72.54c5.2 0 9.41 4.21 9.41 9.41v32c0 5.2-4.21 9.41-9.41 9.41z"
      style={{
        fill: "#4b4a9e",
      }}
    />
    <path
      className="InstrumentDrums808_svg__st102"
      d="M94.83 74.4H16.78a3.92 3.92 0 0 1-3.92-3.92v-7.84a3.92 3.92 0 0 1 3.92-3.92h78.05a3.92 3.92 0 0 1 3.92 3.92v7.84c0 2.16-1.76 3.92-3.92 3.92z"
    />
    <circle
      className="InstrumentDrums808_svg__st102"
      cx={18.32}
      cy={49.87}
      r={4.87}
    />
    <circle
      className="InstrumentDrums808_svg__st100"
      cx={15.69}
      cy={34.59}
      r={2.44}
    />
    <circle
      className="InstrumentDrums808_svg__st99"
      cx={25.26}
      cy={34.34}
      r={2.44}
    />
    <circle
      className="InstrumentDrums808_svg__st104"
      cx={25.26}
      cy={43.11}
      r={2.44}
    />
    <circle
      className="InstrumentDrums808_svg__st102"
      cx={94.71}
      cy={53.44}
      r={1.59}
    />
    <circle
      className="InstrumentDrums808_svg__st99"
      cx={26.27}
      cy={52.18}
      r={2.44}
    />
    <path
      className="InstrumentDrums808_svg__st100"
      d="M21.67 70.99h-5.49c-.79 0-1.43-.64-1.43-1.43v-1.1c0-.79.64-1.43 1.43-1.43h5.49c.79 0 1.43.64 1.43 1.43v1.1c0 .79-.64 1.43-1.43 1.43zM95.82 70.99H93.6a.86.86 0 0 1-.86-.86V67.9c0-.48.39-.86.86-.86h2.22c.48 0 .86.39.86.86v2.22c0 .48-.38.87-.86.87z"
    />
    <path
      className="InstrumentDrums808_svg__st103"
      d="M30.75 66.79h2.69v4.91h-2.69zM34.41 66.79h2.69v4.91h-2.69zM38.31 66.79H41v4.91h-2.69zM42.05 66.79h2.69v4.91h-2.69z"
    />
    <path
      className="InstrumentDrums808_svg__st99"
      d="M45.87 66.79h2.69v4.91h-2.69zM49.54 66.79h2.69v4.91h-2.69zM53.43 66.79h2.69v4.91h-2.69zM57.17 66.79h2.69v4.91h-2.69z"
    />
    <path
      className="InstrumentDrums808_svg__st100"
      d="M60.68 66.79h2.69v4.91h-2.69zM64.35 66.79h2.69v4.91h-2.69zM68.24 66.79h2.69v4.91h-2.69zM71.99 66.79h2.69v4.91h-2.69z"
    />
    <path
      className="InstrumentDrums808_svg__st104"
      d="M75.8 66.79h2.69v4.91H75.8zM79.47 66.79h2.69v4.91h-2.69zM83.37 66.79h2.69v4.91h-2.69zM87.11 66.79h2.69v4.91h-2.69zM88.16 65.51H32.39c-.91 0-1.64-.73-1.64-1.64v-2.72c0-.91.73-1.64 1.64-1.64h55.77c.91 0 1.64.73 1.64 1.64v2.72c0 .9-.74 1.64-1.64 1.64z"
    />
    <circle
      className="InstrumentDrums808_svg__st103"
      cx={33.83}
      cy={30.9}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st103"
      cx={39.47}
      cy={30.9}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st103"
      cx={45.26}
      cy={30.9}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st103"
      cx={50.9}
      cy={30.9}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st103"
      cx={56.51}
      cy={30.9}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st103"
      cx={62.15}
      cy={30.9}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st104"
      cx={39.47}
      cy={37.44}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st104"
      cx={45.26}
      cy={37.44}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st104"
      cx={39.16}
      cy={43.99}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st104"
      cx={44.95}
      cy={43.99}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st104"
      cx={84.71}
      cy={38.15}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st104"
      cx={90.39}
      cy={43.99}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st104"
      cx={50.9}
      cy={37.44}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st104"
      cx={56.51}
      cy={37.44}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st104"
      cx={62.15}
      cy={37.44}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st103"
      cx={67.94}
      cy={30.9}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st103"
      cx={73.58}
      cy={30.9}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st103"
      cx={78.96}
      cy={30.9}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st103"
      cx={84.6}
      cy={30.9}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st103"
      cx={90.39}
      cy={30.9}
      r={1.09}
    />
    <circle
      className="InstrumentDrums808_svg__st103"
      cx={96.03}
      cy={30.9}
      r={1.09}
    />
    <path
      className="InstrumentDrums808_svg__st100"
      d="M96.36 50.78H30.41a.66.66 0 0 1-.66-.66v-.49c0-.36.29-.66.66-.66h65.95c.36 0 .66.29.66.66v.49c0 .36-.29.66-.66.66zM86.62 45.01H48.65c-.28 0-.5-.22-.5-.5v-.81c0-.28.22-.5.5-.5h37.98c.28 0 .5.22.5.5v.81c-.01.27-.23.5-.51.5z"
    />
  </svg>
);
export default SvgInstrumentDrums808;


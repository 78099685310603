import * as React from "react";

const InstrumentFingerBass = (props) => (
  <svg
    id="InstrumentFingerBass_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentFingerBass_svg__st2{fill:#fdcc75}.InstrumentFingerBass_svg__st6{fill:#ca5672}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#cfd125",
      }}
    />
    <path
      d="M68.79 63.29s1.27.22 1.88.03c1.7-.56 2.22-2.77 1.91-4.33-.21-1.05-.72-2.02-1.34-2.89-.45-.63-.99-1.31-.92-2.08.14-1.64 2.19-1.75 3.41-1.49 1.48.31 3.46 2.17 4.49 3.25 3 3.17 2.58 5.51 4.14 7.9 2.02 3.09 4.41 2.91 7.75 4 6.24 2.03 12.93 6.48 12.49 13.84-.19 3.11-1.78 5.24-3.45 7.69-1.98 2.9-4.66 5.37-7.35 7.6-3.15 2.61-7.32 4.99-11.58 4.37-7.3-1.07-9.88-7.74-12.27-13.79-.92-2.33-1.9-5.84-3.97-7.36-2.47-1.81-5.15-3.2-7.84-4.65-3.99-2.16-7.81-5.66-8.91-10.24-.16-.66-.23-1.38.08-1.98 1.32-2.51 4.49.09 5.8 1.17 1.32 1.09 2.86 2.2 4.56 2.01 1.66-.18 3.04-1.8 2.94-3.48.02.13 7.46.39 8.18.43z"
      style={{
        fill: "#4e4092",
        stroke: "#4e4092",
        strokeWidth: 0.9873,
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentFingerBass_svg__st2"
      d="M64.74 67.19c-1.6.15-2.83.58-1.43 2.12 2.35 2.61 5.01 4.98 6.91 7.96 1.56 2.44 2.87 7.04 6.28 7.53 3.5.5 8.51-6.7 11.72-8.7 3.81-2.38 6.8 2.69 7.9 5.65.66 1.76 1.25 3.85 2.69 1.25 2.31-4.18-.15-7.9-3.67-10.32-1.82-1.25-3.85-2.21-5.91-3-2.83-1.09-5.87-1.29-7.76-3.94-2.4-3.37-2.65-8.02-6.97-9.71-.32-.12-.68-.23-.99-.09-.82.37.08 1.54.36 1.97.81 1.27 1.3 2.47.95 3.98-.8 3.4-3.9 4.74-7.09 5.13-.74.1-1.95.08-2.99.17z"
    />
    <path
      className="InstrumentFingerBass_svg__st6"
      d="m79.35 78.42-2.59 2.59c-.46.46-1.2.46-1.65 0l-.63-.63c-.46-.46-.46-1.2 0-1.65l2.59-2.59c.46-.46 1.2-.46 1.65 0l.63.63c.45.45.45 1.19 0 1.65zM84.57 77.77l-2.59 2.59c-.46.46-1.2.46-1.65 0l-.63-.63c-.46-.46-.46-1.2 0-1.65l2.59-2.59c.46-.46 1.2-.46 1.65 0l.63.63c.45.46.45 1.2 0 1.65z"
    />
    <circle
      className="InstrumentFingerBass_svg__st6"
      cx={92.46}
      cy={74}
      r={1.08}
    />
    <circle
      className="InstrumentFingerBass_svg__st6"
      cx={96.97}
      cy={77.86}
      r={0.96}
    />
    <path
      className="InstrumentFingerBass_svg__st6"
      d="m23.66 25.17 3.35-3.34 45.95 44.88c.77.75.78 1.99.01 2.75l-1.94 1.93c-.78.78-2.04.76-2.8-.03L23.66 25.17z"
    />
    <path
      d="M26.09 27.5s-2.76 2.36-4.04.49c-2.64-3.87-5.13-7.84-7.68-11.77-.75-1.15-1.52-2.3-2.26-3.46-.58-.91-1.32-1.82-1.27-2.96.16-3.3 4.95-4.56 6.74-1.82.91 1.39.23 3.15 1.21 4.42.99 1.28 3.13 1.85 4.5 2.66 1.51.89 2.95 1.89 4.33 2.96.68.53.62.49.29 1.16-.29.59-.38 1.26-.35 1.91.1 1.67 1.87 3.06 1.87 3.06l-3.34 3.35z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentFingerBass_svg__st2"
      d="m95.51 90.63-3.3 3.29c-.49.49-1.27.48-1.76 0l-1.34-1.34c-.49-.49-.48-1.27 0-1.76l3.3-3.29c.49-.49 1.27-.48 1.76 0l1.34 1.34c.49.49.49 1.27 0 1.76z"
    />
  </svg>
);

export default InstrumentFingerBass;

import { getExactTranslations } from "./translations_en_exact";

const TRANSLATIONS_EN = {

  // Landing Page
  // ------------
  'lp-what-is-strofe' : "Strofe opens up the door for anybody to create music with the power of A.I.",
  'lp-purpose'        : "Make music that fits the mood and theme of your video game, Twitch stream, Youtube video, podcast and more. Take advantage of built-in mixing and mastering tools to tweak it until it’s just right.",
  'lp-simple-tool'    : "Every song composed through Strofe is unique and free of any concerns of copyright claims or DMCA takedowns.",
  'lp-easy-usage'     : "Whether you’re a seasoned music producer looking for a helpful tool or just exploring music for the first time, Strofe is here to help. You’re a few clicks away from your first or thousandth song!",
  'lp-mood-title'     : "PICK A MOOD, PICK A GENRE, AND GO!",

  // Login Modal
  // -----------
  'invalid-email-password': "Your email/password combination is not valid.",

  // Feedback
  // --------
  'feedback-success-1': 'Thank you for your feedback!',
  'feedback-success-2': 'We will be in touch with you via email.',

  // Songs
  // -----
  'delete-song-warning'     : "Are you sure you would like to delete this track? Once deleted, this track cannot be recovered",
  'track-load-error'        : "This track was not found or you don't have access to it.",
  'track-load-mp3-error'    : "Oops! There was an error loading this track. Refresh this page to try again.",
  
  'instrument-change-error' : "The selected instrument is unavailable, reverting to previous instrument.",
  'instrument-max-limit'    : "<p>Oops! You've reached the maximum number of instruments allowed per music track.</p><p>Remove other instruments first to add new ones.</p>",
  // Create Song
  // -----------
  'reroll-modal-description' : "Rerolling saves this song and makes a new one with the same settings.",

  // Library
  // -------
  'trackname-copy': "{{trackname}} - copy",

  // Payment
  // -------
  // eslint-disable-next-line
  'subscription-price-cancellation' : "You will be charged a {{cadence}} fee of ${{price}}. You can cancel your subscription at any time.",
  // eslint-disable-next-line
  'subscription-price-free-trial'   : "<p>Your card will not be charged today. After your 7 day trial ends, you will be charged a {{cadence}} fee of ${{price}}.</p><p>You can cancel your subscription at any time.</p>",
  // eslint-disable-next-line
  'pay-price'                       : "Pay ${{price}}",
  'get-track-coins'                 : "Get this track using Strofe Coins!",
  'get-track-description'           : "Once purchased, this track belongs to you. Use it however, wherever, whenever! It’s yours to do with as you please.",
  'get-midi-coins'                  : "Get the MIDI file for this track using Strofe Coins!",
  'get-midi-description'            : "Download the MIDI file to load the track into your favorite DAW.",
  'confirm-purchase'                : "Here's a summary of your balance after this transaction:",
  'not-enough-coins'                : "Whoops! You don’t have enough Strofe Coins to get this track.",
  'not-enough-remaining-downloads'  : "Whoops! You don't have any remaining downloads left for this period. Get in touch so we can help you or wait for your next monthly billing cycle.",
  'you-need-x-coins'                : "You currently have {{balance}} coins and need {{missing}} more to get this track.",
  'x-strofe-coins'                  : "{{amount}} Strofe Coins",

  // Settings
  // --------
  'email-verification-regex-error'   : "Your verification code should contain 6 numbers with no letters or special characters",
  'email-verification-request-error' : "Your verification code is invalid or has expired.",

  'confirm-password-error'           : "Your new password and the confirmation don't match.",
  'current-password-error'           : "Your previous password doesn't match the one on record.",
  'profile-last-updated-date'        : "Profile last updated: {{date}}",
  'display-name-taken'               : "The display name you chose is already taken.",
  'display-name-blank-error'         : "Display name cannot be blank.",
  'email-taken'                      : "This email is already taken.",
  'name-or-email-taken'              : "The display name or email you chose are already taken.",
  'ambassador-detail'                : "For your committed effort in promoting and helping out Strofe become better, you can purchase and download tracks for free.",

  'delete-account-warning'           : "<strong>Warning:</strong> all your personal information as well as created tracks will be deleted. This change is irreversible and you will no longer have access to your account.",
  'delete-account-confirm'           : "Please confirm that you would like to delete your account. A verification code will be sent to your email.",
  'delete-account-email-code'        : "To delete your account, enter the verification code you received in your email:",
  'cancel-subscription'              : '<p>Would you like to cancel your membership?</p><p>If so, type "unsubscribe" on the input field below and confirm:</p>',
  'become-member-future'             : "In the meantime, you can continue to create more music. Is there something you wish Strofe could do for you? Let us know at the question mark below!",
  'trial-x-days-left_one'            : "You have {{count}} day left on your free trial",
  'trial-x-days-left_other'          : "You have {{count}} days left on your free trial",
  'next-payment-date'                : "Next Payment date: {{date}}",
  'subscription-ends-date'           : "Your subscription ends on {{date}}",

  // Coin Transactions
  // -------
  'my-balance'             : 'My Balance:',
  'no-transactions-filter' : "There are no Transactions for the selected time frame and filter.",
  // eslint-disable-next-line
  'transaction-amount-x'    : "Transaction amount: ${{cost}}",
  
  // Get Coins
  // ---------
  'ways-earn-coins'       : "You can also get some free coins! These are some ways to earn them!",
  'create-account-steps'  : "Earn <strong>119 Strofe Coins</strong> by setting up an email and password.",
  'verify-email-steps'    : "Earn <strong>120 Strofe Coins</strong>, just type the verification code we sent to the email you used above. If you used a 3rd party login (like Facebook or Discord) your email will be verified automatically.",
  'daily-song-steps'      : "For each day that you return to create a song, you accumulate more Strofe Coins, <strong>1 the first day, 2 the second, then 4... and so on up to 16,</strong> which will remain as long as you continue creating daily.",
  'new-ways-get-coins'    : "* We'll be constantly adding new ways to earn and spend coins so be sure to check in often!",
  'coin-balance-summary'  : "Here's a summary of your balance:",
  'payment-details'       : "Payment Method",
  'invite-instructions'   : "Once you have setup your display name, use your personalized invite link and you'll receive <strong>240 Strofe Coins</strong> whenever someone uses it to create an account:",
  'own-x-tracks_one'      : "Purchase and own {{count}} track¹",
  'own-x-tracks_other'    : "Purchase and own {{count}} tracks¹",
  'purchase-using-coins'  : "Purchase your Track using Strofe Coins once, and own it forever. Use it however you want, wherever you want.",

  'download-tracks-premium' : 'You can pick songs to own by pressing "download" at the bottom of the player screen or library page. Songs that you own are marked with <icon></icon> in your library.',
  'trial-ends-date'         : "Your free trial ends on {{date}} and can be canceled anytime on your Settings page",

  // FAQ
  // ---
  'faq-commercial-purpose'    : "<p>Any track that you purchase can be used however you wish, in any commercial project and without having to mention Strofe.</p>",
  'faq-commercial-purpose-dl' : "<p>Any track that you download can be used however you wish, in any commercial project and without having to mention Strofe.</p>",
  'faq-what-are-coins'        : "<p>Strofe Coins are Strofe's virtual currency that can be used to purchase ownership of the songs you create at Strofe.com. Purchase coins in bulk for a discount or earn them for free.</p>",
  'faq-track-cost'            : "<p>The track in high-quality MP3 format costs 240 Strofe coins, and the MIDI source file costs 200 coins. If you purchase both the MP3 and MIDI file together, you get a discounted cost of 400 coins.</p>",
  'faq-edit-track'            : "<p>Absolutely! You can make unlimited edits using our Track Editor to a track you purchased and keep downloading it.</p>",
  'faq-ownership'             : "<p>The track is yours forever, you are free to edit and clip it in any way you desire.</p>",
  'faq-coins-expire'          : "<p>Coins you own will always be safe inside your account, you can purchase Strofe Coins in bulk and use them for tracks that you create later on.</p>",
  'faq-strofe-ai'             : "<p>Thousands of tracks are carefully analyzed and used to train and create unique, yet familiar-sounding compositions.</p>",
  'faq-tracks-unique'         : "<p>Tracks in certain moods and genres may sound slightly similar, but it is virtually impossible to create the same melody and harmony even if millions of tracks are created through Strofe. That's the beauty of AI and Machine Learning!</p>",
  'faq-updates'               : "<p>We are constantly improving Strofe, such as adding better quality instruments, and the good news about this is that any track that you own will include all these improvements.</p>",

  'faq-members-what'          : "<p>Strofe Premium Members can create unlimited tracks, and pick up to 300 tracks every month to own forever and download as an mp3 or MIDI file. Use them anywhere, anytime, anyhow - it’s your song!</p>",

  // Coupons
  // -------
  'coupon-valid-until'   : "—Valid until {{valid_until}}—",
  'x-br-strofe-coins'    : "{{amount}}<br />Strofe Coins",
  'coupon-load-error'    : "This coupon has expired or is no longer valid.",
  'redeem-error'         : "You may have already redeemed this coupon or there was an issue with our server.",
  'redeem-error-refresh' : "Refresh this page to try again or contact us if the problem persists.",
  'previously-redeemed'  : "You have previously redeemed this coupon and successfully obtained the Strofe Coins.",

  // Comments
  // --------
  'x-comments_one'        : "{{count}} Comment",
  'x-comments_other'      : "{{count}} Comments",
  'song-url'              : "Song URL: {{url}}",
  'report-comment-id'     : "Comment ID: {{id}}",
  'view-x-replies_one'    : "View {{count}} reply",
  'view-x-replies_other'  : "View {{count}} replies",
  'set-display-name'      : "To comment on this track, please set your display name first:",
  'delete-comment-body'   : "Are you sure you want to delete this comment?",
  'delete-nested-replies' : "All replies to this comment thread will also be deleted.",

  // Loop Creator / Composer
  // -------------------------
  'crop-note-body'       : 'Some notes will get cropped and eliminated in the process. Would you like to continue with the change of duration?',
  'style-plus-number'    : "{{style}} (+{{number}})",
  'save-loop'            : "This loop is already enabled for users in Strofe.com. If you save it, it will be moved back to Draft. Would you like to continue?",
  'new-chords'           : "New Accompaniment",
  'new-bass'             : "New Bass Line",
  'new-drums'            : "New Drum Groove",
  'delete-track-warning' : "Are you sure you would like to delete this track?",

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Translations that are 1:1 in English (default Development language):
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  ...getExactTranslations(),
}

export default TRANSLATIONS_EN;

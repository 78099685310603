import { useState, useRef, useEffect } from 'react';
import { Trans as Translate } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce/lib';
import classNames from 'classnames';

import InstrumentIcon from './InstrumentIcon';

import PointyArrow from '../../icons/PointyArrow';
import './InstrumentSelectorModal.scss';

// magic number of column size
const INSTRUMENT_ICON_WIDTH = 100;
const INSTRUMENT_VISIBLE_COUNT = 5;

export default function InstrumentCategory({ category, selected, setSelected }) {

  const overflowContainerRef = useRef();
  const [scrollX, setScrollX] = useState(0);
  const [leftArrowDisabled, setLeftArrowDisabled] = useState(scrollX === 0);
  const [rightArrowDisabled, setRightArrowDisabled] = useState(category.instruments.length <= INSTRUMENT_VISIBLE_COUNT);

  const checkArrows = useDebouncedCallback(() => {
    if (selected && category.instruments.includes(selected)) {
      const instrumentIndex = category.instruments.findIndex(name => name === selected);

      if (instrumentIndex >= INSTRUMENT_VISIBLE_COUNT) {
        setLeftArrowDisabled(false);
      }

      if (instrumentIndex === category.instruments.length - 1) {
        setRightArrowDisabled(true);
      }

      if (instrumentIndex - (INSTRUMENT_VISIBLE_COUNT - 1) > 0) {
        setScrollX((instrumentIndex - (INSTRUMENT_VISIBLE_COUNT - 1)) * INSTRUMENT_ICON_WIDTH);
      }
    }
  }, 300);

  useEffect(() => {
    checkArrows();
  }, [checkArrows]);
  
  const onClickInstrument = (e, instrumentName) => {
    e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    setSelected(instrumentName);
  }

  const onClickScrollLeft = () => {

    console.log('scrollLeft:', scrollX);

    overflowContainerRef.current.scroll({ left: scrollX - (INSTRUMENT_ICON_WIDTH * 2), behavior: 'smooth' });

    const newScrollX = Math.max(scrollX - (INSTRUMENT_ICON_WIDTH * 2), 0);
    setScrollX(newScrollX);
    setRightArrowDisabled(false);

    if (newScrollX === 0) {
      setLeftArrowDisabled(true);
    }
  }
  
  const onClickScrollRight = () => {
    overflowContainerRef.current.scroll({ left: scrollX + (INSTRUMENT_ICON_WIDTH * 2), behavior: 'smooth' });

    const newScrollX = scrollX + (INSTRUMENT_ICON_WIDTH * 2);

    setScrollX(newScrollX);
    setLeftArrowDisabled(false);

    if (newScrollX >= (category.instruments.length - 5) * INSTRUMENT_ICON_WIDTH) {
      setRightArrowDisabled(true);
    }
  }

  return (
    <>
      <h3 className='category-title'><Translate>{ category.phrase }</Translate></h3>
        <div className='instrument-category'>
          <div className='left-arrow-container'>
            <div className={classNames('arrow-icon', { disabled: leftArrowDisabled })} role='button' onClick={leftArrowDisabled ? null : onClickScrollLeft}><PointyArrow /></div>
          </div>
          <div className='instruments-overflow-container' ref={overflowContainerRef}>
            { category.instruments.map(name => (
              <InstrumentIcon id={'__instrument-modal-' + name.replaceAll(' ', '')} key={name} onClick={onClickInstrument} name={name} selected={selected} />
            ))}
          </div>
          <div className='right-arrow-container'>
          <div className={classNames('arrow-icon', { disabled: rightArrowDisabled })} role='button' onClick={rightArrowDisabled ? null : onClickScrollRight}><PointyArrow /></div>
          </div>
        </div>
    </>
  );
}

export default function AnimeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 110.8 112.6"
      style={{
        enableBackground: "new 0 0 110.8 112.6",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".__anime-icon-0{fill:#c95571}.__anime-icon-4{fill:none;stroke:#fff;stroke-width:.7522;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.__anime-icon-5{fill:#fff}.__anime-icon-6,.__anime-icon-7{stroke:#fff;stroke-width:.25;stroke-miterlimit:10}.__anime-icon-7{stroke-linecap:round;stroke-linejoin:round}"
        }
      </style>
      <path
        className="__anime-icon-0"
        d="M94.1 111.7H16.7C7.4 111.7 0 104.3 0 95V17.6C0 8.3 7.4.9 16.7.9h77.5c9.2 0 16.7 7.5 16.7 16.7V95c-.1 9.3-7.5 16.7-16.8 16.7z"
      />
      <circle
        cx={57.6}
        cy={56.1}
        r={43.1}
        style={{
          fill: "#1f3037",
        }}
      />
      <circle
        cx={57.6}
        cy={56.4}
        r={21.2}
        style={{
          fill: "#f08660",
        }}
      />
      <path
        d="M63.3 62.4s.2 3.6-2.8 4.1c-3 .5-3.8-2.9-3.8-2.9l6.6-1.2z"
        style={{
          stroke: "#000",
          strokeWidth: 1.4327,
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <path
        className="__anime-icon-0"
        d="M58.6 66.3s.1-1.6 1.6-1.9c1.5-.3 2.2 1.1 2.2 1.1s-.2.7-1.8 1c-1.5.3-2-.2-2-.2z"
      />
      <path
        className="__anime-icon-4"
        d="M72.6 21.8s10.3 2.6 15 12.4M91.3 74.9s-2.7 9.7-12.9 14.5M74 26.5s5.1 1 8.1 6.7M35.4 86.8s-8.9-4-13-14.5M34.7 82s-4.8-2.2-7.1-7.9"
      />
      <path
        className="__anime-icon-5"
        d="m87.8 15.6 2 2.4c.3.3.3.8.1 1.2l-1.6 2.7c-.5.9.4 1.9 1.3 1.5l2.9-1.2c.4-.2.9-.1 1.2.3l2 2.4c.7.8 1.9.2 1.8-.8l-.2-3.1c0-.4.2-.9.6-1l2.9-1.2c.9-.4.8-1.7-.2-2l-3-.7c-.4-.1-.8-.5-.8-.9l-.2-3.2c-.1-1-1.4-1.3-1.9-.5L93 14.2c-.2.4-.7.6-1.1.5l-3-.7c-1-.3-1.8.9-1.1 1.6zM4.4 50.8l2.8 3.3c.4.5.5 1.1.1 1.7l-2.2 3.7c-.7 1.2.5 2.6 1.8 2.1l4-1.6c.6-.2 1.2-.1 1.6.4l2.8 3.3c.9 1.1 2.6.3 2.5-1l-.3-4.3c0-.6.3-1.2.9-1.4l4-1.6c1.3-.5 1.1-2.4-.2-2.7l-4.2-1c-.6-.1-1-.7-1.1-1.3l-.3-4.3c-.1-1.4-1.9-1.8-2.6-.6l-2.2 3.7c-.3.5-1 .8-1.6.6l-4.2-1c-1.6-.6-2.5.9-1.6 2zM101.6 42.5l-1.6.8c-.2.1-.5.1-.7-.1L98.1 42c-.4-.4-1.1-.1-1 .5l.3 1.7c0 .2-.1.5-.3.6l-1.6.8c-.5.2-.4 1 .2 1.1l1.7.3c.2 0 .4.2.5.5l.3 1.7c.1.6.8.7 1.1.2l.8-1.5c.1-.2.4-.3.6-.3l1.7.3c.6.1.9-.6.5-1l-1.2-1.2c-.2-.2-.2-.5-.1-.7l.8-1.5c.2-.7-.3-1.2-.8-1z"
      />
      <path d="M37.5 58.4c.7 5.5 4.9 9.6 9.4 9 2.7-.3 4.9-2.3 6-5 .8-1.8 1.1-3.9.8-6.1-.7-5.5-4.9-9.6-9.4-9-4.5.6-7.5 5.5-6.8 11.1z" />
      <path
        className="__anime-icon-5"
        d="M47.1 48.6c.7.2 1.5.6 2.1 1.2 1.4 1.2 2.7 3.6 2.5 5.6-.2 1.7-2.4.7-3.3.3-1.7-.8-3.8-1.8-5-3.5-1.2-1.7-.1-3.2 1.5-3.6.8-.2 1.5-.2 2.2 0z"
      />
      <path
        className="__anime-icon-5"
        d="M45.8 53.3c-.4 0-.9.1-1.2.3-.9.6-1.6 2.4-.6 3.2 1.1.8 2.4-.1 2.4-.1.4-.3.8-1.3.9-1.8.2-1.1-.6-1.6-1.5-1.6z"
      />
      <path
        className="__anime-icon-6"
        d="M37.9 52.8c.8-1.2 1.3-2.6 2.4-3.7 1.2-1.2 2.7-2.1 4.3-2.7 2.3-.8 4.9 0 7.1-.8 1.2-.4 1.9-1.7 2.6-2.7 0 0 .6 1.5-1.2 3.2 0 0 1.8.2 3-1.9 0 0 .6 2.5-2.5 3.5 0 0 2.5 2.6 1.9 4 .1-.3-.8-1.4-1.9-2-1.1-.6-3.1-1.5-7.5-1.1-4.4.5-7.4 2.9-8.2 4.2z"
      />
      <path
        className="__anime-icon-5"
        d="M48.5 58.7c.1-.1.2-.1.4-.1.4 0 1.9.1 1.8.7-.1.8-1.1 1.5-1.7 1.5-1.2.1-1.2-1.5-.5-2.1z"
      />
      <path
        className="__anime-icon-6"
        d="M38.6 51.5s-1.8 1.3-3.6.9c0 0 1.7 1.7 2.4 1.3.7-.3 1.2-2.2 1.2-2.2z"
      />
      <path d="M63.4 53.8c.7 5.6 5 9.7 9.5 9.1 2.7-.4 4.9-2.3 6.1-5 .8-1.8 1.1-3.9.8-6.2-.7-5.6-5-9.7-9.5-9.1s-7.7 5.6-6.9 11.2z" />
      <path
        className="__anime-icon-5"
        d="M73 44.2c.7.2 1.5.6 2.1 1.2 1.4 1.2 2.7 3.6 2.5 5.6-.2 1.7-2.4.7-3.3.3-1.7-.8-3.8-1.8-5-3.5-1.2-1.7-.1-3.2 1.5-3.6.7-.2 1.5-.2 2.2 0z"
      />
      <path
        className="__anime-icon-5"
        d="M71.7 48.9c-.4 0-.9.1-1.2.3-.8.6-1.6 2.3-.6 3.1 1.1.8 2.4-.1 2.4-.1.4-.3.8-1.3.9-1.8.2-1-.7-1.5-1.5-1.5z"
      />
      <path
        className="__anime-icon-6"
        d="M63.7 48.3c.8-1.2 1.3-2.6 2.4-3.7 1.2-1.2 2.7-2.1 4.3-2.7 2.3-.8 4.9 0 7.1-.8 1.2-.4 1.9-1.7 2.6-2.7 0 0 .6 1.5-1.2 3.2 0 0 1.8.2 3-1.9 0 0 .6 2.5-2.5 3.5 0 0 2.5 2.6 1.9 4 .1-.3-.8-1.4-1.9-2-1.1-.6-3.1-1.5-7.5-1.1s-7.4 3-8.2 4.2z"
      />
      <path
        className="__anime-icon-5"
        d="M74.3 54.3c.1-.1.2-.1.4-.1.4 0 1.9.1 1.8.7-.1.8-1.1 1.5-1.7 1.5-1.1.1-1.1-1.6-.5-2.1z"
      />
      <path
        className="__anime-icon-6"
        d="M64.2 47.7s-1.8 1.3-3.6.9c0 0 1.7 1.7 2.4 1.3s1.2-2.2 1.2-2.2z"
      />
      <path
        className="__anime-icon-7"
        d="M46.3 67.4s3 .1 5-2.5c0 0 3.1.2 3.6 1.1 0 0-2.3-.2-3.3 0l1 1.6s-1.6-.9-2.8-.7l-.4 1.2-3.1-.7zM72.2 62.9s3 .1 5-2.5c0 0 3.1.2 3.6 1.1 0 0-2.3-.2-3.3 0l1 1.6s-1.6-.9-2.8-.7l-.4 1.2-3.1-.7z"
      />
    </svg>
  )
}

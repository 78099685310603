import { Trans as Translate } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function CropNoteModal({ show, onHide, onSubmit }) {
  return (
    <Modal className='__modal' show={show} onHide={onHide} size='sm'>
      <Modal.Header>
        <Translate>Loop Duration Change</Translate>
      </Modal.Header>
      <Modal.Body>
        <Translate i18nKey='crop-note-body' />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}><Translate>Cancel</Translate></Button>
        <Button onClick={onSubmit}><Translate>Continue</Translate></Button>
      </Modal.Footer>
    </Modal>
  );
}

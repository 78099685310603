import * as React from "react";

const InstrumentOcarina = (props) => (
  <svg
    id="InstrumentOcarina_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentOcarina_svg__st2{fill:#fdcc75}.InstrumentOcarina_svg__st3{fill:#4e4092}.InstrumentOcarina_svg__st4{fill:#f18860}"
      }
    </style>
    
    <path
      className="InstrumentOcarina_svg__st4"
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
    />
    <path
      d="M48.22 43.25c13.03-2.58 26.74-1.49 39.57 2.54 1.92.6 3.82 1.27 5.7 2 1.27.49 1.71 2.2.84 3.28C83.54 64.47 69.13 74.11 52.92 78c-4.38 1.05-8.86 1.71-13.35 2.04-7.16.52-19.96-.14-22.39-9.66-1.51-5.92 2.34-12.17 6.32-15.73 6.88-6.15 15.95-9.66 24.72-11.4z"
      style={{
        fill: "#4e4092",
        stroke: "#4e4092",
        strokeWidth: 0.5696,
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentOcarina_svg__st2"
      d="M30.26 49.4c2.29-.35 11.77-2 18.13-5.86.18-.11.16-.37-.03-.45-1.18-.5-4.28-2.09-5.6-5.38-.25-.64-.95-.97-1.61-.77l-9.09 2.76c-.67.2-1.07.89-.91 1.58.46 1.94 1.01 5.76-1.04 7.86-.09.11 0 .29.15.26z"
    />
    <path
      className="InstrumentOcarina_svg__st3"
      d="M41.79 36.9s-5.23-16.15-10.01-14.76c-4.78 1.39-.31 17.94-.31 17.94l10.32-3.18z"
    />
    <circle
      className="InstrumentOcarina_svg__st2"
      cx={29.57}
      cy={67.89}
      r={2.74}
    />
    <circle
      className="InstrumentOcarina_svg__st2"
      cx={34.2}
      cy={57.54}
      r={2.74}
    />
    <circle
      className="InstrumentOcarina_svg__st2"
      cx={45.09}
      cy={61.88}
      r={2.74}
    />
    <circle
      className="InstrumentOcarina_svg__st2"
      cx={61.55}
      cy={48.73}
      r={2.74}
    />
    <circle
      className="InstrumentOcarina_svg__st2"
      cx={73.9}
      cy={52.5}
      r={2.74}
    />
    <circle
      className="InstrumentOcarina_svg__st2"
      cx={86.01}
      cy={51.47}
      r={2.74}
    />
    <circle
      className="InstrumentOcarina_svg__st2"
      cx={40.18}
      cy={71.66}
      r={2.74}
    />
    <path
      style={{
        fill: "#ca5672",
      }}
      d="m38.84 39.89-1.91-.41-1.3 1.46.6 1.85 1.91.41 1.3-1.45z"
    />
    <path
      className="InstrumentOcarina_svg__st4"
      d="m41.36 42.14-1.91-.4-1.31 1.45.6 1.86 1.91.4L41.96 44z"
    />
    <path
      className="InstrumentOcarina_svg__st3"
      d="m38.14 43.2-1.91-.41-1.3 1.45.6 1.86 1.91.4 1.31-1.45z"
    />
  </svg>
);

export default InstrumentOcarina;

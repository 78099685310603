export default function StrofeCoin(props) {
  return (
    <svg width={25} height={25} fill="none" {...props} viewBox="0 0 196.1 194.4">
    <style>
      {".__strofe-coin-brown{fill:#705A34;}.__strofe-coin-yellow{fill:#FDCB75;}.__strofe-coin-dark-yellow{fill:#A9874E;}.__strofe-coin-light-yellow{fill:#FEE8C2;}"}
    </style>
    <path className="__strofe-coin-brown" d="M168.2,193c11.1,0,28-2,28-18.2V18.2c0-6.3-10-17.2-15-16.2c5,3,11.4,8.6,12,13.2v156.5
      c0,11.1-9.1,20.2-20.2,20.2"/>
    <path className="__strofe-coin-yellow" d="M173.9,193.9c11,0,19.9-9,19.9-19.9V20c0-11-9-19.9-19.9-19.9h-154C8.9,0,0,9,0,20v154c0,11,9,19.9,19.9,19.9
       M181.2,164c0,9.5-7.8,17.3-17.3,17.3h-134c-9.5,0-17.3-7.8-17.3-17.3V30c0-9.5,7.8-17.3,17.3-17.3h134c9.5,0,17.3,7.8,17.3,17.3
      V164z"/>
    <path className="__strofe-coin-dark-yellow" d="M163.9,12.7h-134c-9.5,0-17.3,7.8-17.3,17.3v134c0,9.5,7.8,17.3,17.3,17.3h134c9.5,0,17.3-7.8,17.3-17.3V30
      C181.2,20.5,173.4,12.7,163.9,12.7z"/>
    <path className="__strofe-coin-light-yellow" d="M3.9,19.1c0-0.2,0-0.3,0-0.5c0.3-3.7,3.4-7.8,6-10.2C12.4,5.9,15.6,4,19,3.1c1.8-0.5,6.7-1.8,8,0.3
      c0.8,1.2-0.5,2.2-1.5,2.7c-1.5,0.8-3.1,1.3-4.6,2.2c-1.6,1-3.1,2.1-4.5,3.3c-2.4,2-4.1,4.5-6.1,6.9c-1,1.1-2.3,2.6-3.9,2.8
      C5,21.5,4,20.3,3.9,19.1z"/>
    <path className="__strofe-coin-brown" d="M15.5,167.5V33.3c0-9.5,7.8-17.3,17.3-17.3H167c4,0,7.7,1.4,10.6,3.7c-3.2-4.1-8.1-6.7-13.7-7.7H29.8
      c-9.5,1-17.3,8.8-17.3,18.3v134.2c0,5.5,1.5,8.8,5.5,12C15.7,173.6,15.5,171.5,15.5,167.5z"/>
    <g>
      <g>
        <path className="__strofe-coin-brown" d="M92,151.2c-3.5-2.9-5.5-7.2-5.1-11.9c0.4-5.2,4.8-9.7,10.2-8.9c3.3,0.5,6,2.9,6.5,6.2
          c0.5,3.6-1.5,7.4,0.1,10.9c2.9,6.2,11.4,4.6,15.9,1.4c5.9-4.1,6.6-12.2,2.5-18c-5.2-7.2-12.5-12-19.4-17.2
          c-1.5-1.1-2.9-2.1-4.4-3.2c-2.5-1.9-4.2-1.2-6.1,1.1c-11.3,13.6-22.8,27.1-34.2,40.8c-2,2.4-4,2.7-6.2,0.9c-2.5-1.9-1-3.8,0.5-5.5
          c11-13.1,22.1-26.2,33.1-39.3c3.4-4.1,3.5-4.1-0.7-7.5c-7.4-6-14.5-12.3-19.4-20.7c-8-13.8-5.5-28.1,7.1-38
          c11.2-8.8,27-8.4,37.7-0.3c5,3.8,7.4,11.6,3.1,16.8c-2.9,3.6-8.8,4.9-12.4,1.6c-4.3-4-3-11.1-7.2-15.1c-4.5-4.2-12.6-1.8-15.2,3.3
          c-2,3.9-1.1,7.9,1.3,11.7c4.3,7,11.2,11.2,17.7,15.8c1.9,1.3,4,2.3,5.7,3.8c2.5,2.3,4.2,1.5,6.1-0.7
          c10.7-12.6,21.4-25.2,32.3-37.7c1.2-1.3,3.5-2.7,5-2.4c3.4,0.6,3.8,3.8,1.6,6.5c-9.8,11.6-19.6,23.3-29.4,34.9
          c-5.7,6.8-5.6,6.7,1.1,12.7c6.7,5.9,12.9,12.3,17.1,20.4c11,21.1-4.7,46.8-29.2,43.8c-5-0.6-9.8-2.3-14-5
          C93,152,92.5,151.6,92,151.2z"/>
        <path className="__strofe-coin-yellow" d="M89.7,149c-3.5-2.9-5.5-7.2-5.1-11.9c0.4-5.2,4.8-9.7,10.2-8.9c3.3,0.5,6,2.9,6.5,6.2
          c0.5,3.6-1.5,7.4,0.1,10.9c2.9,6.2,11.4,4.6,15.9,1.4c5.9-4.1,6.6-12.2,2.5-18c-5.2-7.2-12.5-12-19.4-17.2
          c-1.5-1.1-2.9-2.1-4.4-3.2c-2.5-1.9-4.2-1.2-6.1,1.1c-11.3,13.6-22.8,27.1-34.2,40.8c-2,2.4-4,2.7-6.2,0.9c-2.5-1.9-1-3.8,0.5-5.5
          c11-13.1,22.1-26.2,33.1-39.3c3.4-4.1,3.5-4.1-0.7-7.5C75.1,92.8,68,86.6,63,78.1c-8-13.8-5.5-28.1,7.1-38
          c11.2-8.8,27-8.4,37.7-0.3c5,3.8,7.4,11.6,3.1,16.8c-2.9,3.6-8.8,4.9-12.4,1.6c-4.3-4-3-11.1-7.2-15.1c-4.5-4.2-12.6-1.8-15.2,3.3
          c-2,3.9-1.1,7.9,1.3,11.7c4.3,7,11.2,11.2,17.7,15.8c1.9,1.3,4,2.3,5.7,3.8c2.5,2.3,4.2,1.5,6.1-0.7
          c10.7-12.6,21.4-25.2,32.3-37.7c1.2-1.3,3.5-2.7,5-2.4c3.4,0.6,3.8,3.8,1.6,6.5c-9.8,11.6-19.6,23.3-29.4,34.9
          c-5.7,6.8-5.6,6.7,1.1,12.7c6.7,5.9,12.9,12.3,17.1,20.4c11,21.1-4.7,46.8-29.2,43.8c-5-0.6-9.8-2.3-14-5
          C90.8,149.8,90.2,149.4,89.7,149z"/>
        <path className="__strofe-coin-light-yellow" d="M68.6,47c0-0.1,0-0.2,0-0.3c0.2-2.1,1.9-4.5,3.4-5.9c1.5-1.4,3.3-2.5,5.3-3c1.1-0.3,3.9-1,4.6,0.2
          c0.5,0.7-0.3,1.2-0.9,1.5c-0.9,0.5-1.8,0.8-2.6,1.3c-0.9,0.6-1.8,1.2-2.6,1.9c-1.4,1.1-2.4,2.6-3.5,4c-0.6,0.7-1.3,1.5-2.2,1.6
          C69.2,48.4,68.7,47.7,68.6,47z"/>
      </g>
      <g>
        <path className="__strofe-coin-brown" d="M60.4,95.6c2.1,2.5,3.4,5.7,3.4,9.2c0,7.9-6.4,14.4-14.4,14.4c-3.5,0-6.7-1.3-9.2-3.4c2.6,3.2,6.6,5.2,11,5.2
          c7.9,0,14.4-6.4,14.4-14.4C65.5,102.2,63.5,98.2,60.4,95.6z"/>
        <circle className="__strofe-coin-yellow" cx="49.3" cy="104.8" r="14.4"/>
        <path className="__strofe-coin-light-yellow" d="M37.4,100.7c0-0.1,0-0.2,0-0.2c0.1-1.7,1.5-3.5,2.7-4.6c1.1-1.1,2.6-1.9,4.1-2.3c0.8-0.2,3-0.8,3.6,0.1
          c0.4,0.6-0.2,1-0.7,1.2c-0.7,0.4-1.4,0.6-2.1,1c-0.7,0.4-1.4,1-2,1.5c-1.1,0.9-1.9,2-2.7,3.1c-0.4,0.5-1,1.1-1.7,1.2
          C37.9,101.8,37.4,101.2,37.4,100.7z"/>
      </g>
      <g>
        <path className="__strofe-coin-brown" d="M162,69.7c2.1,2.5,3.3,5.7,3.3,9.2c0,7.9-6.4,14.3-14.3,14.3c-3.5,0-6.7-1.3-9.2-3.3c2.6,3.1,6.6,5.1,11,5.1
          c7.9,0,14.3-6.4,14.3-14.3C167.2,76.3,165.2,72.3,162,69.7z"/>
        <circle className="__strofe-coin-yellow" cx="151" cy="78.9" r="14.3"/>
        <path className="__strofe-coin-light-yellow" d="M139.1,74.7c0-0.1,0-0.1,0-0.2c0.1-1.7,1.5-3.5,2.7-4.6c1.1-1.1,2.6-1.9,4.1-2.3c0.8-0.2,3-0.8,3.6,0.1
          c0.4,0.6-0.2,1-0.7,1.2c-0.7,0.4-1.4,0.6-2,1c-0.7,0.4-1.4,0.9-2,1.5c-1.1,0.9-1.8,2-2.7,3.1c-0.4,0.5-1,1.1-1.7,1.2
          C139.6,75.8,139.2,75.3,139.1,74.7z"/>
      </g>
      <path className="__strofe-coin-light-yellow" d="M86.4,135.3c0-0.1,0-0.1,0-0.2c0.1-1.3,1.2-2.8,2.2-3.7c0.9-0.9,2.1-1.6,3.3-1.9c0.7-0.2,2.4-0.6,2.9,0.1
        c0.3,0.4-0.2,0.8-0.5,1c-0.5,0.3-1.1,0.5-1.7,0.8c-0.6,0.3-1.1,0.8-1.6,1.2c-0.9,0.7-1.5,1.6-2.2,2.5c-0.3,0.4-0.8,0.9-1.4,1
        C86.8,136.2,86.4,135.8,86.4,135.3z"/>
    </g>
    </svg>


  );
}

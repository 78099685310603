import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { usersSelectors } from "../store/usersSlice";

export default function RootRoute() {

  const currentUser = useSelector(usersSelectors.getCurrentUser);
  const hasCreatedSongs = currentUser?.created_song_count > 0;

  // When a user has not created a track yet, redirect to create on root route.
  // Otherwise go to library
  return (hasCreatedSongs ? <Redirect to='/library' /> : <Redirect to='/create' />);
}

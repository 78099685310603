import * as React from "react";

const InstrumentSitar = (props) => (
  <svg
    id="InstrumentSitar_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.1"
    style={{
      enableBackground: "new 0 0 110.84 110.1",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentSitar_svg__st3{fill:#4e4092}.InstrumentSitar_svg__st5{fill:#cfd125}.InstrumentSitar_svg__st72,.InstrumentSitar_svg__st73,.InstrumentSitar_svg__st75,.InstrumentSitar_svg__st76{fill:#cfd125;stroke:#cfd125;stroke-width:.3566;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.InstrumentSitar_svg__st73,.InstrumentSitar_svg__st75,.InstrumentSitar_svg__st76{stroke-width:.4085}.InstrumentSitar_svg__st75,.InstrumentSitar_svg__st76{stroke-width:.3407}.InstrumentSitar_svg__st76{stroke-width:.2975}"
      }
    </style>
    
    <path
      className="InstrumentSitar_svg__st3"
      d="M93.73 110.1H16.76C7.61 110.1.2 102.69.2 93.54V16.56C.2 7.41 7.61 0 16.76 0h76.98c9.15 0 16.56 7.41 16.56 16.56v76.98c0 9.15-7.42 16.56-16.57 16.56z"
    />
    <path
      d="M97.5 16.63 43.18 64.39c-1.26 1.23-6.4 5.37-7.43 7.9-2.96 7.29 2.11 15.19-5.84 20.83-5.2 3.85-11.24 4.68-18.12-5.12 0 0 11.2 17.87 23.61 11.37 0 0 7.26-3.47 6.14-12.07-.35-2.67-1.03-5.18-1.9-7.72-.67-1.98-1.1-4.65.1-6.51 1.01-1.58 2.55-2.93 3.97-4.12l54.58-49.47c0-.01-.26-1.97-.79-2.85z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      d="M29.91 93.12c7.95-5.64 2.88-13.54 5.84-20.83 1.03-2.53 6.16-6.67 7.43-7.9L97.5 16.63s-1.26-3.8-5.06-3.44L33.91 64.14c-.66.57-1.5 1.01-2.26 1.42-1.82.99-3.78 1.76-5.81 2.19-2.57.55-5.2.13-7.76.67-1.77.37-3.45 1.1-4.92 2.16-.71.51-1.31 1.13-1.96 1.71-.67.6-6.3 5.92.58 15.72 6.89 9.79 12.93 8.96 18.13 5.11z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      className="InstrumentSitar_svg__st3"
      d="m25.47 81.78-2.48 2.17c-.37.32-.93.29-1.26-.08l-4.87-5.56a.898.898 0 0 1 .08-1.26l2.48-2.17c.37-.32.93-.29 1.26.08l4.87 5.56c.32.37.29.93-.08 1.26zM28.57 76.96l-1.27 1.11c-.29.25-.72.22-.97-.06l-2.94-3.36a.692.692 0 0 1 .06-.97l1.27-1.11c.29-.25.72-.22.97.06l2.94 3.36c.25.28.22.72-.06.97z"
    />
    <circle
      className="InstrumentSitar_svg__st5"
      cx={51.11}
      cy={67.46}
      r={1.21}
    />
    <circle
      className="InstrumentSitar_svg__st5"
      cx={56.63}
      cy={62.33}
      r={1.21}
    />
    <circle
      className="InstrumentSitar_svg__st5"
      cx={63.57}
      cy={56.05}
      r={1.21}
    />
    <circle
      className="InstrumentSitar_svg__st5"
      cx={70.39}
      cy={49.91}
      r={1.21}
    />
    <circle
      className="InstrumentSitar_svg__st5"
      cx={76.78}
      cy={43.92}
      r={1.21}
    />
    <path
      className="InstrumentSitar_svg__st72"
      d="M93.54 15.6s-5.99 3.43-4.65 4.65c1.35 1.21 4.65-4.65 4.65-4.65zM94.16 15.68s-1.91 3.67-.94 3.6.94-3.6.94-3.6zM93.37 14.99s-4.28.8-4.2 1.65c.07.85 4.2-1.65 4.2-1.65z"
    />
    <circle
      className="InstrumentSitar_svg__st73"
      cx={25.5}
      cy={88.41}
      r={0.4}
    />
    <circle
      className="InstrumentSitar_svg__st73"
      cx={27.55}
      cy={93.11}
      r={0.4}
    />
    <path
      className="InstrumentSitar_svg__st72"
      d="M32.65 89.67s-3.4-2.09-4.14-1.22c-.73.86 1.54 1.49 4.14 1.22zM32.82 89.6s-1.41-3.73-2.53-3.49c-1.11.23.3 2.11 2.53 3.49z"
    />
    <path
      d="M29.79 87.76s-1.32-1.7-1.86-1.4.49 1.05 1.86 1.4z"
      style={{
        fill: "#cfd125",
        stroke: "#cfd125",
        strokeWidth: 0.1926,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentSitar_svg__st5"
      d="M23.82 88.56c1.05-1.26-.46-2.72-1.82-2.14-1.6.68-1.67 2.88-.38 3.87 1.43 1.1 3.6 1.14 5.33 1.07 1.97-.08 3.91-.5 5.72-1.26.54-.22.3-1.11-.24-.89-2.31.95-4.8 1.38-7.3 1.19-1.06-.08-2.71-.24-3.34-1.26-.33-.54-.18-1.31.3-1.72.2-.18.48-.29.75-.22.29.07.57.41.33.69-.38.47.27 1.13.65.67z"
    />
    <path
      className="InstrumentSitar_svg__st5"
      d="M29.24 90.23c-1.06.16-2.08.4-3.12.64-.75.17-1.56.35-2.11.94-.64.69-.77 1.78.05 2.39.8.6 1.86.17 2.19-.72.2-.53-.65-.75-.84-.23-.19.5-.92.45-1.05-.06-.15-.56.48-1.02.92-1.19.55-.22 1.16-.31 1.74-.45.81-.2 1.63-.35 2.46-.48.23-.03.36-.33.3-.54-.07-.24-.31-.33-.54-.3z"
    />
    <circle
      className="InstrumentSitar_svg__st75"
      cx={14.39}
      cy={76.55}
      r={0.33}
    />
    <circle
      className="InstrumentSitar_svg__st75"
      cx={10.7}
      cy={74.37}
      r={0.33}
    />
    <path
      className="InstrumentSitar_svg__st76"
      d="M14.07 70.5s1.38 3.02.59 3.55c-.79.52-1.08-1.43-.59-3.55zM14.15 70.36s2.94 1.55 2.64 2.45c-.32.9-1.73-.46-2.64-2.45z"
    />
    <path
      d="M15.36 73.06s1.27 1.27.97 1.68c-.3.42-.82-.51-.97-1.68z"
      style={{
        fill: "#cfd125",
        stroke: "#cfd125",
        strokeWidth: 0.1607,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentSitar_svg__st5"
      d="M14.52 78.55c.24-.15.48-.04.56.22.08.26-.04.52-.23.7-.38.36-1.07.4-1.47.06-.76-.63-.73-1.96-.69-2.84.09-2.08.69-4.11 1.71-5.91.24-.43-.42-.82-.66-.39-.79 1.4-1.35 2.93-1.62 4.52-.24 1.44-.44 3.19.25 4.54.62 1.21 2.35 1.51 3.21.36.35-.47.42-1.15.06-1.64-.34-.48-1.01-.59-1.5-.28-.42.27-.04.93.38.66z"
    />
    <path
      className="InstrumentSitar_svg__st5"
      d="M12.57 73.03c-.18.67-.39 1.32-.64 1.97-.17.46-.31.97-.56 1.4-.19.34-.66.82-1.1.6-.42-.21-.29-.78.13-.87.45-.11.26-.81-.19-.7-.55.13-.98.67-.93 1.24.06.61.57 1.1 1.19 1.1.6 0 1.12-.39 1.43-.86.29-.43.43-.94.61-1.42.28-.74.54-1.49.75-2.26.13-.46-.57-.65-.69-.2z"
    />
    <circle
      className="InstrumentSitar_svg__st72"
      cx={94.11}
      cy={14.93}
      r={0.3}
    />
  </svg>
);

export default InstrumentSitar;

import * as React from "react";

const InstrumentStringEnsembleMarcato = (props) => (
  <svg
    id="InstrumentStringEnsembleMarcato_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentStringEnsembleMarcato_svg__st2{fill:#fdcc75}.InstrumentStringEnsembleMarcato_svg__st4{fill:#f18860}.InstrumentStringEnsembleMarcato_svg__st5{fill:#cfd125}.InstrumentStringEnsembleMarcato_svg__st6{fill:#ca5672}.InstrumentStringEnsembleMarcato_svg__st82,.InstrumentStringEnsembleMarcato_svg__st83,.InstrumentStringEnsembleMarcato_svg__st84{fill:none;stroke:#fff;stroke-width:1.9108;stroke-linecap:round;stroke-miterlimit:10}.InstrumentStringEnsembleMarcato_svg__st83,.InstrumentStringEnsembleMarcato_svg__st84{stroke-width:1.7049}.InstrumentStringEnsembleMarcato_svg__st84{stroke-width:1.3237}"
      }
    </style>
    
    <path
      className="InstrumentStringEnsembleMarcato_svg__st4"
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
    />
    <path
      className="InstrumentStringEnsembleMarcato_svg__st6"
      d="M75.84 82.25s-7.52-4.12-1.73-12.74c5.79-8.62 10.22-4.04 10.22-4.04l3.46-3.78c5.97-6.52 5.13-16.74-1.83-22.18l-3.81-2.98-3.81-2.98c-6.96-5.45-17.09-3.81-21.98 3.55l-2.84 4.27s5.52 3.2-1.46 10.89-12.79 1.38-12.79 1.38-23.47 17.22 1.01 36.38 35.56-7.77 35.56-7.77z"
    />
    <path
      className="InstrumentStringEnsembleMarcato_svg__st2"
      d="M50.46 69.69 38.5 88.63l1.78 1.39 1.77 1.38 15.5-16.16-3.54-2.77zM73.4 56.92l26.62-39.6-1.77-1.39-1.77-1.39L64.43 49.9l4.49 3.51z"
    />
    <circle
      className="InstrumentStringEnsembleMarcato_svg__st6"
      cx={98.25}
      cy={15.93}
      r={6.25}
    />
    <path
      className="InstrumentStringEnsembleMarcato_svg__st82"
      d="m88.11 19.64 8.97 7.02M90.73 21.82 45.27 79.91M94.03 24.27 48.57 82.36"
    />
    <path
      className="InstrumentStringEnsembleMarcato_svg__st5"
      d="M55.7 59.37c.02-.06.02-.13 0-.22-.02-.06-.04-.12-.07-.18-.02-.04-.02-.08-.04-.11-.03-.05-.08-.08-.12-.12-.01-.01-.02-.03-.03-.04-.01-.01-.03-.02-.04-.03-.02-.01-.03-.03-.06-.05-2.02-1.87-4.34-.25-6.04 1.19-.72.61-1.51 1.23-2.34 1.69-.37.2-.76.37-1.14.54-.09.04-.52.15-.56.25-.97 2.02-2 4.01-3.91 5.29-1.28.85-3.23 1.69-4.53.43.12-.05.24-.11.33-.21a.807.807 0 1 0-1.21-1.07c-.05.06-.08.13-.12.18-.01.02-.01.04-.02.06-.01.02-.02.05-.03.07-.51 1.21.62 2.16 1.86 2.4 1.6.31 3.11-.76 4.41-1.5 4.64-2.66 5.57-2.93 5.57-2.93-.04-.01.4-1.07.45-1.15a14.28 14.28 0 0 1 2.84-3.83c.58-.56 1.27-1.29 2.07-1.52.51-.15.96-.04 1.42.2-.1-.05-.26.48-.27.54-.02.19.03.38.14.54.25.35.75.44 1.1.2.21-.15.32-.38.34-.62zM66.84 69.16c.06-.02.12-.03.21-.03.06.01.13.03.19.05.04.01.08.01.12.03.05.03.09.07.13.1.01.01.03.02.04.03l.04.04c.02.02.04.03.05.05 2.11 1.76.8 4.27-.4 6.14-.51.8-1.03 1.65-1.37 2.54-.15.39-.27.8-.39 1.2-.03.09-.09.53-.17.59-1.88 1.22-3.72 2.5-4.74 4.56-.68 1.37-1.26 3.42.15 4.55.03-.13.08-.25.17-.35.3-.34.81-.37 1.14-.07.34.3.37.81.07 1.14-.05.06-.11.09-.17.14-.02.01-.04.02-.05.03-.02.01-.05.03-.07.04-1.13.66-2.23-.34-2.62-1.54-.51-1.55.36-3.18.92-4.57 2.04-4.94 2.2-5.89 2.2-5.9.01.04 1.01-.54 1.09-.59.5-.34.98-.72 1.44-1.12.74-.66 1.41-1.39 2-2.18.48-.65 1.12-1.42 1.24-2.25.08-.52-.08-.96-.38-1.39.07.1-.44.32-.5.34-.19.04-.38.02-.55-.07a.812.812 0 0 1-.34-1.07c.1-.25.31-.39.55-.44z"
    />
    <path
      d="M62.08 80.03s-7.64-.3-6.45-9.49 6.56-7.29 6.56-7.29l1.26-4.4c2.18-7.58-2.56-15.41-10.29-16.99L48.92 41l-4.23-.87c-7.73-1.58-15.16 3.75-16.14 11.58l-.57 4.54s5.68.36 3.16 9.28-9.66 6.19-9.66 6.19-11.88 23.1 15.28 28.66c27.17 5.56 25.32-20.35 25.32-20.35z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      className="InstrumentStringEnsembleMarcato_svg__st2"
      d="M36.82 80.1 34.8 99.98l1.96.4 1.97.4 5.96-19.07-3.94-.81zM50.06 60.77l5.51-42.21-1.97-.41-1.97-.4L40.1 58.73l4.98 1.02z"
    />
    <circle
      className="InstrumentStringEnsembleMarcato_svg__st6"
      cx={53.6}
      cy={18.15}
      r={5.58}
    />
    <path
      className="InstrumentStringEnsembleMarcato_svg__st83"
      d="m46.98 25.15 9.96 2.04M49.93 25.85l-13.2 64.47M53.55 26.5l-13.2 64.47"
    />
    <path
      className="InstrumentStringEnsembleMarcato_svg__st4"
      d="M36.89 69.77a.354.354 0 0 0-.09-.17c-.04-.04-.08-.08-.13-.12-.03-.02-.05-.05-.08-.07-.04-.03-.09-.03-.14-.05-.01 0-.03-.01-.04-.02-.02-.01-.03-.01-.05-.01-.02 0-.04-.01-.06-.02-2.35-.69-3.56 1.53-4.35 3.35-.33.78-.71 1.58-1.2 2.28-.21.31-.46.6-.7.89-.06.07-.35.33-.35.42.03 2 0 4-1.02 5.78-.68 1.19-1.91 2.63-3.44 2.15.08-.08.14-.18.18-.3a.72.72 0 0 0-1.39-.37c-.02.07-.01.13-.02.19 0 .02.01.04.01.05 0 .02 0 .05.01.07.07 1.17 1.36 1.48 2.44 1.18 1.4-.39 2.18-1.84 2.93-2.95 2.65-3.96 3.28-4.55 3.28-4.56-.04.01-.1-1.02-.1-1.1 0-.54.04-1.08.12-1.62.12-.87.33-1.74.63-2.57.24-.68.5-1.54 1.05-2.04.35-.32.75-.42 1.22-.41-.1 0-.02.49 0 .54.06.16.17.29.33.37.34.18.77.05.96-.28.08-.18.08-.41 0-.61zM49.69 73.16c.04-.04.09-.07.16-.11.05-.02.11-.03.17-.04.04-.01.07-.02.1-.02.05 0 .1.02.14.03h.04c.02 0 .03.01.04.01.02.01.04.01.06.02 2.39.57 2.34 3.09 2.12 5.06-.09.84-.17 1.73-.09 2.57.03.37.1.75.16 1.12.01.09.14.46.1.54-1.02 1.72-1.98 3.47-1.98 5.53 0 1.37.36 3.24 1.93 3.57a.596.596 0 0 1 0-.35c.1-.39.5-.62.88-.51.39.1.62.5.51.88-.02.07-.05.12-.08.18-.01.02-.02.03-.03.04-.01.02-.03.04-.04.06-.64.98-1.91.61-2.7-.19-1.02-1.04-.98-2.68-1.08-4.01-.33-4.75-.59-5.58-.59-5.58.03.03.59-.83.63-.9.26-.47.5-.96.7-1.47.33-.82.57-1.67.73-2.54.13-.71.33-1.58.1-2.29-.15-.45-.45-.73-.86-.96.09.05-.22.43-.26.47-.13.11-.3.17-.47.16-.38-.01-.69-.34-.7-.72.02-.22.14-.42.31-.55z"
    />
    <path
      d="M43.66 74.4s-5.7 1.68-7.1-5.38c-1.4-7.05 3.01-6.99 3.01-6.99l-.16-3.55c-.28-6.12-5.71-10.7-11.78-9.95l-3.33.41-3.33.41c-6.08.75-10.22 6.52-9 12.52l.71 3.48s4.27-1.14 4.63 6.04c.36 7.18-5.57 6.95-5.57 6.95S8.71 98.3 30.08 95.65 43.66 74.4 43.66 74.4z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentStringEnsembleMarcato_svg__st2"
      d="m25.09 80.71 3.45 15.13 1.54-.19 1.55-.19-.35-15.51-3.1.38zM30.04 63.21l-6.42-32.43-1.55.19-1.55.2 1.69 33.01 3.91-.49z"
    />
    <circle
      className="InstrumentStringEnsembleMarcato_svg__st6"
      cx={22.07}
      cy={30.97}
      r={4.33}
    />
    <path
      className="InstrumentStringEnsembleMarcato_svg__st84"
      d="m18.94 37.77 7.83-.97M21.28 37.55l6.28 50.71M24.1 37.13l6.28 50.7"
    />
    <path
      className="InstrumentStringEnsembleMarcato_svg__st5"
      d="M22.58 73.09a.285.285 0 0 0-.11-.11.502.502 0 0 0-.13-.05c-.03-.01-.05-.03-.08-.03-.04-.01-.08 0-.11 0H22.03c-1.9.08-2.24 2.01-2.37 3.54-.05.65-.13 1.34-.32 1.97-.08.28-.19.55-.29.83-.02.06-.18.33-.15.4.52 1.46.99 2.94.68 4.51-.21 1.04-.75 2.41-2 2.43.04-.08.06-.17.06-.26a.56.56 0 1 0-1.12.08c0 .05.02.1.03.15 0 .01.01.02.02.04s.01.03.02.05c.34.84 1.37.75 2.09.26.93-.64 1.14-1.9 1.42-2.9.96-3.57 1.28-4.16 1.28-4.17-.02.02-.33-.72-.35-.78-.13-.4-.24-.81-.32-1.22-.13-.67-.19-1.36-.18-2.04.01-.56-.01-1.25.27-1.76.18-.32.45-.49.79-.6-.08.02.1.36.13.39.08.1.2.17.33.2.29.05.58-.15.64-.44.06-.19 0-.36-.11-.49zM32.84 72.42c.02-.04.04-.08.09-.12.03-.03.07-.05.12-.07.02-.01.04-.03.07-.04.04-.01.08-.01.11-.02.01 0 .02-.01.03-.01h.09c1.9-.17 2.49 1.7 2.81 3.2.14.64.31 1.31.57 1.91.12.26.26.52.4.78.03.06.22.3.21.37-.32 1.52-.59 3.05-.08 4.56.34 1.01 1.06 2.29 2.3 2.15a.577.577 0 0 1-.09-.26.56.56 0 1 1 1.12-.08c0 .05-.01.1-.01.15 0 .01-.01.03-.01.04-.01.02-.01.03-.02.05-.23.88-1.26.93-2.03.53-1.01-.51-1.38-1.73-1.79-2.68-1.42-3.42-1.82-3.96-1.82-3.96.03.01.23-.76.24-.82.08-.41.13-.83.15-1.25.04-.68.01-1.37-.09-2.05-.08-.55-.15-1.24-.49-1.71-.22-.3-.51-.43-.87-.49.08.01-.06.37-.08.41-.07.11-.18.2-.3.24-.28.09-.59-.08-.69-.36-.07-.16-.03-.33.06-.47z"
    />
  </svg>
);

export default InstrumentStringEnsembleMarcato;

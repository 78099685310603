import { useLayoutEffect, useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions, usersSelectors } from '../../store/usersSlice';
import { strofeApi } from '../../api/strofeApi';
import { Trans as Translate } from 'react-i18next';
import useEventCallback from '../../hooks/useEventCallback';
import { EventTracker } from '../../utils/Tracking';
import { downloadZip } from 'client-zip';

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { TRACK_PRICES } from '../../utils/Prices';
import LoadingOverlay from '../LoadingOverlay';
import InfiniteLoader from '../../layout/InfiniteLoader/InfiniteLoader';
import Checkbox from '../../layout/Checkbox';

import StrofeCoin from '../../icons/coins/StrofeCoin';
import MoodSpookyIcon from '../../icons/MoodSpookyIcon';

import './DownloadTrackFlow.scss';

let zipUrl = null;

export default function DownloadTrackFlow({ show, song, onPurchase, onHide, purchaseFlow = false, initialAddMidi = false, initialGetMidiOnly = false }) {

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [addMidi, setAddMidi] = useState(false);
  const [getMidiOnly, setGetMidiOnly] = useState(false);
  const [purchased, setPurchased] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [generatingFormat, setGeneratingFormat] = useState(false);

  const dispatch = useDispatch();
  const currentUser = useSelector(usersSelectors.getCurrentUser);
  const subscription = currentUser?.subscription;

  const isSubscribedAndCanDownload = currentUser?.subscribed && currentUser.remaining_downloads > 0;

  useEffect(() => {
    if (show) {
      setShowConfirmation(purchaseFlow);
      setAddMidi(initialAddMidi);
      setPurchased(false);
      setGetMidiOnly(initialGetMidiOnly);
      setProcessing(false);
      EventTracker.openDownloadModal();
    }
    else {
      zipUrl && URL.revokeObjectURL(zipUrl);
    }
  }, [show, purchaseFlow, initialAddMidi, initialGetMidiOnly]);

  const autoPurchaseUser = useEventCallback(() => {
    if (!song.purchased && (currentUser?.ambassador || isSubscribedAndCanDownload)) {
      purchaseSong(true);
    }
  });

  const purchaseSong = async (midiPurchase) => {
    setProcessing(true);

    const midi_purchased = (addMidi || getMidiOnly || midiPurchase || currentUser.subscribed) || undefined;
    const wav_purchased = subscription?.product?.user_can_download_wav || undefined;
    const instruments_purchased = subscription?.product?.user_can_download_instruments || undefined;

    await strofeApi.put(`/songs/${song.id}`, { song: { purchased: true, midi_purchased, wav_purchased, instruments_purchased } });
    await dispatch(usersActions.fetchById({ id: currentUser.id }));

    let format = undefined;

    if (midi_purchased) {
      format = 'midi';
    }

    else if (wav_purchased) {
      format = 'wav';
    }

    else if (instruments_purchased) {
      format = 'tracks';
    }

    setShowConfirmation(false);
    setGetMidiOnly(false);
    setProcessing(false);
    
    onPurchase(song.id, format);
  }

  // For users with auto purchase (ambassadors or subscribers with remaing downloads):
  useLayoutEffect(() => {
    if (show) {
      autoPurchaseUser();
    }
  }, [show, autoPurchaseUser]);
  
  if (!currentUser) {
    return null;
  }
  
  const price = getMidiOnly ? TRACK_PRICES.midi : (addMidi ? TRACK_PRICES.mp3_midi : TRACK_PRICES.mp3);
  const finalBalance = currentUser.coins - price;

  const showPurchaseModal = show && !currentUser.ambassador && !currentUser.subscribed && (!song.purchased || getMidiOnly) && !showConfirmation && process.env.REACT_APP_HIDE_PAYWALL !== 'true';
  const showDownloadModal = show && (currentUser.ambassador || isSubscribedAndCanDownload || ((song.purchased && !getMidiOnly) || process.env.REACT_APP_HIDE_PAYWALL === 'true'));
  const showNotEnoughDownloads = show && !song.purchased && currentUser.subscribed && currentUser.remaining_downloads === 0;

  const hasEnoughCoins = currentUser.coins >= price || currentUser.ambassador;

  const trackDetail = () => {
    if (getMidiOnly) {
      return <Translate>Track (MIDI)</Translate>
    } 
    else {
      return addMidi ? <Translate>Purchase Track (MP3 + MIDI)</Translate> : <Translate>Purchase Track (MP3)</Translate>;
    }
  }
  
  const generateTracksZip = async () => {
    const fetchSong = songUrl => {
      return fetch(songUrl);
    }

    const songUrls = song.instruments.map(instrument => instrument.mp3_download_url);

    const zipReady = async mp3s => {
      const blob = await downloadZip(mp3s).blob();

      const link = document.createElement("a");
      zipUrl = URL.createObjectURL(blob);
      link.href = zipUrl;
      link.download = `${song.title}.zip`;
      link.click();
      link.remove();
    }

    Promise.all(songUrls.map(url => fetchSong(url))).then(songMp3s => {
      zipReady(songMp3s);
    });
  }

  const generateSong = async song_format => {
    console.log('generate song:', song_format);

    setGeneratingFormat(true);
    const { data } = await strofeApi.post(`/songs/generate/${song.id}`, { song_format });
    console.log('data:', data);
    const link = document.createElement("a");

    setGeneratingFormat(false);
    link.href = data[song_format + '_download_url'];

    console.log('link.href:', link.href)

    link.click();
    link.remove();
  }

  const songFormat = getMidiOnly ? 'midi_only' : (addMidi ? 'mp3_midi' : 'mp3');

  return (
    <>
      <Modal className='__modal __download-track-flow' show={showPurchaseModal} onHide={onHide}>
        <Modal.Header>
          <Translate>Purchase Track</Translate>
        </Modal.Header>
        <Modal.Body>
          <p><Translate i18nKey={getMidiOnly ? 'get-midi-coins' : 'get-track-coins' }/></p>
          <p><Translate i18nKey={getMidiOnly ? 'get-midi-description' : 'get-track-description' }/></p>

          <div className='price-detail strong'>
            <div>{ trackDetail() }</div>
            <div className='amount'>
              <StrofeCoin />
              { price.toLocaleString() }
            </div>
          </div>
          
          { !getMidiOnly && (
            <div className='add-midi-container'>
              <div className='add-midi'>
                <Checkbox checked={addMidi} onChange={e => setAddMidi(e.target.checked)}>
                  <Translate>Add MIDI format</Translate>
                </Checkbox>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onHide}><Translate>Close</Translate></Button>
          <Button variant='primary' onClick={() => setShowConfirmation(true)}><Translate>Continue</Translate></Button>
        </Modal.Footer>
      </Modal>

      <Modal className='__modal __download-track-flow' show={showConfirmation && hasEnoughCoins} onHide={onHide}>
        <LoadingOverlay show={processing} phrase='Processing...' />

        <Modal.Header>
          <Translate>Purchase Track</Translate>
        </Modal.Header>
        <Modal.Body>
          <p><Translate i18nKey='confirm-purchase' /></p>
          <div className='price-detail'>
            <div><Translate>Current Balance</Translate></div>
            <div className='amount'>{ currentUser.coins.toLocaleString() }</div>
          </div>

          <div className='price-detail'>
            <div>{ trackDetail() }</div>
            <div className='amount'>&minus;{ price.toLocaleString() }</div>
          </div>

          <div className='price-detail final-balance'>
            <div><Translate>Final Balance</Translate></div>
            <div className='amount'><StrofeCoin /> { finalBalance.toLocaleString() }</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowConfirmation(false)}><Translate>Back</Translate></Button>
          <Button variant='primary' onClick={() => purchaseSong(false)}><Translate>Get Track</Translate></Button>
        </Modal.Footer>
      </Modal>

      <Modal className='__modal __download-track-flow' show={showConfirmation && !hasEnoughCoins} onHide={onHide}>
        <Modal.Header>
          <Translate>Download Track</Translate>
        </Modal.Header>
        <Modal.Body>
          <div className='oops-icon'>
            <MoodSpookyIcon />
          </div>
          <p><Translate i18nKey='not-enough-coins' /></p>
          <p><Translate i18nKey='you-need-x-coins' values={{ balance: currentUser.coins.toLocaleString(), missing: Math.abs(finalBalance).toLocaleString() }} /></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowConfirmation(false)}><Translate>Back</Translate></Button>
          <Button variant='primary' as={Link} to={`/get-coins?purchase_song=${song?.id}&format=${songFormat}`}><Translate>Get Strofe Coins</Translate></Button>
        </Modal.Footer>
      </Modal>

      <Modal className='__modal __download-track-flow' show={showNotEnoughDownloads} onHide={onHide}>
        <Modal.Header>
          <Translate>Download Track</Translate>
        </Modal.Header>
        <Modal.Body>
          <div className='oops-icon'>
            <MoodSpookyIcon />
          </div>
          <p><Translate i18nKey='not-enough-remaining-downloads' /></p>
        </Modal.Body>
        <Modal.Footer>
        <Button onClick={onHide}><Translate>Close</Translate></Button>
        </Modal.Footer>
      </Modal>

      <Modal className='__modal __download-track-flow' show={showConfirmation && !hasEnoughCoins} onHide={onHide}>
        <Modal.Header>
          <Translate>Download Track</Translate>
        </Modal.Header>
        <Modal.Body>
          <div className='oops-icon'>
            <MoodSpookyIcon />
          </div>
          <p><Translate i18nKey='not-enough-coins' /></p>
          <p><Translate i18nKey='you-need-x-coins' values={{ balance: currentUser.coins.toLocaleString(), missing: Math.abs(finalBalance).toLocaleString() }} /></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowConfirmation(false)}><Translate>Back</Translate></Button>
          <Button variant='primary' as={Link} to={`/get-coins?purchase_song=${song?.id}&format=${songFormat}`}><Translate>Get Strofe Coins</Translate></Button>
        </Modal.Footer>
      </Modal>

      <Modal className='__modal __download-track-flow' show={showDownloadModal} backdrop={!generatingFormat ? true : 'static'} onHide={onHide}>
        <Modal.Header closeButton={!generatingFormat}>
          <Modal.Title><Translate>Download Track</Translate></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          { purchased && (
            <p className='text-center'><Translate>Congrats on your new track!</Translate></p>
          )}
          { generatingFormat
          ? <>
              { !purchased && <p className='text-center'><strong><Translate>Hang on tight!</Translate></strong></p> }
              <p><Translate>Your song is being processed, this will take a few seconds.</Translate></p>
            </>
          : <>
              <p className='text-center'><strong><Translate>Your track is ready for download.</Translate></strong></p>
              { (!song?.midi_purchased && process.env.REACT_APP_HIDE_PAYWALL !== 'true') && (
                <div className='text-center'>
                  <Button variant='link' className='add-midi-btn' onClick={() => setGetMidiOnly(true)}><Translate>Add MIDI Format</Translate></Button>
                </div>
              )}
            </>
          }
        </Modal.Body>

        <Modal.Footer className='download-footer'>
          { generatingFormat && (
            <>
              <span style={{ color: 'gray', fontSize: '0.8rem' }}><Translate>Generating...</Translate></span>
              <div className='text-center' style={{ width: 28, height: 28 }}>
                <InfiniteLoader />
              </div>
            </>
          )}

          { !generatingFormat && (
            <div className='format'>
              <div className='select-format'><Translate>Select Format</Translate></div>
                { (subscription?.product?.user_can_download_instruments || song?.instruments_purchased) && <Button variant='secondary' onClick={generateTracksZip}><Translate>Tracks (ZIP)</Translate></Button> }
                { (subscription?.product?.user_can_download_wav || song?.wav_purchased) && <Button variant='secondary' onClick={() => generateSong('wav')}><Translate>WAV</Translate></Button> }
                { (currentUser.ambassador || song?.midi_purchased || subscription?.product?.user_can_download_midi) && <Button variant='secondary' onClick={() => generateSong('midi')}><Translate>MIDI</Translate></Button> }
                <Button variant='secondary' onClick={() => generateSong('mp3')}><Translate>MP3</Translate></Button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

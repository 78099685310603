import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import reportWebVitals from './reportWebVitals';
import rootReducer from './store/rootReducer';
import Tracking from './utils/Tracking';
import FacebookSSO from './utils/FacebookSSO';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import "./locale/init_locales";
import App from './App';
import { bugsnagErrorBoundary, bugsnagEnabled } from './utils/Bugsnag';

//* ---- INITIALIZERS ---- *//
// Initialize Redux Store:
const store = configureStore({ reducer: rootReducer });

// Start Google Track Manager:
Tracking.initializeGTM();

// Initialize Facebook SSO code:
FacebookSSO.initialize();

// Initialize and get error boundary code
const ErrorBoundary = bugsnagErrorBoundary();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      { bugsnagEnabled()
      ? <ErrorBoundary><App /></ErrorBoundary>
      : <App />
      }
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as React from "react"

export default function MoodSadIcon(props) {
  return (
    <svg viewBox="0 0 360 360" {...props}>
      <style>
        {
          ".__sad-icon-st1{fill:none;stroke:#fff;stroke-width:35;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.__sad-icon-st6{fill:#fff}"
        }
      </style>
      <path
        d="M292.8 359.5H67.2c-34.5 0-62.8-28.3-62.8-62.8V63.4C4.4 28.9 32.7.6 67.2.6h225.6c34.5 0 62.8 28.3 62.8 62.8v233.3c0 34.5-28.3 62.8-62.8 62.8z"
        fill="#f08660"
      />
      <path
        className="__sad-icon-st1"
        d="M160 112.4s-28.9 58.6-92.5 40.4M230.5 124.5s25 28 72 16M302.2 63.8l26.7 26.1M80.2 58.2L58.6 76.8l-21.9 19"
      />
      <path
        className="__sad-icon-st6"
        d="M55.5 266.8c-3 0-5.8-1.3-8-3.7-3.4-3.6-5.4-9.7-5-15.5.9-14 9.6-23.4 18.1-32.5 5.9-6.3 11.4-12.3 14.1-19.5l.1-.2c.4-1 1.3-1.6 2.3-1.6.3 0 .6.1.9.2 1.3.5 1.9 1.9 1.5 3.2l-.1.2c-1.5 4.2-2.8 13.2-4.1 22.7-3.3 23.6-6.1 40.6-13.1 44.8-2.3 1.3-4.5 1.9-6.7 1.9z"
      />
      <path
        className="__sad-icon-st6"
        d="M77.1 196.3c0 .1-.1.1-.1.2 0-.1.1-.2.1-.2m-.1.2c-4.5 12.4-6 60.2-16.1 66.2-2 1.2-3.8 1.7-5.4 1.7-6.9 0-11-8.9-10.6-16.5 1.5-22.5 25.2-33.1 32.1-51.4m.1-5.2c-2 0-3.9 1.2-4.7 3.2l-.1.2c-2.5 6.7-7.9 12.6-13.6 18.7-8.4 9-17.8 19.2-18.8 34.1-.4 6.5 1.8 13.1 5.7 17.3 2.7 2.9 6.2 4.5 9.9 4.5 2.6 0 5.3-.8 8-2.4 8-4.7 10.9-21.5 14.3-46.6 1.3-9.4 2.5-18.2 3.9-22.2l.1-.2c1-2.6-.4-5.4-2.9-6.4-.6-.1-1.2-.2-1.8-.2z"
      />
      <path
        d="M174.1 271.1s25.1-70.4 85.5-35.2"
        fill="none"
        stroke="#fff"
        strokeWidth={30}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </svg>
  );
}

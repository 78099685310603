import * as React from "react";

const InstrumentEchoes = (props) => (
  <svg
    id="InstrumentEchoes_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentEchoes_svg__st79{fill:none;stroke:#fdcc75;stroke-width:2;stroke-miterlimit:10}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#f18860",
      }}
    />
    <circle
      className="InstrumentEchoes_svg__st79"
      cx={36.68}
      cy={90.73}
      r={2.81}
    />
    <circle
      className="InstrumentEchoes_svg__st79"
      cx={38.27}
      cy={88.42}
      r={7.07}
    />
    <circle
      className="InstrumentEchoes_svg__st79"
      cx={39.89}
      cy={84.45}
      r={11.96}
    />
    <circle
      className="InstrumentEchoes_svg__st79"
      cx={41.94}
      cy={79.93}
      r={16.81}
    />
    <circle
      className="InstrumentEchoes_svg__st79"
      cx={45.28}
      cy={74.5}
      r={22.09}
    />
    <circle
      className="InstrumentEchoes_svg__st79"
      cx={47.17}
      cy={71.1}
      r={26.74}
    />
    <circle
      className="InstrumentEchoes_svg__st79"
      cx={49.74}
      cy={66.37}
      r={32.43}
    />
    <circle
      className="InstrumentEchoes_svg__st79"
      cx={51.54}
      cy={60.95}
      r={37.78}
    />
    <ellipse
      className="InstrumentEchoes_svg__st79"
      cx={54.89}
      cy={56.11}
      rx={43.46}
      ry={42.82}
    />
  </svg>
);

export default InstrumentEchoes;

import * as React from "react";
const SvgInstrumentOrchestralPercussion = (props) => (
  <svg
    id="InstrumentOrchestralPercussion_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentOrchestralPercussion_svg__st99{fill:#f48864}.InstrumentOrchestralPercussion_svg__st100{fill:#fdc676}.InstrumentOrchestralPercussion_svg__st101{fill:#4b4a9e}.InstrumentOrchestralPercussion_svg__st103{fill:#c55a74}.InstrumentOrchestralPercussion_svg__st104{fill:#f8c4b9}"
      }
    </style>

    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#528459",
      }}
    />
    <path
      className="InstrumentOrchestralPercussion_svg__st99"
      d="M69.49 58.62s-.71 2.44-17.36 2.44-17.36-2.44-17.36-2.44V30.95s3.39-1.72 17.36-1.72 17.36 1.72 17.36 1.72v27.67z"
    />
    <circle
      className="InstrumentOrchestralPercussion_svg__st103"
      cx={51.67}
      cy={52.85}
      r={1.45}
    />
    <path
      className="InstrumentOrchestralPercussion_svg__st103"
      d="M51.67 61.71a.39.39 0 0 1-.39-.39v-7.1a.39.39 0 0 1 .78 0v7.1c0 .22-.17.39-.39.39z"
    />
    <circle
      className="InstrumentOrchestralPercussion_svg__st103"
      cx={39.2}
      cy={51.71}
      r={1.45}
    />
    <path
      className="InstrumentOrchestralPercussion_svg__st103"
      d="M39.2 60.57a.39.39 0 0 1-.39-.39v-7.1a.39.39 0 0 1 .78 0v7.1c0 .22-.18.39-.39.39z"
    />
    <circle
      className="InstrumentOrchestralPercussion_svg__st103"
      cx={64.51}
      cy={52.2}
      r={1.45}
    />
    <path
      className="InstrumentOrchestralPercussion_svg__st103"
      d="M64.51 61.06a.39.39 0 0 1-.39-.39v-7.1a.39.39 0 0 1 .78 0v7.1c0 .22-.17.39-.39.39z"
    />
    <path
      className="InstrumentOrchestralPercussion_svg__st104"
      d="M54.71 77.5c.11-.41.2-.84.25-1.27.07-.54.08-1.07.06-1.6.48-.02.9-.36.96-.86a.993.993 0 0 0-.87-1.11c-.12-.02-.24 0-.36.03a9.887 9.887 0 0 0-1.22-2.94c.3-.14.53-.41.57-.76a.993.993 0 0 0-.87-1.11.99.99 0 0 0-.86.35 9.695 9.695 0 0 0-3.03-2.22c.02-.06.05-.11.06-.17a.993.993 0 0 0-.87-1.11c-.46-.06-.86.22-1.03.62-.4-.1-.81-.19-1.24-.24-.77-.09-1.52-.09-2.26-.01a.992.992 0 0 0-.84-.76.993.993 0 0 0-1.11.87c-.01.1.01.2.02.29-1.33.42-2.55 1.11-3.58 2.01a.978.978 0 0 0-.73-.51.993.993 0 0 0-1.11.87c-.05.43.18.82.55 1.01-.64.82-1.17 1.75-1.53 2.76-.08-.03-.16-.07-.25-.08-.55-.07-1.04.32-1.11.87s.32 1.04.87 1.11h.02c-.01.1-.04.19-.05.29-.13 1.09-.08 2.15.12 3.17-.32.13-.56.42-.61.78-.07.55.32 1.04.87 1.11.11.01.21 0 .31-.02.38.92.9 1.78 1.54 2.54-.24.15-.42.4-.46.71-.07.55.32 1.04.87 1.11.39.05.76-.15.96-.46.76.62 1.62 1.14 2.55 1.52-.01.03-.02.05-.02.07-.07.55.32 1.04.87 1.11.46.06.87-.22 1.03-.63.22.04.44.09.67.12.88.11 1.74.09 2.58-.03.09.4.41.72.84.77a.993.993 0 0 0 1.11-.87c.02-.13 0-.25-.03-.36.83-.29 1.6-.68 2.32-1.17a.993.993 0 0 0 1.76-.51.938.938 0 0 0-.24-.76 9.82 9.82 0 0 0 1.87-2.66c.08.03.15.07.24.08a.993.993 0 0 0 1.11-.87c.04-.51-.26-.93-.7-1.08z"
    />
    <path
      className="InstrumentOrchestralPercussion_svg__st103"
      d="M33.27 79.45c.07-.54.12-1.09.12-1.66 0-.7-.07-1.38-.18-2.04.61-.09 1.09-.6 1.09-1.24 0-.71-.57-1.28-1.28-1.28-.16 0-.31.04-.45.09a12.63 12.63 0 0 0-2.01-3.54c.36-.23.61-.61.61-1.06 0-.71-.57-1.28-1.28-1.28-.44 0-.81.24-1.04.58a12.772 12.772 0 0 0-4.2-2.36c.01-.08.05-.15.05-.23 0-.71-.57-1.28-1.28-1.28-.59 0-1.07.41-1.21.95-.53-.05-1.07-.1-1.61-.1-.99 0-1.95.12-2.87.34-.18-.49-.64-.84-1.19-.84-.71 0-1.28.57-1.28 1.28 0 .13.04.25.07.37-1.63.74-3.07 1.8-4.25 3.12-.23-.32-.59-.54-1.01-.54-.71 0-1.28.57-1.28 1.28 0 .56.36 1.02.86 1.19-.69 1.14-1.21 2.4-1.52 3.74-.11-.03-.21-.07-.33-.07-.71 0-1.28.57-1.28 1.28s.57 1.28 1.28 1.28h.02c0 .13-.02.25-.02.38 0 1.4.23 2.75.65 4.01-.38.21-.65.6-.65 1.08 0 .71.57 1.28 1.28 1.28.14 0 .27-.04.39-.08.63 1.11 1.43 2.12 2.35 2.99-.28.23-.47.57-.47.97 0 .71.57 1.28 1.28 1.28.51 0 .94-.3 1.14-.73 1.07.67 2.24 1.19 3.48 1.53 0 .03-.02.06-.02.1 0 .71.57 1.28 1.28 1.28.59 0 1.07-.41 1.21-.96.29.02.58.04.87.04 1.13 0 2.23-.16 3.27-.44.18.49.64.85 1.19.85.71 0 1.28-.57 1.28-1.28 0-.16-.04-.31-.09-.46a13 13 0 0 0 2.77-1.85 1.274 1.274 0 0 0 2.16-.92c0-.37-.17-.7-.42-.93.84-1.1 1.51-2.33 1.96-3.67.1.03.2.06.31.06.71 0 1.28-.57 1.28-1.28 0-.62-.45-1.11-1.03-1.23z"
    />
    <path
      className="InstrumentOrchestralPercussion_svg__st104"
      d="M90.36 56.68c.05-.42.09-.85.09-1.29 0-.54-.06-1.07-.14-1.6.48-.07.85-.47.85-.97 0-.55-.45-1-1-1-.12 0-.24.03-.35.07a10.23 10.23 0 0 0-1.57-2.77c.28-.18.47-.47.47-.83 0-.55-.45-1-1-1-.35 0-.64.19-.81.45a9.72 9.72 0 0 0-3.28-1.84c.01-.06.04-.12.04-.18 0-.55-.45-1-1-1-.46 0-.83.32-.95.74-.41-.05-.83-.09-1.26-.09-.77 0-1.52.1-2.24.26a.988.988 0 0 0-.93-.65c-.55 0-1 .45-1 1 0 .1.03.19.06.29a9.97 9.97 0 0 0-3.31 2.43.97.97 0 0 0-.79-.42c-.55 0-1 .45-1 1 0 .43.28.79.67.93-.54.89-.95 1.87-1.19 2.92-.08-.02-.17-.05-.26-.05-.55 0-1 .45-1 1s.45 1 1 1h.01c0 .1-.01.19-.01.29 0 1.09.18 2.15.51 3.13-.3.17-.51.48-.51.85 0 .55.45 1 1 1 .11 0 .21-.03.3-.06.49.87 1.11 1.65 1.84 2.34-.22.18-.37.45-.37.76 0 .55.45 1 1 1 .4 0 .73-.24.89-.57.83.53 1.75.93 2.72 1.19 0 .03-.02.05-.02.08 0 .55.45 1 1 1 .46 0 .84-.32.95-.75.23.02.45.03.68.03.88 0 1.74-.13 2.55-.34.14.38.5.66.93.66.55 0 1-.45 1-1 0-.13-.03-.25-.07-.36.78-.39 1.51-.87 2.16-1.44.18.17.42.28.69.28.55 0 1-.45 1-1 0-.29-.13-.55-.33-.73.66-.86 1.18-1.82 1.53-2.86.08.02.16.05.25.05.55 0 1-.45 1-1 0-.47-.34-.86-.8-.95z"
    />
    <path
      className="InstrumentOrchestralPercussion_svg__st103"
      d="M48.84 29.39c.05-.42.09-.85.09-1.29 0-.54-.06-1.07-.14-1.6.48-.07.85-.47.85-.97 0-.55-.45-1-1-1-.12 0-.24.03-.35.07a10.23 10.23 0 0 0-1.57-2.77c.28-.18.47-.47.47-.83 0-.55-.45-1-1-1-.35 0-.64.19-.81.45a9.72 9.72 0 0 0-3.28-1.84c.01-.06.04-.12.04-.18 0-.55-.45-1-1-1-.46 0-.83.32-.95.74-.41-.05-.83-.09-1.26-.09-.77 0-1.52.1-2.24.26a.988.988 0 0 0-.93-.65c-.55 0-1 .45-1 1 0 .1.03.19.06.29a9.97 9.97 0 0 0-3.31 2.43.97.97 0 0 0-.79-.42c-.55 0-1 .45-1 1 0 .43.28.79.67.93-.54.89-.95 1.87-1.19 2.92-.08-.02-.17-.05-.26-.05-.55 0-1 .45-1 1s.45 1 1 1h.01c0 .1-.01.19-.01.29 0 1.09.18 2.15.51 3.13-.3.17-.51.48-.51.85 0 .55.45 1 1 1 .11 0 .21-.03.3-.06.49.87 1.11 1.65 1.84 2.34-.22.18-.37.45-.37.76 0 .55.45 1 1 1 .4 0 .73-.24.89-.57.83.53 1.75.93 2.72 1.19 0 .03-.02.05-.02.08 0 .55.45 1 1 1 .46 0 .84-.32.95-.75.23.02.45.03.68.03.88 0 1.74-.13 2.55-.34.14.38.5.66.93.66.55 0 1-.45 1-1 0-.13-.03-.25-.07-.36.78-.39 1.51-.87 2.16-1.44.18.17.42.28.69.28.55 0 1-.45 1-1 0-.29-.13-.55-.33-.73.66-.86 1.18-1.82 1.53-2.86.08.02.16.05.25.05.55 0 1-.45 1-1 .01-.48-.34-.86-.8-.95z"
    />
    <path
      className="InstrumentOrchestralPercussion_svg__st99"
      d="M55.98 68.71 37.42 84.92c-.21.18-.52.16-.7-.05a.493.493 0 0 1 .05-.7l18.55-16.21c.21-.18.52-.16.7.05.18.21.16.52-.04.7z"
    />
    <ellipse
      transform="rotate(-41.132 56.395 67.696)"
      className="InstrumentOrchestralPercussion_svg__st99"
      cx={56.39}
      cy={67.69}
      rx={0.86}
      ry={0.49}
    />
    <path
      className="InstrumentOrchestralPercussion_svg__st99"
      d="M52.15 65.85 42.23 88.4c-.11.25-.4.36-.65.25a.491.491 0 0 1-.25-.65l9.92-22.55c.11-.25.4-.36.65-.25.25.11.36.4.25.65z"
    />
    <ellipse
      transform="rotate(-66.259 52.088 64.753)"
      className="InstrumentOrchestralPercussion_svg__st99"
      cx={52.09}
      cy={64.76}
      rx={0.86}
      ry={0.49}
    />
    <circle
      className="InstrumentOrchestralPercussion_svg__st101"
      cx={23.23}
      cy={35.37}
      r={13.36}
    />
    <circle
      className="InstrumentOrchestralPercussion_svg__st100"
      cx={74.92}
      cy={35.37}
      r={17.01}
    />
    <path
      className="InstrumentOrchestralPercussion_svg__st103"
      d="M89.6 84.21c.08-.64.14-1.3.14-1.96 0-.83-.08-1.63-.21-2.42.72-.11 1.29-.71 1.29-1.47 0-.84-.68-1.51-1.51-1.51-.19 0-.37.04-.53.11-.57-1.53-1.37-2.95-2.38-4.2.42-.27.72-.72.72-1.26 0-.84-.68-1.51-1.51-1.51-.52 0-.96.28-1.24.69a14.939 14.939 0 0 0-4.97-2.79c.02-.09.06-.18.06-.27 0-.84-.68-1.51-1.51-1.51-.7 0-1.26.48-1.44 1.13-.63-.08-1.26-.13-1.91-.13-1.17 0-2.31.15-3.4.4-.21-.58-.76-.99-1.41-.99-.84 0-1.51.68-1.51 1.51 0 .15.04.29.09.43-1.93.87-3.64 2.14-5.03 3.69-.28-.38-.7-.64-1.2-.64-.84 0-1.51.68-1.51 1.51 0 .66.43 1.21 1.02 1.41-.82 1.36-1.44 2.85-1.8 4.44-.13-.04-.25-.08-.39-.08-.84 0-1.51.68-1.51 1.51 0 .84.68 1.51 1.51 1.51h.02c0 .15-.02.3-.02.45 0 1.66.28 3.26.77 4.75-.45.26-.77.73-.77 1.29 0 .84.68 1.51 1.51 1.51.16 0 .31-.05.46-.09.75 1.32 1.69 2.51 2.79 3.55-.33.28-.56.68-.56 1.15 0 .84.68 1.51 1.51 1.51.6 0 1.11-.36 1.36-.87 1.26.8 2.65 1.41 4.13 1.81 0 .04-.02.08-.02.12 0 .84.68 1.51 1.51 1.51.7 0 1.27-.49 1.44-1.14.34.02.68.05 1.03.05 1.34 0 2.64-.19 3.88-.52.21.58.76 1 1.41 1 .84 0 1.51-.68 1.51-1.51 0-.19-.04-.37-.11-.54a15.14 15.14 0 0 0 3.28-2.19 1.509 1.509 0 0 0 2.55-1.09c0-.44-.2-.83-.5-1.11 1-1.3 1.79-2.76 2.32-4.35.12.03.24.08.37.08.84 0 1.51-.68 1.51-1.51-.02-.74-.55-1.32-1.24-1.46z"
    />
    <circle
      className="InstrumentOrchestralPercussion_svg__st101"
      cx={87.23}
      cy={70.73}
      r={10.15}
    />
    <circle
      className="InstrumentOrchestralPercussion_svg__st100"
      cx={47.51}
      cy={15.2}
      r={10.92}
    />
    <circle
      className="InstrumentOrchestralPercussion_svg__st100"
      cx={22.7}
      cy={57.77}
      r={15.22}
    />
  </svg>
);
export default SvgInstrumentOrchestralPercussion;

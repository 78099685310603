import * as React from "react";

const InstrumentJazzGuitar = (props) => (
  <svg
    id="InstrumentJazzGuitar_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.1 110.84"
    style={{
      enableBackground: "new 0 0 110.1 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentJazzGuitar_svg__st4{fill:#f18860}.InstrumentJazzGuitar_svg__st5{fill:#cfd125}"
      }
    </style>
    
    <path
      d="M93.54 110.1H16.56C7.41 110.1 0 102.69 0 93.54V16.56C0 7.41 7.41 0 16.56 0h76.98c9.15 0 16.56 7.41 16.56 16.56v76.98c0 9.15-7.41 16.56-16.56 16.56z"
      style={{
        fill: "#fdcc75",
      }}
    />
    <path
      d="M52.33 49.38c-3.34-6.55-13.49-5.44-16.16 1.08-.5 1.21-.72 2.52-1.13 3.77-.97 2.92-3.31 4.13-6.21 4.65-2.99.54-6.09.82-8.79 2.31-2.83 1.56-5.15 4.05-6.57 6.95-5.86 12.02 5.42 25.07 16.67 28.29 5.72 1.64 11.61-.32 15.6-4.61 4.41-4.73 4.55-10.36 5.05-16.41.22-2.64 3.15-3.51 5.17-4.51 1.51-.75 3.01-1.62 4.04-2.96 2.35-3.06.76-5.71-2.75-6.08-4.55-.49-4.37-4.38-4.07-8.03.12-1.64-.19-3.15-.85-4.45z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentJazzGuitar_svg__st4"
      d="m46.3 65.7-4.33-3.83a.722.722 0 0 1-.06-1.01l.97-1.09c.26-.29.71-.32 1.01-.06l4.33 3.83c.29.26.32.71.06 1.01l-.97 1.09c-.26.29-.71.32-1.01.06zM40.12 72.68l-4.33-3.83a.722.722 0 0 1-.06-1.01l.97-1.09c.26-.29.71-.32 1.01-.06l4.33 3.83c.29.26.32.71.06 1.01l-.97 1.09c-.26.29-.71.32-1.01.06zM37.73 76.75l-5.41-4.23a.775.775 0 0 1-.13-1.08c.26-.33.75-.39 1.08-.13l5.41 4.23c.33.26.39.75.13 1.08-.26.33-.75.39-1.08.13z"
    />
    <circle
      className="InstrumentJazzGuitar_svg__st4"
      cx={37.62}
      cy={90.35}
      r={1.05}
    />
    <circle
      className="InstrumentJazzGuitar_svg__st4"
      cx={45.35}
      cy={89.1}
      r={1.05}
    />
    <path
      className="InstrumentJazzGuitar_svg__st4"
      d="m25.21 79.37 5.98 5.28-10.09 8.41-3.19-2.81z"
    />
    <path
      className="InstrumentJazzGuitar_svg__st5"
      d="M36.66 61.5a.54.54 0 0 0 0-.18.471.471 0 0 0-.06-.15c-.01-.03-.02-.06-.04-.09a.474.474 0 0 0-.1-.1l-.03-.03-.03-.03c-.02-.01-.03-.03-.05-.04-1.69-1.57-3.63-.22-5.06.98-.61.51-1.26 1.03-1.96 1.41-.31.17-.64.31-.96.45-.08.03-.43.13-.47.2-.82 1.69-1.68 3.35-3.29 4.42-1.07.71-2.71 1.4-3.79.35.1-.04.2-.09.27-.18a.68.68 0 0 0-1.02-.9c-.04.05-.07.11-.1.15-.01.02-.01.03-.02.05-.01.02-.02.04-.02.06-.43 1.01.52 1.81 1.55 2.01 1.34.26 2.6-.63 3.7-1.24 3.89-2.21 4.67-2.44 4.67-2.44-.04-.01.34-.9.38-.97.23-.45.49-.89.78-1.31.47-.68 1.01-1.32 1.61-1.89.49-.47 1.06-1.08 1.74-1.27.43-.12.81-.03 1.19.17-.09-.05-.22.4-.23.45-.02.16.02.32.12.45.21.29.62.37.92.17.19-.1.29-.29.3-.5zM45.97 69.74c.05-.02.1-.03.18-.02.05.01.11.02.16.04.03.01.07.01.1.03.04.02.07.06.11.08.01.01.02.01.03.02l.03.03.04.04c1.76 1.48.66 3.58-.36 5.14-.43.66-.87 1.38-1.16 2.12-.13.33-.23.67-.33 1-.02.08-.07.45-.15.49-1.58 1.02-3.12 2.08-3.98 3.8-.57 1.15-1.06 2.86.11 3.81.02-.11.07-.21.14-.29a.68.68 0 0 1 1.02.9c-.04.05-.1.08-.14.12-.01.01-.03.01-.05.02l-.06.03c-.95.55-1.86-.29-2.19-1.29-.42-1.3.31-2.66.78-3.82 1.72-4.13 1.85-4.93 1.85-4.93.01.03.85-.45.91-.49.42-.29.82-.6 1.21-.93.62-.55 1.19-1.16 1.68-1.82.41-.54.94-1.19 1.05-1.88.07-.44-.06-.8-.32-1.16.06.08-.37.27-.42.28-.16.04-.32.02-.46-.06a.68.68 0 0 1-.28-.9c.12-.2.3-.32.5-.36z"
    />
    <path
      className="InstrumentJazzGuitar_svg__st4"
      d="m77.59 23.38 3.01 2.66-30.54 35.64-4.32-3.82z"
    />
    <path
      d="m80.09 26.03-2.49-2.2a2.564 2.564 0 0 1-.22-3.62l8.19-9.25a2.564 2.564 0 0 1 3.62-.22l2.49 2.2c1.06.94 1.16 2.56.22 3.62l-8.19 9.25a2.564 2.564 0 0 1-3.62.22z"
      style={{
        fill: "#ca5672",
      }}
    />
  </svg>
);

export default InstrumentJazzGuitar;

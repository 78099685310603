import { useEffect, useState } from "react";
import { Trans as Translate } from 'react-i18next';
import { strofeApi } from "../../api/strofeApi";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';

export default function DeleteAccountModal({ show, onHide, currentUser }) {

  const history = useHistory();

  const [showEnterVerification, setShowEnterVerification] = useState(false);
  const [showDeathModal, setShowDeathModal] = useState(false);
  const [creatingCode, setCreatingCode] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (show) {
      setShowEnterVerification(false);
      setVerificationCode('');
      setError(false);
      setShowDeathModal(false);
    }
  }, [show]);

  const createUserDeletion = async () => {
    setCreatingCode(true);
    await strofeApi.post('/user_deletions');
    setCreatingCode(false);
    setShowEnterVerification(true);
  }

  const showConfirmationModal = show && ! showEnterVerification && !showDeathModal;

  const onChangeVerificationCode = e => {
    setVerificationCode(e.target.value);
    setError(false);
  }

  const onVerify = async e => {
    e.preventDefault();
    
    const code = verificationCode.trim().replace(" ", "");
    setVerificationCode(code);

    const verificationRegex = /^\d{6}$/;

    if (!verificationRegex.test(code)) {
      setError('email-verification-regex-error');
      return;
    }

    setError(false);
    setVerifying(true);

    try {
      await strofeApi.delete(`/users/${currentUser.id}`, { data: { code }});
      setShowDeathModal(true);
      setShowEnterVerification(false);
    }
    catch (error) {
      // 401 -> unauthorized (wrong code or expired code)
      // 403 -> is forbidden (IE: access token is invalid)
      // 500 -> server exploded, should show bigger error
      console.log('error:', error)
      setError('email-verification-request-error');
    }
    finally {
      setVerifying(false);
    }
  }

  const onHideDeathModal = () => {
    Cookies.remove('api_access_token');
    Cookies.remove('strofe_user_id');

    history.push('/get-started');
  }

  return (
    <>
      <Modal show={showConfirmationModal} className='__modal' backdrop='static' onHide={onHide}>
        <Modal.Header>
          <Translate>Delete Your Account</Translate>
        </Modal.Header>

        <Modal.Body>
          <p><Translate i18nKey='delete-account-confirm' /></p>
          <p><Translate i18nKey='delete-account-warning' /></p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" disabled={creatingCode} onClick={onHide}><Translate>Close</Translate></Button>
          <Button variant="primary" disabled={creatingCode} onClick={createUserDeletion}><Translate>Delete Account</Translate></Button>
        </Modal.Footer>
      </Modal>

      <Modal className='__modal' show={show && showEnterVerification} backdrop='static' size='sm'>
        <Modal.Header>
          <Translate>Verify Your Email</Translate>
        </Modal.Header>

        <Modal.Body>
          <p><Translate i18nKey='delete-account-email-code' /></p>

          <Form.Control className='my-2' disabled={verifying} value={verificationCode} onChange={onChangeVerificationCode} />

          { error && (
            <Alert variant='warning'>
              <Translate i18nKey={error} />
            </Alert>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide} disabled={verifying}><Translate>Close</Translate></Button>
          <Button variant="primary" type="submit" disabled={!verificationCode.trim().length || verifying} onClick={onVerify}><Translate>Verify</Translate></Button>
        </Modal.Footer>
      </Modal>

      <Modal className='__modal' show={show && showDeathModal} backdrop='static' size='sm'>
        <Modal.Header>
          <Translate>Delete Account</Translate>
        </Modal.Header>

        <Modal.Body>
          <p><Translate>Your account has been permanently deleted.</Translate></p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHideDeathModal}><Translate>Close</Translate></Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

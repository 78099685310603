import { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useImmer } from 'use-immer';
import useEventCallback from "../../hooks/useEventCallback";
import { useSelector } from "react-redux";
import classNames from "classnames";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useDebouncedCallback } from 'use-debounce';
import { strofeApi } from "../../api/strofeApi";
import { Trans as Translate } from 'react-i18next';

import Slider from 'rc-slider';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';

// TODO -> create own partial for css instead of using __create-song
import MidiPlayer from "../../utils/MidiPlayer";
import PermissionsDropdown from "./PermissionsDropdown";

import Equalizer from "./Equalizer";
import useInterval from "../../hooks/useInterval";
import { LoopingAudio } from "../../utils/LoopingAudio";
import { usersSelectors } from "../../store/usersSlice";
import RegistrationModal from "../../registration/RegistrationModal";
import PencilIcon from "../../icons/PencilIcon";
import NavigationHeader from "../NavigationHeader";

// TRACK EDITOR
import { DRUM_PROGRAMS, INSTRUMENT_PROGRAMS } from '../../utils/InstrumentPrograms';

import EditorEqualizer from "./EditorEqualizer";
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";
import LoadingBlocks from "../../layout/LoadingBlocks";
import ShareTrackModal from "./ShareTrackModal";
import seekSliderHandle from "./SeekTooltip";
import MasterVolume from "./MasterVolume";
import DownloadTrackFlow from "../../modals/Download/DownloadTrackFlow";
import ReRollModal from "../../modals/ReRoll/ReRollModal";
import InstrumentSelectorModal from "../../modals/InstrumentSelector/InstrumentSelectorModal";
import SubscribeModal from "../../modals/Download/SubscribeModal";
import InstrumentRow from "./InstrumentRow";
import SeparatorRow from "./SeparatorRow";
import DeleteInstrumentModal from "./DeleteInstrumentModal";
import InstrumentCategoryModal from "../NoteSequences/InstrumentCategoryModal";
import MaxInstrumentsModal from "./MaxInstrumentsModal";

import MoodStyleIcon from "./MoodStyleIcon";
import InfinityIcon from '../../icons/InfinityIcon';
import PauseIconV2 from "../../icons/PauseIconV2";
import PlayIconV2 from "../../icons/PlayIconV2";
import RerollIcon from "../../icons/RerollIcon";

import './SongPlayer.scss';
import './TrackEditor.scss';

// Test files that can be accessed through the public folder:
const TestShortMidi = process.env.PUBLIC_URL + '/test_audios/test-short.mid';
const TestMidi = process.env.PUBLIC_URL + '/test_audios/test.mid';
const TestOgg = process.env.PUBLIC_URL + '/test_audios/starwars.ogg';

// use multitrack version: (instead of 'main' track, download all mp3 instruments):
const MULTITRACK = process.env.REACT_APP_PLAY_MULTITRACK;

// When resizing, calculate if the Song Section should show narrow controls based on this width:
const TRACK_ROW_MIN_WIDTH = 64;

const MAX_INSTRUMENTS_PER_TRACK = 10;

export default function SongPlayer() {

  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const currentUser = useSelector(usersSelectors.getCurrentUser);
  const abTests = useSelector(usersSelectors.getAbTests);

  const [songStatus, setSongStatus] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [audioUrl, setAudioUrl] = useState(location.state?.audioUrl || null);
  const [playbackPosition, setPlaybackPosition] = useState(0);
  const [loop, setLoop] = useState(location.state?.loop || null);
  const [songDuration, setSongDuration] = useState(null);
  const [seekOffset, setSeekOffset] = useState(null);
  const [durationWithLoops, setDurationWithLoops] = useState(null);
  const [songTitle, setSongTitle] = useState(location.state?.title || '');
  const [editingTitle, setEditingTitle] = useState(null);
  const [songData, setSongData] = useImmer(null);
  const [trackVelocities, setTrackVelocities] = useState(null);
  const [trackMutes, setTrackMutes] = useState(null);
  const [trackSolos, setTrackSolos] = useState({});
  const [showOptin, setShowOptin] = useState(null);
  const [optinType, setOptinType] = useState(null);
  const [showDownload, setShowDownload] = useState(false);
  const [loopsPlayed, setLoopsPlayed] = useState(0);
  const [regeneratingPrograms, setRegeneratingPrograms] = useState({});
  const [regeneratingAllSections, setRegeneratingAllSections] = useState({});
  const [creatingTrack, setCreatingTrack] = useImmer({});
  const [loopsEnabled, setLoopsEnabled] = useState([]);
  const [showShareTrack, setShowShareTrack] = useState(false);
  const [noteSequences, setNoteSequences] = useState([]);
  const [regeneratingTrack, setRegeneratingTrack] = useState(false);
  const [narrowSections, setNarrowSections] = useState([]);
  const [showReRoll, setShowReRoll] = useState(false);
  const [instrumentError, setInstrumentError] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showSelectInstrument, setShowSelectInstrument] = useState(false);
  const [selectedInstrument, setSelectedInstrument] = useState({});
  const [deleteInstrumentId, setDeleteInstrumentId] = useState(null);
  const [showDeleteInstrument, setShowDeleteInstrument] = useState(false);
  const [addInstrumentIndex, setAddInstrumentIndex] = useState(false);
  const [showAddInstrument, setShowAddInstrument] = useState(false);
  const [addInstrumentCategory, setAddInstrumentCategory] = useState(null);
  const [showMaxInstruments, setShowMaxInstruments] = useState(false);

  const titleRef = useRef();
  const trackRowRef = useRef();
  const seekRef = useRef();
  const mobileSeekRef = useRef();

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = e => {
    const { active, over } = e;

    if (active.id !== over.id) {
      let instrument_ids;

      setSongData(draft => {
        const oldIndex = draft.instruments.findIndex(i => i.id === active.id);
        const newIndex = draft.instruments.findIndex(i => i.id === over.id);

        draft.instruments = arrayMove(draft.instruments, oldIndex, newIndex);
        instrument_ids = draft.instruments.map(instrument => instrument.id);
      });

      strofeApi.put(`/songs/${songData.id}`, { song: { instrument_ids }}); 
    }
  }

  const subscribeTest = abTests['subscribe-flow'];

  // { [trackId]: sectionId } <- keep which section of the track is regenerating
  const [trackSectionRegenerating, setTrackSectionRegenerating] = useState({});

  // When seekOffset exists (user is dragging the seek slider), use that as the position, else use the playbackPosition set in the interval:
  const seekPosition = seekOffset !== null ? seekOffset : playbackPosition * 100;

  useInterval(() => {
    if (LoopingAudio.status === 'playing') {
      setPlaybackPosition(LoopingAudio.playbackPosition);
      
      setLoopsPlayed(LoopingAudio.loopsPlayed);
    }
  }, 200);

  const showComposer = currentUser?.composer || currentUser?.super_user || currentUser?.subscribed || currentUser?.ambassador;

  const onSongEnded = () => {
    setSongStatus(null);
    setPlaybackPosition(LoopingAudio.playbackPosition);
  }

  useEffect(() => {
    LoopingAudio.initialize({ onSongEnded });

    return () => {
      MidiPlayer.close(); //! This stops the song, but when a new song is loaded, the old one plays
      LoopingAudio.close();
    }
  }, []);

  // TODO -> FIX THIS
  const debouncedOnResize = useDebouncedCallback(() => {
    if (trackRowRef.current) {
      const rows = trackRowRef.current.children;

      let sections = [];

      for (let i = 1; i < rows.length; i++) {
        const { width } = rows[i].getBoundingClientRect();
        sections.push(width < TRACK_ROW_MIN_WIDTH);
      }

      setNarrowSections(sections);
    }
  }, 500);

  useEffect(() => {
    window.addEventListener('resize', debouncedOnResize);
    
    return () => {
      window.removeEventListener('resize', debouncedOnResize);
    }
  }, [debouncedOnResize]);

  useEffect(() => {
    if (loadingStatus === 'ready') {
      debouncedOnResize();
      debouncedOnResize.flush();
    }
  }, [debouncedOnResize, loadingStatus])

  useEffect(() => {

    if (!loadingStatus) {
      const params = new URLSearchParams(location.search);
      const created = params.get('created');
      const audioFormat = params.get('format')?.toLowerCase() || process.env.REACT_APP_DEFAULT_FORMAT || 'midi';

      setLoadingStatus('loading');

      // TODO -> use params.delete
      if (created && audioFormat !== 'mp3') {
        params.delete('created');
        history.replace(`/song/${id}?${params.toString()}`);
        return; // prevent execution continuing and causing a race condition loading the song

        // TODO -> use redux
        // if (audioFormat === 'mp3') {
        //   LoopingAudio.loadSong(audioUrl, loop).then(res => {
        //     setLoadingStatus('ready');
        //     setSongDuration(res)
        //   });
        // }
      }

      else {

        const loadSong = song => {
          MidiPlayer.loadMidi(song, () => {
            setLoadingStatus('ready');
          }, /* doLoop - loop */);
        }

        const loadAudio = (file, loop) => {
          setAudioUrl(file);

          LoopingAudio.loadSong(file, loop).then((res => {
            setSongDuration(res);
            setLoadingStatus('ready');
          }));
          setLoop(loop);
        }

        if (id === 'test') {
          audioFormat === 'ogg' ? loadAudio(TestOgg) : loadSong(TestMidi);
        }
        else if (id === 'test-short') {
          loadSong(TestShortMidi)
        }

        else {
          axios.get(process.env.REACT_APP_NOMODO_API + `/songs/${id}`, {
            headers: {
              Authorization: `Bearer ${Cookies.get('api_access_token')}`
            }
          }).then(response => {
            const { download_url, mp3_download_url, loops, title, section_start_times, creator_id, style } = response.data;

            if (showComposer) {
              strofeApi.get('/note_sequences', { params: { style, viable: true } }).then(response => {
                setNoteSequences(response.data);
              });
            }

            // If the song is public and the creator_id is different to currentUser.id (or currentUser doesn't exist)
            // redirect to listen, with the songData fetched already on location.state ->
            if (response.data.public && creator_id !== currentUser?.id) {
              history.replace(`/listen/${id}`, { songData: response.data });
              return; // prevent execution continuing and causing a race condition loading the song
            }

            const loopStart = section_start_times[loops[0][0]];
            const loopEnd = section_start_times[loops[0][1] + 1];

            LoopingAudio.setSongData(response.data);

            if (MULTITRACK) {

              const velocities = {};
              const mutes = {};

              response.data.instruments.forEach(instrument => {
                // LoopingAudio.loadTrack(instrument.category, instrument.mp3_download_url, false);
                velocities[instrument.id] = instrument.velocity;
                mutes[instrument.id] = instrument.muted;
                LoopingAudio.setTrackVelocity(instrument.id, instrument.velocity);
                LoopingAudio.setTrackMuted(instrument.id, instrument.muted);
              });

              LoopingAudio.loadTracks(response.data.instruments.map(instrument => ({ id: instrument.id, file: instrument.mp3_download_url}))).then(res => {
                LoopingAudio.calculateTotalDuration();
                setSongDuration(res);
                setDurationWithLoops(LoopingAudio.durationWithLoops);

                setLoadingStatus('ready');
              }).catch(() => {
                setLoadingStatus('fetch-mp3-error');
              });
              
              setAudioUrl(mp3_download_url);
              setTrackVelocities(velocities);
              setTrackMutes(mutes);
              setLoop({ loop: true, loopStart, loopEnd });

              LoopingAudio.loop = true;
              LoopingAudio.loopStart = loopStart;
              LoopingAudio.loopEnd = loopEnd;

              setLoopsEnabled(response.data.loops.map(() => true));

              setSongData(response.data);
            }
            else {
              audioFormat === 'mp3' ? loadAudio(mp3_download_url, { loop: true, loopStart, loopEnd }) : loadSong(download_url);
            }

            setSongTitle(title);
            setEditingTitle(title);

          }).catch(() => {
            setLoadingStatus('fetch-error');
          });
        }
      }
    }
  }, [id, history, location.search, loadingStatus, audioUrl, loop, currentUser, showComposer, setSongData]);

  const handleKeyDown = useEventCallback(e => {

    const activeInteractiveElement = () => {
      // buttons allow space-bar for "click" and inputs for adding a blank space:
      if (['INPUT', 'BUTTON', 'TEXTAREA'].includes(document.activeElement.tagName)) {
        return true;
      }
      
      // if a modal is visible, do not prevent the default space action:
      if (document.getElementsByClassName('modal').length) {
        return true;
      }
    }

    if (e.code === 'ArrowRight') {
      if (activeInteractiveElement() || LoopingAudio.playbackPosition + 0.5 >= LoopingAudio.songDuration) {
        return;
      }

      afterChangeOffset(Math.min(LoopingAudio.playbackPosition + 5, LoopingAudio.songDuration - 0.5) * 100);
    }

    if (e.code === 'ArrowLeft') {
      if (activeInteractiveElement() || LoopingAudio.playbackPosition <= 0.5) {
        return;
      }

      afterChangeOffset(Math.max(LoopingAudio.playbackPosition - 5, 0) * 100);
    }

    if (e.code === 'Space') {
      if (activeInteractiveElement()) {
        return;
      }

      e.preventDefault();
      togglePlay();
    }
  });

  useEffect(() => {
    if (loadingStatus === 'ready') {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [loadingStatus, handleKeyDown]);

  const togglePlay = () => {

    const playTrack = MULTITRACK ? LoopingAudio.playAllTracks : LoopingAudio.playTrack;

    if (songStatus === null) {
      audioUrl ? playTrack() : MidiPlayer.start();
      setSongStatus('playing');
    }
    else if (songStatus === 'playing') {
      audioUrl ? LoopingAudio.stopSong() : MidiPlayer.pause();
      setSongStatus('paused');
    }
    else if (songStatus === 'paused') {
      audioUrl ? LoopingAudio.resumeSong() : MidiPlayer.resume();
      setSongStatus('playing');
    }
  }

  const closeOptinModal = () => {
    setShowOptin(false);
  }

  const closeDownloadModal = () => {
    setShowDownload(false);
  }

  const onTitleFormSubmit = e => {
    e.preventDefault();
    titleRef.current.blur();
  }

  const onClickSelectInstrument = (instrument, drums) => {
    setShowSelectInstrument(true);
    setSelectedInstrument({
      id          : instrument.id,
      programName : drums ? DRUM_PROGRAMS[instrument.program] : INSTRUMENT_PROGRAMS[instrument.program],
      drums,
    });
  }

  const submitInstrumentSelect = programNameOrIndex => {
    setShowSelectInstrument(false);

    const nameIndex = typeof programNameOrIndex === 'number' ? programNameOrIndex : (
      selectedInstrument.drums
      ? Object.entries(DRUM_PROGRAMS).find(([_, drum]) => drum === programNameOrIndex)?.[0]
      : INSTRUMENT_PROGRAMS.findIndex(name => name === programNameOrIndex)
    );

    if (nameIndex === -1) {
      console.warn('No association with the name:', programNameOrIndex);
      return;
    }
    if (addInstrumentCategory) {
      createInstrument(nameIndex);
    }
    else {
      changeInstrumentProgram(selectedInstrument.id, nameIndex);
    }
  }

  const onClickDelete = id => {
    setDeleteInstrumentId(id);
    setShowDeleteInstrument(true);
  }

  const onDeleteInstrument = () => {
    setShowDeleteInstrument(false);

    const index = songData.instruments.findIndex(instrument => instrument.id === deleteInstrumentId);

    // if the track is solo'd, toggle it to disable it.
    // If it's the only solo track, this will allow all other tracks to play:
    trackSolos[deleteInstrumentId] && toggleTrackSolos(deleteInstrumentId);
    
    LoopingAudio.deleteTrack(deleteInstrumentId);
    
    if (index !== -1) {
      setSongData(draft => {
        draft.instruments.splice(index, 1);
      });
      strofeApi.delete(`/instruments/${deleteInstrumentId}`);
    }
  }

  const saveEditingTitle = () => {

    const title = editingTitle.trim();

    if (title !== songTitle && title.length) {
      axios.put(process.env.REACT_APP_NOMODO_API + `/songs/${id}`,
        { song: { title } },
        { headers: { Authorization: `Bearer ${Cookies.get('api_access_token')}` }
      });
    }

    setSongTitle(title);
    setEditingTitle(title);
  }

  // 'increment', 'decrement'
  const saveSectionLoops = (index, op) => {
    const { loop_repetitions } = songData;    
    loop_repetitions[index] = Math.min(Math.max(loop_repetitions[index] + (op === 'increment' ? 1 : -1), 1), 10);
    
    setSongData(draft => {
      draft.loop_repetitions[index] = loop_repetitions[index];
    });

    LoopingAudio.setLoopRepetitions(index, loop_repetitions[index]);
    LoopingAudio.calculateTotalDuration();
    LoopingAudio.changeTotalPlayTime(index, op);

    setDurationWithLoops(LoopingAudio.durationWithLoops);

    debounceUpdateLoops(loop_repetitions);
  }

  const debounceUpdateLoops = useDebouncedCallback((loop_repetitions) => {
    axios.put(process.env.REACT_APP_NOMODO_API + `/songs/${id}`,
      { song: { loop_repetitions } },
      { headers: { Authorization: `Bearer ${Cookies.get('api_access_token')}` }
    });
  }, 2000);

  const changeTrackVelocity = (value, id) => {
    const velocity = value;

    setTrackVelocities({ ...trackVelocities, [id]: velocity });
    LoopingAudio.changeGain(id, velocity);
  }

  const saveTrackVelocity = (velocity, id) => {
    axios.put(process.env.REACT_APP_NOMODO_API + `/instruments/${id}`,
      { instrument: { velocity } },
      { headers: { Authorization: `Bearer ${Cookies.get('api_access_token')}` }
    });
  };

    // TODO -> debounce will cancel other instruments, need to keep an array of updates to flush:
  const debounceUpdateTrackMute = useDebouncedCallback((muted, id) => {
    axios.put(process.env.REACT_APP_NOMODO_API + `/instruments/${id}`,
      { instrument: { muted } },
      { headers: { Authorization: `Bearer ${Cookies.get('api_access_token')}` }
    });
  }, 300);

  const changeSectionMute = (sectionId, muted, instrumentId) => {
    axios.put(process.env.REACT_APP_NOMODO_API + `/song_sections/${sectionId}`,
      { song_section: { muted } },
      { headers: { Authorization: `Bearer ${Cookies.get('api_access_token')}` }
    });

    setSongData(draft => {
      draft.instruments.find(instrument => instrument.id === instrumentId).song_sections.find(section => section.id === sectionId).muted = muted;
    });

    LoopingAudio.songData.instruments.find(instrument => instrument.id === instrumentId).song_sections.find(section => section.id === sectionId).muted = muted;
    LoopingAudio.resetTrackGain(instrumentId);
  }

  const regenerateSection = (sectionId, instrumentId, selectedSequenceId) => {

    setTrackSectionRegenerating( { ...trackSectionRegenerating, [instrumentId]: sectionId });

    axios.put(process.env.REACT_APP_NOMODO_API + `/song_sections/${sectionId}/regenerate`,
      { song_section: {
        note_sequence_id: selectedSequenceId,
      } },
      { headers: { Authorization: `Bearer ${Cookies.get('api_access_token')}` }
    }).then(res => {
      LoopingAudio.loadRevisedTrack(instrumentId, res.data.instrument_mp3_download_url).then(() => {
        setTrackSectionRegenerating(trackSectionRegenerating => ({ ...trackSectionRegenerating, [instrumentId]: undefined }));
      });
    });
  }

  const changeTrackMute = (id) => {
    const muted = !trackMutes[id];

    setTrackMutes({ ...trackMutes, [id]: muted });
    LoopingAudio.setTrackMuted(id, muted);

    debounceUpdateTrackMute(muted, id);
  }

  const toggleTrackSolos = id => {
    const solos = {...trackSolos, [id]: trackSolos[id] ? false : true };
    trackMutes[id] && changeTrackMute(id);
    
    setTrackSolos(solos);
    LoopingAudio.toggleTrackSolo(id);
  }

  const changeInstrumentProgram = async (id, value) => {
    setRegeneratingPrograms({ ...regeneratingPrograms, [id]: true });

    try {
      const res = await strofeApi.put(`/instruments/${id}`, { instrument: { program: parseInt(value, 10) } });
      await LoopingAudio.loadRevisedTrack(res.data.id, res.data.mp3_download_url);

      setSongData(draft => {
        draft.instruments.find(instrument => instrument.id === id).program = value;
      });
    }
    catch {
      setInstrumentError(true);
    }
    finally {
      setRegeneratingPrograms(regeneratingPrograms => ({ ...regeneratingPrograms, [id]: undefined }));
    }
  }

  const regenerateInstrument = async id => {
    setRegeneratingAllSections({ ...regeneratingAllSections, [id]: true });

    const res = await strofeApi.put(`/instruments/${id}/regenerate`, { instrument: { no_op: 'no_op' } });
    
    await LoopingAudio.loadRevisedTrack(res.data.id, res.data.mp3_download_url);
    setRegeneratingAllSections(regeneratingAllSections => ({ ...regeneratingAllSections, [id]: undefined }));
  }

  const selectCategory = category => {
    if (category !== 'drums') {
      setAddInstrumentCategory(category);
      setShowAddInstrument(false);
      setShowSelectInstrument(true);
    }
    else {
      createInstrument(0, category);
    }
  }

  const hideSelectInstrument = () => {
    setShowSelectInstrument(false);
    setAddInstrumentCategory(null);
  }

  const createInstrument = async (program, category = addInstrumentCategory) => {
    const id = crypto.randomUUID();

    const instrument = { id, _creating: true };

    setShowAddInstrument(false);
    setAddInstrumentCategory(null);

    setCreatingTrack(draft => {
      draft[id] = true;
    });

    setSongData(draft => {
      draft.instruments.splice(addInstrumentIndex, 0, instrument);
    });

    const { data } = await strofeApi.post('/instruments/', { instrument: {
      song_id    : songData.id,
      instrument : addInstrumentIndex,
      category,
      program,
    }});

    //! not sure if the volume is playing at the right velocity on load:
    LoopingAudio.addTrack(data);

    setTrackVelocities({ ...trackVelocities, [data.id]: data.velocity });

    setSongData(draft => {
      const index = draft.instruments.findIndex(i => i.id === id);
      draft.instruments[index] = data;
    });

    setCreatingTrack(draft => {
      delete draft[id];
    });
  }

  const handleShare = () => {
    if (currentUser.registered) {
      setShowShareTrack(true)
    }
    else {
      setOptinType('share');
      setShowOptin(true);
    }
  }

  const onOptinUserCreated = () => {
    switch (optinType) {
      case 'share':
        setShowShareTrack(true);
        break;

      case 'download':
        setShowDownload(true);
        break;

      case 'subscribe':
        setShowSubscribe(true);
        break;

      default:
        break;
    }
  }

  const changePermission = permission => {
    const previousPermission = songData.public;
    const isPublic = permission === 'public';

    if (previousPermission !== isPublic) {
      setSongData(draft => {
        draft.public = isPublic;
      });

      strofeApi.put(`/songs/${id}`, { song: { public: isPublic }});
    }
  }

  const regenerateSong = async () => {
    setRegeneratingTrack(true);
    await strofeApi.put(`/songs/regenerate/${id}`);
    
    window.location.reload();
  }

  const marks = {
    0                    : { label: ['playing', 'paused'].includes(LoopingAudio.status) ? LoopingAudio.secsToClockFormat(LoopingAudio.totalPlayTime) : "0:00" },
    [songDuration * 100] : { label: LoopingAudio.secsToClockFormat(durationWithLoops) },
  };

  const loopMarks = {};

  songData?.loops.forEach(([startSection, endSection], index) => {
    const startTime = songData.section_start_times[startSection];
    const endTime = songData.section_start_times[endSection + 1];

    loopMarks[startTime * 100] = { label: LoopingAudio.secsToClockFormat(startTime) };
    loopMarks[endTime * 100] = { label: LoopingAudio.secsToClockFormat(endTime) };
  });
  
  const changeOffset = v => {
    LoopingAudio.stopSong();
    setSeekOffset(v);
  }

  const afterChangeOffset = v => {
    const offset = v / 100;
    LoopingAudio.setSeekLoop(offset);

    if (MULTITRACK) {
      LoopingAudio.playAllTracks(offset, true);
    }
    else {
      LoopingAudio.playTrack({ trackOffset: offset, stopTrack: true });
    }

    // since the interval for setting playbackPosition is 200, debounce the seek reset
    // to avoid having the seek flicker between playbackPosition and seekOffset
    debouncedResetSeekOffset();
    setSongStatus('playing');

    // If the slider element is contained, blur it as the <- / -> arrow keys would cause it to jump in small steps and cannot be overwriten:
    if (document.activeElement && (mobileSeekRef.current?.contains(document.activeElement) || seekRef.current?.contains(document.activeElement))) {
      document.activeElement.blur();
    }
  }

  const debouncedResetSeekOffset = useDebouncedCallback(() => {
    setSeekOffset(null);
  }, 300);

  const toggleLoopsEnabled = index => {
    const loops = [...loopsEnabled];
    loops[index] = !loops[index];
    LoopingAudio.toggleLoop(index);
    setLoopsEnabled(loops)
  }

  const onPurchase = async (_, format) => {
    // first, set as purchased before generating (if generating is needed):
    setSongData(draft => {
      draft.purchased = true;
      draft.generated = false;
  
      if (format) {
        draft[`${format}_purchased`] = true;
      }
    });
  }

  const handleDownload = () => {
    if (songData.purchased) {
      setShowDownload(true);
    }
    else if (currentUser.can_subscribe && subscribeTest?.variant !== 'control' && !currentUser.subscribed) {
      if (currentUser.registered) {
        setShowSubscribe(true);
      }
      else {
        setOptinType('subscribe');
        setShowOptin(true);
      }
    }
    else if (currentUser.registered) {
      setShowDownload(true);
    }
    else {
      setShowOptin(true);
      setOptinType('download');
    }
  }

  const handleReRoll = async () => {
    const { mood, style, style_selected } = songData;
    
    history.push('/create', {
      reroll: true,
      mood,
      style: style_selected ? style : undefined
    });
  }

  const promptAddInstrument = index => {
    if (songData?.instruments.length >= MAX_INSTRUMENTS_PER_TRACK) {
      setShowMaxInstruments(true);
    }
    
    else {
      setAddInstrumentIndex(index);
      setShowAddInstrument(true);
    }
  }

  const initialSelectedInstrument = () => {
    if (addInstrumentCategory === 'bass') {
      return "Upright Bass";
    }
    else if (addInstrumentCategory === 'chords') {
      return "Grand Piano";
    }

    return selectedInstrument.programName;
  }

  const renderPlayer = () => {
    if (loadingStatus === 'fetch-mp3-error') {
      return (
        <div className='text-center'>
          <h4 className='load-error'><Translate i18nKey='track-load-mp3-error' /></h4>
          <Link to='/'><Translate>Back to Strofe</Translate></Link>
        </div>
      )
    }

    if (loadingStatus === 'fetch-error') {
      return (
        <div className='text-center'>
          <h4 className='load-error'><Translate i18nKey='track-load-error' /></h4>
          <Link to='/'><Translate>Back to Strofe</Translate></Link>
        </div>
      )
    }

    if (loadingStatus === 'ready') {

      const findGenerating = obj => Object.values(obj).find(item => item !== undefined);

      const trackRegenerating = findGenerating(regeneratingPrograms) || findGenerating(trackSectionRegenerating)  || findGenerating(regeneratingAllSections) || findGenerating(creatingTrack);
      
      return (
        <div>
          { id === 'test' && <pre align='center'>[ PLAYING TEST MIDI ]</pre> }

          <div className='title-bar'>
            <div className='title'>
              { <MoodStyleIcon mood={songData.mood} width={36} height={36} /> }
              { <MoodStyleIcon genre={songData.style} width={36} height={36} /> }
              <div className='title-edit'>
                <Form className='song-title-container' onSubmit={onTitleFormSubmit}>
                  <Form.Control ref={titleRef} className='song-title' value={editingTitle} onChange={e => setEditingTitle(e.target.value)} onBlur={saveEditingTitle} />
                  <PencilIcon />
                </Form>
              </div>
            </div>
            
            <EditorEqualizer status={songStatus === 'playing' && seekOffset === null ? 'playing' : 'paused'} />
            
            <div className='track-share'>
              { currentUser.registered && <PermissionsDropdown trackPermission={songData.public ? 'public' : 'private'} onChange={changePermission} /> }
              <Button className='share-button' variant='success' onClick={handleShare}>SHARE</Button>
            </div>
          </div>

          <div className='mobile-song-player'>
            <Equalizer status={songStatus === 'playing' ? 'playing' : 'paused'} onClick={togglePlay} />
            <div ref={mobileSeekRef}>
              <Slider className='playback' min={0} max={songDuration * 100} value={seekPosition} marks={{ ...loopMarks, ...marks }} onChange={changeOffset} onAfterChange={afterChangeOffset} handle={seekSliderHandle} />
            </div>
          </div>

          { renderTrackEditor() }
          <button className='download-button' disabled={trackRegenerating} onClick={handleDownload}>Download</button>

          <button onClick={() => setShowReRoll(true)} disabled={trackRegenerating} className='regenerate-song'>
            <span>
              <RerollIcon className='reroll-icon' />
              <Translate>Reroll</Translate>
            </span>
          </button>

          { showComposer && <button className='regenerate-song' onClick={regenerateSong}><Translate>Regenerate Track</Translate></button> }

          <Modal className='__modal' show={regeneratingTrack} backdrop='static' size='sm'>
            <Modal.Header><Translate>Regenerating Track</Translate></Modal.Header>
            <Modal.Body>
              <div className='text-center'>
                <InfiniteLoader className='my-2' width={32} height={32} />
                <p><Translate>Please wait...</Translate></p>
              </div>
            </Modal.Body>
          </Modal>

          <Link className='new-song' to='/create'>Create another song</Link>
          <RegistrationModal show={showOptin} onClose={closeOptinModal} from={optinType} onUserCreated={onOptinUserCreated} />
        </div>
      );
    }
    else {
      return (
        <div className='loading-track'>
          <LoadingBlocks>
            <Translate>Loading Track...</Translate>
          </LoadingBlocks>
        </div>
      )
    }
  }

  const renderTrackEditor = () => {

    if (!songData || !songDuration) {
      return null;
    }
  
    const startTimes = songData.section_start_times;
    const sectionLengths = [];
  
    let gridTemplateColumns = "auto"; // first column is instrument;
  
    let i;
  
    for (i = 1; i < startTimes.length; ++i) {
      const previousLength = i === 1 ? 0 : sectionLengths[i-2];
      gridTemplateColumns += ` ${startTimes[i] - startTimes[i-1]}fr`;
      
      sectionLengths.push(startTimes[i] - startTimes[i-1] + previousLength);
    }
  
    gridTemplateColumns += ` ${songDuration - startTimes[i-1]}fr`;
    sectionLengths.push(songDuration - startTimes[i-1] + sectionLengths[i-2]);
  
    const hasSoloTrack = (Object.values(trackSolos).some(isSolo => isSolo));
  
    let currentSection = 0;
  
    for (let i = 0; i < startTimes.length; ++i) {
      if (playbackPosition > sectionLengths[i]) {
        currentSection = i + 1;
      }
    }

    const rowRefIndex = songData?.instruments.findIndex(instrument => !instrument._creating);

    return (
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <div className='__track-editor'>

          <div className='__track-editor-grid' style={{ gridTemplateColumns }}>
    
            <div className='main-controls'>
              <MasterVolume />

              <div onClick={togglePlay} role='button' data-test='TRACK-EDITOR-toggle-play'>
              { songStatus === 'playing'
              ? <div className='play-control pause-song'><PauseIconV2 /></div>
              : <div className='play-control play-song'><PlayIconV2 /></div>
              }
              </div>
            </div>
            <div className="playback-seek">
              <div ref={seekRef}>
                <Slider className={classNames('editor-playback', {'seeking': seekOffset !== null })} min={0} max={songDuration * 100} value={seekPosition} marks={marks} onChange={changeOffset} onAfterChange={afterChangeOffset} />
              </div>
            </div>
            
            { process.env.NODE_ENV === 'development' && process.env.REACT_APP_SHOW_SONG_DATA === 'true' && (
              <>
                <div>SECTIONS</div>
                { songData.section_start_times.map((time, index) => (
                  <pre key={index} className='text-center' style={{ border: '1px solid gray', padding: 4, fontSize: 11 }}>
                    { Math.round(time * 100, 2) / 100 } <br /> #{index}
                  </pre>
                ))}
              </>
            )}
    
            {/* TODO -> memoize and calculate percentages only once */}
            <SeparatorRow onClickAdd={() => promptAddInstrument(0)} />

            <SortableContext items={songData?.instruments} strategy={verticalListSortingStrategy}>
              { songData?.instruments.map((instrument, instrumentIndex) => (
                <InstrumentRow key={instrument.id} instrument={instrument} trackMutes={trackMutes} trackSolos={trackSolos} changeSectionMute={changeSectionMute} regenerateSection={regenerateSection} showComposer={showComposer} noteSequences={noteSequences} songData={songData} instrumentIndex={instrumentIndex} currentSection={currentSection} startTimes={startTimes} trackSectionRegenerating={trackSectionRegenerating} regeneratingPrograms={regeneratingPrograms} regeneratingAllSections={regeneratingAllSections} songDuration={songDuration} hasSoloTrack={hasSoloTrack} changeTrackMute={changeTrackMute} regenerateInstrument={regenerateInstrument} toggleTrackSolos={toggleTrackSolos} trackVelocities={trackVelocities} changeTrackVelocity={changeTrackVelocity} saveTrackVelocity={saveTrackVelocity} sectionLengths={sectionLengths} playbackPosition={playbackPosition} narrowSections={narrowSections} changeInstrumentProgram={changeInstrumentProgram} currentUser={currentUser} onClickSelectInstrument={onClickSelectInstrument} onClickDelete={onClickDelete} promptAddInstrument={promptAddInstrument} trackRowRef={rowRefIndex === instrumentIndex ? trackRowRef : null} />
              ))}
            </SortableContext>

            { songData.loops.map(([startSection, endSection], index) => {
    
              const activeLoop = (playbackPosition > songData.section_start_times[startSection] && playbackPosition < songData.section_start_times[endSection + 1]);
              const loopRepetitions = songData.loop_repetitions[index];

              return (
                <div key={index} className='loop-marker' style={{ gridColumn: `${startSection + 2} / ${endSection + 3}` }}>
                  <div role='button' className={classNames('loop-marker-button', { 'loop-enabled': loopsEnabled[index] })} onClick={() => toggleLoopsEnabled(index)}>
                    <InfinityIcon className='loop-icon' />
                  </div>
                  
                  <div className={classNames('loop-counter', { 'show-buttons': activeLoop })}>
                    <div className={classNames('edit-count', { disabled: loopRepetitions === 1 })} role='button' onClick={() => saveSectionLoops(index, 'decrement')}> – </div>
                    { activeLoop
                    ? <div>{ loopsPlayed + 1 } / { loopRepetitions }</div>
                    : <div>x{ loopRepetitions }</div>
                    }
                    <div className={classNames('edit-count', { disabled: loopRepetitions === 10 })} role='button' onClick={() => saveSectionLoops(index, 'increment')}> + </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </DndContext>
    );  
  }

  return (
    
      <div className={classNames({ '__song-player-multitrack': MULTITRACK })}>
        { currentUser && <NavigationHeader /> }
        <div className='__song-player'>
          { renderPlayer() }
        </div>

        <DownloadTrackFlow show={showDownload} song={songData} onHide={() => closeDownloadModal()} onPurchase={onPurchase} />
        <ShareTrackModal show={showShareTrack} onClose={() => setShowShareTrack(false)} onMakePublic={() => changePermission('public')} isPublic={songData?.public} songId={id} />
        <ReRollModal show={showReRoll} onHide={() => setShowReRoll(false)} onCreate={handleReRoll} />
        <SubscribeModal show={showSubscribe} onHide={() => setShowSubscribe(false)} hasTrial={!currentUser?.unsubscribed && subscribeTest?.variant.includes('trial')} />
        <MaxInstrumentsModal show={showMaxInstruments} onHide={() => setShowMaxInstruments(false)} />

        <InstrumentSelectorModal show={showSelectInstrument} onHide={hideSelectInstrument} onSubmit={submitInstrumentSelect} initialSelected={initialSelectedInstrument()} drums={selectedInstrument.drums} />
        <DeleteInstrumentModal show={showDeleteInstrument} onHide={() => setShowDeleteInstrument(false)} onSubmit={onDeleteInstrument} />
        <InstrumentCategoryModal from="add" show={showAddInstrument} onHide={() => setShowAddInstrument(false)} onSubmit={selectCategory} />
        
        <Toast show={instrumentError} className='strofe-toast' autohide delay={3000} onClose={() => setInstrumentError(null)}>
          <Toast.Body><Translate i18nKey='instrument-change-error' /></Toast.Body>
        </Toast>
      </div>
  );
}
export default function PopOutline(props) {
  return (
    <svg
      id="__pop-outline-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 114.72 112.72"
      {...props}
    >
      <defs>
        <style>
          {
            ".__pop-outline-prefix__cls-1{fill:none;stroke:#cfd4d8;stroke-miterlimit:10;stroke-width:2px}.__pop-outline-prefix__cls-2{fill:#cfd4d8}"
          }
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 27 copy"}</title>
      <rect
        className="__pop-outline-prefix__cls-1"
        x={1.89}
        y={1}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
      />
      <path
        className="__pop-outline-prefix__cls-1"
        d="M81.25 98.14a7.42 7.42 0 01-9.8-3.7l-5.88-13a.48.48 0 11.88-.4l5.88 13a6.45 6.45 0 1011.75-5.31L76 71a7.42 7.42 0 013.7-9.8l.75-.34a7.42 7.42 0 019.8 3.7l2.15 4.75a.48.48 0 01-.88.4l-2.15-4.75a6.45 6.45 0 00-8.53-3.22l-.75.34a6.45 6.45 0 00-3.22 8.53l8 17.77a7.42 7.42 0 01-3.62 9.76z"
      />
      <path
        className="__pop-outline-prefix__cls-1"
        d="M36.58 35.65l24 44.28 4.07-1.85 4.08-1.84-17.36-47.28-7.39 3.35-7.4 3.34z"
      />
      <path
        className="__pop-outline-prefix__cls-2"
        d="M50.94 25.56l-8.32 3.76-8.32 3.76a1.15 1.15 0 001 2.1l8.32-3.76 8.32-3.76a1.15 1.15 0 00-1-2.1z"
      />
      <path
        className="__pop-outline-prefix__cls-1"
        d="M50.93 24.55a12.32 12.32 0 00-.65-1.81 9.68 9.68 0 10-17.64 8 7.88 7.88 0 001 1.65zM61.08 81l8-3.6s.36 2.32-3.05 3.86-4.95-.26-4.95-.26z"
      />
      <circle
        className="__pop-outline-prefix__cls-2"
        cx={48.71}
        cy={42.94}
        r={2.32}
        transform="rotate(-24.31 48.709 42.944)"
      />
      <circle
        className="__pop-outline-prefix__cls-2"
        cx={51.62}
        cy={49.38}
        r={2.32}
        transform="rotate(-24.31 51.623 49.377)"
      />
      <path
        className="__pop-outline-prefix__cls-2"
        d="M75.86 33L73 35.74a1.32 1.32 0 00-.29 1.52l1.68 3.58a1.32 1.32 0 01-1.83 1.71l-3.46-1.91a1.32 1.32 0 00-1.54.19l-2.88 2.7a1.32 1.32 0 01-2.2-1.21l.75-3.88a1.32 1.32 0 00-.66-1.4l-3.46-1.91a1.32 1.32 0 01.47-2.46l3.92-.49a1.32 1.32 0 001.13-1.06l.75-3.88a1.32 1.32 0 012.49-.31l1.68 3.58a1.32 1.32 0 001.36.75l3.92-.49A1.32 1.32 0 0175.86 33zM40.7 65.6l.5 3a1 1 0 00.84.83l3 .44a1 1 0 01.32 1.89l-2.67 1.39a1 1 0 00-.53 1.06l.5 3A1 1 0 0141 78l-2.15-2.11a1 1 0 00-1.17-.17L35 77.15a1 1 0 01-1.37-1.34l1.34-2.7a1 1 0 00-.2-1.17l-2.15-2.11a1 1 0 01.85-1.71l3 .44a1 1 0 001-.55l1.34-2.7a1 1 0 011.89.29z"
      />
    </svg>
  )
}

import { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { resetAccessToken } from '../../api/strofeApi';
import LoginModal from '../../registration/LoginModal';
import ForgotPasswordModal from '../../registration/ForgotPasswordModal';
import { Trans as Translate } from 'react-i18next';

import { EventTracker } from '../../utils/Tracking';
import { MOODS } from '../CreateSong/CreateSong';

// Assets
import StrofeLogoColors from '../../icons/StrofeLogoColors';
import StrofeLogoWhite from '../../icons/StrofeLogoWhite';

import LPFigures1 from './assets/LPFigures1';
import LPFigures2 from './assets/LPFigures2';
import LPFigures3 from './assets/LPFigures3';
import LPBottomDivider from './assets/LPBottomDivider';
import LPCurve1 from './assets/LPCurve1';
import LPCurve2 from './assets/LPCurve2';
import LPCurve3 from './assets/LPCurve3';
import LPSemiCircle from './assets/LPSemiCircle';
import LPTriangle from './assets/LPTriangle';
import LPTopDivider from './assets/LPTopDivider';

import './LandingPage.scss';

export default function LandingPage() {

  const history = useHistory();
  const location = useLocation();

  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [registering, setRegistering] = useState(false);

  const getPhrase = phrase => {
    return <Translate i18nKey={`lp-${phrase}`} />
  }
  
  const handleRegister = async redirectTo => {

    if (registering) {
      return;
    }
    
    EventTracker.getStarted();

    setRegistering(true);

    const referrer = Cookies.get('strofe_referrer');
    const inviter_display_name = Cookies.get('strofe_inviter_display_name');

    try {
      const { data } = await axios.post(process.env.REACT_APP_NOMODO_API + '/registrations', {
        client_id : process.env.REACT_APP_NOMODO_API_CLIENT_ID,
        registration: (referrer || inviter_display_name) ? { referrer, inviter_display_name } : undefined,
      });

      Cookies.set('api_access_token', data.access_token, { expires: 60 });
      Cookies.set('strofe_user_id', data.user_id, { expires: 60 });
      Cookies.remove('strofe_referrer');
      Cookies.remove('strofe_inviter_display_name');

      resetAccessToken(data.access_token);
      
      history.push(redirectTo || location.state?.from || '/');

    } catch(error) {
      console.log(error);
    }
  }
  
  if (Cookies.get('api_access_token')?.length > 0) {
    return <Redirect to={{ pathname: '/'}} />
  }

  const handleLogin = () => {
    setShowLogin(true);
  }

  const renderMoodIcon = mood => {
    const Icon = mood.icon;

    return (
      <div key={mood.id} className='mood-button' role='button' data-test={`LP-mood-${mood.id}`} onClick={() => handleRegister(`/create/${mood.id}`)} >
        <Icon />
        <span><Translate>{ mood.phrase }</Translate></span>
      </div>
    );
  }

  const onForgotPassword = () => {
    setShowLogin(false);
    setShowForgotPassword(true);
  }

  return (
    <div className='__landing-page'>
      <div className='login'>
        <div className='login-container'>
          <StrofeLogoColors className='strofe-logo' viewBox="200 500 1600 1100" />
          <h2><Translate>Make Your Own Music In Seconds</Translate></h2>
          <div className='button-container'>
            <button className="action-button" onClick={handleRegister} data-test="LP-register-top"><div className='arrow-right' /><Translate>Get started</Translate></button>
            <button className="action-button" onClick={handleLogin} data-test="LP-login"><Translate>Login</Translate></button>
          </div>
        </div>

        <LoginModal show={showLogin} from={location.state?.from} onClose={() => setShowLogin(false)} onForgotPassword={onForgotPassword} />
        <ForgotPasswordModal show={showForgotPassword} onClose={() => setShowForgotPassword(false)} />
      </div>

      <div className='copy-text'>
        <LPTopDivider className='top-divider' />

        <div className='copy-mobile'>
          <p>{ getPhrase('what-is-strofe') }</p>
          <p>{ getPhrase('purpose') }</p>
          <p>{ getPhrase('simple-tool') }</p>
          <p>{ getPhrase('easy-usage') }</p>
        </div>
        
        <div className='copy-grid'>
          
          <div className='copy-what-is'>
            <LPFigures1 className='svg-bg' />
            <div className='text'>{ getPhrase('what-is-strofe') }</div>
          </div>
          <div className='figures-2'>
            <LPFigures2 className='svg-bg' />
          </div>

          <div className='curve-1'>
            <LPCurve1 viewBox="0 100 360 160" />
          </div>
          <div className='copy-purpose'>
            <div className='text'>{ getPhrase('purpose') }</div>
          </div>
          
          <div className="copy-simple-tool">{ getPhrase('simple-tool') }</div>

          <div className='curve-2'>
            <LPCurve2 className='line' viewBox="100 0 360 512.5" />
            <LPFigures3 className='svg-bg' />
          </div>
          <div className='curve-3'>
            <LPCurve3 className='line' />
            <LPSemiCircle className='svg-bg' />
          </div>
          <div className='copy-easy-usage'>
            <div className='copy'>{ getPhrase('easy-usage') }</div>
            <LPTriangle className='svg-bg' />
          </div>
        </div>

        <div className='mood-info'>
          <div className='mood-title'>{ getPhrase('mood-title') }</div>

          <div className='mood-icons'>
            { MOODS.map(mood => renderMoodIcon(mood)) }
          </div>
          
          <div className='cta'>
            <button className="action-button bigger" onClick={handleRegister}><div className='arrow-right' /><Translate>Get started</Translate></button>
          </div>
        </div>

        
        <LPBottomDivider className='bottom-divider' />
      </div>

      <div className='lp-footer'>
        <StrofeLogoWhite className='logo' />

        <div className='links'>
          <Link to='/privacy' data-test="LP-privacy-policy"><Translate>Privacy Policy</Translate></Link>
          <Link to='/terms' data-test="LP-terms"><Translate>Terms</Translate></Link>
        </div>

      </div>
    </div>
  );
}

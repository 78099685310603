import * as React from "react";
const SvgInstrumentDrums2 = (props) => (
  <svg
    id="InstrumentDrums2_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentDrums2_svg__st100{fill:#fdc676}.InstrumentDrums2_svg__st101{fill:#4b4a9e}.InstrumentDrums2_svg__st102{fill:#528459}.InstrumentDrums2_svg__st103{fill:#c55a74}.InstrumentDrums2_svg__st105{fill:#c6cf3e}"
      }
    </style>

    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#f48864",
      }}
    />
    <path
      className="InstrumentDrums2_svg__st102"
      d="M31.95 58.1c-8.65 0-15.66-3.52-15.66-7.86v22.19s1.6 9.21 16.24 9.21 15.09-9.21 15.09-9.21V50.24c-.01 4.34-7.02 7.86-15.67 7.86z"
    />
    <ellipse
      className="InstrumentDrums2_svg__st105"
      cx={31.95}
      cy={50.24}
      rx={15.66}
      ry={7.86}
    />
    <path
      className="InstrumentDrums2_svg__st101"
      d="M64.99 62.01 44.8 59.76c7.18 0 13 8.12 13 18.14 0 9.05-4.75 16.56-10.97 17.92l18.55-2.03s7.13-2.29 7.13-15.89-7.52-15.89-7.52-15.89z"
    />
    <path
      className="InstrumentDrums2_svg__st101"
      d="M44.8 59.76c-8.1 0-14.67 8.12-14.67 18.14S36.7 96.04 44.8 96.04l2.29-.22c7.01-1.36 12.38-8.87 12.38-17.92 0-10.02-6.57-18.14-14.67-18.14z"
    />
    <ellipse
      className="InstrumentDrums2_svg__st100"
      cx={44.8}
      cy={77.9}
      rx={12.85}
      ry={15.89}
    />
    <ellipse
      transform="rotate(-70.61 20.912 32.919)"
      className="InstrumentDrums2_svg__st101"
      cx={20.91}
      cy={32.92}
      rx={2.83}
      ry={13.09}
    />
    <path
      className="InstrumentDrums2_svg__st101"
      d="M20.17 87.99c-.31 0-.55-.25-.55-.55V33.47c0-.31.25-.55.55-.55.31 0 .55.25.55.55v53.96c0 .31-.24.56-.55.56z"
    />
    <path
      className="InstrumentDrums2_svg__st101"
      d="m11.5 91.41 8.31-16.46c.14-.28.07-.65-.15-.83l-.04-.03c-.23-.18-.52-.09-.66.19l-8.31 16.46c-.14.28-.07.65.15.83l.04.03c.22.18.52.09.66-.19zM28.92 91.41 20.6 74.95c-.14-.28-.07-.65.15-.83l.04-.03c.23-.18.52-.09.66.19l8.31 16.46c.14.28.07.65-.15.83l-.04.03c-.21.18-.51.09-.65-.19z"
    />
    <path
      className="InstrumentDrums2_svg__st105"
      d="m61.33 89.46 4.82 7.64c.18.28.09.65-.19.83l-.04.03c-.28.18-.65.09-.83-.19l-4.53-7.18.77-1.13zM34.42 90.71l-3.46 5.49c-.18.28-.09.65.19.83l.04.03c.28.18.65.09.83-.19l3.26-5.17-.86-.99z"
    />
    <path
      className="InstrumentDrums2_svg__st101"
      d="m16.05 80.04-2.29.22c.78 0 1.54-.07 2.29-.22z"
    />
    <path
      d="M80.37 60.49c-6.81 0-12.33-1.57-12.33-3.51v9.92s1.26 4.12 12.78 4.12S92.7 66.9 92.7 66.9v-9.92c0 1.93-5.52 3.51-12.33 3.51z"
      style={{
        fill: "#f8c4b9",
      }}
    />
    <ellipse
      className="InstrumentDrums2_svg__st105"
      cx={80.37}
      cy={56.97}
      rx={12.33}
      ry={3.51}
    />
    <path
      className="InstrumentDrums2_svg__st103"
      d="M43.22 44.72c-5.82-3.14-9.48-7.65-8.18-10.07l-6.67 12.39s-1.69 5.72 8.16 11.03 12.93.33 12.93.33l6.67-12.39c-1.31 2.43-7.09 1.85-12.91-1.29z"
    />
    <ellipse
      transform="rotate(-61.684 45.586 40.335)"
      className="InstrumentDrums2_svg__st100"
      cx={45.58}
      cy={40.33}
      rx={4.99}
      ry={11.97}
    />
    <path
      className="InstrumentDrums2_svg__st103"
      d="M68.43 48.14c-6.13 2.96-12.1 3.3-13.33.74l6.31 13.04s3.75 4.87 14.12-.15 8.08-10.58 8.08-10.58L77.3 38.15c1.23 2.55-2.74 7.02-8.87 9.99z"
    />
    <ellipse
      transform="rotate(-25.799 66.194 43.516)"
      className="InstrumentDrums2_svg__st100"
      cx={66.19}
      cy={43.51}
      rx={12.33}
      ry={5.13}
    />
    <ellipse
      transform="rotate(-17.254 76.11 20.393)"
      className="InstrumentDrums2_svg__st101"
      cx={76.1}
      cy={20.39}
      rx={11.64}
      ry={1.83}
    />
    <path
      className="InstrumentDrums2_svg__st101"
      d="M77.54 89.82a.56.56 0 0 1-.56-.56V22.23a.56.56 0 1 1 1.12 0v67.03c0 .31-.25.56-.56.56z"
    />
    <path
      className="InstrumentDrums2_svg__st101"
      d="m65.9 93.52 11.2-17.76c.19-.3.1-.7-.2-.9l-.05-.03a.66.66 0 0 0-.9.2l-11.2 17.76c-.19.3-.1.7.2.9l.05.03c.31.19.71.1.9-.2zM89.13 93.52l-11.2-17.76c-.19-.3-.1-.7.2-.9l.05-.03c.3-.19.7-.1.9.2l11.2 17.76c.19.3.1.7-.2.9l-.05.03c-.31.19-.71.1-.9-.2z"
    />
    <path
      className="InstrumentDrums2_svg__st102"
      d="M87.75 87.32h-.09c-.23 0-.41-.18-.41-.41V42.95c0-.23.18-.41.41-.41h.09c.23 0 .41.18.41.41v43.96c-.01.23-.19.41-.41.41z"
    />
    <path
      className="InstrumentDrums2_svg__st102"
      d="m82.55 85.65 4.94-14.54c.12-.36.07-.85-.13-1.08l-.22-.25c-.2-.23-.46-.12-.58.24l-4.94 14.54c-.12.36-.07.85.13 1.08l.22.25c.2.23.46.12.58-.24zM92.85 85.65 87.91 71.1c-.12-.36-.07-.85.13-1.08l.22-.25c.2-.23.46-.12.58.24l4.94 14.54c.12.36.07.85-.13 1.08l-.22.25c-.2.24-.46.13-.58-.23z"
    />
    <ellipse
      className="InstrumentDrums2_svg__st102"
      cx={87.76}
      cy={43.63}
      rx={12.01}
      ry={2.11}
    />
    <ellipse
      className="InstrumentDrums2_svg__st103"
      cx={87.55}
      cy={40.44}
      rx={12.01}
      ry={2.11}
    />
  </svg>
);
export default SvgInstrumentDrums2;


import { useEffect } from 'react';

export default function Roblox() {
  useEffect(() => {
    window.location.href = "https://forms.gle/LqZz8HKsAcpPP4mv6";
  }, []);

  return (
    <></>
  );
}

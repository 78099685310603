import * as React from "react";

const InstrumentHarp = (props) => (
  <svg
    id="InstrumentHarp_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.47 110.1"
    style={{
      enableBackground: "new 0 0 110.47 110.1",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentHarp_svg__st3{fill:#4e4092}.InstrumentHarp_svg__st4{fill:#f18860}.InstrumentHarp_svg__st7{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>
    
    <path
      className="InstrumentHarp_svg__st3"
      d="M93.91 110.1H16.93C7.78 110.1.37 102.69.37 93.54V16.56C.37 7.41 7.79 0 16.93 0h76.98c9.15 0 16.56 7.41 16.56 16.56v76.98c0 9.15-7.41 16.56-16.56 16.56z"
    />
    <path
      className="InstrumentHarp_svg__st4"
      d="M46.23 90h-1.65c-1.34 0-2.42-1.08-2.42-2.42V15.31c0-1.34 1.08-2.42 2.42-2.42h4.07v74.69c0 1.33-1.09 2.42-2.42 2.42z"
    />
    <path
      d="M48.65 12.89v10.46c0-.37 2.66-.86 3.03-.88 1.54-.09 3.13.52 4.34 1.3 3.03 1.96 5.11 5.51 6.19 8.57.65 1.86 1.17 3.77 2.24 5.48.96 1.52 2.85 3.48 5.04 3.44 3.93-.08 7.09-3.1 9.64-5.34.79-.69 1.25-1.31 2.32-1.66-.09.03-1.03 2.91-1.16 3.2-.64 1.42-1.31 2.83-1.98 4.23-1.91 3.98-3.88 7.93-5.87 11.89-4.44 8.85-8.96 17.67-13.5 26.48-1.71 3.32-3.42 6.64-5.15 9.95h6.59l24.37-53.45s1.84-3.98-2.11-5.69c-3.95-1.71-7.64 6.14-12.25 4.09-2.6-1.15-2.89-11.06-6.98-16.83-3.17-4.47-9.65-5.24-14.76-5.24z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      className="InstrumentHarp_svg__st7"
      d="M51.71 22.69v63.77M55.42 23.65v62.8M59.13 27.16v52.19M62.83 34.86v37.45M66.54 40.15v24.83M70.25 41.2v16.57M73.95 40.15v10.18M77.31 37.75v5.98"
    />
    <path
      className="InstrumentHarp_svg__st3"
      d="M49.37 31.33h-7.82c-.64 0-1.16-.52-1.16-1.16v-.34c0-.64.52-1.16 1.16-1.16h7.82c.64 0 1.16.52 1.16 1.16v.34c0 .64-.52 1.16-1.16 1.16zM49.37 76.89h-7.82c-.64 0-1.16-.52-1.16-1.16v-.34c0-.64.52-1.16 1.16-1.16h7.82c.64 0 1.16.52 1.16 1.16v.34c0 .64-.52 1.16-1.16 1.16z"
    />
    <path
      className="InstrumentHarp_svg__st4"
      d="M70.85 94.49h-35.7c-1.7 0-3.08-1.38-3.08-3.08v-1.67c0-1.7 1.38-3.08 3.08-3.08h35.7c1.7 0 3.08 1.38 3.08 3.08v1.67c0 1.71-1.38 3.08-3.08 3.08z"
    />
  </svg>
);

export default InstrumentHarp;

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";
import SlideProblemBullet from "../assets/SlideProblemBullet";

export default function SlideProblem() {
  return (
    <div className='slide slide-problem white-bg'>
      
      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />
      
      <div className='slide-green-edge' />

      <div className='bullet-title'>
        <SlideProblemBullet className='problem' />
        <h1>Content creators struggle with licensing</h1>
      </div>

      <div className='content'>
        <p><strong>Content Creators need music</strong> but licensing is expensive and difficult.</p>
        <p><strong>They are unable to evade music copyright</strong> online.</p>
        <p><strong>Creators want music that adapts to their content,</strong> not unchangeable stock music.</p>
      </div>
    </div>
  )
}

import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Cookies from 'js-cookie';

export function bugsnagEnabled() {
  return process.env.NODE_ENV !== 'development' || process.env.REACT_APP_ENABLE_BUGSNAG_DEV === 'true';
}

export function bugsnagErrorBoundary() {
  if (bugsnagEnabled()) {
    Bugsnag.start({
      apiKey  : process.env.REACT_APP_BUGSNAG_API_KEY,
      plugins : [new BugsnagPluginReact()],
      onError: event => event.setUser(Cookies.get('strofe_user_id')),
    });
    
    return Bugsnag.getPlugin('react').createErrorBoundary(React);
  }
}

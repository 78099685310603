export default function StarIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.1 26.1" {...props}>
      <path
        d="M15.5 1.6l1.8 5.6c.3.8 1 1.4 1.9 1.4h5.9c1.9 0 2.7 2.4 1.2 3.6l-4.8 3.5c-.7.5-1 1.4-.7 2.2l1.8 5.6c.6 1.8-1.5 3.3-3 2.2l-4.8-3.5c-.7-.5-1.6-.5-2.3 0l-4.8 3.5c-1.5 1.1-3.6-.4-3-2.2l1.8-5.6c.3-.8 0-1.7-.7-2.2L1 12.1C-.5 11 .3 8.5 2.2 8.5h5.9C9 8.5 9.7 8 10 7.1l1.8-5.6c.5-1.7 3.1-1.7 3.7.1z"
        fill="#ced025"
      />
    </svg>
  );
}

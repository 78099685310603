import * as React from "react";

const InstrumentBottleBlows = (props) => (
  <svg
    id="InstrumentBottleBlows_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.1 110.1"
    style={{
      enableBackground: "new 0 0 110.1 110.1",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentBottleBlows_svg__st69,.InstrumentBottleBlows_svg__st70{fill:#4e4092;stroke:#cfd125;stroke-width:.4857;stroke-miterlimit:10}.InstrumentBottleBlows_svg__st70{fill:#cfd125}"
      }
    </style>
    <path
      d="M93.54 110.1H16.56C7.41 110.1 0 102.69 0 93.54V16.56C0 7.41 7.41 0 16.56 0h76.98c9.15 0 16.56 7.41 16.56 16.56v76.98c0 9.15-7.41 16.56-16.56 16.56z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      className="InstrumentBottleBlows_svg__st69"
      d="M14.46 66.23c.07 5.03.17 10.07.28 15.1.02.91.04 1.82.07 2.72 0 0 .53 1.31 7.16 1.31s7.16-1.31 7.16-1.31c.02-.91.04-1.82.07-2.72.12-5.03.21-10.06.28-15.1H14.46zM37.79 57.59c0 .56.01 1.12.01 1.68.06 7.35.19 14.7.36 22.05.02.91.04 1.82.07 2.72 0 0 .53 1.31 7.16 1.31s7.16-1.31 7.16-1.31c.02-.91.04-1.82.07-2.72.17-7.35.31-14.7.36-22.05 0-.56.01-1.12.01-1.68h-15.2zM75.96 47.3H60.89c0 .37-.01.74-.01 1.12-.08 3.62-.06 7.24-.03 10.85.06 7.35.19 14.7.36 22.05.02.91.04 1.82.07 2.72 0 0 .53 1.31 7.16 1.31s7.16-1.31 7.16-1.31c.02-.91.04-1.82.07-2.72.17-7.35.31-14.7.36-22.05.03-3.62.05-7.24-.03-10.85-.04-.38-.04-.75-.04-1.12zM99.73 48.42c-.03-1.38.03-2.75-.33-4.1-.27-.99-.97-1.76-1.4-2.68-.37-.8-.68-1.64-.83-2.51-.14-.87-.13-1.75-.13-2.63v-1.88H87.3v1.88c0 .88.02 1.76-.13 2.63-.14.87-.46 1.71-.83 2.51-.43.92-1.13 1.69-1.4 2.68-.36 1.35-.3 2.72-.33 4.1-.08 3.62-.06 7.24-.03 10.85.06 7.35.19 14.7.36 22.05.02.91.04 1.82.07 2.72 0 0 .53 1.31 7.16 1.31s7.16-1.31 7.16-1.31c.02-.91.04-1.82.07-2.72.17-7.35.31-14.7.36-22.05.03-3.62.05-7.24-.03-10.85z"
    />
    <path
      className="InstrumentBottleBlows_svg__st70"
      d="M29.52 48.42c-.03-1.38.03-2.75-.33-4.1-.27-.99-.97-1.76-1.4-2.68-.37-.8-.68-1.64-.83-2.51-.14-.87-.13-1.75-.13-2.63v-5.65h.19c.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81h-.19v-4.05h-9.74v4.05h-.06c-.45 0-.81.36-.81.81 0 .45.36.81.81.81h.06v5.65c0 .88.02 1.76-.13 2.63-.14.87-.46 1.71-.83 2.51-.43.92-1.13 1.69-1.4 2.68-.36 1.35-.3 2.72-.33 4.1-.08 3.62-.06 7.24-.03 10.85.02 2.32.05 4.64.08 6.96h15.01c.03-2.32.06-4.64.08-6.96.04-3.62.06-7.24-.02-10.85zM52.94 48.42c-.03-1.38.03-2.75-.33-4.1-.27-.99-.97-1.76-1.4-2.68-.37-.8-.68-1.64-.83-2.51-.14-.87-.13-1.75-.13-2.63v-5.65h.08c.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81h-.08v-4.05h-9.74v4.05h-.18c-.45 0-.81.36-.81.81 0 .45.36.81.81.81h.18v5.65c0 .88.02 1.76-.13 2.63-.14.87-.46 1.71-.83 2.51-.43.92-1.13 1.69-1.4 2.68-.36 1.35-.3 2.72-.33 4.1-.07 3.06-.06 6.11-.05 9.17h15.19c.04-3.06.05-6.12-.02-9.17zM75.64 44.31c-.27-.99-.97-1.76-1.4-2.68-.37-.8-.68-1.64-.83-2.51-.14-.87-.13-1.75-.13-2.63v-5.65h.13c.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81h-.13v-4.05h-9.74v4.05h-.13c-.45 0-.81.36-.81.81 0 .45.36.81.81.81h.13v5.65c0 .88.02 1.76-.13 2.63-.14.87-.46 1.71-.83 2.51-.43.92-1.13 1.69-1.4 2.68-.27.99-.3 1.98-.32 2.99h15.07c.01-1-.02-2-.29-2.99z"
    />
    <path
      d="M97.25 29.22h-.2v-4.05h-9.74v4.05h-.06c-.45 0-.81.36-.81.81 0 .45.36.81.81.81h.06v3.77h9.74v-3.77h.2c.45 0 .81-.36.81-.81 0-.44-.36-.81-.81-.81z"
      style={{
        fill: "#cfd125",
        stroke: "#cfd125",
        strokeWidth: 0.4522,
        strokeMiterlimit: 10,
      }}
    />
  </svg>
);

export default InstrumentBottleBlows;

import * as React from "react"

export default function LofiOutline(props) {
  return (
    <svg
      id="__lofi-outline-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 116.1 113.07"
      {...props}
    >
      <defs>
        <style>
          {
            ".__lofi-outline-prefix__cls-1{fill:none;stroke:#cfd4d8;stroke-miterlimit:10;stroke-width:2px}"
          }
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 22 copy"}</title>
      <rect
        className="__lofi-outline-prefix__cls-1"
        x={2.31}
        y={1.48}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
      />
      <rect
        className="__lofi-outline-prefix__cls-1"
        x={12.94}
        y={32.32}
        width={88.64}
        height={49.89}
        rx={8.94}
        ry={8.94}
        transform="rotate(-20.02 57.277 57.284)"
      />
      <rect
        className="__lofi-outline-prefix__cls-1"
        x={21.58}
        y={44}
        width={72.82}
        height={25.88}
        rx={7.35}
        ry={7.35}
        transform="rotate(-20.02 58.008 56.946)"
      />
      <rect
        className="__lofi-outline-prefix__cls-1"
        x={30.68}
        y={48.09}
        width={55.14}
        height={17.63}
        rx={8.82}
        ry={8.82}
        transform="rotate(-20.02 58.278 56.906)"
      />
      <circle
        className="__lofi-outline-prefix__cls-1"
        cx={40.63}
        cy={63.32}
        r={8.82}
        transform="rotate(-20.02 40.638 63.344)"
      />
      <circle
        className="__lofi-outline-prefix__cls-1"
        cx={75.87}
        cy={50.48}
        r={8.82}
        transform="rotate(-20.02 75.885 50.49)"
      />
      <circle
        className="__lofi-outline-prefix__cls-1"
        cx={40.63}
        cy={63.32}
        r={1.7}
        transform="rotate(-20.02 40.638 63.344)"
      />
      <circle
        className="__lofi-outline-prefix__cls-1"
        cx={76.13}
        cy={50.39}
        r={1.7}
        transform="rotate(-20.02 76.165 50.394)"
      />
      <path
        className="__lofi-outline-prefix__cls-1"
        d="M44.91 87.11l5.37-9.08 26.29-9.58 9.96 3.5"
      />
      <rect
        className="__lofi-outline-prefix__cls-1"
        x={30.68}
        y={48.09}
        width={55.14}
        height={17.63}
        rx={8.82}
        ry={8.82}
        transform="rotate(-20.02 58.278 56.906)"
      />
    </svg>
  )
}

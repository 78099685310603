import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { apiAuthUrl, strofeApi } from '../api/strofeApi';

const INITIAL_LOCAL_SETTINGS = {
  hideNotificationBar: Cookies.get('strofe_hide_notification_bar') || false,
}

const initialState = {
  currentUserId : null,
  entities      : {},
  loading       : 'idle',
  error         : null,
  localSettings : INITIAL_LOCAL_SETTINGS,
  abTests       : {},
}

const fetchById = createAsyncThunk(
  'users/fetchById',
  async ({ id, isCurrentUser }, thunkAPI) => {
    const response = await strofeApi.get(`/users/${id}`, { timeout: 10000 });
    return { data: response.data, isCurrentUser };
  }
)

const registerUser = createAsyncThunk(
  'users/registerUser',
  async ({ email, password, id, display_name }, thunkAPI) => {
    const response = await strofeApi.put(`/users/${id || thunkAPI.getState().users.currentUserId}`, {
      user: { email, password, display_name },
    });

    return { data: response.data };
  }
)

const login = createAsyncThunk(
  'users/login',
  async ({ email, password }) => {

    const response = await strofeApi.post('/sessions', {
      client_id: process.env.REACT_APP_NOMODO_API_CLIENT_ID,
      session: { email, password },
    });

    return { data: response.data };
  }
)

const investorLogin = createAsyncThunk(
  'users/investorLogin',
  async ({ investor_identifier }) => {

    const response = await strofeApi.post('/sessions', {
      client_id: process.env.REACT_APP_NOMODO_API_CLIENT_ID,
      session: { investor_identifier },
    });

    return { data: response.data };
  }
)

const updateFields = createAsyncThunk(
  'users/updateFields',
  async ({ user, current_password }, thunkAPI) => {
    const id = thunkAPI.getState().users.currentUserId;
    const { data } = await strofeApi.put(`/users/${id}`, { user, current_password });
    console.log('data:', data);
    return { data };
  }
)

const omniauthRegisterUser = createAsyncThunk(
  'users/omniauthRegisterUser',
  async ({ provider, code, useParam }, thunkAPI) => {
    const response = await strofeApi.put(apiAuthUrl(provider, code, useParam), {
      id: thunkAPI.getState().users.currentUserId,
      code,
    }, { withCredentials: true });

    return { data: response.data };
  }
)


const omniauthLogin = createAsyncThunk(
  'users/omniauthLogin',
  async ({ provider, code, useParam, referrer, inviter_display_name }) => {
    
    const response = await strofeApi.post(apiAuthUrl(provider, code, useParam), {
      client_id: process.env.REACT_APP_NOMODO_API_CLIENT_ID,
      code,
      session: (referrer || inviter_display_name) ? { referrer, inviter_display_name } : undefined,
    }, { withCredentials: true });

    return { data: response.data };
  }
)

// Requests to reset the password (email sending), not actual reset:
const requestResetPassword = createAsyncThunk(
  'users/requestResetPassword',
  async ({ email }) => {

    const response = await strofeApi.post(`/password_resets`, {
      client_id: process.env.REACT_APP_NOMODO_API_CLIENT_ID,
      password_reset: { email },
    });

    return { data: response.data };
  }
)

// Reset and set to new password based on a user_id and token received on the email:
const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async ({ user_id, token, password }) => {

    const response = await strofeApi.post(`/password_resets/reset`, {
      client_id: process.env.REACT_APP_NOMODO_API_CLIENT_ID,
      password_reset: { user_id, token, password }
    });

    return { data: response.data };
  }
)

const sendEmailVerification = createAsyncThunk(
  'users/sendEmailVerification',
  async () => {
    await strofeApi.post('/email_verifications');
    return true;
  }
)

const verifyEmail = createAsyncThunk(
  'users/verifyEmail',
  async ({ code }) => {
    await strofeApi.put('/email_verifications/verify', { email_verification: { code }});
    return true;
  }
)

const usersSlice = createSlice({
  name: 'users',
  initialState,

  reducers: {
    songCreated: state => {
      const currentUser = state.entities[state.currentUserId];
      currentUser.created_song_count++;
      currentUser.song_count++;
    },

    newSongDownloaded: state => {
      const currentUser = state.entities[state.currentUserId];
      currentUser.remaining_downloads--;
    },

    hideNotificationBar: state => {
      Cookies.set('strofe_hide_notification_bar', true, { expires: 31 });
      state.localSettings.hideNotificationBar = true;
    },

    resetNotificationBar: state => {
      Cookies.remove('strofe_hide_notification_bar');
      Cookies.remove('strofe_hide_hero_try_anime');

      state.localSettings.hideNotificationBar = false;
    },

    setTest: (state, action) => {
      const { test } = action.payload;

      state.abTests[test.test_identifier] = test;
    },

    setSubscription: (state, action) => {
      const { subscription } = action.payload;
      const currentUser = state.entities[state.currentUserId];

      currentUser.subscription = subscription;
    },
  },

  extraReducers: {
    [fetchById.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
      state.error = null;
    },
    [fetchById.fulfilled]: (state, action) => {
      const { data, isCurrentUser } = action.payload;
      if (isCurrentUser) {
        state.currentUserId = data.id;
      }

      state.entities[data.id] = data;

      state.loading = 'idle';
      state.error = null;
    },
    [fetchById.rejected]: (state, action) => {
      state.loading = 'idle';
      state.error = action.error;
    },

    [registerUser.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.entities[data.id] = data;

      state.loading = 'idle';
    },

    [omniauthRegisterUser.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.currentUserId = data.id;
      state.entities[data.id] = data;
    },

    [verifyEmail.fulfilled]: state => {
      state.entities[state.currentUserId].email_verified = true;
    },

    [updateFields.fulfilled]: (state, action) => {
      const { data } = action.payload;
      const user = state.entities[state.currentUserId];

      // merge incomming user with previous user values, in case
      // the reducer adds extra information to the user:
      state.entities[state.currentUserId] = {
        ...user,
        ...data,
      }
    },
  }
});

export const usersActions = {
  fetchById,
  registerUser,
  updateFields,
  resetPassword,
  requestResetPassword,
  login,
  investorLogin,
  omniauthLogin,
  omniauthRegisterUser,
  sendEmailVerification,
  verifyEmail,
  ...usersSlice.actions,
};

export const usersSelectors = {
  getCurrentUser   : state => state.users.entities[state.users.currentUserId],
  getLocalSettings : state => state.users.localSettings,
  getAbTests       : state => state.users.abTests,
  getSubscription  : state => state.users.subscription,
}

export default usersSlice.reducer;

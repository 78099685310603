import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usersSelectors } from '../../store/usersSlice';
import { Link } from 'react-router-dom';
import { Trans as Translate, useTranslation } from 'react-i18next';
import useEventCallback from '../../hooks/useEventCallback';
import { strofeApi } from "../../api/strofeApi";
import { DateTime } from "luxon";
import { useDebouncedCallback } from 'use-debounce';
import { PRICING_TIER } from '../../utils/Prices';

import Form from "react-bootstrap/Form";
import Toast from "react-bootstrap/Toast";

import NavigationHeader from "../NavigationHeader";

import StrofeCoin from "../../icons/coins/StrofeCoin";
import InfiniteLoader from '../../layout/InfiniteLoader/InfiniteLoader';

import './Transactions.scss';

const TRANSACTION_REASONS = {
  sign_up:                'Sign Up',
  email_verification:     'Email Verification',
  song_creation:          'Song Creation',
  user_invite:            'User Invite Accepted',
  song_purchase:          'Song Purchase',
  midi_purchase:          'Midi Purchase',
  song_and_midi_purchase: 'Song and Midi Purchase',
  coin_purchase:          'Coin Purchase',
  courtesy:               'Gift from the Strofe team',
  friday_chat:            'Friday chat attendance reward',
  interview_reward:       'User Interview reward',
  coupon_redemption:      'Coupon Redemption',
  song_like:              'Song Like',
  referral:               'A user joined Strofe using your invite link',
}

const NOW = DateTime.local();

const CUTOFF_DATES = {

  past_day: {
    phrase : "Past Day",
    time   : NOW.minus({ days: 1 }).toISO(),
  },

  past_7_days: {
    phrase : "Past 7 Days",
    time   : NOW.minus({ days: 7 }).toISO(),
  },

  past_30_days: {
    phrase : "Past 30 Days",
    time   : NOW.minus({ days: 30 }).toISO(),
  },

  past_year: {
    phrase : "Past Year",
    time   : NOW.minus({ years: 1 }).toISO(),
  },
};

export default function Transactions() {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState(null);
  const [coinTotal, setCoinTotal] = useState(0);
  const [transactionType, setTransactionType] = useState('all');
  const [cutoffDate, setCutoffDate] = useState('past_30_days');

  const { i18n, t } = useTranslation();
  const currentUser = useSelector(usersSelectors.getCurrentUser);

  const loadTransactions = useEventCallback((fetchTransactionType = transactionType, fetchCutoffDate = cutoffDate) => {
    debouncedLoading(true);

    const fetchTransactions = async () => {
      const { data } = await strofeApi.get(`/coin_transactions?coin_transaction_type=${fetchTransactionType}&cutoff_date=${CUTOFF_DATES[fetchCutoffDate].time}`);
      setTransactions(data);

      let total = 0;

      data.forEach((coinTransaction) => {
        total += coinTransaction.coin_amount;
      });

      debouncedLoading.cancel();
      setCoinTotal(total);
      setLoading(false);
    }

    fetchTransactions();
  });

  useEffect(() => {
    loadTransactions()
  }, [loadTransactions]);

  const updateTransactionType = e => {
    setTransactionType(e.target.value);
    loadTransactions(e.target.value);
  }

  const updateCutoffDate = e => {
    setCutoffDate(e.target.value);
    loadTransactions(transactionType, e.target.value);
  }

  const debouncedLoading = useDebouncedCallback(value => {
    setLoading(value);
  }, 200);

  const renderTransactions = () => {
    if (transactions === null) {
      return (
        <div className='initial-load'>
          <InfiniteLoader width={48} />
          <p><Translate>Loading Transactions...</Translate></p>
        </div>
      );
    }

    else if (transactions.length === 0) {
      return (
        <div className='empty-transactions'>
          <Translate i18nKey='no-transactions-filter' />
        </div>
      );
    }

    else {
      return renderTransactionTable();
    }
  }

  const renderTransactionTitle = transaction => {
    const { coin_transaction_reason: reason, song, coupon, invoice } = transaction;

    switch (reason) {

      case 'song_creation':
      case 'song_purchase':
      case 'midi_purchase':
      case 'song_and_midi_purchase':
        return (
          <>
            <span className='reason'><Translate>{ TRANSACTION_REASONS[reason] }</Translate></span>
            <span className='details'><Link to={`/song/${song.id}`}>{ song.title }</Link></span>
          </>
        );

      case 'coin_purchase':
        return (
          <>
            <span className='reason'><Translate>{ TRANSACTION_REASONS[reason] }</Translate></span>
            <span className='details'><Translate i18nKey='transaction-amount-x' values={{ cost: Object.values(PRICING_TIER).find(tier => tier.id === invoice.price_id).price }} /></span>
          </>
        );

      case 'coupon_redemption':
        return (
          <>
            <span className='reason'><Translate>{ TRANSACTION_REASONS[reason] }</Translate></span>
            <span className='details'>{ coupon.title }</span>
          </>
        );

      default:
        return <Translate>{ TRANSACTION_REASONS[reason] }</Translate>
    }
  }

  const renderTransactionTable = () => (
    <table className='transaction-view'>
      <thead>
        <tr>
          <th className='column-created_at'><Translate>Date</Translate></th>
          <th className='column-coin_transaction_reason'><Translate>Transaction</Translate></th>
          <th className='column-coin_amount'><Translate>Amount</Translate></th>
        </tr>
      </thead>
      <tbody>
      { transactions.map(transaction => (
        <tr key={transaction.id}>
          <td className='created_at centered'>
            { DateTime.fromISO(transaction.created_at).setLocale(i18n.language).toLocaleString() }
          </td>
          <td>
            <div className='title'>{ renderTransactionTitle(transaction) }</div>
            <div className='date-mobile'>{ DateTime.fromISO(transaction.created_at).setLocale(i18n.language).toLocaleString() }</div>
          </td>
          <td>
            <div className='currency'>
              <StrofeCoin />
              { transaction.coin_amount.toLocaleString() }
            </div>
          </td>
        </tr>
      ))}
        <tr className='transaction-total'>
          <td className='total'>
            <Translate>Total</Translate>
          </td>
          <td className='total-mobile'>
            <Translate>Total</Translate>
          </td>
          <td className='currency'>
            <StrofeCoin />
            {coinTotal.toLocaleString()}
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <div className='__transactions'>
      <NavigationHeader />

      <div className='header'>
        <StrofeCoin width={36} height={36} /> <Translate>Strofe Coin Transactions</Translate>
      </div>

      <div className='options'>
        <div className='my-balance'>
          <Translate i18nKey='my-balance' />
          <span><StrofeCoin /> { currentUser.coins.toLocaleString() }</span>
        </div>

        <div className='filters'>
          <Form.Control className='cutoff_date-input' as='select' value={cutoffDate} onChange={updateCutoffDate}>
            { Object.entries(CUTOFF_DATES).map(([id, cutoff]) => (
              <option key={id} value={id}>{ t(cutoff.phrase) }</option>
            ))}
          </Form.Control>

          <Form.Control className='transaction-type-input' as='select' value={transactionType} onChange={updateTransactionType}>
            <option key='all' value='all'>{ t('All') }</option>
            <option key='credit' value='credit'>{ t('Credits') }</option>
            <option key='debit' value='debit'>{ t('Debits') }</option>
          </Form.Control>
        </div>
      </div>

      { renderTransactions() }

      <Toast show={transactions !== null && loading} className='strofe-toast'>
        <Toast.Body><Translate>Loading...</Translate></Toast.Body>
      </Toast>

    </div>
  );
}

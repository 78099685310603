import Button from 'react-bootstrap/Button';

export default function SongButton({ outline, icon, id, children, onClick, active, animations }) {

  const Icon = icon;
  const Outline = outline;

  return (
    <Button variant='outline-info' className='style-button genre' onClick={() => onClick(id)} active={active}>
      <div className='icon-container'>
        {animations?.map((animation, index) => {
          const AnimIcon = animation.icon;
          return <AnimIcon key={index} className={animation.className} />
        })}
        <Icon className='hovered' />
        <Outline className='outline' />
      </div>
      <div className={`genre-text text-${id}`}>{ children }</div>
    </Button>
  );
}

import { useEffect } from "react";
import { useState } from "react";
import { strofeApi } from "../../api/strofeApi";
import Checkbox from "../../layout/Checkbox";
import { useDebouncedCallback } from "use-debounce/lib";

import Button from "react-bootstrap/Button";
import Toast from 'react-bootstrap/Toast';
import Modal from "react-bootstrap/Modal";

import NavigationHeader from "../NavigationHeader";
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";
import TestModal from "./TestModal";
import TestResultsModal from "./TestResultsModal";

import './TestManager.scss';

export default function TestManager() {

  const [tests, setTests] = useState(null);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [activeTestId, setActiveTestId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showTestModal, setShowTestModal] = useState(false);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toastTestCreated, setToastTestCreated] = useState(null);
  const [toastTestEdited, setToastTestEdited] = useState(null);
  const [toastTestDeleted, setToastTestDeleted] = useState(null);

  const debouncedLoading = useDebouncedCallback(value => {
    setLoading(value);
  }, 200);

  useEffect(() => {
    const loadTests = async () => {
      debouncedLoading(true);
      const urlParams = new URLSearchParams();

      filterEnabled && urlParams.append('enabled', filterEnabled);

      const { data } = await strofeApi.get(`/tests?${urlParams.toString()}`);
      setTests(data);
      debouncedLoading.cancel();
      setLoading(false);
    }

    loadTests();
  }, [filterEnabled, debouncedLoading]);

  const onCreateTest = () => {
    setActiveTestId(null);
    setShowTestModal(true);
  }

  const onEditTest = id => {
    setActiveTestId(id);
    setShowTestModal(true);
  }

  const onDisplayResults = id => {
    setActiveTestId(id);
    setShowResultsModal(true);
  }

  const onDeleteTest = id => {
    setDeleteId(id);
    setShowDeleteModal(true);
  }

  const onConfirmDeleteTest = async () => {
    await strofeApi.delete(`/tests/${deleteId}`);

    setTests(tests.filter(test => test.id !== deleteId));
    setShowDeleteModal(false);
    setTimeout(() => setToastTestDeleted(true), 500);
  }

  const onEditSuccess = data => {
    const newTests = [...tests];
    newTests[tests.findIndex(test => test.id === data.id)] = data;

    setTests(newTests);
    setShowTestModal(false);
    setTimeout(() => setToastTestEdited(true), 500);
  }

  const onCreateSuccess = data => {
    const newTests = [data, ...tests];

    setTests(newTests);
    setShowTestModal(false);
    setTimeout(() => setToastTestCreated(true), 500);
  }

  const renderTestManager = () => {

    if (tests === null) {
      return (
        <div className='initial-load'>
          <InfiniteLoader width={48} />
          <p>Loading Tests...</p>
        </div>
      );
    }

    let activationPercentages = [];
    tests.forEach((test) => {
      let maxSize = Math.floor( 100 / (test.variants.length + 1) );
      let activationPercentageRow = [];
      test.variant_activations.forEach((variantActivation) => {
        activationPercentageRow.push( Math.floor( (variantActivation * 100)/maxSize ) );
      });
      activationPercentages.push(activationPercentageRow);
    });

    return (
      <>
        <div className='controls'>
          <div className='filters'>
            <strong className='title'>FILTER</strong>
            <Checkbox checked={filterEnabled} onChange={e => setFilterEnabled(e.target.checked)}>Enabled tests</Checkbox>
          </div>
          <Button className='create' variant='warning' onClick={onCreateTest}>Create Test</Button>
        </div>

        { tests.length === 0 && (
          <div className='empty-tests'>
            There are no tests to display
          </div>
        )}

        { tests.map((test, testIndex) => (
          <div className='test' key={test.id}>
            <div className='title'>
              <h3>{ test.title }</h3>
              <div className='coin-controls'>
                {test.enabled && <>Enabled</>}
                {!test.enabled && <>Disabled</>}
              </div>
            </div>

            <p>{ test.description }</p>

            <div className='variants'>
              { test.variants.map((variant, index) => (
                <div className='variant' key={variant}>
                  <div className='variant-label'>
                    <span>{variant}</span>
                    <span>{test.variant_activations[index]}%</span>
                  </div>
                  <div className='activation'>
                    <div className='activation-total' style={{width: `${activationPercentages[testIndex][index]}%`}}></div>
                  </div>
                </div>
              ))}
            </div>

            <div className='options'>
              <div>
                <Button onClick={() => onDisplayResults(test.id)}>Compute Results</Button>
              </div>
              <div>
                <Button variant='danger' onClick={() => onDeleteTest(test.id)}>Delete</Button>
                <Button onClick={() => onEditTest(test.id)}>Edit</Button>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  const renderDeleteModal = () => (
    <Modal className='__modal' size='sm' show={showDeleteModal} backdrop='static'>
      <Modal.Header>
        Delete Test
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this test?
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setShowDeleteModal(false)}>Cancel</Button>
        <Button onClick={onConfirmDeleteTest}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div className='__test-manager'>
      <NavigationHeader />
      <h1 className='header'>Test Manager</h1>
      { renderTestManager() }
      { renderDeleteModal() }
      <TestModal show={showTestModal} onHide={() => setShowTestModal(false)} activeTest={tests?.find(test => test.id === activeTestId)} onEdit={onEditSuccess} onCreate={onCreateSuccess} />
      {showResultsModal && <TestResultsModal show={showResultsModal} onHide={() => setShowResultsModal(false)} activeTest={tests?.find(test => test.id === activeTestId)} />}

      <Toast show={tests !== null && loading} className='strofe-toast'>
        <Toast.Body>Loading...</Toast.Body>
      </Toast>

      <Toast show={toastTestCreated} autohide delay={2000} className='strofe-toast' onClose={() => setToastTestCreated(false)}>
        <Toast.Body>Test created successfully</Toast.Body>
      </Toast>

      <Toast show={toastTestEdited} autohide delay={2000} className='strofe-toast' onClose={() => setToastTestEdited(false)}>
        <Toast.Body>Test edited successfully</Toast.Body>
      </Toast>

      <Toast show={toastTestDeleted} autohide delay={2000} className='strofe-toast' onClose={() => setToastTestDeleted(false)}>
        <Toast.Body>Test deleted successfully</Toast.Body>
      </Toast>
    </div>
  );
}
import * as React from "react";

const InstrumentViolin = (props) => (
  <svg
    id="InstrumentViolin_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentViolin_svg__st2{fill:#fdcc75}.InstrumentViolin_svg__st5{fill:#cfd125}.InstrumentViolin_svg__st21,.InstrumentViolin_svg__st22{fill:none;stroke:#fff;stroke-width:1.6001;stroke-linecap:round;stroke-miterlimit:10}.InstrumentViolin_svg__st22{stroke-width:1.7628}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      d="M62.64 77.2s-6.29-3.47-1.42-10.67 8.57-3.35 8.57-3.35l2.91-3.16c5.02-5.44 4.34-14-1.48-18.58l-3.19-2.51-3.19-2.51c-5.81-4.58-14.3-3.24-18.41 2.92l-2.39 3.57s4.61 2.7-1.25 9.11-10.69 1.12-10.69 1.12-19.7 14.37.75 30.47 29.79-6.41 29.79-6.41z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentViolin_svg__st2"
      d="M41.43 66.62 31.36 82.45l1.48 1.16 1.48 1.17 13.03-13.49-2.96-2.33zM60.67 55.99l22.4-33.09-1.48-1.17-1.49-1.16-26.92 29.52 3.75 2.95z"
    />
    <circle
      cx={81.59}
      cy={21.73}
      r={5.23}
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      className="InstrumentViolin_svg__st21"
      d="m73.09 24.81 7.49 5.91M75.27 26.64 37.05 75.16M78.03 28.71 39.81 77.23"
    />
    <path
      className="InstrumentViolin_svg__st22"
      d="m4.43 76.81 38.51-63.79M7.87 78.54l38.52-63.79M5.71 74.68h3.94"
    />
    <path
      className="InstrumentViolin_svg__st5"
      d="M45.84 57.99a.54.54 0 0 0 0-.18.471.471 0 0 0-.06-.15c-.01-.03-.02-.06-.04-.09a.474.474 0 0 0-.1-.1l-.03-.03-.03-.03c-.02-.01-.03-.03-.05-.04-1.69-1.57-3.63-.22-5.06.98-.61.51-1.26 1.03-1.96 1.41-.31.17-.64.31-.96.45-.08.03-.43.13-.47.2-.82 1.69-1.68 3.35-3.29 4.42-1.07.71-2.71 1.4-3.79.35.1-.04.2-.09.27-.18a.68.68 0 0 0-1.02-.9c-.04.05-.07.11-.1.15-.01.02-.01.03-.02.05-.01.02-.02.04-.02.06-.43 1.01.52 1.81 1.55 2.01 1.34.26 2.6-.63 3.7-1.24 3.89-2.21 4.67-2.44 4.67-2.44-.04-.01.34-.9.38-.97.23-.45.49-.89.78-1.31.47-.68 1.01-1.32 1.61-1.89.49-.47 1.06-1.08 1.74-1.27.43-.12.81-.03 1.19.17-.09-.05-.22.4-.23.45-.02.16.02.32.12.45.21.29.62.37.92.17.19-.11.28-.3.3-.5zM55.14 66.22c.05-.02.1-.03.18-.02.05.01.11.02.16.04.03.01.07.01.1.03.04.02.07.06.11.08.01.01.02.01.03.02l.03.03.04.04c1.76 1.48.66 3.58-.36 5.14-.43.66-.87 1.38-1.16 2.12-.13.33-.23.67-.33 1-.02.08-.07.45-.15.49-1.58 1.02-3.12 2.08-3.98 3.8-.57 1.15-1.06 2.86.11 3.81.02-.11.07-.21.14-.29a.68.68 0 0 1 1.02.9c-.04.05-.1.08-.14.12-.01.01-.03.01-.05.02l-.06.03c-.95.55-1.86-.29-2.19-1.29-.42-1.3.31-2.66.78-3.82 1.72-4.13 1.85-4.93 1.85-4.93.01.03.85-.45.91-.49.42-.29.82-.6 1.21-.93.62-.55 1.19-1.16 1.68-1.82.41-.54.94-1.19 1.05-1.88.07-.44-.06-.8-.32-1.16.06.08-.37.27-.42.28-.16.04-.32.02-.46-.06a.68.68 0 0 1-.28-.9c.12-.2.3-.32.5-.36z"
    />
  </svg>
);

export default InstrumentViolin;

import * as React from "react";

const InstrumentSynthBass1 = (props) => (
  <svg
    id="InstrumentSynthBass1_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.45"
    style={{
      enableBackground: "new 0 0 110.84 110.45",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentSynthBass1_svg__st2{fill:#fdcc75}.InstrumentSynthBass1_svg__st4{fill:#f18860}.InstrumentSynthBass1_svg__st6{fill:#ca5672}.InstrumentSynthBass1_svg__st10{fill:#538553}.InstrumentSynthBass1_svg__st28{fill:#fff}.InstrumentSynthBass1_svg__st29{fill:#2b4c5c}"
      }
    </style>
    
    <path
      className="InstrumentSynthBass1_svg__st4"
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
    />
    <path
      d="M100.25 27.64H13.59A4.59 4.59 0 0 0 9 32.23V71.5c0 3.31 2.69 6 6 6h82.83c3.87 0 7-3.13 7-7V32.22c.01-2.53-2.05-4.58-4.58-4.58z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentSynthBass1_svg__st10"
      d="M62.64 37.58c-1.92 0-3.48-1.56-3.48-3.48s1.56-3.48 3.48-3.48 3.48 1.56 3.48 3.48a3.489 3.489 0 0 1-3.48 3.48z"
    />
    <path
      className="InstrumentSynthBass1_svg__st2"
      d="M84.82 37.58c-1.92 0-3.48-1.56-3.48-3.48s1.56-3.48 3.48-3.48 3.48 1.56 3.48 3.48c0 1.93-1.56 3.48-3.48 3.48z"
    />
    <path
      className="InstrumentSynthBass1_svg__st4"
      d="M73.73 37.58c-1.92 0-3.48-1.56-3.48-3.48s1.56-3.48 3.48-3.48 3.48 1.56 3.48 3.48c0 1.93-1.56 3.48-3.48 3.48z"
    />
    <path
      className="InstrumentSynthBass1_svg__st6"
      d="M95.92 37.58c-1.92 0-3.48-1.56-3.48-3.48s1.56-3.48 3.48-3.48 3.48 1.56 3.48 3.48c0 1.93-1.56 3.48-3.48 3.48zM62.64 47.94c-1.92 0-3.48-1.56-3.48-3.48s1.56-3.48 3.48-3.48 3.48 1.56 3.48 3.48c-.01 1.92-1.57 3.48-3.48 3.48z"
    />
    <path
      className="InstrumentSynthBass1_svg__st4"
      d="M84.82 47.94c-1.92 0-3.48-1.56-3.48-3.48s1.56-3.48 3.48-3.48 3.48 1.56 3.48 3.48-1.56 3.48-3.48 3.48z"
    />
    <path
      className="InstrumentSynthBass1_svg__st2"
      d="M73.73 47.94c-1.92 0-3.48-1.56-3.48-3.48s1.56-3.48 3.48-3.48 3.48 1.56 3.48 3.48-1.56 3.48-3.48 3.48z"
    />
    <path
      className="InstrumentSynthBass1_svg__st10"
      d="M95.92 47.94c-1.92 0-3.48-1.56-3.48-3.48s1.56-3.48 3.48-3.48 3.48 1.56 3.48 3.48-1.56 3.48-3.48 3.48z"
    />
    <path
      className="InstrumentSynthBass1_svg__st28"
      d="M33.98 55.42h65.03v15.94H33.98z"
    />
    <path
      className="InstrumentSynthBass1_svg__st29"
      d="M37.22 55.42h3.12v9.7h-3.12zM42.31 55.42h3.12v9.7h-3.12zM50.09 55.42h3.12v9.7h-3.12zM55.58 55.42h3.12v9.7h-3.12zM61.08 55.42h3.12v9.7h-3.12zM69.11 55.42h3.12v9.7h-3.12zM74.2 55.42h3.12v9.7H74.2zM81.81 55.42h3.12v9.7h-3.12zM87.31 55.42h3.12v9.7h-3.12zM92.8 55.42h3.12v9.7H92.8z"
    />
    <path
      className="InstrumentSynthBass1_svg__st10"
      d="M51.64 48.79h-34.7c-1.65 0-2.98-1.33-2.98-2.98v-12.2c0-1.65 1.33-2.98 2.98-2.98h34.7c1.65 0 2.98 1.33 2.98 2.98v12.2c0 1.65-1.33 2.98-2.98 2.98z"
    />
    <path
      className="InstrumentSynthBass1_svg__st28"
      d="M29.38 57.74c0-1.28-1.04-2.32-2.32-2.32-1.28 0-2.32 1.04-2.32 2.32v3.29h4.64v-3.29z"
    />
    <path
      className="InstrumentSynthBass1_svg__st2"
      d="M24.75 61.03v8.02c0 1.28 1.04 2.32 2.32 2.32 1.28 0 2.32-1.04 2.32-2.32v-8.02h-4.64z"
    />
    <path
      className="InstrumentSynthBass1_svg__st6"
      d="M14.82 65.64v3.41c0 1.28 1.04 2.32 2.32 2.32 1.28 0 2.32-1.04 2.32-2.32v-3.41h-4.64z"
    />
    <path
      className="InstrumentSynthBass1_svg__st28"
      d="M19.46 57.74c0-1.28-1.04-2.32-2.32-2.32-1.28 0-2.32 1.04-2.32 2.32v7.9h4.64v-7.9z"
    />
    <path
      className="InstrumentSynthBass1_svg__st4"
      d="M23.89 59.31h6.35v3.44h-6.35zM13.96 63.4h6.35v3.44h-6.35z"
    />
  </svg>
);

export default InstrumentSynthBass1;

import * as React from "react";

const InstrumentPanFlute = (props) => (
  <svg
    id="InstrumentPanFlute_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentPanFlute_svg__st2{fill:#fdcc75}.InstrumentPanFlute_svg__st4{fill:#f18860}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentPanFlute_svg__st4"
      d="m20.01 88.4-4.76-1.21a1.802 1.802 0 0 1-1.3-2.2l17.38-68.22c.25-.97 1.23-1.55 2.2-1.3l4.76 1.21c.97.25 1.55 1.23 1.3 2.2L22.2 87.1c-.24.97-1.23 1.55-2.19 1.3z"
    />
    <path
      className="InstrumentPanFlute_svg__st2"
      d="m29.69 83.41-4.76-1.21a1.802 1.802 0 0 1-1.3-2.2l15.6-61.21c.25-.97 1.23-1.55 2.2-1.3l4.76 1.21c.97.25 1.55 1.23 1.3 2.2l-15.6 61.21c-.25.97-1.23 1.55-2.2 1.3z"
    />
    <path
      className="InstrumentPanFlute_svg__st4"
      d="m39.1 79.51-4.76-1.21a1.802 1.802 0 0 1-1.3-2.2l14.09-55.3c.25-.97 1.23-1.55 2.2-1.3l4.76 1.21c.97.25 1.55 1.23 1.3 2.2L41.3 78.21a1.81 1.81 0 0 1-2.2 1.3z"
    />
    <path
      className="InstrumentPanFlute_svg__st2"
      d="m48.04 77.43-4.76-1.21a1.802 1.802 0 0 1-1.3-2.2l13.05-51.2c.25-.97 1.23-1.55 2.2-1.3l4.76 1.21c.97.25 1.55 1.23 1.3 2.2l-13.05 51.2c-.25.96-1.23 1.54-2.2 1.3z"
    />
    <path
      className="InstrumentPanFlute_svg__st4"
      d="m55.2 73.79-2.71-.69a1.802 1.802 0 0 1-1.3-2.2l11.74-46.08c.25-.97 1.23-1.55 2.2-1.3l2.71.69c.97.25 1.55 1.23 1.3 2.2L57.39 72.49a1.8 1.8 0 0 1-2.19 1.3z"
    />
    <path
      className="InstrumentPanFlute_svg__st2"
      d="m63.06 71.7-3.74-.95a1.802 1.802 0 0 1-1.3-2.2l10.76-42.23c.25-.97 1.23-1.55 2.2-1.3l3.74.95c.97.25 1.55 1.23 1.3 2.2L65.25 70.4c-.25.96-1.23 1.55-2.19 1.3z"
    />
    <path
      className="InstrumentPanFlute_svg__st4"
      d="m70.89 69.72-3.74-.95a1.802 1.802 0 0 1-1.3-2.2l9.81-38.5c.25-.97 1.23-1.55 2.2-1.3l3.74.95c.97.25 1.55 1.23 1.3 2.2l-9.81 38.5c-.25.96-1.24 1.55-2.2 1.3z"
    />
    <path
      className="InstrumentPanFlute_svg__st2"
      d="m78.61 68.16-3.74-.95a1.802 1.802 0 0 1-1.3-2.2l8.97-35.19c.25-.97 1.23-1.55 2.2-1.3l3.74.95c.97.25 1.55 1.23 1.3 2.2l-8.97 35.19c-.25.97-1.23 1.55-2.2 1.3z"
    />
    <path
      className="InstrumentPanFlute_svg__st4"
      d="m86.21 67.09-3.74-.95a1.802 1.802 0 0 1-1.3-2.2l8.25-32.36c.25-.97 1.23-1.55 2.2-1.3l3.74.95c.97.25 1.55 1.23 1.3 2.2l-8.25 32.36c-.25.96-1.23 1.54-2.2 1.3z"
    />
    <path
      d="m22.58 27.26-5.33 20.9c-.37 1.45-.18 2.83.45 3.33l5.41 4.22c.42.33.98.21 1.51-.33 1.87-1.9 6.52-6.23 9.6-5.75 3.53.56 48.55 10.02 57.47 11.9.85.18 1.87-1.02 2.3-2.7l2.78-10.9c.43-1.69.1-3.24-.74-3.48l-71.1-19.87c-.85-.24-1.91.97-2.35 2.68z"
      style={{
        fill: "#ca5672",
      }}
    />
  </svg>
);

export default InstrumentPanFlute;

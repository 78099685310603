import * as React from "react";

const InstrumentCharang = (props) => (
  <svg
    id="InstrumentCharang_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".InstrumentCharang_svg__st4{fill:#f18860}"}</style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      className="InstrumentCharang_svg__st4"
      d="M94.07 69.38c-3.22-3.82-8.02-5.51-12.63-4.94a11.86 11.86 0 0 0-2.63-5.7c-4.22-4.99-11.68-5.62-16.68-1.41S56.5 69.01 60.72 74c2.15 2.55 5.16 3.96 8.25 4.17-.09 3.37 1 6.79 3.35 9.57 5.07 6.01 14.05 6.76 20.06 1.69 6-5.07 6.76-14.05 1.69-20.05z"
    />
    <path
      d="m79.43 85.94-1.26-1.26a.585.585 0 0 1 0-.83l9.81-9.87c.23-.23.6-.23.83 0l1.26 1.26c.23.23.23.6 0 .83l-9.81 9.87c-.22.23-.6.23-.83 0z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      d="M66.85 62.61a5.154 5.154 0 0 1 4.8-.99L41.64 34.19l-5.38 5.6 29.06 28.43a5.13 5.13 0 0 1 1.53-5.61z"
      style={{
        fill: "#fdcc75",
      }}
    />
    <path
      d="M74.08 63.22a5.137 5.137 0 0 0-2.43-1.6 5.1 5.1 0 0 0-4.8.99 5.13 5.13 0 0 0-1.53 5.61c.2.58.51 1.12.92 1.62a5.133 5.133 0 0 0 7.23.61c2.16-1.83 2.44-5.07.61-7.23z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      className="InstrumentCharang_svg__st4"
      d="m41.64 34.19-1.96-3.38c-.37-.63-.83-1.2-1.38-1.68L19.65 12.89s-2.48-.42-4.65 1.71-1.32 4.38-1.32 4.38L30.46 36.5c.5.53 1.1.96 1.75 1.28l4.05 1.99 5.38-5.58z"
    />
  </svg>
);

export default InstrumentCharang;

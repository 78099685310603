import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Cookies from "js-cookie";

export default function Referrer() {

  let { inviter_display_name } = useParams();
  const history = useHistory();

  useEffect(() => {
    Cookies.set('strofe_inviter_display_name', inviter_display_name, { expires: 60 });
    history.replace('/');
  });

  return null;
}

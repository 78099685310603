import * as React from "react";
const SvgInstrumentDrumsBrush = (props) => (
  <svg
    id="InstrumentDrumsBrush_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentDrumsBrush_svg__st99{fill:#f48864}.InstrumentDrumsBrush_svg__st101{fill:#4b4a9e}.InstrumentDrumsBrush_svg__st102{fill:#528459}.InstrumentDrumsBrush_svg__st108{fill:none;stroke:#f8c4b9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>

    <path
      className="InstrumentDrumsBrush_svg__st101"
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
    />
    <path
      className="InstrumentDrumsBrush_svg__st101"
      d="m78.09 60.87 3.56 5.64c.13.21.07.48-.14.61l-.03.02a.44.44 0 0 1-.61-.14l-3.34-5.3.56-.83zM52.43 60.21l-3.56 5.64a.44.44 0 0 0 .14.61l.03.02c.21.13.48.07.61-.14l3.34-5.3-.56-.83z"
    />
    <path
      d="M62.12 44.94c-18.55 0-33.58-4.29-33.58-9.57v27.01s3.43 11.21 34.81 11.21S95.7 62.38 95.7 62.38V35.36c0 5.29-15.04 9.58-33.58 9.58z"
      style={{
        fill: "#c55a74",
        stroke: "#c55a74",
        strokeMiterlimit: 10,
      }}
    />
    <ellipse
      className="InstrumentDrumsBrush_svg__st99"
      cx={62.12}
      cy={35.36}
      rx={33.58}
      ry={9.57}
    />
    <path
      className="InstrumentDrumsBrush_svg__st99"
      d="M83.21 71.24c-.39 0-.71-.32-.71-.71V42.58c0-.39.32-.71.71-.71.39 0 .71.32.71.71v27.95c0 .39-.32.71-.71.71zM60.11 74.3c-.39 0-.71-.32-.71-.71V45.64c0-.39.32-.71.71-.71.39 0 .71.32.71.71V73.6c0 .39-.32.7-.71.7zM37.01 70.1c-.39 0-.71-.32-.71-.71V41.44c0-.39.32-.71.71-.71.39 0 .71.32.71.71V69.4a.71.71 0 0 1-.71.7z"
    />
    <path
      className="InstrumentDrumsBrush_svg__st108"
      d="M27.56 70.08 7.99 63M26.82 69.2 7.73 60.91M26.73 68.93 9.56 57.16M26.95 68.83l-18.5-9.54M27.8 69.81 18.07 51.4M26.84 69.18 15.99 51.41M26.53 69.22 12.71 53.65M26.54 68.98l-15.7-13.66M26.45 69.41l-12.01-17"
    />
    <path
      className="InstrumentDrumsBrush_svg__st102"
      d="m28.29 68.14 32.35 28.14c.66.57.73 1.57.15 2.22-.57.66-1.57.73-2.22.15L26.22 70.52c-.66-.57-.73-1.57-.15-2.22a1.57 1.57 0 0 1 2.22-.16z"
    />
    <circle
      className="InstrumentDrumsBrush_svg__st108"
      cx={62.58}
      cy={100.09}
      r={2.22}
    />
    <path
      className="InstrumentDrumsBrush_svg__st108"
      d="m65.75 74.18 14.77-14.66M66.86 73.89l15.66-13.72M67.14 73.92l17.98-10.49M67.13 74.16l16.54-12.64M65.89 74.52l20.8-.93M66.87 73.92l20.7-2.22M66.96 73.61l19.98-5.83M67.17 73.73l19.07-8.36M66.83 73.46l20.5-3.59"
    />
    <path
      className="InstrumentDrumsBrush_svg__st102"
      d="M67.19 75.67 27.91 92.88c-.8.35-1.73-.01-2.08-.81-.35-.8.01-1.73.81-2.08l39.28-17.21c.8-.35 1.73.01 2.08.81.35.8-.01 1.73-.81 2.08z"
    />
    <circle
      className="InstrumentDrumsBrush_svg__st108"
      cx={23.64}
      cy={93.01}
      r={2.22}
    />
  </svg>
);
export default SvgInstrumentDrumsBrush;


import { MOODS } from "../routes/CreateSong/CreateSong";

import './LoadingBlocks.scss';

export default function LoadingBlocks({ children }) {

  const renderLoadingMoods = mood => {
    const Icon = mood.icon;
    return <Icon key={mood.id} className='mood-icon' />
  }

  return (
    <div className='__loading-blocks' data-test='LOADING-blocks'>
      <p className='loading'>{ children }</p>
      <div className='loading-moods'>{ MOODS.map(mood => renderLoadingMoods(mood)) }</div>
    </div>
  );
}
export default function OrchestralPopOutline(props) {
  return (
    <svg
      id="__orchestral-pop-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 118.03 113.99"
      {...props}
    >
      <defs>
        <style>
          {
            ".__orchestral-pop-prefix__cls-1{fill:none;stroke:#cfd4d8;stroke-miterlimit:10;stroke-width:2px}"
          }
        </style>
      </defs>
      <title>{"style_SVG_StrofeArtboard 26 copy"}</title>
      <rect
        className="__orchestral-pop-prefix__cls-1"
        x={3.87}
        y={1.88}
        width={110.84}
        height={110.84}
        rx={16.67}
        ry={16.67}
      />
      <path
        className="__orchestral-pop-prefix__cls-1"
        d="M78.06 76.21S71 77.37 70.34 68.7 75 60.76 75 60.76l.33-4.28A12.7 12.7 0 0062.67 42.8h-8.11A12.7 12.7 0 0041.9 56.48l.33 4.28s5.29-.74 4.66 7.94-7.72 7.51-7.72 7.51-6.58 23.47 19.44 23.47 19.45-23.47 19.45-23.47z"
      />
      <circle className="__orchestral-pop-prefix__cls-1" cx={58.61} cy={20.91} r={5.23} />
      <path className="__orchestral-pop-prefix__cls-1" d="M53.85 28.59h9.53" />
      <circle className="__orchestral-pop-prefix__cls-1" cx={58.61} cy={71.24} r={7.56} />
      <path
        className="__orchestral-pop-prefix__cls-1"
        d="M26.54 75.01l66.12-14.25M27.54 78.36l17.06-3.67 17.53-3.78 8.26-1.78 23.27-5.01M28.74 74.54l2.46 2.6M46.41 57.75s2.95 1.76 2.87 4.51M70.64 57.75s-2.95 1.76-2.87 4.51M54.85 81.02l1.88 18.66H60.5l1.88-18.66H54.85zM56.39 25.64l-2.54 35.12h9.53l-2.54-35.12"
      />
    </svg>
  );
}

import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useThrottledCallback, useDebouncedCallback } from 'use-debounce';  
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { Trans as Translate, useTranslation } from 'react-i18next';
import VolumeIcon from '../../icons/VolumeIcon';
import MutedIcon from '../../icons/MutedIcon';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import usePrevious from '../../hooks/usePrevious';
import RefreshIcon from '../../icons/RefreshIcon';

import { LoopingAudio } from '../../utils/LoopingAudio';

export default function SongSection({ section, instrument, playbackPosition, i, narrow, sectionLengths, currentSection, startTimes, currentSectionRegenerating, wholeTrackRegenerating, hasSoloTrack, trackSolos, songDuration, trackMutes, changeSectionMute, regenerateSection, trackRegenerating, showComposer, noteSequences, odd }) {
  
  const prevRegenerating = usePrevious(currentSectionRegenerating);
  const prevWholeTrackRegenerating = usePrevious(wholeTrackRegenerating);

  const [regenFinished, setRegenFinished] = useState(false);
  const [sectionVelocity, setSectionVelocity] = useState(section.velocity);
  const [adjustingVolume, setAdjustingVolume] = useState(false);
  const [showNoteSequence, setShowNoteSequence] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [selectedSequenceTitle, setSelectedSequenceTitle] = useState(null);
  const volumeOffsetY = useRef(null);
  const sectionRef = useRef();

  const { t } = useTranslation();

  // TODO -> Add cleanup
  // useEffect(() => {
  //   return () => document.removeEventListener('mousemove', handleVolumeChange);
  // }, []);

  const resetFlash = useDebouncedCallback(() => setRegenFinished(false), 1100);

  const selectSequence = (sequenceId) => {
    setSelectedSequence(sequenceId);
    if (sequenceId !== null)
      setSelectedSequenceTitle(noteSequences.filter(sequence => sequence.id === sequenceId)[0].title);
    else
      setSelectedSequenceTitle(null);
  }

  useEffect(() => {
    if ((prevRegenerating === section.id && currentSectionRegenerating !== prevRegenerating)
      || (prevWholeTrackRegenerating && prevWholeTrackRegenerating !== wholeTrackRegenerating)) {
      setRegenFinished(true);
      resetFlash();
    }
  }, [currentSectionRegenerating, prevRegenerating, prevWholeTrackRegenerating, wholeTrackRegenerating, section.id, resetFlash]);

  const handleVolumeDragStart = e => {
    if (e.button === 0) {
      volumeOffsetY.current = e.clientY;
      setAdjustingVolume(true);
      document.addEventListener('mousemove', handleVolumeChange);
      document.addEventListener('mouseup', handleVolumeDragEnd);
    }
  }

  const handleVolumeChange = e => {
    const rect = sectionRef.current.getBoundingClientRect();

    const offset = (volumeOffsetY.current - e.clientY) * 100 / rect.height;
    const newVelocity = Math.round(Math.max(Math.min(sectionVelocity + offset, 100), 0));

    setSectionVelocity(newVelocity);
    adjustVolume(newVelocity);
    saveVolume(newVelocity);
  }

  const adjustVolume = useThrottledCallback(velocity => {
    LoopingAudio.setSectionVelocity(velocity, i, instrument.id);
  }, 100);

  const saveVolume = useDebouncedCallback(velocity => {
    axios.put(process.env.REACT_APP_NOMODO_API + `/song_sections/${section.id}`,
      { song_section: { velocity } },
      { headers: { Authorization: `Bearer ${Cookies.get('api_access_token')}` }
    });
  }, 1000);

  const handleVolumeDragEnd = () => {
    document.removeEventListener('mousemove', handleVolumeChange);
    setAdjustingVolume(false);
    saveVolume.flush();
  }

  const finished = currentSection > i;

  let sectionPercentage = 0;

  if (i === currentSection) {
    const previousSegment = i === 0 ? 0 : sectionLengths[i-1];
    const nextStartTime = i === sectionLengths.length - 1 ? songDuration : startTimes[i+1];

    sectionPercentage = (playbackPosition - previousSegment) * 100 / (nextStartTime - startTimes[i]);
  }

  const trackOff = trackMutes[instrument.id] || (hasSoloTrack && !trackSolos[instrument.id]);
  
  const regeneratingCurrentSection = currentSectionRegenerating === section.id;
  const regeneratingOtherParts = !regeneratingCurrentSection && (currentSectionRegenerating || trackRegenerating);

  const canRegenerate = !['melody', 'alto', 'tenor'].includes(instrument.category);

  const sectionClasses = classNames('section', {
    finished,
    narrow,
    'odd'                      : odd,
    'first'                    : i === 0,
    'last'                     : i === sectionLengths.length - 1,

    'track-off'                : trackOff || section.muted,
    'section-regenerate-flash' : regenFinished,
    'section-adjusting-volume' : adjustingVolume,
  });
  
  return (
    <div className={sectionClasses} ref={sectionRef}>
      <div className='section-playing' style={{ width: `${finished ? 100 : sectionPercentage}%` }} />
        { !trackOff && !section.muted && (
        <>
          <div className='section-volume-adjust' style={{ top: (100 - sectionVelocity) + '%' }} onMouseDown={handleVolumeDragStart}>
            <div className='section-volume-adjust-line' onMouseDown={handleVolumeDragStart} />
          </div>
          <div className='section-volume' style={{ top: (100 - sectionVelocity) + '%' }} />
          { !regeneratingOtherParts && canRegenerate && 
            <>
              <div role='button' className={classNames('section-regenerate', { 'section-regenerating': regeneratingCurrentSection, 'with-sequence': showComposer })} onClick={() => !regeneratingCurrentSection && regenerateSection(section.id, instrument.id, selectedSequence !== null ? parseInt(selectedSequence, 10) : undefined)}><RefreshIcon /></div>
              { showComposer && <div role='button' className={classNames('custom-note-sequence', {'has-selected-sequence': selectedSequence })} onClick={() => !regeneratingCurrentSection && setShowNoteSequence(true)}>{ selectedSequence !== null ? selectedSequenceTitle : '+'}</div> }
            </>
          }
        </>
      )}

      { !trackOff && (
        <div role='button' className='section-mute' onClick={() => changeSectionMute(section.id, !section.muted, instrument.id)}>
          { section.muted ? <MutedIcon /> : <VolumeIcon /> }
        </div>
      )}

      <Modal className='__modal' show={showNoteSequence} onHide={() => setShowNoteSequence(false)} size='sm'>
        <Modal.Header><Translate>Custom Loop</Translate></Modal.Header>
        <Modal.Body>
          <Form.Control as='select' value={selectedSequence || 'none'} onChange={e => selectSequence(parseInt(e.target.value))}>
            <option value='none' disabled selected>{t('Select a loop')}</option>
            { noteSequences.map(sequence => (
              <option key={sequence.id} value={sequence.id} title={sequence.title}>{sequence.title}</option>
            ))}
          </Form.Control>
          { selectedSequence !== null && <div className='text-center mt-2'><Button variant='link' onClick={() => selectSequence(null)}><Translate>Remove Custom Loop</Translate></Button></div> }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowNoteSequence(false)} variant='secondary'><Translate>Close</Translate></Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import { Trans as Translate } from 'react-i18next';
import InfiniteLoader from '../layout/InfiniteLoader/InfiniteLoader';

import './Modal.scss';

export default function LoadingOverlay({ show, children, phrase }) {
  return show && (
    <div className='__modal-loading-overlay'>
      <div className='__modal-loading-overlay-inner'>
        <InfiniteLoader width={32} height={32} />
        { phrase && <p className='__modal-loading-overlay-phrase'><Translate>{ phrase }</Translate></p> }
        { children && <div className='__modal-loading-overlay-chidren'>{ children }</div> }
      </div>
    </div>
  );
}

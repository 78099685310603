import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

import { resetAccessToken } from "../../api/strofeApi";
import { usersActions } from "../../store/usersSlice";

import { unwrapResult } from "@reduxjs/toolkit";

export default function Presentation() {
  const { identifier } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const startSession = async () => {
      const action = await dispatch(usersActions.investorLogin({ investor_identifier: identifier }));
      const { data } = unwrapResult(action);
      
      Cookies.set('api_access_token', data.access_token, { expires: 60 });
      Cookies.set('strofe_user_id', data.user_id, { expires: 60 });

      resetAccessToken(data.access_token);
      history.push('/intro');
    }

    startSession();
  }, [identifier, history, dispatch]);

  return (
    <>
    </>
  );
}
import * as React from "react";

const InstrumentTrumpet = (props) => (
  <svg
    id="InstrumentTrumpet_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentTrumpet_svg__st4{fill:#f18860}.InstrumentTrumpet_svg__st6{fill:#ca5672}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      d="m85.06 5.97 15.81 16.99c.29.31.04.81-.38.76-3.3-.37-12.56-.39-22.55 8.76-3.58 2.98-6.95 6.26-10.41 9.38l-21 18.96c-5.81 5.25-11.63 10.5-17.44 15.75-2.62 2.37-4.8 4.63-3.43 8.49.99 2.8 4.25 4.63 7.15 4.09 2.36-.44 6.02-3.77 6.02-3.77L80.5 46.74s3.42-3.27 3.71-5.52c.37-2.91-2.43-5.77-5.34-5.47-2.07.21-3.76 1.69-5.29 3.1-8.99 8.24-17.98 16.48-26.97 24.73-8.88 8.14-17.75 16.3-26.66 24.42l-2.48 2.26c-.18.17-.47.16-.64-.02l-.98-1.04a.459.459 0 0 1 .03-.65c7.26-6.59 14.54-13.17 21.81-19.75 6.73-6.09 13.46-12.17 20.19-18.26 5.17-4.68 10.35-9.36 15.52-14.04 1.06-.96 1.47-1.25 2.53-2.21 1.25-1.13 3.98-.93 5.38-.41 3.52 1.32 6.88 6.26 4.75 9.83-1.25 2.09-3.4 3.91-5.11 5.68-1.51 1.56-3.05 3.09-4.6 4.61-3.76 3.69-7.59 7.3-11.45 10.89-7.57 7.03-15.23 13.99-23.17 20.6-2.4 2-4.77 4.63-7.88 5.63-3.01.97-6.15-.01-8.27-2.29-1.95-2.1-3.26-5.03-2.86-7.93.37-2.64 2.23-4.27 3.98-6.07 3.42-3.54 6.92-7.01 10.41-10.49 7.82-7.79 24.69-24.59 34.28-34.6C83.5 17.31 84.49 9.39 84.27 6.31c-.03-.43.49-.65.79-.34z"
      style={{
        fill: "#cfd125",
      }}
    />
    <path
      className="InstrumentTrumpet_svg__st6"
      d="m15.25 88.99-4.22 1.74a.46.46 0 0 0-.18.71l3.1 3.78c.21.25.6.21.75-.07l2.29-4.29c.09-.17.06-.38-.07-.53l-1.16-1.23c-.14-.14-.34-.19-.51-.11z"
    />
    <circle
      className="InstrumentTrumpet_svg__st6"
      cx={35.85}
      cy={55.48}
      r={2.31}
    />
    <circle
      className="InstrumentTrumpet_svg__st6"
      cx={40.15}
      cy={51.38}
      r={2.31}
    />
    <circle
      className="InstrumentTrumpet_svg__st6"
      cx={44.38}
      cy={47.58}
      r={2.31}
    />
    <path
      className="InstrumentTrumpet_svg__st4"
      d="m57.65 78.67-.34.32c-.89.82-2.27.76-3.08-.13L39.48 62.81c-.82-.89-.76-2.27.13-3.08l.34-.32c.89-.82 2.27-.76 3.08.13l14.75 16.05c.82.88.76 2.26-.13 3.08zM61.81 74.8l-.34.32c-.89.82-2.27.76-3.08-.13L43.64 58.94c-.82-.89-.76-2.27.13-3.08l.34-.32c.89-.82 2.27-.76 3.08.13l14.75 16.05c.82.89.76 2.27-.13 3.08zM65.98 70.94l-.34.32c-.89.82-2.27.76-3.08-.13L47.8 55.07c-.82-.89-.76-2.27.13-3.08l.34-.32c.89-.82 2.27-.76 3.08.13L66.1 67.85c.82.89.76 2.27-.12 3.09z"
    />
  </svg>
);

export default InstrumentTrumpet;

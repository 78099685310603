export default function AnimaySaleAvatar(props) {
  return (
    <svg x={0} y={0} viewBox="0 0 375.7 511" style={{ enableBackground: "new 0 0 375.7 511" }} xmlSpace="preserve" {...props}>
      <style>
        {
          ".__anime-sale-avatar-0,.__anime-sale-avatar-1,.__anime-sale-avatar-3,.__anime-sale-avatar-4{fill:#fff;stroke:#000;stroke-width:2;stroke-miterlimit:10}.__anime-sale-avatar-1,.__anime-sale-avatar-3,.__anime-sale-avatar-4{fill:none}.__anime-sale-avatar-3,.__anime-sale-avatar-4{fill:#fdcb75}.__anime-sale-avatar-4{fill:#c95671;stroke-linecap:round;stroke-linejoin:round}.__anime-sale-avatar-6{fill:#f8ccc2}.__anime-sale-avatar-7{fill:#f18860}.__anime-sale-avatar-8{fill:#c95671}.__anime-sale-avatar-12,.__anime-sale-avatar-14,.__anime-sale-avatar-15{fill:#f18860;stroke:#000;stroke-width:2;stroke-miterlimit:10}.__anime-sale-avatar-14,.__anime-sale-avatar-15{fill:#c95671}.__anime-sale-avatar-15{fill:#ced025}.__anime-sale-avatar-16{fill:#1f3037}.__anime-sale-avatar-16,.__anime-sale-avatar-17,.__anime-sale-avatar-18,.__anime-sale-avatar-19,.__anime-sale-avatar-20{stroke:#000;stroke-width:2;stroke-miterlimit:10}.__anime-sale-avatar-18,.__anime-sale-avatar-19,.__anime-sale-avatar-20{fill:none;stroke-linecap:round;stroke-linejoin:round}.__anime-sale-avatar-19,.__anime-sale-avatar-20{fill:#fdcb75}.__anime-sale-avatar-20{fill:#f18860}"
        }
      </style>
      <path
        className="__anime-sale-avatar-0"
        d="M50.1 475.6c8.6 6.7 19.4 10.7 31.2 10.7 11.3 0 21.8-3.7 30.3-10 12.8 16.2 32.6 26.6 54.8 26.6 11.9 0 23.1-3 33-8.3 6.8 7 16.2 11.4 26.7 11.4 10.6 0 20.2-4.5 27-11.7 5.8 1.5 11.8 2.4 18.1 2.4 37 0 67.3-28.8 69.7-65.3 18.2-7.8 30.9-25.8 30.9-46.8 0-28.1-22.8-50.8-50.8-50.8-18 0-33.9 9.4-42.9 23.5-2.2-.2-4.5-.3-6.8-.3h-.4c0-2.7-.4-5.4-1-8.2C265 329 245 316.6 225 321.4c-6.4 1.5-12 4.6-16.6 8.8-6.7-3.3-14.3-5.2-22.4-5.2-5.9 0-11.6 1-16.9 2.9-6.4-5.3-14.6-8.4-23.5-8.4-10.4 0-19.8 4.3-26.5 11.2-12.1-20.9-34.7-35-60.6-35-12.5 0-24.2 3.3-34.3 9"
      />
      <path
        className="__anime-sale-avatar-1"
        d="M31.4 301.1c8.4-3.5 17.5-5.5 27.2-5.5 28.9 0 53.7 17.6 64.3 42.7M246.4 438.5c9.9 6.7 16.4 18 16.4 30.8 0 9.6-3.6 18.3-9.5 24.9"
      />
      <path
        className="__anime-sale-avatar-3"
        d="M152.4 400.4c-3.8-1.3-8-2.5-11.5-.6-1.4.7-2.6 2.1-2.4 3.7.2 1.8 1.9 4.7 4.6 4.7 0 0 12.1.6 12.2.5.2-.1 1.9-.5 2.1-.8.5-.8.6-1.7.6-2.6l-1.3-62.5c0-2-.5-4.6-2.5-5.1-1.7-.4-3.4 1.3-3.6 3-.2 1.8 1.4 40.4 1.8 59.7z"
      />
      <path
        className="__anime-sale-avatar-4"
        d="M222.3 330.9h0c.1-.6.2-1.3.3-1.9-1.9.3-4.1.6-6.3.9 1.6.5 3.6.9 6 1z"
      />
      <path
        d="m271.3 264.9-32.8-41.6s-39.8 5.2-112.5-.4c.3 1-17.7 24.4-32.3 39.4 12.2 8.7 21.3 12 34.1 12.4 0 0-.8 40.6-1.2 54.8.6.2 1.2.3 1.9.4 11.9 2.7 24.3 2.7 36.5 2.5 10.9-.1 29.2-.6 44.1-1.9 2.5-.2 4.9-.5 7.2-.7 2.3-.3 4.4-.5 6.3-.9 5-.8 8.7-1.8 10.2-3 .2.1-4.9-42.5-4.9-42.5s39.8-8.8 43.4-18.5z"
        style={{
          fill: "#4d3f92",
          stroke: "#000",
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
      <path
        className="__anime-sale-avatar-6"
        d="m167.6 268.4-.1-.1-8-4.8c-.2-.1-.3-.4-.1-.6.1-.2.4-.3.6-.1l7.9 4.7 36.8-4c.2 0 .4.1.5.4 0 .2-.1.4-.4.5l-37.2 4z"
      />
      <path
        className="__anime-sale-avatar-7"
        d="m181.5 265-25.3-6c-.2-.1-.4-.3-.3-.5.1-.2.3-.4.5-.3l25.1 6 25.7-5.7c.2 0 .4.1.5.3 0 .2-.1.4-.3.5l-25.9 5.7z"
      />
      <path
        className="__anime-sale-avatar-8"
        d="M155.9 268c-.2 0-.3-.1-.4-.3-.1-.2.1-.4.3-.5l20.8-6.2 19.1-3.4 13.6 5.1c.2.1.3.3.2.5-.1.2-.3.3-.5.2l-13.4-5.1-18.9 3.4L156 268h-.1z"
      />
      <path
        d="M162.3 279c-.5-.3-1-.6-1.7-.9l-.6-.3c-1.2-.6-1.7-.9-1.7-1.8s.5-1.7 1.7-1.7c.5 0 1.3.2 1.9 1.2l.2.4 1.2-1-.2-.3c-.6-1.2-1.8-1.8-3.1-1.8-2.2 0-3.3 1.6-3.3 3.2 0 .5.1 1 .4 1.5.5.8 1.1 1.1 2.2 1.6.9.5 1.6.8 1.9 1 .8.5.9 1.1.9 1.4 0 1.3-1 2.2-2.3 2.2-.7 0-1.4-.3-1.8-.8-.1-.2-.4-.6-.5-1l-.1-.4-1.4.6.1.3c.1.3.3.8.8 1.4.5.6 1.3 1.3 2.9 1.3 2.2 0 3.9-1.6 3.9-3.7 0-1.3-.8-2-1.4-2.4z"
        style={{
          fill: "#fcca75",
        }}
      />
      <path className="__anime-sale-avatar-6" d="M164.1 274.4h2.9V285h1.6v-10.6h2.9v-1.5h-7.4z" />
      <path
        className="__anime-sale-avatar-8"
        d="M177 279.4c1.4-.5 2.2-1.6 2.2-3.1 0-.8-.2-2-1.3-2.8-.9-.6-1.8-.6-2.6-.6h-2V285h1.5v-5l3.4 5h1.8l-3.7-5.4c.2 0 .5-.1.7-.2zm-.2-4.7c.2.1.8.5.8 1.6 0 1.2-.8 1.9-2.2 1.9h-.7v-3.8h.5c.6 0 1.3.1 1.6.3z"
      />
      <path
        d="M187.4 272.7c-3.5 0-6.3 2.7-6.3 6.3 0 3.5 2.8 6.2 6.3 6.2s6.3-2.7 6.3-6.2c-.1-3.5-2.8-6.3-6.3-6.3zm0 11.1c-2.7 0-4.7-2-4.7-4.8 0-2.7 2-4.8 4.7-4.8s4.7 2.1 4.7 4.8c0 2.7-2.1 4.8-4.7 4.8z"
        style={{
          fill: "#ced025",
        }}
      />
      <path
        style={{
          fill: "#528452",
        }}
        d="M196 285h1.6v-5.8h3.7v-1.5h-3.7v-3.3h3.8v-1.5H196z"
      />
      <path
        className="__anime-sale-avatar-7"
        d="M209.9 274.4v-1.5h-6.3V285h6.3v-1.4h-4.7v-4.4h4.6v-1.5h-4.6v-3.3zM184.2 255.2H183.3v7.5c-1.4.1-2.5.8-2.9 1.9-.3.7 0 1.2.7 1.3 1.4.1 3.2-1.4 3.1-2.7v-8z"
      />
      <path
        className="__anime-sale-avatar-12"
        d="m269.2 247.5 14.5-29.6s13.4 43.4-1.5 48.5l-13-18.9z"
      />
      <path
        className="__anime-sale-avatar-3"
        d="M231.1 217.8h-99.9c-15.3 0-27.8-12.5-27.8-27.8V86.7c0-15.3 12.5-27.8 27.8-27.8h99.9c15.3 0 27.8 12.5 27.8 27.8V190c0 15.3-12.5 27.8-27.8 27.8z"
      />
      <path
        className="__anime-sale-avatar-0"
        d="M141.5 168.9s50-1.9 75.6-14.6c2.6-1.3 2 11.5 1.9 13.1-.5 4.8-1.8 9.3-4.2 13.2-7.2 11.3-20.2 12.5-30.6 12.4-14.2-.3-37.3-4.7-42.7-24.1z"
      />
      <path
        d="m301.9 104.5-44.6-32.4 34.9-20.9-43.8 2.9c.7 3.3 1 6.6.8 10 0 0-27.8-6.2-72.7-5.3 1.6.5 3.2 1 4.8 1.6 0 0-.2.1-.5.4 18.9 2.6 37 7.4 53.3 12.8 39.4 13.4 67.8 30.9 67.8 30.9z"
        style={{
          fill: "#528452",
          stroke: "#000",
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
      <path
        className="__anime-sale-avatar-14"
        d="M176.4 58.9c44.9-.9 72.7 5.3 72.7 5.3.2-3.4-.1-6.8-.8-10-6.7-31.8-51.7-55.1-93.1-46.7-42 8.6-57.7 52.5-60 59.3 22.5-9.3 54.7-15.7 81.2-7.9z"
      />
      <path
        className="__anime-sale-avatar-14"
        d="M176.4 58.9c-26.5-7.8-58.7-1.4-81.1 7.9C68.7 77.9 55.9 93 83 93.4c48.3.8 92.5-29 97.7-32.6.3-.2.5-.4.5-.4l-4.8-1.5z"
      />
      <ellipse className="__anime-sale-avatar-15" cx={218.9} cy={119.5} rx={19.9} ry={25.8} />
      <path
        className="__anime-sale-avatar-16"
        d="M238.1 122.3c.1-1 .2-2 .2-3.1 0-13.8-8.7-25.1-19.4-25.1s-19.4 11.2-19.4 25.1c0 1.2.1 2.3.2 3.5 4.3-3.2 18.7-11.6 38.4-.4z"
      />
      <circle className="__anime-sale-avatar-17" cx={219.5} cy={123.6} r={7.6} />
      <ellipse className="__anime-sale-avatar-0" cx={226.5} cy={114.2} rx={7.4} ry={8.8} />
      <ellipse className="__anime-sale-avatar-0" cx={212.5} cy={118.9} rx={5.5} ry={6.4} />
      <ellipse className="__anime-sale-avatar-0" cx={216.8} cy={133} rx={2.7} ry={1.8} />
      <path
        className="__anime-sale-avatar-18"
        d="M193.2 105.4c13.8-17.6 43.1-15 51.5 6.3 1.9 4.7 2.9 9.8 3.6 14.8M212.5 145.6h12.3"
      />
      <ellipse className="__anime-sale-avatar-15" cx={143.2} cy={119.9} rx={19.9} ry={25.8} />
      <path
        className="__anime-sale-avatar-16"
        d="M124.1 122.6c-.1-1-.2-2-.2-3.1 0-13.7 8.6-24.8 19.2-24.8s19.2 11.1 19.2 24.8c0 1.2-.1 2.3-.2 3.5-4.2-3.2-18.4-11.5-38-.4z"
      />
      <circle className="__anime-sale-avatar-17" cx={142.6} cy={124} r={7.6} />
      <ellipse className="__anime-sale-avatar-0" cx={135.5} cy={114.7} rx={7.4} ry={8.8} />
      <ellipse className="__anime-sale-avatar-0" cx={149.6} cy={119.3} rx={5.5} ry={6.4} />
      <ellipse className="__anime-sale-avatar-0" cx={145.3} cy={133.4} rx={2.7} ry={1.8} />
      <path
        className="__anime-sale-avatar-18"
        d="M168.9 105.9c-13.8-17.6-43.1-15-51.5 6.3-1.9 4.7-2.9 9.8-3.6 14.8M135.9 146.4h12.2"
      />
      <path
        className="__anime-sale-avatar-19"
        d="M104.5 269.1s-7.5 9.3-8 14.1c-.4 4.9 33.1 18 33.2 21.7.1 3.7-12.4 8-17.3 7.5l12.8 9.3s11.5-1.7 8-17.7c-1.8-8-21.7-19-21.7-19l6.2-11.1-13.2-4.8z"
      />
      <path
        className="__anime-sale-avatar-20"
        d="M248.3 299c-9.7 0-16 5.1-20 12-3.4 5.9-5.1 13.2-6 19.9v0c-18.2-.7-15.9-18.4-15.9-18.4-4.5 15.7 6.5 22.6 11.4 24.8 4.1-1.6 8.6-2.4 13.2-2.4 3.2 0 6.3.4 9.3 1.2 7.4 1.9 13.9 6 18.8 11.6 2.7-2.7 5.9-4.8 9.5-6.2 1.5-16.9.5-42.5-20.3-42.5z"
      />
      <path
        className="__anime-sale-avatar-4"
        d="M195.3 341c4.1 0 8 1 11.4 2.8 3.2-2.8 6.9-5 11-6.5-2.3-1-6-3.1-8.8-6.7-14.9 1.3-33.2 1.8-44.1 1.9-12.2.1-24.6.2-36.5-2.5l-4.6 32.2 41.5 2.8c1.4-3.1 3.1-6.1 5.1-8.9.1-.2.6 1 1.2 2.5 3.2-10.2 12.6-17.6 23.8-17.6z"
      />
      <path
        className="__anime-sale-avatar-0"
        d="M277.9 339.7c-3.3 0-6.5.6-9.4 1.7-3.6 1.4-6.9 3.5-9.5 6.2-4.9-5.6-11.4-9.7-18.8-11.6-3-.8-6.1-1.2-9.3-1.2-4.7 0-9.1.9-13.2 2.4s-7.8 3.8-11 6.5c-3.4-1.8-7.3-2.8-11.4-2.8-11.2 0-20.7 7.3-23.9 17.5-.6 2-1 4.2-1.1 6.4v1.2c0 10.2 6.1 18.9 14.7 22.8-.1.8-.1 1.6-.1 2.4 0 12.6 10.2 22.8 22.8 22.8 5.8 0 11.1-2.2 15.2-5.8 2.6.6 5.3.9 8.1.9 14.4 0 27-8.2 33.1-20.3 4 2.5 8.8 3.9 13.8 3.9 14.7 0 26.6-11.9 26.6-26.6s-11.9-26.4-26.6-26.4z"
      />
      <path
        className="__anime-sale-avatar-1"
        d="M265.2 385.3c-5.3 14.1-18.9 24.2-34.9 24.2-2.6 0-5.1-.3-7.5-.8"
      />
      <path className="__anime-sale-avatar-0" d="M206.7 344c3.4 1.6 6.3 4 8.7 6.9" />
      <path
        className="__anime-sale-avatar-20"
        d="M233 313.4s-14.2 24.8-3.6 44.5c0 0-4.3 1.8-2 4s12.2 0 12.2 0l4-32.1s1.8-.9 4.4-.9c2.7 0 4 .9 4 .9l4 32.1s9.8 2.2 12 0-2-4-2-4 1-18.8.1-20.6"
      />
      <path
        className="__anime-sale-avatar-18"
        d="M227.4 361.9s-4.9-6.6-14.2-2.7M238.5 362.3c4.8-3.6 18-1.7 17.7-.4M268.2 361.9s1.3-4 10.2-1.8M109.1 411.3s13.8-7.7 31.8-3 37.6-1.5 37.6-1.5"
      />
      <path
        className="__anime-sale-avatar-19"
        d="M240.4 274.2c-3.2 3 25.2 16.1 24.5 25.8-.5 5.9-5.8 6.7-5.8 6.7l7.1 16.4s6.5-8.8 7.1-9.7c5.8-10.2-.2-15.4-6.2-24.2-2.1-3.1-4.4-6.1-6.7-9.1l-20-5.9"
      />
      <path
        className="__anime-sale-avatar-12"
        d="M235.6 246.6 221.1 217s-13.4 43.4 1.5 48.5l13-18.9z"
      />
      <path
        className="__anime-sale-avatar-12"
        d="m221.4 286.2 1.3-25.6c.5-8.6 7.8-15.2 16.4-14.8l28.2 1.5c8.6.5 15.2 7.8 14.8 16.4l-1.3 25.6c-.5 8.6-7.8 15.2-16.4 14.8l-28.2-1.5c-8.6-.4-15.2-7.8-14.8-16.4z"
      />
      <path
        className="__anime-sale-avatar-17"
        d="m250.5 277.4 5.1.1c.6 0 .9.7.4 1.1-.8.7-1.9 1.5-2.9 1.5s-2.2-.9-3-1.7c-.5-.3-.2-1 .4-1z"
      />
      <circle className="__anime-sale-avatar-14" cx={224.3} cy={284.3} r={5.8} />
      <circle className="__anime-sale-avatar-14" cx={277} cy={287} r={5.8} />
      <circle className="__anime-sale-avatar-17" cx={273.1} cy={269.3} r={9.3} />
      <circle className="__anime-sale-avatar-0" cx={276.2} cy={265.6} r={3.1} />
      <circle className="__anime-sale-avatar-17" cx={231.4} cy={268.8} r={9.3} />
      <circle className="__anime-sale-avatar-0" cx={234.5} cy={265.1} r={3.1} />
    </svg>
  )
}

export default function OddityOutline(props) {
  return (
    <svg viewBox="0 0 116.1 113.1" {...props}>
    <style>
      {
      ".__oddity-outline-prefix__cls-0{fill:none;stroke:#CFD4D8;stroke-width:2;stroke-miterlimit:10;}.__oddity-outline-prefix__cls-1{fill:none;stroke:#CFD4D8;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.__oddity-outline-prefix__cls-2{fill:#CFD4D8;stroke:#CFD4D8;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.__oddity-outline-prefix__cls-3{fill:#CFD4D8;}.__oddity-outline-prefix__cls-4{fill:#FFFFFF;}"
      }
    </style>
    <path className="__oddity-outline-prefix__cls-0" d="M19,1.5h77.5c9.2,0,16.7,7.5,16.7,16.7v77.5c0,9.2-7.5,16.7-16.7,16.7H19c-9.2,0-16.7-7.5-16.7-16.7V18.1
      C2.3,8.9,9.8,1.5,19,1.5z"/>
    <g>
      <polygon className="__oddity-outline-prefix__cls-1" points="25.8,47.3 58,63.8 90.3,47.3 58.9,33.4  "/>
      <polygon className="__oddity-outline-prefix__cls-1" points="58,100.9 58,63.8 25.8,47.3 26.5,83.2   "/>
      <polygon className="__oddity-outline-prefix__cls-2" points="58,63.8 45.3,73.5 13.6,56.4 25.8,47.3  "/>
      <polygon className="__oddity-outline-prefix__cls-1" points="58.1,100.9 58.1,63.8 90.3,47.3 89.6,83.2   "/>
      <polygon className="__oddity-outline-prefix__cls-2" points="58.1,63.8 70.9,73.5 102.5,56.4 90.3,47.3   "/>
      <path className="__oddity-outline-prefix__cls-3" d="M54.8,12.8l6.6,10c0.3,0.4,0.8,0.6,1.3,0.5l11.5-3.2c1.1-0.3,2,1,1.3,1.9L68,31.3c-0.3,0.4-0.4,1-0.1,1.4
        l6.6,10c0.7,1-0.3,2.2-1.5,1.8l-11.2-4.2c-0.5-0.2-1.1,0-1.4,0.4L53,50c-0.7,0.9-2.2,0.4-2.2-0.8l0.6-11.9c0-0.5-0.3-1-0.8-1.2
        l-11.2-4.2c-1.1-0.4-1-2,0.1-2.3L51,26.4c0.5-0.1,0.9-0.6,0.9-1.1l0.5-11.9C52.6,12.2,54.1,11.8,54.8,12.8z"/>
    </g>
    </svg>
  )
}

export default function SlideController(props) {
  return (
    <svg
      width={100}
      height={100}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 73.7 59.3"
      {...props}
    >
      <style>
        {
          ".__strofe_slide_controller_4{fill:#FFFFFF;}.__strofe_slide_controller_15{fill:#020203;stroke:#1E1E1C;stroke-width:1.9018;stroke-miterlimit:10;}.__strofe_slide_controller_16{fill:#FFFFFF;stroke:#1E1E1C;stroke-width:0.1655;stroke-miterlimit:10;}.__strofe_slide_controller_17{fill:none;stroke:#1E1E1C;stroke-width:2.2933;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"
        }
      </style>
      <g>
        <path className="__strofe_slide_controller_15" d="M55.7,47.2H21.3c-7.9,0-14.4-6.5-14.4-14.4v0c0-7.9,6.5-14.4,14.4-14.4h34.4c7.9,0,14.4,6.5,14.4,14.4v0
          C70.1,40.7,63.6,47.2,55.7,47.2z"/>
        <path className="__strofe_slide_controller_16" d="M28.1,31.1h-2.5c-0.6,0-1.1-0.5-1.1-1.1v-2.8c0-0.6-0.5-1.2-1.2-1.2h-1.9c-0.6,0-1.2,0.5-1.2,1.2V30
          c0,0.6-0.5,1.1-1.1,1.1h-2.4c-0.9,0-1.6,0.7-1.6,1.6v1.2c0,0.9,0.7,1.6,1.6,1.6h2.4c0.6,0,1.1,0.5,1.1,1.1v2.8
          c0,0.6,0.5,1.2,1.2,1.2h1.9c0.6,0,1.2-0.5,1.2-1.2v-2.8c0-0.6,0.5-1.1,1.1-1.1h2.5c0.9,0,1.6-0.7,1.6-1.6v-1.2
          C29.7,31.8,28.9,31.1,28.1,31.1z"/>
        <circle className="__strofe_slide_controller_4" cx="54.3" cy="27.8" r="2.9"/>
        <circle className="__strofe_slide_controller_4" cx="54.3" cy="39.2" r="2.9"/>
        <circle className="__strofe_slide_controller_4" cx="48.6" cy="33.4" r="2.9"/>
        <circle className="__strofe_slide_controller_4" cx="60" cy="33.4" r="2.9"/>
        <path className="__strofe_slide_controller_17" d="M38.1,18.2c0,0-0.5-5.1,1.4-6.6c2.3-2,5.6,0.2,8,1c1.6,0.5,3.4,0.2,4.3-1.3c0.8-1.3,0.9-3.5-0.7-4.3"/>
      </g>
    </svg>
  );
}

import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";
import SlideMarketValidationBG from "../assets/SlideMarketValidationBG";

export default function SlideMarketValidation() {

  const bullet = (
    <div className='bullet'>
      <div className='yellow-circle' />
      <div className='lime-circle' />
      <div className='red-circle' />
    </div>
  )

  return (
    <div className='slide slide-market-validation'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />

      <div className='bullet-title'>
        { bullet }
        <h1>But why stop at Game Devs?</h1>
      </div>

      <div className='content'>
        <SlideMarketValidationBG className='bg' width={340} height={340} />
        <div className='total-users'>96M Content Creators</div>

        <div className='video-game-devs'>
          <div className='bullet'>35M Video Game Devs</div>
          <div className='line' />
          <div className='head' />
        </div>

        <div className='youtubers'>
          <div className='bullet'>51M Youtubers</div>
          <div className='line' />
          <div className='head' />
        </div>

        <div className='streamers'>
          <div className='bullet'>10M Streamers</div>
          <div className='line' />
          <div className='head' />
        </div>
      </div>
      <div className='footer'>
        <a target="_blank" rel="noreferrer" href="https://web.archive.org/web/20160730021122/http://unity3d.com:80/public-relations">https://web.archive.org/web/20160730021122/http://unity3d.com:80/public-relations</a>
        <a target="_blank" rel="noreferrer" href="https://backlinko.com/roblox-users">https://backlinko.com/roblox-users</a>
        <a target="_blank" rel="noreferrer" href="https://www.tubics.com/blog/number-of-youtube-channels">https://www.tubics.com/blog/number-of-youtube-channels</a>
        <a target="_blank" rel="noreferrer" href="https://www.statista.com/statistics/746173/monthly-active-streamers-on-twitch">https://www.statista.com/statistics/746173/monthly-active-streamers-on-twitch</a>
      </div>
    </div>
  )
}

import * as React from "react";

const InstrumentHammondOrgan = (props) => (
  <svg
    id="InstrumentHammondOrgan_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentHammondOrgan_svg__st2{fill:#fdcc75}.InstrumentHammondOrgan_svg__st28{fill:#fff}.InstrumentHammondOrgan_svg__st35{fill:#1f3138}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#538553",
      }}
    />
    <path
      className="InstrumentHammondOrgan_svg__st2"
      d="M94.29 48.11H16.77V31.1c0-1.18.96-2.14 2.14-2.14h73.24c1.18 0 2.14.96 2.14 2.14v17.01zM14.73 64.65h82.06v20.61H14.73zM70.82 28.96H38.49v-2.94c0-2.29 1.85-4.14 4.14-4.14h24.05c2.29 0 4.14 1.85 4.14 4.14v2.94z"
    />
    <path
      d="M95.17 62.9h-79.5c-.84 0-1.53-.68-1.53-1.53v-22.2c0-.84.68-1.53 1.53-1.53h79.49c.84 0 1.53.68 1.53 1.53v22.2c0 .85-.68 1.53-1.52 1.53z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      d="M100.48 64.65H10.36c-.71 0-1.29-.58-1.29-1.29v-13.4c0-.71.58-1.29 1.29-1.29h90.12c.71 0 1.29.58 1.29 1.29v13.41c0 .7-.58 1.28-1.29 1.28z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      className="InstrumentHammondOrgan_svg__st28"
      d="M18.76 48.67h6.61v5.97h-6.61zM25.91 48.67h5.97v5.97h-5.97zM32.43 48.67h6.08v5.97h-6.08zM39.05 48.67h6.02v5.97h-6.02zM45.61 48.67h6.08v5.97h-6.08zM52.23 48.67h5.97v5.97h-5.97zM58.75 48.67h6.05v5.97h-6.05zM65.34 48.67h6.14v5.97h-6.14zM72.02 48.67h6.15v5.97h-6.15zM78.71 48.67h6.07v5.97h-6.07zM85.32 48.67h6.14v5.97h-6.14z"
    />
    <path
      className="InstrumentHammondOrgan_svg__st35"
      d="M23.25 45.18h4.82v3.49h-4.82zM29.47 45.18h4.82v3.49h-4.82zM68.86 45.18h4.82v3.49h-4.82zM75.3 45.18h4.82v3.49H75.3zM81.74 45.18h4.82v3.49h-4.82zM43.58 45.18h4.82v3.49h-4.82zM49.96 45.18h4.82v3.49h-4.82zM56.34 45.18h4.82v3.49h-4.82z"
    />
    <path
      className="InstrumentHammondOrgan_svg__st28"
      d="M22.58 37.66h5.88v5.32h-5.88zM28.95 37.66h5.32v5.32h-5.32zM34.75 37.66h5.42v5.32h-5.42zM40.65 37.66h5.36v5.32h-5.36zM46.49 37.66h5.41v5.32h-5.41zM52.39 37.66h5.32v5.32h-5.32zM58.19 37.66h5.38v5.32h-5.38zM64.06 37.66h5.47v5.32h-5.47zM70.01 37.66h5.47v5.32h-5.47zM75.96 37.66h5.4v5.32h-5.4zM81.85 37.66h5.46v5.32h-5.46z"
    />
    <path
      className="InstrumentHammondOrgan_svg__st35"
      d="M26.58 34.55h4.29v3.1h-4.29zM32.12 34.55h4.29v3.1h-4.29zM67.19 34.55h4.29v3.1h-4.29zM72.93 34.55h4.29v3.1h-4.29zM78.66 34.55h4.29v3.1h-4.29zM44.68 34.55h4.29v3.1h-4.29zM50.36 34.55h4.29v3.1h-4.29zM56.04 34.55h4.29v3.1h-4.29z"
    />
    <circle
      className="InstrumentHammondOrgan_svg__st35"
      cx={14.23}
      cy={87.31}
      r={2.05}
    />
    <circle
      className="InstrumentHammondOrgan_svg__st35"
      cx={97.85}
      cy={87.3}
      r={2.05}
    />
  </svg>
);

export default InstrumentHammondOrgan;

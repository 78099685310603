import * as React from "react";

const InstrumentBassoon = (props) => (
  <svg
    id="InstrumentBassoon_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.39"
    style={{
      enableBackground: "new 0 0 110.84 110.39",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentBassoon_svg__st2{fill:#fdcc75}.InstrumentBassoon_svg__st5{fill:#cfd125}"
      }
    </style>
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      d="M48.54 70.98c.15-.19.34-.44.56-.72l25.1-29.52c-.13-.69-.49-.95-.76-1.17 8.95-11.24 17.13-21.49 18.4-23.1.11-.14.17-.22.17-.22s1.58-1.81.28-2.92c-1.48-1.27-2.97.62-2.97.62s-.12.13-.34.39C84.91 18.99 45.28 64.2 43 66.58c-2.4 2.51-22.71 26.97-22.71 26.97 1.79 2.46 4.54 3.88 4.54 3.88s19.12-20.49 23.71-26.45z"
      style={{
        fill: "#ca5672",
      }}
    />
    <path
      className="InstrumentBassoon_svg__st2"
      d="M74.33 36.88c.25.05 1.2.29 1.54 1.32.01.04.01.08-.01.11l-1.45 2.41c-.06.1-.21.07-.23-.04-.1-.45-.47-1.19-1.86-1.76-.09-.03-.11-.15-.04-.21l1.94-1.8c.03-.02.07-.03.11-.03zM20.19 93.14s.64 1.62 4.96 4.35l-4.42 4.28-3.76-4.01 3.22-4.62z"
    />
    <circle
      className="InstrumentBassoon_svg__st5"
      cx={22.9}
      cy={90.89}
      r={1.38}
    />
    <circle
      className="InstrumentBassoon_svg__st5"
      cx={24.05}
      cy={94.11}
      r={1.24}
    />
    <circle
      className="InstrumentBassoon_svg__st5"
      cx={34.74}
      cy={79.13}
      r={1.09}
    />
    <circle
      className="InstrumentBassoon_svg__st5"
      cx={32.61}
      cy={81.23}
      r={0.9}
    />
    <circle
      className="InstrumentBassoon_svg__st5"
      cx={43.29}
      cy={73.13}
      r={1.25}
    />
    <path
      className="InstrumentBassoon_svg__st2"
      d="M44.31 64.71s3.86 1.25 5.44 4.97l-1.7 1.99s-2.09-3.26-5.49-4.91l1.75-2.05z"
    />
    <circle
      className="InstrumentBassoon_svg__st5"
      cx={61.14}
      cy={48.54}
      r={1.06}
    />
    <circle
      className="InstrumentBassoon_svg__st5"
      cx={48.37}
      cy={63.11}
      r={1.06}
    />
    <path
      className="InstrumentBassoon_svg__st2"
      d="M92.29 13.34c1.3 1.11-.28 2.92-.28 2.92s-.06.07-.17.22c.42-.12.82-.35 1.13-.71.81-.95.7-2.38-.25-3.19s-2.38-.7-3.19.25c-.38.44-.55.99-.54 1.52.22-.25.34-.39.34-.39s1.48-1.88 2.96-.62z"
    />
    <path
      d="M75.36 37.39s2.97-2.92.94-5.1-5.34-.38-6.61-1.34c-1.27-.96-7.93-6.84-7.93-6.84"
      style={{
        fill: "none",
        stroke: "#cfd125",
        strokeWidth: 1.0236,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <circle
      className="InstrumentBassoon_svg__st2"
      cx={47.24}
      cy={77.05}
      r={1.67}
    />
  </svg>
);

export default InstrumentBassoon;

import { useEffect } from "react";
import { useState } from "react";
import { strofeApi } from "../../api/strofeApi";
import { useDebouncedCallback } from "use-debounce/lib";

import NavigationHeader from "../NavigationHeader";
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";

import './PotentialInvestorStats.scss';

export default function PotentialInvestorStats() {
  const [potentialInvestors, setPotentialInvestors] = useState(null);
  const [loading, setLoading] = useState(false);

  const debouncedLoading = useDebouncedCallback(value => {
    setLoading(value);
  }, 200);

  useEffect(() => {
    const loadStats = async () => {
      debouncedLoading(true);
      const { data } = await strofeApi.get(`/potential_investors`);
      setPotentialInvestors(data);
      debouncedLoading.cancel();
      setLoading(false);
    }

    loadStats();
  }, [debouncedLoading]);

  const renderStats = () => {
    if (potentialInvestors === null ) {
      return null;
    }

    return <>
      { potentialInvestors.map(potentialInvestor => (
        <div className='investor'>
          <div className='id'>{potentialInvestor.id}</div>
          <div className='email'>{potentialInvestor.email}</div>
        </div>
      ))}
    </>
  }

  return (
    <div className='__potential-investor-stats'>
      <NavigationHeader />
      <h1 className='header'>Potential Investors</h1>

      { loading && (
        <div className='loading'>
          <InfiniteLoader width={100} />
        </div>
      )}

      { !loading && renderStats() }
    </div>
  );
}
import * as React from "react";

const InstrumentBrassSection = (props) => (
  <svg
    id="InstrumentBrassSection_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.1 110.1"
    style={{
      enableBackground: "new 0 0 110.1 110.1",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".InstrumentBrassSection_svg__st4{fill:#f18860}"}</style>
    <path
      d="M93.54 110.1H16.56C7.41 110.1 0 102.69 0 93.54V16.56C0 7.41 7.41 0 16.56 0h76.98c9.15 0 16.56 7.41 16.56 16.56v76.98c0 9.15-7.41 16.56-16.56 16.56z"
      style={{
        fill: "#ca5672",
      }}
    />
    <circle
      cx={74.46}
      cy={36.24}
      r={32.96}
      style={{
        fill: "#cfd125",
      }}
    />
    <circle
      className="InstrumentBrassSection_svg__st4"
      cx={75.46}
      cy={36.24}
      r={13.71}
    />
    <circle
      className="InstrumentBrassSection_svg__st4"
      cx={33.82}
      cy={79.73}
      r={24.68}
    />
    <circle
      cx={33.82}
      cy={79.73}
      r={14.31}
      style={{
        fill: "#fdcc75",
      }}
    />
    <path
      d="m21.28 13.02-2.96 3.29-3.59 3.99-1.99 2.21s2.01 5.8 12.94 39.27l15.05-16.76 5.12-5.7 14.89-16.58c-34.45-7.26-39.46-9.72-39.46-9.72z"
      style={{
        fill: "#4e4092",
      }}
    />
    <path
      d="m95.61 104.06 3.48-3.79 4.22-4.6 2.34-2.55s-2.27-6.76-14.58-45.81l-17.7 19.31-6.01 6.56-17.51 19.1c39.97 8.86 45.76 11.78 45.76 11.78z"
      style={{
        fill: "#538553",
      }}
    />
  </svg>
);

export default InstrumentBrassSection;

export default function TrackMuteIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38.66 26.51"
      {...props}
    >
      <rect
        className='__track-mute-icon_cls'
        x={0.76}
        y={0.49}
        width={37.07}
        height={25.5}
        rx={11.72}
        ry={11.72}
        strokeMiterlimit={10}
      />

      <path
        d="M16.74 7.22l-4.05 2.83h-1.42A1.32 1.32 0 0010 11.37v3.8a1.32 1.32 0 001.32 1.32H13l3.74 2.74a1.37 1.37 0 002.17-1.1V8.34a1.37 1.37 0 00-2.17-1.12zM26.71 9.82l-1.82 1.82-1.82-1.82a1.13 1.13 0 00-1.6 1.6l1.82 1.82-1.82 1.82a1.13 1.13 0 000 1.6 1.13 1.13 0 001.6 0l1.82-1.82 1.82 1.82a1.13 1.13 0 001.6-1.6l-1.82-1.82 1.82-1.82a1.13 1.13 0 000-1.6 1.13 1.13 0 00-1.6 0z"
      />
    </svg>
  )
}

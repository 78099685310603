import * as React from "react";

const InstrumentSopranoSax = (props) => (
  <svg
    id="InstrumentSopranoSax_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 111.26 110.84"
    style={{
      enableBackground: "new 0 0 111.26 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentSopranoSax_svg__st3{fill:#4e4092}.InstrumentSopranoSax_svg__st33{fill:#cfd125;stroke:#cfd125;stroke-width:.1758;stroke-miterlimit:10}"
      }
    </style>
    
    <path
      className="InstrumentSopranoSax_svg__st3"
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
    />
    <path
      style={{
        fill: "#f18860",
        stroke: "#f18860",
        strokeWidth: 0.1631,
        strokeMiterlimit: 10,
      }}
      d="m23.73 21.03-2.2-1.89-2.2 2.55 2.08 2.02z"
    />
    <path
      d="m21.22 19.5-2.26 2.62c-.13.16-.31.22-.39.14l-7.96-6.87c-.15-.14.29-.79.54-.8l5.59-.4c.04 0 .07.01.09.03l4.98 4.3c.08.07.04.25-.09.4l-.5.58z"
      style={{
        fill: "#fdcc75",
        stroke: "#fdcc75",
        strokeWidth: 0.1631,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      transform="rotate(-49.217 37.336 30.75)"
      className="InstrumentSopranoSax_svg__st33"
      cx={37.34}
      cy={30.75}
      r={2.43}
    />
    <circle
      transform="rotate(-49.217 42.295 35.028)"
      className="InstrumentSopranoSax_svg__st33"
      cx={42.3}
      cy={35.03}
      r={2.43}
    />
    <circle
      transform="rotate(-49.217 47.104 39.177)"
      className="InstrumentSopranoSax_svg__st33"
      cx={47.11}
      cy={39.18}
      r={2.43}
    />
    <path
      d="M103.88 83.02c.92-1.54 1.51-3.01 2.23-4.52.09-.18-.02-.27-.19-.17-3.53 2.36-5.69 1.33-7.72.25-1.86-.99-3.58-2.16-5.25-3.41l1.06-1.28a.633.633 0 0 0 .69-1.05L55.24 40.25a.634.634 0 0 0-.89.09c-.22.27-.19.67.08.89l.59.49-1.87 2.26.76.63 1.87-2.26 37.46 30.94-1.07 1.29c-.26-.2-.52-.39-.78-.59L23.82 21.12c-.06-.05-.18 0-.27.1l-1.99 2.31c-.09.1-.12.23-.06.28.1.1.2.19.3.29l2.7 2.61c1.61 1.56 3.22 3.12 4.84 4.68 2.17 2.1 4.33 4.19 6.49 6.29 2.56 2.48 5.12 4.96 7.67 7.45 2.79 2.71 5.58 5.43 8.37 8.14 2.86 2.79 5.73 5.58 8.59 8.38 2.78 2.72 5.56 5.44 8.33 8.16 2.53 2.49 5.06 4.98 7.59 7.48 2.13 2.11 4.25 4.22 6.37 6.35 1.57 1.57 3.13 3.15 4.67 4.75 1.35 1.4 3.28 2.72 3.1 5.41-.08 1.15-.74 2.4-1.01 3.52-.03.13.05.19.17.13 1.68-.85 3.59-2.36 5.17-3.7 3.55-3 6.68-6.77 9.03-10.73z"
      style={{
        fill: "#cfd125",
        stroke: "#cfd125",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M53.98 58.2c-.78 0-1.53-.28-2.11-.79a3.254 3.254 0 0 1-.34-4.57c.62-.72 1.51-1.12 2.46-1.12.78 0 1.53.28 2.11.79.66.57 1.05 1.35 1.11 2.22.06.86-.21 1.7-.78 2.36-.61.7-1.51 1.11-2.45 1.11z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      d="M53.98 52.22c.63 0 1.27.22 1.79.67a2.74 2.74 0 0 1 .28 3.87c-.54.63-1.31.95-2.08.95-.63 0-1.27-.22-1.79-.67a2.74 2.74 0 0 1-.28-3.87c.55-.63 1.31-.95 2.08-.95m0-1a3.749 3.749 0 0 0-3.74 4.02c.07 1 .53 1.91 1.29 2.56.68.59 1.55.91 2.44.91a3.749 3.749 0 0 0 3.74-4.02c-.07-1-.53-1.91-1.29-2.56-.67-.59-1.54-.91-2.44-.91z"
      style={{
        fill: "#cfd125",
      }}
    />
    <circle
      transform="rotate(-49.217 47.104 39.177)"
      className="InstrumentSopranoSax_svg__st3"
      cx={47.11}
      cy={39.18}
      r={1.72}
    />
    <circle
      transform="rotate(-49.217 42.295 35.028)"
      className="InstrumentSopranoSax_svg__st3"
      cx={42.3}
      cy={35.03}
      r={1.72}
    />
    <circle
      transform="rotate(-49.217 37.336 30.75)"
      className="InstrumentSopranoSax_svg__st3"
      cx={37.34}
      cy={30.75}
      r={1.72}
    />
  </svg>
);

export default InstrumentSopranoSax;

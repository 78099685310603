import { useEffect } from "react";
import { useHistory } from "react-router";
import Cookies from 'js-cookie';
import { strofeApi } from '../api/strofeApi';

export default function Logout() {
  const history = useHistory();

  useEffect(() => {
    Cookies.remove('api_access_token');
    Cookies.remove('strofe_user_id');
    
    strofeApi.delete('/sessions').finally(() => {
      history.push('/get-started');
    });
  }, [history]);

  return <div className='text-center'>Logging out...</div>;
}

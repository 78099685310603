import StrofeLogoWhite from "../../../icons/StrofeLogoWhite";
import SlideGreenCorner from "../assets/SlideGreenCorner";

const Bullet = () => (
  <svg viewBox="0 0 42 38">
    <path d="M32.1 37.8a8.9 8.9 0 1 0 0-17.8 8.9 8.9 0 0 0 0 17.8Z" fill="#C95671" />
    <path d="M13 24.5c6.738 0 12.2-5.462 12.2-12.2C25.2 5.562 19.738.1 13 .1 6.262.1.8 5.562.8 12.3c0 6.738 5.462 12.2 12.2 12.2Z" fill="#CECF25" />
    <path d="M34.9 12.4a6.2 6.2 0 1 0 0-12.4 6.2 6.2 0 0 0 0 12.4Z" fill="#4D3F92" />
  </svg>
);

const TAGS = [
  { color: '#F8CCC2', text: "300\nGames Published\n\n2011" },
  { color: '#F18860', text: "500\nGames Published\n67% Growth\n2013" },
  { color: '#528352', text: "13,000\nGames Published\n2500% Growth\n2015" },
  { color: '#C95671', text: "67,000\nGames Published\n415% Growth\n2017" },
  { color: '#FCCA75', text: "150,000\nGames Published\n124% Growth\n2019" },
  { color: '#CECF25', text: "270,000\nGames Published\n80% Growth\n2021" },
];

const LEFT_OFFSETS = [0, 100, 130, 160, 190, 220, 320];

export default function SlideVideoGameGrowth() {

  let left = 0;

  const renderTag = (tag, index) => {
    
    left += LEFT_OFFSETS[index];
    const bottom = index * 30;

    return (
      <div className='tag' style={{ transform: `scale(${1 + index * 0.3})`, left, bottom, backgroundColor: tag.color }}>
        <div className='text'>{ tag.text }</div>
      </div>
    );
  }

  return (
    <div className='slide slide-video-game-growth white-bg'>

      <StrofeLogoWhite className='strofe-logo-corner' viewBox="0 200 2000 1600" />
      <SlideGreenCorner className='green-corner' />

      <div className='bullet-title'>
        <div className='bullet'>
          <Bullet />
        </div>
        <h1>The Indie Game Dev Market is Growing Fast</h1>
      </div>

      <div className='content'>
        { TAGS.map((tag, index) => renderTag(tag, index)) }
      </div>

      <div className='footer'>
        <a href="https://itch.io/blog/108659/there-are-200000-games-on-itchio-heres-how-to-find-your-new-favorite" target="_blank" rel="noreferrer">
          https://itch.io/blog/108659/there-are-200000-games-on-itchio-heres-how-to-find-your-new-favorite
        </a>
        <a href="https://www.statista.com/statistics/552623/number-games-released-steam" target="_blank" rel="noreferrer">
          https://www.statista.com/statistics/552623/number-games-released-steam/
        </a>
        <a href="https://www.vice.com/en/article/vvbkb8/itchio-is-the-littlest-next-big-thing-in-gaming" target="_blank" rel="noreferrer">
          https://www.vice.com/en/article/vvbkb8/itchio-is-the-littlest-next-big-thing-in-gaming
        </a>
      </div>
    </div>
  )
}
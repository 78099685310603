import { combineReducers } from '@reduxjs/toolkit';

import users from './usersSlice';
import player from './playerSlice';
import comments from './commentsSlice';

export default combineReducers({
  users,
  player,
  comments,
});

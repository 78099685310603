import * as React from "react";

const InstrumentDulcimer = (props) => (
  <svg
    id="InstrumentDulcimer_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentDulcimer_svg__st3{fill:#4e4092}.InstrumentDulcimer_svg__st5{fill:#cfd125}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#f18860",
      }}
    />
    <path
      className="InstrumentDulcimer_svg__st3"
      d="M77.23 33.75c-5.72 6.3-6.46 15.72-12.11 22.16-4.1 4.67-10.07 6.51-13.83 11.58-4.26 5.75-7.17 12.25-11.16 18.08-3.96 5.78-10.96 8.67-16.95 11.83-1.88.99-6.81 3.14-7.03 3.39 5.8-6.37 11.61-12.74 17.41-19.1C43.84 70.41 54.12 59.13 64.4 47.84c4.29-4.7 8.57-9.41 12.85-14.11l-.02.02z"
    />
    <path
      transform="rotate(42.337 46.404 63.318)"
      style={{
        fill: "#ca5672",
      }}
      d="M43.53 14.49h5.75v97.65h-5.75z"
    />
    <circle
      className="InstrumentDulcimer_svg__st5"
      cx={63.12}
      cy={53.96}
      r={1.25}
    />
    <circle
      className="InstrumentDulcimer_svg__st5"
      cx={35.26}
      cy={86.39}
      r={1.25}
    />
    <path
      className="InstrumentDulcimer_svg__st3"
      d="M72.98 29.88C67.24 36.16 57.93 37.77 52.04 44c-4.27 4.52-5.54 10.63-10.25 14.85-5.33 4.77-11.53 8.27-16.97 12.79-5.39 4.48-7.62 11.72-10.21 17.97-.81 1.96-2.5 7.07-2.72 7.32 5.8-6.37 11.61-12.74 17.41-19.1 10.28-11.28 20.56-22.56 30.84-33.85 4.29-4.7 8.57-9.41 12.86-14.11-.01 0-.01.01-.02.01z"
    />
    <circle
      className="InstrumentDulcimer_svg__st5"
      cx={54.17}
      cy={45.8}
      r={1.25}
    />
    <circle
      className="InstrumentDulcimer_svg__st5"
      cx={24.46}
      cy={76.55}
      r={1.25}
    />
    <path
      d="M95.84 13.83s.34 2.6-3.03 3.32L81.66 29.38l-4.75-4.32 11.15-12.24c.4-3.42 3.03-3.32 3.03-3.32l4.75 4.33z"
      style={{
        fill: "#fdcc75",
      }}
    />
  </svg>
);

export default InstrumentDulcimer;

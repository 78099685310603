import { forwardRef, useEffect, useRef, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import { Trans as Translate } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions, usersSelectors } from '../store/usersSlice';
import { DateTime } from 'luxon';
import useInterval from '../hooks/useInterval';
import usePrevious from '../hooks/usePrevious';
import { useDebouncedCallback } from 'use-debounce/lib';

import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';

import UserEvents from '../utils/UserEvents';
import { LoopingAudio } from '../utils/LoopingAudio';

import RegistrationModal from '../registration/RegistrationModal';
import LoginModal from '../registration/LoginModal';
import ForgotPasswordModal from '../registration/ForgotPasswordModal';
import Countdown from '../layout/Countdown';

import LibraryIcon from "../icons/LibraryIcon";
import NotesWhiteIcon from "../icons/NotesWhiteIcon";
import GearIcon from "../icons/GearIcon";
import StrofeWithStaffLogo from "../icons/logos/StrofeWithStaffLogo";
import HamburgerIcon from '../icons/HamburgerIcon';
import StrofeCoinMono from '../icons/coins/StrofeCoinMono';
import MoodSpiritedIcon from '../icons/MoodSpiritedIcon';
import InfinityIcon from '../icons/InfinityIcon';

import './NavigationHeader.scss';

export default function NavigationHeader() {

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  
  const currentUser = useSelector(usersSelectors.getCurrentUser);
  const localSettings = useSelector(usersSelectors.getLocalSettings);
  const abTests = useSelector(usersSelectors.getAbTests);

  const [coinCounter, setCoinCounter] = useState(currentUser.coins);
  const [coinDifference, setCoinDifference] = useState(0);

  const mobileMenuRef = useRef();
  const extraCoinsRef = useRef();
  const coinsAmountRef = useRef();

  const dispatch = useDispatch();

  const expiryDate = DateTime.fromISO('2022-06-01');
  const today = DateTime.now();

  const animaySaleTest = abTests['animay-sale-2022'];
  const subscribeTest = abTests['subscriptions-multi-tiers'];

  const showSaleBar = animaySaleTest?.variant === 'control' && !localSettings.hideNotificationBar && today < expiryDate;
  const showSaleBadge = animaySaleTest?.variant === 'control' && localSettings.hideNotificationBar && today < expiryDate;

  const prevUserId = usePrevious(currentUser.id);
  const currentUserId = currentUser?.id;

  const disableCoins = currentUser.can_subscribe && (currentUser.subscribed || !subscribeTest || subscribeTest?.variant !== 'control');

  const showLoops = currentUser?.super_user || currentUser?.composer || currentUser?.ambassador || currentUser?.subscribed

  useEffect(() => {
    // If there is a difference between the user coins (potentially just fetched) and the 'cached' coin difference value
    if (currentUser.coins !== coinDifference && !coinDifference) {

      // only show animation through coinDifference if the coins if the user ID is the same
      if (prevUserId === currentUserId) {
        setCoinDifference(currentUser.coins - coinCounter);
      }
      
      // don't show animation if users with different ID's signing in (IE: unregistered user id: 300 -> login with user_id: 2)
      else {
        setCoinCounter(currentUser.coins);
        setCoinDifference(0);
      }
    }
  }, [currentUser.coins, coinCounter, coinDifference, prevUserId, currentUserId]);
  
  useInterval(() => {

    if (coinCounter !== currentUser.coins) {
      const diff = Math.abs(coinCounter - currentUser.coins);
      const op = coinCounter < currentUser.coins ? 1 : -1;

      let increment = 1;

      if (diff > 1000) {
        increment = 1000;
      }
      else if (diff > 100) {
        increment = 100;
      }
      else if (diff > 10) {
        increment = 10;
      }

      setCoinCounter(coinCounter + (increment * op));
    }
    else if (coinDifference && !debouncedResetCoinDifference.isPending()) {
      debouncedResetCoinDifference();
    }
  }, 30);

  const debouncedResetCoinDifference = useDebouncedCallback(() => {
    setCoinDifference(0);
  }, 1000);

  const formattedCoins = coinCounter?.toLocaleString();

  const showCoins = !disableCoins;

  useEffect(() => {
    document.body.classList[showMobileMenu ? 'add' : 'remove']('__body-fixed-mobile');
  }, [showMobileMenu]);

  const handlePrintAudioLog = () => {
    LoopingAudio.logData(true);
  }

  const onForgotPassword = () => {
    setShowLogin(false);
    setShowForgotPassword(true);
  }

  const onLoginFromRegistration = () => {
    setShowRegistration(false);
    setShowLogin(true);
  }

  const renderCoins = () => (
    <div className='coin-container'>
      <CSSTransition in={coinDifference > 0} classNames='popin' timeout={1000} nodeRef={extraCoinsRef}>
        <div className='extra-coins' ref={extraCoinsRef}>
          <div className='add-coins'>+{ coinDifference }</div>
          <div className='coin-arrow' />
        </div>
      </CSSTransition>

      <CSSTransition in={coinDifference !== 0} classNames='highlight' timeout={1000} nodeRef={coinsAmountRef}>
        <div className='coin-menu' ref={coinsAmountRef}>
          <Dropdown>
            <Dropdown.Toggle as={CoinToggle}>
              <StrofeCoinMono />
              { coinCounter > 0 && <div className='number'>{ formattedCoins }</div> }
            </Dropdown.Toggle>
          
            <Dropdown.Menu align='right'>
              <Dropdown.Item as={Link} to='/transactions'><Translate>Transaction History</Translate></Dropdown.Item>
              <Dropdown.Item as={Link} to='/get-coins' data-test='HEADER-get-coins'><Translate>Get Strofe Coins</Translate></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </CSSTransition>
    </div>
  );

  const renderSuperUser = () => (
    <div className='super-user-container'>
      <Dropdown>
        <Dropdown.Toggle as={SuperUserToggle}>
          <MoodSpiritedIcon width={28} height={28} />
        </Dropdown.Toggle>
        <Dropdown.Menu align='right'>
          {currentUser.super_user && <>
              <Dropdown.Item as={Link} className='support-center-link' to='/feedback'>
                <div>Support Center</div>
                <div className='open-feedback-messages-counter'>{currentUser.open_feedback_messages}</div>
              </Dropdown.Item>
              <Dropdown.Item as={Link} to='/user-stats'>User Stats</Dropdown.Item>
              <Dropdown.Item as={Link} to='/coupon-manager'>Coupon Manager</Dropdown.Item>
              <Dropdown.Item as={Link} to='/test-manager'>Test Manager</Dropdown.Item>
              <Dropdown.Item as={Link} to='/intro'>Strofe Introduction</Dropdown.Item>
              <Dropdown.Item as={Link} to='/introduction-invite-manager'>Introduction Invite Manager</Dropdown.Item>
              <Dropdown.Item as={Link} to='/potential-investors'>Potential Investors</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={Link} to='/missing-translations'>Missing Translations</Dropdown.Item>
              <Dropdown.Item as={Link} to='/release'>Release Version</Dropdown.Item>
              <Dropdown.Item onClick={handlePrintAudioLog}>Print Audio Log</Dropdown.Item>
              <Dropdown.Item onClick={resetTopMessage}>Reset Sale Message</Dropdown.Item>
              <Dropdown.Divider />
            </>
          }
          <Dropdown.Item as={Link} to='/loop-stats'>Loop Stats</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {currentUser.open_feedback_messages !== null && currentUser.open_feedback_messages !== 0 &&
        <div className='open-feedback-messages'>
          {currentUser.open_feedback_messages}
        </div>
      }
    </div>
  );

  const handleClickBackground = e => {
    if (e.currentTarget === e.target) {
      setShowMobileMenu(false);
    }
  }

  const hideTopMessage = () => {
    UserEvents.logEvent('nav_header.close_sale_top_bar', animaySaleTest);
    dispatch(usersActions.hideNotificationBar());
  }

  const resetTopMessage = () => {
    dispatch(usersActions.resetNotificationBar());
  }

  const logClickSaleBar = () => {
    UserEvents.logEvent('nav_header.click_sale_top_bar', animaySaleTest);
  }

  const logClickSaleBadge = () => {
    UserEvents.logEvent('nav_header.click_sale_badge', animaySaleTest);
  }

  const renderSaleBar = () => (
    <div className='__notification-bar'>
      <Link to='/get-coins' onClick={logClickSaleBar}>
        <div><Translate>50% Off Strofe Coins</Translate></div>
        <div><strong>Ends in <Countdown inline /></strong></div>
      </Link>
      <div role='button' className='close-button' onClick={hideTopMessage}>×</div>
    </div>
  );

  return (
    <div className='__top-header'>
      { showSaleBar && renderSaleBar() }
      <header className='__navigation-header'>
        <StrofeWithStaffLogo className='logo' />
        <div className='__navigation-header-desktop'>
          <div className='section'>
            <NavLink className='link' to='/library'><LibraryIcon width={26} height={26} /><Translate>Library</Translate></NavLink>
            <NavLink className='link' to='/create'><NotesWhiteIcon width={20} height={20} /><Translate>Create</Translate></NavLink>
            { showLoops && <NavLink className='link' to='/loops'><InfinityIcon width={26} height={26} /><Translate>Loops</Translate></NavLink>}
            { showSaleBadge && <NavLink className='sale-badge' to='/get-coins' onClick={logClickSaleBadge}><Translate>Sale 50% Off</Translate></NavLink> }
          </div>
          <div className='section'>
            { showCoins && renderCoins() }
            { (currentUser.super_user || currentUser.composer) && renderSuperUser() }
            { currentUser.registered && <NavLink className='link' to='/settings' data-test="HEADER-settings"><GearIcon width={20} height={20} /></NavLink> }
            { !currentUser.registered && <Button className='sign-up' variant='outline-danger' onClick={() => setShowRegistration(true)} data-test='HEADER-sign-up'><Translate>Sign Up</Translate></Button> }
          </div>
        </div>
        <div className='__navigation-header-mobile'>
          { currentUser.super_user && renderSuperUser() }
          <HamburgerIcon onClick={() => setShowMobileMenu(true)} className='hamburger-toggle' />
        </div>

        <CSSTransition in={showMobileMenu} classNames='show-menu' nodeRef={mobileMenuRef} timeout={300}>
          <div className='mobile-menu-background' onClick={handleClickBackground} ref={mobileMenuRef}>
            <div className='mobile-menu'>
              <div className='close-menu' role='button' onClick={() => setShowMobileMenu(false)}>×</div>

              { !currentUser.registered && (
                <div className='mobile-signup'>
                  <Button className='sign-up' variant='outline-danger' onClick={() => setShowRegistration(true)}><Translate>Sign Up</Translate></Button>
                </div>
              )}

              <NavLink className='link' to='/library'><LibraryIcon width={26} height={26} /><Translate>Library</Translate></NavLink>
              <NavLink className='link' to='/create'><NotesWhiteIcon width={20} height={20} /><Translate>Create</Translate></NavLink>
              {showLoops && <NavLink className='link' to='/loops'><InfinityIcon width={26} height={26} /><Translate>Loops</Translate></NavLink>}

              { currentUser.registered && <NavLink className='link' to='/settings'><GearIcon width={20} height={20} /><Translate>Settings</Translate></NavLink> }

              { showSaleBadge && <NavLink className='sale-badge' to='/get-coins'><Translate>Sale 50% Off</Translate></NavLink> }

              { showCoins && (
                <>
                  <div className='coin-balance'>
                    <div><Translate>Strofe Coins</Translate></div>
                    <div className='amount'>
                      <StrofeCoinMono />
                      { coinCounter > 0 && <div className='number'>{ formattedCoins }</div> }
                    </div>
                  </div>
                  <NavLink className='coin-link' to='/transactions'>&#187; <Translate>Transaction History</Translate></NavLink>
                  <NavLink className='coin-link' to='/get-coins'>&#187; <Translate>Get Strofe Coins</Translate></NavLink>
                </>
              )}
            </div>
          </div>
        </CSSTransition>
      </header>
      
      <RegistrationModal onUserCreated={() => setShowRegistration(false)} onClose={() => setShowRegistration(false)} showWarmup={false} show={showRegistration} from='generic' showLoginLink onLogin={onLoginFromRegistration} />
      <LoginModal show={showLogin} onClose={() => setShowLogin(false)} onForgotPassword={onForgotPassword} fetchUserAfterLogin />
      <ForgotPasswordModal show={showForgotPassword} onClose={() => setShowForgotPassword(false)} />
    </div>
  );
}

const CoinToggle = forwardRef(({ children, onClick }, ref) => (
  <div ref={ref} onClick={onClick} className='coin-toggle' role='button' data-test='HEADER-coin-toggle'>
    { children }
  </div>
));

const SuperUserToggle = forwardRef(({ children, onClick }, ref) => (
  <div ref={ref} onClick={onClick} className='super-user-toggle' role='button'>
    { children }
  </div>
));
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { usersActions } from '../store/usersSlice';

export default function ForgotPasswordModal({ show, onClose }) {

  const [email, setEmail] = useState('');

  const [showInvalidInfo, setShowInvalidInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    if (!show) {
      setEmail('');
      setEmailSent(false);
      setShowInvalidInfo(false);
    }
  }, [show]);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setShowInvalidInfo(false);
    setLoading(true);
    
    try {
      const action = await dispatch(usersActions.requestResetPassword({ email }));
      unwrapResult(action);
      setEmailSent(true);
    }
    catch (error) {
      setShowInvalidInfo(true);
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  const changeEmail = e => {
    setEmail(e.target.value);
  }

  const submitDisabled = !email;

  const renderEmailForm = () => (
    <>
      <Form.Group>
        <Form.Label>Enter your email address:</Form.Label>
        <Form.Control type="email" placeholder="Email" required value={email} onChange={changeEmail} />
      </Form.Group>

      <div className='text-center'>
        { showInvalidInfo && (
          <Alert variant='warning'>
            This email is not registered.
          </Alert>
        )}
      </div>
    </>
  );

  return (
    <>
      <Modal show={show} size='sm' className='__modal' backdrop='static'>
        <Form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>Forgot password?</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            { !emailSent
            ? renderEmailForm()
            : <Alert variant='success'>Instructions to reset your password have been sent to your email.</Alert>
            }
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={onClose} disabled={loading}>{ !emailSent? 'Cancel' : 'Close' }</Button>
            { !emailSent && <Button variant="primary" type="submit" disabled={submitDisabled || loading}>Reset Password</Button> }
          </Modal.Footer>
        </Form>
      </Modal> 
    </>
  );
}
import Form from 'react-bootstrap/Form';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DRUMS } from '../../utils/rails_generated/drums';
import DragHandleIcon from '../../icons/DragHandleIcon';

import './DrumSelect.scss';
import classNames from 'classnames';

export default function DrumSelect({ notes, index, onChange, onDelete }) {

  const { pitch } = notes[index];
  const usedPitches = Object.values(notes).map(note => note.pitch);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: pitch });

  const style = { transform: CSS.Transform.toString(transform), transition };

  return (
    <div className={classNames('__drum-select', { dragging: isDragging })} ref={setNodeRef} style={style}>
      <div className='drag-handle' {...listeners} {...attributes}>
        <DragHandleIcon />  
      </div>

      <Form.Control as="select" value={pitch} size='sm' onChange={e => onChange(index, Number(e.target.value))}>
        { Object.entries(DRUMS).map(([name, index]) => (
          <option key={index} value={index} disabled={usedPitches.includes(index)}>{ name.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }</option>
        ))}
      </Form.Control>

      { <div className='delete-instrument' role="button" onClick={() => onDelete(index)}>×</div> }
    </div>
  );
}

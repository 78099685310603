import * as React from "react";

const InstrumentVoicesOoohs = (props) => (
  <svg
    id="InstrumentVoicesOoohs_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentVoicesOoohs_svg__st6{fill:#ca5672}.InstrumentVoicesOoohs_svg__st9{fill:#cfd026}.InstrumentVoicesOoohs_svg__st28{fill:#fff}"
      }
    </style>
    
    <path
      d="M94.17 110.84h-77.5C7.46 110.84 0 103.38 0 94.17v-77.5C0 7.46 7.46 0 16.67 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.46 16.67-16.67 16.67z"
      style={{
        fill: "#cfd125",
      }}
    />
    <path
      className="InstrumentVoicesOoohs_svg__st28"
      d="M81.97 90.97c-3.37 2.31-7.99 1.45-10.3-1.92L63.6 77.27a.483.483 0 0 1 .8-.54l8.07 11.78a6.453 6.453 0 0 0 8.96 1.67 6.453 6.453 0 0 0 1.67-8.96L72.08 65.13c-2.31-3.37-1.45-7.99 1.92-10.3l.68-.46c3.37-2.31 7.99-1.45 10.3 1.92l2.95 4.3a.483.483 0 0 1-.8.54l-2.95-4.3a6.453 6.453 0 0 0-8.96-1.67l-.68.46a6.453 6.453 0 0 0-1.67 8.96L83.9 80.67c2.3 3.37 1.44 7.99-1.93 10.3z"
    />
    <path
      className="InstrumentVoicesOoohs_svg__st6"
      d="m27.03 37.29 31.39 39.38 3.7-2.53 3.69-2.53-25.39-43.5-6.7 4.59zM39.4 24.84 31.87 30l-7.53 5.16c-.53.36-.66 1.08-.3 1.6.36.53 1.08.66 1.6.3l7.53-5.16 7.53-5.16c.53-.36.66-1.08.3-1.6s-1.08-.66-1.6-.3zM39.21 23.85c-.23-.51-.63-1.19-.96-1.67-3.02-4.41-9.05-5.54-13.46-2.51s-5.54 9.05-2.51 13.46c.41.6.76.97 1.28 1.45l15.65-10.73zM59.11 77.6l7.22-4.94S67.1 74.88 64 77s-4.89.6-4.89.6z"
    />
    <circle
      className="InstrumentVoicesOoohs_svg__st9"
      cx={40.25}
      cy={42.34}
      r={2.32}
    />
    <circle
      className="InstrumentVoicesOoohs_svg__st9"
      cx={44.24}
      cy={48.17}
      r={2.32}
    />
    <path
      className="InstrumentVoicesOoohs_svg__st28"
      d="M23.22 31.23c-.17.12-.4.16-.61.1a.726.726 0 0 1-.49-.9l2.65-8.97c.11-.38.51-.6.9-.49.38.11.6.51.49.9l-2.65 8.97c-.05.16-.16.3-.29.39zM28.03 29.27c-.17.12-.4.16-.61.1a.726.726 0 0 1-.49-.9l2.25-7.61c.11-.38.51-.6.9-.49.38.11.6.51.49.9l-2.25 7.61c-.05.17-.16.3-.29.39zM32.65 26.64c-.17.12-.4.16-.61.1a.726.726 0 0 1-.49-.9l1.39-4.71c.11-.38.51-.6.9-.49.38.11.6.51.49.9l-1.39 4.71c-.05.16-.15.3-.29.39z"
    />
  </svg>
);

export default InstrumentVoicesOoohs;

import { useState } from 'react';
import Slider from 'rc-slider';
import { useDispatch, useSelector } from 'react-redux';
import { playerActions, playerSelectors } from '../../store/playerSlice';
import { useDebouncedCallback } from 'use-debounce/lib';
import { LoopingAudio } from '../../utils/LoopingAudio';

import VolumeIcon from '../../icons/VolumeIcon';
import MutedIcon from '../../icons/MutedIcon';
import './MasterVolume.scss';

export default function MasterVolume() {

  const dispatch = useDispatch();
  const masterVolume = useSelector(playerSelectors.getMasterVolume);
  const masterMuted = useSelector(playerSelectors.getMasterMuted);

  const [volume, setVolume] = useState(masterVolume);

  const changeMasterVolume = volume => {
    setVolume(volume);
    debouncedSetVolume(volume);
    debouncedSetMasterGain(volume);
  }

  const debouncedSetMasterGain = useDebouncedCallback(volume => {
    LoopingAudio.setMasterGain(volume);
  }, 300, { maxWait: 100 });
  
  const debouncedSetVolume = useDebouncedCallback(volume => {
    dispatch(playerActions.changeMasterVolume({ volume }));
  }, 300);

  const toggleMasterMuted = () => {
    LoopingAudio.setMasterGain(masterMuted ? masterVolume : 0);
    dispatch(playerActions.toggleMasterMuted());
  }

  return (
    <div className='__master-volume'>
      <Slider min={0} max={100} onChange={changeMasterVolume} value={masterMuted ? 0 : volume} />
      { masterMuted ? <MutedIcon role='button' onClick={toggleMasterMuted} /> : <VolumeIcon role='button' onClick={toggleMasterMuted} /> }
    </div>
  );
}

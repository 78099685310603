export default function LatinOutline(props) {
  return (
    <svg 
      id="__jazz-outline-prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 116.8 113.9"
      {...props}
    >
      <style>
       {".__latin-outline-prefix__cls-0{fill:none;stroke:#CFD4D7;stroke-width:1.957;stroke-miterlimit:10;}.__latin-outline-prefix__cls-1{fill:#CFD4D7;stroke:#CFD4D7;stroke-miterlimit:10;}.__latin-outline-prefix__cls-2{fill:none;stroke:#CFD4D7;stroke-miterlimit:10;}"}
      </style>
      <g>
        <path className="__latin-outline-prefix__cls-0" d="M97.1,112.3H19.7c-9.2,0-16.7-7.5-16.7-16.7V18.3C3,9.1,10.5,1.6,19.7,1.6h77.4c9.2,0,16.7,7.5,16.7,16.7v77.4
          C113.8,104.9,106.3,112.3,97.1,112.3z"/>
      </g>
      <g>
        <g>
          <path className="__latin-outline-prefix__cls-1" d="M57.4,90.7c-0.1,1.9-0.3,3.8-0.3,5.4c1.2-0.2,3.5-0.3,5.8,0.7c0.4-1.5,0.8-3.4,1.1-5.3
            C61.4,90.2,58.4,90.6,57.4,90.7z"/>
          <path className="__latin-outline-prefix__cls-2" d="M61.9,52.8c0.2,2.7,0,5.4-0.3,8.2c-0.8,7-2.1,14-3.3,21c-0.1,0.4-0.5,3.6-0.8,7.1c0.9-0.2,4-0.6,6.8,0.9
            c0.6-3.5,1.1-6.6,1.1-7c0.7-7.1,1.3-14.1,2.3-21.1c0.4-2.8,0.9-5.5,1.8-8C66.6,52.3,63.7,52.4,61.9,52.8z"/>
          <path className="__latin-outline-prefix__cls-2" d="M67.1,16.4l5.3,5.6l4.9-4.5l3.1,6.6l2.4-0.4c-0.3-2.2-1.1-4.4-2.3-6.3c-1.4-2.2-3.3-4.1-5.8-5.1
            c-1.5-0.6-3.3-0.9-5-1c-1.3-0.1-2.7,0.1-3.9,0.5c-2.5,0.8-4.7,2.4-6.3,4.5c-0.6,0.7-1.1,1.5-1.6,2.3l4.7,3L67.1,16.4z"/>
          <path className="__latin-outline-prefix__cls-2" d="M57.1,96.2c0,1.7,0.1,3.1,0.4,3.6c0.3,0.4,3.1,0.9,4.1,0.5c0.4-0.4,0.9-1.7,1.3-3.4
            C60.7,95.9,58.3,96,57.1,96.2z"/>
          <path className="__latin-outline-prefix__cls-2" d="M57.5,89.1c0,0.5-0.1,1.1-0.1,1.7c1-0.2,4-0.5,6.7,0.9c0.1-0.5,0.2-1.1,0.3-1.6
            C61.6,88.5,58.4,88.9,57.5,89.1z"/>
          <path className="__latin-outline-prefix__cls-1" d="M60.9,48.1c0.4,0.9,0.6,1.9,0.8,2.8c0.1,0.6,0.2,1.3,0.3,1.9c1.8-0.3,4.7-0.5,7.7,1c0.6-1.5,1.3-2.9,2.2-4.3
            C66.7,46,61.5,47.8,60.9,48.1z"/>
          <path className="__latin-outline-prefix__cls-1" d="M82.8,23.6l-2.4,0.4l-3.1-6.6L72.4,22l-5.3-5.6l-4.5,5.1l-4.7-3c-0.5,0.9-1,1.9-1.4,2.9
            c-0.2,0.5-0.4,1-0.5,1.6l1.9-2.7l4.8,3.4l4.4-5.3l5.6,6.4l4.3-4.8l2.7,6.1l3.3-0.8c0-0.1,0-0.3,0-0.4
            C82.9,24.5,82.8,24.1,82.8,23.6z"/>
          <path className="__latin-outline-prefix__cls-2" d="M76.9,20l-4.3,4.8l-5.6-6.4l-4.4,5.3l-4.8-3.4L56,23c-1.2,4.2-1.4,8.6-0.5,12.8l2-5.5l4,4.8l3.6-5.8l3.5,5.2
            l4.8-4.1l2.5,6.1l3.8-2l0.6,4.5c2.1-4.2,3.1-8.9,2.8-13.7l-3.3,0.8L76.9,20z"/>
          <path className="__latin-outline-prefix__cls-1" d="M75.8,36.6l-2.5-6.1l-4.8,4.1L65,29.4l-3.6,5.8l-4-4.8l-2,5.5c0.1,0.4,0.2,0.9,0.3,1.3l1.2-3.4l4,4.8l3.6-5.8
            l3.5,5.2l4.8-4.1l2.5,6.1l3.8-2l0.3,2.4c0.2-0.4,0.5-0.9,0.7-1.3l-0.6-4.5L75.8,36.6z"/>
          <path className="__latin-outline-prefix__cls-2" d="M75.3,39.9l-2.5-6.1L68,37.9l-3.5-5.2L61,38.5l-4-4.8l-1.2,3.4c0.2,0.8,0.4,1.6,0.7,2.4c0.7,2,1.7,4,2.9,5.9
            c0.6,0.9,1.1,1.8,1.4,2.8c0.7-0.3,5.9-2,11,1.5c0.6-0.8,1.3-1.6,2.1-2.3c2.2-2,4.1-4.3,5.5-6.8l-0.3-2.4L75.3,39.9z"/>
        </g>
        <g>
          <path className="__latin-outline-prefix__cls-2" d="M84.5,86.2c1.6,1.1,3.2,2.2,4.6,3.1c0.5-1.1,1.6-3.3,3.7-4.7c-1.1-1.2-2.6-2.5-4-3.8
            C86.1,82.4,84.9,85.3,84.5,86.2z"/>
          <path className="__latin-outline-prefix__cls-1" d="M53.3,62.2c2.5,1.2,4.8,2.9,7.1,4.6c5.8,4.5,11.3,9.2,16.8,14c0.3,0.3,2.9,2.3,5.9,4.4
            c0.3-0.8,1.6-3.9,4.4-5.6c-2.8-2.4-5.3-4.4-5.6-4.7c-5.9-4.3-11.8-8.6-17.5-13.2c-2.2-1.8-4.4-3.7-6.1-5.8
            C55.4,57.8,54,60.4,53.3,62.2z"/>
          <path className="__latin-outline-prefix__cls-2" d="M23.9,38.3l7.7-1.7l-1.4-6.7l7.5,0.8l0.9-2.3c-2.2-0.9-4.5-1.4-6.8-1.4c-2.7,0-5.4,0.8-7.5,2.4
            c-1.4,1-2.6,2.5-3.5,3.9c-0.8,1.1-1.3,2.4-1.7,3.7c-0.6,2.6-0.3,5.4,0.6,7.9c0.3,0.9,0.7,1.8,1.2,2.6l5.2-2.6L23.9,38.3z"/>
          <path className="__latin-outline-prefix__cls-1" d="M89.1,89.3c1.5,0.9,2.8,1.6,3.4,1.5c0.5,0,2.5-2.3,2.6-3.3c-0.1-0.6-1-1.7-2.3-2.9
            C90.8,86,89.6,88.2,89.1,89.3z"/>
          <path className="__latin-outline-prefix__cls-1" d="M83.1,85.2c0.5,0.3,0.9,0.7,1.4,1c0.4-0.9,1.6-3.8,4.3-5.4c-0.4-0.4-0.9-0.8-1.3-1.1
            C84.7,81.3,83.4,84.4,83.1,85.2z"/>
          <path className="__latin-outline-prefix__cls-2" d="M48.6,60.6c1,0.2,2,0.4,2.9,0.8c0.6,0.2,1.2,0.5,1.8,0.8c0.6-1.7,2-4.4,5-6.3c-1-1.3-1.9-2.7-2.6-4.2
            C49.9,54.3,48.7,59.9,48.6,60.6z"/>
          <path className="__latin-outline-prefix__cls-1" d="M38.6,28.3l-0.9,2.3l-7.5-0.8l1.4,6.7l-7.7,1.7l2.2,6.7l-5.2,2.6c0.5,1,1.2,1.9,1.9,2.8
            c0.4,0.4,0.7,0.9,1.1,1.3l-1.4-3.1l5.5-2.4l-2.3-6.7l8.6-1.6l-2-6.3l6.8,0.9l1.1-3.3c-0.1-0.1-0.2-0.1-0.4-0.2
            C39.4,28.7,39,28.5,38.6,28.3z"/>
          <path className="__latin-outline-prefix__cls-2" d="M32.3,31.5l2,6.3l-8.6,1.6l2.3,6.7l-5.5,2.4l1.4,3.1c3.1,3.3,6.9,5.8,11,7.2l-3.8-4.6l6.4-0.9L34.3,47
            l6.4-0.3l-1.1-6.5l6.7,1.1l0.2-4.5l4.3,1.9c-2.6-4.1-6.2-7.5-10.7-9.7l-1.1,3.3L32.3,31.5z"/>
          <path className="__latin-outline-prefix__cls-1" d="M46.4,41.3l-6.7-1.1l1.1,6.5L34.3,47l3.2,6.2l-6.4,0.9l3.8,4.6c0.4,0.1,0.9,0.3,1.3,0.4l-2.4-2.9l6.4-0.9
            L37,49.2l6.4-0.3l-1.1-6.5l6.7,1.1l0.2-4.5l2.3,1c-0.2-0.4-0.5-0.9-0.8-1.3l-4.3-1.9L46.4,41.3z"/>
          <path className="__latin-outline-prefix__cls-2" d="M49.1,43.5l-6.7-1.1l1.1,6.5L37,49.2l3.2,6.2l-6.4,0.9l2.4,2.9c0.8,0.2,1.6,0.4,2.5,0.6
            c2.2,0.4,4.4,0.6,6.7,0.5c1.1,0,2.2,0,3.2,0.2c0.1-0.7,1.3-6.3,7.1-8.9c-0.4-1-0.7-2-0.9-3.1c-0.6-3-1.6-5.9-3.1-8.5l-2.3-1
            L49.1,43.5z"/>
        </g>
        <circle className="__latin-outline-prefix__cls-1" cx="36.7" cy="72.3" r="5.4"/>
        <circle className="__latin-outline-prefix__cls-1" cx="88.7" cy="66.9" r="2"/>
        <circle className="__latin-outline-prefix__cls-1" cx="46.8" cy="76.2" r="2"/>
        <circle className="__latin-outline-prefix__cls-1" cx="91.9" cy="51.5" r="1.4"/>
        <circle className="__latin-outline-prefix__cls-1" cx="44.8" cy="86.2" r="1.4"/>
        <circle className="__latin-outline-prefix__cls-1" cx="84.5" cy="50.1" r="1.4"/>
        <circle className="__latin-outline-prefix__cls-1" cx="83.1" cy="59.2" r="3.4"/>
      </g>
    </svg>
  )
}

import { useEffect, useState } from "react";

import ForgotPasswordModal from "./ForgotPasswordModal";
import LoginModal from "./LoginModal";
import RegistrationModal from "./RegistrationModal";

export default function EnrollmentFlow({ show, onClose, onSuccess, from, showWarmup, requireDisplayName }) {

  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [warmupShown, setWarmupShown] = useState(false);

  useEffect(() => {
    if (show) {
      setShowLogin(false);
      setShowForgotPassword(false);
      setWarmupShown(false);
    }
  }, [show]);

  const onLoginFromRegistration = () => {
    setShowLogin(true);
    setWarmupShown(true);
  }

  const onForgotPassword = () => {
    setShowLogin(false);
    setShowForgotPassword(true);
  }

  const onUserCreated = fetchedUser => {
    onSuccess(fetchedUser);
  }

  const onLoggedIn = fetchedUser => {
    setShowLogin(false);
    onSuccess(fetchedUser);
  }

  const showRegistration = show && !showLogin && !showForgotPassword;
  return (
    <>
      <RegistrationModal showWarmup={showWarmup && !warmupShown} show={showRegistration} onUserCreated={onUserCreated} onClose={onClose} from={from} showLoginLink fetchOnSuccess onLogin={onLoginFromRegistration} requireDisplayName={requireDisplayName} />
      <LoginModal show={showLogin} onClose={() => setShowLogin(false)} onForgotPassword={onForgotPassword} onLoggedIn={onLoggedIn} fetchUserAfterLogin />
      <ForgotPasswordModal show={showForgotPassword} onClose={() => setShowForgotPassword(false)} />
    </>
  )
}
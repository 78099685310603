import * as React from "react";

const InstrumentOboe = (props) => (
  <svg
    id="InstrumentOboe_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.84 110.84"
    style={{
      enableBackground: "new 0 0 110.84 110.84",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".InstrumentOboe_svg__st6{fill:#ca5672}.InstrumentOboe_svg__st51{fill:#f18860;stroke:#f18860;stroke-width:.1723;stroke-miterlimit:10}"
      }
    </style>
    
    <path
      d="M94.27 110.84h-77.5C7.56 110.84.1 103.38.1 94.17v-77.5C.1 7.46 7.56 0 16.77 0h77.5c9.21 0 16.67 7.46 16.67 16.67v77.5c0 9.21-7.47 16.67-16.67 16.67z"
      style={{
        fill: "#cfd125",
      }}
    />
    <path
      d="M106.57 86.17s.45 1.89.16 2.49c-.29.6-3.6 4.81-4.54 4.86-.94.05-1.69.08-1.69.08.01 0-.54-1.55-.59-1.65-.55-1.15-2.35-2.68-2.35-2.68S18.52 24.34 18.22 24.1c-.34-.28-.65-.58-.96-.87-.27-.25-.67-.13-.96.02 0 0-.93-.78-.34-1.63.59-.84 1.53-1.93 1.93-2.11.41-.18 1.29-.15 1.64.29 0 0-.41.35-.25.66.24.44 78.59 61.15 81.05 62.92 1.83 1.31 3.83 3.13 6.24 2.79z"
      style={{
        fill: "#4e4092",
        stroke: "#4e4092",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentOboe_svg__st6"
      d="m13.74 17.57-5.79-5.34-.92.97 6.38 4.67z"
    />
    <circle
      className="InstrumentOboe_svg__st6"
      cx={27.92}
      cy={29.43}
      r={0.78}
    />
    <circle
      className="InstrumentOboe_svg__st6"
      cx={35.85}
      cy={35.83}
      r={1.02}
    />
    <circle
      className="InstrumentOboe_svg__st6"
      cx={40.52}
      cy={39.71}
      r={1.02}
    />
    <circle className="InstrumentOboe_svg__st6" cx={54.42} cy={51} r={1.02} />
    <circle
      className="InstrumentOboe_svg__st6"
      cx={59.42}
      cy={55.22}
      r={1.02}
    />
    <circle
      className="InstrumentOboe_svg__st6"
      cx={63.98}
      cy={58.92}
      r={1.02}
    />
    <circle
      className="InstrumentOboe_svg__st6"
      cx={73.97}
      cy={59.43}
      r={1.54}
    />
    <circle
      className="InstrumentOboe_svg__st6"
      cx={70.16}
      cy={72.85}
      r={1.72}
    />
    <circle
      className="InstrumentOboe_svg__st6"
      cx={78.07}
      cy={79.37}
      r={1.72}
    />
    <circle className="InstrumentOboe_svg__st6" cx={85.1} cy={84.87} r={1.72} />
    <circle
      className="InstrumentOboe_svg__st6"
      cx={84.89}
      cy={67.18}
      r={1.54}
    />
    <circle
      className="InstrumentOboe_svg__st6"
      cx={45.29}
      cy={43.61}
      r={1.02}
    />
    <path
      d="M106.52 86.49s-1.84 4.1-6.12 6.87"
      style={{
        fill: "none",
        stroke: "#f18860",
        strokeWidth: 0.9221,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="InstrumentOboe_svg__st51"
      d="m16.48 19.46-3.16-2.6-.74.74 3.16 2.67z"
    />
    <path
      className="InstrumentOboe_svg__st51"
      d="m15.62 20.41.75.86 1.18-1.39-.88-.63-.19.21z"
    />
  </svg>
);

export default InstrumentOboe;
